import React from "react";

export const RoleSelect = (props) => {
  const { getRolesSuccess, rolesData, roles, handleSelectRole, datarole } =
    props;

  return getRolesSuccess ? (
    <div class="role-checkbox-block">
      {rolesData &&
        rolesData.map((role, index) => {
          return (
            <div className="role-checkbox-block-item"> 
              <input
                className="role-checkbox"
                id={role.id}
                type="checkbox"
                value={role.value}
                name={role.value}
                checked={role.id === datarole || roles === index}
                onChange={() => handleSelectRole(index, role.id)}
              />
              <label for="data-analyst">{role.id}</label>
            </div>
          );
        })}
    </div>
  ) : (
    <div>Error fetching Roles</div>
  );
};
