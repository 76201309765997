import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import {
  getAllEnterprises,
  uploadEnterpriseUsers,
} from "../../redux/Actions/EnterpriseActions";
import Backdrop from "../Backdrop/Backdrop";
import UploadFile from "../Input/UploadFile";

const UploadCSVModal = (props) => {
  const [fileIsPicked, setFileIsPicked] = useState(false);
  const {
    setShow,
    rowId,
    uploadEnterpriseUsersSuccess,
    isUploadingEnterpriseUsers,
    uploadEnterpriseUsersFile,
  } = props;
  useEffect(() => {
    if (uploadEnterpriseUsersSuccess) {
      setShow(false);
    }
  }, [uploadEnterpriseUsersSuccess]);
  const uploadFileHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      return setFileIsPicked(true);
    }

    setFileIsPicked(false);
  };
  const uploadFile = () => {
    const file = document.querySelector(`#fileSelect`).files[0];
    const formData = new FormData();
    formData.append("users", file);
    const payload = {
      formData: formData,
    };
    uploadEnterpriseUsersFile(rowId, payload);
  };
  return (
    <div className="">
      <Backdrop />
      <div className="upload-csv-modal">
        <div className="csv-container">
          <h2>Upload Enterprise Users</h2>
          <p>
            You can upload users to this Enterprise through a <span> csv</span> file with the
            following columns:
            <span> first_name</span>,<span> last_name</span>,<span> email</span>
            ,<span> phone_number </span>
          </p>
          <UploadFile changed={uploadFileHandler} />
        </div>
        <div className="button-container">
          <button disabled={!fileIsPicked} onClick={uploadFile}>
            {isUploadingEnterpriseUsers ? "Uploading..." : "Upload"}
          </button>
          <button onClick={() => setShow(false)}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isUploadingEnterpriseUsers: state.enterprise.isUploadingEnterpriseUsers,
  uploadEnterpriseUsersSuccess: state.enterprise.uploadEnterpriseUsersSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  uploadEnterpriseUsersFile: (id, payload) =>
    dispatch(uploadEnterpriseUsers(id, payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadCSVModal);
