import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  changeProductStatus,
  getAllProducts,
} from "../../../redux/Actions/ProductActions";
import DeleteModal from "../../Modal/DeleteModal";

const DeactivateProduct = (props) => {
  const {
    isChangingStatus,
    changeSuccess,
    changeProductStatus,
    getProducts,
    id,
  } = props;

  const changeStatus = () => {
    const payload = {
      status: "deactivate",
      id: id,
    };
    changeProductStatus(payload);
  };

  useEffect(
    () => {
      if (changeSuccess) {
        props.closeModal();
        getProducts(1, 10);
      }
    }, // eslint-disable-next-line
    [changeSuccess]
  );

  return (
    <DeleteModal
      displayModal={props.displayModal}
      closeModal={props.closeModal}
      title={"Deactivate this product?"}
      width={"463px"}
      text={"Deactivate"}
      onClick={changeStatus}
      disabled={isChangingStatus}
      isLoading={isChangingStatus}
      loadingText={"Deactivating..."}
      children={
        "Once you deactivate this product, this product would be inactive. Deactivate product?"
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isChangingStatus: state.products.isChangingStatus,
  changeSuccess: state.products.changeStatusSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getProducts: (page, limit) => dispatch(getAllProducts(page, limit)),
  changeProductStatus: (payload) => dispatch(changeProductStatus(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeactivateProduct);
