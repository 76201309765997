import React from "react";
import Empty from "../../assets/empty-chat.svg";

const EmptyChat = ({ text, className }) => {
  return (
    <div className={className ? className : "chats-right-details2"}>
      <div className="table-empty-container-image">
        <img src={Empty} alt="empty-table" />
      </div>
      <div className="chats-right-details-history2">{text}</div>
    </div>
  );
};

export default EmptyChat;
