import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";
import Alert from "../../utils/notifications/Alert";

// UPLOAD IMAGE
export const uploadImageStart = () => ({
  type: Actions.UPLOAD_IMAGE_START,
});

export const uploadImageSuccess = (payload) => ({
  type: Actions.UPLOAD_IMAGE_SUCCESS,
  payload,
});

export const uploadImageFailure = (payload) => ({
  type: Actions.UPLOAD_IMAGE_FAILURE,
  payload,
});

export const uploadImage = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(uploadImageStart());

  API.post("/upload", payload.formData, {
    headers: { "Content-Type": "multipart/form-data" },
  }).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(uploadImageSuccess(data && data.data));
      payload.type === "single"
        ? payload.assignUrl(data && data.data && data.data.image_url)
        : payload.assignUrl(data && data.data && data.data.image_url, payload);
      Storage.remove("id");
    } else {
      dispatch(uploadImageFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

const apiUrl = process.env.REACT_APP_WHISPA_ADMIN_KEY;

export function uploadImageCallBack(file) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", `${apiUrl}/upload`);
    xhr.setRequestHeader("Authorization", Storage.get("admin-access-token"));
    const data = new FormData();
    data.append("image_url", file);
    xhr.send(data);
    xhr.addEventListener("load", () => {
      const response = JSON.parse(xhr.responseText);
      resolve({ data: { link: response?.data?.image_url } });
    });
    xhr.addEventListener("error", () => {
      const error = JSON.parse(xhr.responseText);
      Alert(
        "error",
        "Upload Failure!",
        "Image upload was unsuccessful",
        "Image upload was unsuccessful"
      );
      reject(error);
    });
  });
}
