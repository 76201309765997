import React, { useState } from "react";
import { PageButton } from "../Button/Button";
import ExportIcon from "../../assets/Union.svg";
import DropIcon from "../../assets/drop-export.svg";
import TableDropdown from "../Tables/Dropdown";
import MiniDropdown from "./MiniDropdown";
import { useOverviewTypeContext } from "../../contexts/OverviewTypeContext";
import { DateRangePicker } from "react-date-range";
import CustomDropdown from "../CustomDropdown";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import OptionIcon from "../../assets/option_icon.svg";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const Export = (props) => { 
  const { exportHandler, refetchOverview } = props; 

  const [show, setShow] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState("yearly");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [rangeSelected, setRangeSelected] = useState(false);

  const [seasonsTabs, setSeasonsTabs] = useState(["yearly", "monthly"]);
  const overviewType = useOverviewTypeContext();
  const setOverviewType = overviewType?.setOverviewType;

  const types = ["yearly", "monthly"];

  const options = [
    {
      name: "PDF",
      action: () => exportHandler("PDF"),
    },
    {
      name: "CSV",
      action: () => exportHandler("CSV"),
    },
  ];

  const formatMonths = () => {
    return months.map((month, index) => ({ label: month, value: index }));
  };

  const tabOptions = [
    // Yearly
    Array.from({ length: 5 }, (_, index) => new Date().getFullYear() - index)
      .reverse()
      .map((year) => ({ label: year, value: year })),
    // Monthly
    new Date().getFullYear() !== selectedYear
      ? formatMonths()
      : formatMonths().filter((_, index) => index <= new Date().getMonth()),
  ];

  const dropdownHandler = (option, index, type) => {
    if (type === "yearly") {
      refetchOverview({ year: option.value }, type.replace("ly", ""));
      setOverviewType({ year: option.value, type: type.replace("ly", "") });
      setSelectedYear(option.value);
    }

    if (type === "monthly") {
      refetchOverview(
        { year: selectedYear, month: option.value + 1 },
        type.replace("ly", "")
      );
      setOverviewType({
        year: selectedYear,
        month: option.value + 1,
        type: type.replace("ly", ""),
      });
    }

    const tabs = [...seasonsTabs];
    tabs[index] = option.label;
    setSeasonsTabs(tabs);
    setActiveDropdown(option.label);
  };

  const customRangeToggler = (
    <div className={`mini__tab${activeDropdown === "range" ? " active" : ""}`}>
      <div>
        <img src={OptionIcon} alt="option-icon" />
      </div>
    </div>
  );

  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const padTo2Digits = (num) =>
    num.toString().padStart(2, '0');

  const formatDate = (date) => {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-');
  }

  const rangeFilterHandler = () => {
    const { startDate, endDate } = selectionRange[0];
    const start_date = formatDate(startDate);
    const end_date = formatDate(endDate);
    setActiveDropdown("range");
    refetchOverview({ start_date, end_date }, "week"); 
    setOverviewType({ start_date, end_date, type: "week" });
    setRangeSelected((range) => !range);
    setSelectedYear(new Date().getFullYear());
    setSeasonsTabs(["yearly", "monthly"]);
  };

  return (
    <div className="overview-header-right">
      <div className="overview-header-right-button">
        <PageButton
          width={"121px"}
          mainWidth={"144px"}
          background={"rgba(10, 116, 220, 0.08)"}
          marginTop={"0px"}
          type={"button"}
          onClick={() => setShow((prevCheck) => !prevCheck)}
        >
          <div className="button-data">
            <img src={ExportIcon} alt="export icon" />
            <span className="span2">Export</span>
            <img src={DropIcon} alt="dropdown" />
          </div>
        </PageButton>
        {show && (
          <TableDropdown
            active={show}
            hideDropdown={() => setShow(false)}
            options={options}
          />
        )}
      </div>

      <div>
        <div style={{ display: "flex", marginLeft: "3.2rem" }}>
          {seasonsTabs.map((tab, index) => (
            <MiniDropdown
              key={index}
              active={activeDropdown === tab}
              label={tab}
              isDropdown
              options={tabOptions[index]}
              onSelect={(option) =>
                dropdownHandler(option, index, types[index])
              }
            />
          ))}
          <CustomDropdown
            tab={customRangeToggler}
            optionSelected={rangeSelected}
          >
            <DateRangePicker
              className="date-range-picker"
              ranges={selectionRange}
              onChange={({ selection }) => setSelectionRange([selection])}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                onClick={() => rangeFilterHandler()}
                className="date-range-picker-btn"
              >
                SELECT RANGE
              </button>
            </div>
          </CustomDropdown>
        </div>
      </div>
    </div>
  );
};

export default Export;
