import { Field, Formik, Form } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { getUsers, searchUsers } from "../../redux/Actions/UsersActions";
import Modal, { ModalButton } from "../Modal/Modal";
import AsyncSelect from "react-select/async";
import "react-dropdown/style.css";
import axios from "axios";
import Storage from "../../utils/services/storage";
import responseHandler from "../../utils/notifications/responseHandler";
import Input from "../Input/Input";
import {
  assignBlogCategoryOptions,
  assignOrderItemsPayload,
} from "../../utils/functions/UpdateValues";
import MultiDropdown from "../Input/MultiDropdown";
import { createOrder, getAllOrders } from "../../redux/Actions/OrderActions";
import { CreateOrderSchema } from "../../utils/validation/validation";

const CreateOrder = (props) => {
  const {
    displayModal,
    closeModal,
    getUsersData,
    createUserOrder,
    createOrderSuccess,
    isCreatingOrder,
    getAllOrdersData,
  } = props;

  const [dropDownValues, setDropDownValues] = useState({
    user_id: "",
    delivery_state: "",
  });

  const [state, setState] = useState({
    products: [],
  });
  const [options, setOptions] = useState({
    products: null,
  });

  const initialValues = {
    city: "",
    closest_landmark: "",
    full_address: "",
    user_name: "",
    phone_number: "",
  };
  useEffect(() => {
    getUsersData(1, 10, "customer", false);
    getAllProductsNew(1, 10, true);
  }, []);

  useEffect(() => {
    if (createOrderSuccess) {
      document.getElementById("addorderform").reset();
      setDropDownValues({
        user_id: "",
        delivery_state: "",
      });
      setState({ ...state, products: [] });
      getAllOrdersData(1, 10);
    }
    closeModal();
  }, [createOrderSuccess]);

  const adminToken = Storage.get("admin-access-token");
  const getAllProductsNew = async (page, limit, all) => {
    const url = `${process.env.REACT_APP_WHISPA_ADMIN_KEY}/product?limit=${limit}&page=${page}&all=${all}`;

    await axios
      .get(url, {
        headers: {
          "Content-type": "application/json",
          Authorization: adminToken,
        },
      })
      .then((res) => {
        const updatedOptions =
          state && assignBlogCategoryOptions(res?.data?.data?.products);
        setOptions({ ...options, products: updatedOptions });
      });
  };

  const loadOptions = (inputValue, callback) => {
    const url = `${
      process.env.REACT_APP_WHISPA_ADMIN_KEY
    }/user?page=${1}&limit=${10}&type=${"customer"}&search=${inputValue}&hasReferredUsers=false&onlyVerified=false`;
    axios
      .get(url, {
        headers: {
          "Content-type": "application/json",
          Authorization: adminToken,
        },
      })
      .then((res) => {
        const tempArr = [];
        const data = res?.data?.data?.rows;
        data.forEach((element) => {
          tempArr.push({ label: element.full_name, value: element.id });
        });
        callback(tempArr);
      })
      .catch((err) => responseHandler(err, "Failed!"));
  };
  const loadOptionsFee = (inputValue, callback) => {
    const url = `${
      process.env.REACT_APP_WHISPA_ADMIN_KEY
    }/delivery-fee?page=${1}&limit=${10}&q=${inputValue}`;
    axios
      .get(url, {
        headers: {
          "Content-type": "application/json",
          Authorization: adminToken,
        },
      })
      .then((res) => {
        const tempArr = [];
        const data = res?.data?.data?.deliveryFees;
        data.forEach((element) => {
          tempArr.push({ label: element.state, value: element.state });
        });
        callback(tempArr);
      })
      .catch((err) => responseHandler(err, "Failed!"));
  };

  const submitValues = (values) => {
    const { user_id, delivery_state } = dropDownValues;
    const { city, closest_landmark, full_address, user_name, phone_number, email } =
      values;
    const quantites = [];

    for (let i = 0; i < state.products.length; i++) {
      quantites.push(values[state.products[i].name]);
    }
    const payload = {
      email: email,
      delivery_address: {
        state: delivery_state,
        city: city,
        closest_landmark: closest_landmark,
        full_address: full_address,
        user_name: user_name,
        phone_number: phone_number,
      },
      order_items: assignOrderItemsPayload(state.products, quantites),
    };
    createUserOrder(payload);
  };

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Create New Order"}
      width={"568px"}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => submitValues(values)}
        validationSchema={CreateOrderSchema}
      >
        {({ isValid, dirty, setFieldValue }) => {
          const disabled =
            !(isValid && dirty) ||
            !(state.products.length > 0) ||
            dropDownValues.delivery_state === "" 
            // ||
            // dropDownValues.user_id === "";
          return (
            <Form id="addorderform">
              {/* <div style={{ position: "relative", zIndex: "1000" }}>
                <AsyncSelect
                  onChange={(value) =>
                    setDropDownValues({
                      ...dropDownValues,
                      user_id: value.value,
                    })
                  }
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      padding: ".5rem .5rem",
                    }),
                  }}
                  loadOptions={loadOptions}
                  placeholder="Enter Customer Name"
                />
              </div> */}

              <Field name="email" label="email" placeholder="Enter Email" component={Input} emptyValue={setFieldValue} />
 
              <div className="form-grid2">
                <Field
                  name="city"
                  label="City"
                  placeholder="Enter Delivery City"
                  component={Input}
                  emptyValue={setFieldValue}
                />
                <AsyncSelect
                  onChange={(value) =>
                    setDropDownValues({
                      ...dropDownValues,
                      delivery_state: value.value,
                    })
                  }
                  loadOptions={loadOptionsFee}
                  placeholder="Enter Delivery State"
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      padding: ".5rem .5rem",
                    }),
                  }}
                />
              </div>
              <div className="form-grid">
                <Field
                  name="closest_landmark"
                  label="Closest Landmark"
                  placeholder="Enter Closest Landmark"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>
              <div className="form-grid">
                <Field
                  name="full_address"
                  label="Full Address"
                  placeholder="Enter Full Address"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>
              <div className="form-grid2">
                <Field
                  name="user_name"
                  label="Username"
                  placeholder="Enter Customer Username"
                  component={Input}
                  emptyValue={setFieldValue}
                />
                <Field
                  name="phone_number"
                  label="Phone Number"
                  placeholder="Enter Customer Phone Number"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>
              {options && options.products != null && (
                <MultiDropdown
                  state={state}
                  accessor="products"
                  onSelect={(products) =>
                    setState((state) => ({ ...state, products }))
                  }
                  onRemove={(products) =>
                    setState((state) => ({ ...state, products }))
                  }
                  label="Products"
                  top={"30px"}
                  mb={"10px"}
                  placeholder={
                    state.products.length > 0
                      ? "Add more products..."
                      : "e.g Select Products"
                  }
                  testid={"products-tags"}
                  options={options.products}
                />
              )}

              {state &&
                state.products.length > 0 &&
                state.products.map((el) => {
                  return (
                    <div key={el.name} className="form-grid">
                      <Field
                        name={el.name}
                        label={el.name}
                        placeholder="Enter Quantity of Product"
                        component={Input}
                        emptyValue={setFieldValue}
                      />
                    </div>
                  );
                })}
              <ModalButton
                isLoading={isCreatingOrder}
                text={"Create"}
                type={"submit"}
                testId={"button"}
                disabled={disabled}
                loadingText={"Creating..."}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  getUsersSuccess: state.users.getSuccess,
  isCreatingOrder: state.order.isCreatingOrder,
  createOrderSuccess: state.order.createOrderSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getUsersData: (page, limit, type, hasReferredUsers) => dispatch(getUsers(page, limit, type, hasReferredUsers)),
  createUserOrder: (payload) => dispatch(createOrder(payload)),
  getAllOrdersData: (page, limit) => dispatch(getAllOrders(page, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrder);
