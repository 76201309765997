import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";

// CHANGE PASSWORD
export const changeProfilePasswordStart = () => ({
  type: Actions.CHANGE_PASSWORD_START,
});

export const changeProfilePasswordSuccess = (payload) => ({
  type: Actions.CHANGE_PASSWORD_SUCCESS,
  payload,
});

export const changeProfilePasswordFailure = (payload) => ({
  type: Actions.CHANGE_PASSWORD_FAILURE,
  payload,
});

// UPDATE PROFILE
export const updateProfileStart = () => ({
  type: Actions.UPDATE_PROFILE_START,
});

export const updateProfileSuccess = (payload) => ({
  type: Actions.UPDATE_PROFILE_SUCCESS,
  payload,
});

export const updateProfileFailure = (payload) => ({
  type: Actions.UPDATE_PROFILE_FAILURE,
  payload,
});

export const changeProfilePassword = (id, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(changeProfilePasswordStart());
  API.patch(`/dashboard-user/change-password/${id}`, payload).then(
    (response) => {
      const { data, ok } = response;
      if (ok) {
        responseHandler(response, "Success!");
        dispatch(changeProfilePasswordSuccess(data && data.data));
      } else {
        responseHandler(response, "Failed!");
        dispatch(changeProfilePasswordFailure(data));
      }
    }
  );
};

export const updateProfile = (id, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  const adminData = JSON.stringify(payload);

  dispatch(updateProfileStart());
  API.patch(`/dashboard-user/${id}`, payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      responseHandler(response, "Success!");
      Storage.set("admin-details", adminData);
      dispatch(updateProfileSuccess(data && data.data));
    } else {
      responseHandler(response, "Failed!");
      dispatch(updateProfileFailure(data));
    }
  });
};
