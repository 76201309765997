import React from "react";
import Carousel from "../../components/Input/Carousel";
import { useMediaQuery } from "react-responsive";
import Empty from "../../assets/empty.svg";
import { ErrorBtn } from "../Tables/TableButtons";

const Metrics = ({ data }) => {
	const details = data && data.plan_metrics;

	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

	return (
		<div className={isTabletOrMobile ? "hide-carousel" : "info-flex"}>
			{details?.length > 0 ? (
				<Carousel show={isTabletOrMobile ? 1 : 4}>
					{details &&
						details.map((detail, index) => (
							<div className="overview-figures2-div" key={index}>
								<div className="overview-figures2-div-top">
									<h2>{detail.subscribersCount}</h2>{" "}
									{detail.deleted && (
										<span>
											<ErrorBtn text={"Deleted"} />
										</span>
									)}
								</div>
								{detail.renewingSubscribersCount ? (
									<div className="overview-figures2-div-bottom">
										({detail.renewingSubscribersCount}{" "}
										renewable)
									</div>
								) : (
									<></>
								)}
								<div className="overview-figures2-div-bottom">
									{detail.name} Subscribers
								</div>
							</div>
						))}
				</Carousel>
			) : (
				<div className="table-empty2">
					<div className="table-empty-container">
						<div className="table-empty-container-image">
							<img src={Empty} alt="empty-table" />
						</div>
						<div>
							<h2>No payment plan</h2>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Metrics;
