import React, { useEffect, useState } from "react";
import { PageButton } from "../../components/Button/Button";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import { cancelModal, viewModal } from "../../components/Modal/Modal";
import Add from "../../assets/add.svg";
import SwitchWrapper from "../../components/Wrappers/SwitchWrapper";
import SortData from "../../components/Sorting/SortData";
import CreateCoupon from "../../components/CouponsReferrals/Coupons/CreateCoupon";
import {
  filterCoupons,
  getCoupons,
  searchCoupons,
} from "../../redux/Actions/CouponsActions";
import { connect } from "react-redux";
import CouponsColumns from "../../components/TableData/CouponReferrals/Coupon/Coupons";
import FilterCoupon from "../../components/CouponsReferrals/Coupons/FilterCoupon";
import TableDropdown from "../../components/Tables/Dropdown";
import downloadCSV from "../../utils/functions/csvDownloader";
import ExportIcon from "../../assets/Union.svg";
import DropIcon from "../../assets/drop-export.svg";

const Coupon = (props) => {
  const {
    isGetting,
    getSuccess,
    coupons,
    couponsData,
    isSearching,
    searchSuccess,
    searchedCoupons,
    searchedCouponsData,
    getCoupons,
    searchCoupons,
    filterCoupons,
    filterSuccess,
    filteredCoupons,
    filteredCouponsData,
    filterSuccessCoupons,
    isFilteringCoupons,
    filteredCoupon,
    filteredCouponData,
  } = props;

  const [show, setShow] = useState(false);
  const [state, setState] = useState({
    active1: true,
    active2: false,
    active3: false,
    addModal: false,
    addReferralModal: false,
    filterModal: false,
    filterCouponModal: false,
    showSearchResults: false,
    searchValue: "",
    filterValues: {},
  });

  const openModal = (type) => viewModal(type, state, setState);
  const closeModal = (type) => cancelModal(type, state, setState);

  const searchAllCoupons = () => {
    if (state.searchValue === "") {
      return coupons;
    } else {
      searchCoupons(1, 10, state.searchValue, state.active3 ? "auto" : "");
    }
  };

  const moreData = (page, perPage) => {
    searchSuccess
      ? searchCoupons(
          page,
          perPage,
          state.searchValue,
          state.active3 ? "auto" : ""
        )
      : filterSuccess
      ? filterCoupons(
          page,
          perPage,
          state.filterValues?.amount,
          state.filterValues?.status,
          state.filterValues?.product
        )
      : getCoupons(page, perPage);
  };

  const allCoupons = searchSuccess
    ? searchedCoupons
    : filterSuccess
    ? filteredCoupons
    : filterSuccessCoupons
    ? filteredCoupon
    : coupons;

  const totalCoupons = searchSuccess
    ? searchedCouponsData && searchedCouponsData.total
    : filterSuccess
    ? filteredCouponsData && filteredCouponsData.total
    : filterSuccessCoupons
    ? filteredCouponData && filteredCouponData.total
    : couponsData && couponsData.total;

  useEffect(
    () => {
      if (getSuccess) {
        setState({
          ...state,
          searchValue: "",
        });
      }
    },

    // eslint-disable-next-line
    [getSuccess]
  );

  const exportHandler = (format) => {
    switch (format) {
      case "CSV":
        const data = allCoupons;
        
        const exportData =
          data &&
          data.map((coupon) => ({
            Name: coupon.name,
            Code: coupon.code,
            Amount:
              coupon.type === "percentage"
                ? coupon.amount + "%"
                : "NGN" + coupon.amount,
            Products:
              (coupon.products?.length === 0) &
              (coupon.subscription_plans?.length === 0)
                ? "All Products"
                : (coupon.products || coupon.subscription_plan)
                    ?.map((el) => el.name)
                    .join(","),
            Status: coupon.status,
          }));
        downloadCSV(exportData, "coupons-data");
        return;
      default:
        return;
    }
  };

  const export_options = [
    {
      name: "CSV",
      action: () => exportHandler("CSV"),
    },
  ];

  return (
    <div>
      <HeaderWrapper
        title={"Promotions"}
        subtitle={"View and Manage all Discounts"}
      >
        <div
          style={{ display: "flex", alignItems: "center" }}
          className="overview-header-right-button"
        >
          <PageButton
            width={"auto"}
            background={"rgba(10, 116, 220, 0.08)"}
            marginTop={"30px"}
            marginRight={"10px"}
            onClick={() => openModal("addModal")}
            type={"submit"}
          >
            <div className="button-data">
              <img src={Add} alt="add-icon" />
              <span>Create Discount</span>
            </div>
          </PageButton>
          <PageButton
            width={"121px"}
            mainWidth={"144px"}
            background={"rgba(10, 116, 220, 0.08)"}
            marginTop={"30px"}
            type={"button"}
            onClick={() => setShow((prevCheck) => !prevCheck)}
          >
            <div className="button-data">
              <img src={ExportIcon} alt="export icon" />
              <span className="span2">Export</span>
              <img src={DropIcon} alt="dropdown" />
            </div>
          </PageButton>
          {show && (
            <TableDropdown
              options={export_options}
              hideDropdown={() => setShow((prevCheck) => !prevCheck)}
              active
            />
          )}
        </div>
      </HeaderWrapper>
      <SwitchWrapper
        title1={"Codes"}
        state={state}
        setstate={setState}
        component1={
          <CouponsColumns
            state={state}
            setState={setState}
            data={allCoupons}
            getData={(page, limit) => getCoupons(page, limit)}
            getMoreData={(page, perPage) => moreData(page, perPage)}
            isLoading={isGetting || isSearching || isFilteringCoupons}
            success={getSuccess || searchSuccess || filterSuccess}
            sortSucces={searchSuccess || filterSuccess}
            total={totalCoupons}
          />
        }
        right1={
          <SortData
            placeholder={"Search by discount name"}
            width={"347px"}
            searchItems={searchAllCoupons}
            setState={setState}
            state={state}
            onClick={() => openModal("filterCouponModal")}
            filter={true}
            sortSuccess={filterSuccessCoupons || searchSuccess}
            refreshFunction={() =>
              getCoupons(1, 10, state.active3 ? "auto" : "")
            }
          />
        }
      />
      <CreateCoupon
        displayModal={state.addModal}
        closeModal={() => closeModal("addModal")}
      />

      <FilterCoupon
        displayModal={state.filterCouponModal}
        closeModal={() => closeModal("filterCouponModal")}
        prevstate={state}
        setPrevState={setState}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGetting: state.coupons.isGetting,
  getSuccess: state.coupons.getSuccess,
  coupons: state.coupons.coupons,
  couponsData: state.coupons.couponsData,
  isSearching: state.coupons.isSearching,
  searchSuccess: state.coupons.searchSuccess,
  searchedCoupons: state.coupons.searchedCoupons,
  searchedCouponsData: state.coupons.searchedCouponsData,
  filterSuccess: state.coupons.filterSuccess,
  filteredCoupons: state.coupons.filteredCoupons,
  filteredCouponsData: state.coupons.filteredCouponsData,
  isFilteringCoupons: state.coupons.isFilteringCoupons,
  filterSuccessCoupons: state.coupons.filterSuccessCoupons,
  filteredCoupon: state.coupons.filteredCoupon,
  filteredCouponData: state.coupons.filteredCouponData,
  referralsData: state.referrals.referralsData,
});
const mapDispatchToProps = (dispatch) => ({
  getCoupons: (page, limit, type) => dispatch(getCoupons(page, limit, type)),
  searchCoupons: (page, limit, searchValue, type) =>
    dispatch(searchCoupons(page, limit, searchValue, type)),
  filterCoupons: (page, limit, amount, status, product) =>
    dispatch(filterCoupons(page, limit, amount, status, product)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Coupon);
