import React, { useState } from "react";
import truncateName from "../../../utils/functions/ShortenStr";
import { createCategoryArray } from "../../../utils/functions/UpdateValues";
import ActivateFacility from "../../FacilityManagement/ActivateFacility";
import DeleteFacility from "../../FacilityManagement/DeleteFacility";
import EditFacility from "../../FacilityManagement/EditFacility";
import SuspendFacility from "../../FacilityManagement/SuspendFacility";
import { cancelModal, viewModal } from "../../Modal/Modal";
import {
  SuccessBtn,
  DropBtn,
  TableDropdown,
  InfoBtn,
} from "../../Tables/TableButtons";

export const facilityColumns = [
  {
    Header: "Name",
    accessor: "name",
    Cell: ({ row }) => (
      <div className="table-flex">
        <span>{row.original.name}</span>
      </div>
    ),
  },
  {
    Header: "Appointment Type",
    accessor: "appointment_category",
    Cell: ({ row }) => {
      const categories =
        row.original.appointment_category &&
        createCategoryArray(row.original.appointment_category);
      const newCategories = truncateName(categories, 25);

      return <div className="action-flex">{newCategories}</div>;
    },
  },
  {
    Header: "Address",
    accessor: "full_address",
  },
  {
    Header: "Location",
    accessor: "state",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ row }) =>
      row.original.status === "active" ? (
        <SuccessBtn text={"Available"} />
      ) : (
        <InfoBtn text={"Suspended"} />
      ),
  },
  {
    Header: "Action ",
    accessor: "action",
    Cell: ({ row }) => {
      const [show, setShow] = useState(false);

      const id = row.original.id;

      const [state, setState] = useState({
        editModal: false,
        suspendModal: false,
        activateModal: false,
        deleteModal: false,
        state: "",
        categories: [],
      });

      const showModal = (type) => {
        viewModal(type, state, setState);
        setShow((prevCheck) => !prevCheck);
      };

      const options = [
        {
          name: "Edit",
          action: () => showModal("editModal"),
        },
        {
          name: row.original.status === "active" ? "Suspend" : "Activate",
          action:
            row.original.status === "active"
              ? () => showModal("suspendModal")
              : () => showModal("activateModal"),
        },
        {
          name: "Delete",
          action: () => showModal("deleteModal"),
        },
      ];

      const closeModal = (type) => cancelModal(type, state, setState);

      return (
        <div className="action-flex">
          <DropBtn onClick={() => setShow((prevCheck) => !prevCheck)} />
          {show && <TableDropdown options={options} />}

          {state.editModal && (
            <EditFacility
              displayModal={state.editModal}
              closeModal={() => closeModal("editModal")}
              state={state}
              setState={setState}
              id={row.original && row.original.id}
            />
          )}

          {state.suspendModal && (
            <SuspendFacility
              displayModal={state.suspendModal}
              closeModal={() => closeModal("suspendModal")}
              id={id}
            />
          )}

          {state.activateModal && (
            <ActivateFacility
              displayModal={state.activateModal}
              closeModal={() => closeModal("activateModal")}
              id={id}
            />
          )}

          {state.deleteModal && (
            <DeleteFacility
              displayModal={state.deleteModal}
              closeModal={() => closeModal("deleteModal")}
              id={id}
            />
          )}
        </div>
      );
    },
  },
];
