import React, { useEffect, useState } from "react";
import { cancelModal, viewModal } from "../../components/Modal/Modal";
import {
  SuccessBtn,
  PendingBtn,
  DropBtn,
  TableDropdown,
  InfoBtn,
  ErrorBtn,
} from "../../components/Tables/TableButtons";
import Table from "../Tables/Table";
import CanceledOrder from "./CanceledOrder";
import DeleveredOrder from "./DeleveredOrder";
import PendingOrder from "./PendingOrder";
import ProcessingOrder from "./ProcessingOrder";

const OrderDetailsColumns = (props) => {
  const { data, orderNo, changeSuccess, singleOrder } = props;

  useEffect(
    () => {
      if (changeSuccess) {
        window.location.reload();
      }
    }, // eslint-disable-next-line
    [changeSuccess]
  );

  const orderDetailsData = [
    {
      Header: "Product Name",
      accessor: "product_name",
      Cell: ({ row }) => (
        <div className="table-flex">
          <div className="table-flex-image">
            <img
              src={
                row.original &&
                row.original.product &&
                row.original.product.images[0].image_url
              }
              alt="pic"
            />
          </div>
          <span style={{ marginLeft: "16px" }}>
            <h2>
              {row.original &&
                row.original.product &&
                row.original.product.name}
            </h2>
            <p>Order No: {orderNo}</p>
          </span>
        </div>
      ),
    },
    {
      Header: "Quantity",
      accessor: "qty",
    },
    {
      Header: "Unit Price",
      accessor: "price",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <span>NGN {row.original.price}</span>
        </div>
      ),
      Footer: (info) => {
        return (
          <div className="total-price">
            Total &nbsp; <span>NGN {singleOrder?.total_item_price}</span>
          </div>
        );
      },
    },

    {
      Header: "Order Status",
      accessor: "status",
      Cell: ({ row }) =>
        row.original.status === "delivered" ? (
          <SuccessBtn text={"Delivered"} />
        ) : row.original.status === "processing" ? (
          <InfoBtn text={"Processing"} />
        ) : row.original.status === "pending" ? (
          <PendingBtn text={"Pending"} />
        ) : (
          <ErrorBtn text={"Canceled"} />
        ),
    },
    {
      Header: "Product Status ",
      accessor: "product_status",
      Cell: ({ row }) => {
        const id = row.original && row.original.id;

        const status = row.original && row.original.status;

        const [show, setShow] = useState(false);

        const [state, setState] = useState({
          pendingModal: false,
          processingModal: false,
          deliveredModal: false,
          canceledModal: false,
        });

        const openModal = (type) => {
          viewModal(type, state, setState);
          setShow((prevCheck) => !prevCheck);
        };

        const closeModal = (type) => {
          cancelModal(type, state, setState);
        };

        const option1 = [
          {
            name: "Delivered",
            action: () => openModal("deliveredModal"),
          },
          {
            name: "Canceled",
            action: () => openModal("canceledModal"),
          },
        ];

        const option2 = [
          {
            name: "Processing",
            action: () => openModal("processingModal"),
          },
          {
            name: "Delivered",
            action: () => openModal("deliveredModal"),
          },
          {
            name: "Canceled",
            action: () => openModal("canceledModal"),
          },
        ];

        const options = status === "processing" ? option1 : option2;

        return (
          <div className="action-flex">
            {row?.original?.product?.product_status === "In Stock" ? (
              <span className="action-state1">In Stock</span>
            ) : row?.original?.product?.product_status !== "In Stock" ? (
              <span className="action-state2">Out of Stock</span>
            ) : (
              "Nil"
            )}

            {status !== "delivered" && status !== "canceled" && (
              <DropBtn onClick={() => setShow((prevCheck) => !prevCheck)} />
            )}
            {show && <TableDropdown options={options} disabled />}

            {state.pendingModal && (
              <PendingOrder
                displayModal={state.pendingModal}
                closeModal={() => closeModal("pendingModal")}
                id={id}
              />
            )}

            {state.processingModal && (
              <ProcessingOrder
                displayModal={state.processingModal}
                closeModal={() => closeModal("processingModal")}
                id={id}
              />
            )}

            {state.deliveredModal && (
              <DeleveredOrder
                displayModal={state.deliveredModal}
                closeModal={() => closeModal("deliveredModal")}
                id={id}
              />
            )}

            {state.canceledModal && (
              <CanceledOrder
                displayModal={state.canceledModal}
                closeModal={() => closeModal("canceledModal")}
                id={id}
              />
            )}
          </div>
        );
      },
    },
  ];
  return (
    <div className="orders-table">
      <Table
        columns={orderDetailsData}
        data={data}
        getData={() => {}}
        emptyText1={"No available details"}
        isLoading={false}
        success={true}
        auth={true}
        className={"auth-order"}
        showFooter={true}
      />
    </div>
  );
};

export default OrderDetailsColumns;
