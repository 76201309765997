import React, { useEffect, useState } from "react";
import Modal, { ModalButton } from "../Modal/Modal";
import DateFilter from "../Input/DateFilter";

const FilterChat = (props) => {
  const {
    displayModal,
    closeModal,
    isFiltering,
    filterChatStats,
    filterSuccess,
    prevstate,
    setPrevState,
  } = props;
  const [state, setState] = useState({
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    if (filterSuccess) {
      closeModal();
      setState({
        startDate: "",
        endDate: "",
      });
    }
  }, [filterSuccess]);
  const disabled = isFiltering;
  const handleFilter = () => {
    setPrevState({
      ...prevstate,
      filterValues: {
        created_from: new Date(state.startDate).toISOString(),
        created_to: new Date(state.endDate).toISOString(),
      },
    });
    filterChatStats(state.startDate, state.endDate);
  };
  return (
    <div>
      <Modal
        displayModal={displayModal}
        closeModal={closeModal}
        title={"Filter"}
        width={"496px"}
      >
        <div className="">
          <DateFilter
            label={"Start Date"}
            placeholder={"Select date"}
            state={state}
            setState={setState}
            name={"startDate"}
            success={filterSuccess}
          />
          <div className="top-spacer">
            <DateFilter
              label={"End Date"}
              placeholder={"Select date"}
              name={"endDate"}
              state={state}
              setState={setState}
              success={filterSuccess}
            />
          </div>
        </div>
        <ModalButton
          isLoading={isFiltering}
          text={"Apply Filter"}
          testId="button"
          loadingText={"Filtering..."}
          disabled={disabled}
          onClick={handleFilter}
        />
      </Modal>
    </div>
  );
};

export default FilterChat;
