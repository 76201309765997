import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";

// GET FACILITIES (UNPAGINATED)
export const getFacilityStart = () => ({
  type: Actions.GET_FACILITIES_START,
});

export const getFacilitySuccess = (payload) => ({
  type: Actions.GET_FACILITIES_SUCCESS,
  payload,
});

export const getFacilityFailure = (payload) => ({
  type: Actions.GET_FACILITIES_FAILURE,
  payload,
});

// GET FACILITIES
export const getFacilitiesStart = () => ({
  type: Actions.GET_ALL_FACILITIES_START,
});

export const getFacilitiesSuccess = (payload) => ({
  type: Actions.GET_ALL_FACILITIES_SUCCESS,
  payload,
});

export const getFacilitiesFailure = (payload) => ({
  type: Actions.GET_ALL_FACILITIES_FAILURE,
  payload,
});

// ADD NEW FACILITTY
export const addNewFacilityStart = () => ({
  type: Actions.ADD_NEW_FACILITY_START,
});

export const addNewFacilitySuccess = (payload) => ({
  type: Actions.ADD_NEW_FACILITY_SUCCESS,
  payload,
});

export const addNewFacilityFailure = (payload) => ({
  type: Actions.ADD_NEW_FACILITY_FAILURE,
  payload,
});

// GET SINGLE FACILITY
export const getSingleFacilityStart = () => ({
  type: Actions.GET_SINGLE_FACILITY_START,
});

export const getSingleFacilitySuccess = (payload) => ({
  type: Actions.GET_SINGLE_FACILITY_SUCCESS,
  payload,
});

export const getSingleFacilityFailure = (payload) => ({
  type: Actions.GET_SINGLE_FACILITY_FAILURE,
  payload,
});

// EDIT FACILITY
export const editFacilityStart = () => ({
  type: Actions.EDIT_FACILITY_START,
});

export const editFacilitySuccess = (payload) => ({
  type: Actions.EDIT_FACILITY_SUCCESS,
  payload,
});

export const editFacilityFailure = (payload) => ({
  type: Actions.EDIT_FACILITY_FAILURE,
  payload,
});

// ACTIVATE OR SUSPEND FACILITY
export const changeFacilityStatusStart = () => ({
  type: Actions.CHANGE_FACILITY_STATUS_START,
});

export const changeFacilityStatusSuccess = (payload) => ({
  type: Actions.CHANGE_FACILITY_STATUS_SUCCESS,
  payload,
});

export const changeFacilityStatusFailure = (payload) => ({
  type: Actions.CHANGE_FACILITY_STATUS_FAILURE,
  payload,
});

// FILTER FACILITIES
export const filterFacilitiesStart = () => ({
  type: Actions.FILTER_FACILITIES_START,
});

export const filterFacilitiesSuccess = (payload) => ({
  type: Actions.FILTER_FACILITIES_SUCCESS,
  payload,
});

export const filterFacilitiesFailure = (payload) => ({
  type: Actions.FILTER_FACILITIES_FAILURE,
  payload,
});

// SEARCH FACILITIES
export const searchFacilitiesStart = () => ({
  type: Actions.SEARCH_FACILITIES_START,
});

export const searchFacilitiesSuccess = (payload) => ({
  type: Actions.SEARCH_FACILITIES_SUCCESS,
  payload,
});

export const searchFacilitiesFailure = (payload) => ({
  type: Actions.SEARCH_FACILITIES_FAILURE,
  payload,
});

// DELETE FACILITY
export const deleteFacilityStart = () => ({
  type: Actions.DELETE_FACILITY_START,
});

export const deleteFacilitySuccess = (payload) => ({
  type: Actions.DELETE_FACILITY_SUCCESS,
  payload,
});

export const deleteFacilityFailure = (payload) => ({
  type: Actions.DELETE_FACILITY_FAILURE,
  payload,
});

export const getFacility = () => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getFacilityStart());
  API.get("/facility/all").then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getFacilitySuccess(data && data.data));
    } else {
      dispatch(getFacilityFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const getFacilities = (page, limit) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getFacilitiesStart());
  API.get(`/facility?limit=${limit}&page=${page}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getFacilitiesSuccess(data && data.data));
    } else {
      dispatch(getFacilitiesFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const addNewFacility = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(addNewFacilityStart());
  API.post("/facility", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(addNewFacilitySuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(addNewFacilityFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const getSingleFacility = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getSingleFacilityStart());
  API.get(`/facility/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getSingleFacilitySuccess(data && data.data));
    } else {
      dispatch(getSingleFacilityFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const editFacility = (id, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(editFacilityStart());
  API.patch(`/facility/${id}`, payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(editFacilitySuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(editFacilityFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const changeFacilityStatus = (id, status) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(changeFacilityStatusStart());
  API.patch(`/facility/change-status/${id}?status=${status}`).then(
    (response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(changeFacilityStatusSuccess(data && data.data));
        responseHandler(response, "Success!");
      } else {
        dispatch(changeFacilityStatusFailure(data));
        responseHandler(response, "Failed!");
      }
    }
  );
};

export const filterFacilities = (page, limit, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(filterFacilitiesStart());
  API.get(
    `/facility/filter?status=${payload.status}&category_name=${payload.category_name}&limit=${limit}&page=${page}&state=${payload.location}`
  ).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(filterFacilitiesSuccess(data && data.data));
    } else {
      dispatch(filterFacilitiesFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const searchFacilities =
  (page, limit, searchValue) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

    dispatch(searchFacilitiesStart());
    API.get(`/facility?limit=${limit}&page=${page}&search=${searchValue}`).then(
      (response) => {
        const { data, ok } = response;
        if (ok) {
          dispatch(searchFacilitiesSuccess(data && data.data));
        } else {
          dispatch(searchFacilitiesFailure(data));
          responseHandler(response, "Failed!");
        }
      }
    );
  };

export const deleteFacility = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(deleteFacilityStart());
  API.delete(`/facility/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(deleteFacilitySuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(deleteFacilityFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};
