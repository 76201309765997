import responseHandler from "../../utils/notifications/responseHandler";
import Instance from "../../utils/services/api";
import Storage from "../../utils/services/storage";
import Actions from "../Types";

const getAllEnterprisesStart = () => ({
  type: Actions.GET_ENTERPRISES_START,
});

const getAllEnterprisesSuccess = (payload) => ({
  type: Actions.GET_ENTERPRISES_SUCCESS,
  payload,
});

const getSingleEnterpriseDataStart = () => ({
  type: Actions.GET_SINGLE_ENTERPRISES_START,
});

const getSingleEnterpriseDataSuccess = (payload) => ({
  type: Actions.GET_SINGLE_ENTERPRISES_SUCCESS,
  payload,
});

const createEnterpriseStart = () => ({
  type: Actions.CREATE_ENTERPRISE_START,
});
const createEnterpriseSuccess = () => ({
  type: Actions.CREATE_ENTERPRISE_SUCCESS,
});
const createEnterpriseFailure = () => ({
  type: Actions.CREATE_ENTERPRISE_FAILURE,
});

const uploadEnterpriseUsersStart = () => ({
  type: Actions.UPLOAD_ENTERPRISE_USERS_START,
});

const uploadEnterpriseUsersSuccess = () => ({
  type: Actions.UPLOAD_ENTERPRISE_USERS_SUCCESS,
});

export const uploadEnterpriseUsersFailure = () => ({
  type: Actions.UPLOAD_ENTERPRISE_USERS_FAILURE,
});

const updateEnterpriseDataStart = () => ({
  type: Actions.UPDATE_ENTERPRISE_START,
});
const updateEnterpriseDataSuccess = () => ({
  type: Actions.UPDATE_ENTERPRISE_SUCCESS,
});

const updateEnterpriseDataFailure = () => ({
  type: Actions.UPDATE_ENTERPRISE_FAILURE,
});

export const updateEnterpriseData = (id, payload) => (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  dispatch(updateEnterpriseDataStart());
  API.post(`enterprise/${id}`, payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(updateEnterpriseDataSuccess());
      responseHandler(response, "Success");
    } else {
      dispatch(updateEnterpriseDataFailure());
      responseHandler(response, "Failed");
    }
  });
};

export const getAllEnterprises = (page, limit) => (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  dispatch(getAllEnterprisesStart());
  API.get(`enterprise?page=${page}&limit=${limit}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getAllEnterprisesSuccess(data.data.enterprises));
    } else {
      responseHandler(response, "Failed");
    }
  });
};

export const getSingleEnterpriseData = (id) => (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  dispatch(getSingleEnterpriseDataStart());
  API.get(`enterprise/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getSingleEnterpriseDataSuccess(data.data));
    } else {
      responseHandler(response, "Failed");
    }
  });
};

export const createEnterprise = (payload) => (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  dispatch(createEnterpriseStart());
  API.post(`enterprise`, payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(createEnterpriseSuccess());
      responseHandler(response, "Success");
    } else {
      dispatch(createEnterpriseFailure());
      responseHandler(response, "Failed");
    }
  });
};

export const uploadEnterpriseUsers = (id, payload) => (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  dispatch(uploadEnterpriseUsersStart());
  API.post(`enterprise/upload-users/${id}`, payload.formData, {
    headers: { "Content-Type": "multipart/form-data" },
  }).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(uploadEnterpriseUsersSuccess());
      responseHandler(response, "Success");
    } else {
      dispatch(uploadEnterpriseUsersFailure());
      responseHandler(response, "Failure");
    }
  });
};
