import React, { useEffect } from "react";
import SingleUsersChatRoom from "../../../pages/UserManagement/SingleUsersChatRoom";

const ConsultantChats = (props) => {
  const {
    isLoading,
    data,
    setState,
    state,
    showSingleChat,
    getChats,
    id,
    success,
    sortSuccess,
    status,
    openModal
  } = props;

  useEffect(
    () => {
      getChats(id, status);
    },
    // eslint-disable-next-line
    [getChats]
  );

  return (
    <SingleUsersChatRoom
      isLoading={isLoading}
      data={data}
      setState={setState}
      state={state}
      showSingleChat={showSingleChat}
      success={success}
      sortSuccess={sortSuccess}
      status={status}
      openModal={openModal}
    />
  );
};

export default ConsultantChats;
