import PropTypes from 'prop-types';
import React from 'react';
import Loader from 'react-loader-spinner';

export const LoaderSpin = (props) => {
  const { color, width, height, type } = props;

  return (
    <div style={{ height, width }}>
      <Loader type={type} color={color} height='100%' width='100%' />
    </div>
  );
};

LoaderSpin.defaultProps = {
  height: '25px',
  width: '25px',
  type: 'Oval',
  color: '#031434',
};

LoaderSpin.propTypes = {
  color: PropTypes.string,
  height: PropTypes.any,
  width: PropTypes.any,
};
