import React, { useEffect, useState } from "react";
import DeleteCoupon from "../../../CouponsReferrals/Coupons/DeleteCoupon";
import { cancelModal, viewModal } from "../../../Modal/Modal";
import {
  SuccessBtn,
  DropBtn,
  TableDropdown,
  ErrorBtn,
} from "../../../Tables/TableButtons";
import Table from "../../../Tables/Table";
import { getCoupons } from "../../../../redux/Actions/CouponsActions";
import { connect } from "react-redux";
import { getFormattedNowDate } from "../../../../utils/functions/date";

const ReferralsCouponColumns = (props) => {
  const {
    state,
    setState,
    data,
    getData,
    getMoreData,
    isLoading,
    success,
    sortSucces,
    total,
    deleteSuccess,
    getCoupons,
    createSuccess,
  } = props;

  useEffect(
    () => {
      if (createSuccess || deleteSuccess) {
        getCoupons(1, 10, "auto");
      }
    }, // eslint-disable-next-line
    [createSuccess, deleteSuccess, getCoupons]
  );

  const referralsCouponColumns = [
    {
      Header: "Code",
      accessor: "code",
      Cell: ({ row }) => (
        <span style={{ textTransform: "capitalize" }}>
          {row.original?.code}
        </span>
      ),
    },
    {
      Header: "Receiver's Email",
      accessor: "email",
      Cell: ({ row }) => (
        <div>
          <span>{row.original.email}</span>
        </div>
      ),
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ row }) => (
        <div>
          <span>NGN {row.original.amount}</span>
        </div>
      ),
    },
    {
      Header: "Exp. Date",
      accessor: "exp_date",
      Cell: ({ row }) => (
        <div>
          <span>
            {new Date(getFormattedNowDate()) >
            new Date(row.original.expiration_date) ? (
              <ErrorBtn text={"Expired"} />
            ) : (
              row.original.expiration_date
            )}
          </span>
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        const id = row.original && row.original.id;
        const [show, setShow] = useState(false);

        const [state, setState] = useState({
          deleteModal: false,
        });

        const openModal = (type) => {
          viewModal(type, state, setState);
          setShow((prevCheck) => !prevCheck);
        };

        const closeModal = (type) => {
          cancelModal(type, state, setState);
        };

        const options = [
          {
            name: "Delete",
            action: () => openModal("deleteModal"),
          },
        ];

        return (
          <div className="action-flex">
            {row.original.used === true ? (
              <SuccessBtn text={"Used"} />
            ) : (
              <ErrorBtn text={"Unused"} />
            )}
            <DropBtn onClick={() => setShow((prevCheck) => !prevCheck)} />
            {show && <TableDropdown options={options} />}

            {state.deleteModal && (
              <DeleteCoupon
                displayModal={state.deleteModal}
                closeModal={() => closeModal("deleteModal")}
                id={id}
              />
            )}
          </div>
        );
      },
    },
  ];

  return (
    <Table
      state={state}
      setState={setState}
      columns={referralsCouponColumns}
      data={data}
      getData={getData}
      getMoreData={getMoreData}
      isLoading={isLoading}
      success={success}
      sortSucces={sortSucces}
      total={total}
      emptyText1={"You haven't added any Coupon"}
      emptyResultText={"Coupon not found!"}
    />
  );
};
const mapStateToProps = (state) => ({
  isDeleting: state.coupons.isDeleting,
  deleteSuccess: state.coupons.deleteSuccess,
  createSuccess: state.coupons.createSuccess,
  filterSuccess: state.coupons.filterSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getCoupons: (page, limit, type) => dispatch(getCoupons(page, limit, type)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferralsCouponColumns);
