import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  changeFacilityStatus,
  getFacilities,
} from "../../redux/Actions/FacilityActions";
import DeleteModal from "../Modal/DeleteModal";

const SuspendFacility = (props) => {
  const { suspendFacility, isSuspending, changeSuccess, id, getFacilities } =
    props;

  useEffect(
    () => {
      if (changeSuccess) {
        props.closeModal();
        getFacilities(1, 10);
      }
    }, // eslint-disable-next-line
    [changeSuccess]
  );

  const changeStatus = (id) => {
    suspendFacility(id, "suspend");
  };

  return (
    <DeleteModal
      displayModal={props.displayModal}
      closeModal={props.closeModal}
      title={"Suspend this facility?"}
      width={"463px"}
      text={"Suspend"}
      onClick={() => changeStatus(id)}
      disabled={isSuspending}
      isLoading={isSuspending}
      loadingText={"Suspending..."}
      children={
        "Once you suspend this facility, this facility won't be active. Suspend facility?"
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isSuspending: state.facility.isChangingStatus,
  changeSuccess: state.facility.changeSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  suspendFacility: (id, status) => dispatch(changeFacilityStatus(id, status)),
  getFacilities: (page, limit) => dispatch(getFacilities(page, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuspendFacility);
