import React, { useEffect, useState } from "react";
import { PageButton } from "../../components/Button/Button";
import { cancelModal, viewModal } from "../../components/Modal/Modal";
import Table from "../../components/Tables/Table";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import Add from "../../assets/add.svg";
import SortData from "../../components/Sorting/SortData";
import { facilityColumns } from "../../components/TableData/Facility/Facility";
import AddFacility from "../../components/FacilityManagement/AddFacility";
import FilterFacility from "../../components/FacilityManagement/FilterFacility";
import { connect } from "react-redux";
import {
  addNewFacility,
  filterFacilities,
  getFacilities,
  searchFacilities,
} from "../../redux/Actions/FacilityActions";
import ExportIcon from "../../assets/Union.svg";
import DropIcon from "../../assets/drop-export.svg";
import { paginateFacilityFilter } from "../../utils/functions/UpdateValues";
import downloadCSV from "../../utils/functions/csvDownloader";
import TableDropdown from "../../components/Tables/Dropdown";

const Facility = (props) => {
  const {
    isGettingFacilities,
    getSuccess,
    facilities,
    facilitiesData,
    isFilteringFacilities,
    filterSuccess,
    filteredFacilities,
    filteredFacilitiesData,
    isSearchingFacilities,
    searchSuccess,
    searchedFacilities,
    searchedFacilitiesData,
    getFacilities,
    searchFacilities,
    filterFacilities,
    addNewFacility,
    isCreatingFacility,
    addSuccess,
  } = props;

  const [show, setShow] = useState(false);

  const [state, setState] = useState({
    addModal: false,
    filterModal: false,
    searchValue: "",
    state: "",
    city: "",
    filterValues: {},
    categories: [
      {
        category_id: "",
        name: "",
        subcategoryOptions: [],
        sub_category_ids: [],
      },
    ],
  });

  const openModal = (type) => viewModal(type, state, setState);
  const closeModal = (type) => cancelModal(type, state, setState);

  const searchAllFacilities = () => {
    if (state.searchValue === "") {
      return facilities;
    } else {
      searchFacilities(1, 10, state.searchValue);
    }
  };

  const moreData = (page, perPage) => {
    searchSuccess
      ? searchFacilities(page, perPage, state.searchValue)
      : filterSuccess
      ? paginateFacilityFilter(
          page,
          perPage,
          state.filterValues,
          filterFacilities
        )
      : getFacilities(page, perPage);
  };

  const allFacilities = searchSuccess
    ? searchedFacilities
    : filterSuccess
    ? filteredFacilities
    : facilities;

  const totalFacilities = searchSuccess
    ? searchedFacilitiesData && searchedFacilitiesData.count
    : filterSuccess
    ? filteredFacilitiesData && filteredFacilitiesData.count
    : facilitiesData && facilitiesData.count;

  useEffect(
    () => {
      if (addSuccess) {
        document.getElementById("addFacility").reset();
        closeModal("addModal");
        getFacilities(1, 10);
      }
    },
    // eslint-disable-next-line
    [addSuccess]
  );

  useEffect(
    () => {
      if (getSuccess) {
        setState({
          ...state,
          searchValue: "",
        });
      }
    },
    // eslint-disable-next-line
    [getSuccess]
  );

  const exportHandler = (format) => {
    switch (format) {
      case "CSV":
        const data = allFacilities;
        const exportData =
          data &&
          data.map((facility) => ({
            Name: facility.name,
            Category: facility.appointment_category
              ?.map((el) => el.name)
              .join(","),
            Address: facility.full_address,
            Location: facility.state,
            Status: facility.status,
          }));
        downloadCSV(exportData, "facility-data");
        return;
      default:
        return;
    }
  };

  const export_options = [
    {
      name: "CSV",
      action: () => exportHandler("CSV"),
    },
  ];

  return (
    <div>
      <HeaderWrapper
        title={"Facility Management"}
        subtitle={"View and Manage all facilities"}
      >
        <div
          style={{ display: "flex", alignItems: "center" }}
          className="overview-header-right-button"
        >
          <PageButton
            width={"auto"}
            background={"rgba(10, 116, 220, 0.08)"}
            marginTop={"30px"}
            marginRight={"10px"}
            onClick={() => openModal("addModal")}
            type={"submit"}
            disabled={isGettingFacilities}
          >
            <div className="button-data">
              <img src={Add} alt="add-icon" />
              <span>Add Facility</span>
            </div>
          </PageButton>
          <PageButton
            width={"121px"}
            mainWidth={"144px"}
            background={"rgba(10, 116, 220, 0.08)"}
            marginTop={"30px"}
            type={"button"}
            onClick={() => setShow((prevCheck) => !prevCheck)}
          >
            <div className="button-data">
              <img src={ExportIcon} alt="export icon" />
              <span className="span2">Export</span>
              <img src={DropIcon} alt="dropdown" />
            </div>
          </PageButton>
          {show && (
            <TableDropdown
              options={export_options}
              hideDropdown={() => setShow((prevCheck) => !prevCheck)}
              active
            />
          )}
        </div>
      </HeaderWrapper>

      <SortData
        placeholder={"Search by name, appointment type, location"}
        width={"347px"}
        searchItems={searchAllFacilities}
        setState={setState}
        state={state}
        onClick={() => openModal("filterModal")}
        filter={true}
        sortSuccess={filterSuccess || searchSuccess}
        refreshFunction={() => getFacilities(1, 10)}
      />

      <Table
        columns={facilityColumns}
        data={allFacilities}
        getData={getFacilities}
        emptyText1={"You haven't added any facility"}
        emptyText2={"Click on the “Add Facility” button to get started"}
        isLoading={
          isGettingFacilities || isSearchingFacilities || isFilteringFacilities
        }
        getMoreData={(page, perPage) => moreData(page, perPage)}
        success={getSuccess || filterSuccess || searchSuccess}
        sortSuccess={searchSuccess || filterSuccess}
        total={totalFacilities}
        emptyResultText={"Facility not found!"}
        className={"table5"}
      />

      {/* {state.addModal && ( */}
      <AddFacility
        displayModal={state.addModal}
        closeModal={() => closeModal("addModal")}
        isAdding={isCreatingFacility}
        addFacility={addNewFacility}
        state={state}
        setState={setState}
      />
      {/* )} */}

      <FilterFacility
        displayModal={state.filterModal}
        closeModal={() => closeModal("filterModal")}
        isFiltering={isFilteringFacilities}
        filterFacilities={filterFacilities}
        filterSuccess={filterSuccess}
        setFilterState={setState}
        filterState={state}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGettingFacilities: state.facility.isGettingFacilities,
  getSuccess: state.facility.getSuccess,
  facilities: state.facility.facilities,
  facilitiesData: state.facility.facilitiesData,
  isFilteringFacilities: state.facility.isFilteringFacilities,
  filterSuccess: state.facility.filterSuccess,
  filteredFacilities: state.facility.filteredFacilities,
  filteredFacilitiesData: state.facility.filteredFacilitiesData,
  isSearchingFacilities: state.facility.isSearchingFacilities,
  searchSuccess: state.facility.searchSuccess,
  searchedFacilities: state.facility.searchedFacilities,
  searchedFacilitiesData: state.facility.searchedFacilitiesData,
  isCreatingFacility: state.facility.isCreatingFacility,
  addSuccess: state.facility.createSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getFacilities: (page, limit) => dispatch(getFacilities(page, limit)),
  searchFacilities: (page, limit, searchValue) =>
    dispatch(searchFacilities(page, limit, searchValue)),
  filterFacilities: (page, limit, payload) =>
    dispatch(filterFacilities(page, limit, payload)),
  addNewFacility: (payload) => dispatch(addNewFacility(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Facility);
