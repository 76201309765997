import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import Icon from "../../../assets/account_icon.svg";
import BackBtn from "../../../components/Input/BackBtn";
import { LoaderSpin } from "../../../components/Loader/Loader";
import { ClientRight } from "../../../components/UserManagement/Clients/ClientRight";
import GeneralInfo from "../../../components/UserManagement/GeneralInfo";
import UserHeader from "../../../components/UserManagement/UserHeader";
import {
    deleteUser,
    getUsersProfile,
} from "../../../redux/Actions/UsersActions";


const EditClient = (props) => {
    const {
        getUsersProfile,
        isGettingProfile,
        usersProfileData
    } = props;


    const location = useLocation();

    const id = location && location.state && location.state.id;
    const clientName = usersProfileData && usersProfileData.full_name;
    const clientStatus = usersProfileData && usersProfileData.status;
    const clientImage = usersProfileData && usersProfileData.image_url;

    useEffect(() => {
        getUsersProfile("customer", id);
    }, [getUsersProfile, id]);

    const [isLoading, setIsLoading] = useState(false);
    const disabled = isLoading;
    const [clientData, setClientData] = useState(usersProfileData ? usersProfileData : {
        first_name: '',
        last_name: '',
        status: '',
        description: '',
        gender: '',
        age_group: '',
        phoneNumber: '',
        email: ''
    })
    const [message, setMessage] = useState('');
    const [messType, setMessType] = useState('');

    const handleInputChange = (e) => {
        setMessage('');
        setClientData({
            ...clientData, [e.target.name]: e.target.value
        })
    }

    // Making API call to update user's profile 
    const handleUpdateProfile = () => {

        if (clientData.first_name == '' || clientData.firstName == ' ') {
            setMessType('danger')
            setMessage("First name can't be empty");
            return false;
        } else if (clientData.last_name == '' || clientData.last_name == ' ') {
            setMessType('danger')
            setMessage("Last name can't be empty")
            return false;
        } else if (clientData.phone_number == '' || clientData.phone_number == ' ') {
            setMessType('danger')
            setMessage("Phone number can't be empty")
            return false;
        } else if (clientData.email == '' || clientData.email == ' ') {
            setMessType('danger')
            setMessage("Email can't be empty")
            return false;
        } else if (clientData.status == '' || clientData.status == ' ') {
            setMessType('danger')
            setMessage("Status can't be empty")
            return false;
        } else {
            // Now the user has entered email and password, let's check if the user entered a valid email 
            var p = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z]+)/ig;
            var rg = clientData.email.match(p);

            if (!rg) {
                setMessType('danger')
                setMessage("Please enter a valid email.")
                return false;
            } else {

                let updatedUser = {
                    first_name: clientData.first_name,
                    last_name: clientData.last_name,
                    status: clientData.status,
                    phone_number: clientData.phone_number,
                    description: clientData.description,
                    gender: clientData.gender,
                    age_group: clientData.age_group
                }

                // All tests have passed so we can make our API call to update the profile
                console.log('updatedUser', updatedUser)
                console.log('usersProfileData', usersProfileData)
            };
        }
    }


    return (
        <div className="single-users">
            {isGettingProfile ? (
                <div className="loader-table2">
                    <LoaderSpin width="40px" height="40px" />
                </div>
            ) : (
                <div>
                    <BackBtn page="/users" />
                    <div className="top-wrap">
                        <div className="top-wrap-top">
                            <div className="client-header-top">
                                <div className="client-header-top-image">
                                    <img src={clientImage === null ? Icon : clientImage} alt="client" />
                                </div>

                                <div className="client-header-top-text">
                                    <h2>{clientName}</h2>
                                    <p>{clientStatus}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Lower Section  */}
                    <div className="info-wrap">
                        <div className="info-wrap-left">
                            <div className="info">
                                <h4>Description</h4>
                                <div> {!usersProfileData?.about ? "Description not available" : usersProfileData?.about}</div>
                            </div>
                            <div className="info">
                                <h4>General Infomation</h4>
                                <div className="info-data">
                                    <div className="info-data-left">Sex</div>
                                    <div
                                        className="info-data-right"
                                        style={{ textTransform: "capitalize" }}
                                    >
                                        {!usersProfileData?.gender ? "Nil" : usersProfileData?.gender}
                                    </div>
                                    <div className="info-data-left">Age Group</div>
                                    <div
                                        className="info-data-right"
                                        style={{ textTransform: "capitalize" }}
                                    >
                                        {!usersProfileData?.age_group ? "Nil" : ` ${usersProfileData?.age_group} years`}
                                    </div>

                                    <div className="info-data-left">Last Active Subscription Plan</div>
                                    <div
                                        className="info-data-right"
                                        style={{ textTransform: "capitalize" }}
                                    >
                                        {!usersProfileData?.last_active_subscription?.subscription_plans?.id ? "Nil" : ` ${usersProfileData?.last_active_subscription?.subscription_plans?.name}@${new Date(usersProfileData?.last_active_subscription?.created_at).toDateString()}`}
                                    </div>
                                </div>
                            </div>
                            <div className="info">
                                <h4>Contact Infomation</h4>
                                <div className="info-data">
                                    <div className="info-data-left">Phone Number</div>
                                    <div className="info-data-right">
                                        {!usersProfileData?.phone_number ? "Nil" : usersProfileData?.phone_number}
                                    </div>
                                    <div className="info-data-left">Email</div>
                                    <div className="info-data-right">{usersProfileData?.email}</div>
                                </div>
                            </div>

                            {/* Submit Button  */}
                            
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    isGettingProfile: state.users.isGettingProfile,
    getSuccessProfile: state.users.getSuccessProfile,
    usersProfileData: state.users.usersProfileData,
    isDeleting: state.users.isDeleting,
    deleteSuccess: state.users.deleteSuccess,
});

const mapDispatchToProps = (dispatch) => ({
    deleteUser: (id) => dispatch(deleteUser(id)),
    getUsersProfile: (type, id) => dispatch(getUsersProfile(type, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditClient);
