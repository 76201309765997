import React, { useEffect, useState } from "react";
import Modal, { ModalButton } from "../Modal/Modal";
import SelectInput from "../Input/Select";
import { connect } from "react-redux";
import { getAllPlans } from "../../redux/Actions/PaymentPlanActions";
import { LoaderSpin } from "../Loader/Loader";
import { assignPlanFilterOptions } from "../../utils/functions/UpdateValues";
import DateFilterNew from "../Overview/DateFilterNew";
import { TiArrowDownOutline, TiArrowRightOutline } from "react-icons/ti";
import MultiDropdown from "../Input/MultiDropdown";
import DateFilter from "../Input/DateFilter";

const FilterSubscribers = (props) => {
  const {
    displayModal,
    closeModal,
    isFiltering,
    filterSubscribers,
    filterSuccess,
    setFilterState,
    filterState,
    isGetting,
    getPlansSuccess,
    plans,
    getPlans,
    getExportSubscribersCSVResponse,
  } = props;

  const [options, setOptions] = useState([]);

  const [state, setState] = useState({
    category: [],
    subscriptionStart: "",
    subscriptionEnd: "",
  });
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const [minDate2, setMinDate2] = useState("");
  const [maxDate2, setMaxDate2] = useState("");
  const [verification, setVerification] = useState("");
  const [ageRange, setAgeRange] = useState("");

  useEffect(
    () => {
      getPlans();
    },
    // eslint-disable-next-line
    [getPlans]
  );

  useEffect(
    () => {
      if (getPlansSuccess) {
        const updatedPlans = assignPlanFilterOptions(plans?.plans);
        setOptions([...updatedPlans, { category_id: "None", name: "None" }]);
      }
    },
    // eslint-disable-next-line
    [getPlansSuccess]
  );

  const updateSelection = (data) => {
    setState({
      ...state,
      category: data.label,
    });
  };

  const handleFilter = () => {
    const planData = state.category;
    const plans = planData && planData.map((el) => el.category_id);
    console.log(plans);
    const payload = {
      plan: plans && plans.join(","),
      subStartDate:
        (state.subscriptionStart &&
          new Date(state.subscriptionStart).toLocaleDateString()) ||
        null,
      subEndDate:
        (state.subscriptionEnd &&
          new Date(state.subscriptionEnd).toLocaleDateString()) ||
        null,
      // regStartDate: minDate2,
      // regEndDate: maxDate2,
      // verified: verification,
      // ageRange: ageRange,
    };
    setFilterState({
      ...filterState,
      filterValues: payload,
    });
    filterSubscribers(1, 10, payload);
    setState({ ...state, subscriptionEnd: "", subscriptionStart: "" });
    // getExportSubscribersCSVResponse(payload, 1, 10);
  };

  useEffect(
    () => {
      if (filterSuccess) {
        closeModal();
        setState({
          ...state,
          category: "",
        });
      }
    },
    // eslint-disable-next-line
    [
      filterSuccess,
      minDate,
      minDate2,
      maxDate,
      maxDate2,
      verification,
      ageRange,
    ]
  );

  // function to handle verification, gender and age-groups
  const handleSelectChange = (e, type) => {
    console.log("value", e.target.value);
    console.log("type", type);

    if (type == "verification") {
      setVerification(e.target.value);
    } else if (type == "age-groups") {
      setAgeRange(e.target.value);
    }
  };

  return (
    <div>
      {isGetting && displayModal ? (
        <div className="loader-table2">
          <LoaderSpin width="40px" height="40px" />
        </div>
      ) : getPlansSuccess ? (
        <Modal
          displayModal={displayModal}
          closeModal={closeModal}
          title={"Filter Subscribers"}
          width={"50%"}
        >
          <MultiDropdown
            state={state}
            accessor="category"
            label="Subscripion Type"
            placeholder={
              state.category.length > 0
                ? "Filter by more subscripion types"
                : "e.g Select Subscription Types"
            }
            testid={"category-tags"}
            options={options}
            onSelect={(category) =>
              setState((state) => ({ ...state, category }))
            }
            onRemove={(category) =>
              setState((state) => ({ ...state, category }))
            }
          />
          <div className="top-spacer">
            <DateFilter
              label={"Date"}
              placeholder={"Select Subscription Start Date"}
              name={"subscriptionStart"}
              setState={setState}
              state={state}
              success={filterSuccess}
            />
          </div>
          <div className="top-spacer">
            <DateFilter
              label={"Date"}
              placeholder={"Select Subscription End Date"}
              name={"subscriptionEnd"}
              setState={setState}
              state={state}
              success={filterSuccess}
            />
          </div>
          {/* <SelectInput
            label={"Subscription Type"}
            options={options}
            onChange={updateSelection}
            success={filterSuccess}
          /> */}

          {/* Subscription Date Filter - newly implemented  */}
          {/* <div
            style={{
              marginTop: "40px",
              marginBottom: "40px",
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: "darkgrey",
              }}
            >
              <div style={{ display: "flex" }}>
                <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Subscription Date Range
                </p>
                <div style={{ marginLeft: "10px" }}>
                  <TiArrowRightOutline size={22} />
                </div>
              </div>
              <div style={{ marginTop: "3px", marginRight: "16px" }}>
                <TiArrowDownOutline size={22} />
              </div>
            </div> */}

          {/* This only shows when the date has values  */}
          {/* <div
              style={{
                position: "absolute",
                top: "120%",
                width: "100%",
              }}
              id="subscribersDateFilter"
            >
              {minDate != "" && maxDate != "" && (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ fontSize: "14px" }}>
                    <b>From</b>:{" "}
                    <input
                      type="text"
                      value={new Date(minDate).toLocaleDateString()}
                    />
                  </p>
                  <p style={{ fontSize: "14px" }}>
                    <b>To</b>:{" "}
                    <input
                      type="text"
                      value={new Date(maxDate).toLocaleDateString()}
                    />
                  </p>
                </div>
              )}
            </div> */}

          {/* DatePicker  */}
          {/* <div className="subscribersDateBox">
              <DateFilterNew setMinDate={setMinDate} setMaxDate={setMaxDate} />
            </div>
          </div> */}

          {/* Registration Date Filter - newly implemented  */}
          {/* <div
            style={{
              marginTop: "120px",
              marginBottom: "40px",
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: "darkgrey",
              }}
            >
              <div style={{ display: "flex" }}>
                <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Registration Date Range
                </p>
                <div style={{ marginLeft: "10px" }}>
                  <TiArrowRightOutline size={22} />
                </div>
              </div>
              <div style={{ marginTop: "3px", marginRight: "16px" }}>
                <TiArrowDownOutline size={22} />
              </div>
            </div>

            <div
              style={{
                position: "absolute",
                top: "120%",
                width: "100%",
              }}
              id="subscribersDateFilter"
            >
              {minDate2 != "" && maxDate2 != "" && (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ fontSize: "14px" }}>
                    <b>From</b>:{" "}
                    <input
                      type="text"
                      value={new Date(minDate2).toLocaleDateString()}
                    />
                  </p>
                  <p style={{ fontSize: "14px" }}>
                    <b>To</b>:{" "}
                    <input
                      type="text"
                      value={new Date(maxDate2).toLocaleDateString()}
                    />
                  </p>
                </div>
              )}
            </div>

            
            <div className="subscribersDateBox">
              <DateFilterNew
                setMinDate={setMinDate2}
                setMaxDate={setMaxDate2}
              />
            </div>
          </div> */}

          {/* Verification status, Age-group & gender  */}
          <div
            style={{
              marginTop: "80px",
              marginBottom: "40px",
              position: "relative",
            }}
          >
            <div className="subscriberSelectFlex">
              {/* <div>
                <div>
                  <h4 style={{ color: "darkgrey" }}>
                    Select Verification Status:
                  </h4>
                </div>
                <select
                  className="subscriberSelect"
                  onChange={(e) => handleSelectChange(e, "verification")}
                >
                  <option value="">Status</option>
                  <option value="true">VERIFIED</option>
                  <option value="false">UNVERIFIED</option>
                </select>
              </div> */}

              {/* <div>
                <div>
                  <h4 style={{ color: "darkgrey" }}>Select Age-groups:</h4>
                </div>
                <select
                  className="subscriberSelect"
                  onChange={(e) => handleSelectChange(e, "age-groups")}
                >
                  <option selected value="">
                    Age-groups
                  </option>
                  <option value="18 - 24">18 - 24</option>
                  <option value="25 - 34">25 - 34</option>
                  <option value="35 and above">35 and above</option>
                </select>
              </div> */}
            </div>
          </div>

          <ModalButton
            isLoading={isFiltering}
            text={"Apply Search"}
            onClick={() => handleFilter()}
            testId={"button"}
            loadingText={"Filtering..."}
            // disabled={state.category === "" || isFiltering}
          />
        </Modal>
      ) : (
        <div>{displayModal ? "Error fetching plans options" : ""}</div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGetting: state.plan.isGettingPlans,
  getPlansSuccess: state.plan.getSuccess,
  plans: state.plan.plans,
});

const mapDispatchToProps = (dispatch) => ({
  getPlans: () => dispatch(getAllPlans()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterSubscribers);
