import React, { useEffect } from "react";
import SingleChatRoom from "./SingleChatRoom";

const ActiveChatRoom = (props) => {
  const {
    type,
    isLoading,
    data,
    setState,
    state,
    showSingleChat,
    getChats,
    status,
    success,
    sortSuccess,
    filterChatRooms,
    filterSuccess
  } = props;
 

  useEffect(
    () => {
      if (
        filterSuccess
      
      ) {
        return filterChatRooms(
          status,
          state.filterValues.created_from,
          state.filterValues.created_to
        );
      }
      getChats(status);
    },
    // eslint-disable-next-line
    [filterSuccess]
  );

  return (
    <SingleChatRoom
      isLoading={isLoading}
      data={data}
      setState={setState}
      state={state}
      showSingleChat={showSingleChat}
      success={success}
      sortSuccess={sortSuccess}
      status={status}
      type={type}
    />
  );
};

export default ActiveChatRoom;
