import React, { useState } from "react";
import { cancelModal, viewModal } from "../../Modal/Modal";
import delete_icon from "../../../assets/Trash.svg";
import DeleteAdmin from "../../../components/Onboarding/DeleteAdmin";
import truncateName from "../../../utils/functions/ShortenStr";
import EditAdmin from "../../AdminManagement/EditAdmin";

export const otherAdminsColumns = [
  {
    Header: "Name",
    accessor: "name",
    Cell: ({ row }) => (
      <div className="table-flex">
        <span>{row.original.full_name}</span>
      </div>
    ),
  },
  {
    Header: "Email",
    accessor: "email",
    Cell: ({ row }) => (
      <div className="table-flex">
        <span>{truncateName(row.original.email, 15) || "-"}</span>
      </div>
    ),
  },
  {
    Header: "Role",
    accessor: "role",
  },
  {
    Header: "Description",
    accessor: "description",
    Cell: ({ row }) =>
      row.original.description === null ? (
        "Nil"
      ) : (
        <span>{row.original.description}</span>
      ),
  },
  {
    Header: "",
    accessor: "action",
    Cell: ({ row }) => {
      const id = row.original && row.original.id;
      const [state, setState] = useState({
        editModal: false,
        deleteModal: false,
      });

      const openModal = (type) => viewModal(type, state, setState);
      const closeModal = (type) => cancelModal(type, state, setState);

      return (
        <div className="action-flex2">
          <div className="edit" onClick={() => openModal("editModal")}>
            Edit
          </div>
          <div
            className="action-flex2-image"
            onClick={() => openModal("deleteModal")}
          >
            <img src={delete_icon} alt="pic" />
          </div>

          {state.editModal && (
            <EditAdmin
              displayModal={state.editModal}
              closeModal={() => closeModal("editModal")}
              state={state}
              setState={setState}
              data={row.original}
            />
          )}

          {state.deleteModal && (
            <DeleteAdmin
              displayModal={state.deleteModal}
              closeModal={() => closeModal("deleteModal")}
              id={id}
            />
          )}
        </div>
      );
    },
  },
];
