import React from "react";


const Figures2 = ({ chatStatistics }) => {

    //capitalize only the first letter of the string. 
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const reversedChatStatistics = chatStatistics != null && chatStatistics != undefined && chatStatistics?.distribution && [...chatStatistics?.distribution].reverse();

    return (
        <div className="overview-figures3 chat-statistics">
            {/* Details - 1  */}

            {
                reversedChatStatistics ?
                    reversedChatStatistics?.map((detail, index) => ( 
                        <div className="overview-figures3-div" key={index}>
                            <h2 style={{ color: '#a4a7b7' }}>{capitalizeFirstLetter(detail.status)}</h2>
                            <div className="overview-figures-div-top">
                                <h2>{detail._count}</h2>
                                <span
                                    style={{
                                        color: detail.percentage === "0.00" ? "#DC0000" : "#00DCB4",
                                    }}
                                >
                                    {detail?.percentage === "0.00" ? "" : "+"}
                                    {'0.00'}%
                                </span>
                            </div>
                            <div className="overview-figures-div-bottom">Total Number Of Chats</div>
                        </div>
                    )) : ''
            }
        </div>
    );
};

export default Figures2;
