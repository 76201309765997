import Actions from "../Types";

const initialState = {
  isGetting: false,
  getCustomersSuccess: false,
  customers: [],
  customersData: {},

  isSearching: false,
  searchSuccess: false,
  searchedCustomers: [],
  searchedCustomersData: {},

  isFiltering: false,
  filterSuccess: false,
  filteredCustomers: [],
  filteredCustomersData: {},

  isGettingReferredUsers: false,
  referredUsers: [],
  referredUsersData: {},
  getReferredUsersSuccess: false,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_ALL_CUSTOMERS_START:
      return {
        ...state,
        isGetting: true,
        getCustomersSuccess: false,
        isSearching: false,
        isFiltering: false,
        searchSuccess: false,
        filterSuccess: false,
      };
    case Actions.GET_ALL_CUSTOMERS_SUCCESS:
      return {
        ...state,
        isGetting: false,
        getCustomersSuccess: true,
        isSearching: false,
        isFiltering: false,
        searchSuccess: false,
        filterSuccess: false,
        customers: action.payload.rows,
        customersData: action.payload,
      };
    case Actions.FILTER_CUSTOMERS_START:
      return {
        ...state,
        isGetting: false,
        getCustomersSuccess: false,
        isSearching: false,
        isFiltering: true,
        searchSuccess: false,
        filterSuccess: false,
      };
    case Actions.FILTER_CUSTOMERS_SUCCESS:
      return {
        ...state,
        isGetting: false,
        getCustomersSuccess: false,
        isSearching: false,
        isFiltering: false,
        searchSuccess: false,
        filterSuccess: true,
        filteredCustomers: action.payload.rows,
        filteredCustomersData: action.payload,
      };
    case Actions.SEARCH_CUSTOMERS_START:
      return {
        ...state,
        isGetting: false,
        getCustomersSuccess: false,
        isSearching: false,
        isFiltering: true,
        searchSuccess: false,
        filterSuccess: false,
      };
    case Actions.SEARCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        isGetting: false,
        getCustomersSuccess: false,
        isSearching: false,
        isFiltering: false,
        searchSuccess: true,
        filterSuccess: false,
        searchedCustomers: action.payload.rows,
        searchedCustomersData: action.payload,
      };
    case Actions.GET_REFERRED_USERS_START:
      return {
        isGettingReferredUsers: true,
        getCustomersSuccess: false,
      };
    case Actions.GET_REFERRED_USERS_SUCCESS:
      return {
        isGettingReferredUsers: false,
        getReferredUsersSuccess: true,
        referredUsers: action.payload.users,
        referredUsersData: action.payload
      };
    default:
      return state;
  }
};

export default reduxState;
