import PropTypes from "prop-types";
import React from "react";

const DeleteModal = (props) => {
  const {
    title,
    width,
    text,
    onClick,
    disabled,
    isLoading,
    loadingText,
    children,
    activate,
  } = props;

  const divStyle = {
    display: props.displayModal ? "flex" : "none",
  };

  const closeModal = () => {
    props.closeModal();
  };

  return (
    <div className="modal2" style={divStyle}>
      <div style={{ width: `${width}` }} className="modal2-content">
        <div className="top-delete-modal">
          <h1 className="modal2-content-header">{title}</h1>
          <hr />
          <div className="modal2-content-text">
            <span>{children}</span>
          </div>
        </div>

        <div className="modal2-content-button">
          <button
            data-testid="button"
            className="button1"
            style={{ background: activate ? "#0A74DC" : "" }}
            type="submit"
            onClick={onClick}
            disabled={disabled}
          >
            {isLoading ? (
              <div className="loader">
                <div className="loader-text">{loadingText}</div>
              </div>
            ) : (
              text
            )}
          </button>
          <button
            onClick={closeModal}
            className="button2"
            type="submit"
            data-testid={"button2"}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

DeleteModal.propTypes = {
  children: PropTypes.any,
  closeModal: PropTypes.func,
  disabled: PropTypes.any,
  displayModal: PropTypes.any,
  isLoading: PropTypes.any,
  loadingText: PropTypes.any,
  name: PropTypes.any,
  onClick: PropTypes.any,
  show: PropTypes.any,
  text: PropTypes.any,
  title: PropTypes.any,
  type: PropTypes.any,
  width: PropTypes.any,
};

export default DeleteModal;
