import React, { useEffect, useState } from "react";
import Modal, { ModalButton } from "../Modal/Modal";
import SelectInput from "../Input/Select";
import { Statuses } from "../Input/Statuses";
import { assignCategoryOptions } from "../../utils/functions/UpdateValues";
import { connect } from "react-redux";
import { getBlogCategories } from "../../redux/Actions/BlogActions";

const FilterPosts = (props) => {
  const {
    displayModal,
    closeModal,
    isFiltering,
    filterPosts,
    getSuccess,
    categories,
    getCategory,
    filterSuccess,
    setFilterState,
    filterState,
  } = props;

  const [options, setOptions] = useState([]);

  useEffect(
    () => {
      getCategory();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (getSuccess) {
        const updatedCategory = assignCategoryOptions(categories);
        setOptions(updatedCategory);
      }
    },
    // eslint-disable-next-line
    [getSuccess]
  );

  const status = [{ name: "Posted" }, { name: "Failed" }];

  const [state, setState] = useState({
    category: "",
    active: false,
    status: "",
    index: "",
  });

  const updateSelection = (data) => {
    setState({
      ...state,
      category: data.label,
    });
  };

  const handleFilter = () => {
    const payload = {
      category: state.category,
      status: state.status && state.status.toLowerCase(),
    };
    setFilterState({
      ...filterState,
      filterValues: payload,
    });
    filterPosts(1, 10, payload);
  };

  useEffect(
    () => {
      if (filterSuccess) {
        closeModal();
        setState({
          ...state,
          category: "",
          active: false,
          status: "",
          index: "",
        });
      }
    },
    // eslint-disable-next-line
    [filterSuccess]
  );

  const disabled = isFiltering || state.status === "" || state.category === "";

  return (
    <div>
      <Modal
        displayModal={displayModal}
        closeModal={closeModal}
        title={"Filter Posts"}
        width={"496px"}
      >
        <SelectInput
          label={"Category"}
          options={options}
          onChange={updateSelection}
          success={filterSuccess}
        />

        <div className="top-spacer">
          <Statuses
            label={"Status"}
            state={state}
            options={status}
            setstate={setState}
            blue={false}
            numberPerRow={3}
          />
        </div>

        <ModalButton
          isLoading={isFiltering}
          text={"Apply Search"}
          onClick={() => handleFilter()}
          testId={"button"}
          loadingText={"Filtering..."}
          disabled={disabled}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGettingCategory: state.appointment.isGettingCategories,
  getSuccess: state.blogcategory.getCategorySuccess,
  categories: state.blogcategory.allCategories,
});

const mapDispatchToProps = (dispatch) => ({
  getCategory: () => dispatch(getBlogCategories()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterPosts);
