import React from "react";
import Skeleton from "react-loading-skeleton";
import ChatsGrid from "../../components/ChatManagement/ChatsGrid";
import Empty from "../../assets/empty.svg";

const SingleUsersChatRoom = (props) => {
  const {
    isLoading,
    data,
    setState,
    state,
    showSingleChat,
    success,
    sortSuccess,
    status,
    openModal
  } = props;

  return (
    <div>
      {isLoading ? (
        <div className="all-chats">
          <div style={{ padding: "20px", paddingTop: "0px" }}>
            <Skeleton height={"60px"} width={"100%"} count={15} />
          </div>
        </div>
      ) : success ? (
        data && data.length > 0 ? (
          <ChatsGrid
            chats={data}
            setState={setState}
            state={state}
            showSingleChat={showSingleChat}
            isLoading={isLoading}
            openModal={openModal}
          />
        ) : (
          <div className="table-empty">
            <div className="table-empty-container">
              <div className="table-empty-container-image">
                <img src={Empty} alt="empty-table" />
              </div>
              {sortSuccess ? (
                <div>
                  <h2>Chat not found!</h2>
                </div>
              ) : (
                <div>
                  <h2>No {status} Chats</h2>
                </div>
              )}
            </div>
          </div>
        )
      ) : (
        <div style={{ padding: "20px", paddingLeft: "40px" }}>
          Error Fetching Chats
        </div>
      )}
    </div>
  );
};

export default SingleUsersChatRoom;
