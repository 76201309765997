import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getDeliveryFees } from "../../../redux/Actions/DeliveryFeesActions";
import DeleteDeliveryFee from "../../DeliveryFees/DeleteDeliveryFee";
import EditDeliveryFee from "../../DeliveryFees/EditDeliveryFee";

import { cancelModal, viewModal } from "../../Modal/Modal";
import Table from "../../Tables/Table";
import {
  DropBtn,
  TableDropdown
} from "../../Tables/TableButtons";

const DeliveryChargeColumns = (props) => {
  const {
    state,
    setState,
    data,
    getData,
    getMoreData,
    isLoading,
    success,
    sortSucces,
    total,
    deleteSuccess,
    getDeliveryFees,
    changeSuccess,
    createSuccess,
    editSuccess,
  } = props;

  const deliveryFeesColumns = [
    {
      Header: "State",
      accessor: "state",
      Cell: ({ row }) => (
        <div style={{ textTransform: "capitalize" }}>
          <span>{row.original.state}</span>
        </div>
      ),
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ row }) => (
        <div>
          <span>
          NGN&nbsp;
            {row.original.fees}
          </span>
        </div>
      ),
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }) => {
        const data = row.original;
        const id = row.original && row.original.id;
        const [show, setShow] = useState(false);

        const [state, setState] = useState({
          editModal: false,
          scheduleModal: false,
          activateModal: false,
          deleteModal: false,
        });

        const openModal = (type) => {
          viewModal(type, state, setState);
          setShow((prevCheck) => !prevCheck);
        };

        const closeModal = (type) => {
          cancelModal(type, state, setState);
        };

        const options = [
          {
            name: "Edit",
            action: () => openModal("editModal"),
          },
          {
            name: "Delete",
            action: () => openModal("deleteModal"),
          },
        ];

        return (
          <div className="action-flex">
            <DropBtn onClick={() => setShow((prevCheck) => !prevCheck)} />
            {show && (
              <TableDropdown
                options={options}
              />
            )}

            {state.editModal && (
              <EditDeliveryFee
                displayModal={state.editModal}
                closeModal={() => closeModal("editModal")}
                data={data}
              />
            )}

            {state.deleteModal && (
              <DeleteDeliveryFee
                displayModal={state.deleteModal}
                closeModal={() => closeModal("deleteModal")}
                id={id}
              />
            )}
          </div>
        );
      },
    },
  ];

  return (
    <Table
      state={state}
      setState={setState}
      columns={deliveryFeesColumns}
      data={data}
      getData={getData}
      getMoreData={getMoreData}
      isLoading={isLoading}
      success={success}
      sortSucces={sortSucces}
      total={total}
      emptyText1={"You haven't added any Delivery charges for any state"}
      emptyResultText={"State not found!"}
    />
  );
};
const mapStateToProps = (state) => ({
  isDeleting: state.deliveryFees.isDeleting,
  deleteSuccess: state.deliveryFees.deleteSuccess,
  changeSuccess: state.deliveryFees.changeSuccess,
  createSuccess: state.deliveryFees.createSuccess,
  editSuccess: state.deliveryFees.editSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getDeliveryFees: (page, limit) => dispatch(getDeliveryFees(page, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryChargeColumns);
