import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getProductCategories } from "../../../redux/Actions/ProductCategoryAction";
import { assignCategoryOptions } from "../../../utils/functions/UpdateValues";
import DateFilter from "../../Input/DateFilter";
import SelectInput from "../../Input/Select";
import { Statuses } from "../../Input/Statuses";
import Modal, { ModalButton } from "../../Modal/Modal";

const FilterProduct = (props) => {
  const {
    displayModal,
    closeModal,
    isFiltering,
    filterProducts,
    filterSuccess,
    setFilterState,
    filterState,
    getCategory,
    getCategorySuccess,
    categories,
  } = props;

  const [categoryOptions, setCategoryOptions] = useState([]);

  useEffect(
    () => {
      getCategory();
    },
    // eslint-disable-next-line
    [getCategory]
  );

  useEffect(
    () => {
      if (getCategorySuccess) {
        const updatedCategory = assignCategoryOptions(categories);
        setCategoryOptions(updatedCategory);
      }
    },
    // eslint-disable-next-line
    [getCategorySuccess]
  );

  const status = [
    { name: "In Stock" },
    { name: "Low Stock" },
    { name: "Out of Stock" },
  ];

  const [state, setState] = useState({
    date: "",
    category: "",
    active: false,
    status: "",
    index: "",
  });

  const updateSelection = (data) => {
    setState({
      ...state,
      category: data.value,
    });
  };

  const disabled =
    isFiltering ||
    state.status === "" ||
    state.category === ""

  const handleFilter = () => {
    const payload = {
      category_id: state.category,
      status: state.status && state.status.toLowerCase(),
    };

    if (state.date) {
      payload.date = state.date
    }
    setFilterState({
      ...filterState,
      filterValues: payload,
    });
    filterProducts(1, 10, payload);
  };

  useEffect(
    () => {
      if (filterSuccess) {
        closeModal();
        setState({
          ...state,
          date: "",
          category: "",
          active: false,
          status: "",
          index: "",
        });
      }
    },
    // eslint-disable-next-line
    [filterSuccess]
  );

  return (
    <div>
      <Modal
        displayModal={displayModal}
        closeModal={closeModal}
        title={"Filter Product"}
        width={"496px"}
      >
        <SelectInput
          label={"Category"}
          options={categoryOptions}
          onChange={updateSelection}
          success={filterSuccess}
        />

        <div className="top-spacer">
          <DateFilter
            label={"Date added"}
            placeholder={"Select date"}
            state={state}
            name={"date"}
            setState={setState}
            success={filterSuccess}
          />
        </div>

        <div className="top-spacer">
          <Statuses
            label={"Status"}
            state={state}
            options={status}
            setstate={setState}
            blue={false}
            numberPerRow={3}
          />
        </div>

        <ModalButton
          isLoading={isFiltering}
          text={"Apply Filter"}
          onClick={() => handleFilter()}
          testId={"button"}
          loadingText={"Filtering..."}
          disabled={disabled}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGettingCategory: state.productcategory.isGetting,
  getCategorySuccess: state.productcategory.getSuccess,
  categories: state.productcategory.productCategories,
});

const mapDispatchToProps = (dispatch) => ({
  getCategory: () => dispatch(getProductCategories()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterProduct);
