import React from "react";
import Skeleton from "react-loading-skeleton";

const GridLoader = () => {
  return (
    <div className="appointment-grid">
      <Skeleton height={"281px"} width={"100%"} />
      <Skeleton height={"281px"} width={"100%"} />
      <Skeleton height={"281px"} width={"100%"} />
      <Skeleton height={"281px"} width={"100%"} />
      <Skeleton height={"281px"} width={"100%"} />
      <Skeleton height={"281px"} width={"100%"} />
    </div>
  );
};

export default GridLoader;
