import Actions from "../Types";

const initialState = {
  isGettingAppointments: false,
  getSuccess: false,
  appointments: [],
  appointmentsData: {},
  isSearchingAppointments: false,
  searchSuccess: false,
  searchedAppointments: [],
  searchedAppointmentsData: {},
  isFilteringAppointments: false,
  filterSuccess: false,
  filteredAppointments: [],
  filteredAppointmentsData: {},
  isScheduling: false,
  scheduleSuccess: false,
  isRescheduling: false,
  rescheduleSuccess: false,
  isDeleting: false,
  deleteSuccess: false,
  isChangingStatus: false,
  changeSuccess: false,
  appointment: {},
  isGettingSpecialists: false,
  getSpecialistsSuccess: false,
  specialists: [],
  error: null,

  isLoadingExportData: false,
  exportDataSuccess: false,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_SPECIALISTS_START:
      return {
        ...state,
        isGettingSpecialists: true,
        getSpecialistsSuccess: false,
        specialists: [],
        error: null,
      };
    case Actions.GET_SPECIALISTS_SUCCESS:
      return {
        ...state,
        isGettingSpecialists: false,
        getSpecialistsSuccess: true,
        specialists: action.payload,
        error: null,
      };
    case Actions.GET_SPECIALISTS_FAILURE:
      return {
        ...state,
        isGettingSpecialists: false,
        getSpecialistsSuccess: false,
        specialists: [],
        error: action.payload,
      };
    case Actions.GET_USER_APPOINTMENTS_START:
      return {
        ...state,
        isGettingAppointments: true,
        getSuccess: false,
        searchSuccess: false,
        filterSuccess: false,
        rescheduleSuccess: false,
        appointments: [],
        appointmentsData: {},
        error: null,
      };
    case Actions.GET_USER_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        isGettingAppointments: false,
        getSuccess: true,
        searchSuccess: false,
        filterSuccess: false,
        changeSuccess: false,
        deleteSuccess: false,
        rescheduleSuccess: false,
        appointments: action.payload && action.payload.rows,
        appointmentsData: action.payload,
        error: null,
      };
    case Actions.GET_USER_APPOINTMENTS_FAILURE:
      return {
        ...state,
        isGettingAppointments: false,
        getSuccess: false,
        searchSuccess: false,
        filterSuccess: false,
        rescheduleSuccess: false,
        appointments: [],
        appointmentsData: {},
        error: action.payload,
      };
    case Actions.SEARCH_USER_APPOINTMENTS_START:
      return {
        ...state,
        isSearchingAppointments: true,
        searchSuccess: false,
        searchedAppointments: [],
        searchedAppointmentsData: {},
        error: null,
      };
    case Actions.SEARCH_USER_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        isSearchingAppointments: false,
        searchSuccess: true,
        changeSuccess: false,
        deleteSuccess: false,
        rescheduleSuccess: false,
        searchedAppointments: action.payload && action.payload.rows,
        searchedAppointmentsData: action.payload,
        error: null,
      };
    case Actions.SEARCH_USER_APPOINTMENTS_FAILURE:
      return {
        ...state,
        isSearchingAppointments: false,
        searchSuccess: false,
        searchedAppointments: [],
        searchedAppointmentsData: {},
        error: action.payload,
      };
    case Actions.FILTER_USER_APPOINTMENTS_START:
      return {
        ...state,
        isFilteringAppointments: true,
        filterSuccess: false,
        filteredAppointments: [],
        filteredAppointmentsData: {},
        error: null,
      };
    case Actions.FILTER_USER_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        isFilteringAppointments: false,
        filterSuccess: true,
        changeSuccess: false,
        deleteSuccess: false,
        rescheduleSuccess: false,
        filteredAppointments: action.payload && action.payload.rows,
        filteredAppointmentsData: action.payload,
        error: null,
      };
    case Actions.FILTER_USER_APPOINTMENTS_FAILURE:
      return {
        ...state,
        isFilteringAppointments: false,
        searchSuccess: false,
        filteredAppointments: [],
        filteredAppointmentsData: {},
        error: action.payload,
      };
    case Actions.SCHEDULE_USER_APPOINTMENT_START:
      return {
        ...state,
        isScheduling: true,
        scheduleSuccess: false,
        appointment: {},
        error: null,
      };
    case Actions.SCHEDULE_USER_APPOINTMENT_SUCCESS:
      return {
        ...state,
        isScheduling: false,
        scheduleSuccess: true,
        appointment: action.payload,
        error: null,
      };
    case Actions.SCHEDULE_USER_APPOINTMENT_FAILURE:
      return {
        ...state,
        isScheduling: false,
        scheduleSuccess: false,
        appointment: {},
        error: action.payload,
      };
    case Actions.RESCHEDULE_USER_APPOINTMENT_START:
      return {
        ...state,
        isRescheduling: true,
        rescheduleSuccess: false,
        appointment: {},
        error: null,
      };
    case Actions.RESCHEDULE_USER_APPOINTMENT_RESET:
      return {
        ...state,
        rescheduleSuccess: false,
      };
    case Actions.RESCHEDULE_USER_APPOINTMENT_SUCCESS:
      return {
        ...state,
        isRescheduling: false,
        rescheduleSuccess: true,
        changeSuccess: false,
        deleteSuccess: false,
        appointment: action.payload,
        error: null,
      };
    case Actions.RESCHEDULE_USER_APPOINTMENT_FAILURE:
      return {
        ...state,
        isRescheduling: false,
        rescheduleSuccess: false,
        appointment: {},
        error: action.payload,
      };
    case Actions.DELETE_USER_APPOINTMENT_START:
      return {
        ...state,
        isDeleting: true,
        deleteSuccess: false,
        appointment: {},
        error: null,
      };
    case Actions.DELETE_USER_APPOINTMENT_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: true,
        changeSuccess: false,
        rescheduleSuccess: false,
        appointment: action.payload,
        error: null,
      };
    case Actions.DELETE_USER_APPOINTMENT_FAILURE:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: false,
        appointment: {},
        error: action.payload,
      };
    case Actions.CHANGE_USER_APPOINTMENT_STATUS_START:
      return {
        ...state,
        isChangingStatus: true,
        changeSuccess: false,
        appointment: {},
        error: null,
      };
    case Actions.CHANGE_USER_APPOINTMENT_STATUS_SUCCESS:
      return {
        ...state,
        isChangingStatus: false,
        changeSuccess: true,
        deleteSuccess: false,
        rescheduleSuccess: false,
        appointment: action.payload,
        error: null,
      };
    case Actions.CHANGE_USER_APPOINTMENT_STATUS_FAILURE:
      return {
        ...state,
        isChangingStatus: false,
        changeSuccess: false,
        appointment: {},
        error: action.payload,
      };
    case Actions.CONFIRM_USER_APPOINTMENT_START:
      return {
        ...state,
        isChangingStatus: true,
        changeSuccess: false,
        appointment: {},
        error: null,
      };
    case Actions.CONFIRM_USER_APPOINTMENT_SUCCESS:
      return {
        ...state,
        isChangingStatus: false,
        changeSuccess: true,
        deleteSuccess: false,
        rescheduleSuccess: false,
        appointment: action.payload,
        error: null,
      };
    case Actions.CONFIRM_USER_APPOINTMENT_FAILURE:
      return {
        ...state,
        isChangingStatus: false,
        changeSuccess: false,
        appointment: {},
        error: action.payload,
      };
    case Actions.EXPORT_APPOINTMENT_START:
      return {
        ...state,
        isLoadingExportData: true,
        exportDataSuccess: false,
      };
    case Actions.EXPORT_APPOINTMENT_SUCCESS:
      return {
        ...state,
        isLoadingExportData: false,
        exportDataSuccess: true,
      };
    case Actions.EXPORT_APPOINTMENT_FAILURE:
      return {
        ...state,
        isLoadingExportData: false,
        exportDataSuccess: false,
      };
    default:
      return state;
  }
};

export default reduxState;
