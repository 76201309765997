import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  clearAdminState,
  deleteAdmin,
  getAdmins,
} from "../../redux/Actions/AdminActions";
import DeleteModal from "../Modal/DeleteModal";

const DeleteAdmin = (props) => {
  const { deleteAdmin, isDeleting, deleteSuccess, id } = props;

  useEffect(
    () => {
      if (deleteSuccess) {
        props.closeModal();
      }
    }, // eslint-disable-next-line
    [deleteSuccess]
  );

  const handleDelete = (id) => {
    deleteAdmin(id);
  };
  return (
    <DeleteModal
      displayModal={props.displayModal}
      closeModal={props.closeModal}
      title={"Delete this admin?"}
      width={"463px"}
      text={"Delete"}
      onClick={() => handleDelete(id)}
      disabled={isDeleting}
      isLoading={isDeleting}
      loadingText={"Deleting..."}
      children={
        "Once you delete this admin, it won’t be possible to undo this action. Delete admin?"
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isDeleting: state.admin.isDeleting,
  deleteSuccess: state.admin.deleteSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  deleteAdmin: (id) => dispatch(deleteAdmin(id)),
  getAdmins: (page, limit) => dispatch(getAdmins(page, limit)),
  clearState: () => dispatch(clearAdminState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAdmin);
