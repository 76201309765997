import PropTypes from "prop-types";
import React from "react";
import SelectIcon from "../../assets/selectIcon.svg";

const Filter = (props) => {
  const { onClick, marginLeft, marginRight, testid, newIcon } = props;
  return (
    <div
      className="filter-div"
      data-testid={testid}
      onClick={onClick}
      style={{
        marginLeft: `${marginLeft}`,
        marginRight: `${marginRight}`,  
        width: newIcon ? "43px" : "",
      }}
    >
      {!newIcon && <div className="filter-div-text">Filter</div>}
      <div className={newIcon ? "filter-div-img2" : "filter-div-img"}>
        <img src={SelectIcon} alt="filter" />
      </div>
    </div>
  );
};

Filter.propTypes = {
  marginLeft: PropTypes.any,
  marginRight: PropTypes.any,
  newIcon: PropTypes.any,
  onClick: PropTypes.any,
  testid: PropTypes.any,
};

export default Filter;
