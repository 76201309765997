import React, { useEffect, useState } from "react";
import ProductHeader from "../../components/Products/ProductHeader";
import Icon from "../../assets/product.svg";
import ProductSummary from "../../components/Products/Products/ProductSummary";
import ProductHistory from "../../components/Products/ProductHistory/ProductHistory";
import FilterProductHistory from "../../components/Products/ProductHistory/FilterProductHistory";
import { cancelModal, viewModal } from "../../components/Modal/Modal";
import BackBtn from "../../components/Input/BackBtn";
import { connect } from "react-redux";
import { getProductCategories } from "../../redux/Actions/ProductCategoryAction";
import {
  editProduct,
  filterProductHistory,
  getProductHistory,
  getSingleProduct,
  searchProductHistory,
} from "../../redux/Actions/ProductActions";
import { getAppointmentCategories } from "../../redux/Actions/AppointmentActions";

const SingleProduct = (props) => {
  const {
    getProductSuccess,
    productCategories,
    isEditing,
    editSuccess,
    isGetting,
    getSuccess,
    product,
    getProductCategory,
    getSingleProduct,
    editProduct,
    isGettingHistory,
    productHistory,
    productHistoryData,
    getHistorySuccess,
    isFiltering,
    filteredProducts,
    filteredProductsData,
    filterSuccess,
    isSearching,
    searchedProducts,
    searchedProductsData,
    searchSuccess,
    getProductHistory,
    filterProductHistory,
    searchProductHistory,
  } = props;

  const [state, setState] = useState({
    index: 0,
    active: true,
    disableEdit: true,
    filterModal: false,
    searchValue: "",
    filterValues: "",
  });

  const data = props && props.location.state && props.location.state.data;
  const id = props && props.location.state && props.location.state.id;

  const openModal = (type) => viewModal(type, state, setState);
  const closeModal = (type) => cancelModal(type, state, setState);

  useEffect(
    () => {
      if (editSuccess) {
        getSingleProduct(id);
        setState({
          ...state,
          disableEdit: true,
        });
      }
    },
    // eslint-disable-next-line
    [editSuccess]
  );

  useEffect(
    () => {
      if (getHistorySuccess) {
        setState({
          ...state,
          searchValue: "",
        });
      }
    },
    // eslint-disable-next-line
    [getHistorySuccess]
  );

  const searchAllProductHistory = () => {
    if (state.searchValue === "") {
      return productHistory;
    } else {
      const payload = {
        searchValue: state.searchValue,
        id: id,
      };
      searchProductHistory(1, 10, payload);
    }
  };

  return (
    <>
      <BackBtn page={"/products"} />
      <ProductHeader
        state={state}
        setState={setState}
        icon={Icon}
        productName={product.name}
        searchItems={searchAllProductHistory}
        onClick={() => openModal("filterModal")}
        sortSuccess={filterSuccess || searchSuccess}
        refreshFunction={() => getProductHistory(1, 10, id)}
        component1={
          <ProductSummary
            state={state}
            setState={setState}
            disabled={state.disableEdit}
            getProductSuccess={getProductSuccess}
            productCategories={productCategories}
            isEditing={isEditing}
            editSuccess={editSuccess}
            isGetting={isGetting}
            getSuccess={getSuccess}
            product={product}
            getProductCategory={getProductCategory}
            getSingleProduct={getSingleProduct}
            editProduct={editProduct}
            data={data}
            id={id}
          />
        }
        component2={
          <ProductHistory
            data={data}
            id={id}
            isGettingHistory={isGettingHistory}
            productHistory={productHistory}
            productHistoryData={productHistoryData}
            getHistorySuccess={getHistorySuccess}
            isSearching={isSearching}
            searchedProducts={searchedProducts}
            searchedProductsData={searchedProductsData}
            searchSuccess={searchSuccess}
            getProductHistory={getProductHistory}
            filterProductHistory={filterProductHistory}
            searchProductHistory={searchProductHistory}
            isFiltering={isFiltering}
            filteredProducts={filteredProducts}
            filteredProductsData={filteredProductsData}
            filterSuccess={filterSuccess}
            state={state}
          />
        }
      />

      <FilterProductHistory
        displayModal={state.filterModal}
        closeModal={() => closeModal("filterModal")}
        isFiltering={isFiltering}
        filterSuccess={filterSuccess}
        filterProductHistory={filterProductHistory}
        setFilterState={setState}
        filterState={state}
        id={id}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  isGettingProductCategory: state.productcategory.isGetting,
  getProductSuccess: state.productcategory.getSuccess,
  productCategories: state.productcategory.productCategories,
  isEditing: state.products.isEditing,
  editSuccess: state.products.editSuccess,
  isGetting: state.products.isGetting,
  getSuccess: state.products.success,
  product: state.products.singleProduct,
  isGettingCategory: state.appointment.isGettingCategories,
  getCategoriesSuccess: state.appointment.getCategorySuccess,
  categories: state.appointment.categories,
  isGettingHistory: state.products.isGettingHistory,
  productHistory: state.products.productHistory,
  productHistoryData: state.products.productHistoryData,
  getHistorySuccess: state.products.getHistorySuccess,
  isFiltering: state.products.isFiltering,
  filteredProducts: state.products.filteredProducts,
  filteredProductsData: state.products.filteredProductsData,
  filterSuccess: state.products.filterSuccess,
  isSearching: state.products.isSearching,
  searchedProducts: state.products.searchedProducts,
  searchedProductsData: state.products.searchedProductsData,
  searchSuccess: state.products.searchSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getProductCategory: () => dispatch(getProductCategories()),
  getSingleProduct: (id) => dispatch(getSingleProduct(id)),
  editProduct: (id, payload) => dispatch(editProduct(id, payload)),
  getCategory: () => dispatch(getAppointmentCategories()),
  getProductHistory: (page, limit, id) =>
    dispatch(getProductHistory(page, limit, id)),
  filterProductHistory: (page, limit, payload) =>
    dispatch(filterProductHistory(page, limit, payload)),
  searchProductHistory: (page, limit, payload) =>
    dispatch(searchProductHistory(page, limit, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleProduct);
