import React, { useState } from "react";
import SwitchWrapper from "../../Wrappers/SwitchWrapper";
import Appointment from "../Appointment";
import Patients from "../Patients";

export const WhispaDoctorRight = (props) => {
  const { data, patients, reroutedPatients } = props;

  const [state, setState] = useState({
    active1: true,
    active2: false,
  });
  return (
    <div>
      <div className="info">
        <div className="info-top">
          <h4>Recents Appointments</h4>
        </div>
        <Appointment data={data} />
      </div>
      <div className="info">
        <h4>New Rerouted Patients and Patients</h4>
        <SwitchWrapper
          title1={"Patients"}
          title2={"Rerouted Patients"}
          state={state}
          setstate={setState}
          component1={<Patients data={patients} type={"patients"} />}
          component2={
            <Patients data={reroutedPatients} type={"rerouted patients"} />
          }
        />
      </div>
    </div>
  );
};
