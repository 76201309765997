import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import Modal, { ModalButton } from "../../Modal/Modal";
import Input from "../../Input/Input";
import {
  AddClientSchema,
  AddUserCommonSchema,
} from "../../../utils/validation/validation";
import SelectInput from "../../Input/Select";
import { connect } from "react-redux";
import { createUser } from "../../../redux/Actions/UsersActions";

const AddClient = (props) => {
  const { getUsers, displayModal, closeModal, createClient, isCreating, createSuccess } =
    props;

  const [state, setState] = useState({
    customer: "",
  });
  const [gender, setGender] = useState("");

  useEffect(() => {
    if (createSuccess) {
      document.getElementById("addClient").reset();
      getUsers(1, 10, "customer", false)
    }
    closeModal()
  }, [createSuccess]);

  const options = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const updateSelection = (data) => {
    setGender(data.value);
  };

  const initialValues = {
    first_name: "",
    last_name: "",
    username: "",
    gender: "",
    email: "",
    phone_number: "",
    full_address: "",
    // state: "",
  };

  const submitValues = (values) => {
    const payload = {
      first_name: values.first_name,
      last_name: values.last_name,
      username: values.username,
      gender: gender,
      email: values.email,
      phone_number: values.phone_number,
      full_address: values.full_address,
      // state: values.state,
    };

    // Make API Call Now
    console.log("customer-details", payload);
    createClient("customer", payload);
  };

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Add New Customer"}
      width={"568px"}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => submitValues(values)}
        validationSchema={AddUserCommonSchema}
      >
        {({ setFieldValue, isValid, dirty, }) => {
          return (
            <Form id="addClient">
              <div className="form-grid3">
                <Field
                  name="first_name"
                  label="First Name"
                  placeholder="Enter first name"
                  component={Input}
                  emptyValue={setFieldValue}
                />
                <Field
                  name="last_name"
                  label="Last Name"
                  placeholder="Enter last name"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>
              <div className="form-grid2">
                <Field
                  name="email"
                  label="Email"
                  placeholder="Enter email address"
                  component={Input}
                  emptyValue={setFieldValue}
                />
                <Field
                  name="username"
                  label="User Name"
                  placeholder="Enter user name"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>
              <div className="form-grid2">
                <Field
                  name="phone_number"
                  label="Phone Number"
                  placeholder="Enter phone number"
                  component={Input}
                  emptyValue={setFieldValue}
                />
                <SelectInput
                  label={"Gender"}
                  options={options}
                  onChange={(data) => updateSelection(data)}
                />
              </div>
              <div className="form-grid2">
                <Field
                  name="full_address"
                  label="Full Address"
                  placeholder="Enter full address"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>

              <ModalButton
                text={"Create Customer"}
                type={"submit"}
                testId={"button"}
                loadingText={"Creating..."}
                isLoading={isCreating}
                disabled={isCreating || !(isValid && dirty)}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createClient: (type, payload) => dispatch(createUser(type, payload)),
});
const mapStateToProps = (state) => ({
  isCreating: state.users.isCreating,
  createSuccess: state.users.createSuccess,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddClient);
