import React, { useEffect, useState } from "react";
import { ModalButton } from "../Modal/Modal";
import { Field } from "formik";
import Input from "../Input/Input";
import SelectInput from "../Input/Select";
import { assignCategoryOptions } from "../../utils/functions/UpdateValues";

const PlanInputs = (props) => {
  const {
    state,
    setState,
    text,
    loadingText,
    defaultOption,
    defaultOption2,
    disabled,
    isLoading,
    setFieldValue,
    actionType,
    enterpriseData,
    enterpriseId
  } = props;

  const [entepriseOptions, setEnterpriseOptions] = useState([]);

  useEffect(() => {
    if (enterpriseData.length > 0) {
      const options = assignCategoryOptions(enterpriseData);
      options.unshift({
        value: "",
        label: "No Option",
      });
      setEnterpriseOptions(options);
    }
  }, [enterpriseData]);

  const options = [
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
    { value: "Biannually", label: "Biannually" },
    { value: "Yearly", label: "Yearly" },
  ];

  const options2 = [
    { value: true, label: "True" },
    { value: false, label: "False" },
  ];

  const updateSelection = (data, type) => {
    setState({
      ...state,
      [type]: data.value,
    });
  };

  const addNewBenefit = () => {
    setState({
      ...state,
      benefits: state.benefits.concat([""]),
    });
  };

  const updateBenefit = (index) => (event) => {
    const newBenefits = state.benefits.map((benefit, idx) => {
      if (index !== idx) return benefit;
      return event.target.value;
    });
    setState({ ...state, benefits: newBenefits });
  };

  const getOption = (id) => {
    if (id) {
      const option = entepriseOptions.filter((el) => el.value === id);
      return option && option[0].label;
    }
  };

  return (
    <>
      <h2 className="payment-modal-header">General Info</h2>
      <div className="form-grid3 top-spacer">
        <Field
          name="name"
          label="Name"
          placeholder="Enter name"
          component={Input}
          emptyValue={setFieldValue}
        />

        <Field
          name="amount"
          label="Amount"
          placeholder="Enter amount"
          component={Input}
          emptyValue={setFieldValue}
        />
      </div>

      <div className="form-grid3 top-spacer">
        <Field
          name="caption"
          label="Caption"
          placeholder="Enter caption"
          component={Input}
          emptyValue={setFieldValue}
        />

        <SelectInput
          label={"Plan Validity"}
          options={options}
          defaultOption={defaultOption}
          onChange={(data) => updateSelection(data, "plan_validity")}
        />
      </div>

      <div className="form-grid3 top-spacer">
        <Field
          name="tag"
          label="Tag"
          placeholder="Enter tag"
          component={Input}
          emptyValue={setFieldValue}
        />

        <SelectInput
          label={"Unlimited Chats"}
          options={options2}
          defaultOption={defaultOption2}
          onChange={(data) => updateSelection(data, "unlimited_chat")}
        />
      </div>

      <div className="form-grid3 top-spacer">
        {actionType === "create" && state.plan_validity !== "Weekly" && (
          <SelectInput
            label={"Auto Renew"}
            options={options2}
            onChange={(data) => updateSelection(data, "auto_renew")}
          />
        )}
        <SelectInput
          label={"Select Enterprise"}
          options={entepriseOptions}
          onChange={(data) => setState({ ...state, enterpriseId: data.value })}
          defaultOption={enterpriseId && entepriseOptions.length  > 0 ? getOption(enterpriseId) : ""}
        />
      </div>

      <div className="payment-modal">
        <h2 className="payment-modal-header">Plan Benefits</h2>
        <div className="payment-modal-add-more">
          <span onClick={addNewBenefit}>+ Add More</span>
        </div>
        <div className="form-grid3">
          {state.benefits &&
            state.benefits.map((benefit, index) => (
              <Input
                key={index}
                value={benefit}
                onChange={updateBenefit(index)}
                label={`Benefit ${index + 1}`}
                placeholder="Enter benefit"
                emptyValue={setFieldValue}
              />
            ))}
        </div>
      </div>

      <ModalButton
        isLoading={isLoading}
        disabled={disabled}
        text={text}
        testId={"button"}
        loadingText={loadingText}
        type={"submit"}
      />
    </>
  );
};

export default PlanInputs;
