import React, { useEffect, useState } from "react";
import {
  SuccessBtn,
  DropBtn,
  TableDropdown,
  InfoBtn,
} from "../../../Tables/TableButtons";
import { cancelModal, viewModal } from "../../../Modal/Modal";
import { useHistory } from "react-router-dom";
import DeleteWhispaDoctor from "../../../UserManagement/WhispaDoctors/DeleteWhispaDoctor";
import ActivateWhispaDoctor from "../../../UserManagement/WhispaDoctors/ActivateWhispaDoctor";
import SuspendWhispaDoctor from "../../../UserManagement/WhispaDoctors/SuspendWhispaDoctor";
import Table from "../../../Tables/Table";
import { filterOptionsLabel } from "../../../../utils/functions/FilterObject";
import truncateName from "../../../../utils/functions/ShortenStr";
import { connect } from "react-redux";
import { getUsers } from "../../../../redux/Actions/UsersActions";

const WhispaDoctorColumns = (props) => {
  const {
    state,
    setState,
    data,
    getData,
    getMoreData,
    isLoading,
    success,
    sortSucces,
    total,
    deleteSuccess,
    revokeSuccess,
    createSuccess,
  } = props;

  useEffect(() => {
    if (deleteSuccess || revokeSuccess || createSuccess) {
      getData(1, 10);
    }
  }, [deleteSuccess, revokeSuccess, createSuccess]);

  const whispaDoctorColumns = [
    {
      Header: "Full Name",
      accessor: "full_name",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <span style={{ textTransform: "capitalize" }}>
            Dr. {row.original.full_name}
          </span>
        </div>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ row }) => (
        <div className="table-flex">
          <span>{truncateName(row.original.email, 15) || "-"}</span>
        </div>
      ),
    },
    {
      Header: "Registration No.",
      accessor: "registration_no",
      Cell: ({ row }) => (
        <span>
          {row.original?.registration_no === null
            ? "Nil"
            : row.original?.registration_no}
        </span>
      ),
    },
    {
      Header: "Specialization",
      accessor: "specialization",
      Cell: ({ row }) => (
        <span>
          {row.original?.specialization === null
            ? "Nil"
            : row.original?.specialization}
        </span>
      ),
    },
    {
      Header: "Sex",
      accessor: "gender",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <span style={{ textTransform: "capitalize" }}>
            {row.original && row.original.gender}
          </span>
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        const id = row.original && row.original.id;
        const status = row.original && row.original.status;
        const [show, setShow] = useState(false);

        const history = useHistory();

        const [state, setState] = useState({
          activateModal: false,
          suspendModal: false,
          deleteModal: false,
          deliveredModal: false,
          canceledModal: false,
        });

        const openModal = (type) => {
          viewModal(type, state, setState);
          setShow((prevCheck) => !prevCheck);
        };

        const closeModal = (type) => {
          cancelModal(type, state, setState);
        };

        const goToSinglePage = () => {
          history.push({
            pathname: `/users/whispa-doctors/${id}`,
            state: {
              id: id,
              data: row.original,
            },
          });
        };

        // Newly Added 
        const goToEditPage = () => {
          history.push({
            pathname: `/users/whispa-doctors/edit/${id}`,
            state: {
              id: id,
              data: row.original,
            },
          });
        };

        const options = [
          {
            name: "View",
            action: () => goToSinglePage(),
          },
          {
            name: "Activate",
            label: "Active",
            action: () => openModal("activateModal"),
          },
          {
            name: "Suspend",
            label: "Suspended",
            action: () => openModal("suspendModal"),
          },
          {
            name: "Edit",
            label: "Edit",
            action: () => goToEditPage(),
          },
          {
            name: "Delete",
            action: () => openModal("deleteModal"),
          },
        ];

        return (
          <div className="action-flex">
            {row.original.status === "active" ? (
              <SuccessBtn text={"Active"} />
            ) : row.original.status === "suspended" ? (
              <InfoBtn text={"Suspended"} />
            ) : (
              "Nil"
            )}
            <DropBtn onClick={() => setShow((prevCheck) => !prevCheck)} />
            {show && (
              <TableDropdown
                options={options && filterOptionsLabel(status, options)}
              />
            )}

            {state.activateModal && (
              <ActivateWhispaDoctor
                displayModal={state.activateModal}
                closeModal={() => closeModal("activateModal")}
                id={id}
              />
            )}

            {state.suspendModal && (
              <SuspendWhispaDoctor
                displayModal={state.suspendModal}
                closeModal={() => closeModal("suspendModal")}
                id={id}
              />
            )}

            {state.deleteModal && (
              <DeleteWhispaDoctor
                displayModal={state.deleteModal}
                closeModal={() => closeModal("deleteModal")}
                id={id}
              />
            )}
          </div>
        );
      },
    },
  ];
  return (
    <div className="orders-table">
      <Table
        state={state}
        setState={setState}
        columns={whispaDoctorColumns}
        data={data}
        getData={getData}
        getMoreData={getMoreData}
        isLoading={isLoading}
        success={success}
        sortSucces={sortSucces}
        total={total}
        emptyText1={"You haven't added any Whispa doctor"}
        emptyResultText={"Whispa doctor not found!"}
        className={"table7"}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isDeleting: state.users.isDeleting,
  deleteSuccess: state.users.deleteSuccess,
  revokeSuccess: state.users.revokeSuccess,
  createSuccess: state.users.createSuccess,
});

export default connect(
  mapStateToProps,
  null
)(WhispaDoctorColumns);
