import PropTypes from "prop-types";
import React from "react";

export const Pagination = (props) => {
  const { getData, pageOptions, pageIndex, setPage } = props;

  const filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter((page) => page <= totalPages);
  }; 

  const getVisiblePages = (page, total) => {
    if (total < 7) {
      return filterPages([1, 2, 3, 4, 5, 6], total); 
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  const visiblePages = getVisiblePages(pageIndex, pageOptions);

  return (
    <div className="table-pagination">
      <div className="table-pagination-left">
        Showing Page {pageIndex} of {pageOptions}
      </div>
      <div className="table-pagination-right">
        <button
          className="normalPage"
          onClick={() => {
            setPage(pageIndex - 1);
            getData(pageIndex - 1);
          }}
          disabled={pageIndex === 1}
          data-testid="double-backward"
        >
          {"<<"}
        </button>
        <button
          className="normalPage"
          onClick={() => {
            setPage(pageIndex - 1);
            getData(pageIndex - 1);
          }}
          disabled={pageIndex === 1}
          data-testid="normal-backward"
        >
          {"<"}
        </button>
        {visiblePages.map((option, index) => (
          <div
            key={index}
            onClick={() => {
              setPage(option);
              getData(option);
            }}
            data-testid={`view-page${index}`}
            className={pageIndex === option ? "activePage" : "normalPage"}
          >
            {option}
          </div>
        ))}
        <button
          className="normalPage"
          onClick={() => {
            setPage(pageIndex + 1);
            getData(pageIndex + 1);
          }}
          disabled={pageIndex === pageOptions}
          data-testid="normal-forward"
        >
          {">"}
        </button>
        <button
          className="normalPage"
          onClick={() => {
            setPage(pageIndex + 2);
            getData(pageIndex + 2);
          }}
          disabled={pageIndex === pageOptions}
          data-testid="double-forward"
        >
          {">>"}
        </button>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  getData: PropTypes.func,
  pageIndex: PropTypes.number,
  pageOptions: PropTypes.any,
  setPage: PropTypes.func,
};
