import "date-fns";
import React, { useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import moment from "moment";

const TimePicker = ({
  state,
  setState,
  label,
  name,
  type2,
  setTime,
  displayModal,
  // setOnMount = false,
}) => {
  const [selectedTime, setSelectedTime] = React.useState();

  const updateTime = (time) => {
    setSelectedTime(time);
    setState({
      ...state,
      [name]: moment(time).format(),
    });
  };

  const handleDateChange = (currentTime) => {
    updateTime(currentTime);
  };

  useEffect(
    () => {
      updateTime(setTime);
    }, // eslint-disable-next-line
    [displayModal]
  );

  return (
    <div className="date-wrapper">
      <p className={type2 ? "filter-label" : "label2"}>{label}</p>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
          margin="normal"
          id="time-picker"
          label="Time picker"
          value={selectedTime}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change time",
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default TimePicker;
