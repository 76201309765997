import React from "react";
import { AuthButton } from "../Button/Button";
import { Field, Form, Formik } from "formik";
import Input from "../Input/Input";
import { UpdatePasswordSchema } from "../../utils/validation/validation";

const ChangePassword = (props) => {
  const { initialValues2, submitValues, disabled, isLoading } = props;
  return (
    <Formik
      initialValues={initialValues2}
      onSubmit={(values) => submitValues(values)}
      validationSchema={UpdatePasswordSchema}
    >
      {({ isValid, dirty }) => {
        const disableBtn = disabled || !(isValid && dirty) || isLoading;
        return (
          <Form id="changePasswordForm">
            <div className="user-profile-top2">
              <div className="user-profile-top2-left">Change Password</div>
            </div>

            <div className="user-profile-bottom">
              <div className="user-profile-bottom-left">
                <div className="user-profile-bottom-left-form2">
                  <div className="user-profile-bottom-left-form-flex">
                    <Field
                      name="old_password"
                      label="Old Password"
                      placeholder="Enter old password"
                      type="password"
                      component={Input}
                    />

                    <Field
                      name="new_password"
                      label="New Password"
                      placeholder="Enter new password"
                      type="password"
                      component={Input}
                    />

                    <Field
                      name="confirm_password"
                      label="Confirm new Password"
                      type="password"
                      placeholder="Confirm password"
                      component={Input}
                    />
                  </div>
                </div>
              </div>

              <div className="user-profile-bottom-right2 profile-spacer">
                <AuthButton
                  children={"Save"}
                  background={
                    disableBtn ? "rgba(164, 167, 183, 0.4)" : "#0A74DC"
                  }
                  width={"167px"}
                  color={"#FFFFFF"}
                  type={"submit"}
                  marginTop={"0px"}
                  isLoading={isLoading}
                  disabled={disableBtn}
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangePassword;
