import React, { useEffect, useState } from "react";
import BackBtn from "../../components/Input/BackBtn";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import CreateEnterpriseInput from "../../components/Enterprise/CreateEnterprise";
import { createEnterprise } from "../../redux/Actions/EnterpriseActions";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
const CreateEnterprise = (props) => {
  const { createEnterpriseData, createEnterpriseSuccess } = props;
  const history = useHistory();

  const [state, setState] = useState({
    file_name: "",
    loaded: false,
    enterprise_facilities: [
      {
        facility_id: "",
        default: false,
      },
    ],
    services: [
      {
        enterprise_service_type: "",
        amount: null,
        model_id: null,
        available: false,
        free_if_recommended: false,
        free_budget_per_user: "",
      },
    ],
  });

  useEffect(() => {
    if (createEnterpriseSuccess) {
      history.goBack();
    }
  }, [createEnterpriseSuccess]);

  const initialValues = {
    name: "",
    domain: "",
    amount: "",
    free_budget_per_user: "",
  };
  const submitValues = (values) => {
    const payload = {
      name: values.name,
      domain: values.domain,
      enterprise_services: state.services,
      enterprise_facilities: state.enterprise_facilities,
      logo_url: state.file_name,
    };
    console.log(payload)
    createEnterpriseData(payload);
  };
  return (
    <div className="">
      <BackBtn page={"/enterprise"} />;
      <HeaderWrapper title={"Create Enterprise"} />
      <div className="assessment-manager-single-container">
        <CreateEnterpriseInput
          submitValues={submitValues}
          initialValues={initialValues}
          state={state}
          setState={setState}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  createEnterpriseSuccess: state.enterprise.isCreatingSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  createEnterpriseData: (payload) => dispatch(createEnterprise(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEnterprise);
