import Actions from '../Types';

const initialState = {
  isGetting: false,
  productCategories: [],
  productCategoriesData: {},
  getSuccess: false,
  isSearching: false,
  searchedProductCategories: [],
  searchedProductCategoriesData: {},
  searchSuccess: false,
  isCreating: false,
  productCategory: {},
  success: false,
  isEditing: false,
  editSuccess: false,
  isDeleting: false,
  deleteSuccess: false,
  error: null,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_PRODUCT_CATEGORIES_START:
      return {
        ...state,
        isGetting: true,
        productCategories: [],
        productCategoriesData: {},
        searchSuccess: false,
        getSuccess: false,
        error: null,
      };
    case Actions.GET_PRODUCT_CATEGORIES_SUCCESS:
      return {
        ...state,
        isGetting: false,
        productCategories: action.payload,
        productCategoriesData: action.payload,
        searchSuccess: false,
        deleteSuccess: false,
        editSuccess: false,
        getSuccess: true,
        error: null,
      };
    case Actions.GET_PRODUCT_CATEGORIES_FAILURE:
      return {
        ...state,
        isGetting: false,
        productCategories: [],
        productCategoriesData: {},
        searchSuccess: false,
        getSuccess: false,
        error: action.payload,
      };
    case Actions.CREATE_PRODUCT_CATEGORY_START:
      return {
        ...state,
        isCreating: true,
        productCategory: {},
        error: null,
        success: false,
      };
    case Actions.CREATE_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        isCreating: false,
        productCategory: action.payload,
        error: null,
        success: true,
      };
    case Actions.CREATE_PRODUCT_CATEGORY_FAILURE:
      return {
        ...state,
        isCreating: false,
        productCategory: {},
        error: action.payload,
        success: false,
      };
    case Actions.SEARCH_PRODUCT_CATEGORIES_START:
      return {
        ...state,
        issearching: true,
        searchedProductCategories: [],
        searchedProductCategoriesData: {},
        searchSuccess: false,
        error: null,
      };
    case Actions.SEARCH_PRODUCT_CATEGORIES_SUCCESS:
      return {
        ...state,
        isSearching: false,
        searchedProductCategories: action.payload && action.payload.data,
        searchedProductCategoriesData: action.payload,
        searchSuccess: true,
        error: null,
      };
    case Actions.SEARCH_PRODUCT_CATEGORIES_FAILURE:
      return {
        ...state,
        isSearching: false,
        searchedProductCategories: [],
        searchedProductCategoriesData: {},
        searchSuccess: false,
        error: action.payload,
      };
    case Actions.EDIT_PRODUCT_CATEGORY_START:
      return {
        ...state,
        isEditing: true,
        productCategory: {},
        editSuccess: false,
        error: null,
      };
    case Actions.EDIT_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        isEditing: false,
        productCategory: action.payload,
        editSuccess: true,
        error: null,
      };
    case Actions.EDIT_PRODUCT_CATEGORY_FAILURE:
      return {
        ...state,
        isEditing: false,
        productCategory: null,
        editSuccess: false,
        error: action.payload,
      };
    case Actions.DELETE_PRODUCT_CATEGORY_START:
      return {
        ...state,
        isDeleting: true,
        deleteSuccess: false,
        productCategory: null,
        error: null,
      };
    case Actions.DELETE_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        productCategory: action.payload,
        deleteSuccess: true,
        error: null,
      };
    case Actions.DELETE_PRODUCT_CATEGORY_FAILURE:
      return {
        ...state,
        isDeleting: false,
        productCategory: null,
        deleteSuccess: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reduxState;
