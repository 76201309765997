import React from "react";

const Terms = () => {
  const embeddedFile = process.env.REACT_APP_WHISPA_TERMS_OF_SERVICES_FILE;
  return (
    <div className="terms-of-service">
      <iframe
        src={embeddedFile}
        width="100%"
        height="100%"
        padding="30"
        allow="autoplay"
        title="Terms of Service"
      ></iframe>
    </div>
  );
};

export default Terms;
