import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";
import { setNewData } from "../../components/AppointmentManager/Assessment/Assessment";

// GET ASSESSMENTS
export const getAssessmentsStart = () => ({
  type: Actions.GET_ASSESSMENTS_START,
});

export const getAssessmentsSuccess = (payload) => ({
  type: Actions.GET_ASSESSMENTS_SUCCESS,
  payload,
});

export const getAssessmentsFailure = () => ({
  type: Actions.GET_ASSESSMENTS_FAILURE,
});

// CREATE ASSESSMENTS
export const createQuestionStart = () => ({
  type: Actions.CREATE_QUESTION_START,
});

export const createQuestionSuccess = (payload) => ({
  type: Actions.CREATE_QUESTION_SUCCESS,
  payload,
});

export const createQuestionFailure = (payload) => ({
  type: Actions.CREATE_QUESTION_FAILURE,
  payload,
});

// GET QUESTION
export const getQuestionStart = () => ({
  type: Actions.GET_QUESTION_START,
});

export const getQuestionSuccess = (payload) => ({
  type: Actions.GET_QUESTION_SUCCESS,
  payload,
});

export const getQuestionFailure = (payload) => ({
  type: Actions.GET_QUESTION_FAILURE,
  payload,
});

// EDIT QUESTION
export const editQuestionStart = () => ({
  type: Actions.EDIT_QUESTION_START,
});

export const editQuestionSuccess = (payload) => ({
  type: Actions.EDIT_QUESTION_SUCCESS,
  payload,
});

export const editQuestionFailure = (payload) => ({
  type: Actions.EDIT_QUESTION_FAILURE,
  payload,
});

// UPDATE STATUS
export const updateAssessmentStart = () => ({
  type: Actions.UPDATE_ASSESSMENT_START,
});

export const updateAssessmentSuccess = (payload) => ({
  type: Actions.UPDATE_ASSESSMENT_SUCCESS,
  payload,
});

export const updateAssessmentFailure = (payload) => ({
  type: Actions.UPDATE_ASSESSMENT_FAILURE,
  payload,
});

// DELETE ASSESSMENT
export const deleteAssessmentStart = () => ({
  type: Actions.DELETE_ASSESSMENT_START,
});

export const deleteAssessmentSuccess = () => ({
  type: Actions.DELETE_ASSESSMENT_SUCCESS,
});

export const deleteAssessmentFailure = () => ({
  type: Actions.DELETE_ASSESSMENT_FAILURE,
});

// FILTER ASSESSMENT BY SUBCATEGORY
export const filterAssessmentByCategoryStart = () => ({
  type: Actions.FILTER_ASSESSMENT_BY_SUBCATEGORY_START,
});

export const filterAssessmentByCategorySuccess = (payload) => ({
  type: Actions.FILTER_ASSESSMENT_BY_SUBCATEGORY_SUCCESS,
  payload,
});

export const filterAssessmentByCategoryFailure = (payload) => ({
  type: Actions.FILTER_ASSESSMENT_BY_SUBCATEGORY_FAILURE,
  payload,
});

// REORDER ASSESSMENT
export const reorderAssessmentStart = () => ({
  type: Actions.REORDER_ASSESSMENT_START,
});

export const reorderAssessmentSuccess = (payload) => ({
  type: Actions.REORDER_ASSESSMENT_SUCCESS,
  payload,
});

export const reorderAssessmentFailure = (payload) => ({
  type: Actions.REORDER_ASSESSMENT_FAILURE,
  payload,
});

export const filterAssessmentFailure = () => ({
  type: Actions.FILTER_ASSESSMENT_FAILURE,
});

export const getAssessments = (queries, setData) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getAssessmentsStart());
  let url = `/assessment${queries.extraUrlString ?? ""}?`;

  Object.entries(queries).forEach(([key, value]) => {
    key !== "extraUrlString" && (url += `${key}=${value}&`);
  });

  API.get(url).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(
        getAssessmentsSuccess({
          data: data.data,
          // This value is used to know whether to close the modal or not
          filter: !!queries.extraUrlString,
        })
      );
      if (!data.data.rows.length) {
        // If you are filtering...
        if (queries.extraUrlString) {
          responseHandler(
            { problem: "CLIENT_ERROR", data: "No match found" },
            "Error!"
          );
          dispatch(filterAssessmentFailure());
        }
        return;
      }
      setData(setNewData(data.data?.rows));
    } else {
      dispatch(getAssessmentsFailure());
    }
  });
};

export const createQuestion = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(createQuestionStart());
  API.post("/assessment", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(createQuestionSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(createQuestionFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const updateAssessmentStatus = (status, id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(updateAssessmentStart());
  API.patch(`/assessment/change-status/${id}?status=${status}`).then(
    (response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(updateAssessmentSuccess(data && data.data));
        responseHandler(response, "Success!");
      } else {
        dispatch(updateAssessmentFailure(data));
        responseHandler(response, "Failed!");
      }
    }
  );
};

export const deleteAssessment = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(deleteAssessmentStart());
  API.delete(`/assessment/${id}`).then((response) => {
    const { ok } = response;
    if (ok) {
      dispatch(deleteAssessmentSuccess());
      responseHandler(response, "Success!");
    } else {
      dispatch(deleteAssessmentFailure());
      responseHandler(response, "Failed!");
    }
  });
};

export const reorderAssessment = (payload, id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  dispatch(reorderAssessmentStart());

  API.patch(`/assessment/reorder/${id}`, payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(reorderAssessmentSuccess(data && data.data));
    } else {
      dispatch(reorderAssessmentFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const fetchSingleQuestion = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getQuestionStart());
  API.get(`/assessment/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getQuestionSuccess(data && data.data));
    } else {
      dispatch(getQuestionFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const editSingleQuestion = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  const { question, id } = payload;
  dispatch(editQuestionStart());
  API.patch(`/assessment/${id}`, {
    ...question,
    type: undefined,
    category_id: undefined,
    categories: undefined,
  }).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(editQuestionSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(editQuestionFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const clearQuestion = () => (dispatch) => {
  dispatch({ type: Actions.RESET_QUESTION });
};

export const filterAssessmentByCategory = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(filterAssessmentByCategoryStart());
  API.get(`/assessment/subcategory/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(filterAssessmentByCategorySuccess(data && data.data));
    } else {
      dispatch(filterAssessmentByCategoryFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};
