import React, { useEffect } from "react";
import { connect } from "react-redux";
import { deleteUser, getUsers } from "../../../redux/Actions/UsersActions";
import DeleteModal from "../../Modal/DeleteModal";

const DeleteHfcp = (props) => {
  const { deleteUser, isDeleting, id, deleteSuccess, getUsers } = props;

  useEffect(
    () => {
      if (deleteSuccess) {
        props.closeModal();
        getUsers(1, 10, "hfcp");
      }
    }, // eslint-disable-next-line
    [deleteSuccess]
  );

  const handleDelete = (id) => {
    deleteUser(id);
  };
  return (
    <DeleteModal
      displayModal={props.displayModal}
      closeModal={props.closeModal}
      title={"Delete this HFCP?"}
      width={"463px"}
      text={"Delete"}
      onClick={() => handleDelete(id)}
      disabled={isDeleting}
      isLoading={isDeleting}
      loadingText={"Deleting..."}
      children={
        "Once you delete this HFCP it won’t be possible to undo this action. Delete HFCP?"
      }
    />
  );
};
const mapStateToProps = (state) => ({
  isDeleting: state.users.isDeleting,
  deleteSuccess: state.users.deleteSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  deleteUser: (id) => dispatch(deleteUser(id)),
  getUsers: (page, limit, type) => dispatch(getUsers(page, limit, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteHfcp);
