import React, { useState } from "react";
import { connect } from "react-redux";
import {
  getUserChatHistory,
  searchUserChatHistory,
} from "../../../redux/Actions/UsersActions";
import { PageButton } from "../../Button/Button";
import AllChats from "../../ChatManagement/AllChats";
import ChatList from "../../ChatManagement/ChatList";
import SingleChat from "../../ChatManagement/SingleChat";
import { cancelModal, viewModal } from "../../Modal/Modal";
import SwitchWrapper from "../../Wrappers/SwitchWrapper";
import ConsultantChats from "./ConsultantChat";
import DoctorChats from "./DoctorChat";
import RerouteClient from "./RerouteClient";

const ClientChats = (props) => {
  const {
    getUserChatHistory,
    searchUserChatHistory,
    isGetting,
    userChat,
    getSuccess,
    isSearching,
    searchedUserChat,
    searchSuccess,
  } = props;

  const [state, setState] = useState({
    searchValue: "",
    active1: true,
    active2: false,
    chatId: "",
    chat: "",
    reRouteModal: false,
    newDoctor: "",
  });

  const id = props?.location?.state;

  const openModal = (type) => viewModal(type, state, setState);
  const closeModal = (type) => cancelModal(type, state, setState);

  const showSingleClientChat = (chat) => {
    setState({
      ...state,
      chatId: chat && chat.id,
      chat: chat,
    });
  };

  const searchAllChats = () => {
    const checkType = state.active1 ? "consultant" : "doctor";

    if (state.searchValue === "") {
      return userChat;
    } else {
      searchUserChatHistory(id, checkType, state.searchValue);
    }
  };

  const getAllChats = () => {
    const checkType = state.active1 ? "consultant" : "doctor";
    setState({
      ...state,
      searchValue: "",
    });
    getUserChatHistory(id, checkType);
  };

  const allChats = searchSuccess ? searchedUserChat : userChat;

  return (
    <AllChats
      leftComponent={
        <ChatList
          subtitle={`View all new chats with Whispa ${
            state.active1 ? "Consultants" : "Doctors"
          }`}
          back={true}
          setState={setState}
          state={state}
          searchItems={searchAllChats}
          sortSuccess={searchSuccess}
          refreshFunction={getAllChats}
          switchWrapper={
            <SwitchWrapper
              title1={"Consultants"}
              title2={"Doctors"}
              state={state}
              setstate={setState}
              component1={
                <ConsultantChats
                  isLoading={isGetting || isSearching}
                  data={allChats}
                  setState={setState}
                  state={state}
                  showSingleChat={showSingleClientChat}
                  getChats={getUserChatHistory}
                  success={getSuccess || searchSuccess}
                  sortSuccess={searchSuccess}
                  status={"consultant"}
                  id={id}
                  openModal={openModal}
                />
              }
              component2={
                <DoctorChats
                  isLoading={isGetting || isSearching}
                  data={allChats}
                  setState={setState}
                  state={state}
                  showSingleChat={showSingleClientChat}
                  getChats={getUserChatHistory}
                  success={getSuccess || searchSuccess}
                  sortSuccess={searchSuccess}
                  status={"doctor"}
                  id={id}
                  openModal={openModal}
                />
              }
              margin={"chats-margin"}
              paddingLeft={"chats-padding"}
              minWidth={"chats-minWidth"}
            />
          }
        />
      }
      rightComponent={
        <>
          <SingleChat
            chat={state.chat}
            className={"chats-right-web"}
            component={
              <PageButton
                width={"156px"}
                background={"rgba(10, 116, 220, 0.08)"}
                marginTop={"0px"}
                onClick={() => openModal("reRouteModal")}
                type={"submit"}
                children={"Transfer Client"}
                testId={"pageButton"}
              />
            }
          />

          <RerouteClient
            displayModal={state.reRouteModal}
            closeModal={() => closeModal("reRouteModal")}
            setState={setState}
            state={state}
            id={state.chatId}
            getUserChatHistory={getUserChatHistory}
          />
        </>
      }
    ></AllChats>
  );
};

const mapStateToProps = (state) => ({
  isGetting: state.users.isGettingUserChat,
  userChat: state.users.userChat,
  getSuccess: state.users.getSuccessUserChat,
  isSearching: state.users.isSearchingUserChat,
  searchedUserChat: state.users.searchedUserChat,
  searchSuccess: state.users.searchSuccessUserChat,
});

const mapDispatchToProps = (dispatch) => ({
  getUserChatHistory: (id, type) => dispatch(getUserChatHistory(id, type)),
  searchUserChatHistory: (id, type, search) =>
    dispatch(searchUserChatHistory(id, type, search)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientChats);
