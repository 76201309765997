import React, { useEffect, useState } from "react";
import Modal, { ModalButton } from "../Modal/Modal";
import SelectInput from "../Input/Select";
import { Statuses } from "../Input/Statuses";
import { getAppointmentCategories } from "../../redux/Actions/AppointmentActions";
import { connect } from "react-redux";
import { assignCategoryOptions } from "../../utils/functions/UpdateValues";
import { states } from "./FacilityInputs";

const FilterFacility = (props) => {
  const {
    displayModal,
    closeModal,
    isFiltering,
    filterFacilities,
    getSuccess,
    categories,
    getCategory,
    filterSuccess,
    setFilterState,
    filterState,
  } = props;

  const [options, setOptions] = useState([]);

  useEffect(
    () => {
      getCategory();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (getSuccess) {
        const updatedCategory = assignCategoryOptions(categories);
        setOptions(updatedCategory);
      }
    },
    // eslint-disable-next-line
    [getSuccess]
  );

  const status = [{ name: "Available" }, { name: "Suspended" }];

  const [state, setState] = useState({
    category: "",
    location: "",
    active: false,
    status: "",
    index: "",
  });

  const updateSelection = (data, type) => {
    setState({
      ...state,
      [type]: data.label,
    });
  };

  const handleFilter = () => {
    const payload = {
      category_name: state.category,
      status: state.status === "Suspended" ? "suspended" : "active",
      location: state.location,
    };
    setFilterState({
      ...filterState,
      filterValues: payload,
    });
    filterFacilities(1, 10, payload);
  };

  const disabled =
    isFiltering ||
    state.status === "" ||
    state.category === "" ||
    state.location === "";

  useEffect(
    () => {
      if (filterSuccess) {
        closeModal();
        setState({
          ...state,
          category: "",
          location: "",
          active: false,
          status: "",
          index: "",
        });
      }
    },
    // eslint-disable-next-line
    [filterSuccess]
  );

  return (
    <div>
      <Modal
        displayModal={displayModal}
        closeModal={closeModal}
        title={"Filter Facility"}
        width={"496px"}
      >
        <SelectInput
          label={"Category"}
          options={options}
          onChange={(data) => updateSelection(data, "category")}
          success={filterSuccess}
        />

        <div className="top-spacer">
          <SelectInput
            label="Location"
            options={states}
            onChange={(data) => updateSelection(data, "location")}
            success={filterSuccess}
          />
        </div>

        <div className="top-spacer">
          <Statuses
            label={"Status"}
            state={state}
            options={status}
            setstate={setState}
            blue={false}
            numberPerRow={3}
          />
        </div>

        <ModalButton
          isLoading={isFiltering}
          text={"Apply Search"}
          testId={"button"}
          type={"submit"}
          loadingText={"Filtering..."}
          disabled={disabled}
          onClick={handleFilter}
        />
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => ({
  isGettingCategory: state.appointment.isGettingCategories,
  getSuccess: state.appointment.getCategorySuccess,
  categories: state.appointment.categories,
});

const mapDispatchToProps = (dispatch) => ({
  getCategory: () => dispatch(getAppointmentCategories()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterFacility);
