import React, { useEffect, useState } from "react";
import SortData from "../../components/Sorting/SortData";
import Table from "../../components/Tables/Table";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import { cancelModal, viewModal } from "../../components/Modal/Modal";
import { auditTrailColumns } from "../../components/TableData/AuditTrail/AuditTrail";
import FilterAuditTrail from "../../components/TableData/AuditTrail/FilterAuditTrail";
import { connect } from "react-redux";
import {
  filterAuditTrail,
  getAuditTrail,
  searchAuditTrail,
} from "../../redux/Actions/AuditTrailActions";
import { PageButton } from "../../components/Button/Button";
import TableDropdown from "../../components/Tables/Dropdown";
import ExportIcon from "../../assets/Union.svg";
import DropIcon from "../../assets/drop-export.svg";
import downloadCSV from "../../utils/functions/csvDownloader";
import moment from "moment";

const AuditTrail = (props) => {
  const {
    isGetting,
    getSuccess,
    getAuditTrail,
    auditTrail,
    auditTrailData,
    isSearching,
    searchSuccess,
    searchAuditTrail,
    searchedAuditTrail,
    searchedAuditTrailData,
    filterAuditTrail,
    isFiltering,
    filterSuccess,
    filteredAuditTrail,
    filteredAuditTrailData,
  } = props;

  const [show, setShow] = useState(false);
  const [state, setState] = useState({
    addModal: false,
    filterModal: false,
    searchValue: "",
    date: "",
    status: "",
    active: false,
    index: "",
  });

  const openModal = (type) => viewModal(type, state, setState);
  const closeModal = (type) => cancelModal(type, state, setState);

  const searchAllAuditTrail = () => {
    if (state.searchValue === "") {
      return auditTrail;
    } else {
      searchAuditTrail(1, 10, state.searchValue);
    }
  };

  const status = state.status.toLowerCase();

  const moreData = (page, perPage) => {
    searchSuccess
      ? searchAuditTrail(page, perPage, state.searchValue)
      : filterSuccess
      ? filterAuditTrail(page, perPage, state.date, status)
      : getAuditTrail(page, perPage);
  };

  useEffect(
    () => {
      if (getSuccess) {
        setState({
          ...state,
          searchValue: "",
        });
      }
    },
    // eslint-disable-next-line
    [getSuccess]
  );

  const allAuditTrail = searchSuccess
    ? searchedAuditTrail
    : filterSuccess
    ? filteredAuditTrail
    : auditTrail;

  const totalAuditTrail = searchSuccess
    ? searchedAuditTrailData && searchedAuditTrailData.total
    : filterSuccess
    ? filteredAuditTrailData && filteredAuditTrailData.total
    : auditTrailData && auditTrailData.total;

    const exportHandler = (format) => {
      switch (format) {
        case "CSV":
          const data = allAuditTrail
          const exportData =
            data &&
            data.map((audit) => ({
              Name: audit.first_name + " " + audit.last_name,
              Activities: audit.action,
              Date: moment(audit.created_at).format("ll"),
              Time: moment(audit.created_at).format("hh:mm a"),
              Status: audit.status,
            }));
          downloadCSV(exportData, "audit");
          return;
        default:
          return;
      }
    };
  
    const export_options = [
      {
        name: "CSV",
        action: () => exportHandler("CSV"),
      },
    ];

  return (
    <div>
      <HeaderWrapper
        title={"Audit Trail"}
        subtitle={"View all users activities on the board"}
      >
        <div className="overview-header-right-button">
          <PageButton
            width={"121px"}
            mainWidth={"144px"}
            background={"rgba(10, 116, 220, 0.08)"}
            // marginTop={"35px"}
            type={"button"}
            onClick={() => setShow((prevCheck) => !prevCheck)}
          >
            <div className="button-data">
              <img src={ExportIcon} alt="export icon" />
              <span className="span2">Export</span>
              <img src={DropIcon} alt="dropdown" />
            </div>
          </PageButton>
          {show && (
            <TableDropdown
              options={export_options}
              hideDropdown={() => setShow((prevCheck) => !prevCheck)}
              active
            />
          )}
        </div>
      </HeaderWrapper>
      <SortData
        placeholder={"Search trails"}
        width={"347px"}
        searchItems={searchAllAuditTrail}
        setState={setState}
        state={state}
        onClick={() => openModal("filterModal")}
        filter={true}
        sortSuccess={filterSuccess || searchSuccess}
        refreshFunction={() => getAuditTrail(1, 10)}
      />

      <Table
        columns={auditTrailColumns}
        data={allAuditTrail}
        getData={getAuditTrail}
        getMoreData={(page, perPage) => moreData(page, perPage)}
        emptyText1={"You haven't added any audit trail"}
        isLoading={isGetting || isSearching || isFiltering}
        success={getSuccess || filterSuccess || searchSuccess}
        sortSucces={searchSuccess || filterSuccess}
        total={totalAuditTrail}
        emptyResultText={"Audit Trail not found!"}
        className={"table8"}
      />
      <FilterAuditTrail
        displayModal={state.filterModal}
        closeModal={() => closeModal("filterModal")}
        state={state}
        setState={setState}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGetting: state.auditTrail.isGetting,
  getSuccess: state.auditTrail.getSuccess,
  auditTrail: state.auditTrail.auditTrail,
  auditTrailData: state.auditTrail.auditTrailData,
  isSearching: state.auditTrail.isSearching,
  isFiltering: state.auditTrail.isFiltering,
  searchSuccess: state.auditTrail.searchSuccess,
  searchedAuditTrail: state.auditTrail.searchedAuditTrail,
  searchedAuditTrailData: state.auditTrail.searchedAuditTrailData,
  filterSuccess: state.auditTrail.filterSuccess,
  filteredAuditTrail: state.auditTrail.filteredAuditTrail,
  filteredAuditTrailData: state.auditTrail.filteredAuditTrailData,
});

const mapDispatchToProps = (dispatch) => ({
  getAuditTrail: (page, limit) => dispatch(getAuditTrail(page, limit)),
  searchAuditTrail: (page, limit, search) =>
    dispatch(searchAuditTrail(page, limit, search)),
  filterAuditTrail: (page, limit, date, status) =>
    dispatch(filterAuditTrail(page, limit, date, status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuditTrail);
