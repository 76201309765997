import React from "react";
import Links from "./Links";
import logo from "../../assets/whispa-logo.svg";
import { NavLink } from "react-router-dom";
import { getAdminPermissions } from "../../utils/functions/CreateNewArray";
import Storage from "../../utils/services/storage";

const Sidebar2 = ({ setMenuChecked, menuChecked }) => {
  const adminDetails = Storage.get("admin-data");
  const currentAdmin = adminDetails && JSON.parse(adminDetails);

  const permissions = getAdminPermissions(currentAdmin);

  const handleVisibility = (data) => {
    const menu = permissions?.find(({ id }) => id === data?.role);
    if (menu?.checked) {
      return false;
    }
    return true;
  };

  return (
    <div
      className="sidebar2"
      style={{ display: menuChecked ? "block" : "none" }}
    >
      <div className="sidebar2-wrap">
        <div className="sidebar2-left">
          <div
            className="close"
            onClick={() => setMenuChecked(!menuChecked)}
          ></div>
        </div>
        {/* <div className="sidebar2-logo-section">
          <img src={logo} alt="logo" />
        </div> */}
      </div>
      <div className="sidebar-content">
        {Links.map((data, index) => {
          return (
            <div className="sidebar-content-list" key={index}>
              <h3 className="sidebar-content-items">{data.label}</h3>
              {data?.items?.map((subdata, index) => {
                return (
                  <NavLink
                    key={index}
                    to={`${subdata.name}`}
                    activeClassName="sidebar-content-isactive"
                    exact={subdata.label === "Overview" ? true : false} 
                    hidden={handleVisibility(subdata)}
                  >
                    <div
                      className="sidebar2-item"
                      onClick={() => setMenuChecked(!menuChecked)}
                    >
                      <div className="sidebar-content-icon">
                        <img src={subdata.icon} alt="pic" />
                      </div>
                      <span className="link-name">{subdata.label}</span>
                    </div>
                  </NavLink>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar2;
