import React, { useEffect, useState } from "react";
import { Statuses } from "../../Input/Statuses";
import Modal, { ModalButton } from "../../Modal/Modal";
import Input from "../../Input/Input";
import { Field, Form, Formik } from "formik";
import { filterCoupons } from "../../../redux/Actions/CouponsActions";
import { connect } from "react-redux";
import SelectInput from "../../Input/Select";
import { getAllProducts } from "../../../redux/Actions/ProductActions";
import {
  assignCategoryOptions,
  assignUpdatedOptions,
} from "../../../utils/functions/UpdateValues";

const FilterCoupon = (props) => {
  const {
    displayModal,
    closeModal,
    filterCoupons,
    isFiltering,
    filterSuccess,
    products,
    getSuccess,
    getProducts,
    prevstate,
    setPrevState,
  } = props;

  useEffect(
    () => {
      getProducts(1, 10, true);
    },
    // eslint-disable-next-line
    []
  );
  const [productOptions, setProductOptions] = useState([]);

  const updateSelection = (data) => {
    setState({
      ...state,
      products: data.label,
    });
  };

  const status = [{ name: "Active" }, { name: "Scheduled" }];

  const [state, setState] = useState({
    status: "",
    products: [],
  });

  const submitValues = (values) => {
    const payload = {
      amount: values && Number(values.amount),
      status: state.status.toLowerCase(),
      product: state.products,
    };
    setPrevState({ ...prevstate, filterValues: payload });

    filterCoupons(1, 10, payload.amount, payload.status, payload.product);
  };

  useEffect(
    () => {
      if (filterSuccess) {
        closeModal();
        document.getElementById("filterCoupon").reset();
        setState({
          ...state,
          products: [],
          active: false,
          status: "",
          index: "",
        });
      } else if (getSuccess) {
        const updatedOptions = state && assignCategoryOptions(products);
        const newUpdate =
          updatedOptions && assignUpdatedOptions(updatedOptions);
        setProductOptions(newUpdate);
      }
    },
    // eslint-disable-next-line
    [filterSuccess, getSuccess]
  );

  return (
    <div>
      <Modal
        displayModal={displayModal}
        closeModal={closeModal}
        title={"Filter Coupon"}
        width={"496px"}
      >
        <Formik
          initialValues={{ amount: "" }}
          onSubmit={(values) => submitValues(values)}
        >
          {({ isValid, dirty, setFieldValue }) => {
            return (
              <Form id="filterCoupon">
                <Field
                  type="number"
                  name="amount"
                  label="Amount"
                  placeholder="Enter amount"
                  component={Input}
                  emptyValue={setFieldValue}
                />

                <div className="form-grid">
                  <SelectInput
                    label={"Products"}
                    options={productOptions}
                    onChange={(data) => updateSelection(data)}
                    success={filterSuccess}
                  />
                </div>

                <div className="top-spacer">
                  <Statuses
                    label={"Status"}
                    state={state}
                    options={status}
                    setstate={setState}
                    blue={false}
                    numberPerRow={3}
                  />
                </div>

                <ModalButton
                  isLoading={isFiltering}
                  disabled={
                    isFiltering ||
                    state.status === "" ||
                    state.products.length === 0 ||
                    !(isValid && dirty)
                  }
                  text={"Apply Filter"}
                  type={"submit"}
                  testId={"button"}
                  loadingText={"Filtering..."}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isFiltering: state.coupons.isFilteringCoupons,
  filterSuccess: state.coupons.filterSuccessCoupons,
  getSuccess: state.products.getSuccess,
  products: state.products.allProducts,
});

const mapDispatchToProps = (dispatch) => ({
  filterCoupons: (page, limit, amount, status, product) =>
    dispatch(filterCoupons(page, limit, amount, status, product)),
  getProducts: (page, limit, all) => dispatch(getAllProducts(page, limit, all)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterCoupon);
