import React from "react";
import SingleChat from "./SingleChat";
import imageUrl from "../../assets/account_icon.svg";
import moment from "moment";
import { PageButton } from "../Button/Button";
import truncateName from "../../utils/functions/ShortenStr";

const ChatsGrid = (props) => {
  const { chats, showSingleChat, state, isLoading, openModal } = props;

  return (
    <div className="all-chats">
      <input type="checkbox" id="chat-check" />
      <SingleChat
        chat={state.chat}
        isLoading={isLoading}
        className={"chats-right-mobile"}
        component={
          <PageButton
            width={"156px"}
            background={"rgba(10, 116, 220, 0.08)"}
            marginTop={"0px"}
            testId="reRouteModal"
            onClick={() => openModal("reRouteModal")}
            type={"submit"}
            children={"Transfer Client"}
          />
        }
      />

      {chats &&
        chats.map((chat, index) => (
          <label htmlFor="chat-check" key={index}>
            <div
              className={`all-chats-single ${
                state.chatId === chat.id ? "all-chats-single2" : ""
              }`}
              key={index}
              data-testid="showSingleChat"
              onClick={() => showSingleChat(chat)}
            >
              <div className="all-chats-single-image">
                <img
                  src={
                    chat?.specialist?.image_url === null
                      ? imageUrl
                      : chat?.specialist?.image_url ||
                        chat?.user?.image_url === null
                      ? imageUrl
                      : chat?.user?.image_url
                  }
                  alt="profile pic"
                />
              </div>

              <div className="all-chats-single-text">
                <div className="all-chats-single-text-name">
                  {chat?.specialist?.full_name || chat?.user?.full_name}
                </div>
                <div className="all-chats-single-text-message">
                  {truncateName(chat.question, 30) || "-"}
                </div>
              </div>

              <div className="all-chats-single-figures">
                <div className="all-chats-single-figures-time">
                  {moment(chat.updated_at).format("HH:mm")}
                </div>
              </div>
            </div>
          </label>
        ))}
    </div>
  );
};

export default ChatsGrid;
