import React, { useEffect } from "react";
import { connect } from "react-redux";
import { deleteDeliveryFee } from "../../redux/Actions/DeliveryFeesActions";
import DeleteModal from "../Modal/DeleteModal";

const DeleteDeliveryFee = (props) => {
  const { deleteDeliveryFee, isDeleting, deleteSuccess, id } = props;

  useEffect(
    () => {
      if (deleteSuccess) {
        props.closeModal();
      }
    }, // eslint-disable-next-line
    [deleteSuccess]
  );

  const handleDelete = () => {
    deleteDeliveryFee(id);
  };
  return (
    <DeleteModal
      displayModal={props.displayModal}
      closeModal={props.closeModal}
      title={"Delete this state?"}
      width={"463px"}
      text={"Delete"}
      onClick={handleDelete}
      disabled={isDeleting}
      isLoading={isDeleting}
      loadingText={"Deleting..."}
      children={
        "Once you delete this state it won’t be possible to undo this action. Delete state?"
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isDeleting: state.deliveryFees.isDeleting,
  deleteSuccess: state.deliveryFees.deleteSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  deleteDeliveryFee: (id) => dispatch(deleteDeliveryFee(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDeliveryFee);
