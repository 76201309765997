import React from "react";
import { PropTypes } from "prop-types";
import Dropdown from "react-dropdown";
import Select from "react-select";
import "react-dropdown/style.css";

const SelectInput = ({
  options,
  defaultOption,
  label,
  onChange,
  readOnly,
  placeholder,
  success,
  isSearchable,
}) => {
  return (
    <div className="input-wrapper">
      <div className="input-container">
        {label && (
          <p style={{ zIndex: "3" }} className="label">
            {label}
          </p>
        )}
        {readOnly && <div className="input-disabled"></div>}
        {isSearchable ? (
          <Select
            placeholder={placeholder}
            onChange={onChange}
            defaultOption={defaultOption}
            options={options}
            isSearchable
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                padding: ".5rem .5rem",
              }),
            }}
          />
        ) : (
          <Dropdown
            options={options}
            isSearchable
            onChange={onChange}
            value={success ? "" : defaultOption}
            placeholder={placeholder ? placeholder : "Select an option"}
            disabled={readOnly}
            className={"react-dropdown"}
          />
        )}
      </div>
    </div>
  );
};

SelectInput.propTypes = {
  options: PropTypes.array,
  field: PropTypes.object,
  form: PropTypes.object,
  onChange: PropTypes.func,
  handleBlur: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default SelectInput;
