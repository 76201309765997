import PropTypes from "prop-types";
import React from "react";
import { Headers } from "./AuthWrapper";

export const HeaderWrapper = (props) => {
  const { count, children, text, title, subtitle } = props;
  return (
    <div className="page-headers">
      <div className="page-headers-top">
        <Headers className="auth-header2" title1={title} title2={subtitle} />
        <div>{children}</div>
      </div>

      {count && <div className="count">{text}</div>}
    </div>
  );
};

HeaderWrapper.propTypes = {
  children: PropTypes.any,
  count: PropTypes.any,
  subtitle: PropTypes.any,
  text: PropTypes.any,
  title: PropTypes.any,
};

export default HeaderWrapper;
