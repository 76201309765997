import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Add from "../../assets/add.svg";
import { PageButton } from "../../components/Button/Button";
import SortData from "../../components/Sorting/SortData";
import CreateDeliveryFee from "../../components/DeliveryFees/CreateDeliveryFee";
import { cancelModal, viewModal } from "../../components/Modal/Modal";
import DeliveryChargeColumns from "../../components/TableData/DeliveryCharge/DeliveryCharge";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import { getDeliveryFees } from "../../redux/Actions/DeliveryFeesActions";

const DeliveryFee = (props) => {
	const {
		isGetting,
		getSuccess,
		deliveryFees,
		deliveryFeesData,
		getDeliveryFees,	
		deleteSuccess,
		changeSuccess,
		createSuccess,
		editSuccess,
	} = props;
	const [state, setState] = useState({
		addModal: false,
		showSearchResults: false,
		searchValue: "",
	});

	const openModal = (type) => viewModal(type, state, setState);
	const closeModal = (type) => cancelModal(type, state, setState);

	const moreData = (page, perPage) => {
		getDeliveryFees(page, perPage, state.searchValue);
	};
	const searchAllDeliveryFees = () => {
	  if (state.searchValue === "") {
		return deliveryFees;
	  } else {
		getDeliveryFees(1, 10, state.searchValue);
	  }
	};

	useEffect(
	  () => {
		if (createSuccess || changeSuccess || deleteSuccess || editSuccess) {
		  getDeliveryFees(1, 10, state.searchValue);
		}
	  }, // eslint-disable-next-line
	  [createSuccess, deleteSuccess, changeSuccess, editSuccess, getDeliveryFees]
	);

	const allDeliveryFees = deliveryFees;

	const totalDeliveryFees = deliveryFeesData && deliveryFeesData.total;

	return (
		<div>
			<HeaderWrapper
				title={"States/ Delivery Fees"}
				subtitle={"View and Manage all delivery charges per state"}
				children={
					<PageButton
						width={"auto"}
						background={"rgba(10, 116, 220, 0.08)"}
						marginTop={"30px"}
						onClick={() => openModal("addModal")}
						type={"submit"}
					>
						<div className="button-data">
							<img src={Add} alt="add-icon" />
							<span>Create</span>
						</div>
					</PageButton>
				}
			></HeaderWrapper>
			<SortData
				placeholder={"Search by State"}
				width={"347px"}
				searchItems={searchAllDeliveryFees}
				setState={setState}
				state={state}
				filter={false}
				sortSuccess={false}
				refreshFunction={() =>
					getDeliveryFees(1, 10, "")
				}
			/>
			<DeliveryChargeColumns
				state={state}
				setState={setState}
				data={allDeliveryFees}
				getData={(page, limit) => getDeliveryFees(page, limit, state.searchValue)}
				getMoreData={(page, perPage) => moreData(page, perPage)}
				isLoading={isGetting}
				success={getSuccess}
				total={totalDeliveryFees}
			/>
			<CreateDeliveryFee
				displayModal={state.addModal}
				closeModal={() => closeModal("addModal")}
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	isGetting: state.deliveryFees.isGetting,
	getSuccess: state.deliveryFees.getSuccess,
	deliveryFees: state.deliveryFees.deliveryFees,
	deliveryFeesData: state.deliveryFees.deliveryFeesData,
	deleteSuccess: state.deliveryFees.deleteSuccess,
	changeSuccess: state.deliveryFees.changeSuccess,
	createSuccess: state.deliveryFees.createSuccess,
	editSuccess: state.deliveryFees.editSuccess,
});
const mapDispatchToProps = (dispatch) => ({
	getDeliveryFees: (page, limit, type) =>
		dispatch(getDeliveryFees(page, limit, type)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DeliveryFee);
