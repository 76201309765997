import Actions from "../Types";

const initialState = {
  isGetting: false,
  chats: [],
  getSuccess: false,
  isSearching: false,
  searchedChats: [],
  searchSuccess: false,
  isAssigning: false,
  data: {},
  assignSuccess: false,
  error: null,
  isFilteringChatStats: false,
  filterChatsSuccess: false,
  statsData: {},
  isGettingChatStats: false,
  getChatStatsSuccess: false,
  filterChatRoomsStart: false,
  filterChatRoomsSuccess: false,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_CHATS_ROOMS_START:
      return {
        ...state,
        isGetting: true,
        chats: [],
        getSuccess: false,
        searchSuccess: false,
        error: null,
      };
    case Actions.GET_CHATS_ROOMS_SUCCESS:
      return {
        ...state,
        isGetting: false,
        chats: action.payload,
        isFilteringChatStats: false,
        filterChatsSuccess: false,
        getSuccess: true,
        filterChatRoomsStart: false,
        filterChatRoomsSuccess: false,
        searchSuccess: false,
        error: null,
      };
    case Actions.GET_CHATS_ROOMS_FAILURE:
      return {
        ...state,
        isGetting: false,
        chats: [],
        getSuccess: false,
        searchSuccess: false,
        error: true,
      };
    case Actions.SEARCH_CHATS_ROOMS_START:
      return {
        ...state,
        isSearching: true,
        searchedChats: [],
        searchSuccess: false,
        error: null,
      };
    case Actions.SEARCH_CHATS_ROOMS_SUCCESS:
      return {
        ...state,
        isSearching: false,
        searchedChats: action.payload,
        searchSuccess: true,
        error: null,
      };
    case Actions.SEARCH_CHATS_ROOMS_FAILURE:
      return {
        ...state,
        isSearching: false,
        searchedChats: [],
        searchSuccess: false,
        error: true,
      };
    case Actions.ASSIGN_PENDING_CHAT_START:
      return {
        ...state,
        isAssigning: true,
        data: {},
        assignSuccess: false,
        error: null,
      };
    case Actions.ASSIGN_PENDING_CHAT_SUCCESS:
      return {
        ...state,
        isAssigning: false,
        data: action.payload,
        assignSuccess: true,
        error: null,
      };
    case Actions.FILTER_CHAT_STATS_START:
      return {
        ...state,
        filterChatsStart: true,
        filterChatsSuccess: false,
        isGettingChatStats: false,
        getChatStatsSuccess: false,
        statsData: null,
      };
    case Actions.FILTER_CHATS_START:
      return {
        ...state,
        filterChatRoomsStart: true,
        filterChatRoomsSuccess: false,
        chats: [],
      };
    case Actions.FILTER_CHATS_SUCCESS:
      return {
        ...state,
        filterChatRoomsStart: false,
        filterChatRoomsSuccess: true,
        chats: action.payload,
      };
    case Actions.FILTER_CHAT_STATS_SUCCESS:
      return {
        ...state,
        filterChatsStart: false,
        filterChatsSuccess: true,
        isGettingChatStats: false,
        getChatStatsSuccess: false,
        statsData: action.payload,
      };
    case Actions.GET_CHAT_STATS_START:
      return {
        ...state,
        isGettingChatStats: true,
        getChatStatsSuccess: false,
        filterChatsStart: false,
        filterChatsSuccess: false,
        statsData: null,
      };
    case Actions.GET_CHAT_STATS_SUCCESS:
      return {
        ...state,
        filterChatsStart: false,
        filterChatsSuccess: false,
        isGettingChatStats: false,
        getChatStatsSuccess: true,
        statsData: action.payload,
      };
    case Actions.ASSIGN_PENDING_CHAT_FAILURE:
      return {
        ...state,
        isAssigning: false,
        data: {},
        assignSuccess: false,
        error: true,
      };
    default:
      return state;
  }
};

export default reduxState;
