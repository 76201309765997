import React, { useEffect } from "react";
import { connect } from "react-redux";
import { LoaderSpin } from "../../components/Loader/Loader";
import { getSingleCoupon } from "../../redux/Actions/CouponsActions";

const CouponDetails = (props) => {
  const {
    getSingleCouponData,
    match,
    singleCouponData,
    isGettingSingleCoupon,
    getSingleCouponSuccess,
  } = props;

  useEffect(() => {
    const id = match.params.id;
    getSingleCouponData(id);
  }, []);

  useEffect(() => {
    console.log(singleCouponData);
  }, [getSingleCouponSuccess]);

  return (
    <div className="coupon-details">
      <h1>Coupon Info</h1>
      {isGettingSingleCoupon ? (
        <LoaderSpin />
      ) : (
        <div className="card">
          <div className="content">
            <div className="left">
              <div className="info">
                <h2>NAME:</h2>
                <p>{singleCouponData.coupon?.name}</p>
              </div>
              <div className="info">
                <h2>CODE:</h2>
                <p>{singleCouponData.coupon?.code}</p>
              </div>
              <div className="info">
                <h2>USAGE COUNT:</h2>
                <p>{singleCouponData.usage_count}</p>
              </div>
              <div className="info">
                <h2>TOTAL AMOUNT ASSIGNED:</h2>
                <p>
                  {singleCouponData.coupon?.type === "fixed" ? "NGN" : ""}{" "}
                  {singleCouponData.usage_count *
                    singleCouponData.coupon?.amount}
                  {singleCouponData.coupon?.type === "percentage" ? "%" : ""}{" "}
                </p>
              </div>
            </div>
            <div className="right">
              <h3>USERS</h3>
              {singleCouponData.users?.length === 0 ? (
                <p style={{ fontStyle: "italic" }}>No users for this coupon</p>
              ) : (
                ""
              )}
              {singleCouponData.users?.map((item) => (
                <p>{item}</p>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  singleCouponData: state.coupons.singleCouponData,
  isGettingSingleCoupon: state.coupons.isGettingSingleCoupon,
  getSingleCouponSuccess: state.coupons.getSingleCouponSuccess,
});
const mapDispatchToProps = (dispatch) => ({
  getSingleCouponData: (id) => dispatch(getSingleCoupon(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CouponDetails);
