import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  deleteBlogCategory,
  getAllBlogCategories,
} from "../../../redux/Actions/BlogActions";
import { getCurrentPagination } from "../../../utils/functions/GetStorageItems";
import DeleteModal from "../../Modal/DeleteModal";

const DeleteCategory = (props) => {
  const { deleteCategory, isDeleting, deleteSuccess, id, getCategories } =
    props;

  const { page, perPage } = getCurrentPagination();

  useEffect(
    () => {
      if (deleteSuccess) {
        props.closeModal();
        getCategories(page, perPage);
      }
    }, // eslint-disable-next-line
    [deleteSuccess]
  );

  const handleDelete = (id) => {
    deleteCategory(id);
  };

  return (
    <DeleteModal
      displayModal={props.displayModal}
      closeModal={props.closeModal}
      title={"Delete this category?"}
      width={"463px"}
      text={"Delete"}
      onClick={() => handleDelete(id)}
      disabled={isDeleting}
      isLoading={isDeleting}
      loadingText={"Deleting..."}
      children={
        "Once you delete this category, it won’t be possible to undo this action. Delete category?"
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isDeleting: state.blogcategory.isDeleting,
  deleteSuccess: state.blogcategory.deleteSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  deleteCategory: (id) => dispatch(deleteBlogCategory(id)),
  getCategories: (page, limit) => dispatch(getAllBlogCategories(page, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteCategory);
