import React from "react";

const ImportantText = ({ label }) => {
  return (
    <span>
      {label} <small style={{ color: "#dc0000", fontSize: "12px" }}>*</small>
    </span>
  );
};

export default ImportantText;
