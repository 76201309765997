import React, { useState, useEffect, useRef } from "react";
import Green from "../../assets/graph-green.svg";
import Blue from "../../assets/graph-blue.svg";
import moment from "moment";
import { Line } from "react-chartjs-2";

const defaultLabelProps = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June", 
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const Graph = ({ graphData, graphType }) => {
  const [date, setDate] = useState(moment().format("LLL"));
  const [labelProps, setLabelProps] = useState([]);
  const { revenue_graph, sales_graph } = graphData;

  const dateRef = useRef(null);

  const dataHandler = (data) => {
    if (graphType === "year") {
      return Array.from(
        { length: defaultLabelProps?.length },
        (_, index) => index
      ).map((value) => {
        const yAxis = data?.find((val) => value === new Date(val.x).getMonth());
        return yAxis?.y ?? 0;
      });
    }

    return data?.map((key) => key.y);
  };

  const dataState = {
    labels: labelProps,
    datasets: [
      {
        label: "Total Revenue",
        data: dataHandler(revenue_graph?.year),
        fill: true,
        backgroundColor: "transparent",
        borderColor: "#00DCB4",
        lineTension: 0.5,
      },
      {
        label: "Sales",
        data: dataHandler(sales_graph?.year),
        fill: false,
        borderColor: "#0A74DC",
        backgroundColor: "transparent",
        lineTension: 0.5,
      },
    ],
  };

  useEffect(
    () => {
      dateRef.current = setInterval(
        () => setDate(moment().format("LLL")),
        60_000
      );
      return () => clearInterval(dateRef.current);
    }, // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (graphType === "year") {
        setLabelProps(defaultLabelProps);
        return;
      }

      setLabelProps(() =>
        sales_graph?.year.map((type) => new Date(type.x).toDateString())
      );
    }, // eslint-disable-next-line
    [graphType]
  );

  return (
    <div className="overview-graph">
      <div className="overview-graph-header">
        <div className="overview-graph-header-left">
          <h4>Today’s sales trend</h4>
          <p>{date}</p>
        </div>

        <div className="overview-graph-header-right">
          <div className="overview-graph-header-right-indicators">
            <img src={Green} alt="total revenue" />
            <span>Total Revenue</span>
          </div>
          <div className="overview-graph-header-right-indicators">
            <img src={Blue} alt="Sales" />
            <span>Sales</span>
          </div>
        </div>
      </div>

      <div className="overview-graph-section">
        <Line
          data={dataState}
          options={{
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
              y: {
                grid: {
                  borderDash: [10, 10],
                },
                beginAtZero: true,
              },
            },
          }}
        />
      </div>
    </div>
  );
};

Graph.defaultProps = {
  graphType: "year",
};

export default Graph;
