import {
  SuccessBtn,
  ErrorBtn,
  // TableDropdown,
  // DropBtn,
} from "../Tables/TableButtons";
// import { useState } from "react";
import Table from "../Tables/Table";
// import { cancelModal, viewModal } from "../Modal/Modal";
// import { filterOptionsLabel } from "../../utils/functions/FilterObject";
// import EditSubscription from "./EditSubscription";

const SubscriptionsColumns = (props) => {
  const { data, getData, success, isLoading, planIds } = props;
  const subscriptionsColumns = [
    // {
    //   Header: "Email",
    //   accessor: "email",
    //   Cell: ({row}) =>{ 
    //     console.log(row.original)
    //     return (
    //     <div className="table-flex">
    //       <p>{row.original.email || 'Nil'}</p>
    //     </div>
    //   )}
    // }
    ,
    {
      Header: "Plan",
      accessor: "plan",
      Cell: ({ row }) => (
        <div className="table-flex">
          <span style={{ "padding-right": "10px" }}>
            {row.original.subscription_plans?.name}
          </span>
        </div>
      ),
    },
    {
      Header: "Plan Id",
      accessor: "id",
      Cell: ({ row }) => (
        <div className="table-flex">
          <span style={{ "padding-right": "10px" }}>{row.original.id}</span>
        </div>
      ),
    },

    {
      Header: "Transaction Reference",
      accessor: "transaction_reference",
      Cell: ({ row }) => (
        <div className="table-flex">
          <span style={{ "padding-right": "10px" }}>
            {row.original.transaction_reference}
          </span>
        </div>
      ),
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ row }) => (
        <div className="table-flex">
          <span style={{ "padding-right": "10px" }}>{row.original.amount}</span>
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        const data = row.original;
        // console.log(data)
        // const id = row.original && row.original.id;
        // const status = row.original && row.original.status;
        // const [show, setShow] = useState(false);
        // const [state, setState] = useState({
        //   editModal: false,
        // });
        // const openModal = (type) => {
        //   viewModal(type, state, setState);
        //   setShow((prevCheck) => !prevCheck);
        // };

        // const closeModal = (type) => {
        //   cancelModal(type, state, setState);
        // };

        // const options = [
        //   {
        //     name: "Edit",
        //     action: () => openModal("editModal"),
        //   },
        // ];
        return (
          <div className="action-flex">
            {row.original.status === "success" ? (
              <SuccessBtn text={"Successful"} />
            ) : row.original.status === "failed" ? (
              <ErrorBtn text={"Failed"} />
            ) : (
              "Nil"
            )}
            {/* <DropBtn onClick={() => setShow((prevCheck) => !prevCheck)} />
            {show && (
              <TableDropdown
                options={options && filterOptionsLabel(status, options)}
              />
            )} */}
            {/* {state.editModal && (
              <EditSubscription
                getSubscriptions={getData}
                displayModal={state.editModal}
                closeModal={() => closeModal("editModal")}
                data={data}
                id={row.original.id}
                planIds={planIds}
              />
            )} */}
          </div>
        );
      },
    },
  ];

  return (
    <Table
      columns={subscriptionsColumns}
      data={data}
      getData={getData}
      success={success}
      isLoading={isLoading}
    />
  );
};
export default SubscriptionsColumns;
