import React, { useState, useEffect, useRef } from 'react';

const CustomDropdown = ({ children, tab, optionSelected }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const customDropdown = useRef();
  const tabRef = useRef();

  const dropdownHandler = (e) => {
    const outsideAreaWasClicked = !customDropdown.current?.contains(e.target);
    const dropdownTogglerWasClicked = tabRef.current?.contains(e.target);

    // If the dropdown toggler is clicked, negate the dropdown option's state
    if (dropdownTogglerWasClicked && customDropdown.current) {
      setShowDropdown((prevState) => !prevState);
    }

    // If anywhere outside the dropdown is clicked, close the dropdown's option
    if (outsideAreaWasClicked && customDropdown.current) setShowDropdown(false);
  };

  useEffect(() => {

    // If an option is selected, close the dropdown's option
    setShowDropdown(false);
    
  }, [optionSelected]);

  useEffect(() => {
    // Add the click event listener on component mount
    document?.body?.addEventListener('click', dropdownHandler);

    // Remove the click event listener when this component is destroyed
    return () => document.body.removeEventListener('click', dropdownHandler);
  }, []);

  return (
    <div ref={customDropdown}>
      <div className='custom__dropdown'>
        <div className='cursor-pointer' ref={tabRef}>
          {tab}
        </div>
        <div
          className={`custom__dropdown-option${showDropdown ? ' active' : ''}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default CustomDropdown;
