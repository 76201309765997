import PropTypes from "prop-types";
import React, { useRef, useEffect } from "react";
import { LoaderSpin } from "../Loader/Loader";

export const TableDropdown = (props) => {
  const { options, hideDropdown, active, isGettingCSVResponse } = props;
  const tableDropdown = useRef();

  const toggleHandler = (option) => {
    option.action();
    active && hideDropdown();
  };

  useEffect(
    () => {
      tableDropdown.current.focus();
      tableDropdown.current.addEventListener("blur", () => {
        active && hideDropdown();
      });
    }, // eslint-disable-next-line
    []
  );

  return (
    <div tabIndex="-1" ref={tableDropdown} className="table-dropdown">
      {options &&
        options.map((option, index) => (
          <div
            className="table-dropdown-item"
            onClick={() => toggleHandler(option)}
            key={index}
            data-testid={`toggleHandler${index}`}
          >
            {/* {(option.name === "CSV") & isGettingCSVResponse ? (
              <LoaderSpin />
            ) : ( */}
              <div className="table-dropdown-item-text">{option.name}</div>
            {/* )} */}
          </div>
        ))}
    </div>
  );
};

TableDropdown.defaultProps = {
  hideDropdown: () => {},
};

TableDropdown.propTypes = {
  hideDropdown: PropTypes.func,
};

export default TableDropdown;
