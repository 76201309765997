import React, { useEffect, useState } from "react";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import { PageButton } from "../../components/Button/Button";
import { cancelModal, viewModal } from "../../components/Modal/Modal";
import SwitchWrapper from "../../components/Wrappers/SwitchWrapper";
import Search from "../../components/Sorting/Search";
import Table from "../../components/Tables/Table";
import { appointmentManagerColumns } from "../../components/TableData/Appointment/AppointmentManager";
import Assessment from "../../components/AppointmentManager/Assessment/Assessment";
import AddCategory from "../../components/AppointmentManager/AppointmentCategory/AddCategory";
import AddAssessment from "../../components/AppointmentManager/Assessment/AddAssessment";
import {
  getAllAppointmentCategories,
  getAppointmentCategories,
  searchAppointmentCategories,
  swapAppointmentRank,
} from "../../redux/Actions/AppointmentActions";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import Filter from "../../components/Sorting/Filter";
import refreshLogo from "../../assets/refreshing.svg";
import {
  filterAssessmentByCategory,
  getAssessments,
} from "../../redux/Actions/AssessmentActions";
import SelectInput from "../../components/Input/Select";
import {
  assignCategoryOptions,
  assignSubcategoryOptions,
} from "../../utils/functions/UpdateValues";
import SortData from "../../components/Sorting/SortData";
import TableDropdown from "../../components/Tables/Dropdown";
import ExportIcon from "../../assets/Union.svg";
import DropIcon from "../../assets/drop-export.svg";
import downloadCSV from "../../utils/functions/csvDownloader";

const AppointmentManager = (props) => {
  const {
    isGetting,
    getSuccess,
    getCategories,
    searchCategories,
    searchedCategories,
    allcategories,
    isSearching,
    searchSuccess,
    searchedCategoriesData,
    categoriesData,
    fetchAssessments,
    filterSuccess,
    categories,
    getAllCategories,
    getCategorySuccess,
    filterQuestions,
    filterByCategorySuccess,
    getAssessmentsSuccess,
    deleteSuccess,
    swapAppointmentsRank,
  } = props;
  const [assessmentsData, setAssessmentsData] = useState([]);

  const [show, setShow] = useState(false);
  const [state, setState] = useState({
    addAssessment: false,
    addCategory: false,
    active1: true,
    active2: false,
    id: "",
    active: true,
    showSearchResults: false,
    showAssessmentModal: false,
    subcategory_name: "",
    category_name: "",
    searchValue: "",
  });

  const history = useHistory();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);

  const openModal = (type) => viewModal(type, state, setState);
  const closeModal = (type) => cancelModal(type, state, setState);

  useEffect(
    () => {
      if (getCategorySuccess) {
        const updatedCategory = assignCategoryOptions(categories);
        setCategoryOptions(updatedCategory);
      }
    },
    // eslint-disable-next-line
    [getCategorySuccess]
  );

  const searchAllCategories = () => {
    if (state.searchValue === "") {
      return allcategories;
    } else {
      searchCategories(1, 10, state.searchValue);
    }
  };

  const moreData = (page, perPage) => {
    searchSuccess
      ? searchCategories(page, perPage, state.searchValue)
      : getCategories(page, perPage);
  };

  const updateCategory = (data) => {
    const updatedSubcategory = assignSubcategoryOptions(categories, data.value);
    setSubcategoryOptions(updatedSubcategory);
    setState({
      ...state,
      category_name: data.label,
      subcategory_name: "",
      subcategory_id: "",
    });
  };

  const filterQuestionsByCategory = (data) => {
    filterQuestions(data.value);
    setState({
      ...state,
      subcategory_name: data.label,
      subcategory_id: data.value,
    });
  };

  const allCategories = searchSuccess ? searchedCategories : allcategories;

  const totalCategories = searchSuccess
    ? searchedCategoriesData && searchedCategoriesData.count
    : categoriesData && categoriesData.count;

  useEffect(
    () => {
      if (deleteSuccess) {
        fetchAssessments({ type: "all" }, setAssessmentsData);
      }
    }, // eslint-disable-next-line
    [deleteSuccess]
  );

  const exportHandler = (format) => {
    switch (format) {
      case "CSV":
        const data = assessmentsData;
        const exportData =
          data &&
          data.map((assessment) => ({
            Title: assessment.title,
            Category: assessment.category,
            Type: assessment.type,
            Status: assessment.status,
          }));
        downloadCSV(exportData, "appointment-data");
        return;
      default:
        return;
    }
  };

  const export_options = [
    {
      name: "CSV",
      action: () => exportHandler("CSV"),
    },
  ];

  return (
    <div>
      <HeaderWrapper
        title={state.active1 ? "Appointment Manager" : "Assessment Questions"}
        subtitle={
          state.active1
            ? "Helps you view and manage all your appointment categories"
            : "Helps you view and manage all assessment questions "
        }
      >
        <div style={{display: "flex", alignItems: "center"}} className="overview-header-right-button">
          <PageButton
            width={"156px"}
            background={"rgba(10, 116, 220, 0.08)"}
            marginTop={"30px"}
            marginRight={"10px"}
            onClick={
              state.active1
                ? () => history.push("/appointment-manager/create-category")
                : () => openModal("addAssessment")
            }
            type={"submit"}
          >
            <div className="button-data">
              <span>{state.active1 ? "Add Category" : "Create Question"}</span>
            </div>
          </PageButton>
          {state.active2 ? (
            <PageButton
              width={"121px"}
              mainWidth={"144px"}
              // marginLeft={"10px"}
              background={"rgba(10, 116, 220, 0.08)"}
              marginTop={"30px"}
              type={"button"}
              onClick={() => setShow((prevCheck) => !prevCheck)}
            >
              <div className="button-data">
                <img src={ExportIcon} alt="export icon" />
                <span className="span2">Export</span>
                <img src={DropIcon} alt="dropdown" />
              </div>
            </PageButton>
          ) : null}
        {show && (
          <TableDropdown
          options={export_options}
          hideDropdown={() => setShow((prevCheck) => !prevCheck)}
          active
          />
          )}
          </div>
      </HeaderWrapper>

      <SwitchWrapper
        title1={"Appointment Category"}
        title2={"Assessment"}
        state={state}
        setstate={setState}
        right1={
          <SortData
            placeholder={"Search categories by name, subcategory"}
            width={"347px"}
            searchItems={searchAllCategories}
            setState={setState}
            state={state}
            filter={false}
            sortSuccess={searchSuccess}
            refreshFunction={() => {
              setState({
                ...state,
                searchValue: "",
              });
              getCategories(1, 10);
            }}
          />
        }
        right2={
          <div className="flex-options2">
            <div className="flex-options2-reorder">
              <div className="text">Reorder:</div>
              <div className="">
                <p
                  style={{
                    marginBottom: "-.5px",
                    fontSize: "1.1rem",
                    marginLeft: ".9rem",
                    fontWeight: "bold",
                  }}
                >
                  Category
                </p>
                <SelectInput
                  options={categoryOptions}
                  onChange={(data) => updateCategory(data)}
                  defaultOption={state.category_name}
                  success={getAssessmentsSuccess}
                  placeholder="Category"
                />
              </div>
              &nbsp; &nbsp;
              <div className="">
                <p
                  style={{
                    marginBottom: "-.5px",
                    fontSize: "1.1rem",
                    marginLeft: ".9rem",
                    fontWeight: "bold",
                  }}
                >
                  Subcategory
                </p>
                <SelectInput
                  options={subcategoryOptions}
                  onChange={(data) => filterQuestionsByCategory(data)}
                  defaultOption={state.subcategory_name}
                  success={getAssessmentsSuccess}
                  placeholder="Subcategory"
                />
              </div>
            </div>
            {filterSuccess ||
              (filterByCategorySuccess && (
                <img
                  title="Refresh table"
                  style={{ marginRight: "15px" }}
                  className="cursor-pointer"
                  src={refreshLogo}
                  alt="refresh-logo"
                  onClick={() =>
                    fetchAssessments({ type: "all" }, setAssessmentsData)
                  }
                />
              ))}
            <Filter
              onClick={() =>
                setState((state) => ({ ...state, showAssessmentModal: true }))
              }
              testid="data-filter"
            />
          </div>
        }
        component1={
          <Table
            columns={appointmentManagerColumns}
            data={allCategories}
            getData={getCategories}
            isLoading={isGetting || isSearching}
            success={getSuccess || searchSuccess}
            getMoreData={(page, perPage) => moreData(page, perPage)}
            sortSuccess={searchSuccess}
            emptyText1={"You haven't added any Appointment Category"}
            emptyText2={"Click on the “Add Category” button to get started"}
            emptyResultText={"Appointment category not found!"}
            className={"manager-table2"}
            total={totalCategories}
            isDraggable={true}
            moveRowAsyncFunc={(payload) => swapAppointmentsRank(payload)}
          />
        }
        component2={
          <Assessment
            modalActive={state.showAssessmentModal}
            closeModal={() =>
              setState((state) => ({
                ...state,
                showAssessmentModal: false,
              }))
            }
            id={state.subcategory_id}
            getAllCategories={getAllCategories}
            assessmentsData={assessmentsData}
            setAssessmentsData={setAssessmentsData}
          />
        }
      />

      {state.addCategory && (
        <AddCategory
          displayModal={state.addCategory}
          closeModal={() => closeModal("addCategory")}
        />
      )}

      {state.addAssessment && (
        <AddAssessment
          displayModal={state.addAssessment}
          closeModal={() => closeModal("addAssessment")}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGetting: state.appointment.isGettingCategories,
  getSuccess: state.appointment.getAllCategorySuccess,
  getAssessmentsSuccess: state.assessments.getAssessmentsSuccess,
  allcategories: state.appointment.allcategories,
  categoriesData: state.appointment.categoriesData,
  isSearching: state.appointment.isSearchingCategories,
  searchSuccess: state.appointment.searchCategorySuccess,
  searchedCategories: state.appointment.searchedCategories,
  searchedCategoriesData: state.appointment.searchedCategoriesData,
  addSuccess: state.appointment.addSuccess,
  filterSuccess: state.assessments.filterSuccess,
  isGettingCategories: state.appointment.isGettingCategories,
  getCategorySuccess: state.appointment.getCategorySuccess,
  categories: state.appointment.categories,
  filterByCategorySuccess: state.assessments.filterByCategorySuccess,
  deleteSuccess: state.assessments.deleteSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getCategories: (page, limit) =>
    dispatch(getAllAppointmentCategories(page, limit)),
  getAllCategories: () => dispatch(getAppointmentCategories()),
  searchCategories: (page, limit, searchValue) =>
    dispatch(searchAppointmentCategories(page, limit, searchValue)),
  fetchAssessments: (queries, setData) =>
    dispatch(getAssessments(queries, setData)),
  filterQuestions: (payload) => dispatch(filterAssessmentByCategory(payload)),
  swapAppointmentsRank: (payload) => dispatch(swapAppointmentRank(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentManager);
