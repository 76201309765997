import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { editDeliveryFee } from "../../redux/Actions/DeliveryFeesActions";
import { DeliveryFeeSchema } from "../../utils/validation/validation";
import Input from "../Input/Input";
import Modal, { ModalButton } from "../Modal/Modal";

const EditDeliveryFee = (props) => {
  const {
    displayModal,
    closeModal,
    data,
    editDeliveryFee,
    isEditing,
    editSuccess,
  } = props;


  useEffect(
    () => {
      if (editSuccess) {
        closeModal();
      }
    },
    // eslint-disable-next-line
    [editSuccess]
  );

  const initialValues = {
		state: data.state,
		fees: data.fees,
  };


  const submitValues = (values) => {
		const { state, fees } = values;

    const payload = {
			state,
			fees,
    };

    const id = data && data.id;
    editDeliveryFee(id, payload);

  };

  

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Edit Delivery Fee"}
      width={"568px"}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={DeliveryFeeSchema}
        onSubmit={(values) => submitValues(values)}
      >
        {({ isValid, dirty, setFieldValue, values }) => {
          const disabled =
            isEditing ||
            values.state === "" ||
            values.fees === "" ;
          return (
            <Form id="addDeliveryFeeform">
            <Field
              name="state"
              label="State"
              placeholder="Enter state"
              component={Input}
              emptyValue={setFieldValue}
            />
            <div className="top-spacer">
            <Field
              type="number"
              name="fees"
              label="Fees(NGN)"
              placeholder="Enter amount"
              component={Input}
              emptyValue={setFieldValue}
            /></div>

              <ModalButton
                isLoading={isEditing}
                text={"Save Changes"}
                type={"submit"}
                testId={"button"}
                disabled={disabled}
                loadingText={"Saving..."}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isEditing: state.deliveryFees.isEditing,
  editSuccess: state.deliveryFees.editSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  editDeliveryFee: (id, payload) => dispatch(editDeliveryFee(id, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDeliveryFee);
