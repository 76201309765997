import Actions from "../Types";

const initialState = {
  isUploading: false,
  fileUrl: "",
  uploadSuccess: false,
  error: null,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.UPLOAD_IMAGE_START:
      return {
        ...state,
        isUploading: true,
        uploadSuccess: false,
        fileUrl: "",
        error: null,
      };
    case Actions.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        isUploading: false,
        fileUrl: action.payload,
        uploadSuccess: true,
        error: null,
      };
    case Actions.UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        isUploading: false,
        uploadSuccess: false,
        fileUrl: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reduxState;
