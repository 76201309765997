import React, { useState, useEffect } from "react";
import { PageButton } from "../../components/Button/Button";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import { cancelModal, viewModal } from "../../components/Modal/Modal";
import Add from "../../assets/add.svg";
import { paymentColumns } from "../../components/TableData/Payment/Payment";
import Table from "../../components/Tables/Table";
import { useHistory } from "react-router-dom";
import CreatePlan from "../../components/PaymentPlans/CreatePlan";
import Plans from "../../components/PaymentPlans/Plans";
import { connect } from "react-redux";
import {
  createPlan,
  getAllPlans,
} from "../../redux/Actions/PaymentPlanActions";
import PaymentPlansLoader from "./PaymentPlansLoader";
import Metrics from "../../components/PaymentPlans/Metrics";
import { getAllEnterprises } from "../../redux/Actions/EnterpriseActions";

const PaymentPlans = (props) => {
  const {
    getPlans,
    getSuccess,
    createPlan,
    isGettingPlans,
    createSuccess,
    isCreatingPlan,
    plans,
    enterpriseData,
    getEnterprises
  } = props;

  const history = useHistory();

  const [state, setState] = useState({
    createModal: false,
    editModal: false,
    deleteModal: false,
    index: "",
    plan_validity: "",
    benefits: [""],
    auto_renew: "",
    unlimited_chat: "",
    enterpriseId: ""
  });

  const [recentSubscribersState, setRecentSubscribersState] = useState([]);

  useEffect(
    () => {
      getPlans();
    },
    // eslint-disable-next-line
    [getPlans]
  );

  const paymentPlans = plans && plans.plans;
  const recentSubscribers = plans && plans.recent_subscribers;

  useEffect(
    () => {
      if (getSuccess) {
        setRecentSubscribersState(recentSubscribers);
      }
    },
    // eslint-disable-next-line
    [getSuccess]
  );

  const openModal = (type) => viewModal(type, state, setState);
  const closeModal = (type) => cancelModal(type, state, setState);

  const viewAll = () => {
    history.push("/payment-plans/users");
  };

  useEffect(
    () => {
      if (createSuccess) {
        document.getElementById("createPlan").reset();
        closeModal("createModal");
        getPlans();
      }
    },
    // eslint-disable-next-line
    [createSuccess]
  );

  return (
    <div>
      <HeaderWrapper
        title={"Payment Plans"}
        subtitle={"View and Manage all payment plans"}
      >
        <PageButton
          width={"auto"}
          background={"rgba(10, 116, 220, 0.08)"}
          marginTop={"30px"}
          onClick={() => openModal("createModal")}
          type={"submit"}
          disabled={isGettingPlans}
        >
          <div className="button-data">
            <img src={Add} alt="add-icon" />
            <span>Create Plan</span>
          </div>
        </PageButton>
      </HeaderWrapper>

      {isGettingPlans && !getSuccess ? (
        <PaymentPlansLoader />
      ) : (
        <>
          <Metrics data={plans} />

          <div className="top-spacer">
            <Plans
              state={state}
              setState={setState}
              plans={paymentPlans}
              getPlans={getPlans}
              enterpriseData={enterpriseData}
            />
          </div>

          <div className="payment-table">
            <div className="payment-table-header">
              <h2>Recent Subscribers </h2>
              {/* <span onClick={viewAll}>View all Subscribers</span> */}
            </div>
            <Table
              columns={paymentColumns}
              data={recentSubscribersState}
              getData={() => {}}
              emptyText1={"No recent subscribers"}
              isLoading={false}
              success={true}
              emptyResultText={""}
            />

            <CreatePlan
              displayModal={state.createModal}
              closeModal={() => closeModal("createModal")}
              state={state}
              setState={setState}
              isCreatingPlan={isCreatingPlan}
              createPlan={createPlan}
              enterpriseData={enterpriseData}
              getEnterprises={getEnterprises}
            />
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGettingPlans: state.plan.isGettingPlans,
  getSuccess: state.plan.getSuccess,
  plans: state.plan.plans,
  plansData: state.plan.plansData,
  isCreatingPlan: state.plan.isCreatingPlan,
  createSuccess: state.plan.createSuccess,
  enterpriseData: state.enterprise.enterpriseData,
});

const mapDispatchToProps = (dispatch) => ({
  getPlans: () => dispatch(getAllPlans()),
  createPlan: (payload) => dispatch(createPlan(payload)),
  getEnterprises: (page, limit) => dispatch(getAllEnterprises(page, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPlans);
