import Actions from "../Types";

const initialState = {
  isGetting: false,
  isGettingSuccess: false,
  enterpriseData: [],

  isCreating: false,
  isCreatingSuccess: false,
  isCreatingFailure: false,

  isUpdating: false,
  isUpdatingSuccess: false,
  isUpdatingFailure: false,

  isGettingSingeEnterpriseData: false,
  singleEnterpriseData: {},
  getSingleEnterpriseDataSuccess: false,

  isUploadingEnterpriseUsers: false,
  uploadEnterpriseUsersSuccess: false,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.CREATE_ENTERPRISE_START:
      return {
        ...state,
        isCreating: true,
        isCreatingSuccess: false,
        isCreatingFailure: false,
        isUpdatingSuccess: false,
        isGettingSuccess: false,
        uploadEnterpriseUsersSuccess: false,
      };
    case Actions.CREATE_ENTERPRISE_SUCCESS:
      return {
        ...state,
        isCreating: false,
        isCreatingSuccess: true,
        uploadEnterpriseUsersSuccess: false,
        isCreatingFailure: false,
        isUpdatingSuccess: false,
        isGettingSuccess: false,
      };
    case Actions.CREATE_ENTERPRISE_FAILURE:
      return {
        ...state,
        isCreating: false,
        isCreatingSuccess: false,
        isCreatingFailure: false,
        isUpdatingSuccess: false,
        uploadEnterpriseUsersSuccess: false,
        isGettingSuccess: false,
        getSingleEnterpriseDataSuccess: false,
      };
    case Actions.GET_ENTERPRISES_START:
      return {
        ...state,
        isGetting: true,
        uploadEnterpriseUsersSuccess: false,
        isCreatingSuccess: false,
        isUpdatingSuccess: false,
        isGettingSuccess: false,
        getSingleEnterpriseDataSuccess: false,
      };
    case Actions.GET_ENTERPRISES_SUCCESS:
      return {
        ...state,
        isGetting: false,
        isCreatingSuccess: false,
        uploadEnterpriseUsersSuccess: false,
        isUpdatingSuccess: false,
        isGettingSuccess: true,
        getSingleEnterpriseDataSuccess: false,
        enterpriseData: action.payload,
      };
    case Actions.GET_SINGLE_ENTERPRISES_START:
      return {
        ...state,
        isGettingSingeEnterpriseData: true,
        singleEnterpriseData: {},
        getSingleEnterpriseDataSuccess: false,
        uploadEnterpriseUsersSuccess: false,
        isCreatingSuccess: false,
        isUpdatingSuccess: false,
        isGettingSuccess: false,
      };
    case Actions.GET_SINGLE_ENTERPRISES_SUCCESS:
      return {
        ...state,
        isGettingSingeEnterpriseData: false,
        singleEnterpriseData: action.payload,
        getSingleEnterpriseDataSuccess: true,
        isCreatingSuccess: false,
        uploadEnterpriseUsersSuccess: false,
        isUpdatingSuccess: false,
        isGettingSuccess: false,
      };
    case Actions.UPDATE_ENTERPRISE_START:
      return {
        ...state,
        isUpdating: true,
        isCreatingSuccess: false,
        isUpdatingSuccess: false,
        isGettingSuccess: false,
        uploadEnterpriseUsersSuccess: false,
        getSingleEnterpriseDataSuccess: false,
      };
    case Actions.UPDATE_ENTERPRISE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isCreatingSuccess: false,
        isUpdatingSuccess: true,
        isGettingSuccess: false,
        getSingleEnterpriseDataSuccess: false,
        uploadEnterpriseUsersSuccess: false,
      };
    case Actions.UPDATE_ENTERPRISE_FAILURE:
      return {
        ...state,
        isUpdating: false,
        isCreatingSuccess: false,
        isUpdatingSuccess: false,
        isGettingSuccess: false,
        isUpdatingFailure: true,
        getSingleEnterpriseDataSuccess: false,
        uploadEnterpriseUsersSuccess: false,
      };
    case Actions.UPLOAD_ENTERPRISE_USERS_START:
      return {
        ...state,
        isUploadingEnterpriseUsers: true,
        uploadEnterpriseUsersSuccess: false,
      };
    case Actions.UPLOAD_ENTERPRISE_USERS_SUCCESS:
      return {
        ...state,
        isUploadingEnterpriseUsers: false,
        uploadEnterpriseUsersSuccess: true
      };
    case Actions.UPLOAD_ENTERPRISE_USERS_FAILURE:
      return {
        ...state,
        isUploadingEnterpriseUsers: false,
        uploadEnterpriseUsersSuccess: false,
      };
    default:
      return state;
  }
};

export default reduxState;
