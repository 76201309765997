import React, { useEffect } from "react";
import { connect } from "react-redux";
import { filterPrescriptions } from "../../redux/Actions/prescriptionActions";
import DateFilter from "../Input/DateFilter";
import Modal, { ModalButton } from "../Modal/Modal";

const FilterPrescriptions = (props) => {
  const {
    displayModal,
    closeModal,
    state,
    setState,
    filterPrescriptions,
    filterSuccess,
    isFiltering,
  } = props;
  const disabled =
    isFiltering || state.prescribedFrom === "" || state.prescribedTo === "";
  const handleFilter = () => {
    const payload = {};
    payload.prescribedFrom = state.prescribedFrom;
    payload.prescribedTo = state.prescribedTo;
    filterPrescriptions(1, 10, payload);
  };

  useEffect(
    () => {
      if (filterSuccess) {
        closeModal();
      }
    },
    // eslint-disable-next-line
    [filterSuccess]
  );

  return (
    <div>
      <Modal
        displayModal={displayModal}
        closeModal={closeModal}
        title={"Filter Prescriptions"}
        width={"496px"}
      >
        <DateFilter
          label={"Date"}
          placeholder={"Select Prescribed From Date"}
          name={"prescribedFrom"}
          setState={setState}
          state={state}
          success={filterSuccess}
        />
        <div className="top-spacer">
          <DateFilter
            label={"Date"}
            placeholder={"Select Prescribed To Date"}
            name={"prescribedTo"}
            setState={setState}
            state={state}
            success={filterSuccess}
          />
        </div>
        <ModalButton
          isLoading={isFiltering}
          disabled={disabled}
          testId={"button"}
          text={"Apply Filter"}
          onClick={() => handleFilter()}
          loadingText={"Filtering..."}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isFiltering: state.prescriptions.isFiltering,
  filterSuccess: state.prescriptions.filterSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  filterPrescriptions: (page, limit, payload) =>
    dispatch(filterPrescriptions(page, limit, payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterPrescriptions);
