import { convertArrayToCSV } from "convert-array-to-csv";

const downloadCSV = (csv, csvName) => {
  const csvResponse = typeof csv === "object" ? convertArrayToCSV(csv) : csv;
  const hiddenElement = document.createElement("a");
  hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csvResponse)}`;
  hiddenElement.target = "_blank";
  hiddenElement.download = `${new Date().toISOString()}-${csvName}.csv`;
  hiddenElement.click();
};

export default downloadCSV;
