import Actions from "../Types";

const initialState = {
  isFiltering: false,
  isSearching: false,
  isDeleting: false,
  isChangingStatus: false,
  filterSuccess: false,
  searchSuccess: false,
  deleteSuccess: false,
  changeStatusSuccess: false,
  editSuccess: false,
  isGettingCategories: false,
  getCategorySuccess: false,
  allCategories: [],
  categories: [],
  categoriesData: {},
  isAddingCategory: false,
  addCategorySuccess: false,
  category: {},
  isEditing: false,
  filteredCategories: [],
  filteredCategoriesData: {},
  searchedBlogCategories: [],
  searchedBlogCategoriesData: {},
  error: null,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_BLOG_CATEGORIES_START:
      return {
        ...state,
        isGettingCategories: true,
        getCategorySuccess: false,
        allCategories: [],
        error: null,
      };
    case Actions.GET_BLOG_CATEGORIES_SUCCESS:
      return {
        ...state,
        createSuccess: false,
        isGettingCategories: false,
        getCategorySuccess: true,
        allCategories: action.payload,
        error: null,
      };
    case Actions.GET_BLOG_CATEGORIES_FAILURE:
      return {
        ...state,
        isGettingCategories: false,
        getCategorySuccess: false,
        allCategories: [],
        error: action.payload,
      };
    case Actions.GET_ALL_BLOG_CATEGORIES_START:
      return {
        ...state,
        isGettingCategories: true,
        getCategorySuccess: false,
        categories: [],
        categoriesData: {},
        editSuccess: false,
        deleteSuccess: false,
        filterSuccess: false,
        searchSuccess: false,
        changeStatusSuccess: false,
        error: null,
      };
    case Actions.GET_ALL_BLOG_CATEGORIES_SUCCESS:
      return {
        ...state,
        createSuccess: false,
        isGettingCategories: false,
        getCategorySuccess: true,
        categories: action.payload && action.payload.data,
        categoriesData: action.payload,
        editSuccess: false,
        deleteSuccess: false,
        filterSuccess: false,
        searchSuccess: false,
        changeStatusSuccess: false,
        error: null,
      };
    case Actions.GET_ALL_BLOG_CATEGORIES_FAILURE:
      return {
        ...state,
        isGettingCategories: false,
        getCategorySuccess: false,
        categories: [],
        categoriesData: {},
        editSuccess: false,
        deleteSuccess: false,
        filterSuccess: false,
        searchSuccess: false,
        changeStatusSuccess: false,
        error: action.payload,
      };
    case Actions.CREATE_BLOG_CATEGORY_START:
      return {
        ...state,
        isAddingCategory: true,
        addCategorySuccess: false,
        category: {},
        error: null,
      };
    case Actions.CREATE_BLOG_CATEGORY_SUCCESS:
      return {
        ...state,
        isAddingCategory: false,
        addCategorySuccess: true,
        category: action.payload,
        error: null,
      };
    case Actions.CREATE_BLOG_CATEGORY_FAILURE:
      return {
        ...state,
        isAddingCategory: false,
        addCategorySuccess: false,
        category: {},
        error: action.payload,
      };
    case Actions.EDIT_BLOG_CATEGORY_START:
      return {
        ...state,
        isEditing: true,
        editSuccess: false,
        category: {},
        error: null,
      };
    case Actions.EDIT_BLOG_CATEGORY_SUCCESS:
      return {
        ...state,
        isEditing: false,
        editSuccess: true,
        category: action.payload,
        error: null,
      };
    case Actions.EDIT_BLOG_CATEGORY_FAILURE:
      return {
        ...state,
        isEditing: false,
        editSuccess: false,
        category: null,
        error: action.payload,
      };
    case Actions.FILTER_BLOG_CATEGORIES_START:
      return {
        ...state,
        isFiltering: true,
        filterSuccess: false,
        editSuccess: false,
        deleteSuccess: false,
        changeStatusSuccess: false,
        filteredBlogCategories: [],
        filteredBlogCategoriesData: {},
        error: null,
      };
    case Actions.FILTER_BLOG_CATEGORIES_SUCCESS:
      return {
        ...state,
        isFiltering: false,
        filterSuccess: true,
        searchSuccess: false,
        editSuccess: false,
        deleteSuccess: false,
        changeStatusSuccess: false,
        filteredBlogCategories: action.payload && action.payload.data,
        filteredBlogCategoriesData: action.payload,
        error: null,
      };
    case Actions.FILTER_BLOG_CATEGORIES_FAILURE:
      return {
        ...state,
        isFiltering: false,
        filterSuccess: false,
        editSuccess: false,
        deleteSuccess: false,
        changeStatusSuccess: false,
        filteredBlogCategories: [],
        filteredBlogCategoriesData: {},
        error: action.payload,
      };
    case Actions.SEARCH_BLOG_CATEGORIES_START:
      return {
        ...state,
        isSearching: true,
        searchSuccess: false,
        editSuccess: false,
        deleteSuccess: false,
        changeStatusSuccess: false,
        searchedBlogCategories: [],
        searchedBlogCategoriesData: {},
        error: null,
      };
    case Actions.SEARCH_BLOG_CATEGORIES_SUCCESS:
      return {
        ...state,
        isSearching: false,
        searchSuccess: true,
        filterSuccess: false,
        editSuccess: false,
        deleteSuccess: false,
        changeStatusSuccess: false,
        searchedBlogCategories: action.payload && action.payload.data,
        searchedBlogCategoriesData: action.payload,
        error: null,
      };
    case Actions.SEARCH_BLOG_CATEGORIES_FAILURE:
      return {
        ...state,
        isSearching: false,
        searchSuccess: false,
        editSuccess: false,
        deleteSuccess: false,
        changeStatusSuccess: false,
        searchedBlogCategories: [],
        searchedBlogCategoriesData: {},
        error: action.payload,
      };
    case Actions.CHANGE_BLOG_CATEGORY_STATUS_START:
      return {
        ...state,
        isChangingStatus: true,
        changeStatusSuccess: false,
        category: {},
        error: null,
      };
    case Actions.CHANGE_BLOG_CATEGORY_STATUS_SUCCESS:
      return {
        ...state,
        isChangingStatus: false,
        changeStatusSuccess: true,
        category: action.payload,
        error: null,
      };
    case Actions.CHANGE_BLOG_CATEGORY_STATUS_FAILURE:
      return {
        ...state,
        isChangingStatus: false,
        changeStatusSuccess: false,
        category: null,
        error: action.payload,
      };
    case Actions.DELETE_BLOG_CATEGORY_START:
      return {
        ...state,
        isDeleting: true,
        deleteSuccess: false,
        category: {},
        error: null,
      };
    case Actions.DELETE_BLOG_CATEGORY_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: true,
        category: action.payload,
        error: null,
      };
    case Actions.DELETE_BLOG_CATEGORY_FAILURE:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: false,
        category: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reduxState;
