import React, { useEffect, useState } from "react";
import {
  SuccessBtn,
  DropBtn,
  TableDropdown,
  InfoBtn,
} from "../../../Tables/TableButtons";
import { cancelModal, viewModal } from "../../../Modal/Modal";
import { useHistory } from "react-router-dom";
import DeleteHfcp from "../../../UserManagement/HFCP/DeleteHfcp";
import ActivateHfcp from "../../../UserManagement/HFCP/ActivateHfcp";
import SuspendHfcp from "../../../UserManagement/HFCP/SuspendHfcp";
import Table from "../../../Tables/Table";
import { filterOptionsLabel } from "../../../../utils/functions/FilterObject";
import { getUsers } from "../../../../redux/Actions/UsersActions";
import { connect } from "react-redux";

const HfcpColumns = (props) => {
  const {
    state,
    setState,
    data,
    getData,
    getMoreData,
    isLoading,
    success,
    sortSucces,
    total,
    deleteSuccess,
    revokeSuccess,
    createSuccess,
  } = props;

  useEffect(() => {
    if (deleteSuccess || revokeSuccess || createSuccess) {
      getData(1, 10);
    }
  }, [deleteSuccess, getUsers, revokeSuccess, createSuccess]);

  const hfcpColumn = [
    {
      Header: "Full Name",
      accessor: "full_name",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <span style={{ textTransform: "capitalize" }}>
            {row.original.full_name}
          </span>
        </div>
      ),
    },
    {
      Header: "Services",
      accessor: "services",
      Cell: ({ row }) => (
        <div className="table-flex">
          <span>
            {(row.original?.facility && row.original?.facility.name) ||
              (row.original?.facility[0] && row.original.facility[0].name)}
          </span>
        </div>
      ),
    },
    {
      Header: "Address",
      accessor: "full_address",
      Cell: ({ row }) => (
        <div className="table-flex">
          <span>
            {(row.original?.facility && row.original?.facility.full_address) ||
              (row.original?.facility[0] &&
                row.original?.facility[0].full_address)}
          </span>
        </div>
      ),
    },
    {
      Header: "Location",
      accessor: "location",
      Cell: ({ row }) => (
        <div className="table-flex">
          <span>
            {(row.original?.facility && row.original.facility.state) ||
              (row.original?.facility[0] && row.original.facility[0].state)}
          </span>
        </div>
      ),
    },
    {
      Header: "Sex",
      accessor: "gender",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <span style={{ textTransform: "capitalize" }}>
            {row.original && row.original.gender}
          </span>
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        const id = row.original && row.original.id;
        const status = row.original && row.original.status;

        const [show, setShow] = useState(false);

        const history = useHistory();

        const [state, setState] = useState({
          activateModal: false,
          suspendModal: false,
          deleteModal: false,
          deliveredModal: false,
          canceledModal: false,
        });

        const openModal = (type) => {
          viewModal(type, state, setState);
          setShow((prevCheck) => !prevCheck);
        };

        const closeModal = (type) => {
          cancelModal(type, state, setState);
        };

        const goToSinglePage = () => {
          history.push({
            pathname: `/users/hfcp/${id}`,
            state: {
              id: id,
              data: row.original,
            },
          });
        };

        // Newly Added 
        const goToEditPage = () => {
          history.push({
            pathname: `/users/hfcps/edit/${id}`,
            state: {
              id: id,
              data: row.original,
            },
          });
        };

        const options = [
          {
            name: "View",
            action: () => goToSinglePage(),
          },
          {
            name: "Activate",
            label: "Active",
            action: () => openModal("activateModal"),
          },
          {
            name: "Suspend",
            label: "Suspended",
            action: () => openModal("suspendModal"),
          },
          {
            name: "Edit",
            label: "Edit",
            action: () => goToEditPage(),
          },
          {
            name: "Delete",
            action: () => openModal("deleteModal"),
          },
        ];

        return (
          <div className="action-flex">
            {row.original.status === "active" ? (
              <SuccessBtn text={"Active"} />
            ) : row.original.status === "suspended" ? (
              <InfoBtn text={"Suspended"} />
            ) : (
              "Nil"
            )}
            &nbsp; &nbsp;
            <DropBtn onClick={() => setShow((prevCheck) => !prevCheck)} />
            {show && (
              <TableDropdown
                options={options && filterOptionsLabel(status, options)}
              />
            )}
            {state.activateModal && (
              <ActivateHfcp
                displayModal={state.activateModal}
                closeModal={() => closeModal("activateModal")}
                id={id}
              />
            )}
            {state.suspendModal && (
              <SuspendHfcp
                displayModal={state.suspendModal}
                closeModal={() => closeModal("suspendModal")}
                id={id}
              />
            )}
            {state.deleteModal && (
              <DeleteHfcp
                displayModal={state.deleteModal}
                closeModal={() => closeModal("deleteModal")}
                id={id}
              />
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="orders-table">
      <Table
        state={state}
        setState={setState}
        columns={hfcpColumn}
        data={data}
        getData={getData}
        getMoreData={getMoreData}
        isLoading={isLoading}
        success={success}
        sortSucces={sortSucces}
        total={total}
        emptyText1={"You haven't added any HFCP"}
        emptyResultText={"HFCP not found!"}
        className={"manager-table3"}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isDeleting: state.users.isDeleting,
  deleteSuccess: state.users.deleteSuccess,
  revokeSuccess: state.users.revokeSuccess,
  createSuccess: state.users.createSuccess,
});

export default connect(mapStateToProps, null)(HfcpColumns);
