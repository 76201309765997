import React, { useEffect } from "react";
import { productColumns } from "../../components/TableData/Products/Products";
import Table from "../../components/Tables/Table";
import FilterProduct from "../../components/Products/Products/FilterProduct";
import { connect } from "react-redux";
import {
  filterProducts,
  getAllProducts,
  searchProducts,
  swapProductRank,
} from "../../redux/Actions/ProductActions";
import { paginateProductFilter } from "./Products";

const ProductsContainer = (props) => {
  const {
    isGetting,
    products,
    getSuccess,
    getProducts,
    productsData,
    isFiltering,
    filterSuccess,
    filteredProducts,
    filteredProductsData,
    isSearching,
    searchSuccess,
    searchedProducts,
    searchedProductsData,
    addSuccess,
    filterProducts,
    searchProducts,
    state,
    setState,
    closeModal,
    setProducts,
    swapProductsRank
  } = props;

  const moreData = (page, perPage) => {
    searchSuccess
      ? searchProducts(page, perPage, state.searchValue)
      : filterSuccess
      ? paginateProductFilter(page, perPage, state.filterValues, filterProducts)
      : getProducts(page, perPage);
  };

  const allProducts = searchSuccess
    ? searchedProducts
    : filterSuccess
    ? filteredProducts
    : products;

    useEffect(() => {
      setProducts(allProducts)
    }, [allProducts])

  const totalProducts = searchSuccess
    ? searchedProductsData && searchedProductsData.total
    : filterSuccess
    ? filteredProductsData && filteredProductsData.total
    : productsData && productsData.total;

  useEffect(
    () => {
      if (addSuccess) {
        document.getElementById("addProduct").reset();
        closeModal("addModal");
        getProducts(1, 10);
      }
    },
    // eslint-disable-next-line
    [addSuccess]
  );

  return (
    <div>
      <Table
        columns={productColumns}
        data={allProducts}
        getData={getProducts}
        getMoreData={(page, perPage) => moreData(page, perPage)}
        emptyText1={"You haven't added any product"}
        emptyText2={"Click on the “Add Product” button to get started"} 
        isLoading={isGetting || isFiltering || isSearching}
        success={getSuccess || filterSuccess || searchSuccess}
        emptyResultText={"Product not found!"}
        className={"table1"}
        sortSuccess={searchSuccess || filterSuccess}
        total={totalProducts}
        isDraggable={true}
        moveRowAsyncFunc={(payload) => swapProductsRank(payload)}
      />

      <FilterProduct
        displayModal={state.filterModal}
        closeModal={() => closeModal("filterModal")}
        isFiltering={isFiltering}
        filterProducts={filterProducts}
        filterSuccess={filterSuccess}
        setFilterState={setState}
        filterState={state}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGetting: state.products.isGetting,
  getSuccess: state.products.getSuccess,
  products: state.products.allProducts,
  productsData: state.products.allProductsData,
  isFiltering: state.products.isFiltering,
  filterSuccess: state.products.filterSuccess,
  filteredProducts: state.products.filteredProducts,
  filteredProductsData: state.products.filteredProductsData,
  isSearching: state.products.isSearching,
  searchSuccess: state.products.searchSuccess,
  searchedProducts: state.products.searchedProducts,
  searchedProductsData: state.products.searchedProductsData,
  addSuccess: state.products.success
});

const mapDispatchToProps = (dispatch) => ({
  getProducts: (page, limit) => dispatch(getAllProducts(page, limit)),
  filterProducts: (page, limit, payload) =>
    dispatch(filterProducts(page, limit, payload)),
  searchProducts: (page, limit, payload) =>
    dispatch(searchProducts(page, limit, payload)),
  swapProductsRank: (payload) => dispatch(swapProductRank(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsContainer);
