import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { cancelModal, viewModal } from "../../Modal/Modal";
import ActivateProduct from "../../Products/Products/ActivateProduct";
import DeactivateProduct from "../../Products/Products/DeactivateProduct";
import DeleteProduct from "../../Products/Products/DeleteProduct";
import StockUp from "../../Products/Products/StockUp";
import {
  ErrorBtn,
  SuccessBtn,
  PendingBtn,
  DropBtn,
  TableDropdown,
} from "../../Tables/TableButtons";
import pic1 from "../../../assets/drugpic1.svg";

export const productColumns = [
  {
    Header: "Product Name",
    accessor: "product_name",
    Cell: ({ row }) => (
      <div className="table-flex">
        <div className="table-flex-image">
          <img
            src={
              row.original?.images?.length !== 0
                ? row.original.images[0].image_url
                : pic1
            }
            alt="product"
          />
        </div>
        <span style={{ marginLeft: "16px" }}>{row.original.name}</span>
      </div>
    ),
  },
  {
    Header: "Category",
    accessor: "category",
    Cell: ({ row }) => (
      <div className="table-flex">
        <span>
          {(row.original.category && row.original.category.name) || "Nil"}
        </span>
      </div>
    ),
  },
  {
    Header: "Price",
    accessor: "price",
    Cell: ({ row }) => (
      <div className="table-shrink">
        <span>{row.original.price ? `NGN ${row.original.price}` : "-"}</span>
      </div>
    ),
  },
  {
    Header: "Sold",
    accessor: "quantity_sold",
    Cell: ({ row }) => (
      <div className="table-shrink">
        <span>{row.original.quantity_sold}</span>
      </div>
    ),
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ row }) => (
      <div
        style={{
          marginLeft: "-3rem",
        }}
      >
        {row.original.product_status &&
        row.original.product_status.toLowerCase() === "in stock" ? (
          <SuccessBtn text={"In stock"} />
        ) : row.original.product_status &&
          row.original.product_status.toLowerCase() === "out of stock" ? (
          <ErrorBtn text={"Out of Stock"} />
        ) : row.original.product_status &&
          row.original.product_status.toLowerCase() === "low stock" ? (
          <PendingBtn text={"Low Stock"} />
        ) : (
          "Nil"
        )}
      </div>
    ),
  },
  {
    Header: "Action ",
    accessor: "action",
    Cell: ({ row }) => {
      const [show, setShow] = useState(false);
      const id = row.original.id;

      const history = useHistory();

      const [state, setState] = useState({
        editModal: false,
        stockModal: false,
        deleteModal: false,
        activateModal: false,
        deactivateModal: false,
      });

      const openModal = (type) => {
        viewModal(type, state, setState);
        setShow((prevCheck) => !prevCheck);
      };

      const closeModal = (type) => {
        cancelModal(type, state, setState);
      };

      const goToSinglePage = () => {
        const id = row.original && row.original.id;
        history.push({
          pathname: `/product/${id}`,
          state: {
            id: id,
            data: row.original,
          },
        });
      };

      const status = row.original && row.original.active_status;

      const options = [
        {
          name: "View",
          action: () => goToSinglePage(),
        },
        {
          name: "Stock up",
          action: () => openModal("stockModal"),
        },
        {
          name: "Delete",
          action: () => openModal("deleteModal"),
        },
        {
          name: status === true ? "Deactivate" : "Activate",
          action:
            status === true
              ? () => openModal("deactivateModal")
              : () => openModal("activateModal"),
        },
      ];

      return (
        <div className="action-flex">
          <div
            style={{
              marginLeft: "-3rem",
            }}
          >
            {row.original.active_status ? (
              <span className="action-state1">Activated</span>
            ) : (
              <span className="action-state2">Deactivated</span>
            )}
          </div>
          <div className="move-dropdownBtn">
            <DropBtn onClick={() => setShow((prevCheck) => !prevCheck)} />
          </div>
          {show && <TableDropdown options={options} />}

          {state.stockModal && (
            <StockUp
              displayModal={state.stockModal}
              closeModal={() => closeModal("stockModal")}
              data={row.original}
            />
          )}

          {state.deleteModal && (
            <DeleteProduct
              displayModal={state.deleteModal}
              closeModal={() => closeModal("deleteModal")}
              id={id}
            />
          )}

          {state.activateModal && (
            <ActivateProduct
              displayModal={state.activateModal}
              closeModal={() => closeModal("activateModal")}
              id={id}
            />
          )}

          {state.deactivateModal && (
            <DeactivateProduct
              displayModal={state.deactivateModal}
              closeModal={() => closeModal("deactivateModal")}
              id={id}
            />
          )}
        </div>
      );
    },
  },
];
