import React from "react";
import Profile from "../../assets/user.svg";
import LogOutIcon from "../../assets/logout.svg";
import SettingsIcon from "../../assets/settings.svg";
import { clearStorage } from "../../utils/functions/ClearStorage";

export const Dropdown = ({ role }) => {
  const handleLogout = () => {
    clearStorage();
  };

  return (
    <div className="dropdown">
      <a
        className="dropdown-item"
        data-testid="routeUserProfile"
        href="/profile"
      >
        <div className="dropdown-item-image">
          <img src={Profile} alt="profile-icon" />
        </div>
        <div className="dropdown-item-text">Profile</div>
      </a>

      {role && role.toLowerCase() === "super admin" && (
        <a
          className="dropdown-item"
          data-testid="routeUserSettings"
          href="/settings"
        >
          <div className="dropdown-item-image">
            <img src={SettingsIcon} alt="settings-icon" />
          </div>
          <div className="dropdown-item-text">Settings</div>
        </a>
      )}

      <div className="dropdown-item" data-testid="routeUserLogout">
        <div className="dropdown-item-image">
          <img src={LogOutIcon} alt="logout-icon" />
        </div>
        <div className="dropdown-item-text" onClick={handleLogout}>
          Log Out
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
