import React, { useState } from "react";
import { connect } from "react-redux";
import { cancelModal, viewModal } from "../../components/Modal/Modal";
import CustomersColumn from "../../components/ReferralCodes/CustomerColumns";
import FilterCustomer from "../../components/ReferralCodes/FilterCustomer";
import InfluencerColumns from "../../components/ReferralCodes/InfluencerColumns";
import SortData from "../../components/Sorting/SortData";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import SwitchWrapper from "../../components/Wrappers/SwitchWrapper";
import {
  filterCustomers,
  getCustomers,
  searchCustomers,
} from "../../redux/Actions/ReferralReportAction";

const ReferralCodeManager = (props) => {
  const {
    isGetting,
    getCustomersSuccess,
    customers,
    isSearching,
    searchSuccess,
    searchedCustomers,
    isFiltering,
    filterSuccess,
    filteredCustomers,
    getCustomersHandler,
    filteredCustomersHandler,
    searchCustomersHandler,
    customersData,
    filteredCustomersData,
    searchedCustomersData,
  } = props;
  const [state, setState] = useState({
    active1: true,
    active2: false,
    filterValues: {},
    searchValue: "",
    showSearchResults: false,
    filterModal: false,
    createdFrom: "",
    createdTo: "",
  });

  const openModal = (type) => viewModal(type, state, setState);
  const closeModal = (type) => cancelModal(type, state, setState);
  const allCustomers = searchSuccess
    ? searchedCustomers
    : filterSuccess
    ? filteredCustomers
    : getCustomersSuccess
    ? customers
    : [
        {
          full_name: "",
          email: "",
          referral_code: "",
          referral_trails_referrerUsers: [],
        },
      ];
  const moreData = (page, perpage, type) => {
    searchSuccess
      ? searchCustomersHandler(page, perpage, type, state.searchValue)
      : filterSuccess
      ? filteredCustomersHandler(page, perpage, type, state.filterValues)
      : getCustomersHandler(page, perpage, type);
  };
  const totalCustomers = searchSuccess
    ? searchedCustomersData && searchedCustomersData.count
    : filterSuccess
    ? filteredCustomersData && filteredCustomersData.count
    : customersData && customersData.count;
  const searchAllUsers = () => {
    const checkType = state.active1 ? "customer" : "influencer";
    if (state.searchValue === "") {
      return customers;
    }
    searchCustomersHandler(1, 10, checkType, state.searchValue);
  };

  return (
    <div>
      <HeaderWrapper
        title={"Referral Report"}
        subtitle={"View and Manage all Referrals"}
      />
      <SwitchWrapper
        title1={"Customers"}
        title2={"Influencer"}
        state={state}
        setstate={setState}
        component1={
          <CustomersColumn
            state={state}
            setState={setState}
            data={allCustomers}
            getData={() => getCustomersHandler(1, 10, "customer")}
            getMoreData={(page, perPage) => moreData(page, perPage, "customer")}
            isLoading={isGetting || isSearching || isFiltering}
            success={getCustomersSuccess || searchSuccess || filterSuccess}
            sortSuccess={searchSuccess || filterSuccess}
            total={totalCustomers}
          />
        }
        component2={
          <InfluencerColumns
            state={state}
            setState={setState}
            data={allCustomers}
            getData={() => getCustomersHandler(1, 10, "influencer")}
            getMoreData={(page, perPage) =>
              moreData(page, perPage, "influencer")
            }
            isLoading={isGetting || isSearching || isFiltering}
            success={getCustomersSuccess || searchSuccess || filterSuccess}
            sortSuccess={searchSuccess || filterSuccess}
            total={totalCustomers}
          />
        }
        right1={
          <SortData
            placeholder={"Search by name, email"}
            filter={true}
            searchItems={searchAllUsers}
            setState={setState}
            state={state}
            onClick={() => openModal("filterModal")}
            sortSuccess={filterSuccess || searchSuccess}
            refreshFunction={() =>
              getCustomersHandler(
                1,
                10,
                state.active1 ? "customer" : "influencer"
              )
            }
          />
        }
        right2={"false"}
      />
      <FilterCustomer
        displayModal={state.filterModal}
        closeModal={() => closeModal("filterModal")}
        state={state}
        setState={setState}
        filterSuccess={filterSuccess}
        isFiltering={isFiltering}
        filterCustomers={filteredCustomersHandler}
      />
    </div>
  );
};
const mapStateToProps = (state) => ({
  isGetting: state.referralreport.isGetting,
  getCustomersSuccess: state.referralreport.getCustomersSuccess,
  customers: state.referralreport.customers,
  isSearching: state.referralreport.isSearching,
  searchSuccess: state.referralreport.searchSuccess,
  searchedCustomers: state.referralreport.searchedCustomers,
  isFiltering: state.referralreport.isFiltering,
  filterSuccess: state.referralreport.filterSuccess,
  filteredCustomers: state.referralreport.filteredCustomers,
  customersData: state.referralreport.customersData,
  filteredCustomersData: state.referralreport.filteredCustomersData,
  searchedCustomersData: state.referralreport.searchedCustomersData,
});

const mapDispatchToProps = (dispatch) => ({
  getCustomersHandler: (page, limit, type) =>
    dispatch(getCustomers(page, limit, type)),
  searchCustomersHandler: (page, limit, type, search) =>
    dispatch(searchCustomers(page, limit, type, search)),
  filteredCustomersHandler: (page, limit, type, payload) =>
    dispatch(filterCustomers(page, limit, type, payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferralCodeManager);
