import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";

// GET DELIVERY_FEES
export const getDeliveryFeesStart = () => ({
  type: Actions.GET_DELIVERY_FEES_START,
});

export const getDeliveryFeesSuccess = (payload) => ({
  type: Actions.GET_DELIVERY_FEES_SUCCESS,
  payload,
});

export const getDeliveryFeesFailure = (payload) => ({
  type: Actions.GET_DELIVERY_FEES_FAILURE,
  payload,
});

// CREATE DELIVERY_FEE
export const createDeliveryFeeStart = () => ({
  type: Actions.CREATE_DELIVERY_FEE_START,
});

export const createDeliveryFeeSuccess = (payload) => ({
  type: Actions.CREATE_DELIVERY_FEE_SUCCESS,
  payload,
});

export const createDeliveryFeeFailure = (payload) => ({
  type: Actions.CREATE_DELIVERY_FEE_FAILURE,
  payload,
});

// EDIT DELIVERY_FEE
export const editDeliveryFeeStart = () => ({
  type: Actions.EDIT_DELIVERY_FEE_START,
});

export const editDeliveryFeeSuccess = (payload) => ({
  type: Actions.EDIT_DELIVERY_FEE_SUCCESS,
  payload,
});

export const editDeliveryFeeFailure = (payload) => ({
  type: Actions.EDIT_DELIVERY_FEE_FAILURE,
  payload,
});

// DELETE DELIVERY_FEE
export const deleteDeliveryFeeStart = () => ({
  type: Actions.DELETE_DELIVERY_FEE_START,
});

export const deleteDeliveryFeeSuccess = (payload) => ({
  type: Actions.DELETE_DELIVERY_FEE_SUCCESS,
  payload,
});

export const deleteDeliveryFeeFailure = (payload) => ({
  type: Actions.DELETE_DELIVERY_FEE_FAILURE,
  payload,
});


export const getDeliveryFees = (page, limit, search) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getDeliveryFeesStart());
  API.get(
    search
      ? `delivery-fee?page=${page}&limit=${limit}&q=${search}`
      : `delivery-fee?page=${page}&limit=${limit}`
  ).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getDeliveryFeesSuccess(data && data.data));
    } else {
      dispatch(getDeliveryFeesFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const createDeliveryFee = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(createDeliveryFeeStart());
  API.post("delivery-fee", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(createDeliveryFeeSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(createDeliveryFeeFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const editDeliveryFee = (id, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(editDeliveryFeeStart());
  API.put(`delivery-fee/edit-delivery-fee/${id}`, payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(editDeliveryFeeSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(editDeliveryFeeFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const deleteDeliveryFee = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(deleteDeliveryFeeStart());
  API.delete(`delivery-fee/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(deleteDeliveryFeeSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(deleteDeliveryFeeFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

