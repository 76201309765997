import React, { useEffect, useState } from "react";
import CategoryInputs from "./CategoryInputs";
import {
  filterAppointmentSubcategories,
  filterArrayObj,
  filterObj3,
} from "../../../utils/functions/FilterObject";
import {
  editAppointmentCategory,
  getAllAppointmentCategories,
  getSingleAppointmentCategory,
  swapAppointmentSubcategoryRank,
} from "../../../redux/Actions/AppointmentActions";
import { uploadImage } from "../../../redux/Actions/UploadActions";
import { connect } from "react-redux";
import { updateSubcategories } from "../../../utils/functions/UpdateValues";
import HeaderWrapper from "../../Wrappers/HeaderWrapper";
import BackBtn from "../../Input/BackBtn";
import { useHistory } from "react-router";
import { LoaderSpin } from "../../Loader/Loader";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const EditCategory = (props) => {
  const {
    isEditing,
    editCategory,
    isUploading,
    uploadFile,
    data,
    editSuccess,
    isGetting,
    getSuccess,
    getCategory,
    swapAppointmentSubcategoryRankHandler,
  } = props;

  const history = useHistory();
  const id = props.location.state && props.location.state.id;

  useEffect(
    () => {
      getCategory(id);
    },
    // eslint-disable-next-line
    [getCategory]
  );

  const [error, setError] = useState("");

  const [state, setState] = useState({
    index: "",
    subcategory: [],
    loaded: false,
    file_name: data && data.image_url,
    status: data && data.status,
  });

  const [subcategoryState, setSubcategoryState] = useState([]);

  const subcategory = data && data.appointment_subcategory;

  useEffect(
    () => {
      if (getSuccess) {
        setState({
          ...state,
          loaded: true,
          file_name: data && data.image_url,
          subcategory: subcategory && updateSubcategories(subcategory),
          status: data && data.status,
        });

        setSubcategoryState(subcategory && updateSubcategories(subcategory));
      }
    },
    // eslint-disable-next-line
    [getSuccess]
  );

  const initialValues = {
    category_name: (data && data.name) || "",
    description: (data && data.caption) || "",
    rank: (data && data.rank) || "",
  };

  const filteredSubcategories =
    subcategoryState && filterAppointmentSubcategories(subcategoryState);
  const filteredPayload = subcategoryState && filterObj3(subcategoryState);
  const subCategories = filteredPayload && filterArrayObj(filteredPayload);

  const disabled = (isValid, dirty, values) =>
    values.category_name === "" ||
    values.description === "" ||
    isUploading ||
    isEditing ||
    state.file_name === "" ||
    filteredSubcategories ||
    error !== "";

  const submitValues = (values) => {
    const payload = {
      name: values.category_name,
      description: values.description,
      rank: values.rank,
      image_url: state.file_name,
      status: state.status,
      appointment_subcategories: subCategories,
    };
    console.log(payload);

    editCategory(id, payload);
  };

  useEffect(
    () => {
      if (editSuccess) {
        history.push("/appointment-manager");
      }
    },
    // eslint-disable-next-line
    [editSuccess]
  );

  return (
    <div className="assessment-manager-single">
      <BackBtn page="/appointment-manager" />
      <HeaderWrapper title={"Edit Category"} />

      {isGetting ? (
        <div className="loader-table2">
          <LoaderSpin width="40px" height="40px" />
        </div>
      ) : getSuccess ? (
        <div className="assessment-manager-single-container">
          <DndProvider backend={HTML5Backend}>
            <CategoryInputs
              subcategoryState={subcategoryState}
              setSubcategoryState={setSubcategoryState}
              state={state}
              setState={setState}
              uploadFile={uploadFile}
              isLoading={isEditing}
              disabledFunc={(isValid, dirty, values) =>
                disabled(isValid, dirty, values)
              }
              isUploading={isUploading}
              setIconState={setState}
              iconState={state}
              formId={"editCategory"}
              actionType={"edit"}
              error={error}
              setError={setError}
              initialValues={initialValues}
              submitValues={submitValues}
              statusValue={data && data.status}
              swapAppointmentSubcategoryRankHandler={
                swapAppointmentSubcategoryRankHandler
              }
            />
          </DndProvider>
        </div>
      ) : (
        <div className="auth-header">
          <h1>Error fetching single appointment category</h1>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isEditing: state.appointment.isEditingCategory,
  isUploading: state.upload.isUploading,
  uploadSuccess: state.upload.uploadSuccess,
  editSuccess: state.appointment.editSuccess,
  isGetting: state.appointment.isGettingSingle,
  getSuccess: state.appointment.getSuccess,
  data: state.appointment.singleCategory,
});

const mapDispatchToProps = (dispatch) => ({
  getCategory: (id) => dispatch(getSingleAppointmentCategory(id)),
  editCategory: (id, payload) => dispatch(editAppointmentCategory(id, payload)),
  uploadFile: (payload) => dispatch(uploadImage(payload)),
  getCategories: (page, limit) =>
    dispatch(getAllAppointmentCategories(page, limit)),
  swapAppointmentSubcategoryRankHandler: (payload) =>
    dispatch(swapAppointmentSubcategoryRank(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCategory);
