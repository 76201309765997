import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import Modal, { ModalButton } from "../../Modal/Modal";
import Input from "../../Input/Input";
import { connect } from "react-redux";
import {
  editBlogCategory,
  getAllBlogCategories,
} from "../../../redux/Actions/BlogActions";
import { Statuses } from "../../Input/Statuses";
import { getCurrentPagination } from "../../../utils/functions/GetStorageItems";

const EditCategory = (props) => {
  const {
    displayModal,
    closeModal,
    isEditing,
    editCategory,
    editSuccess,
    data,
    getCategories,
  } = props;

  const id = data && data.id;
  const { page, perPage } = getCurrentPagination();

  const initialValues = {
    name: data && data.name,
  };

  const [state, setState] = useState({
    active: data && data.status !== "" ? true : false,
    status: data && data.status === "active" ? "Activate" : "Deactivate",
    index: data && data.status === "active" ? 0 : 1,
  });

  const status = [{ name: "Activate" }, { name: "Deactivate" }];

  const submitValues = (values) => {
    const payload = {
      name: values.name,
      status: state.status === "Activate" ? "active" : "deactivated",
    };
    editCategory(id, payload);
  };

  useEffect(
    () => {
      if (editSuccess) {
        closeModal();
        getCategories(page, perPage);
      }
    },
    // eslint-disable-next-line
    [editSuccess]
  );

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Edit Category"}
      width={"496px"}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => submitValues(values)}
      >
        {({ isValid, dirty, setFieldValue, values }) => {
          const disabled =
            values.name === "" || isEditing || state.status === "";
          return (
            <Form id="editCategory">
              <Field
                name="name"
                label="Category name"
                placeholder="Enter category name"
                component={Input}
                emptyValue={setFieldValue}
              />

              <div className="top-spacer">
                <Statuses
                  label={"Status"}
                  state={state}
                  options={status}
                  setstate={setState}
                  blue={false}
                  numberPerRow={3}
                />
              </div>

              <ModalButton
                isLoading={isEditing}
                text={"Save Changes"}
                type={"submit"}
                testId={"button"}
                loadingText={"Editing..."}
                disabled={disabled}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isEditing: state.blogcategory.isEditing,
  editSuccess: state.blogcategory.editSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  editCategory: (id, payload) => dispatch(editBlogCategory(id, payload)),
  getCategories: (page, limit) => dispatch(getAllBlogCategories(page, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCategory);
