export const adminPermissions = [
  {
    id: "can_manage_overview",
    name: "Overview",
    checked: false,
  },
  {
    id: "can_manage_appointment", 
    name: "Appointment Manager",
    checked: false,
  },
  {
    id: "can_manage_user_appointment",
    name: "User Appointments",
    checked: false,
  },
  {
    id: "can_manage_product",
    name: "Product Management",
    checked: false,
  },
  {
    id: "can_manage_facility",
    name: "Facility",
    checked: false,
  },
  {
    id: "can_manage_blog",
    name: "Blog Management",
    checked: false,
  },
  {
    id: "can_manage_users",
    name: "Users",
    checked: false,
  },
  {
    id: "can_manage_payment_plans",
    name: "Payment Plans",
    checked: false,
  },
  {
    id: "can_manage_audit_trail",
    name: "Audit Trail",
    checked: false,
  },
  {
    id: "can_manage_coupons",
    name: "Coupons",
    checked: false,
  },
  {
    id: "can_manage_chat",
    name: "Chat Management",
    checked: false,
  },
  {
    id: "can_manage_administrators",
    name: "Administrators",
    checked: false,
  },
  {
    id: "can_manage_orders",
    name: "Order Management",
    checked: false,
  },
  {
    id: "can_manage_referrals",
    name: "Referrals Report",
    checked: false,
  },
];
