import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SelectInput from "../../components/Input/Select";
import { Statuses } from "../../components/Input/Statuses";
import Modal, { ModalButton } from "../../components/Modal/Modal";
import { assignChat } from "../../redux/Actions/ChatActions";
import { getSpecialists } from "../../redux/Actions/UserAppointmentActions";
import { assignSpecialistsOptions } from "../../utils/functions/UpdateValues";

const TransferUser = (props) => {
  const {
    displayModal,
    closeModal,
    state,
    setState,
    specialists,
    isGettingSpecialists,
    getSpecialistsSuccess,
    getSpecialists,
    id,
    assignChat,
    isAssigning,
    assignSuccess,
    getChats,
  } = props;

  const [specialistOptions, setSpecialistOptions] = useState([]);

  useEffect(
    () => {
      getSpecialists("doctor");
    },
    // eslint-disable-next-line
    [getSpecialists]
  );

  const types = [{ name: "Whispa Doctor" }, { name: "Consultant" }];

  useEffect(
    () => {
      if (getSpecialistsSuccess) {
        const updatedSpecialists = assignSpecialistsOptions(specialists);
        setSpecialistOptions(updatedSpecialists);
      }
    },
    // eslint-disable-next-line
    [getSpecialistsSuccess]
  );

  const updateSelection = (data) => {
    setState({
      ...state,
      specialist: data.value,
    });
  };

  const setActiveState = (index, status) => {
    setState({
      ...state,
      active: true,
      status: status,
      index: index,
      specialist: "",
    });
    callSpecialistFunction(status);
  };

  const callSpecialistFunction = (status) => {
    status === "Consultant"
      ? getSpecialists("consultant")
      : getSpecialists("doctor");
  };

  const submitValues = () => {
    const payload = {
      user_id: state.specialist,
      user_type: state.status === "Whispa Doctor" ? "doctor" : "consultant",
    };
    assignChat(id, payload);
  };

  useEffect(
    () => {
      if (assignSuccess) {
        getChats(state.active1 ? "active" : "pending");
        setState({
          ...state,
          chatId: "",
          chat: "",
          transferUser: false,
          specialist: "",
          status: "Whispa Doctor",
          active: true,
          index: 0,
        });
      }
    },
    // eslint-disable-next-line
    [assignSuccess]
  );

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Transfer User"}
      width={"496px"}
    >
      <Statuses
        label={"Transfer To"}
        state={state}
        options={types}
        setstate={setState}
        blue={false}
        numberPerRow={2}
        marginTop={"0px"}
        onClick={setActiveState}
      />

      <div className="top-spacer2">
        <SelectInput
          label={`Choose ${state.status}`}
          options={specialistOptions}
          onChange={updateSelection}
          readOnly={isGettingSpecialists}
          success={assignSuccess}
        />
      </div>

      <ModalButton
        isLoading={isAssigning}
        text={"Transfer Chat"}
        testId={"button"}
        loadingText={"Transfering..."}
        onClick={submitValues}
        disabled={
          isGettingSpecialists || isAssigning || state.specialist === ""
        }
      />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isGettingSpecialists: state.userappointment.isGettingSpecialists,
  getSpecialistsSuccess: state.userappointment.getSpecialistsSuccess,
  specialists: state.userappointment.specialists,
  isAssigning: state.chats.isAssigning,
  assignSuccess: state.chats.assignSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getSpecialists: (type) => dispatch(getSpecialists(type)),
  assignChat: (id, payload) => dispatch(assignChat(id, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransferUser);
