import React, { useEffect } from "react";
import { connect } from "react-redux";
import { revokeAdmin } from "../../redux/Actions/AdminActions";
import {
  assignPermissionData,
  permissionData,
  permissionPayload,
} from "../../utils/functions/CreateNewArray";
import DeleteModal from "../Modal/DeleteModal";

const SuspendAdmin = (props) => {
  const { revokeAdmin, isRevoking, data, revokeSuccess } = props;

  useEffect(
    () => {
      if (revokeSuccess) {
        props.closeModal();
      }
    }, // eslint-disable-next-line
    [revokeSuccess]
  );

  const newArray = data && assignPermissionData(data);
  const permissions = newArray && permissionData(newArray);
  const permissionsPayload = permissions && permissionPayload(permissions);

  const handleRevoke = () => {
    const payload = {
      id: data.id,
      status: "suspended",
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      role: data.role,
      ...permissionsPayload,
    };
    revokeAdmin(payload);
  };
  return (
    <DeleteModal
      displayModal={props.displayModal}
      closeModal={props.closeModal}
      title={"Suspend this admin?"}
      width={"463px"}
      text={"Suspend"}
      onClick={handleRevoke}
      disabled={isRevoking}
      isLoading={isRevoking}
      loadingText={"Suspending..."}
      children={
        "Once you suspend this admin, this admin won't be active. Suspend admin?"
      }
    />
  );
};
const mapStateToProps = (state) => ({
  isRevoking: state.admin.isRevoking,
  revokeSuccess: state.admin.revokeSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  revokeAdmin: (payload) => dispatch(revokeAdmin(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuspendAdmin);
