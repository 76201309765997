import React, { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import { Form, Formik } from "formik";
import PlanInputs from "./PlanInputs";
import { editPlan, getAllPlans } from "../../redux/Actions/PaymentPlanActions";
import { connect } from "react-redux";
import {
  assignPlanOptions,
  capitalizeFirstLetter,
} from "../../utils/functions/UpdateValues";
import { filterArray, filterObj } from "../../utils/functions/FilterObject";

const EditPlan = (props) => {
  const {
    data,
    displayModal,
    closeModal,
    editPlan,
    isEditingPlan,
    editSuccess,
    getPlans,
    enterpriseData
  } = props;

  const [state, setState] = useState({
    plan_validity: data && data.payment_frequency,
    benefits: [],
    auto_renew: "",
    unlimited_chat: "",
    enterpriseId: ""
  });

  const initialValues = {
    name: data && data.name,
    amount: data && data.amount,
    caption: data && data.caption,
    tag: data && data.tag,
  };

  const benefits = data && data.benefits;
  const type = data && data.name;

  useEffect(
    () => {
      if (displayModal) {
        setState({
          ...state,
          benefits: benefits && assignPlanOptions(benefits),
          auto_renew: data && data.auto_renew,
          unlimited_chat: data && data.unlimited_chat,
          enterpriseId: data && data.enterprise_id
        });
      }
    },
    // eslint-disable-next-line
    [displayModal]
  );

  const filteredBenefits = state.benefits && filterArray(state.benefits);

  const submitValues = (values) => {
    console.log('aaa')
    const payload = {
      id: data && data.id,
      name: values.name,
      amount: values.amount.toString(),
      validity: state.plan_validity,
      caption: values.caption,
      tag: values.tag,
      benefits: filteredBenefits,
      auto_renew: state.auto_renew,
      unlimited_chat: state.unlimited_chat,
      enterprise_id: state.enterpriseId
    };
    const filteredPayload = filterObj(payload);
    editPlan(filteredPayload);
  };

  useEffect(
    () => {
      if (editSuccess) {
        closeModal();
        getPlans();
      }
    },
    // eslint-disable-next-line
    [editSuccess]
  );

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={`Edit ${type}`}
      width={"568px"}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => submitValues(values)}
      >
        {({ isValid, dirty, setFieldValue, values }) => {
          const disabled =
            isEditingPlan ||
            (filteredBenefits && filteredBenefits.length === 0) ||
            state.plan_validity === "" ||
            values.name === "" ||
            values.caption === "" ||
            values.amount === "";

          return (
            <Form id="form">
              <PlanInputs
                state={state}
                setState={setState}
                text={"Save Changes"}
                loadingText={"Saving..."}
                defaultOption={capitalizeFirstLetter(data?.payment_frequency)}
                defaultOption2={data?.unlimited_chat ? "True" : "False"}
                disabled={disabled}
                isLoading={isEditingPlan}
                setFieldValue={setFieldValue}
                enterpriseId={state.enterpriseId}
                enterpriseData={enterpriseData}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isEditingPlan: state.plan.isEditingPlan,
  editSuccess: state.plan.editSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getPlans: () => dispatch(getAllPlans()),
  editPlan: (payload) => dispatch(editPlan(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPlan);
