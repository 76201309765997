import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";
import { omitBy, isEmpty } from "lodash";
import downloadCSV from "../../utils/functions/csvDownloader";

// GET ALL PLANS
export const getAllPlansStart = () => ({
  type: Actions.GET_ALL_PLANS_START,
});

export const getAllPlansSuccess = (payload) => ({
  type: Actions.GET_ALL_PLANS_SUCCESS,
  payload,
});

export const getAllPlansFailure = (payload) => ({
  type: Actions.GET_ALL_PLANS_FAILURE,
  payload,
});

// GET ALL SUBSCRIBERS
export const getAllSubscribersStart = () => ({
  type: Actions.GET_ALL_SUBSCRIBERS_START,
});

export const getAllSubscribersSuccess = (payload) => ({
  type: Actions.GET_ALL_SUBSCRIBERS_SUCCESS,
  payload,
});

export const getAllSubscribersFailure = (payload) => ({
  type: Actions.GET_ALL_SUBSCRIBERS_FAILURE,
  payload,
});

// CREATE NEW PLAN
export const createPlanStart = () => ({
  type: Actions.CREATE_PLAN_START,
});

export const createPlanSuccess = (payload) => ({
  type: Actions.CREATE_PLAN_SUCCESS,
  payload,
});

export const createPlanFailure = (payload) => ({
  type: Actions.CREATE_PLAN_FAILURE,
  payload,
});

// EDIT PLAN
export const editPlanStart = () => ({
  type: Actions.EDIT_PLAN_START,
});

export const editPlanSuccess = (payload) => ({
  type: Actions.EDIT_PLAN_SUCCESS,
  payload,
});

export const editPlanFailure = (payload) => ({
  type: Actions.EDIT_PLAN_FAILURE,
  payload,
});

// DELETE PLAN
export const deletePlanStart = () => ({
  type: Actions.DELETE_PLAN_START,
});

export const deletePlanSuccess = (payload) => ({
  type: Actions.DELETE_PLAN_SUCCESS,
  payload,
});

export const deletePlanFailure = (payload) => ({
  type: Actions.DELETE_PLAN_FAILURE,
  payload,
});

// GET MOST SUBSCRIBED PLAN
export const getMostSubscribedPlanStart = () => ({
  type: Actions.GET_MOST_SUBSCRIBED_PLAN_START,
});

export const getMostSubscribedPlanSuccess = (payload) => ({
  type: Actions.GET_MOST_SUBSCRIBED_PLAN_SUCCESS,
  payload,
});

export const getMostSubscribedPlanFailure = (payload) => ({
  type: Actions.GET_MOST_SUBSCRIBED_PLAN_FAILURE,
  payload,
});

// SEARCH ALL SUBSCRIBERS
export const searchAllSubscribersStart = () => ({
  type: Actions.SEARCH_ALL_SUBSCRIBERS_START,
});

export const searchAllSubscribersSuccess = (payload) => ({
  type: Actions.SEARCH_ALL_SUBSCRIBERS_SUCCESS,
  payload,
});

export const searchAllSubscribersFailure = (payload) => ({
  type: Actions.SEARCH_ALL_SUBSCRIBERS_FAILURE,
  payload,
});

// FILTER ALL SUBSCRIBERS
export const filterAllSubscribersStart = () => ({
  type: Actions.FILTER_ALL_SUBSCRIBERS_START,
});

export const filterAllSubscribersSuccess = (payload) => ({
  type: Actions.FILTER_ALL_SUBSCRIBERS_SUCCESS,
  payload,
});

export const filterAllSubscribersFailure = (payload) => ({
  type: Actions.FILTER_ALL_SUBSCRIBERS_FAILURE,
  payload,
});

// CHANGE SUBSCRIPTION PLAN STATUS
export const changeSubscriptionPlanStatusStart = () => ({
  type: Actions.CHANGE_SUBSCRIPTION_PLAN_STATUS_START,
});

export const changeSubscriptionPlanStatusSuccess = (payload) => ({
  type: Actions.CHANGE_SUBSCRIPTION_PLAN_STATUS_SUCCESS,
  payload,
});

export const changeSubscriptionPlanStatusFailure = (payload) => ({
  type: Actions.CHANGE_SUBSCRIPTION_PLAN_STATUS_FAILURE,
  payload,
});

export const getExportCSVSuccess = (payload) => ({
  type: Actions.GET_EXPORT_CSV_SUCCESS,
  payload,
});

export const isGettingCSVResponse = () => ({
  type: Actions.GET_CSV_START,
});

export const isAssigningSubscriptionPlan = () => ({
  type: Actions.ASSIGN_SUBSCRIPTION_START,
});
export const assignSubscriptionPlanSuccess = () => ({
  type: Actions.ASSIGN_SUBSCRIPTION_SUCCESS,
});
export const assignSubscriptionFailure = () => ({
  type: Actions.ASSIGN_SUBSCRIPTION_FAILURE,
});

export const assignSubscriptionPlan = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  dispatch(isAssigningSubscriptionPlan());
  API.post("/subscription-payment/assign", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(assignSubscriptionPlanSuccess());
      responseHandler(response, "Success");
    } else {
      dispatch(assignSubscriptionFailure());
      responseHandler(response, "Failed");
    }
  });
};

export const getAllPlans = () => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  dispatch(getAllPlansStart());
  API.get(`/subscription-plan`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getAllPlansSuccess(data && data.data));
    } else {
      dispatch(getAllPlansFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const getAllSubscribers = (page, limit) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getAllSubscribersStart());
  API.get(`/subscription-plan/subscribers?page=${page}&limit=${limit}`).then(
    (response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(getAllSubscribersSuccess(data && data.data));
      } else {
        dispatch(getAllSubscribersFailure(data));
        responseHandler(response, "Failed!");
      }
    }
  );
};

export const createPlan = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(createPlanStart());
  API.post("/subscription-plan", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(createPlanSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(createPlanFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const editPlan = (payload) => async (dispatch) => {
  console.log("start");
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(editPlanStart());
  API.put("/subscription-plan", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      console.log("yes");
      dispatch(editPlanSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      console.log("no");
      dispatch(editPlanFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const deletePlan = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(deletePlanStart());
  API.delete(`/subscription-plan/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(deletePlanSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(deletePlanFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const getMostSubscribedPlan = () => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getMostSubscribedPlanStart());
  API.get("/subscription-plan/most-subscribed").then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getMostSubscribedPlanSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(getMostSubscribedPlanFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const searchAllSubscribers =
  (page, limit, searchValue) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
    const searchUrl = `/subscription-plan/subscribers?page=${page}&limit=${limit}&search=${searchValue}`;

    console.log("searchUrl", searchUrl);
    // console.log('page, limit, searchValue', page, limit, searchValue)

    dispatch(searchAllSubscribersStart());
    API.get(searchUrl).then((response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(searchAllSubscribersSuccess(data && data.data));
      } else {
        dispatch(searchAllSubscribersFailure(data));
        responseHandler(response, "Failed!");
      }
    });
  };

export const getExportSubscribersCSVResponse =
  (payload, page, limit) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
    dispatch(isGettingCSVResponse());
    const getUrl = (payload, page, limit) => {
      let urlBase = `/subscription-plan/subscribers/export?`;
      if (payload.plan) {
        urlBase += `&plan=${payload.plan}`;
      }
      if (payload.subStartDate) {
        urlBase += `&subscribed-from=${payload.subStartDate}&subscribed-to=${payload.subEndDate}`;
      }

      return urlBase;
    };
    let urlToCall = getUrl(payload, page, limit);
    API.get(urlToCall).then((response) => {
      const { data, ok } = response;
      if (ok) {
        downloadCSV(data, "subscribed-users");
        dispatch(getExportCSVSuccess(data));
      } else {
        responseHandler(response, "Failed!");
      }
    });
  };

export const filterAllSubscribers =
  (page, limit, payload) => async (dispatch) => {
    const { plan, subStartDate, subEndDate } = payload;
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
    let urlBase = `/subscription-plan/subscribers/filter?page=${page}&limit=${limit}&plan=${payload.plan}`;

    const url =
      subEndDate && subStartDate && !plan
        ? `/subscription-plan/subscribers/filter?page=${page}&limit=${limit}&subscribed-from=${payload.subStartDate}&subscribed-to=${payload.subEndDate}`
        : plan && !(subStartDate && subEndDate)
        ? urlBase
        : `/subscription-plan/subscribers/filter?page=${page}&limit=${limit}&subscribed-from=${payload.subStartDate}&subscribed-to=${payload.subEndDate}&plan=${payload.plan}`;

    // let urlToCall = getUrl(payload, page, limit);

    dispatch(filterAllSubscribersStart());
    API.get(url).then((response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(filterAllSubscribersSuccess(data && data.data));
      } else {
        dispatch(filterAllSubscribersFailure(data));
        responseHandler(response, "Failed!");
      }
    });
  };

export const changeSubscriptionPlanStatus = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(changeSubscriptionPlanStatusStart());
  API.put("/subscription-plan/subscription/change-status", payload).then(
    (response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(changeSubscriptionPlanStatusSuccess(data && data.data));
        responseHandler(response, "Success!");
      } else {
        dispatch(changeSubscriptionPlanStatusFailure(data));
        responseHandler(response, "Failed!");
      }
    }
  );
};
