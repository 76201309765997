import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import {
  filterPrescriptions,
  getPrescriptions,
  searchPrescriptions,
} from "../../redux/Actions/prescriptionActions";
import PrescriptionsColumns from "../../components/Prescriptions/Prescriptions";
import { cancelModal, viewModal } from "../../components/Modal/Modal";
import FilterPrescriptions from "../../components/Prescriptions/FilterPrescriptions";
import SortData from "../../components/Sorting/SortData";
import ExportIcon from "../../assets/Union.svg";
import DropIcon from "../../assets/drop-export.svg";
import downloadCSV from "../../utils/functions/csvDownloader";
import TableDropdown from "../../components/Tables/Dropdown";
import { PageButton } from "../../components/Button/Button";

const Prescriptions = (props) => {
  const [state, setState] = useState({
    filterModal: false,
    prescribedFrom: "",
    prescribedTo: "",
    searchValue: "",
  });
  const {
    getPrescriptionsData,
    prescriptions,
    prescriptionData,
    searchData,
    isGetting,
    getPrescriptionsSuccess,
    isFiltering,
    filterSuccess,
    filteredData,
    filterData,
    searchPrescriptionsData,
    isSearching,
    searchSuccess,
    searchedData,
    filterPrescriptionsData,
  } = props;
  useEffect(() => {
    getPrescriptionsData(1, 10);
  }, []);

  const [show, setShow] = useState(false);
  const prescriptionsData = searchSuccess
    ? searchedData
    : filterSuccess
    ? filteredData
    : getPrescriptionsSuccess
    ? prescriptions
    : prescriptions;

  const moreData = (page, perPage) => {
    const payload = {};
    payload.prescribedFrom = state.prescribedFrom;
    payload.prescribedTo = state.prescribedTo;
    return searchSuccess
      ? searchPrescriptionsData(page, perPage, state.searchValue)
      : filterSuccess
      ? filterPrescriptionsData(page, perPage, payload)
      : getPrescriptionsData(page, perPage);
  };

  const totalPrescriptions = searchSuccess
    ? searchData && searchData.total
    : filterSuccess
    ? filterData && filterData.total
    : prescriptionData && prescriptionData.total;

  const searchAllPrescriptions = () => {
    if (state.searchValue === "") {
      return prescriptions;
    } else {
      searchPrescriptionsData(1, 10, state.searchValue);
    }
  };

  const openModal = (type) => viewModal(type, state, setState);
  const closeModal = (type) => cancelModal(type, state, setState);


  const exportHandler = (format) => {
    switch (format) {
      case "CSV":
        const data = prescriptionsData;
        const exportData =
          data &&
          data.map((prescription) => ({
            Doctor: prescription.doctor?.full_name,
            Customer:
              prescription.user?.full_name || prescription.user?.username,
            "Prescription Number": prescription.prescription_number,
            "Prescription Items": prescription.prescription_items
              ?.map((el) => el.drug)
              .join(","),
            Status: prescription.status,
          }));
        downloadCSV(exportData, "prescriptions");
        return;
      default:
        return;
    }
  };

  const export_options = [
    {
      name: "CSV",
      action: () => exportHandler("CSV"),
    },
  ];
  return (
    <div>
      <HeaderWrapper
        title={"Prescriptions"}
        subtitle={"View and Manage all Prescriptions"}
      >
        <div className="overview-header-right-button">
          <PageButton
            width={"121px"}
            mainWidth={"144px"}
            background={"rgba(10, 116, 220, 0.08)"}
            // marginTop={"35px"}
            type={"button"}
            onClick={() => setShow((prevCheck) => !prevCheck)}
          >
            <div className="button-data">
              <img src={ExportIcon} alt="export icon" />
              <span className="span2">Export</span>
              <img src={DropIcon} alt="dropdown" />
            </div>
          </PageButton>
          {show && (
            <TableDropdown
              options={export_options}
              hideDropdown={() => setShow((prevCheck) => !prevCheck)}
              active
            />
          )}
        </div>
      </HeaderWrapper>
      <SortData
        placeholder={"Search Prescriptions"}
        onClick={() => openModal("filterModal")}
        setState={setState}
        state={state}
        filter={true}
        sortSuccess={filterSuccess || searchSuccess}
        refreshFunction={() => {
          setState({
            ...state,
            searchValue: "",
          });
          getPrescriptionsData(1, 10);
        }}
        searchItems={searchAllPrescriptions}
        width={"347px"}
      />
      <PrescriptionsColumns
        data={prescriptionsData}
        getData={getPrescriptions}
        success={getPrescriptionsSuccess || filterSuccess || searchSuccess}
        isLoading={isGetting || isFiltering || isSearching}
        total={totalPrescriptions}
        getMoreData={(page, perPage) => moreData(page, perPage)}
      />
      <FilterPrescriptions
        displayModal={state.filterModal}
        closeModal={() => closeModal("filterModal")}
        state={state}
        setState={setState}
      />
    </div>
  );
};
const mapStateToProps = (state) => ({
  prescriptions: state.prescriptions.prescriptionsData,
  isGetting: state.prescriptions.isGetting,
  getPrescriptionsSuccess: state.prescriptions.getPrescriptionsSuccess,
  isFiltering: state.prescriptions.isFiltering,
  filterSuccess: state.prescriptions.filterSuccess,
  filteredData: state.prescriptions.filteredData,
  searchSuccess: state.prescriptions.searchSuccess,
  searchedData: state.prescriptions.searchedData,
  filterData: state.prescriptions.filterData,
  searchData: state.prescriptions.searchData,
  isSearching: state.prescriptions.isSearching,
  prescriptionData: state.prescriptions.prescriptions,
});
const mapDispatchToProps = (dispatch) => ({
  getPrescriptionsData: (page, limit, payload) =>
    dispatch(getPrescriptions(page, limit, payload)),
  searchPrescriptionsData: (page, limit, payload) =>
    dispatch(searchPrescriptions(page, limit, payload)),
  filterPrescriptionsData: (page, limit, payload) =>
    dispatch(filterPrescriptions(page, limit, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Prescriptions);
