import React, { useEffect, useState } from "react";
import CategoryInputs from "./CategoryInputs";
import { connect } from "react-redux";
import { uploadImage } from "../../../redux/Actions/UploadActions";
import { addAppointmentCategory } from "../../../redux/Actions/AppointmentActions";
import {
  filterAppointmentSubcategories,
  filterArrayObj,
  filterObj2,
} from "../../../utils/functions/FilterObject";
import { useHistory } from "react-router";
import BackBtn from "../../Input/BackBtn";
import HeaderWrapper from "../../Wrappers/HeaderWrapper";
import { CategorySchema } from "../../../utils/validation/validation";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { swapAppointmentRank } from "../../../redux/Actions/AppointmentActions";

const AddCategory = (props) => {
  const { isAdding, addCategory, isUploading, uploadFile, addSuccess } = props;

  const history = useHistory();

  const [state, setState] = useState({
    index: "",

    loaded: false,
    file_name: "",
  });
  const [subcategoryState, setSubcategoryState] = useState([
    {
      name: "",
      image_url: "",
      description: "",
      direction: "",
      percentage_effectiveness: "",
      duration: "",
      hormones: "",
      price_range: "",
      amount: "",
    },
  ]);

  const initialValues = {
    category_name: "",
    description: "",
  };

  const filteredSubcategories =
    subcategoryState && filterAppointmentSubcategories(subcategoryState);
  const filteredPayload = subcategoryState && filterObj2(subcategoryState);
  const subCategories = filteredPayload && filterArrayObj(filteredPayload);

  const submitValues = (values) => {
    const payload = {
      name: values.category_name,
      description: values.description,
      image_url: state.file_name,
      appointment_subcategories: subCategories,
    };
    addCategory(payload);
  };

  useEffect(
    () => {
      if (addSuccess) {
        document.getElementById("addCategory")?.reset();
        history.push("/appointment-manager");
      }
    },
    // eslint-disable-next-line
    [addSuccess]
  );

  const disabled =
    isUploading || isAdding || filteredSubcategories || state.file_name === "";

  return (
    <div className="assessment-manager-single">
      <BackBtn page="/appointment-manager" />
      <HeaderWrapper title={"Create Category"} />
      <DndProvider backend={HTML5Backend}>
        <div className="assessment-manager-single-container">
          <CategoryInputs
            state={state}
            setState={setState}
            subcategoryState={subcategoryState}
            setSubcategoryState={setSubcategoryState}
            uploadFile={uploadFile}
            isLoading={isAdding}
            disabledFunc={(isValid, dirty, values) =>
              disabled || !(isValid && dirty)
            }
            isUploading={isUploading}
            setIconState={setState}
            iconState={state}
            formId={"addCategory"}
            actionType={"add"}
            schema={CategorySchema}
            initialValues={initialValues}
            submitValues={submitValues}
            swapAppointmentSubcategoryRankHandler={null}
          />
        </div>
      </DndProvider>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAdding: state.appointment.isAddingCategory,
  isUploading: state.upload.isUploading,
  uploadSuccess: state.upload.uploadSuccess,
  addSuccess: state.appointment.addSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  addCategory: (payload) => dispatch(addAppointmentCategory(payload)),
  uploadFile: (payload) => dispatch(uploadImage(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCategory);
