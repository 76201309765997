import React, { useState } from "react";
import { createCategoryArray } from "../../../utils/functions/UpdateValues";
import truncateName from "../../../utils/functions/ShortenStr";
import DeleteCategory from "../../AppointmentManager/AppointmentCategory/DeleteCategory";
import { cancelModal, viewModal } from "../../Modal/Modal";
import {
  DropBtn,
  TableDropdown,
  SuccessBtn,
  ErrorBtn,
} from "../../Tables/TableButtons";
import { useHistory } from "react-router";
import DeleteAssessment from "../../AppointmentManager/Assessment/DeleteAssessment";


export const appointmentManagerColumns = [
  {
    Header: "Category",
    accessor: "name",
  },
  {
    Header: "Description",
    accessor: "caption",
    Cell: ({ row }) => {
      return (
        <div className="action-flex">
          {row.original.caption
            ? truncateName(row.original.caption, 50)
            : "Not available"}
        </div>
      );
    },
  },
  {
    Header: "Subcategory",
    accessor: "appointment_subcategory",
    Cell: ({ row }) => {
      const subcategories =
        row.original.appointment_subcategory &&
        createCategoryArray(row.original.appointment_subcategory);
      const newSubcategories = truncateName(subcategories, 20);

      return <div className="action-flex">{newSubcategories}</div>;
    },
  },
  {
    Header: "",
    accessor: "action",
    Cell: ({ row }) => {
      const [state, setState] = useState({
        deleteModal: false,
        show: false,
        index: "",
      });

      const [show, setShow] = useState(false);

      const id = row.original.id;

      const openModal = (type) => {
        viewModal(type, state, setState);
        setShow((prevCheck) => !prevCheck);
      };

      const closeModal = (type) => {
        cancelModal(type, state, setState);
      };

      const history = useHistory();

      const goToSingle = () => {
        history.push({
          pathname: `/appointment-manager/edit-category/${id}`,
          state: {
            id: id,
            data: row.original,
          },
        });
        setShow((prevCheck) => !prevCheck);
      };

      const options = [
        {
          name: "Edit",
          action: () => goToSingle(),
        },
        {
          name: "Delete",
          action: () => openModal("deleteModal"),
        },
      ];

      return (
        <div className="action-flex">
          <DropBtn onClick={() => setShow((prevCheck) => !prevCheck)} />
          {show && <TableDropdown options={options} />}

          {state.deleteModal && (
            <DeleteCategory
              displayModal={state.deleteModal}
              closeModal={() => closeModal("deleteModal")}
              id={id}
            />
          )}
        </div>
      );
    },
  },
];

export const useAssessmentManagerColumns = (updateStatus) => {
  const history = useHistory();

  const columns = [
    {
      Header: "N/S",
      accessor: "",
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Title",
      accessor: "title",
      Cell: ({ row: { original } }) => {
        return <div>{original.title}</div>;
      },
    },
    {
      Header: "Category",
      accessor: "category",
      Cell: ({ row: { original } }) => {
        return <div>{original.category}</div>;
      },
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ row: { original } }) => {
        return <div>{original.type}</div>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row: { original } }) => {
        return (
          <div>
            {original.status === "active" ? (
              <SuccessBtn text={original.status} />
            ) : (
              <ErrorBtn text={original.status} />
            )}
          </div>
        );
      },
    },
    {
      Header: " ",
      accessor: " ",
      Cell: ({ row: { original } }) => {
        const [show, setShow] = useState(false);
        const [showDelete, setShowDelete] = useState(false);

        const options = [
          [
            {
              name: "Edit",
              action: () =>
                history.push({
                  pathname: `/appointment-manager/question/${original.id}/edit`,
                }),
            },
          ],
          [
            {
              name: "Activate",
              action: () => history.push("active", original.id),
            },
          ],
          [
            {
              name: "Inactivate",
              action: () => updateStatus("inactive", original.id),
            },
          ],
          [
            {
              name: "Delete",
              action: () => setShowDelete(true),
            },
          ],
        ];

        return (
          <div className="action-flex center">
            <DropBtn onClick={() => setShow((prevCheck) => !prevCheck)} />
            {show && (
              <TableDropdown
                hideDropdown={() => setShow((prevCheck) => !prevCheck)}
                fromLeft
                options={
                  original.status === "active"
                    ? [...options[0], ...options[2], ...options[3]]
                    : [...options[0], ...options[1], ...options[3]]
                }
              />
            )}

            {showDelete && (
              <DeleteAssessment
                displayModal={showDelete}
                closeModal={() => setShowDelete(false)}
                data={original}
              />
            )}
          </div>
        );
      },
    },
  ];

  return { columns };
};
