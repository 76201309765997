import React, { useEffect } from "react";
import { connect } from "react-redux";
import { deleteReferral } from "../../../redux/Actions/ReferralActions";
import DeleteModal from "../../Modal/DeleteModal";

const DeleteReferral = (props) => {
  const { deleteReferral, isDeleting, deleteSuccess, id } = props;

  useEffect(
    () => {
      if (deleteSuccess) {
        props.closeModal();
      }
    }, // eslint-disable-next-line
    [deleteSuccess]
  );

  const handleDelete = () => {
    deleteReferral(id);
  };
  return (
    <DeleteModal
      displayModal={props.displayModal}
      closeModal={props.closeModal}
      title={"Delete this referral?"}
      width={"463px"}
      text={"Delete"}
      onClick={handleDelete}
      disabled={isDeleting}
      isLoading={isDeleting}
      loadingText={"Deleting..."}
      children={
        "Once you delete this referral it won’t be possible to undo this action. Delete referral?"
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isDeleting: state.referrals.isDeleting,
  deleteSuccess: state.referrals.deleteSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  deleteReferral: (id) => dispatch(deleteReferral(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteReferral);
