import React from "react";

const Figures = ({ figuresData }) => { 
  const { total_user, total_appointment, total_product, total_chat } = figuresData;

  const details = [
    {
      number: total_user.total ?? "0",
      title: "Total number of Users",
      percentage: total_user.average_up?.slice(0, -1) ?? "0.00",
      increase: true,
    },
    {
      number: total_chat.total ?? "0",
      title: "Total number of Chats",
      percentage: total_chat.average_up?.slice(0, -1) ?? "0.00",
      increase: true,
    },
    {
      number: total_appointment.total ?? "0",
      title: "Total number of Appointments",
      percentage: total_appointment.average_up?.slice(0, -1) ?? "0.00",
      increase: true,
    },
    {
      number: total_product.total ?? "0",
      title: "Total Products sold",
      percentage: total_product.average_up?.slice(0, -1) ?? "0.00",
      increase: false,
    },
  ];
  return (
    <div className="overview-figures">
      {details &&
        details.map((detail, index) => (
          <div className="overview-figures-div" key={index}>
            <div className="overview-figures-div-top">
              <h2>{detail.number}</h2>
              <span
                style={{
                  color: detail.percentage === "0.00" ? "#DC0000" : "#00DCB4",
                }}
              >
                {detail.percentage === "0.00" ? "" : "+"}
                {detail.percentage}%
              </span>
            </div>
            <div className="overview-figures-div-bottom">{detail.title.toUpperCase()}</div>
          </div>
        ))}
    </div>
  );
};

export default Figures;
