import Actions from "../Types";

const initialState = {
  appVersionsData: [],
  isGetting: false,
  getAppVersionsSuccess: false,

  isUpdating: false,
  updateAppVersionsSuccess: false,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_APP_VERSIONS_START:
      return {
        ...state,
        isGetting: true,
        getAppVersionsSuccess: false,
        isUpdating: false,
        updateAppVersionsSuccess: false,
      };
    case Actions.GET_APP_VERSIONS_SUCCESS:
      return {
        ...state,
        isGetting: false,
        getAppVersionsSuccess: true,
        isUpdating: false,
        updateAppVersionsSuccess: false,
        appVersionsData: action.payload
      };
    case Actions.UPDATE_APP_VERSIONS_START:
      return {
        ...state,
        isGetting: false,
        getAppVersionsSuccess: false,
        isUpdating: true,
        updateAppVersionsSuccess: false,
      };
    case Actions.UPDATE_APP_VERSIONS_SUCCESS:
      return {
        ...state,
        isGetting: false,
        getAppVersionsSuccess: false,
        isUpdating: false,
        updateAppVersionsSuccess: true,
      };
    default:
      return state;
  }
};

export default reduxState
