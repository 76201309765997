import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { uploadImageCallBack } from "../../redux/Actions/UploadActions";

const BlogEditor = (props) => {
  const content = {
    entityMap: {},
    blocks: [
      {
        key: "637gr",
        text: "",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
  };

  return (
    <Editor
      initialContentState={props.type === "edit" ? props.contentState : content}
      wrapperClassName="blog-editor"
      editorClassName="demo-editor"
      placeholder="Type article..."
      onContentStateChange={props.onContentStateChange}
      spellCheck={true}
      toolbar={{
        options: [
          "inline",
          "blockType",
          "fontSize",
          "fontFamily",
          "list",
          "textAlign",
          "image",
          "colorPicker",
          "link",
          "history",
        ],
        inline: { inDropdown: true },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        link: { inDropdown: true, defaultTargetOption: "_self" },
        history: { inDropdown: true },
        image: {
          alt: { present: true, mandatory: false, previewImage: true },
          uploadEnabled: true,
          defaultSize: { height: "300px", width: "300px" },
          urlEnabled: true,
          uploadCallback: uploadImageCallBack,
        },
      }}
    />
  );
};

export default BlogEditor;
