import React from "react";
import { Doughnut } from "react-chartjs-2";
import { abbreviate } from "@pqt/abbreviate";

const PopularProducts = ({ productsData }) => {
  const { mostSoldProducts, totalNoOfProducts } = productsData;

  const bgColors = ["#F93D55", "#00DCB4", "#0A74DC", "#00DCB4"];
  const products = mostSoldProducts?.map((product, index) => ({
    ...product,
    color: bgColors[index],
  }));

  const data = {
    labels: mostSoldProducts?.map((product) => product.name),
    datasets: [
      {
        data: mostSoldProducts?.map((data) => data.total),
        backgroundColor: mostSoldProducts?.map((_, index) => bgColors[index]),
        innerWidth: "200",
        cutout: "70%",
      },
    ],
  };

  return (
    <div className="overview-recents-chart">
      <h5>Most popular products</h5>

      <div className="doughnut">
        <div>
          <Doughnut
            data={data}
            height={195}
            options={{
              responsive: true,
              maintainAspectRatio: true,
              plugins: {
                legend: {
                  display: false,
                },
              },
              layout: {
                padding: 60,
              },
            }}
          />
        </div>
        <div className="doughnut__info text-center">
          <h2>{abbreviate(Number(totalNoOfProducts?.total))}</h2>
          <p className="text-grey">Products sold</p>
        </div>
      </div>

      <div className="labels text-capitalize">
        {products &&
          products.map((product, index) => (
            <div className="labels-grid" key={index}>
              <div
                className="indicator"
                style={{ background: product.color }}
              ></div>
              <div className="texts">
                <h6>{product.name}</h6>{" "}
                <span>N {abbreviate(product?.total)}</span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PopularProducts;
