import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DeleteButton } from "../Button/Button";
import { cancelModal, viewModal } from "../Modal/Modal";
import DeleteModal from "../Modal/DeleteModal";

const FacilityHeader = (props) => {
  const {
    facilityName,
    clientName,
    status,
    deleteUser,
    isDeleting,
    deleteSuccess,
    ClientId,
  } = props;
  const [state, setState] = useState({
    deleteModal: false,
  });
  const openModal = (type) => viewModal(type, state, setState);
  const closeModal = (type) => cancelModal(type, state, setState);
  const history = useHistory();

  useEffect(
    () => {
      if (deleteSuccess) {
        closeModal();
        history.push("/users");
      }
    }, // eslint-disable-next-line
    [deleteSuccess]
  );

  return (
    <div className="top-wrap">
      <div className="top-wrap-top">
        <div className="client-header-top-text">
          <h2 style={{ marginBottom: "20px" }}>{facilityName}</h2>
          <div>HFCP Name: {clientName}</div>
          <p>{status}</p>
        </div>
        <div className="left-buttons">
          <DeleteButton
            children={"Delete"}
            width={"67px"}
            color={"#DC0000"}
            marginTop={"0px"}
            type={"submit"}
            onClick={() => openModal("deleteModal")}
            testId={"deleteButton"}
          />
        </div>

        <DeleteModal
          displayModal={state.deleteModal}
          title={"Delete this client?"}
          width={"463px"}
          text={"Delete"}
          onClick={() => deleteUser(ClientId)}
          disabled={isDeleting}
          isLoading={isDeleting}
          loadingText={"Deleting..."}
          children={
            "Once you delete this client it won’t be possible to undo this action. Delete client?"
          }
          closeModal={() => closeModal("deleteModal")}
        />
      </div>
    </div>
  );
};

export default FacilityHeader;
