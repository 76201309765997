import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/auth-logo.svg";

export const Headers = (props) => {
  const { className, flex, title1, title2 } = props;
  return (
    <div
      className={className ? className : `auth-header ${flex ? "flex" : ""}`}
    >
      <h1>{title1}</h1>
      <h4 style={{ textAlign: `${flex ? "center" : ""}` }}>{title2}</h4> 
    </div>
  );
}; 

Headers.propTypes = {
  title1: PropTypes.any,
  title2: PropTypes.any,
}; 

const AuthWrapper = (props) => {
  const { className, flex, children } = props;

  return (
    <div className={className}>
      <div className="auth-wrap">
        <div className={`auth-form ${flex ? "flex" : ""}`}>
          <Link to="/">
            <div className="brand-logo">
              <img src={Logo} alt="logo" />
            </div>
          </Link>
          <div className={flex ? "flex" : ""}>{children}</div>
        </div>
      </div>
    </div>
  );
};

AuthWrapper.propTypes = {
  children: PropTypes.any,
};

export default AuthWrapper;
