import React, { useEffect, useState } from "react";
import Roles from "../../components/Settings/Roles";
import Add from "../../assets/add.svg";
import { cancelModal, viewModal } from "../../components/Modal/Modal";
import CreateRole from "../../components/Settings/CreateRole";
import { PageButton } from "../../components/Button/Button";
import { connect } from "react-redux";
import { createRole, getAllRoles } from "../../redux/Actions/SettingActions";

const Settings = (props) => {
  const {
    isCreating,
    createSuccess,
    roles,
    getRolesSuccess,
    isGetting,
    createRole,
    getRoles,
  } = props;

  const [state, setState] = useState({
    addRoleModal: false,
  });

  const openModal = (type) => viewModal(type, state, setState);
  const closeModal = (type) => cancelModal(type, state, setState);

  useEffect(
    () => {
      if (createSuccess) {
        document.getElementById("createRole").reset();
        closeModal("addRoleModal");
        getRoles();
      }
    },
    // eslint-disable-next-line
    [createSuccess]
  );

  return (
    <div className="profile-page">
      <div className="profile-page-left">
        <div className="profile-page-left-header">
          <h2>Settings</h2>
        </div>
      </div>

      <div className="settings">
        <div className="settings-wrapper">
          <div className="settings-wrapper-left">
            <div className="settings-wrapper-left-one">Roles</div>
          </div>

          <div className="settings-wrapper-right">
            <PageButton
              width={"auto"}
              background={"rgba(10, 116, 220, 0.08)"}
              marginTop={"0px"}
              onClick={() => openModal("addRoleModal")}
              type={"submit"}
            >
              <div className="button-data">
                <img src={Add} alt="add-icon" />
                <span>{"Add Role"}</span>
              </div>
            </PageButton>
          </div>
        </div>

        <Roles
          getRoles={getRoles}
          isGetting={isGetting}
          getRolesSuccess={getRolesSuccess}
          roles={roles}
        />

        <CreateRole
          displayModal={state.addRoleModal}
          closeModal={() => closeModal("addRoleModal")}
          isCreating={isCreating}
          createSuccess={createSuccess}
          createRole={createRole}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createRole: (payload) => dispatch(createRole(payload)),
  getRoles: () => dispatch(getAllRoles()),
});

const mapStateToProps = (state) => ({
  isCreating: state.roles.isCreating,
  createSuccess: state.roles.createRoleSuccess,
  roles: state.roles.roles,
  getRolesSuccess: state.roles.getRolesSuccess,
  isGetting: state.roles.isGetting,
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
