import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import DeleteBlog from "../../BlogManagement/DeleteBlog";
import { cancelModal, viewModal } from "../../Modal/Modal";
import {
  ErrorBtn,
  SuccessBtn,
  DropBtn,
  TableDropdown,
} from "../../Tables/TableButtons";

export const handleData = (row) => {
  const data = row.original && row.original.categories;
  const columndata = Array.prototype.map
    .call(data, function (item) {
      return item.name;
    })
    .join(", ");

  return columndata;
};

export const blogColumns = [
  {
    Header: "Article by",
    accessor: "author_name",
    Cell: ({ row }) => (
      <div className="table-flex">
        <span>{(row.original && row.original.author_name) || "Nil"}</span>
      </div>
    ),
  },
  {
    Header: "Article Title",
    accessor: "title",
  },
  {
    Header: "Category",
    accessor: "category",
    Cell: ({ row }) => (
      <div className="table-flex">
        <span>
          {row.original.categories && row.original.categories.length > 0
            ? handleData(row)
            : "Nil"}
        </span>
      </div>
    ),
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ row }) =>
      row.original.status && row.original.status.toLowerCase() === "posted" ? (
        <SuccessBtn text={"Posted"} />
      ) : (
        <ErrorBtn text={"Failed"} />
      ),
  },
  {
    Header: "Action ",
    accessor: "action",
    Cell: ({ row }) => {
      const [show, setShow] = useState(false);
      const [state, setState] = useState({
        deleteModal: false,
      });

      const history = useHistory();

      const goToEditPage = () => {
        const id = row.original && row.original.id;
        history.push({
          pathname: `/blog-management/edit/${id}`,
          state: {
            id: id,
            data: row.original,
          },
        });
        setShow((prevCheck) => !prevCheck);
      };

      const openModal = (type) => {
        viewModal(type, state, setState);
        setShow((prevCheck) => !prevCheck);
      };

      const closeModal = (type) => {
        cancelModal(type, state, setState);
      };

      const options = [
        {
          name: "Edit",
          action: () => goToEditPage(),
        },
        {
          name: "Delete",
          action: () => openModal("deleteModal"),
        },
      ];

      return (
        <div className="action-flex">
          <DropBtn onClick={() => setShow((prevCheck) => !prevCheck)} />
          {show && <TableDropdown options={options} />}

          {state.deleteModal && (
            <DeleteBlog
              displayModal={state.deleteModal}
              closeModal={() => closeModal("deleteModal")}
              id={row.original && row.original.id}
            />
          )}
        </div>
      );
    },
  },
];
