import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTable, usePagination } from "react-table";
import { Body } from "./Body";
import { Pagination } from "./Pagination";
import update from "immutability-helper";

function TableComponent(props) {
  const {
    columns,
    data,
    emptyText1,
    emptyText2,
    onClick,
    isLoading,
    success,
    emptyResultText,
    sortSuccess,
    setPage,
    currentpage,
    perPage,
    totalPage,
    total,
    getNewData,
    className,
    auth,
    border,
    showFooter,
    isDraggable,
    reArrangedData,
    isDraggingRow,
    moveRowAsyncFunc,
  } = props;
  const [records, setRecords] = React.useState(data && data);

  useEffect(() => {
    setRecords(data);
  }, [data]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
  } = useTable(
    {
      columns,
      data: records,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            pageIndex: currentpage,
          }),
          [state]
        );
      },
      initialState: { pageIndex: currentpage },
      manualPagination: true,
      pageCount: totalPage,
    },
    usePagination
  );
  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex];
    setRecords(
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
    );
    moveRowAsyncFunc({
      array: [records[dragIndex].id, records[hoverIndex].id],
    });
  };

  const pageOptions = total && Math.ceil(Number(total) / 10);

  return (
    <>
      <Body
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        footerGroups={footerGroups}
        prepareRow={prepareRow}
        page={page}
        emptyText1={emptyText1}
        emptyText2={emptyText2}
        testRowClick={onClick}
        isLoading={isLoading}
        success={success}
        emptyResultText={emptyResultText}
        sortSuccess={sortSuccess}
        currentpage={currentpage}
        perPage={perPage}
        totalPage={totalPage}
        className={className ? className : ""}
        auth={auth}
        border={border}
        isDraggable={isDraggable}
        showFooter={showFooter}
        data={data}
        reArrangedData={reArrangedData}
        isDraggingRow={(value) => isDraggingRow(value)}
        moveRow={moveRow}
      />
      {pageOptions > 1 && !isLoading && (
        <Pagination
          getData={(index) => getNewData(index)}
          pageOptions={pageOptions}
          setPage={setPage}
          pageIndex={currentpage}
        />
      )}
    </>
  );
}

TableComponent.propTypes = {
  columns: PropTypes.any,
  currentpage: PropTypes.any,
  data: PropTypes.any,
  emptyResultText: PropTypes.any,
  emptyText1: PropTypes.any,
  emptyText2: PropTypes.any,
  getNewData: PropTypes.func,
  isLoading: PropTypes.any,
  onClick: PropTypes.any,
  perPage: PropTypes.any,
  setPage: PropTypes.any,
  sortSuccess: PropTypes.any,
  success: PropTypes.any,
  total: PropTypes.any,
  totalPage: PropTypes.any,
};

function Table(props) {
  const {
    columns,
    data,
    emptyText1,
    emptyText2,
    showData,
    isLoading,
    success,
    emptyResultText,
    sortSuccess,
    total,
    getData,
    getMoreData,
    className,
    auth,
    border,
    showFooter,
    isDraggable,
    reArrangedData,
    rowDragState,
    moveRowAsyncFunc,
  } = props;

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(auth ? 5 : 10);

  useEffect(
    () => {
      getData(page, perPage);
    },
    // eslint-disable-next-line
    []
  );

  const getNewData = (page) => {
    getMoreData(page, perPage);
  };

  return (
    <TableComponent
      columns={columns}
      data={data}
      emptyText1={emptyText1}
      emptyText2={emptyText2}
      onClick={showData}
      isLoading={isLoading}
      success={success}
      getData={getData}
      getNewData={(data) => getNewData(data)}
      setPerPage={setPerPage}
      setPage={setPage}
      currentpage={page}
      perPage={perPage}
      totalPage={page}
      emptyResultText={emptyResultText}
      sortSuccess={sortSuccess}
      total={total}
      className={className}
      auth={auth}
      border={border}
      showFooter={showFooter}
      isDraggable={isDraggable}
      reArrangedData={reArrangedData}
      isDraggingRow={(value) => rowDragState && rowDragState(value)}
      moveRowAsyncFunc={(payload) => moveRowAsyncFunc(payload)}
    />
  );
}

Table.propTypes = {
  columns: PropTypes.any,
  data: PropTypes.any,
  emptyResultText: PropTypes.any,
  emptyText1: PropTypes.any,
  emptyText2: PropTypes.any,
  getData: PropTypes.func,
  getMoreData: PropTypes.func,
  isLoading: PropTypes.any,
  showData: PropTypes.any,
  sortSuccess: PropTypes.any,
  success: PropTypes.any,
  total: PropTypes.any,
};

export default Table;
