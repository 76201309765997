import React from "react";
import { Form, Formik, Field } from "formik";
import { connect } from "react-redux";
import Input from "../../components/Input/Input";
import { AuthButton } from "../../components/Button/Button";
import { adminLogin } from "../../redux/Actions/AuthActions";
import { useHistory } from "react-router-dom";
import { LoginSchema } from "../../utils/validation/validation";  


const LoginForm = (props) => {
  const { login, isLoading } = props;
  const history = useHistory(); 


  return (
    <div> 
      <Formik
        initialValues={{ email: "", password: "" }}

        onSubmit={(values) => {
          const loginvalues = {
            email: values.email.trim(),
            password: values.password,
          }; 

          console.log('loginvalues', loginvalues)

          login(loginvalues, history);

          Storage.set("email-value", values.email.trim());
        }}
        validationSchema={LoginSchema}
      >
        {({ isValid, dirty, setFieldValue }) => {


          return (
            <Form id="form">
              <div>
                <div className="form-grid">
                  <Field
                    name="email"
                    label="Email Address"
                    placeholder="Enter email address"
                    component={Input}
                    emptyValue={setFieldValue}
                  />
                </div>
                <div className="form-grid">
                  <Field
                    name="password"
                    label="Password"
                    placeholder="Enter password"
                    component={Input}
                    type="password"
                    password
                  />
                </div>

                <AuthButton
                  children={"Log in"}
                  background={"#0A74DC"}
                  width={"100%"}
                  color={"#FFFFFF"}
                  isLoading={isLoading}
                  loadingText={"Loading"}
                  disabled={isLoading || !(isValid && dirty)}
                />

                <a href="/forgot-password" className="forgot-password">
                  Forgot your password?
                </a>
                <div className="policy-wrap">
                  <a href="/privacy-policy" className="policy">
                    Privacy Policy
                  </a>
                  <span>and</span>
                  <a href="/terms-of-service" className="policy">
                    Terms of Services
                  </a>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  login: (user, history) => dispatch(adminLogin(user, history)),
});

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);  
