import React, { useState } from "react";
import Storage from "../../../utils/services/storage";
import { assignUrlFunc } from "../UploadImage";

const UploadProductImage = (props) => {
  const { uploadFile, id, type, isUploading, updateUrl, loaded, index } = props;

  const [state, setstate] = useState({
    loaded: false,
    file_name: true,
  });

  const assignUrl = (uploadedFile, payload) => {
    setstate({
      ...state,
      loaded: true,
      file_name: uploadedFile,
    });
    type === "multiple" && updateUrl(id, uploadedFile, payload, index ?? null);
  };

  const upload = (uploadId) => {
    Storage.set("id", uploadId);
    assignUrlFunc(uploadId, assignUrl, uploadFile, type);
  };

  const setId = Storage.get("id") && Number(Storage.get("id"));
  const loading = setId === id && isUploading;
  const uploaded = loaded ? loaded : state.loaded;

  return (
    <>
      <span>
        {uploaded ? "Change Image" : loading ? "Uploading..." : "Add Image"}
      </span>
      <input
        type="file"
        className="custom-file-input"
        data-testid={`uploadFile${id}`}
        id={`custom-file-input${id}`}
        accept=".jpg, .png"
        onChange={() => upload(id)}
      />
    </>
  );
};

export default UploadProductImage;
