import React, { useEffect } from "react";
import SelectInput from "../../Input/Select";
import Modal, { ModalButton } from "../../Modal/Modal";
import { connect } from "react-redux";
import { getAppointmentCategories } from "../../../redux/Actions/AppointmentActions";
import { Statuses } from "../../Input/Statuses";

const FilterAssessment = ({
  modalActive,
  closeModal,
  getCategorySuccess,
  categories,
  getCategories,
  isGettingAssessments,
  isGettingSuccess,
  fetchAssessments,
  assessments,
  stopTableDrag,
  filterSuccess,
  setAssessmentsData,
}) => {
  const [filter, setFilter] = React.useState({
    type: "",
    status: "",
    category_name: "",
  });

  useEffect(
    () => {
      getCategories();
    },
    // eslint-disable-next-line
    []
  );

  const submitBtnDisabled = () => {
    return !filter.type || !filter.category_name || !filter.status;
  };

  useEffect(
    () => {
      if (isGettingSuccess && !isGettingAssessments) {
        assessments.rows.length && closeModal();
        filterSuccess && stopTableDrag(false);
      }
    },
    // eslint-disable-next-line
    [isGettingAssessments]
  );

  const filterAction = () => {
    const { type, status, category_name } = filter;
    fetchAssessments(
      {
        type,
        status,
        category_name,
        extraUrlString: "/filter",
      },
      setAssessmentsData
    );
  };

  return (
    <Modal
      displayModal={modalActive}
      closeModal={closeModal}
      title={"Filter Product"}
      width={"496px"}
    >
      <SelectInput
        label="Category"
        options={
          getCategorySuccess
            ? categories.map((category) => ({
                label: category.name,
                value: category.id,
              }))
            : [{ label: "Loading...", value: "Loading..." }]
        }
        onChange={({ label }) =>
          setFilter((filter) => ({ ...filter, category_name: label }))
        }
      />

      <div className="top-spacer">
        <SelectInput
          label="Types"
          options={[
            { label: "Open", value: "open" },
            { label: "Choice", value: "choice" },
          ]}
          onChange={({ value }) =>
            setFilter((filter) => ({ ...filter, type: value }))
          }
        />

        <Statuses
          label={"Status"}
          state={filter}
          options={["active", "inactive"].map((status) => ({ name: status }))}
          setstate={setFilter}
          blue={false}
          numberPerRow={3}
        />

        <ModalButton
          isLoading={isGettingAssessments}
          text={"Apply Filter"}
          onClick={() => filterAction()}
          testId={"button"}
          loadingText={"Filtering..."}
          disabled={submitBtnDisabled()}
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isGettingCategories: state.appointment.isGettingCategories,
  getCategorySuccess: state.appointment.getCategorySuccess,
  categories: state.appointment.categories,
  assessments: state.assessments.assessments,
  filterSuccess: state.assessments.filterSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getCategories: () => dispatch(getAppointmentCategories),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterAssessment);
