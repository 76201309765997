import responseHandler from "../../utils/notifications/responseHandler";
import Instance from "../../utils/services/api";
import Storage from "../../utils/services/storage";
import Actions from "../Types";

export const getCustomersStart = () => ({
  type: Actions.GET_ALL_CUSTOMERS_START,
});

export const getCustomersSuccess = (payload) => ({
  type: Actions.GET_ALL_CUSTOMERS_SUCCESS,
  payload,
});

export const searchCustomersStart = () => ({
  type: Actions.SEARCH_CUSTOMERS_START,
});

export const searchCustomersSuccess = (payload) => ({
  type: Actions.SEARCH_CUSTOMERS_SUCCESS,
  payload,
});

export const filterCustomersStart = () => ({
  type: Actions.FILTER_CUSTOMERS_START,
});

export const filterCustomersSuccess = (payload) => ({
  type: Actions.FILTER_CUSTOMERS_SUCCESS,
  payload,
});

export const getReferredUsersStart = () => ({
  type: Actions.GET_REFERRED_USERS_START,
});

export const getReferredUsersSuccess = (payload) => ({
  type: Actions.GET_REFERRED_USERS_SUCCESS,
  payload,
});

export const getCustomers = (page, limit, type) => (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  dispatch(getCustomersStart());
  API.get(
    `user?page=${page}&limit=${limit}&type=${type}&expand=referredUsers`
  ).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getCustomersSuccess(data && data.data));
    } else {
      responseHandler(response, "Failed");
    }
  });
};

export const searchCustomers = (page, limit, type, search) => (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  dispatch(searchCustomersStart());
  API.get(
    `user?page=${page}&limit=${limit}&type=${type}&search=${search}&expand=referredUsers`
  ).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(searchCustomersSuccess(data && data.data));
    } else {
      responseHandler(response, "Failed");
    }
  });
};

export const filterCustomers = (page, limit, type, payload) => (dispatch) => {
  const { createdFrom, createdTo } = payload;
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  dispatch(filterCustomersStart());
  API.get(
    `user?page=${page}&limit=${limit}&type=${type}&created-from=${createdFrom}&created-to=${createdTo}&expand=referredUsers`
  ).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(filterCustomersSuccess(data && data.data));
    } else {
      responseHandler(response, "Failed");
    }
  });
};

export const getReferredUsers = (id, page, limit) => (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  dispatch(getReferredUsersStart());
  console.log(id)
console.log(id, page, limit)
  API.get(`user/referred-by?code=${id}&page=${page}&limit=${limit}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getReferredUsersSuccess(data && data.data));
    } else {
      return
    }
  });
};
