import PropTypes from "prop-types";
import React from "react";

const TextArea = (props) => {
  const { name, placeholder, form, field, rows, type2, label, mb } = props;

  const errorCheck =
    form && form.touched[field.name] && form && form.errors[field.name];

  return (
    <div className="input-wrapper">
      <div className="input-container">
        {type2 && (
          <label className="label-input2" style={{ marginBottom: mb }}>
            {label}
          </label>
        )}
        <pre style={{ marginBottom: "0px" }}>
          <textarea
            className={errorCheck ? "error-border2" : "textarea"}
            rows={rows}
            cols="50"
            name={(field && field.name) || name}
            value={field && field.value}
            onChange={field && field.onChange}
            onBlur={field && field.onBlur}
            onFocus={field && field.onFocus}
            placeholder={placeholder}
          />
        </pre>
        {!type2 && <span className="text-label3">{label}</span>}
      </div>
      {errorCheck && (
        <small className="errors">{form.errors[field.name]}</small>
      )}
    </div>
  );
};

TextArea.propTypes = {
  name: PropTypes.any,
  placeholder: PropTypes.any,
  show: PropTypes.any,
  text: PropTypes.any,
  title: PropTypes.any,
};

export default TextArea;
