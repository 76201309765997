import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import BackBtn from "../../../components/Input/BackBtn";
import { LoaderSpin } from "../../../components/Loader/Loader";
import FacilityHeader from "../../../components/UserManagement/FacilityHeader";
import FacilityInfo from "../../../components/UserManagement/FacilityInfo";
import { HfcpRight } from "../../../components/UserManagement/HFCP/HfcpRight";
import {
  deleteUser,
  getUsersProfile,
} from "../../../redux/Actions/UsersActions";

const SingleHfcp = (props) => {
  const {
    getUsersProfile,
    isGettingProfile,
    usersProfileData,
    deleteUser,
    isDeleting,
    deleteSuccess,
  } = props;

  const location = useLocation();

  const id = location && location.state.id;
  const clientName = usersProfileData && usersProfileData.full_name;
  const facilityName =
    usersProfileData &&
    usersProfileData.facility &&
    usersProfileData.facility.name;
  const clientStatus = usersProfileData && usersProfileData.status;
  const clientAppointments = usersProfileData && usersProfileData;

  useEffect(() => {
    getUsersProfile("hfcp", id);
  }, [getUsersProfile, id]);

  return (
    <div>
      {isGettingProfile ? (
        <div className="loader-table2">
          <LoaderSpin width="40px" height="40px" />
        </div>
      ) : (
        <>
          <BackBtn page="/users" />
          <FacilityHeader
            facilityName={facilityName}
            clientName={clientName}
            status={clientStatus}
            deleteUser={deleteUser}
            isDeleting={isDeleting}
            deleteSuccess={deleteSuccess}
            ClientId={id}
          />
          <FacilityInfo
            data={usersProfileData}
            children={<HfcpRight data={clientAppointments} />}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGettingProfile: state.users.isGettingProfile,
  getSuccessProfile: state.users.getSuccessProfile,
  usersProfileData: state.users.usersProfileData,
  isDeleting: state.users.isDeleting,
  deleteSuccess: state.users.deleteSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  deleteUser: (id) => dispatch(deleteUser(id)),
  getUsersProfile: (type, id) => dispatch(getUsersProfile(type, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleHfcp);
