import React, { createRef, useEffect, useState } from "react";
import { Form, Field, Formik } from "formik";
import Input from "../Input/Input";
import { ModalButton } from "../Modal/Modal";
import NaijaStates from "naija-state-local-government";
import SelectInput from "../Input/Select";
import {
  assignFacilitySubcategoryOptions,
  assignStates,
} from "../../utils/functions/UpdateValues";
import MultiDropdown from "../Input/MultiDropdown";
import CancelIcon from "../../assets/plus.svg";
import TextArea from "../../components/Input/TextArea";

export const states =
  NaijaStates.states() && assignStates(NaijaStates.states());

const FacilityInputs = (props) => {
  const {
    text,
    loadingText,
    formId,
    state,
    setState,
    disabledFunc,
    isLoading,
    defaultOption,
    options,
    categories,
    setOptions,
    submitValues,
    schema,
    initialValues,
  } = props;

  const arrLength = state.categories && state.categories.length;
  const [elRefs, setElRefs] = useState([]);
  const [statePicked, setStatePicked] = useState("");
  const cities =
    statePicked && statePicked != "" ? NaijaStates.lgas(statePicked).lgas : [];

  useEffect(() => {
    // add or remove refs
    setElRefs((elRefs) =>
      Array(arrLength)
        .fill()
        .map((_, i) => elRefs[i] || createRef())
    );
  }, [arrLength]);

  const resetValues = () => {
    elRefs.current?.resetSelectedValues();
  };

  const updateSelection = (data, type) => {
    setState({
      ...state,
      [type]: data.label,
    });
  };

  const addCategory = () => {
    setState({
      ...state,
      categories: state.categories.concat([
        {
          category_id: "",
          name: "",
          subcategoryOptions: [],
          sub_category_ids: [],
        },
      ]),
    });
  };

  const updateOptions = (id) => {
    let newOptions = [];
    const optionExists =
      options && options.filter((option) => option.value === id);
    const category = categories && categories.filter((item) => item.id === id);
    if (optionExists && optionExists.length === 0) {
      newOptions =
        options &&
        options.concat(
          Object.assign({
            value: category[0]?.id,
            label: category[0]?.name,
          })
        );
    } else {
      newOptions = options;
    }
    setOptions(newOptions);
  };

  const removeCategory = (idx, id) => {
    const newCategories =
      state.categories &&
      state.categories.filter((item, index) => index !== idx);
    id !== "" && updateOptions(id);
    setState({ ...state, categories: newCategories });
  };

  const updateCategoryOptions = (categories, data) => {
    categories &&
      categories.map((category) => {
        if (category.category_id === data) {
          const updatedCategories =
            options && options.filter((item) => item.value !== data);
          return setOptions(updatedCategories);
        } else {
          return options;
        }
      });
  };

  const updateCategoryOptions2 = (newCategories, data, id) => {
    newCategories &&
      newCategories.map((category) => {
        if (category.category_id === data) {
          const updatedCategories =
            options && options.filter((item) => item.value !== data);
          const category =
            categories && categories.filter((item) => item.id === id);
          const each = Object.assign({
            value: category[0].id,
            label: category[0].name,
          });
          const list = updatedCategories && updatedCategories.concat(each);
          return setOptions(list);
        } else {
          return options;
        }
      });
  };

  const updateAllOptions = (id, data, categories) => {
    if (id === "") {
      updateCategoryOptions(categories, data);
    } else {
      updateCategoryOptions2(categories, data, id);
    }
  };

  const updateCategorySelection = (data, index, id) => {
    const updatedSubcategory = assignFacilitySubcategoryOptions(
      categories,
      data.value
    );
    const newCategories = state.categories.map((category, idx) => {
      const check =
        category.category_id !== "" && category.category_id !== data;
      if (index !== idx) {
        return category;
      }
      if (check) {
        resetValues();
      }
      return {
        ...category,
        category_id: data.value,
        name: data.label,
        subcategoryOptions: updatedSubcategory,
        sub_category_ids: check ? [] : category.sub_category_ids,
      };
    });
    setState({ ...state, categories: newCategories });
    updateAllOptions(id, data.value, newCategories);
  };

  const updateSubcategorySelection = (data, index) => {
    const newCategories = state.categories.map((category, idx) => {
      if (index !== idx) return category;
      return {
        ...category,
        sub_category_ids: data,
      };
    });
    setState({ ...state, categories: newCategories });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema ? schema : ""}
      onSubmit={(values) => submitValues(values)}
    >
      {({ setFieldValue, isValid, dirty, values }) => {
        const disabled = disabledFunc(isValid, dirty, values);
        return (
          <Form id={formId}>
            <div className="facility-container">
              <div className="form-grid3">
                <Field
                  name="name"
                  label="Name"
                  placeholder="Enter name"
                  component={Input}
                  emptyValue={setFieldValue}
                />

                <Field
                  name="email"
                  label="Email"
                  placeholder="Enter email"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>

              <div className="form-grid2">
                <Field
                  name="phone_number"
                  label="Phone Number"
                  placeholder="Enter phone number"
                  component={Input}
                  emptyValue={setFieldValue}
                />

                <Field
                  type="number"
                  name="years_of_operation"
                  label="Years of Operation"
                  placeholder="Enter years of operation"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>

              <div className="form-grid2">
                <SelectInput
                  label="State"
                  options={states}
                  onChange={(data) => {
                    updateSelection(data, "state");
                    setStatePicked(data?.value);
                  }}
                  defaultOption={defaultOption}
                />

                <Field
                  name="city"
                  label="City"
                  placeholder="Enter City"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>

              <div className="form-grid2">
                <Field
                  name="closest_landmark"
                  label="Landmark"
                  placeholder="Input more location info"
                  component={Input}
                  emptyValue={setFieldValue}
                />

                <Field
                  name="full_address"
                  label="Full Address"
                  placeholder="Enter address"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>

              <div className="form-grid">
                <Field
                  name="description"
                  label="Description"
                  placeholder="Enter description"
                  component={TextArea}
                  title={"description"}
                  emptyValue={setFieldValue}
                />
              </div>
            </div>

            {state.categories &&
              state.categories.map((item, index) => (
                <div className="facility-category" key={index}>
                  {index > 0 && (
                    <div
                      className="facility-category-delete"
                      data-testid="removeCategory"
                      onClick={() => removeCategory(index, item.category_id)}
                    >
                      <img src={CancelIcon} alt="cancel icon" />
                    </div>
                  )}
                  <div className="top-spacer">
                    <SelectInput
                      label="Category"
                      options={categories?.length === 0 ? [] : options}
                      onChange={(data) =>
                        updateCategorySelection(data, index, item.category_id)
                      }
                      defaultOption={item.name}
                    />
                  </div>

                  <div className="top-spacer">
                    <MultiDropdown
                      state={item}
                      accessor="sub_category_ids"
                      onSelect={(data) =>
                        updateSubcategorySelection(data, index)
                      }
                      onRemove={(data) =>
                        updateSubcategorySelection(data, index)
                      }
                      multiselectRef={elRefs[index]}
                      label={"Sub Category"}
                      placeholder={
                        item.sub_category_ids &&
                        item.sub_category_ids.length > 0
                          ? ""
                          : "e.g Select subcategories"
                      }
                      testid={"sub-category-tags"}
                      options={item.subcategoryOptions}
                    />
                  </div>
                </div>
              ))}
            <div
              className="payment-modal-add-more"
              style={{ textDecoration: "underline" }}
            >
              <span onClick={() => addCategory()} data-testid="addCategory">
                {" "}
                + Add Category
              </span>
            </div>

            <ModalButton
              isLoading={isLoading}
              text={text}
              type={"submit"}
              testId={"button"}
              loadingText={loadingText}
              disabled={disabled}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default FacilityInputs;
