import React, { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import FacilityInputs from "./FacilityInputs";
import {
  editFacility,
  getFacilities,
  getSingleFacility,
} from "../../redux/Actions/FacilityActions";
import { connect } from "react-redux";
import {
  assignCategoryOptions,
  capitalizeFirstLetter,
} from "../../utils/functions/UpdateValues";
import { getAppointmentCategories } from "../../redux/Actions/AppointmentActions";
import {
  AddFacilitySchema,
  phoneRegExp,
} from "../../utils/validation/validation";
import { LoaderSpin } from "../Loader/Loader";
import {
  changeFacilityArray,
  filterCategoryOptions,
  updateFacilityArray,
} from "../../utils/functions/CreateNewArray";
import {
  filterFacilityCategories,
  filterFacilityPayload,
} from "../../utils/functions/FilterObject";

const EditFacility = (props) => {
  const {
    displayModal,
    closeModal,
    isGettingCategory,
    getSuccess,
    categories,
    getCategory,
    state,
    setState,
    isEditing,
    editFacility,
    editSuccess,
    getFacilities,
    isGetting,
    getSingleSuccess,
    getSingleFacility,
    data,
    id,
  } = props;

  useEffect(
    () => {
      getSingleFacility(id);
      getCategory();
    },
    // eslint-disable-next-line
    [getSingleFacility, getCategory]
  );

  const selectedCategories = data && data.appointment_categories;
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  const changedFacilityArray =
    selectedCategories && changeFacilityArray(selectedCategories);
  const updatedFacilityArray =
    changedFacilityArray &&
    updateFacilityArray(changedFacilityArray, categories);
  const updatedCategory = assignCategoryOptions(categories);
  const filteredCategory = filterCategoryOptions(
    updatedFacilityArray,
    updatedCategory
  );

  useEffect(
    () => {
      if (getSingleSuccess && getSuccess) {
        setCategoryOptions(filteredCategory);
        setState({
          ...state,
          categories: updatedFacilityArray,
          state: data && data.state,
        });
      }
    },
    // eslint-disable-next-line
    [getSuccess, getSingleSuccess]
  );

  const initialValues = {
    name: data && data.name,
    city: data && data.city,
    email: data && data.email,
    full_address: data && data.full_address,
    closest_landmark: data && data.closest_landmark,
    phone_number: data && data.phone_number,
    description: data && data.description,
    years_of_operation: data && data.years_of_operation,
  };

  const newCategories =
    state.categories && filterFacilityCategories(state.categories);

  const submitValues = (values) => {
    const payload = {
      name: values.name,
      state: state.state,
      city: values.city,
      email: values.email,
      full_address: values.full_address,
      closest_landmark: values.closest_landmark,
      appointment_categories:
        state.categories && filterFacilityPayload(state.categories),
      phone_number: values.phone_number,
      description: values.description,
      years_of_operation: values.years_of_operation.toString(),
    };
    editFacility(id, payload);
  };

  useEffect(
    () => {
      if (editSuccess) {
        closeModal();
        getFacilities(1, 10);
      }
    },
    // eslint-disable-next-line
    [editSuccess]
  );

  const disabled = (isValid, dirty, values) =>
    isEditing ||
    state.state === "" ||
    values.name === "" ||
    values.city === "" ||
    values.email === "" ||
    !values.email ||
    values.full_address === "" ||
    values.closest_landmark === "" ||
    values.phone_number === "" ||
    !(values.phone_number?.match(phoneRegExp)) ||
    values.description === "" ||
    values.years_of_operation === "" ||
    newCategories;

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Edit Facility"}
      width={"568px"}
    >
      {isGetting ? (
        <LoaderSpin width="40px" height="40px" />
      ) : getSingleSuccess ? (
        <FacilityInputs
          formId={"editFacility"}
          text={"Save Changes"}
          loadingText={"Saving..."}
          isGettingCategory={isGettingCategory}
          getSuccess={getSuccess}
          submitValues={submitValues}
          state={state}
          setState={setState}
          disabledFunc={disabled}
          isLoading={isEditing}
          defaultOption={state.state && capitalizeFirstLetter(state.state)}
          options={categoryOptions}
          setOptions={setCategoryOptions}
          subcategoryOptions={subcategoryOptions}
          setSubcategoryOptions={setSubcategoryOptions}
          categories={categories}
          schema={AddFacilitySchema}
          initialValues={initialValues}
        />
      ) : (
        <div>Error fetching single facility</div>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isGettingCategory: state.appointment.isGettingCategories,
  getSuccess: state.appointment.getCategorySuccess,
  isGetting: state.facility.isGettingSingleFacility,
  getSingleSuccess: state.facility.getSingleSuccess,
  data: state.facility.facility,
  isEditing: state.facility.isEditingFacility,
  editSuccess: state.facility.editSuccess,
  categories: state.appointment.categories,
});

const mapDispatchToProps = (dispatch) => ({
  getCategory: () => dispatch(getAppointmentCategories()),
  getSingleFacility: (id) => dispatch(getSingleFacility(id)),
  editFacility: (id, payload) => dispatch(editFacility(id, payload)),
  getFacilities: (page, limit) => dispatch(getFacilities(page, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditFacility);
