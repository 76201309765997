import React, { useState } from "react";
import Input from "../Input/Input";
import { AuthButton } from "../Button/Button";
import { ToggleButton } from "../Input/ToggleButton";
import UploadIcon from "../../assets/upload.svg";
import { Form, Field, Formik } from "formik";
import BlogEditor from "./BlogEditor";
import { FileUploader } from "react-drag-drop-files";
import Cancel from "../../assets/delete.svg";
import { assignUrlFunc2 } from "../Products/UploadImage";
import MultiDropdown from "../Input/MultiDropdown";

const BlogInputs = (props) => {
  const {
    state,
    setState,
    toggleList,
    btnText,
    isLoading,
    contentState,
    onContentStateChange,
    isUploading,
    uploadFile,
    options,
    name,
    type,
    initialValues,
    schema,
    submitValues,
    disabledFunc,
  } = props;
  const fileTypes = ["jpeg", "png", "jpg"];

  const [file, setFile] = useState(null);
  const [error, setError] = useState("");

  const assignUrl = (uploadedFile, payload) => {
    setState({
      ...state,
      loaded: true,
      image: uploadedFile,
    });
  };

  const handleUpload = (file) => {
    setFile(file);
    setError("");
    assignUrlFunc2(file, assignUrl, uploadFile, "single");
  };

  const setErrorMsg = (err) => {
    setError(err);
  };

  const removeFile = () => {
    setState({
      ...state,
      loaded: false,
      image: "",
    });
    setError("Image is required");
    setFile(null);
  };

  const addSelection = (data) => {
    setState({
      ...state,
      category: data,
    });
  };

  const removeSelection = (data) => {
    setState({
      ...state,
      category: data,
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema ? schema : ""}
      onSubmit={(values) => submitValues(values)}
    >
      {({ isValid, dirty, values, setFieldValue }) => {
        const disabled = disabledFunc(isValid, dirty, values);

        return (
          <Form id="form" className="blog-post">
            <div className="blog-post-left">
              <div className="blog-post-left-title">
                <Field
                  name="title"
                  label="Blog Title"
                  placeholder="Enter blog title"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>

              <div className="form-grid">
                <BlogEditor
                  contentState={contentState}
                  onContentStateChange={onContentStateChange}
                  type={type}
                />
              </div>

              <div className="blog-post-left-image">
                <h2>Image</h2>
                <div className="blog-post-left-image-div">
                  <h4>Upload Image*</h4>
                  <FileUploader
                    handleChange={handleUpload}
                    name="file"
                    types={fileTypes}
                    onTypeError={setErrorMsg}
                    children={
                      isUploading ? (
                        <div className="dashed-div-container">
                          <div className="dashed-div">
                            <span>Uploading...</span>
                          </div>
                        </div>
                      ) : state.loaded ? (
                        <div className="bordered-div">
                          <span>{type === "edit" ? name : file?.name}</span>

                          <div
                            className="icon-upload"
                            onClick={() => removeFile()}
                            data-testid="removeFile"
                          >
                            <img src={Cancel} alt="remove" />
                          </div>
                        </div>
                      ) : (
                        <div className="dashed-div-container">
                          <div className="dashed-div">
                            <div className="dashed-div-icon">
                              <img src={UploadIcon} alt="upload-icon" />
                            </div>
                            <span>Upload a file</span>
                            <p>or drag and drop</p>
                          </div>
                          <span className="errors">{error}</span>
                        </div>
                      )
                    }
                  />
                </div>
                {error !== "" && <br />}

                <div className="blog-post-left-image-div">
                  <h4>Caption</h4>
                  <Field
                    name="caption"
                    placeholder="Enter caption"
                    component={Input}
                    input2={true}
                    emptyValue={setFieldValue}
                  />
                </div>
              </div>
            </div>

            <div className="blog-post-right">
              <div className="blog-post-right-btn">
                <AuthButton
                  children={btnText}
                  type={"submit"}
                  width={"156px"}
                  color={"#fff"}
                  testId={"button"}
                  marginTop={"0px"}
                  isLoading={isLoading}
                  disabled={disabled}
                />
              </div>
              <Field
                name="author"
                label="Author"
                placeholder="Enter author name"
                component={Input}
                input2={true}
                emptyValue={setFieldValue}
              />

              <MultiDropdown
                state={state}
                onSelect={(data) => addSelection(data)}
                onRemove={(data) => removeSelection(data)}
                label={"Category"}
                top={"32px"}
                mb={"8px"}
                placeholder={
                  state.category && state.category.length > 0
                    ? ""
                    : "e.g Select categories"
                }
                testid={"category-tags"}
                type2={true}
                options={options}
              />

              <div>
                {toggleList &&
                  toggleList.map((item, index) => (
                    <ToggleButton
                      text={item.title}
                      handleChange={(status) => item.handleChange(status)}
                      checked={item.status}
                      id={item.id}
                      key={index}
                    />
                  ))}
              </div>

              <div className="blog-post-right-btn2">
                <AuthButton
                  children={btnText}
                  type={"submit"}
                  width={"156px"}
                  color={"#fff"}
                  testId={"button"}
                  marginTop={"0px"}
                  isLoading={isLoading}
                  disabled={disabled}
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BlogInputs;
