import React, { useEffect } from "react";
import SingleClientChat from "../../components/ChatManagement/SingleClientChat";
import Icon from "../../assets/account_icon.svg";
import moment from "moment";
import { PageButton } from "../../components/Button/Button";
import truncateName from "../../utils/functions/ShortenStr";
import { filterCustomer } from "../../utils/functions/Chats";

const ChatsGrid = (props) => {
  const { chats, showSingleChat, state, openModal } = props;

  const ChatData = ({ chat, index }) => {
    const customer = filterCustomer(chat);

    return (
      <div
        className={`all-chats-single ${
          state.chatId === chat.id ? "all-chats-single2" : ""
        }`}
        key={index}
        onClick={() => showSingleChat(chat)}
      >
        <div className="all-chats-single-image">
          <img src={customer?.user?.image_url || Icon} alt="profile pic" />
        </div>

        <div className="all-chats-single-text">
          <div className="all-chats-single-text-name">
            {customer?.user?.username || customer?.user?.email}
          </div>
          <div className="all-chats-single-text-message">
            {truncateName(chat?.question, 30)}
          </div>
        </div>

        <div className="all-chats-single-figures">
          <div className="all-chats-single-figures-time">
            {moment(chat.updated_at).format("HH:mm")}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="all-chats">
      <input type="checkbox" id="chat-check" />
      <SingleClientChat
        chat={state.chat}
        className={"chats-right-mobile"}
        component={
          !state.active3 && (
            <PageButton
              width={"148px"}
              background={"rgba(10, 116, 220, 0.08)"}
              marginTop={"0px"}
              onClick={() => openModal("transferUser")}
              type={"button"}
              children={"Transfer User"}
            />
          )
        }
        state={state}
      />

      <label htmlFor="chat-check">
        {chats &&
          chats.map((chat, index) => (
            <ChatData chat={chat} index={index} key={index} />
          ))}
      </label>
    </div>
  );
};

export default ChatsGrid;
