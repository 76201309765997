import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Bar } from "react-chartjs-2";

const GenderDistributionChart = ({ overviewData }) => {
  const [date, setDate] = useState(moment().format("LLL"));
  const { gender_distribution } = overviewData;

  const [data, setData] = useState([]);
  const dateRef = useRef(null);

  useEffect(() => {
    const data = gender_distribution.filter(
      (key) => key.gender === "male" || key.gender === "female"
    );
    setData(data);
  }, [gender_distribution]);
  useEffect(
    () => {
      dateRef.current = setInterval(
        () => setDate(moment().format("LLL")),
        60_000
      );
      return () => clearInterval(dateRef.current);
    }, // eslint-disable-next-line
    []
  );
  const dataState = {
    labels: ["FEMALE", "MALE"],
    datasets: [
      {
        label: "Gender Distribution",
        data: data?.map((key) => key.y),
        borderWidth: 1,
        backgroundColor: "#0A74DC",
      },
    ],
  };
  return (
    <div className="overview-graph">
      <div className="overview-graph-header">
        <div className="overview-graph-header-left">
          <h4>Gender Distribution</h4>
          <p>{date}</p>
        </div>
      </div>
      <div className="overview-graph-section">
        <Bar
          data={dataState}
          options={{
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default GenderDistributionChart;
