import Actions from "../Types";

const initialState = {
  isGettingBlogPosts: false,
  isFiltering: false,
  isSearching: false,
  isCreatingBlogPost: false,
  isEditingBlogPost: false,
  isDeleting: false,
  isChangingStatus: false,
  isGettingSinglePost: false,
  getSuccess: false,
  filterSuccess: false,
  searchSuccess: false,
  deleteSuccess: false,
  changeStatusSuccess: false,
  getSingleSuccess: false,
  createSuccess: false,
  editSuccess: false,
  blogPosts: [],
  blogPostsData: {},
  filteredBlogPosts: [],
  filteredBlogPostsData: {},
  searchedBlogPosts: [],
  searchedBlogPostsData: {},
  blogPost: {},
  singlePost: {},
  error: null,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_BLOG_POSTS_START:
      return {
        ...state,
        isGettingBlogPosts: true,
        getSuccess: false,
        editSuccess: false,
        deleteSuccess: false,
        filterSuccess: false,
        searchSuccess: false,
        blogPosts: [],
        blogPostsData: {},
        error: null,
      };
    case Actions.GET_BLOG_POSTS_SUCCESS:
      return {
        ...state,
        isGettingBlogPosts: false,
        getSuccess: true,
        editSuccess: false,
        deleteSuccess: false,
        createSuccess: false,
        filterSuccess: false,
        searchSuccess: false,
        blogPosts: action.payload && action.payload.data,
        blogPostsData: action.payload,
        error: null,
      };
    case Actions.GET_BLOG_POSTS_FAILURE:
      return {
        ...state,
        isGettingBlogPosts: false,
        getSuccess: false,
        blogPosts: [],
        editSuccess: false,
        deleteSuccess: false,
        filterSuccess: false,
        searchSuccess: false,
        blogPostsData: {},
        error: action.payload,
      };
    case Actions.CREATE_BLOG_POST_START:
      return {
        ...state,
        isCreatingBlogPost: true,
        createSuccess: false,
        blogPost: {},
        error: null,
      };
    case Actions.CREATE_BLOG_POST_SUCCESS:
      return {
        ...state,
        isCreatingBlogPost: false,
        createSuccess: true,
        blogPost: action.payload,
        error: null,
      };
    case Actions.CREATE_BLOG_POST_FAILURE:
      return {
        ...state,
        isCreatingBlogPost: false,
        createSuccess: false,
        blogPost: null,
        error: action.payload,
      };
    case Actions.EDIT_BLOG_POST_START:
      return {
        ...state,
        isEditingBlogPost: true,
        editSuccess: false,
        blogPost: {},
        error: null,
      };
    case Actions.EDIT_BLOG_POST_SUCCESS:
      return {
        ...state,
        isEditingBlogPost: false,
        editSuccess: true,
        blogPost: action.payload,
        error: null,
      };
    case Actions.EDIT_BLOG_POST_FAILURE:
      return {
        ...state,
        isEditingBlogPost: false,
        editSuccess: false,
        blogPost: null,
        error: action.payload,
      };
    case Actions.GET_SINGLE_BLOG_POST_START:
      return {
        ...state,
        isGettingSinglePost: true,
        getSingleSuccess: false,
        singlePost: {},
        error: null,
      };
    case Actions.GET_SINGLE_BLOG_POST_SUCCESS:
      return {
        ...state,
        isGettingSinglePost: false,
        getSingleSuccess: true,
        singlePost: action.payload,
        error: null,
      };
    case Actions.GET_SINGLE_BLOG_POST_FAILURE:
      return {
        ...state,
        isGettingSinglePost: false,
        getSingleSuccess: false,
        singlePost: [{}],
        error: action.payload,
      };
    case Actions.SINGLE_BLOG_POST_CLEAR:
      return {
        ...state,
        isGettingSinglePost: true,
        singlePost: {},
        error: action.payload,
      };
    case Actions.FILTER_BLOG_POSTS_START:
      return {
        ...state,
        isFiltering: true,
        filterSuccess: false,
        editSuccess: false,
        deleteSuccess: false,
        filteredBlogPosts: [],
        filteredBlogPostsData: {},
        error: null,
      };
    case Actions.FILTER_BLOG_POSTS_SUCCESS:
      return {
        ...state,
        isFiltering: false,
        filterSuccess: true,
        searchSuccess: false,
        editSuccess: false,
        deleteSuccess: false,
        filteredBlogPosts: action.payload && action.payload.data,
        filteredBlogPostsData: action.payload,
        error: null,
      };
    case Actions.FILTER_BLOG_POSTS_FAILURE:
      return {
        ...state,
        isFiltering: false,
        filterSuccess: false,
        editSuccess: false,
        deleteSuccess: false,
        filteredBlogPosts: [],
        filteredBlogPostsData: {},
        error: action.payload,
      };
    case Actions.SEARCH_BLOG_POSTS_START:
      return {
        ...state,
        isSearching: true,
        searchSuccess: false,
        editSuccess: false,
        deleteSuccess: false,
        searchedBlogPosts: [],
        searchedBlogPostsData: {},
        error: null,
      };
    case Actions.SEARCH_BLOG_POSTS_SUCCESS:
      return {
        ...state,
        isSearching: false,
        searchSuccess: true,
        filterSuccess: false,
        editSuccess: false,
        deleteSuccess: false,
        searchedBlogPosts: action.payload && action.payload.data,
        searchedBlogPostsData: action.payload,
        error: null,
      };
    case Actions.SEARCH_BLOG_POSTS_FAILURE:
      return {
        ...state,
        isSearching: false,
        searchSuccess: false,
        editSuccess: false,
        deleteSuccess: false,
        searchedBlogPosts: [],
        searchedBlogPostsData: {},
        error: action.payload,
      };
    case Actions.DELETE_BLOG_POST_START:
      return {
        ...state,
        isDeleting: true,
        deleteSuccess: false,
        blogPost: {},
        error: null,
      };
    case Actions.DELETE_BLOG_POST_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: true,
        blogPost: action.payload,
        error: null,
      };
    case Actions.DELETE_BLOG_POST_FAILURE:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: false,
        blogPost: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reduxState;
