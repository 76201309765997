import { Formik, Form, Field } from "formik";
import React, { useEffect, useState } from "react";
import Modal, { ModalButton } from "../Modal/Modal";
import Input from "../Input/Input";
import { SubscriptionSchema } from "../../utils/validation/validation";
import { connect } from "react-redux";
import { createSubscription } from "../../redux/Actions/SubscriptionsAction";
import SelectInput from "../Input/Select";
import { getAllPlans } from "../../redux/Actions/PaymentPlanActions";
import { assignCategoryOptions, assignPlanFilterOptions } from "../../utils/functions/UpdateValues";

function CreateSubscription(props) {
  const {
    createSubscription,
    displayModal,
    closeModal,
    createSubscriptionSuccess,
    isCreating,
    getSubscriptions,
    planIds,
    plans,
    getPlans,
    getPlansSuccess,
  } = props;

  const [planId, setPlanId] = useState({});
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (createSubscriptionSuccess) {
      getSubscriptions();
      document.getElementById("addsubscriptionform").reset();
      closeModal();
    }
  }, [createSubscriptionSuccess]);

  const [state, setState] = useState({
    category: "",
  });

  useEffect(
    () => {
      getPlans();
    },
    // eslint-disable-next-line
    [getPlans]
  );

  useEffect(
    () => {
      if (getPlansSuccess) {
        const updatedPlans = assignCategoryOptions(plans?.plans);
        setOptions([...updatedPlans]);
      }
    },
    // eslint-disable-next-line
    [getPlansSuccess]
  );

  const initialValues = {
    transaction_reference: "",
    plan_id: "",
    amount: "",
    email: "",
  };
  const submitValues = (values) => {
    const payload = {
      transaction_reference: values.transaction_reference,
      plan_id: state.category,
      amount: values.amount,
      email: values.email,
    };
    createSubscription(payload);
  };

  const updateSelection = (data) => {
    setState({
      ...state,
      category: data.value,
    });
  };

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Create Subscription Modal"}
      width={"568px"}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => submitValues(values)}
        validationSchema={SubscriptionSchema}
      >
        {({ isValid, dirty, setFieldValue }) => {
          const disabled = isCreating || !(isValid && dirty) || !state.category;
          return (
            <Form id="addsubscriptionform">
              <div className="">
                <Field
                  name="email"
                  label="Email"
                  placeholder="Enter Customer Email"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>
              <div className="top-spacer">
                <Field
                  name="transaction_reference"
                  label="Transaction reference"
                  placeholder="Enter Transaction Reference"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>
              <div className="form-grid2">
                <Field
                  name="amount"
                  label="Amount"
                  placeholder="Enter Amount"
                  component={Input}
                  emptyValue={setFieldValue}
                />
                <SelectInput
                  label={"Subscription Type"}
                  options={options}
                  onChange={updateSelection}
                  success={createSubscriptionSuccess}
                />
              </div>
              <ModalButton
                isLoading={isCreating}
                text={"Create"}
                type={"submit"}
                testId={"button"}
                loadingText={"Creating..."}
                disabled={disabled}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  isCreating: state.subscriptions.isCreating,
  plans: state.plan.plans,
  getPlansSuccess: state.plan.getSuccess,
  createSubscriptionSuccess: state.subscriptions.createSubscriptionSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getPlans: () => dispatch(getAllPlans()),
  createSubscription: (payload) => dispatch(createSubscription(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateSubscription);
