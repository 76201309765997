import { Field, Form, Formik } from "formik";
import React from "react";
import Input from "../../../components/Input/Input";
import Modal, { ModalButton } from "../../../components/Modal/Modal";
import { ProductCategorySchema } from "../../../utils/validation/validation";

const AddCategory = (props) => {
  const { displayModal, closeModal, isCreating, createCategory } = props;

  const initialValues = {
    name: "",
    description: "",
  };

  const submitValues = (values) => {
    const payload = {
      name: values.name,
      description: values.description,
    };
    createCategory(payload);
  };

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Add New Category"}
      width={"568px"}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={ProductCategorySchema}
        onSubmit={(values) => submitValues(values)}
      >
        {({ isValid, dirty, setFieldValue }) => {
          return (
            <Form id="addCategory">
              <div className="form-grid3">
                <Field
                  name="name"
                  label="Category name"
                  placeholder="Enter category name"
                  component={Input}
                  emptyValue={setFieldValue}
                />

                <Field
                  name="description"
                  label="Description"
                  placeholder="Enter description"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>

              <ModalButton
                isLoading={isCreating}
                text={"Create Category"}
                type={"submit"}
                testId={"button"}
                loadingText={"Creating..."}
                disabled={!(isValid && dirty) || isCreating}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddCategory;
