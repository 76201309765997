import React, { useEffect } from "react";
import "./App.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setCurrentUser } from "./redux/Actions/AuthActions";
import Routes from "./routes";



function App({ checkUser }) {
  useEffect(() => {
    checkUser();
  }, [checkUser]);

  return (
    <>
      <Routes /> 
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  checkUser: () => dispatch(setCurrentUser()),
});

export default connect(null, mapDispatchToProps)(withRouter(App));
