import React, { useState } from "react";
import { cancelModal, viewModal } from "../../Modal/Modal";
import DeactivateRole from "../../Settings/Actions/DeactivateRole";
import EditRole from "../../Settings/EditRole";
import {
  DropBtn,
  ErrorBtn,
  SuccessBtn,
  TableDropdown,
} from "../../Tables/TableButtons";

export const rolesColumns = [
  {
    Header: "Name",
    accessor: "id",
  },
  {
    Header: "Description",
    accessor: "description",
    Cell: ({ row }) => <div>{row.original.description || "Not available"}</div>,
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ row }) => {
      const [show, setShow] = useState(false);

      const [state, setState] = useState({
        editModal: false,
        statusModal: false,
        deleteModal: false,
      });

      const openModal = (type) => {
        viewModal(type, state, setState);
        setShow((prevCheck) => !prevCheck);
      };

      const closeModal = (type) => {
        cancelModal(type, state, setState);
      };

      const status =
        row.original &&
        row.original.status &&
        row.original.status.toLowerCase();

      const options = [
        {
          name: "Edit Info",
          action: () => openModal("editModal"),
        },
        {
          name: status === "active" ? "Deactivate" : "Activate",
          action: () => openModal("statusModal"),
        },
      ];

      return (
        <div className="action-flex">
          {row.original.status &&
          row.original.status.toLowerCase() === "active" ? (
            <SuccessBtn text={"Active"} />
          ) : (
            <ErrorBtn text={"Inactive"} />
          )}
          &nbsp;
          <DropBtn onClick={() => setShow((prevCheck) => !prevCheck)} />
          {show && <TableDropdown options={options} />}
          {state.editModal && (
            <EditRole
              displayModal={state.editModal}
              closeModal={() => closeModal("editModal")}
              data={row.original}
            />
          )}
          {state.statusModal && (
            <DeactivateRole
              displayModal={state.statusModal}
              closeModal={() => closeModal("statusModal")}
              data={row.original}
              status={status}
            />
          )}
        </div>
      );
    },
  },
];
