import React from "react";
import Carousel from "../../Input/Carousel";
import { useMediaQuery } from "react-responsive";
import defaultImage from "../../../assets/account_icon.svg";
import moment from "moment";
import Empty from "../../../assets/empty.svg";

export const Session = (props) => {
  const { clientSession } = props;

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <div className="info-flex">
      {clientSession?.length > 0 ? (
        <Carousel show={isTabletOrMobile ? 1 : 2}>
          {clientSession &&
            clientSession.map((session, index) => (
              <div key={index}>
                <div className="session">
                  <div className="session-left">
                    <img
                      src={
                        session.image_url === null
                          ? defaultImage
                          : session.image_url
                      }
                      alt="session icon"
                    />
                  </div>
                  <div className="session-right">
                    <div className="title">{session.topic}</div>
                    <div className="name">With: {session.full_name}</div>
                    <div className="date">
                      {moment(session.created_at).format("ll")}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Carousel>
      ) : (
        <div className="table-empty2">
          <div className="table-empty-container">
            <div className="table-empty-container-image">
              <img src={Empty} alt="empty-table" />
            </div>
            <div>
              <h2>No recent sessions</h2>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
