import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";
import { clearStorage } from "../../utils/functions/ClearStorage";
import { handleRouting } from "../../utils/functions/GetStorageItems";

// SET CURRENT ADMIN
export const setCurrentUser = () => ({
  type: Actions.SET_CURRENT_ADMIN,
});

// LOG IN ADMIN
export const AdminLoginStart = () => ({
  type: Actions.ADMIN_LOGIN_START,
});

export const AdminLoginSuccess = (payload) => ({
  type: Actions.ADMIN_LOGIN_SUCCESS,
  payload,
});

export const AdminLoginFailure = (payload) => ({
  type: Actions.ADMIN_LOGIN_FAILURE,
  payload,
});

// SEND EMAIL ADDRESS
export const SendEmailAddressStart = () => ({
  type: Actions.SEND_EMAIL_ADDRESS_START,
});

export const SendEmailAddressSuccess = (payload) => ({
  type: Actions.SEND_EMAIL_ADDRESS_SUCCESS,
  payload,
});

export const SendEmailAddressFailure = (payload) => ({
  type: Actions.SEND_EMAIL_ADDRESS_FAILURE,
  payload,
});

// RESET ADMIN PASSWORD
export const resetAdminPasswordStart = () => ({
  type: Actions.RESET_ADMIN_PASSWORD_START,
});

export const resetAdminPasswordSuccess = (payload) => ({
  type: Actions.RESET_ADMIN_PASSWORD_SUCCESS,
  payload,
});

export const resetAdminPasswordFailure = (payload) => ({
  type: Actions.RESET_ADMIN_PASSWORD_FAILURE,
  payload,
});

// RESET PASSWORD
export const resetPasswordStart = () => ({
  type: Actions.RESET_PASSWORD_START,
});

export const resetPasswordSuccess = (payload) => ({
  type: Actions.RESET_PASSWORD_SUCCESS,
  payload,
});

export const resetPasswordFailure = (payload) => ({
  type: Actions.RESET_PASSWORD_FAILURE,
  payload,
});

// LOG OUT CURRENT ADMIN
export const logOutStart = () => ({
  type: Actions.ADMIN_LOG_OUT_START,
});

export const logOutSuccess = (payload) => ({
  type: Actions.ADMIN_LOG_OUT_SUCCESS,
  payload,
});

export const logOutFailure = (payload) => ({
  type: Actions.ADMIN_LOG_OUT_FAILURE,
  payload,
});

export const adminLogin = (payload, history) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  console.log(process.env.REACT_APP_WHISPA_ADMIN_KEY);
  dispatch(AdminLoginStart());
  API.post("auth/login", payload).then((response) => {
    const { data, ok } = response;
    console.log("login-data", data);

    if (ok) {
      Storage.set("admin-access-token", data && data.data && data.data.token);
      const userData = JSON.stringify(data && data.data);
      Storage.set("admin-data", userData);
      Storage.set("admin-details", userData);
      dispatch(AdminLoginSuccess(data && data.data));
      let isFirstTimeLogin = data && data.data && data.data.isFirstTimeLogin;
      if (isFirstTimeLogin === true) {
        history.push("/complete-profile");
      } else {
        history.push(handleRouting);
      }
    } else {
      responseHandler(response, "Admin Login Failed");
      dispatch(AdminLoginFailure(data));
    }
  });
};

export const SendEmailAddress = (payload, history) => (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(SendEmailAddressStart());
  API.post("/auth/forgot-password", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(SendEmailAddressSuccess(data));
      responseHandler(response, "Email sent successfully");
      history.push("/check-inbox");
    } else {
      dispatch(SendEmailAddressFailure(data));
      responseHandler(response, "Action Failed");
    }
  });
};

export const changeAdminPassword = (payload, history) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(resetAdminPasswordStart());

  API.post("auth/reset-admin-password", payload).then((response) => {
    const { data, ok } = response;
    responseHandler(response);
    if (ok) {
      dispatch(resetAdminPasswordSuccess(data.success));
      clearStorage();
      history.push("/");
    } else {
      dispatch(resetAdminPasswordFailure(data));
    }
  });
};

export const resetPassword = (payload, history) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(resetPasswordStart());
  API.post("auth/reset-password", payload).then((response) => {
    const { data, ok } = response;
    responseHandler(response);
    if (ok) {
      dispatch(resetPasswordSuccess(data.success));
      history.push("/");
    } else {
      dispatch(resetPasswordFailure(data));
    }
  });
};

export const logOut = (payload) => (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(logOutStart());
  API.post("", payload).then((response) => {
    const { data, ok } = response;
    responseHandler(response);
    if (ok) {
      dispatch(logOutSuccess(data));
    } else {
      dispatch(logOutFailure(data));
    }
  });
};
