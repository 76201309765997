import React from "react";
import Table from "../../components/Tables/Table";
import FilterPosts from "../../components/BlogManagement/FilterPosts";
import { blogColumns } from "../../components/TableData/Blog/Blog";
import { connect } from "react-redux";
import {
  filterBlogPosts,
  getBlogPosts,
  searchBlogPosts,
} from "../../redux/Actions/BlogActions";
import { useEffect } from "react";

const Blog = (props) => {
  const {
    isGettingBlogPosts,
    getSuccess,
    blogPostsData,
    isFiltering,
    filterSuccess,
    filteredBlogPosts,
    filteredBlogPostsData,
    isSearching,
    searchSuccess,
    searchedBlogPosts,
    searchedBlogPostsData,
    getBlogPosts,
    searchBlogPosts,
    filterBlogPosts,
    blogPosts,
    closeModal,
    state,
    setState,
    setBlogData,
    blogData
  } = props;

  const paginateBlogFilter = (page, perPage, values) => {
    const payload = {
      category: values.category,
      status: values.status,
    };
    filterBlogPosts(page, perPage, payload);
  };

  const moreData = (page, perPage) => {
    searchSuccess
      ? searchBlogPosts(page, perPage, state.searchValue)
      : filterSuccess
      ? paginateBlogFilter(page, perPage, state.filterValues)
      : getBlogPosts(page, perPage);
  };

  const allBlogPosts = searchSuccess
    ? searchedBlogPosts
    : filterSuccess
    ? filteredBlogPosts
    : blogPosts;

  useEffect(() => {
    setBlogData({ ...blogData, blog: allBlogPosts });
  }, [allBlogPosts]);

  const totalBlogPosts = searchSuccess
    ? searchedBlogPostsData && searchedBlogPostsData.total
    : filterSuccess
    ? filteredBlogPostsData && filteredBlogPostsData.total
    : blogPostsData && blogPostsData.total;

  return (
    <div>
      <Table
        columns={blogColumns}
        data={allBlogPosts}
        getData={getBlogPosts}
        emptyText1={"You haven't added any post"}
        emptyText2={"Click on the “Create Post” button to get started"}
        isLoading={isGettingBlogPosts || isSearching || isFiltering}
        getMoreData={(page, perPage) => moreData(page, perPage)}
        success={getSuccess || filterSuccess || searchSuccess}
        sortSuccess={searchSuccess || filterSuccess}
        total={totalBlogPosts}
        emptyResultText={"Post not found!"}
        className={"table4"}
      />

      <FilterPosts
        displayModal={state.filterModal}
        closeModal={() => closeModal("filterModal")}
        isFiltering={isFiltering}
        filterPosts={filterBlogPosts}
        filterSuccess={filterSuccess}
        setFilterState={setState}
        filterState={state}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGettingBlogPosts: state.blog.isGettingBlogPosts,
  getSuccess: state.blog.getSuccess,
  blogPosts: state.blog.blogPosts,
  blogPostsData: state.blog.blogPostsData,
  isFiltering: state.blog.isFiltering,
  filterSuccess: state.blog.filterSuccess,
  filteredBlogPosts: state.blog.filteredBlogPosts,
  filteredBlogPostsData: state.blog.filteredBlogPostsData,
  isSearching: state.blog.isSearching,
  searchSuccess: state.blog.searchSuccess,
  searchedBlogPosts: state.blog.searchedBlogPosts,
  searchedBlogPostsData: state.blog.searchedBlogPostsData,
});

const mapDispatchToProps = (dispatch) => ({
  getBlogPosts: (page, limit) => dispatch(getBlogPosts(page, limit)),
  searchBlogPosts: (page, limit, searchValue) =>
    dispatch(searchBlogPosts(page, limit, searchValue)),
  filterBlogPosts: (page, limit, payload) =>
    dispatch(filterBlogPosts(page, limit, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
