import React, { useEffect, useState } from "react";
import Modal, { ModalButton } from "../Modal/Modal";
import { Statuses } from "../Input/Statuses";
import { connect } from "react-redux";
import { filterUsers } from "../../redux/Actions/UsersActions";
import SelectInput from "../Input/Select";
import { states } from "../FacilityManagement/FacilityInputs";
import DateFilter from "../Input/DateFilter";
import MultiDropdown from "../Input/MultiDropdown";

const FilterUser = (props) => {
  const {
    displayModal,
    closeModal,
    filterUsers,
    isFiltering,
    filterSuccess,
    type,
    prevstate,
    setPrevState,
  } = props;

  const options = [{ label: "Male" }, { label: "Female" }];
  const age_options = [
    { category_id: "18 - 24", name: "18 - 24" },
    { category_id: "25 - 34", name: "25 - 34" },
    { category_id: "35 and above", name: "35 and above" },
  ];

  const status = [{ name: "Active" }, { name: "Suspended" }];

  const [state, setState] = useState({
    gender: "",
    active: false,
    status: "",
    location: "",
    index: "",
    endDate: "",
    startDate: "",
    age: []
  });

  const updateSelection = (data, type) => {
    setState({
      ...state,
      [type]: data.label,
    });
  };

  const handleFilter = () => {
    const agegroup = state.age.map(el => el.name)
    const status = state.status.toLowerCase();
    const gender = state.gender.toLowerCase();
    const location = state.location;
    const startDate = state.startDate;
    const endDate = state.endDate;

    const payload = {
      status: state.status.toLowerCase(),
      gender: state.gender.toLowerCase(),
      location: state.location,
      startDate: state.startDate,
      endDate: state.endDate,
      age_group: agegroup.join(",")
    };
    setPrevState({ ...prevstate, filterValues: payload });
    type === "hfcp"
      ? filterUsers(startDate, endDate, 1, 10, type, gender, agegroup.join(","), status, location)
      : filterUsers(startDate, endDate, 1, 10, type, gender, agegroup.join(","),status);
  };

  useEffect(
    () => {
      if (filterSuccess) {
        closeModal();
        setState({
          gender: "",
          status: "",
          location: "",
          age: "",
        });
      }
    },
    // eslint-disable-next-line
    [filterSuccess]
  );

  const disabled =
    type === "hfcp"
      ? isFiltering ||
        state.status === "" ||
        state.gender === "" ||
        state.location === ""
      : isFiltering || state.status === "" || state.gender === "";

  return (
    <div>
      <Modal
        displayModal={displayModal}
        closeModal={closeModal}
        title={"Filter Users"}
        width={"496px"}
      >
        <div
          style={{
            marginBottom: "30px",
          }}
          className="form-grid3 spacer"
        >
          <DateFilter
            label={"Start Date"}
            placeholder={"Select date"}
            state={state}
            name={"startDate"}
            setState={setState}
            success={filterSuccess}
          />
          <DateFilter
            label={"End Date"}
            placeholder={"Select date"}
            state={state}
            name={"endDate"}
            setState={setState}
            success={filterSuccess}
          />
        </div>
        {prevstate.active4 && (
          <SelectInput
            label="Location"
            options={states}
            onChange={(data) => updateSelection(data, "location")}
            success={filterSuccess}
          />
        )}

        <div className={prevstate.active4 ? "top-spacer" : ""}>
          <SelectInput
            label={"Sex"}
            options={options}
            onChange={(data) => updateSelection(data, "gender")}
            success={filterSuccess}
          />
        </div>
        {/* <div className={"top-spacer" }>
          <SelectInput
            label={"Age Groups"}
            options={age_options}
            onChange={(data) => updateSelection(data, "age")}
            success={filterSuccess}
          />
        </div> */}
        <MultiDropdown
                  state={state}
                  accessor="age"
                  label="Age Group"
                  placeholder={
                    state.age.length > 0
                      ? "Filter by more Age Groups..."
                      : "e.g Select Age Groups"
                  }
                  top={"30px"}
                  testid={"statuses-tags"}
                  options={age_options}
                  onSelect={(age) =>
                    setState((state) => ({ ...state, age }))
                  }
                  onRemove={(age) =>
                    setState((state) => ({ ...state, age }))
                  }
                />

        <div className="top-spacer">
          <Statuses
            label={"Status"}
            state={state}
            options={status}
            setstate={setState}
            blue={false}
            numberPerRow={3}
          />
        </div>

        <ModalButton
          isLoading={isFiltering}
          disabled={disabled}
          text={"Apply Search"}
          onClick={handleFilter}
          testId={"button"}
          loadingText={"Filtering..."}
        />
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => ({
  isFiltering: state.users.isFiltering,
  filterSuccess: state.users.filterSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  filterUsers: (
    startDate,
    endDate,
    page,
    limit,
    type,
    gender,
    age,
    status,
    location
  ) =>
    dispatch(
      filterUsers(
        startDate,
        endDate,
        page,
        limit,
        type,
        gender,
        age,
        status,
        location
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterUser);
