import moment from "moment";
import React, { useState } from "react";
import DeclineAppointment from "../../Appointments/DeclineAppointment";
import DeleteAppointment from "../../Appointments/DeleteAppointment";
import ResheduleAppointment from "../../Appointments/ResheduleAppointment";
import { cancelModal, viewModal } from "../../Modal/Modal";
import {
  ErrorBtn,
  SuccessBtn,
  PendingBtn,
  DropBtn,
  TableDropdown,
} from "../../Tables/TableButtons";
import Icon from "../../../assets/account_icon.svg";
import CompleteAppointment from "../../Appointments/CompleteAppointment";
import ConfirmAppointment from "../../Appointments/ConfirmAppointment";

export const appointmentColumns = [
  // {
  //   Header: "Username",
  //   accessor: "user.full_name",
  //   Cell: ({ row }) => (
  //     <div className="table-flex">
  //       <div className="table-flex-image">
  //         <img
  //           src={
  //             row.original.user && row.original.user.image_url
  //               ? row.original.user.image_url
  //               : Icon
  //           }
  //           alt="pic"
  //         />
  //       </div>
  //       <span style={{ marginLeft: "16px", textTransform: "capitalize" }}>
  //         {row.original.user?.username || "N/A"}
  //       </span>
  //     </div>
  //   ),
  // },
  {
    Header: "Email",
    accessor: "user.email",
    Cell: ({ row }) => (
      <div style={{ marginRight: "16px" }} className="table-flex">
        {row.original.user?.email || "Nil"}
      </div>
    ),
  },
  {
    Header: "Service",
    accessor: "appointment_sub_category.name",
    Cell: ({ row }) => (
      <div className="table-flex">
        {row.original.appointment_sub_category?.name || "Nil"}
      </div>
    ),
  },
  {
    Header: "Date Scheduled",
    accessor: "scheduled_date",
    Cell: ({ row }) => (
      <div className="table-flex">
        {moment(row.original.scheduled_date).format("L")}
      </div>
    ),
  },
  {
    Header: "Time",
    accessor: "scheduled_time",
    Cell: ({ row }) => {
      const date = new Date(row?.original.scheduled_time).toISOString();

      return <div className="table-flex">{moment(date).format("hh:mm a")}</div>;
    },
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ row }) =>
      row.original.status === "completed" ? (
        <SuccessBtn text={"Completed"} />
      ) : row.original.status === "declined" ? (
        <ErrorBtn text={"Declined"} />
      ) : row.original.status === "pending" ||
        (row.original.status !== "completed" &&
          row.original.status !== "declined" &&
          row.original.status !== "pending") ? (
        <PendingBtn text={row.original.status} />
      ) : (
        "Nil"
      ),
  },
  {
    Header: "Action ",
    accessor: "action",
    Cell: ({ row }) => {
      const [state, setState] = useState({
        declineModal: false,
        deleteModal: false,
        completeModal: false,
        confirmModal: false,
        openReshedule: false,
        show: false,
        index: "",
      });

      const [show, setShow] = useState(false);

      const openModal = (type) => {
        viewModal(type, state, setState);
        setShow((prevCheck) => !prevCheck);
      };

      const closeModal = (type) => {
        cancelModal(type, state, setState);
      };

      const options = [
        {
          name: "Reshedule",
          action: () => openModal("openReshedule"),
        },
        {
          name: "Decline",
          action: () => openModal("declineModal"),
        },
        {
          name: "Confirm",
          action: () => openModal("confirmModal"),
        },
        {
          name: "Complete",
          action: () => openModal("completeModal"),
        },
        {
          name: "Delete",
          action: () => openModal("deleteModal"),
        },
      ];

      const statusOptions = () => {
        if (row.original.status?.toLowerCase() === "declined")
          return [options[3]];
        return options;
      };

      const status = row.original.status?.toLowerCase() === "completed";

      return (
        <div className="action-flex" style={{ display: status ? "none" : "" }}>
          <DropBtn onClick={() => setShow((prevCheck) => !prevCheck)} />
          {show && <TableDropdown options={statusOptions()} />}

          {state.openReshedule && (
            <ResheduleAppointment
              displayModal={state.openReshedule}
              closeModal={() => closeModal("openReshedule")}
              data={row.original}
              limit={10}
            />
          )}

          {state.deleteModal && (
            <DeleteAppointment
              displayModal={state.deleteModal}
              closeModal={() => closeModal("deleteModal")}
              data={row.original}
              limit={10}
            />
          )}

          {state.declineModal && (
            <DeclineAppointment
              displayModal={state.declineModal}
              closeModal={() => closeModal("declineModal")}
              data={row.original}
              limit={10}
            />
          )}

          {state.completeModal && (
            <CompleteAppointment
              displayModal={state.completeModal}
              closeModal={() => closeModal("completeModal")}
              data={row.original}
              limit={10}
            />
          )}

          {state.confirmModal && (
            <ConfirmAppointment
              displayModal={state.confirmModal}
              closeModal={() => closeModal("confirmModal")}
              data={row.original}
              limit={10}
            />
          )}
        </div>
      );
    },
  },
];
