import React, { useEffect } from "react";
import { connect } from "react-redux";
import { deleteBlogPost, getBlogPosts } from "../../redux/Actions/BlogActions";
import DeleteModal from "../Modal/DeleteModal";

const DeleteBlog = (props) => {
  const { deleteBlogPost, isDeleting, deleteSuccess, id, getBlogPosts } = props;

  useEffect(
    () => {
      if (deleteSuccess) {
        props.closeModal();
        getBlogPosts(1, 10);
      }
    }, // eslint-disable-next-line
    [deleteSuccess]
  );

  const handleDelete = () => {
    deleteBlogPost(id);
  };

  return (
    <DeleteModal
      displayModal={props.displayModal}
      closeModal={props.closeModal}
      title={"Delete this article?"}
      width={"463px"}
      text={"Delete"}
      onClick={handleDelete}
      disabled={isDeleting}
      isLoading={isDeleting}
      loadingText={"Deleting..."}
      children={
        "Once you delete this article, it won’t be possible to undo this action. Delete article?"
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isDeleting: state.blog.isDeleting,
  deleteSuccess: state.blog.deleteSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  deleteBlogPost: (id) => dispatch(deleteBlogPost(id)),
  getBlogPosts: (page, limit) => dispatch(getBlogPosts(page, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteBlog);
