import React, { useEffect } from "react";
import { connect } from "react-redux";
import { changeCouponStatus } from "../../../redux/Actions/CouponsActions";
import DeleteModal from "../../Modal/DeleteModal";

const ScheduleCoupon = (props) => {
  const { changeCouponStatus, id, isChanging, changeSuccess } = props;

  useEffect(
    () => {
      if (changeSuccess) {
        props.closeModal();
      }
    }, // eslint-disable-next-line
    [changeSuccess]
  );

  const payload = {
    status: "schedule",
  };
  return (
    <DeleteModal
      displayModal={props.displayModal}
      closeModal={props.closeModal}
      title={"Change coupon status to Schedule?"}
      width={"463px"}
      text={"Schedule"}
      onClick={() => changeCouponStatus(id, payload)}
      disabled={isChanging}
      isLoading={isChanging}
      activate={true}
      loadingText={"Changing..."}
      children={
        "Once you change this coupon status, this coupon would show as scheduled. Change to scheduled?"
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isChanging: state.coupons.isChanging,
  changeSuccess: state.coupons.changeSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  changeCouponStatus: (id, payload) =>
    dispatch(changeCouponStatus(id, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleCoupon);
