import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";

// GET APPOINTMENT CATEGORIES
export const getAppointmentCategoriesStart = () => ({
  type: Actions.GET_APPOINTMENT_CATEGORIES_START,
});

export const getAppointmentCategoriesSuccess = (payload) => ({
  type: Actions.GET_APPOINTMENT_CATEGORIES_SUCCESS,
  payload,
});

export const getAppointmentCategoriesFailure = (payload) => ({
  type: Actions.GET_APPOINTMENT_CATEGORIES_FAILURE,
  payload,
});

// GET ALL APPOINTMENT CATEGORIES
export const getAllAppointmentCategoriesStart = () => ({
  type: Actions.GET_ALL_APPOINTMENT_CATEGORIES_START,
});

export const getAllAppointmentCategoriesSuccess = (payload) => ({
  type: Actions.GET_ALL_APPOINTMENT_CATEGORIES_SUCCESS,
  payload,
});

export const getAllAppointmentCategoriesFailure = (payload) => ({
  type: Actions.GET_ALL_APPOINTMENT_CATEGORIES_FAILURE,
  payload,
});

// SEARCH APPOINTMENT CATEGORIES
export const searchAppointmentCategoriesStart = () => ({
  type: Actions.SEARCH_APPOINTMENT_CATEGORIES_START,
});

export const searchAppointmentCategoriesSuccess = (payload) => ({
  type: Actions.SEARCH_APPOINTMENT_CATEGORIES_SUCCESS,
  payload,
});

export const searchAppointmentCategoriesFailure = (payload) => ({
  type: Actions.SEARCH_APPOINTMENT_CATEGORIES_FAILURE,
  payload,
});

// ADD NEW APPOINTMENT CATEGORY
export const addAppointmentCategoryStart = () => ({
  type: Actions.ADD_APPOINTMENT_CATEGORY_START,
});

export const addAppointmentCategorySuccess = (payload) => ({
  type: Actions.ADD_APPOINTMENT_CATEGORY_SUCCESS,
  payload,
});

export const addAppointmentCategoryFailure = (payload) => ({
  type: Actions.ADD_APPOINTMENT_CATEGORY_FAILURE,
  payload,
});

// GET SINGLE APPOINTMENT CATEGORY
export const getSingleAppointmentCategoryStart = () => ({
  type: Actions.GET_SINGLE_APPOINTMENT_CATEGORY_START,
});

export const getSingleAppointmentCategorySuccess = (payload) => ({
  type: Actions.GET_SINGLE_APPOINTMENT_CATEGORY_SUCCESS,
  payload,
});

export const getSingleAppointmentCategoryFailure = (payload) => ({
  type: Actions.GET_SINGLE_APPOINTMENT_CATEGORY_FAILURE,
  payload,
});

// EDIT APPOINTMENT CATEGORY
export const editAppointmentCategoryStart = () => ({
  type: Actions.EDIT_APPOINTMENT_CATEGORY_START,
});

export const editAppointmentCategorySuccess = (payload) => ({
  type: Actions.EDIT_APPOINTMENT_CATEGORY_SUCCESS,
  payload,
});

export const editAppointmentCategoryFailure = (payload) => ({
  type: Actions.EDIT_APPOINTMENT_CATEGORY_FAILURE,
  payload,
});

// DELETE APPOINTMENT CATEGORY
export const deleteAppointmentCategoryStart = () => ({
  type: Actions.DELETE_APPOINTMENT_CATEGORY_START,
});

export const deleteAppointmentCategorySuccess = (payload) => ({
  type: Actions.DELETE_APPOINTMENT_CATEGORY_SUCCESS,
  payload,
});

export const deleteAppointmentCategoryFailure = (payload) => ({
  type: Actions.DELETE_APPOINTMENT_CATEGORY_FAILURE,
  payload,
});

export const pushAppointmentToTopStart = () => ({
  type: Actions.PUSH_APPOINTMEMT_TO_TOP_START,
});

export const pushAppointmentToTopSuccess = () => ({
  type: Actions.PUSH_APPOINTMEMT_TO_TOP_SUCCESS,
});

export const getAppointmentCategories = () => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getAppointmentCategoriesStart());
  API.get("/appointment-category").then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getAppointmentCategoriesSuccess(data && data.data));
    } else {
      dispatch(getAppointmentCategoriesFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const getAllAppointmentCategories =
  (page, limit) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

    dispatch(getAllAppointmentCategoriesStart());
    API.get(`/appointment-category/all?limit=${limit}&page=${page}`).then(
      (response) => {
        const { data, ok } = response;
        if (ok) {
          dispatch(getAllAppointmentCategoriesSuccess(data && data.data));
        } else {
          dispatch(getAllAppointmentCategoriesFailure(data));
          responseHandler(response, "Failed!");
        }
      }
    );
  };

export const searchAppointmentCategories =
  (page, limit, searchValue) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

    dispatch(searchAppointmentCategoriesStart());
    API.get(
      `/appointment-category/all?limit=${limit}&page=${page}&search=${searchValue}`
    ).then((response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(searchAppointmentCategoriesSuccess(data && data.data));
      } else {
        dispatch(searchAppointmentCategoriesFailure(data));
        responseHandler(response, "Failed!");
      }
    });
  };

export const addAppointmentCategory = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(addAppointmentCategoryStart());
  API.post("/appointment-category", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(addAppointmentCategorySuccess(data && data.data));
      responseHandler(response, "Sucess!");
    } else {
      dispatch(addAppointmentCategoryFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const getSingleAppointmentCategory = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getSingleAppointmentCategoryStart());
  API.get(`appointment-category/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getSingleAppointmentCategorySuccess(data && data.data));
    } else {
      dispatch(getSingleAppointmentCategoryFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const editAppointmentCategory = (id, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(editAppointmentCategoryStart());
  API.patch(`appointment-category/${id}`, payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(editAppointmentCategorySuccess(data && data.data));
      responseHandler(response, "Sucess!");
    } else {
      dispatch(editAppointmentCategoryFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const deleteAppointmentCategory = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(deleteAppointmentCategoryStart());
  API.delete(`appointment-category/delete/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(deleteAppointmentCategorySuccess(data && data.data));
      responseHandler(response, "Sucess!");
    } else {
      dispatch(deleteAppointmentCategoryFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const swapAppointmentRank = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  API.post(`appointment-category/swap-ranks`, payload).then((response) => {
    const { ok } = response;
    if (!ok) {
      responseHandler(response, "Failed!");
    }
  });
};

export const swapAppointmentSubcategoryRank = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"))
  API.post(`appointment-category/subcategories/swap-ranks`, payload).then((response) => {
    const { ok } = response;
    if (!ok) {
      responseHandler(response, "Failed!");
    }
  });
}