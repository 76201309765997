import React, { useEffect, useState } from "react";
import Appointment3 from "../Appointment3";
import { Session } from "./Session";

export const ClientRight = (props) => {
  const { appointments, clientSession } = props;

  const [data, setData] = useState(appointments);

  const paginateData = (data) => {
    const items = data && data.slice(0, 4);
    return setData(items);
  };

  useEffect(() => {
    paginateData(appointments);
  }, [appointments]);

  const showAllData = () => {
    setData(appointments);
  };

  return (
    <>
      <div className="info">
        <h4>Recent Session</h4>
        <Session clientSession={clientSession} />
      </div>
      <div className="info">
        <div className="info-top">
          <h4>Recents Appointments</h4>
          <span data-testid={"showAll"} onClick={() => showAllData()}>
            View all appointments
          </span>
        </div>
        <Appointment3 data={data} />
      </div>
    </>
  );
};
