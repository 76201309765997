import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  deleteProduct,
  getAllProducts,
} from "../../../redux/Actions/ProductActions";
import DeleteModal from "../../Modal/DeleteModal";

const DeleteProduct = (props) => {
  const { isDeleting, deleteSuccess, deleteProduct, getProducts, id } =
    props;

  const handleDelete = () => {
    deleteProduct(id);
  };

  useEffect(
    () => {
      if (deleteSuccess) {
        props.closeModal();
        getProducts(1, 10);
      }
    }, // eslint-disable-next-line
    [deleteSuccess]
  );

  return (
    <DeleteModal
      displayModal={props.displayModal}
      closeModal={props.closeModal}
      title={"Delete this product?"}
      width={"463px"}
      text={"Delete"}
      onClick={handleDelete}
      disabled={isDeleting}
      isLoading={isDeleting}
      loadingText={"Deleting..."}
      children={
        "Once you delete this product, it won’t be possible to undo this action. Delete product?"
      }
    />
  );
};
const mapStateToProps = (state) => ({
  isDeleting: state.products.isDeleting,
  deleteSuccess: state.products.deleteSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getProducts: (page, limit) => dispatch(getAllProducts(page, limit)),
  deleteProduct: (payload) => dispatch(deleteProduct(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteProduct);
