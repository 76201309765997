import Actions from "../Types";

const initialState = {
  isGettingPlans: false,
  isCreatingPlan: false,
  isEditingPlan: false,
  isChangingStatus: false,
  isGettingMostSubscribed: false,
  getSuccess: false,
  createSuccess: false,
  editSuccess: false,
  changeSuccess: false,
  getMostSubscribedSuccess: false,
  plans: [],
  plansData: {},
  plan: {},
  mostSubscribedPlan: {},
  isGettingSubscribers: false,
  getSubscribersSuccess: false,
  subscribers: [],
  subscribersData: {},
  isFilteringSubscribers: false,
  filterSuccess: false,
  filteredSubscribers: [],
  filteredSubscribersData: {},
  isSearchingSubscribers: false,
  searchSuccess: false,
  searchedSubscribers: [],
  searchedSubscribersData: {},
  isDeleting: false,
  deleteSuccess: false,
  error: null,
  csvResponseData: "",
  isGettingCSVResponse: false,
  getCSVResponseDataSuccess: false,
  isAssigningSubscription: false,
  assignSubscriptionSuccess: false,
  assignSubscriptionFailure: false,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_ALL_PLANS_START:
      return {
        ...state,
        deleteSuccess: false,
        isGettingPlans: true,
        getSuccess: false,
        plans: [],
        error: null,
      };
    case Actions.GET_ALL_PLANS_SUCCESS:
      return {
        ...state,
        isGettingPlans: false,
        getSuccess: true,
        plans: action.payload,
        plansData: action.payload,
        changeSuccess: false,
        editSuccess: false,
        deleteSuccess: false,
        error: null,
      };
    case Actions.GET_ALL_PLANS_FAILURE:
      return {
        ...state,
        isGettingPlans: false,
        getSuccess: false,
        plans: [],
        plansData: {},
        error: action.payload,
      };
    case Actions.GET_ALL_SUBSCRIBERS_START:
      return {
        ...state,
        isGettingSubscribers: true,
        getSubscribersSuccess: false,
        filterSuccess: false,
        subscribers: [],
        subscribersData: {},
        error: null,
      };
    case Actions.GET_ALL_SUBSCRIBERS_SUCCESS:
      return {
        ...state,
        isGettingSubscribers: false,
        getSubscribersSuccess: true,
        subscribers: action.payload && action.payload.subscribers,
        subscribersData: action.payload,
        filterSuccess: false,
        changeSuccess: false,
        editSuccess: false,
        deleteSuccess: false,
        assignSubscriptionSuccess: false,
        error: null,
      };
    case Actions.GET_ALL_SUBSCRIBERS_FAILURE:
      return {
        ...state,
        isGettingSubscribers: false,
        getSubscribersSuccess: false,
        filterSuccess: false,
        subscribers: [],
        subscribersData: {},
        error: action.payload,
      };
    case Actions.CREATE_PLAN_START:
      return {
        ...state,
        isCreatingPlan: true,
        createSuccess: false,
        plan: {},
        error: null,
      };
    case Actions.CREATE_PLAN_SUCCESS:
      return {
        ...state,
        isCreatingPlan: false,
        createSuccess: true,
        plan: action.payload,
        error: null,
      };
    case Actions.CREATE_PLAN_FAILURE:
      return {
        ...state,
        isCreatingPlan: false,
        createSuccess: false,
        plan: null,
        error: action.payload,
      };
    case Actions.EDIT_PLAN_START:
      return {
        ...state,
        isEditingPlan: true,
        editSuccess: false,
        plan: {},
        error: null,
      };
    case Actions.EDIT_PLAN_SUCCESS:
      return {
        ...state,
        isEditingPlan: false,
        editSuccess: true,
        plan: action.payload,
        error: null,
      };
    case Actions.EDIT_PLAN_FAILURE:
      return {
        ...state,
        isEditingPlan: false,
        editSuccess: false,
        plan: null,
        error: action.payload,
      };
    case Actions.DELETE_PLAN_START:
      return {
        ...state,
        isDeleting: true,
        deleteSuccess: false,
        plan: null,
        error: null,
      };
    case Actions.DELETE_PLAN_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: true,
        plan: action.payload,
        error: null,
      };
    case Actions.DELETE_PLAN_FAILURE:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: false,
        plan: null,
        error: action.payload,
      };
    case Actions.CHANGE_SUBSCRIPTION_PLAN_STATUS_START:
      return {
        ...state,
        isChangingStatus: true,
        changeSuccess: false,
        plan: {},
        error: null,
      };
    case Actions.CHANGE_SUBSCRIPTION_PLAN_STATUS_SUCCESS:
      return {
        ...state,
        isChangingStatus: false,
        changeSuccess: true,
        plan: action.payload,
        error: null,
      };
    case Actions.CHANGE_SUBSCRIPTION_PLAN_STATUS_FAILURE:
      return {
        ...state,
        isChangingStatus: false,
        changeSuccess: false,
        plan: null,
        error: action.payload,
      };
    case Actions.FILTER_ALL_SUBSCRIBERS_START:
      return {
        ...state,
        isFilteringSubscribers: true,
        filterSuccess: false,
        filteredSubscribers: [],
        filteredSubscribersData: {},
        error: null,
      };
    case Actions.FILTER_ALL_SUBSCRIBERS_SUCCESS:
      return {
        ...state,
        isFilteringSubscribers: false,
        filterSuccess: true,
        searchSuccess: false,
        filteredSubscribers: action.payload && action.payload.subscribers,
        filteredSubscribersData: action.payload,
        error: null,
      };
    case Actions.FILTER_ALL_SUBSCRIBERS_FAILURE:
      return {
        ...state,
        isFilteringSubscribers: false,
        filterSuccess: false,
        filteredSubscribers: [],
        filteredSubscribersData: {},
        error: action.payload,
      };
    case Actions.SEARCH_ALL_SUBSCRIBERS_START:
      return {
        ...state,
        isSearchingSubscribers: true,
        searchSuccess: false,
        searchedSubscribers: [],
        searchedSubscribersData: {},
        error: null,
      };
    case Actions.SEARCH_ALL_SUBSCRIBERS_SUCCESS:
      return {
        ...state,
        isSearchingSubscribers: false,
        searchSuccess: true,
        filterSuccess: false,
        searchedSubscribers: action.payload && action.payload.subscribers,
        searchedSubscribersData: action.payload,
        error: null,
      };
    case Actions.SEARCH_ALL_SUBSCRIBERS_FAILURE:
      return {
        ...state,
        isSearchingSubscribers: false,
        searchSuccess: false,
        searchedSubscribers: [],
        searchedSubscribersData: {},
        error: action.payload,
      };
    case Actions.GET_MOST_SUBSCRIBED_PLAN_START:
      return {
        ...state,
        isGettingMostSubscribed: true,
        getMostSubscribedSuccess: false,
        mostSubscribedPlan: {},
        error: null,
      };
    case Actions.GET_MOST_SUBSCRIBED_PLAN_SUCCESS:
      return {
        ...state,
        isGettingMostSubscribed: false,
        getMostSubscribedSuccess: true,
        mostSubscribedPlan: action.payload,
        error: null,
      };
    case Actions.GET_MOST_SUBSCRIBED_PLAN_FAILURE:
      return {
        ...state,
        isGettingMostSubscribed: false,
        getMostSubscribedSuccess: false,
        mostSubscribedPlan: null,
        error: action.payload,
      };
    case Actions.GET_CSV_START:
      return {
        ...state,
        isGettingCSVResponse: true,
        getCSVResponseDataSuccess: false,
      };
    case Actions.GET_EXPORT_CSV_SUCCESS:
      return {
        ...state,
        isGettingCSVResponse: false,
        getCSVResponseDataSuccess: true,
        csvResponseData: action.payload,
      };
    case Actions.ASSIGN_SUBSCRIPTION_START:
      return {
        ...state,
        isAssigningSubscription: true,
        assignSubscriptionSuccess: false,
        assignSubscriptionFailure: false,
      };
    case Actions.ASSIGN_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        assignSubscriptionFailure: false,
        isAssigningSubscription: false,
        assignSubscriptionSuccess: true,
      };
    case Actions.ASSIGN_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        assignSubscriptionFailure: true,
        isAssigningSubscription: false,
        assignSubscriptionSuccess: false,
      };
    default:
      return state;
  }
};

export default reduxState;
