import Actions from "../Types";

const initialState = {
  prescriptionsData: [],
  prescriptions: {},
  isGetting: false,
  getPrescriptionsSuccess: false,

  prescriptionData: {},
  isGettingPrescription: false,
  getPrescriptionSuccess: false,

  isFiltering: false,
  filterSuccess: false,
  filteredData: [],
  filterData: {},

  isSearching: false,
  searchSuccess: false,
  searchedData: [],
  searchData: {}
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_ALL_PRESCRIPTIONS_START:
      return {
        ...state,
        isGetting: true,
        getPrescriptionsSuccess: false,
        isFiltering: false,
        filterSuccess: false,
        isSearching: false,
        searchSuccess: false,
        prescriptionData: []        
      };
    case Actions.GET_ALL_PRESCRIPTIONS_SUCCESS:
      return {
        ...state,
        prescriptionsData: action.payload.prescriptions,
        prescriptions: action.payload,
        isGetting: false,
        getPrescriptionsSuccess: true,
        isFiltering: false,
        filterSuccess: false,
        isSearching: false,
        searchSuccess: false,
      };
    case Actions.GET_PRESCRIPTION_START:
      return {
        ...state,
        isGettingPrescription: true,
        getPrescriptionSuccess: false,
        prescriptionData: {},
      };
    case Actions.GET_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        isGettingPrescription: false,
        getPrescriptionSuccess: true,
        prescriptionData: action.payload,
      };
    case Actions.FILTER_PRESCRIPTIONS_START:
      return {
        ...state,
        isGetting: false,
        getPrescriptionsSuccess: false,
        isFiltering: true,
        filterSuccess: false,
        isSearching: false,
        searchSuccess: false,
      };
    case Actions.FILTER_PRESCRIPTIONS_SUCCESS:
      return {
        ...state,
        filteredData: action.payload.prescriptions,
        filterData:action.payload,
        isGetting: false,
        getPrescriptionsSuccess: false,
        isFiltering: false,
        filterSuccess: true,
        isSearching: false,
        searchSuccess: false,
      };
    case Actions.SEARCH_PRESCRIPTIONS_START:
      return {
        ...state,
        isGetting: false,
        getPrescriptionsSuccess: false,
        isFiltering: false,
        filterSuccess: false,
        isSearching: true,
        searchSuccess: false,
      };
    case Actions.SEARCH_PRESCRIPTIONS_SUCCESS:
      return {
        ...state,
        isGetting: false,
        getPrescriptionsSuccess: false,
        isFiltering: false,
        filterSuccess: false,
        isSearching: false,
        searchSuccess: true,
        searchedData: action.payload.prescriptions,
        searchData: action.payload
      };
    default:
      return state;
  }
};

export default reduxState;
