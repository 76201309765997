import React, { useState } from 'react';

const MiniDropdown = ({ label, active, onSelect, isDropdown, options }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownHandler = (value) => {
    if (isDropdown) {
      setShowDropdown(value);
      return;
    }

    onSelect({ label });
  };

  const itemSelectHandler = (option) => {
    onSelect(option);
  };

  return (
    <div className='mini__tab-wrapper'>
      <div
        tabIndex='-1'
        onFocus={() => dropdownHandler(true)}
        onBlur={() => setTimeout(() => dropdownHandler(false), 200)}
        className={`mini__tab${active ? ' active' : ''}`}
      >
        <span className='text-capitalize'>{label}</span>
      </div>
      {options && (
        <ul className={`mini__tab-dropdown${showDropdown ? ' active' : ''}`}>
          {options.map((option, index) => (
            <li
              key={index}
              onClick={() => itemSelectHandler(option)}
              className='mini__tab-dropdown-item'
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MiniDropdown;
