import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ModalButton } from "../../components/Modal/Modal";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import {
  getAppVersions,
  updateAppVersions,
} from "../../redux/Actions/AppVersions";
import { Field, Form, Formik } from "formik";
import Input from "../../components/Input/Input";
import { LoaderSpin } from "../../components/Loader/Loader";

const AppVersions = (props) => {
  const {
    getAppversionsData,
    isGetting,
    appVersionsData,
    getAppVersionsSuccess,
    isUpdating,
    updateAppVersionsSuccess,
    updateAppVersionsHandler,
  } = props;
  const [state, setState] = useState({
    updateAppVersionsModal: false,
    hfcp: "",
    whispa_consultant: "",
    whispa_doctor: "",
    whispa_patient: "",
  });
  useEffect(() => {
    getAppversionsData();
  }, []);

  useEffect(() => {
    if (getAppVersionsSuccess && appVersionsData.length > 0) {
      console.log(appVersionsData);
      setState({
        ...state,
        hfcp: appVersionsData[0].hfcp,
        whispa_consultant: appVersionsData[0].whispa_consultant,
        whispa_doctor: appVersionsData[0].whispa_doctor,
        whispa_patient: appVersionsData[0].whispa_patient,
      });
    }
  }, [getAppVersionsSuccess]);

  const initialValues = {
    whispa_consultant: state.whispa_consultant,
    hfcp: state.hfcp,
    whispa_doctor: state.whispa_doctor,
    whispa_patient: state.whispa_patient,
  };

  const submitValues = (values) => {
    const payload = values;
    console.log(payload);
    updateAppVersionsHandler(payload);
  };
  return (
    <div className="" style={{height: "100vh"}}>
      <HeaderWrapper title={"App Version"} />
      {state.hfcp ? (
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => submitValues(values)}
        >
          {({ isValid, dirty, setFieldValue }) => {
            const disabled = !(isValid && dirty);
            return (
              <Form id="updateappversionform">
                <div className="grid-4-one">
                  <Field
                    name="whispa_consultant"
                    label="Whispa Consultant"
                    placeholder={"Enter Whipa Consultant App Version"}
                    component={Input}
                    emptyValue={setFieldValue}
                  />
                  <Field
                    name="hfcp"
                    label="HFCP"
                    placeholder={"Enter HCFP App Version"}
                    component={Input}
                    emptyValue={setFieldValue}
                  />
                  <Field
                    name="whispa_doctor"
                    label="Whispa Doctor"
                    placeholder={"Enter Whipa Doctor App Version"}
                    component={Input}
                    emptyValue={setFieldValue}
                  />
                  <Field
                    name="whispa_patient"
                    label="Whispa Patient"
                    placeholder={"Enter Whipa Patient App Version"}
                    component={Input}
                    emptyValue={setFieldValue}
                  />
                </div>
                <ModalButton
                  isLoading={isUpdating}
                  text={"Update App Versions"}
                  type={"submit"}
                  testId={"button"}
                  loadingText={"Updating...."}
                  disabled={disabled}
                />
              </Form>
            );
          }}
        </Formik>
      ) : (
        <LoaderSpin />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  appVersionsData: state.appversions.appVersionsData,
  getAppVersionsSuccess: state.appversions.getAppVersionsSuccess,
  isGetting: state.appversions.isGetting,
  isUpdating: state.appversions.isUpdating,
  updateAppVersionsSuccess: state.appversions.updateAppVersionsSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getAppversionsData: () => dispatch(getAppVersions()),
  updateAppVersionsHandler: (payload) => dispatch(updateAppVersions(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppVersions);
