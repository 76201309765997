import React, { useState, useEffect, useRef } from "react";
import Green from "../../assets/graph-green.svg";
import Blue from "../../assets/graph-blue.svg";
import moment from "moment";
import { Line } from "react-chartjs-2";
import { reduce } from "lodash";

const defaultLabelProps = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const ChatDistributionLine = ({ overviewData, graphType }) => {
  const [date, setDate] = useState(moment().format("LLL"));
  const [labelProps, setLabelProps] = useState([]);
  const { chat_distribution } = overviewData;

  const bgColors = ["#F93D55", "#00DCB4", "#0A74DC", "#00DCB4"];
  const dateRef = useRef(null);

  const dataHandler = (data) => {
    if (graphType === "year") {
      return Array.from(
        { length: defaultLabelProps?.length },
        (_, index) => index
      ).map((value) => {
        const yAxis = data?.find((val) => value === new Date(val.x).getMonth());
        return yAxis?.y ?? 0;
      });
    }

    return data?.map((key) => key.y);
  };

  const dataState = {
    labels: labelProps,
    datasets: chat_distribution.map((x, i) => ({
      label: x?.full_name ? x.full_name : `${x.first_name} ${x.last_name}`,
      data: dataHandler(x?.year),
      fill: true,
      borderColor: "#00DCB4",
      backgroundColor: "transparent",
      borderColor: bgColors[i],
      lineTension: 0.5,
    })),
  };

  useEffect(
    () => {
      dateRef.current = setInterval(
        () => setDate(moment().format("LLL")),
        60_000
      );
      return () => clearInterval(dateRef.current);
    }, // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (graphType === "year") {
        setLabelProps(defaultLabelProps);
        return;
      }

      setLabelProps(() =>
        reduce(chat_distribution?.map((x) => x.year) ?? [], (a, b) => [
          ...a,
          ...b,
        ])?.map((type) => new Date(type.x).toDateString())
      );
    }, // eslint-disable-next-line
    [graphType]
  );

  return (
    <div className="overview-graph">
      <div className="overview-graph-header">
        <div className="overview-graph-header-left">
          <h4>Chat Distribution</h4>
          <p>{date}</p>
        </div>

        <div className="overview-graph-header-right">
          {chat_distribution.map((x, i) => (
            <div
              key={i}
              className="overview-graph-header-right-indicators"
              style={{
                alignItems: "center",
              }}
            >
              <div
                style={{
                  background: bgColors[i],
                  width: 14,
                  height: 14,
                  alignItems: "center",
                  borderRadius: "100%",
                }}
              ></div>
              <span>
                {x?.full_name ? x.full_name : `${x.first_name} ${x.last_name}`}
              </span>
            </div>
          ))}
        </div>
      </div>

      <div className="overview-graph-section">
        <Line
          data={dataState}
          options={{
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
              y: {
                grid: {
                  borderDash: [10, 10],
                },
                beginAtZero: true,
              },
            },
          }}
        />
      </div>
    </div>
  );
};

ChatDistributionLine.defaultProps = {
  graphType: "year",
};

export default ChatDistributionLine;
