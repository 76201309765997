import React, { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import FacilityInputs from "./FacilityInputs";
import { getAppointmentCategories } from "../../redux/Actions/AppointmentActions";
import { connect } from "react-redux";
import { assignCategoryOptions } from "../../utils/functions/UpdateValues"; 
import { AddFacilitySchema } from "../../utils/validation/validation";
import {
  filterFacilityCategories,
  filterFacilityPayload,
} from "../../utils/functions/FilterObject";



const AddFacility = (props) => {
  const {
    displayModal,
    closeModal,
    isGettingCategory,
    getSuccess,
    categories,
    getCategory,
    state,
    setState,
    isAdding,
    addFacility,
  } = props;

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);

  useEffect(
    () => {
      getCategory();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (getSuccess) {
        const updatedCategory = assignCategoryOptions(categories);
        setCategoryOptions(updatedCategory);
      }
    },
    // eslint-disable-next-line
    [getSuccess]
  );

  const initialValues = {
    name: "",
    email: "",
    city: "",
    full_address: "",
    closest_landmark: "",
    phone_number: "",
    description: "",
    years_of_operation: "",
  };

  const selectedCategories =
    state.categories && filterFacilityCategories(state.categories);

  const submitValues = (values) => {
    const payload = {
      name: values.name,
      state: state.state,
      city: values.city,
      email: values.email,
      full_address: values.full_address,
      closest_landmark: values.closest_landmark,
      appointment_categories:
        state.categories && filterFacilityPayload(state.categories),
      phone_number: values.phone_number,
      description: values.description,
      years_of_operation: values.years_of_operation.toString(),
    };

    console.log('payload', payload)

    addFacility(payload);
  };

  const disabled = (isValid, dirty, values) =>
    !(isValid && dirty) || isAdding || state.state === "" || selectedCategories;

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Add New Facility"}
      width={"568px"}
    >
      <FacilityInputs
        formId={"addFacility"}
        text={"Create Facility"}
        loadingText={"Creating..."}
        isGettingCategory={isGettingCategory}
        getSuccess={getSuccess}
        submitValues={submitValues}
        state={state}
        setState={setState}
        disabledFunc={disabled}
        isLoading={isAdding}
        setOptions={setCategoryOptions}
        options={categoryOptions}
        subcategoryOptions={subcategoryOptions}
        setSubcategoryOptions={setSubcategoryOptions}
        categories={categories}
        schema={AddFacilitySchema}
        initialValues={initialValues}
      />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isGettingCategory: state.appointment.isGettingCategories,
  getSuccess: state.appointment.getCategorySuccess,
  categories: state.appointment.categories,
});

const mapDispatchToProps = (dispatch) => ({
  getCategory: () => dispatch(getAppointmentCategories()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddFacility);
