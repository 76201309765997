import { Field, Form, Formik } from "formik";
import React from "react";
import Input from "../../../components/Input/Input";
import Modal, { ModalButton } from "../../../components/Modal/Modal";
import { BlogCategorySchema } from "../../../utils/validation/validation";

const AddCategory = (props) => {
  const { displayModal, closeModal, isCreating, createCategory } = props;

  const initialValues = {
    name: "",
  };

  const submitValues = (values) => {
    const payload = {
      name: values.name,
      status: "active",
    };
    createCategory(payload);
  };

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Create Blog Category"}
      width={"496px"}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={BlogCategorySchema}
        onSubmit={(values) => submitValues(values)}
      >
        {({ isValid, dirty, setFieldValue }) => {
          return (
            <Form id="addCategory">
              <Field
                name="name"
                label="Category name"
                placeholder="Enter category name"
                component={Input}
                emptyValue={setFieldValue}
              />

              <ModalButton
                isLoading={isCreating}
                text={"Create Category"}
                type={"submit"}
                testId={"button"}
                loadingText={"Creating..."}
                disabled={!(isValid && dirty) || isCreating}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddCategory;
