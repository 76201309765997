import moment from "moment";
import React, { useEffect, useState } from "react";
import SelectTable from "../../Tables/SelectTable";
import {
  DropBtn,
  ErrorBtn,
  InfoBtn,
  PendingBtn,
  SuccessBtn,
  TableDropdown,
} from "../../Tables/TableButtons";

const OrdersColumns = (props) => {
  useEffect(
    () => {
      setState({
        searchValue: "",
        active1: true,
      });
    }, // eslint-disable-next-line
    []
  );
  const {
    options,
    selectedRows,
    setSelectedRows,
    state,
    setState,
    isLoading,
    success,
    sortSucces,
    getData,
    total,
    data,
    getMoreData,
  } = props;

  const [show, setShow] = useState(false);

  const showDropdown = (data) => {
    console.log(data)
    setShow((prevCheck) => !prevCheck);
    setState({
      ...state,
      show: (prevCheck) => !prevCheck,
      id: data && data.id,
      order_id: data && data.order_id,
      orderData: data,
    });
  };

  const columnData = [
    {
      Header: "Order No.",
      accessor: "order_number",
      Cell: ({ row }) => (
        <span>
          {row.original?.order?.order_number || row.original?.order_number}
        </span>
      ),
    },
    {
      Header: "Customer Name",
      accessor: "full_name",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <span style={{ textTransform: "capitalize" }}>
            {row.original?.order?.user?.first_name && row.original?.order?.user?.last_name
              ? `${row.original?.order?.user?.first_name} ${row.original?.order?.user?.last_name}`
              : row.original?.order?.user?.full_name || "Null"}
          </span>
        </div>
      ),
    },
    {
      Header: " Total Price",
      accessor: "price",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <span>
            NGN {row.original?.price * row.original?.qty}
          </span>
        </div>
      ),
    },
    {
      Header: "Date",
      accessor: "date",
      Cell: ({ row }) => (
        <div className="table-flex">
          {moment(row.original.created_at).format("DD-MM-YYYY")}
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="action-flex">
          {row.original.status === "pending" ? (
            <PendingBtn text={"Pending"} />
          ) : row.original.status === "processing" ? (
            <InfoBtn text={"Processing"} />
          ) : (
            (row.original.status = "delivered" ? (
              <SuccessBtn text={"Delivered"} />
            ) : (
              <ErrorBtn text={"Canceled"} />
            ))
          )}
        </div>
      ),
    },
    {
      Header: "",
      accessor: "action",
      Cell: ({ row }) => {
        const id = row.original && row.original.id;
    

        return (
          <div className="action-flex">
            <DropBtn onClick={() => showDropdown(row.original)} />
            {show && state.id === id && <TableDropdown options={options} />}
          </div>
        );
      },
    },
  ];

  return (
    <div className="orders-table">
      <SelectTable
        columns={columnData}
        data={data}
        emptyText1={"Order not found!"}
        getData={(page, limit) => getData(page, limit)}
        isLoading={isLoading}
        success={success}
        sortSucces={sortSucces}
        total={total}
        emptyResultText={"Order not found!"}
        className={"main-order-table"}
        setSelectedRows={() => setSelectedRows()}
        selectedRows={selectedRows}
        getMoreData={getMoreData}
        state={state}
        setState={setState}
      />
    </div>
  );
};

export default OrdersColumns;
