import React from "react";
import { useHistory } from "react-router-dom";
import Back from "../../assets/chevron_right.svg";

const BackBtn = ({ page, onClick }) => {
  const history = useHistory();

  const goBack = () => {
    history.push(page);
  };

  return (
    <div
      className="back-button"
      onClick={onClick ? onClick : goBack}
      data-testid="goBackBtn"
    >
      <img src={Back} alt="back button" /> <span>Back</span>
    </div>
  );
};

export default BackBtn;
