import React, { useEffect, useState } from "react";
import { PageButton } from "../../components/Button/Button";
import { cancelModal, viewModal } from "../../components/Modal/Modal";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import Add from "../../assets/add.svg";
import SortData from "../../components/Sorting/SortData";
import AddProduct from "../../components/Products/Products/AddProduct";
import { connect } from "react-redux";
import {
  createProduct,
  getAllProducts,
  searchProducts,
} from "../../redux/Actions/ProductActions";
import SwitchWrapper from "../../components/Wrappers/SwitchWrapper";
import ProductsContainer from "./ProductsContainer";
import ProductCategory from "./ProductCategory";
import AddCategory from "../../components/Products/ProductCategory/AddCategory";
import { createProductCategory } from "../../redux/Actions/ProductCategoryAction";
import TableDropdown from "../../components/Tables/Dropdown";
import ExportIcon from "../../assets/Union.svg";
import DropIcon from "../../assets/drop-export.svg";
import downloadCSV from "../../utils/functions/csvDownloader";

export const paginateProductFilter = (
  page,
  perPage,
  values,
  filterProducts
) => {
  const payload = {
    category_id: values.category_id,
    status: values.status,
    date: values.date,
  };
  filterProducts(page, perPage, payload);
};

const Products = (props) => {
  const {
    isCreating,
    createProduct,
    products,
    searchProducts,
    isCreatingCategory,
    createCategory,
    addSuccess,
    createCategorySuccess,
    filterSuccess,
    searchSuccess,
    getProducts,
    getSuccess,
  } = props;
  const [show, setShow] = useState(false);
  const [state, setState] = useState({
    active1: true,
    active2: false,
    addModal: false,
    addCategory: false,
    filterModal: false,
    searchValue: "",
    filterValues: {},
  });

  const [productsData, setProducts] = useState([]);

  const openModal = (type) => viewModal(type, state, setState);
  const closeModal = (type) => cancelModal(type, state, setState);

  const searchAllProducts = () => {
    if (state.searchValue === "") {
      return products;
    } else {
      searchProducts(1, 10, state.searchValue);
    }
  };

  useEffect(
    () => {
      if (getSuccess) {
        setState({
          ...state,
          searchValue: "",
        });
      }
    },
    // eslint-disable-next-line
    [getSuccess]
  );

  const exportHandler = (format) => {
    switch (format) {
      case "CSV":
        const data = productsData;
        const exportData =
          data &&
          data.map((product) => ({
            "Product Name": product.name,
            Category: product?.category?.name,
            "Price (NGN)": product.price,
            Sold: product.quantity_sold,
            Active: product.active_status,
            Status: product.product_status,
          }));
        downloadCSV(exportData, "products-data");
        return;
      default:
        return;
    }
  };

  const export_options = [
    {
      name: "CSV",
      action: () => exportHandler("CSV"),
    },
  ];

  return (
    <div>
      <HeaderWrapper
        title={"Product Management"}
        subtitle={"View products and add also stock new products"}
      >
        <div
          style={{ display: "flex", alignItems: "center" }}
          className="overview-header-right-button"
        >
          <PageButton
            width={"auto"}
            background={"rgba(10, 116, 220, 0.08)"}
            marginTop={"30px"}
            marginRight={"10px"}
            onClick={
              state.active1
                ? () => openModal("addModal")
                : () => openModal("addCategory")
            }
            type={"submit"}
          >
            <div className="button-data">
              <img src={Add} alt="add-icon" />
              <span>{state.active1 ? "Add Product" : "Add Category"}</span>
            </div>
          </PageButton>
          {state.active1 ? (
            <PageButton
              width={"121px"}
              mainWidth={"144px"}
              background={"rgba(10, 116, 220, 0.08)"}
              marginTop={"30px"}
              type={"button"}
              onClick={() => setShow((prevCheck) => !prevCheck)}
            >
              <div className="button-data">
                <img src={ExportIcon} alt="export icon" />
                <span className="span2">Export</span>
                <img src={DropIcon} alt="dropdown" />
              </div>
            </PageButton>
          ) : null}
          {show && (
            <TableDropdown
              options={export_options}
              hideDropdown={() => setShow((prevCheck) => !prevCheck)}
              active
            />
          )}
        </div>
      </HeaderWrapper>

      <SwitchWrapper
        title1={"All Products"}
        title2={"Category"}
        state={state}
        setstate={setState}
        right1={
          <SortData
            placeholder={"Search by product name"}
            width={"347px"}
            searchItems={searchAllProducts}
            setState={setState}
            state={state}
            onClick={() => openModal("filterModal")}
            filter={true}
            sortSuccess={filterSuccess || searchSuccess}
            refreshFunction={() => getProducts(1, 10)}
          />
        }
        component1={
          <ProductsContainer
            state={state}
            closeModal={closeModal}
            setState={setState}
            setProducts={setProducts}
            addSuccess={addSuccess}
          />
        }
        component2={
          <ProductCategory
            state={state}
            closeModal={closeModal}
            setState={setState}
            createSuccess={createCategorySuccess}
          />
        }
      />

      <AddProduct
        displayModal={state.addModal}
        closeModal={() => closeModal("addModal")}
        isCreating={isCreating}
        createProduct={createProduct}
        state={state}
        setState={setState}
        addSuccess={addSuccess}
      />

      <AddCategory
        displayModal={state.addCategory}
        closeModal={() => closeModal("addCategory")}
        isCreating={isCreatingCategory}
        createCategory={createCategory}
        state={state}
        setState={setState}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  products: state.products.allProducts,
  getSuccess: state.products.getSuccess,
  filterSuccess: state.products.filterSuccess,
  searchSuccess: state.products.searchSuccess,
  isCreating: state.products.isCreating,
  addSuccess: state.products.success,
  isCreatingCategory: state.productcategory.isCreating,
  createCategorySuccess: state.productcategory.success,
});

const mapDispatchToProps = (dispatch) => ({
  searchProducts: (page, limit, searchValue) =>
    dispatch(searchProducts(page, limit, searchValue)),
  createProduct: (payload) => dispatch(createProduct(payload)),
  createCategory: (payload) => dispatch(createProductCategory(payload)),
  getProducts: (page, limit) => dispatch(getAllProducts(page, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Products);
