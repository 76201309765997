import React from "react";
import { useHistory } from "react-router-dom";
import no_admin from "../../assets/no_admin_logo.svg";
import { AddAdminButton, PageButton } from "../../components/Button/Button";

const NoAdmin = (props) => {
  const { setstate } = props;

  const history = useHistory();

  const goToOverviewPage = () => {
    history.push({
      pathname: "/overview",
    });
  };

  return (
    <div className="add-admin">
      <div className="add-admin-icon">
        <img src={no_admin} alt="No Admin Icon" />
      </div>
      <div className="add-admin-text">
        No data for any other Admin is available
      </div>
      <div className="add-admin-button">
        <AddAdminButton setstate={setstate} />
      </div>
      <PageButton
        children={"Skip"}
        background={"none"}
        type={"button"}
        width={"167px"}
        marginTop={"60px"}
        onClick={goToOverviewPage}
        testId={"goToOverviewPage"}
      />
    </div>
  );
};

export default NoAdmin;
