import * as Yup from "yup";

const pattern =
  /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,15}$/;

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const alphaNumPattern = /^[A-Za-z\s]*$/;

export const LoginSchema = Yup.object().shape({
  email: Yup.string().email().trim().required("email is required"),
  password: Yup.string().required("password is required"),
});

export const SendEmailSchema = Yup.object().shape({
  email: Yup.string().email().trim().required("email is required"),
});

export const ChangePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("password is required")
    .min(8, "password cannot be less than 8 characters")
    .max(15, "password cannot be more than 15 characters")
    .matches(
      pattern,
      "Must contain upper case,lower case, numeric,and special character"
    ),
  confirm_password: Yup.string()
    .required("please confirm your new password")
    .oneOf([Yup.ref("password"), null], "passwords must match"),
});

export const CreateAdminSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "first name cannot be less than 2 characters")
    .required("first name is required")
    .matches(
      /^[A-Za-z/-]+$/,
      "cannot contain numbers and special characters except hyphen"
    ),
  last_name: Yup.string()
    .min(2, "last name cannot be less than 2 characters")
    .required("last name is required")
    .matches(
      /^[A-Za-z/-]+$/,
      "cannot contain numbers and special characters except hyphen"
    ),
  username: Yup.string()
    .min(2, "username cannot be less than 2 characters")
    .required("username is required"),
  phone_number: Yup.string()
    .min(11, "phone number cannot be less than 11 numbers")
    .max(11, "phone number cannot be more than 11 numbers")
    .matches(phoneRegExp, "phone number is not valid")
    .required("phone number is required"),
  email: Yup.string().email().trim().required("email is required"),
  description: Yup.string()
    .min(2, "description cannot be less than 2 characters")
    .required("description is required"),
});

export const EditAdminSchema = Yup.object().shape({
  email: Yup.string().email().trim().required("email is required"),
  first_name: Yup.string()
    .min(2, "first name cannot be less than 2 characters")
    .required("first name is required")
    .matches(
      /^[A-Za-z/-]+$/,
      "cannot contain numbers and special characters except hyphen"
    ),
  last_name: Yup.string()
    .min(2, "last name cannot be less than 2 characters")
    .required("last name is required")
    .matches(
      /^[A-Za-z/-]+$/,
      "cannot contain numbers and special characters except hyphen"
    ),
  username: Yup.string()
    .min(2, "username cannot be less than 2 characters")
    .required("username is required"),
  phone_number: Yup.string()
    .min(11, "phone number cannot be less than 11 numbers")
    .max(11, "phone number cannot be more than 11 numbers")
    .matches(phoneRegExp, "phone number is not valid")
    .required("phone number is required"),
  description: Yup.string()
    .min(2, "description cannot be less than 2 characters")
    .required("description is required"),
});

export const UpdateProfileSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("first name is required")
    .min(2, "first name cannot be less than 2 characters")
    .matches(
      /^[A-Za-z/-]+$/,
      "cannot contain numbers and special characters except hyphen"
    ),
  last_name: Yup.string()
    .required("last name is required")
    .min(2, "last name cannot be less than 2 characters")
    .matches(
      /^[A-Za-z/-]+$/,
      "cannot contain numbers and special characters except hyphen"
    ),
  phone_number: Yup.string()
    .min(11, "phone number cannot be less than 11 numbers")
    .max(11, "phone number cannot be more than 11 numbers")
    .matches(phoneRegExp, "phone number is not valid")
    .required("phone number is required"),
  address: Yup.string()
    .required("address is required")
    .min(2, "address cannot be less than 2 characters"),
});

export const UpdatePasswordSchema = Yup.object().shape({
  old_password: Yup.string().required("old password is required"),
  new_password: Yup.string()
    .required("new password is required")
    .min(8, "password cannot be less than 8 characters")
    .max(15, "password cannot be more than 15 characters")
    .matches(
      pattern,
      "Must contain upper case,lower case, numeric,and special character"
    ),
  confirm_password: Yup.string()
    .required("please confirm your new password")
    .oneOf([Yup.ref("new_password"), null], "passwords must match"),
});

export const AddFacilitySchema = Yup.object().shape({
  name: Yup.string()
    .required("name is required")
    .min(3, "name cannot be less than 3 characters"),
  email: Yup.string().email().trim().required("email is required"),
  // city: Yup.string()
  //   .required("city is required")
  //   .min(3, "city cannot be less than 3 characters"),
  full_address: Yup.string()
    .required("address is required")
    .min(3, "address cannot be less than 3 characters"),
  closest_landmark: Yup.string()
    .required("landmark is required")
    .min(3, "landmark cannot be less than 3 characters"),
  phone_number: Yup.string()
    .required("phone number is required")
    .min(11, "phone number cannot be less than 11 numbers")
    .max(11, "phone number cannot be more than 11 numbers")
    .matches(phoneRegExp, "phone number is not valid"),
  description: Yup.string()
    .required("description is required")
    .min(5, "description cannot be less than 5 characters"),
  years_of_operation: Yup.string().required("years of operation is required"),
});

export const ScheduleAppointmentSchema = Yup.object().shape({
  email: Yup.string().email().trim().required("client's email is required"),
});

export const FilterFacilitySchema = Yup.object().shape({
  location: Yup.string()
    .required("location is required")
    .min(3, "location cannot be less than 3 characters"),
});

export const AddUserCommonSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "first name cannot be less than 2 characters")
    .matches(
      /^[A-Za-z/-]+$/,
      "cannot contain numbers and special characters except hyphen"
    ),
  last_name: Yup.string()
    .min(2, "last name cannot be less than 2 characters")
    .matches(
      /^[A-Za-z/-]+$/,
      "cannot contain numbers and special characters except hyphen"
    ),
  username: Yup.string().min(2, "username cannot be less than 2 characters"),
  email: Yup.string().email().trim().required("email is required"),
  phone_number: Yup.string()
    .min(11, "phone number cannot be less than 11 numbers")
    .max(11, "phone number cannot be more than 11 numbers")
    .matches(phoneRegExp, "phone number is not valid")
    .required("phone number is required"),
  full_address: Yup.string().min(2, "address cannot be less than 2 characters"),
});

// export const AddClientSchema = Yup.object()
//   .shape({
//     state: Yup.string().required("state is required"),
//   })
//   .concat(AddUserCommonSchema);

export const AddConsultantSchema = Yup.object()
  .shape({
    registration_no: Yup.string()
      .required("reg. number is required")
      .min(4, "reg. number cannot be less than 4 characters"),
    specialization: Yup.string().required("specialization is required"),
    about: Yup.string().required("description is required"),
    years_of_experience: Yup.string().required(
      "years of experience is required"
    ),
  })
  .concat(AddUserCommonSchema);

export const AddWDSchema = Yup.object().shape({}).concat(AddConsultantSchema);

export const AddHfcpSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "first name cannot be less than 2 characters")
    .matches(
      /^[A-Za-z/-]+$/,
      "cannot contain numbers and special characters except hyphen"
    ),
  last_name: Yup.string()
    .min(2, "last name cannot be less than 2 characters")
    .matches(
      /^[A-Za-z/-]+$/,
      "cannot contain numbers and special characters except hyphen"
    ),
  username: Yup.string().min(2, "username cannot be less than 2 characters"),
  email: Yup.string().email().trim().required("email is required"),
  phone_number: Yup.string()
    .min(11, "phone number cannot be less than 11 numbers")
    .max(11, "phone number cannot be more than 11 numbers")
    .matches(phoneRegExp, "phone number is not valid")
    .required("phone number is required"),
  full_address: Yup.string()
    .min(2, "address cannot be less than 2 characters")
    .required("address is required"),
  about: Yup.string().required("description is required"),
  years_of_experience: Yup.string().required("years of experience is required"),
  specialization: Yup.string()
    .matches(/^[A-Za-z]+$/, "cannot contain numbers and special characters")
    .required("specialization is required"),
});

export const ProductSchema = Yup.object().shape({
  product_name: Yup.string()
    .required("product name is required")
    .min(2, "product name cannot be less than 2 characters"),
  product_description: Yup.string()
    .required("description is required")
    .min(3, "description cannot be less than 3 characters"),
  quantity: Yup.string().required("quantity is required"),
  price: Yup.string().required("price is required"),
  moq: Yup.string().required("moq is required"),
  manufacturer: Yup.string().min(
    3,
    "manufacturer name cannot be less than 3 characters"
  ),
  duration: Yup.string().min(2, "duration cannot be less than 2 characters"),
  size: Yup.string().min(2, "size cannot be less than 2 characters"),
  effectiveness: Yup.string().min(
    2,
    "effectiveness cannot be less than 2 characters"
  ),
  color: Yup.string().min(2, "color cannot be less than 2 characters"),
  discount: Yup.string().min(2, "discount cannot be less than 2 characters"),
});

export const ProductCategorySchema = Yup.object().shape({
  name: Yup.string()
    .required("category name is required")
    .min(2, "category name cannot be less than 2 characters"),
  description: Yup.string()
    .required("description is required")
    .min(3, "description cannot be less than 3 characters"),
});

export const PaymentPlanSchema = Yup.object().shape({
  name: Yup.string()
    .required("category name is required")
    .min(3, "category name cannot be less than 3 characters"),
  amount: Yup.string().required("amount is required"),
  caption: Yup.string()
    .required("caption is required")
    .min(5, "caption cannot be less than 5 characters")
    .max(100, "caption cannot be more than 100 characters"),
  tag: Yup.string().min(3, "tag cannot be less than 3 characters"),
});

export const CreatePostSchema = Yup.object().shape({
  title: Yup.string()
    .required("blog title is required")
    .min(3, "blog title cannot be less than 3 characters"),
  author: Yup.string()
    .required("author name is required")
    .min(3, "author name cannot be less than 3 characters")
    .matches(alphaNumPattern, "cannot contain numbers and special characters"),
  caption: Yup.string()
    .required("caption is required")
    .min(2, "caption cannot be less than 5 characters")
    .max(100, "caption cannot be more than 100 characters"),
});

export const AddRoleSchema = Yup.object().shape({
  name: Yup.string()
    .required("role name is required")
    .min(3, "role name cannot be less than 3 characters")
    .matches(alphaNumPattern, "cannot contain numbers and special characters"),
  description: Yup.string()
    .required("description is required")
    .min(3, "description cannot be less than 3 characters")
    .matches(alphaNumPattern, "cannot contain numbers and special characters"),
});

export const CouponSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(3, "Name cannot be less than 3 characters"),
  amount: Yup.string().required("amount is required"),
});

export const AppVersionSchema = Yup.object().shape({
  hfcp: Yup.string().required(),
  whispa_consultant: Yup.string().required(),
  whispa_doctor: Yup.string().required(),
  whispa_patient: Yup.string().required(),
});

export const CreateOrderSchema = Yup.object().shape({
  city: Yup.string().required("City is required"),
  full_address: Yup.string().required("Address is required"),
});

// export const create

export const EditCouponSchema = Yup.object().shape({
  code: Yup.string()
    .required("Code is required")
    .min(3, "Code cannot be less than 3 characters"),
  amount: Yup.string().required("amount is required"),
});

export const ReferralSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(3, "Name cannot be less than 3 characters"),
  sender_reward: Yup.string().required("Sender's reward is required"),
  receiver_reward: Yup.string().required("Receiver's reward is required"),
  duration: Yup.string().required("Duration is required"),
});

export const SubscriptionSchema = Yup.object().shape({
  transaction_reference: Yup.string().required(
    "Transaction Reference is Required"
  ),
  amount: Yup.number().required("Amount is Required"),
  email: Yup.string().required("Email is required").email()
});
export const BlogCategorySchema = Yup.object().shape({
  name: Yup.string()
    .required("category name is required")
    .min(2, "category name cannot be less than 2 characters")
    .matches(alphaNumPattern, "cannot contain numbers and special characters"),
});

export const CategorySchema = Yup.object().shape({
  category_name: Yup.string()
    .required("category name is required")
    .min(3, "category name cannot be less than 3 characters")
    .matches(alphaNumPattern, "cannot contain numbers and special characters"),
  description: Yup.string()
    .required("description is required")
    .min(3, "description cannot be less than 3 characters"),
});

export const DeliveryFeeSchema = Yup.object().shape({
  state: Yup.string().required("state is required"),
  fees: Yup.number()
    .positive()
    .required("fees is required")
    .min(1, "fees mus tbe greater than 0"),
});
