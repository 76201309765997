import React from 'react'

const Backdrop = () => {
  return (
    <div 
    className='backdrop'
    ></div>
  )
}

export default Backdrop