import PropTypes from "prop-types";
import React, { useRef, useEffect } from "react";
import Empty from "../../assets/empty.svg";
import Skeleton from "react-loading-skeleton";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import dotsLogo from "../../assets/dots.svg";

export const TableRow = (props) => {
  const { row, moveRow, index, isDraggable, isDraggingRow } = props;
  const dropRef = useRef(null); 
  const dragRef = useRef(null);

  const DND_ITEM_TYPE = "row";

  const [{ isDragging }, drag, preview] = useDrag({
    item: { index },
    type: DND_ITEM_TYPE,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(
    () => {
      isDraggable && isDraggingRow(isDragging);
    },
    // eslint-disable-next-line
    [isDragging]
  );

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const style = isDragging
    ? {
        border: "3px solid #81C2F7",
        transform: "scale(1.016)",
      }
    : {};

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <tr
      className="draggable-container"
      style={style}
      ref={dropRef}
      {...row.getRowProps()}
    >
      {row.cells.map((cell) => {
        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>; 
      })}
      {isDraggable && (
        <div
          title="Click and hold, then move row"
          className="draggable-btn"
          ref={dragRef}
        >
          <div className="draggable-icon">
            <img src={dotsLogo} alt="dotslogo" width="100%" height="100%" />
          </div>
        </div>
      )}
    </tr>
  );
};

TableRow.defaultProps = {
  isDraggable: false,
};

TableRow.propTypes = {
  row: PropTypes.shape({
    cells: PropTypes.any,
    getRowProps: PropTypes.func,
  }),
  isDraggable: PropTypes.bool,
};

export const Body = (props) => {
  const {
    getTableProps,
    getTableBodyProps, 
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    emptyText1,
    emptyText2,
    className,
    isLoading,
    sortSuccess,
    success,
    emptyResultText,
    auth,
    border,
    showFooter,
    isDraggable,
    data,
    reArrangedData,
    moveRow,
    isDraggingRow,
  } = props;

  // const moveRow = (dragIndex, hoverIndex) => {
  //   let updatedData = [...data];
  //   console.log(updatedData)
  //   const selectedItem = updatedData[dragIndex];
  //   updatedData.splice(dragIndex, 1);
  //   updatedData.splice(hoverIndex, 0, selectedItem);
  //   reArrangedData(updatedData);
  // };


  return (
    <div
      className={auth ? "" : "table-wrapper"}
      style={{ border: border ? border : "" }}
    >
      <DndProvider backend={HTML5Backend}>
        <table
          {...getTableProps()}
          className={auth ? `auth-table ${className}` : `table ${className}`}
        >
          <thead>
            {headerGroups &&
              headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
          </thead>
          {isLoading ? (
            <div style={{ padding: "20px" }}>
              <Skeleton height={"50px"} width={"100%"} count={10} />
            </div>
          ) : success && page && page.length > 0 ? (
            <>
              <tbody {...getTableBodyProps()}>
                {page &&
                  page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <TableRow
                        isDraggable={isDraggable}
                        index={i}
                        moveRow={moveRow}
                        row={row}
                        key={i}
                        isDraggingRow={(value) => isDraggingRow(value)}
                      />
                    );
                  })}
              </tbody>
              {showFooter && (
                <tfoot>
                  {footerGroups.map((group) => (
                    <tr {...group.getFooterGroupProps()}>
                      {group.headers.map((column) => (
                        <td {...column.getFooterProps()}>
                          {column.render("Footer")}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tfoot>
              )}
            </>
          ) : (
            <div className="table-empty">
              <div className="table-empty-container">
                <div className="table-empty-container-image">
                  <img src={Empty} alt="empty-table" />
                </div>
                {sortSuccess ? (
                  <div>
                    <h2>{emptyResultText}</h2>
                  </div>
                ) : (
                  <div>
                    <h2>{emptyText1}</h2>
                    <h3>{emptyText2}</h3>
                  </div>
                )}
              </div>
            </div>
          )}
        </table>
      </DndProvider>
    </div>
  );
};

Body.propTypes = {
  auth: PropTypes.any,
  border: PropTypes.any,
  className: PropTypes.string,
  emptyResultText: PropTypes.any,
  emptyText1: PropTypes.any,
  emptyText2: PropTypes.any,
  getTableBodyProps: PropTypes.func,
  getTableProps: PropTypes.func,
  headerGroups: PropTypes.array,
  isLoading: PropTypes.any,
  page: PropTypes.oneOfType([PropTypes.any]),
  prepareRow: PropTypes.func,
  sortSuccess: PropTypes.any,
  success: PropTypes.any,
  testRowClick: PropTypes.any,
};