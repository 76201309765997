import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { editAdmin } from "../../redux/Actions/AdminActions";
import { connect } from "react-redux";
import { EditAdminSchema } from "../../utils/validation/validation";
// import AdminInputs from "./AdminInputs";
import {
  filterActiveRoles,
  getAdminPermissions,
  permissionPayload,
} from "../../utils/functions/CreateNewArray";
import { getRoles } from "@testing-library/react";
import Modal from "../Modal/Modal";
import AdminInputs from "../Onboarding/AdminInputs";
import { filterRoles } from "../../utils/functions/FilterObject";
import { getUserData } from "../../utils/functions/GetStorageItems";
import { getAllEnterprises } from "../../redux/Actions/EnterpriseActions";

const EditAdmin = (props) => {
  const {
    data,
    editAdmin,
    isEditing,
    isGetting,
    adminRoles,
    getRolesSuccess,
    closeModal,
    displayModal,
    editAdminSuccess,
    enterpriseData,
    getEnterprises,
  } = props;

  const id = data && data.id;
  const rolesData =
    adminRoles && adminRoles.roles && filterActiveRoles(adminRoles.roles);

  console.log("adminRoles", adminRoles);

  const { roleIndex, roleName } = filterRoles(data.role, rolesData);

  const [roles, setRoles] = useState(roleIndex);
  const [rolesName, setRolesName] = useState(roleName);
  const [adminData, setAdminData] = useState(data);
  const [enterpriseId, setEnterpriseId] = useState( data && data.enterprises_id);

  const permissions = getAdminPermissions(adminData);

  const [checkedItems, setCheckedItems] = useState(permissions);

  const permissionsPayload = checkedItems && permissionPayload(checkedItems);

  const { user } = getUserData();

  useEffect(
    () => {
      if (getRolesSuccess) {
        setCheckedItems(permissions);
      }
    },
    // eslint-disable-next-line
    [getRolesSuccess, roles]
  );

  useEffect(() => {
    if (data) {
      setEnterpriseId(data && data.enterprises_id)
    } 
  }, [data])

  const handleSelectRole = (index, data) => {
    setAdminData(rolesData && rolesData[index]);
    setRoles(index);
    setRolesName(data);
  };

  const initialValues = {
    first_name: data.first_name,
    last_name: data.last_name,
    username: data.username,
    phone_number: data.phone_number,
    email: data.email,
    description: data.description,
  };

  const submitValues = (values) => {
    const payload = {
      id: id,
      first_name: values.first_name,
      last_name: values.last_name,
      username: values.username,
      phone_number: values.phone_number,
      email: values.email,
      description: values.description,
      role: rolesName,
      enterprises_id: enterpriseId,
      ...permissionsPayload,
    };
    const adminDetails = {
      ...user,
      ...payload,
    };
    editAdmin(payload, adminDetails);
  };

  useEffect(
    () => {
      if (editAdminSuccess) {
        closeModal();
      }
    },
    // eslint-disable-next-line
    [editAdminSuccess]
  );

  useEffect(() => {
    getEnterprises(1, 10)
  }, [])

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Edit Admin"}
      width={"568px"}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => submitValues(values)}
        validationSchema={EditAdminSchema}
      >
        {({ isValid, dirty, setFieldValue }) => {
          return (
            <AdminInputs
              enterpriseData={enterpriseData}
              formId={"editAdmin"}
              text={"Save"}
              loadingText={"Saving..."}
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
              isGetting={isGetting}
              getRolesSuccess={getRolesSuccess}
              rolesData={rolesData}
              roles={roles}
              rolesName={rolesName}
              handleSelectRole={handleSelectRole}
              isCreating={isEditing}
              disabled={isEditing || !rolesName || rolesName === ""}
              setFieldValue={setFieldValue}
              readonly={true}
              setEnterpriseId={setEnterpriseId}
              enterpriseId={enterpriseId}
            />
          );
        }}
      </Formik>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  editAdmin: (payload, adminDetails) =>
    dispatch(editAdmin(payload, adminDetails)),
  getAdminRoles: () => dispatch(getRoles()),
  getEnterprises: (page, limit) => dispatch(getAllEnterprises(page, limit)),
});

const mapStateToProps = (state) => ({
  isEditing: state.admin.isEditing,
  editAdminSuccess: state.admin.editAdminSuccess,
  adminRoles: state.admin.roles,
  getRolesSuccess: state.admin.getRolesSuccess,
  isGetting: state.admin.isGetting,
  enterpriseData: state.enterprise.enterpriseData,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAdmin);
