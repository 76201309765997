import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getConsultantChatHistory,
  searchConsultantChatHistory,
} from "../../../redux/Actions/UsersActions";
import { PageButton } from "../../Button/Button";
import AllChats from "../../ChatManagement/AllChats";
import ChatList from "../../ChatManagement/ChatList";
import SingleChat from "../../ChatManagement/SingleChat";
import { cancelModal, viewModal } from "../../Modal/Modal";
import ConsultantUserChats from "./ConsultantUserChat";
import RerouteConsultant from "./RerouteConsultant";

const ConsultantChats = (props) => {
  const {
    getUserChatHistory,
    searchUserChatHistory,
    isGetting,
    userChat,
    getSuccess,
    isSearching,
    searchedUserChat,
    searchSuccess,
  } = props;

  const [state, setState] = useState({
    searchValue: "",
    active1: true,
    active2: false,
    chatId: "",
    chat: "",
    reRouteModal: false,
    newDoctor: "",
  });

  const id = props?.location?.state;

  const openModal = (type) => viewModal(type, state, setState);
  const closeModal = (type) => cancelModal(type, state, setState);

  const showSingleChat = (chat) => {
    setState({
      ...state,
      chatId: chat && chat.id,
      chat: chat,
    });
  };

  const searchAllChats = () => {
    if (state.searchValue === "") {
      return userChat;
    } else {
      searchUserChatHistory(id, state.searchValue);
    }
  };

  useEffect(
    () => {
      if (getSuccess) {
        setState({
          ...state,
          searchValue: "",
        });
      }
    },
    // eslint-disable-next-line
    [getSuccess]
  );

  const allChats = searchSuccess ? searchedUserChat : userChat;

  return (
    <AllChats
      leftComponent={
        <ChatList
          subtitle={"View all new chats with Whispa Consultants"}
          back={true}
          setState={setState}
          state={state}
          searchItems={searchAllChats}
          sortSuccess={searchSuccess}
          refreshFunction={() => getUserChatHistory(id)}
          chatComponent={
            <ConsultantUserChats
              isLoading={isGetting || isSearching}
              data={allChats}
              setState={setState}
              state={state}
              showSingleChat={showSingleChat}
              getChats={getUserChatHistory}
              success={getSuccess || searchSuccess}
              sortSuccess={searchSuccess}
              status={"consultant"}
              id={id}
              openModal={openModal}
            />
          }
        />
      }
      rightComponent={
        <>
          <SingleChat
            chat={state.chat}
            className={"chats-right-web"}
            component={
              <PageButton
                width={"156px"}
                background={"rgba(10, 116, 220, 0.08)"}
                marginTop={"0px"}
                onClick={() => openModal("reRouteModal")}
                type={"submit"}
                children={"Transfer Client"}
              />
            }
          />

          <RerouteConsultant
            displayModal={state.reRouteModal}
            closeModal={() => closeModal("reRouteModal")}
            setState={setState}
            state={state}
            id={state.chatId}
            getUserChatHistory={getUserChatHistory}
          />
        </>
      }
    ></AllChats>
  );
};

const mapStateToProps = (state) => ({
  isGetting: state.users.isGettingUserChat,
  userChat: state.users.userChat,
  getSuccess: state.users.getSuccessUserChat,
  isSearching: state.users.isSearchingUserChat,
  searchedUserChat: state.users.searchedUserChat,
  searchSuccess: state.users.searchSuccessUserChat,
});

const mapDispatchToProps = (dispatch) => ({
  getUserChatHistory: (id) => dispatch(getConsultantChatHistory(id)),
  searchUserChatHistory: (id, search) =>
    dispatch(searchConsultantChatHistory(id, search)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantChats);
