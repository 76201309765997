import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";

// GET PRODUCT CATEGORIES
export const getProductCategoriesStart = () => ({
  type: Actions.GET_PRODUCT_CATEGORIES_START,
});

export const getProductCategoriesSuccess = (payload) => ({
  type: Actions.GET_PRODUCT_CATEGORIES_SUCCESS,
  payload,
});

export const getProductCategoriesFailure = (payload) => ({
  type: Actions.GET_PRODUCT_CATEGORIES_FAILURE,
  payload,
});

// CREATE NEW PRODUCT CATEGORY
export const createProductCategoryStart = () => ({
  type: Actions.CREATE_PRODUCT_CATEGORY_START,
});

export const createProductCategorySuccess = (payload) => ({
  type: Actions.CREATE_PRODUCT_CATEGORY_SUCCESS,
  payload,
});

export const createProductCategoryFailure = (payload) => ({
  type: Actions.CREATE_PRODUCT_CATEGORY_FAILURE,
  payload,
});

// EDIT PRODUCT CATEGORY
export const editProductCategoryStart = () => ({
  type: Actions.EDIT_PRODUCT_CATEGORY_START,
});

export const editProductCategorySuccess = (payload) => ({
  type: Actions.EDIT_PRODUCT_CATEGORY_SUCCESS,
  payload,
});

export const editProductCategoryFailure = (payload) => ({
  type: Actions.EDIT_PRODUCT_CATEGORY_FAILURE,
  payload,
});

// DELETE PRODUCT CATEGORY
export const deleteProductCategoryStart = () => ({
  type: Actions.DELETE_PRODUCT_CATEGORY_START,
});

export const deleteProductCategorySuccess = (payload) => ({
  type: Actions.DELETE_PRODUCT_CATEGORY_SUCCESS,
  payload,
});

export const deleteProductCategoryFailure = (payload) => ({
  type: Actions.DELETE_PRODUCT_CATEGORY_FAILURE,
  payload,
});

// SEARCH PRODUCT CATEGORIES
export const searchProductCategoriesStart = () => ({
  type: Actions.SEARCH_PRODUCT_CATEGORIES_START,
});

export const searchProductCategoriesSuccess = (payload) => ({
  type: Actions.SEARCH_PRODUCT_CATEGORIES_SUCCESS,
  payload,
});

export const searchProductCategoriesFailure = (payload) => ({
  type: Actions.SEARCH_PRODUCT_CATEGORIES_FAILURE,
  payload,
});

export const getProductCategories = () => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getProductCategoriesStart());
  API.get("/product-category").then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getProductCategoriesSuccess(data && data.data));
    } else {
      dispatch(getProductCategoriesFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const createProductCategory = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(createProductCategoryStart());
  API.post("/product-category", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(createProductCategorySuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(createProductCategoryFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const editProductCategory = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(editProductCategoryStart());
  API.put(`/product-category`, payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(editProductCategorySuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(editProductCategoryFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const deleteProductCategory = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(deleteProductCategoryStart());
  API.delete(`/product-category/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(deleteProductCategorySuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(deleteProductCategoryFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const searchProductCategories = (searchValue) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(searchProductCategoriesStart());
  API.get(`/product-category?search=${searchValue}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(searchProductCategoriesSuccess(data && data.data));
    } else {
      dispatch(searchProductCategoriesFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};
