import React, { useState } from "react";
import TableDropdown from "../Tables/Dropdown";
import Option from "../../assets/vertical-option.svg";
import ResheduleAppointment from "../Appointments/ResheduleAppointment";
import DeleteAppointment from "../Appointments/DeleteAppointment";
import DeclineAppointment from "../Appointments/DeclineAppointment";
import moment from "moment";
import Icon from "../../assets/account_icon.svg";
import CompleteAppointment from "../Appointments/CompleteAppointment";
import ConfirmAppointment from "../Appointments/ConfirmAppointment";

const UserGrid = ({ item, index, icon, state, setState, overview, limit }) => {
  const [show, setShow] = useState(false);

  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);

  const showDropdown = (id) => {
    setState({ ...state, id });
    setShow((prevCheck) => !prevCheck);
  };

  const options = [
    {
      name: "Reshedule",
      action: () => setShowRescheduleModal(true),
    },
    {
      name: "Decline",
      action: () => setShowDeclineModal(true),
    },
    {
      name: "Confirm",
      action: () => setShowConfirmModal(true),
    },
    {
      name: "Complete",
      action: () => setShowCompleteModal(true),
    },
    {
      name: "Delete",
      action: () => setShowDeleteModal(true),
    },
  ];

  const statusStyle = () => {
    const style = { textTransform: "capitalize" };
    const status = item.status?.toLowerCase();
    if (status === "completed") {
      return { ...style, color: "#00DCB4" };
    }

    return { ...style, color: status === "declined" ? "#F93D55" : "#0A74DC" };
  };

  const statusOptions = () => {
    if (
      item.status?.toLowerCase() === "declined" ||
      item.status?.toLowerCase() === "completed"
    )
      return [options[3]];
    return options;
  };
  const time = new Date(item?.scheduled_time).toISOString();

  return (
    <div className="appointment-grid-section" key={index}>
      <div
        className={
          overview
            ? "appointment-grid-section2-top"
            : "appointment-grid-section-top"
        }
      >
        <div className="header">
          <div className="header-image">
            <img
              src={item.user?.image_url ? item.user?.image_url : Icon}
              alt="user"
            />
          </div>
          <div className="">
            <h2 className="text-capitalize">{item.user?.email}</h2>
            <h3 className="">{item.user?.username}</h3>
          </div>
        </div>
        <div className="body">
          <div className="body-item">
            <p>Phone Number</p>
            <h3 className="text-capitalize">
              {item.user?.phone_number ?? "N/A"}
            </h3>
          </div>

          <div className="body-item">
            <p>With</p>
            <h3 className="text-capitalize">{item.facility?.name ?? "N/A"}</h3>
          </div>
          <div className="body-item">
            <p>Service</p>
            <h3>{item.appointment_sub_category?.name ?? "N/A"}</h3>
          </div>
          <div className="body-item">
            <p>Date</p>
            <h3>{moment(item.scheduled_date).format("ll")}</h3>
          </div>
          <div className="body-item">
            <p>Time</p>
            <h3>{moment(time).format("hh:mm a")}</h3>
          </div>
          <div className="body-item">
            <p>Age Group</p>
            <h3>{item.user?.age_group ?? "N/A"}</h3>
          </div>
          <div className="body-item">
            <p>Gender</p>
            <h3>{item.user?.gender ?? "N/A"}</h3>
          </div>
          <div className="body-item">
            <p>Comment</p>
            <h3>{item?.extra_comment ?? "N/A"}</h3>
          </div>
        </div>
      </div>
      <div
        className={
          overview
            ? "appointment-grid-section2-bottom"
            : "appointment-grid-section-bottom"
        }
      >
        <div
          className={
            overview
              ? "appointment-grid-section2-bottom-item"
              : "appointment-grid-section-bottom-item"
          }
        >
          <span style={statusStyle()}>{item.status?.toLowerCase()}</span>

          {icon && item.status?.toLowerCase() !== "completed" ? (
            <>
              <div
                className="image"
                onClick={() => showDropdown(index)}
                data-testid="showDropdown"
              >
                <img src={Option} alt="option" />
                {show && index === state.id && (
                  <TableDropdown
                    active={show}
                    hideDropdown={() => setShow(false)}
                    options={statusOptions()}
                  />
                )}
              </div>

              {showRescheduleModal && index === state.id && (
                <ResheduleAppointment
                  displayModal={showRescheduleModal}
                  closeModal={() => setShowRescheduleModal(false)}
                  data={item}
                  limit={limit}
                />
              )}

              {showDeleteModal && index === state.id && (
                <DeleteAppointment
                  displayModal={showDeleteModal}
                  closeModal={() => setShowDeleteModal(false)}
                  data={item}
                  limit={limit}
                />
              )}

              {showDeclineModal && index === state.id && (
                <DeclineAppointment
                  displayModal={showDeclineModal}
                  closeModal={() => setShowDeclineModal(false)}
                  data={item}
                  limit={limit}
                />
              )}

              {showConfirmModal && index === state.id && (
                <ConfirmAppointment
                  displayModal={showConfirmModal}
                  closeModal={() => setShowConfirmModal(false)}
                  data={item}
                  limit={limit}
                />
              )}

              {showCompleteModal && index === state.id && (
                <CompleteAppointment
                  displayModal={showCompleteModal}
                  closeModal={() => setShowCompleteModal(false)}
                  data={item}
                  limit={limit}
                />
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default UserGrid;
