import Actions from "../Types";

const initialState = {
  subscriptiondata: "",
  getSubscriptionsSuccess: false,
  isGetting: false,
  isEditting: false,
  editSubscriptionSuccess: false,
  isCreating: false,
  createSubscriptionSuccess: false,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptiondata: action.payload,
        getSubscriptionsSuccess: true,
        isGetting: false,
        editSubscriptionSuccess: false
      };
    case Actions.GET_SUBSCRIPTIONS_START:
      return {
        ...state,
        isGetting: true,
        createSubscriptionSuccess: false,
      };
    case Actions.CREATE_SUBSCRIPTIONS_START:
      return {
        ...state,
        isCreating: true,
        createSubscriptionSuccess: false,
      };
    case Actions.CREATE_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        isGetting: false,
        getSubscriptionsSuccess: false,
        createSubscriptionSuccess: true,
        isCreating: false
      };
    case Actions.EDIT_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        isEditting: false,
        editSubscriptionSuccess: true,
      };
    case Actions.EDIT_SUBSCRIPTIONS_START:
      return {
        ...state,
        isEditting: true,
        editSubscriptionSuccess: false,
      };
    case Actions.EDIT_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        isEditting: false,
      };
    case Actions.CREATE_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        isCreating: false,
      };

    default:
      return state;
  }
};

export default reduxState;
