import Actions from '../Types';
import Instance from '../../utils/services/api';
import responseHandler from '../../utils/notifications/responseHandler';
import Storage from '../../utils/services/storage';

// GET OVERVIEW
export const getOverviewStart = () => ({
  type: Actions.GET_OVERVIEW_START,
});

export const getOverviewSuccess = (payload) => ({
  type: Actions.GET_OVERVIEW_SUCCESS,
  payload,
});

export const getOverviewFailure = () => ({
  type: Actions.GET_OVERVIEW_FAILURE,
});

export const getOverview = (payload) => async (dispatch) => { 
  const API = Instance.customAxiosInstance(Storage.get('admin-access-token'));
  const { type, ...queries } = payload;
  let url1 = '/dashboard?';
  let url2 = `/dashboard/graph/${type}?`;

  console.log('type', type)
  console.log('payload', payload)

  Object.entries(queries).forEach(([key, value]) => {
    url1 += `${key}=${value}&`;
  });


  Object.entries(queries).forEach(([key, value]) => {
    url2 += `${key}=${value}&`;
  });

  const requests = [API.get(url1), API.get(url2)];
  dispatch(getOverviewStart());

  try {
    const responses = await Promise.all(requests);
    if (responses.every((response) => response.ok)) {
      const [response1, response2] = responses;
      const data = { ...response1.data.data, ...response2.data.data };
      const { revenue_graph, sales_graph } = data;
      if (!revenue_graph.year.length && !sales_graph.year.length) {
        responseHandler(
          { problem: 'CLIENT_ERROR' },
          'No result found for this season'
        );
      }
      dispatch(getOverviewSuccess(data));
      return;
    }
  } catch (error) {
    dispatch(getOverviewFailure());
    responseHandler({ problem: 'CLIENT_ERROR' }, error.response.data.message);
  }
};