import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import DateFilter from "../../Input/DateFilter";
import Input from "../../Input/Input";
import SelectInput from "../../Input/Select";
import { ModalButton } from "../../Modal/Modal";
import { Statuses } from "../../Input/Statuses";
import ProductImage from "./ProductImage";
import { assignCategoryOptions } from "../../../utils/functions/UpdateValues";
import { LoaderSpin } from "../../Loader/Loader";
import { parseISO } from "date-fns";
import moment from "moment";
import {
  assignProductImagesPayload,
  filterObj,
  filterProductImages,
} from "../../../utils/functions/FilterObject";

const ProductSummary = (props) => {
  const {
    disabled,
    getProductSuccess,
    productCategories,
    isEditing,
    isGetting,
    getSuccess,
    product,
    getProductCategory,
    getSingleProduct,
    editProduct,
    id,
  } = props;

  const [categoryOptions, setCategoryOptions] = useState([]);

  useEffect(
    () => {
      getSingleProduct(id);
      getProductCategory();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (getProductSuccess) {
        const updatedCategory = assignCategoryOptions(productCategories);
        setCategoryOptions(updatedCategory);
      }
    },
    // eslint-disable-next-line
    [getProductSuccess]
  );

  const [state, setState] = useState({
    date: product && product.expiry_date,
    category: product && product.category_id,
    addedDate: product && product.createdAt,
    product_images: product.image_url,
    active: true,
    index:
      product && product.product_status === "In Stock"
        ? 0
        : product && product.product_status === "Low Stock"
        ? 1
        : 2,
    status: product && product.product_status,
    error: "",
    loaded: false,
  });

  const [quantityState, setQuantityState] = useState({
    active: true,
    status: product && product.quantity_type,
    index: product && product.quantity_type === "Single" ? 0 : 1,
  });

  const [deliveryState, setDeliveryState] = useState({
    status: product && product.delivery_available === true ? "Yes" : "No",
    index: product && product.delivery_available === true ? 0 : 1,
    active: true,
  });

  useEffect(
    () => {
      if (getSuccess) {
        setState({
          ...state,
          date: product && product.expiry_date,
          category: product && product.category_id,
          addedDate: product && product.createdAt,
          active: true,
          index:
            product && product.product_status === "In Stock"
              ? 0
              : product && product.product_status === "Low Stock"
              ? 1
              : 2,
          status: product && product.product_status,
          product_images:
            product && product.images && filterProductImages(product.images),
        });

        setQuantityState({
          ...state,
          active: true,
          status: product && product.quantity_type,
          index: product && product.quantity_type === "Single" ? 0 : 1,
        });

        setDeliveryState({
          ...state,
          status: product && product.delivery_available === true ? "Yes" : "No",
          index: product && product.delivery_available === true ? 0 : 1,
          active: true,
        });
      }
    },
    // eslint-disable-next-line
    [getSuccess]
  );

  const updateUrl = (uploadId, image_url, payload, index) => {
    let product_images = [...state.product_images];
    if (index !== null) {
      product_images[index] = { ...product_images[index], image_url };
    } else {
      product_images.push({ image_url, loaded: true });
    }
    setState((state) => ({ ...state, product_images }));
  };

  const initialValues = {
    product_name: product && product.name,
    price: product && product.price && Number(product.price),
    manufacturer: product && product.manufacturer,
    discount: product && product.discount,
    moq: product && product.minimum_order_quantity,
    product_description: product && product.summary,
    quantity: product && product.quantity,
    size: product && product.size,
    effectiveness: product && product.effectiveness,
    color: product && product.color,
    rank: product && product.rank,
    duration: product && product.duration,
  };

  const updateSelection = (type, data) => {
    setState({
      ...state,
      [type]: data.value,
    });
  };

  const quantity = [{ name: "Single" }, { name: "Bulk" }];
  const delivery = [{ name: "Yes" }, { name: "No" }];

  const status = [
    { name: "In Stock" },
    { name: "Low Stock" },
    { name: "Out of Stock" },
  ];

  const categoryName = product && product.category && product.category.name;

  const submitValues = (values) => {
    const payload = {
      name: values.product_name,
      price: values.price && values.price.toString(),
      minimum_order_quantity: values.moq,
      manufacturer: values.manufacturer,
      discount: values.discount,
      duration: values.duration,
      category_id: state.category,
      quantity: values.quantity,
      expiry_date: state.date,
      delivery_available: deliveryState.status === "Yes" ? true : false,
      effectiveness: values.effectiveness,
      product_status: state.status,
      summary: values.product_description,
      color: values.color,
      size: values.size,
      rank: values.rank,
      quantity_type: quantityState.status,
      images:
        state &&
        state.product_images &&
        assignProductImagesPayload(state.product_images),
    };
    submitPayload(payload);
  };

  const submitPayload = (data) => {
    const payload = data && filterObj(data);
    editProduct(id, payload);
  };

  return (
    <div className="product-body">
      {isGetting ? (
        <LoaderSpin width="40px" height="40px" />
      ) : getSuccess ? (
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => submitValues(values)}
        >
          {({ isValid, dirty, setFieldValue }) => {
            const disabled2 = isEditing;
            return (
              <Form id="form" className="product-summary">
                <div className="product-summary-left">
                  <ProductImage
                    images={state.product_images}
                    updateUrl={updateUrl}
                  />
                  <div className="divider"></div>

                  <div className="product-summary-left-inputs">
                    <Field
                      name="product_name"
                      label="Product Name"
                      placeholder="Enter product name"
                      component={Input}
                      readOnly={disabled}
                    />

                    <SelectInput
                      label="Category"
                      options={categoryOptions}
                      onChange={(data) => updateSelection("category", data)}
                      defaultOption={categoryName}
                      readOnly={disabled}
                    />

                    <Field
                      name="price"
                      label="Price"
                      type="number"
                      placeholder="NGN 0.00"
                      component={Input}
                      readOnly={disabled}
                    />

                    <Field
                      name="quantity"
                      label="Stock Quantity"
                      placeholder="Enter stock quantity"
                      component={Input}
                      readOnly={disabled}
                    />

                    <Field
                      name="moq"
                      label="Minimum Order Quantity"
                      type="number"
                      placeholder="Enter MOQ"
                      component={Input}
                      emptyValue={setFieldValue}
                      readOnly={disabled}
                    />

                    <DateFilter
                      label={"Date Added"}
                      placeholder={"Select date"}
                      state={state}
                      name={"addedDate"}
                      setState={setState}
                      readOnly={true}
                      previousDate={
                        product.createdAt && parseISO(product.createdAt)
                      }
                    />

                    <DateFilter
                      label={"Expiry Date"}
                      placeholder={"Select date"}
                      state={state}
                      name={"date"}
                      setState={setState}
                      readOnly={disabled}
                      previousDate={
                        product.expiry_date && parseISO(product.expiry_date)
                      }
                      minDate={moment().toDate()}
                    />

                    <Field
                      name="size"
                      label="Size"
                      placeholder="Enter size"
                      component={Input}
                      emptyValue={setFieldValue}
                      readOnly={disabled}
                    />

                    <Field
                      name="effectiveness"
                      label="Effectiveness"
                      placeholder="Enter effectiveness"
                      component={Input}
                      emptyValue={setFieldValue}
                      readOnly={disabled}
                    />

                    <Field
                      name="color"
                      label="Color"
                      placeholder="Enter color"
                      component={Input}
                      emptyValue={setFieldValue}
                      readOnly={disabled}
                    />

                    <Field
                      name="duration"
                      label="Duration"
                      placeholder="Enter duration eg 20 days"
                      component={Input}
                      emptyValue={setFieldValue}
                      readOnly={disabled}
                    />
                    <Field
                      name="rank"
                      label="Rank"
                      placeholder="Update Rank"
                      component={Input}
                      emptyValue={setFieldValue}
                      readOnly={disabled}
                    />
                  </div>
                </div>
                <div className="product-summary-right">
                  <Field
                    name="product_description"
                    label="Product Description"
                    placeholder="Enter description"
                    component={Input}
                    emptyValue={setFieldValue}
                    input2={true}
                    readOnly={disabled}
                  />

                  <div className="form-grid">
                    <Field
                      name="discount"
                      label="Discount"
                      placeholder="Enter Discount rate"
                      component={Input}
                      input2={true}
                      readOnly={disabled}
                    />
                  </div>

                  <div className="form-grid">
                    <Field
                      name="manufacturer"
                      label="Manufacturer"
                      placeholder="Enter manufacturer name"
                      component={Input}
                      input2={true}
                      readOnly={disabled}
                    />
                  </div>

                  <div style={{ marginTop: "45px" }}>
                    <Statuses
                      label={"Status"}
                      state={state}
                      options={status}
                      setstate={setState}
                      blue={true}
                      numberPerRow={2}
                    />
                  </div>

                  <div style={{ marginTop: "40px" }}>
                    <Statuses
                      label={"Quantity type"}
                      state={quantityState}
                      options={quantity}
                      setstate={setQuantityState}
                      blue={true}
                    />
                  </div>

                  <div cstyle={{ marginTop: "40px" }}>
                    <Statuses
                      label={"Delivery available"}
                      state={deliveryState}
                      options={delivery}
                      setstate={setDeliveryState}
                      blue={false}
                    />
                  </div>
                </div>

                <ModalButton
                  isLoading={isEditing}
                  text={"Save Changes"}
                  type={"submit"}
                  testId={"button"}
                  loadingText={"Saving..."}
                  disabled={disabled || disabled2}
                />
              </Form>
            );
          }}
        </Formik>
      ) : (
        <div>Error fetching single product details</div>
      )}
    </div>
  );
};

export default ProductSummary;
