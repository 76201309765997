import LinkRoutes from "../../components/Sidebar/LinkRoutes";
import Storage from "../services/storage";
import { assignRoute, getAdminPermissions } from "./CreateNewArray";

export function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);  
}

export function getUserData() {  
  let userData = Storage.get("admin-data");
  const user = userData && JSON.parse(userData);
  const first_name = user && user.first_name && capitalizeFirstLetter(user.first_name);
  const last_name = user && user.last_name && capitalizeFirstLetter(user.last_name);
  const email = user && user.email;
  const exp = user && user.exp;
  const iat = user && user.iat;
  const role = user && user.role;
  const address = user && user.address;
  const username = user && user.username;
  const isFirstTimeLogin = user && user.isFirstTimeLogin;
  const token = user && user.token;
  const id = user && user.id;
  const phoneNo = user && user.phone_number;

  return {
    first_name,
    last_name,
    email,
    exp,
    iat,
    role,
    address,
    username,
    isFirstTimeLogin,
    token,
    id,
    phoneNo,
    user,
  };
}

export function getAdminDetails() {
  let userData = Storage.get("admin-details");
  const user = userData && JSON.parse(userData);
  const first_name = user && user.first_name && capitalizeFirstLetter(user.first_name);
  const last_name = user && user.last_name && capitalizeFirstLetter(user.last_name);
  const email = user && user.email;
  const address = user && user.address;
  const image = user && user.image_url;
  const phoneNo = user && user.phone_number;

  return {
    first_name,
    last_name,
    email,
    image,
    address,
    phoneNo,
  };
}

export const getCurrentPagination = () => {
  const page = Storage.get("page") && Number(Storage.get("page"));
  const perPage = Storage.get("perPage") && Number(Storage.get("perPage"));
  return {
    page,
    perPage,
  };
};

export const handleRouting = () => {
  const adminDetails = Storage.get("admin-data");
  const currentAdmin = adminDetails && JSON.parse(adminDetails);
  const permissions = currentAdmin && getAdminPermissions(currentAdmin);

  let userRoute = "";
  const data = assignRoute(permissions, LinkRoutes);
  const menu = data?.filter((data) => data.checked === true);
  const newData = menu?.filter((data) => data.id === "can_manage_overview");
  if (newData?.length > 0) {
    userRoute = newData[0]?.name;
    return userRoute;
  } else {
    userRoute = menu[0]?.name;
    return userRoute;
  }
};
