import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";

// GET COUPONS
export const getCouponsStart = () => ({
  type: Actions.GET_COUPONS_START,
});

export const getCouponsSuccess = (payload) => ({
  type: Actions.GET_COUPONS_SUCCESS,
  payload,
});

export const getCouponsFailure = (payload) => ({
  type: Actions.GET_COUPONS_FAILURE,
  payload,
});

// CREATE COUPON
export const createCouponStart = () => ({
  type: Actions.CREATE_COUPON_START,
});

export const createCouponSuccess = (payload) => ({
  type: Actions.CREATE_COUPON_SUCCESS,
  payload,
});

export const createCouponFailure = (payload) => ({
  type: Actions.CREATE_COUPON_FAILURE,
  payload,
});

// EDIT COUPON
export const editCouponStart = () => ({
  type: Actions.EDIT_COUPON_START,
});

export const editCouponSuccess = (payload) => ({
  type: Actions.EDIT_COUPON_SUCCESS,
  payload,
});

export const editCouponFailure = (payload) => ({
  type: Actions.EDIT_COUPON_FAILURE,
  payload,
});

// SEARCH COUPON
export const searchCouponsStart = () => ({
  type: Actions.SEARCH_COUPONS_START,
});

export const searchCouponsSuccess = (payload) => ({
  type: Actions.SEARCH_COUPONS_SUCCESS,
  payload,
});

export const searchCouponsFailure = (payload) => ({
  type: Actions.SEARCH_COUPONS_FAILURE,
  payload,
});

// FILTER COUPON
export const filterCouponsStart = () => ({
  type: Actions.FILTER_COUPONS_START,
});

export const filterCouponsSuccess = (payload) => ({
  type: Actions.FILTER_COUPONS_SUCCESS,
  payload,
});

export const filterCouponsFailure = (payload) => ({
  type: Actions.FILTER_COUPONS_FAILURE,
  payload,
});

// CHANGE COUPON STATUS
export const changeCouponStatusStart = () => ({
  type: Actions.CHANGE_COUPON_STATUS_START,
});

export const changeCouponStatusSuccess = (payload) => ({
  type: Actions.CHANGE_COUPON_STATUS_SUCCESS,
  payload,
});

export const changeCouponStatusFailure = (payload) => ({
  type: Actions.CHANGE_COUPON_STATUS_FAILURE,
  payload,
});

// DELETE COUPON
export const deleteCouponStart = () => ({
  type: Actions.DELETE_COUPON_START,
});

export const deleteCouponSuccess = (payload) => ({
  type: Actions.DELETE_COUPON_SUCCESS,
  payload,
});

export const deleteCouponFailure = (payload) => ({
  type: Actions.DELETE_COUPON_FAILURE,
  payload,
});

// FILTER REFERRAL COUPON
export const filterReferralCouponStart = () => ({
  type: Actions.FILTER_REFERRAL_COUPON_START,
});

export const filterReferralCouponSuccess = (payload) => ({
  type: Actions.FILTER_REFERRAL_COUPON_SUCCESS,
  payload,
});

export const filterReferralCouponFailure = (payload) => ({
  type: Actions.FILTER_REFERRAL_COUPON_FAILURE,
  payload,
});

export const getCoupons = (page, limit, type) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getCouponsStart());
  API.get(
    type
      ? `coupon?page=${page}&limit=${limit}&type=${type}`
      : `coupon?page=${page}&limit=${limit}`
  ).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getCouponsSuccess(data && data.data));
    } else {
      dispatch(getCouponsFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const createCoupon = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  dispatch(createCouponStart());
  API.post("coupon", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(createCouponSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(createCouponFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const editCoupon = (id, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(editCouponStart());
  API.put(`coupon/edit-coupon/${id}`, payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(editCouponSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      console.log(data.data, "aaaaddd");
      dispatch(editCouponFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const searchCoupons =
  (page, limit, search, type) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

    dispatch(searchCouponsStart());
    API.get(
      type
        ? `coupon?page=${page}&limit=${limit}&search=${search}&type=${type}`
        : `coupon?page=${page}&limit=${limit}&search=${search}`
    ).then((response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(searchCouponsSuccess(data && data.data));
      } else {
        dispatch(searchCouponsFailure(data));
        responseHandler(response, "Failed!");
      }
    });
  };

export const changeCouponStatus = (id, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(changeCouponStatusStart());
  API.put(`coupon/change-status/${id}`, payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(changeCouponStatusSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(changeCouponStatusFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const deleteCoupon = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(deleteCouponStart());
  API.delete(`coupon/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(deleteCouponSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(deleteCouponFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const filterReferralCoupon =
  (page, limit, amount, status) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

    dispatch(filterReferralCouponStart());
    API.get(
      `coupon/filter-ref?page=${page}&limit=${limit}&amount=${amount}&status=${status}`
    ).then((response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(filterReferralCouponSuccess(data && data.data));
      } else {
        dispatch(filterReferralCouponFailure(data));
        responseHandler(response, "Failed!");
      }
    });
  };

export const filterCoupons =
  (page, limit, amount, status, product) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

    dispatch(filterCouponsStart());
    API.get(
      `coupon/filter-coupon?page=${page}&limit=${limit}&amount=${amount}&status=${status}&product=${product}`
    ).then((response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(filterCouponsSuccess(data && data.data));
      } else {
        dispatch(filterCouponsFailure(data));
        responseHandler(response, "Failed!");
      }
    });
  };

export const getSingleCouponStart = () => ({
  type: Actions.GET_SINGLE_COUPON_START,
});

export const getSingleCouponSuccess = (payload) => ({
  type: Actions.GET_SINGLE_COUPON_SUCCESS,
  payload,
});

export const getSingleCoupon = (id) => async (dispatch) => {
  console.log("here")
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  dispatch(getSingleCouponStart());
  API.get(`coupon/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getSingleCouponSuccess(data && data.data));
    } else {
      responseHandler(response, "Failed");
    }
  });
};
