import Actions from "../Types";

const initialState = {
  isGettingFacilities: false,
  isGettingSingleFacility: false,
  isFilteringFacilities: false,
  isSearchingFacilities: false,
  isCreatingFacility: false,
  isEditingFacility: false,
  isDeletingFacility: false,
  isChangingStatus: false,
  getSuccess: false,
  getSingleSuccess: false,
  filterSuccess: false,
  searchSuccess: false,
  deleteSuccess: false,
  changeSuccess: false,
  createSuccess: false,
  editSuccess: false,
  facilities: [],
  facilitiesData: {},
  filteredFacilities: [],
  filteredFacilitiesData: {},
  searchedFacilities: [],
  searchedFacilitiesData: {},
  facility: {},
  error: null,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_FACILITIES_START:
      return {
        ...state,
        isGettingFacilities: true,
        getSuccess: false,
        filterSuccess: false,
        searchSuccess: false,
        facilities: [],
        error: null,
      };
    case Actions.GET_FACILITIES_SUCCESS:
      return {
        ...state,
        isGettingFacilities: false,
        getSuccess: true,
        facilities: action.payload,
        error: null,
      };
    case Actions.GET_FACILITIES_FAILURE:
      return {
        ...state,
        isGettingFacilities: false,
        getSuccess: false,
        facilities: [],
        error: action.payload,
      };
    case Actions.GET_ALL_FACILITIES_START:
      return {
        ...state,
        isGettingFacilities: true,
        getSuccess: false,
        filterSuccess: false,
        searchSuccess: false,
        deleteSuccess: false,
        changeSuccess: false,
        createSuccess: false,
        editSuccess: false,
        getSingleSuccess: false,
        facilities: [],
        facilitiesData: {},
        error: null,
      };
    case Actions.GET_ALL_FACILITIES_SUCCESS:
      return {
        ...state,
        isGettingFacilities: false,
        getSuccess: true,
        filterSuccess: false,
        searchSuccess: false,
        deleteSuccess: false,
        changeSuccess: false,
        createSuccess: false,
        editSuccess: false,
        getSingleSuccess: false,
        facilities: action.payload && action.payload.rows,
        facilitiesData: action.payload,
        error: null,
      };
    case Actions.GET_ALL_FACILITIES_FAILURE:
      return {
        ...state,
        isGettingFacilities: false,
        getSuccess: false,
        filterSuccess: false,
        searchSuccess: false,
        deleteSuccess: false,
        changeSuccess: false,
        createSuccess: false,
        editSuccess: false,
        getSingleSuccess: false,
        facilities: [],
        facilitiesData: {},
        error: action.payload,
      };
    case Actions.ADD_NEW_FACILITY_START:
      return {
        ...state,
        isCreatingFacility: true,
        createSuccess: false,
        facility: {},
        error: null,
      };
    case Actions.ADD_NEW_FACILITY_SUCCESS:
      return {
        ...state,
        isCreatingFacility: false,
        createSuccess: true,
        facility: action.payload,
        error: null,
      };
    case Actions.ADD_NEW_FACILITY_FAILURE:
      return {
        ...state,
        isCreatingFacility: false,
        createSuccess: false,
        facility: null,
        error: action.payload,
      };
    case Actions.GET_SINGLE_FACILITY_START:
      return {
        ...state,
        isGettingSingleFacility: true,
        getSingleSuccess: false,
        facility: {},
        error: null,
      };
    case Actions.GET_SINGLE_FACILITY_SUCCESS:
      return {
        ...state,
        isGettingSingleFacility: false,
        getSingleSuccess: true,
        facility: action.payload,
        error: null,
      };
    case Actions.GET_SINGLE_FACILITY_FAILURE:
      return {
        ...state,
        isGettingSingleFacility: false,
        getSingleSuccess: false,
        facility: null,
        error: action.payload,
      };
    case Actions.EDIT_FACILITY_START:
      return {
        ...state,
        isEditingFacility: true,
        editSuccess: false,
        facility: {},
        error: null,
      };
    case Actions.EDIT_FACILITY_SUCCESS:
      return {
        ...state,
        isEditingFacility: false,
        editSuccess: true,
        facility: action.payload,
        error: null,
      };
    case Actions.EDIT_FACILITY_FAILURE:
      return {
        ...state,
        isEditingFacility: false,
        editSuccess: false,
        facility: null,
        error: action.payload,
      };
    case Actions.CHANGE_FACILITY_STATUS_START:
      return {
        ...state,
        isChangingStatus: true,
        changeSuccess: false,
        facility: {},
        error: null,
      };
    case Actions.CHANGE_FACILITY_STATUS_SUCCESS:
      return {
        ...state,
        isChangingStatus: false,
        changeSuccess: true,
        facility: action.payload,
        error: null,
      };
    case Actions.CHANGE_FACILITY_STATUS_FAILURE:
      return {
        ...state,
        isChangingStatus: false,
        changeSuccess: false,
        facility: null,
        error: action.payload,
      };
    case Actions.FILTER_FACILITIES_START:
      return {
        ...state,
        isFilteringFacilities: true,
        filterSuccess: false,
        searchSuccess: false,
        deleteSuccess: false,
        changeSuccess: false,
        createSuccess: false,
        editSuccess: false,
        getSingleSuccess: false,
        filteredFacilities: [],
        filteredFacilitiesData: {},
        error: null,
      };
    case Actions.FILTER_FACILITIES_SUCCESS:
      return {
        ...state,
        isFilteringFacilities: false,
        filterSuccess: true,
        searchSuccess: false,
        deleteSuccess: false,
        changeSuccess: false,
        createSuccess: false,
        editSuccess: false,
        getSingleSuccess: false,
        filteredFacilities: action.payload && action.payload.rows,
        filteredFacilitiesData: action.payload,
        error: null,
      };
    case Actions.FILTER_FACILITIES_FAILURE:
      return {
        ...state,
        isFilteringFacilities: false,
        filterSuccess: false,
        searchSuccess: false,
        deleteSuccess: false,
        changeSuccess: false,
        createSuccess: false,
        editSuccess: false,
        getSingleSuccess: false,
        filteredFacilities: [],
        filteredFacilitiesData: {},
        error: action.payload,
      };
    case Actions.SEARCH_FACILITIES_START:
      return {
        ...state,
        isSearchingFacilities: true,
        searchSuccess: false,
        deleteSuccess: false,
        changeSuccess: false,
        createSuccess: false,
        editSuccess: false,
        getSingleSuccess: false,
        searchedFacilities: [],
        searchedFacilitiesData: {},
        error: null,
      };
    case Actions.SEARCH_FACILITIES_SUCCESS:
      return {
        ...state,
        isSearchingFacilities: false,
        searchSuccess: true,
        filterSuccess: false,
        deleteSuccess: false,
        changeSuccess: false,
        createSuccess: false,
        editSuccess: false,
        getSingleSuccess: false,
        searchedFacilities: action.payload && action.payload.rows,
        searchedFacilitiesData: action.payload,
        error: null,
      };
    case Actions.SEARCH_FACILITIES_FAILURE:
      return {
        ...state,
        isSearchingFacilities: false,
        searchSuccess: false,
        filterSuccess: false,
        deleteSuccess: false,
        changeSuccess: false,
        createSuccess: false,
        editSuccess: false,
        getSingleSuccess: false,
        searchedFacilities: [],
        searchedFacilitiesData: {},
        error: action.payload,
      };
    case Actions.DELETE_FACILITY_START:
      return {
        ...state,
        isDeletingFacility: true,
        deleteSuccess: false,
        facility: {},
        error: null,
      };
    case Actions.DELETE_FACILITY_SUCCESS:
      return {
        ...state,
        isDeletingFacility: false,
        deleteSuccess: true,
        facility: action.payload,
        error: null,
      };
    case Actions.DELETE_FACILITY_FAILURE:
      return {
        ...state,
        isDeletingFacility: false,
        deleteSuccess: false,
        facility: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reduxState;
