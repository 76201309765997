import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";
import { getUserData } from "../../utils/functions/GetStorageItems";

//CREATE ADMIN
export const createAdminStart = () => ({
  type: Actions.CREATE_ADMIN_START,
});

export const createAdminSuccess = (payload) => ({ 
  type: Actions.CREATE_ADMIN_SUCCESS,
  payload,
});
 
export const createAdminFailure = (payload) => ({
  type: Actions.CREATE_ADMIN_FAILURE, 
  payload,
});
//EDIT ADMIN
export const editAdminStart = () => ({
  type: Actions.EDIT_ADMIN_START,
});

export const editAdminSuccess = (payload) => ({
  type: Actions.EDIT_ADMIN_SUCCESS,
  payload,
});

export const editAdminFailure = (payload) => ({
  type: Actions.EDIT_ADMIN_FAILURE,
  payload,
});

//GET ADMINS
export const getAdminsStart = () => ({
  type: Actions.GET_ADMINS_START,
});

export const getAdminsSuccess = (payload) => ({
  type: Actions.GET_ADMINS_SUCCESS,
  payload,
});

export const getAdminsFailure = (payload) => ({
  type: Actions.GET_ADMINS_FAILURE,
  payload,
});

//SEARCH ADMINS
export const searchAdminsStart = () => ({
  type: Actions.SEARCH_ADMINS_START,
});

export const searchAdminsSuccess = (payload) => ({
  type: Actions.SEARCH_ADMINS_SUCCESS,
  payload,
});

export const searchAdminsFailure = (payload) => ({
  type: Actions.SEARCH_ADMINS_FAILURE,
  payload,
});

//FILTER ADMINS
export const filterAdminsStart = () => ({
  type: Actions.FILTER_ADMINS_START,
});

export const filterAdminsSuccess = (payload) => ({
  type: Actions.FILTER_ADMINS_SUCCESS,
  payload,
});

export const filterAdminsFailure = (payload) => ({
  type: Actions.FILTER_ADMINS_FAILURE,
  payload,
});

//REVOKE ADMINS
export const revokeAdminStart = () => ({
  type: Actions.REVOKE_ADMIN_START,
});

export const revokeAdminSuccess = (payload) => ({
  type: Actions.REVOKE_ADMIN_SUCCESS,
  payload,
});

export const revokeAdminFailure = (payload) => ({
  type: Actions.REVOKE_ADMIN_FAILURE,
  payload,
});

//DELETE ADMIN
export const deleteAdminStart = () => ({
  type: Actions.DELETE_ADMIN_START,
});

export const deleteAdminSuccess = (payload) => ({
  type: Actions.DELETE_ADMIN_SUCCESS,
  payload,
});

export const deleteAdminFailure = (payload) => ({
  type: Actions.DELETE_ADMIN_FAILURE,
  payload,
});

//GET RROLES
export const getRolesStart = () => ({
  type: Actions.GET_ROLES_START,
});

export const getRolesSuccess = (payload) => ({
  type: Actions.GET_ROLES_SUCCESS,
  payload,
});

export const getRolesFailure = (payload) => ({
  type: Actions.GET_ROLES_FAILURE,
  payload,
});

export const clearAdminState = () => ({
  type: Actions.CLEAR_ADMIN_STATE,
});

export const createAdmin = (payload) => (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(createAdminStart());
  API.post("admin/create-admin", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(createAdminSuccess(data));
      responseHandler(response, "Success!");
    } else {
      dispatch(createAdminFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const editAdmin = (payload, adminDetails) => (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  const { id } = getUserData();
  const adminData = JSON.stringify(adminDetails);

  dispatch(editAdminStart());
  API.put("admin/edit", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(editAdminSuccess(data));
      responseHandler(response, "Success!");
      if (payload.id === id) {
        Storage.set("admin-data", adminData);
        window.location.reload();
      }
    } else {
      dispatch(editAdminFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const getAdmins = (page, limit) => (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getAdminsStart());
  API.get(`admin?page=${page}&limit=${limit}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getAdminsSuccess(data && data.data));
    } else {
      dispatch(getAdminsFailure(data));
      responseHandler(response);
    }
  });
};

export const deleteAdmin = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(deleteAdminStart());
  API.delete(`/admin/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(deleteAdminSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(deleteAdminFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const getRoles = (payload) => (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getRolesStart());
  API.get("role", payload).then((response) => {
    const { data, ok } = response;

    if (ok) {
      dispatch(getRolesSuccess(data));
    } else {
      dispatch(getRolesFailure(data));
      responseHandler(response);
    }
  });
};

export const searchAdmins = (page, limit, search) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(searchAdminsStart());
  API.get(`/admin?page=${page}&limit=${limit}&search=${search}`).then(
    (response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(searchAdminsSuccess(data && data.data));
      } else {
        dispatch(searchAdminsFailure(data));
        responseHandler(response, "Failed!");
      }
    }
  );
};

export const filterAdmins =
  (page, limit, date, role, status) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

    dispatch(filterAdminsStart());
    API.get(
      `admin?page=${page}&limit=${limit}&date=${date}&role=${role}&status=${status}`
    ).then((response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(filterAdminsSuccess(data && data.data));
      } else {
        dispatch(filterAdminsFailure(data));
        responseHandler(response, "Failed!");
      }
    });
  };

export const revokeAdmin = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(revokeAdminStart());
  API.put("admin/edit", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(revokeAdminSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(revokeAdminFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};
