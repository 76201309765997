import React from 'react';
import AddIcon from '../../assets/add-image.svg';
import Storage from '../../utils/services/storage';
import { LoaderSpin } from '../Loader/Loader';

export let uploadedFile;

export const assignUrlFunc = (id, assignUrl, uploadFile, type) => {
  uploadedFile = document.querySelector(`#custom-file-input${id}`).files[0];
  const formData = new FormData();
  formData.append('image_url', uploadedFile);
  const payload = {
    formData: formData,
    assignUrl: assignUrl,
    id: id,
    type: type,
    uploadedFile: uploadedFile,
  };
  uploadFile(payload);
  return '';
};

export const assignUrlFunc2 = (file, assignUrl, uploadFile, type) => {
  const formData = new FormData();
  formData.append('image_url', file);
  const payload = {
    formData: formData,
    assignUrl: assignUrl,
    type: type,
    uploadedFile: uploadedFile,
  };
  uploadFile(payload);
  return '';
};

const UploadImage = (props) => {
  const {
    uploadFile,
    id,
    text,
    changeText,
    type,
    isUploading,
    imageUrl,
    updateUrl,
    state,
    setState,
    loaded,
  } = props;

  const assignUrl = (uploadedFile, payload) => {
    console.log(uploadedFile)
    setState({
      ...state,
      loaded: true,
      file_name: uploadedFile,
    });
    type === 'multiple' && updateUrl(id, uploadedFile, payload);
  };

  const upload = (id) => {
    Storage.set('id', id);
    assignUrlFunc(id, assignUrl, uploadFile, type);
  };

  const setId = Storage.get('id') && Number(Storage.get('id'));
  const loading = setId === id && isUploading;
  const uploaded = type === 'single' ? state.loaded : loaded;

  return (
    <div className='upload-image'>
      {loading ? (
        <LoaderSpin width='30px' height='30px' />
      ) : uploaded ? (
        <div className='upload-image-icon'>
          <img
            src={type === 'single' ? state.file_name : imageUrl}
            alt='add-icon'
          />
        </div>
      ) : (
        <div className='upload-image-icon'>
          <img src={AddIcon} alt='add-icon' />
        </div>
      )}

      <div className='upload-image-text'>
        <h2>{uploaded ? changeText : text}</h2>

        <input
          type='file'
          className='custom-file-input'
          data-testid={`uploadFile${id}`}
          id={`custom-file-input${id}`}
          onChange={() => upload(id)}
        />
      </div>
    </div>
  );
};

export default UploadImage;
