import Actions from "../Types";

const initialState = {
  isGetting: false,
  getSuccess: false,
  allOrders: [],
  allOrdersData: {},

  getOrderSuccess: false,
  singleOrder: {},

  allOrdersByStatus: [],
  allOrdersByStatusData: {},

  isSearchingOrders: false,
  searchSuccess: false,
  searchedOrders: [],
  searchedOrdersData: {},

  isFilteringOrders: false,
  filterSuccess: false,
  filteredOrders: [],
  filteredOrdersData: {},

  isChangingStatus: false,
  changeSuccess: false,
  order: {},

  isCreatingOrder: false,
  createOrderSuccess: false,

  error: null,

  isLoadingExportData: false,
  exportDataSuccess: false,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.CREATE_ORDER_START:
      return {
        ...state,
        isCreatingOrder: true,
        createOrderSuccess: false,
      };
    case Actions.CREATE_ORDER_SUCCESS:
      return {
        ...state,
        isCreatingOrder: false,
        createOrderSuccess: true,
      };
    case Actions.GET_ALL_ORDERS_START:
      return {
        ...state,
        isGetting: true,
        getSuccess: false,
        allOrders: [],
        error: null,
      };
    case Actions.GET_ALL_ORDERS_SUCCESS:
      return {
        ...state,
        isGetting: false,
        getSuccess: true,
        filterSuccess: false,
        searchSuccess: false,
        changeSuccess: false,
        allOrders: action.payload && action.payload.orders,
        allOrdersData: action.payload,
        error: null,
        createOrderSuccess: false,
      };
    case Actions.GET_ALL_ORDERS_FAILURE:
      return {
        ...state,
        isGetting: false,
        getSuccess: false,
        allOrders: [],
        allOrdersData: {},
        error: action.payload,
      };
    case Actions.GET_ORDERS_BY_STATUS_START:
      return {
        ...state,
        isGetting: true,
        getSuccess: false,
        allOrdersByStatus: [],
        error: null,
      };
    case Actions.GET_ORDERS_BY_STATUS_SUCCESS:
      return {
        ...state,
        isGetting: false,
        getSuccess: true,
        filterSuccess: false,
        searchSuccess: false,
        changeSuccess: false,
        allOrdersByStatus: action.payload && action.payload.orders,
        allOrdersByStatusData: action.payload,
        error: null,
      };
    case Actions.GET_ORDERS_BY_STATUS_FAILURE:
      return {
        ...state,
        isGetting: false,
        getSuccess: false,
        allOrdersByStatus: [],
        allOrdersByStatusData: {},
        error: action.payload,
      };
    case Actions.GET_ORDER_START:
      return {
        ...state,
        isGetting: true,
        getOrderSuccess: false,
        singleOrder: {},
        error: null,
      };
    case Actions.GET_ORDER_SUCCESS:
      return {
        ...state,
        isGetting: false,
        changeSuccess: false,
        singleOrder: action.payload,
        getOrderSuccess: true,
        error: null,
      };
    case Actions.GET_ORDER_FAILURE:
      return {
        ...state,
        isGetting: false,
        singleOrder: {},
        error: action.payload,
      };
    case Actions.SEARCH_ORDERS_START:
      return {
        ...state,
        isSearchingOrders: true,
        searchSuccess: false,
        searchedOrders: [],
        searchedOrdersData: {},
        error: null,
      };
    case Actions.SEARCH_ORDERS_SUCCESS:
      return {
        ...state,
        isSearchingOrders: false,
        searchSuccess: true,
        searchedOrders: action.payload && action.payload.orders,
        searchedOrdersData: action.payload,
        error: null,
      };
    case Actions.SEARCH_ORDERS_FAILURE:
      return {
        ...state,
        isSearchingOrders: false,
        searchSuccess: false,
        searchedOrders: [],
        searchedOrdersData: {},
        error: action.payload,
      };
    case Actions.FILTER_ORDERS_START:
      return {
        ...state,
        isFilteringOrders: true,
        filterSuccess: false,
        filteredOrders: [],
        filteredOrdersData: {},
        error: null,
      };
    case Actions.FILTER_ORDERS_SUCCESS:
      return {
        ...state,
        isFilteringOrders: false,
        filterSuccess: true,
        filteredOrders: action.payload && action.payload.orders,
        filteredOrdersData: action.payload,
        error: null,
      };
    case Actions.FILTER_ORDERS_FAILURE:
      return {
        ...state,
        isFilteringOrders: false,
        filterSuccess: false,
        filteredOrders: [],
        filteredOrdersData: {},
        error: action.payload,
      };
    case Actions.CHANGE_ORDER_STATUS_START:
      return {
        ...state,
        isChangingStatus: true,
        changeSuccess: false,
        order: {},
        error: null,
      };
    case Actions.CHANGE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        isChangingStatus: false,
        changeSuccess: true,
        order: action.payload,
        error: null,
      };
    case Actions.CHANGE_ORDER_STATUS_FAILURE:
      return {
        ...state,
        isChangingStatus: false,
        changeSuccess: false,
        order: null,
        error: action.payload,
      };
    case Actions.CLEAR_ORDERS_STATE:
      return {
        searchSuccess: false,
      };
    case Actions.EXPORT_ORDERS_START:
      return {
        ...state,
        isLoadingExportData: true,
        exportDataSuccess: false,
      };
      case Actions.EXPORT_ORDERS_SUCCESS:
        return {
        ...state,
        isLoadingExportData: false,
        exportDataSuccess: true,
      };
      case Actions.EXPORT_ORDERS_FAILURE:
        return {
        ...state,
        isLoadingExportData: false,
        exportDataSuccess: false,
      };
    default:
      return state;
  }
};

export default reduxState;
