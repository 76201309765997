import Actions from "../Types";

const initialState = {
  isGettingAssessments: false,
  getAssessmentsSuccess: false,
  assessments: { rows: [] },
  fetchedQuestion: {},
  isSubmittingQuestion: false,
  submitQuestionSuccess: false,
  isUpdating: false,
  isUpdatingSuccess: false,
  reorderingQuestion: false,
  reorderingSuccess: false,
  isGettingQuestion: false,
  isGettingQuestionSuccess: false,
  isGettingQuestionFailure: false,
  isEditing: false,
  isEditingSuccess: false,
  isEditingFailure: false,
  filterSuccess: false,
  isFilteringByCategory: false,
  filterByCategorySuccess: false,
  filteredAssessments: [],
  isDeleting: false,
  deleteSuccess: false,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_ASSESSMENTS_START:
      return {
        ...state,
        isGettingAssessments: true,
        filterSuccess: false,
        filterByCategorySuccess: false,
        isUpdatingSuccess: false,
      };

    case Actions.GET_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        isGettingAssessments: false,
        getAssessmentsSuccess: true,
        filterByCategorySuccess: false,
        isUpdatingSuccess: false,
        deleteSuccess: false,
        assessments: action.payload?.data,
        filterSuccess: action.payload?.filter,
      };

    case Actions.GET_ASSESSMENTS_FAILURE:
      return {
        ...state,
        isGettingAssessments: false,
        getAssessmentsSuccess: false,
        filterByCategorySuccess: false,
        isUpdatingSuccess: false,
      };
    case Actions.CREATE_QUESTION_START:
      return {
        ...state,
        isSubmittingQuestion: true,
        submitQuestionSuccess: false,
      };
    case Actions.CREATE_QUESTION_SUCCESS:
      return {
        ...state,
        isSubmittingQuestion: false,
        submitQuestionSuccess: true,
      };
    case Actions.CREATE_QUESTION_FAILURE:
      return {
        ...state,
        isSubmittingQuestion: false,
        submitQuestionSuccess: false,
      };
    case Actions.GET_QUESTION_START:
      return {
        ...state,
        isGettingQuestion: true,
        isGettingQuestionSuccess: false,
        isGettingQuestionFailure: false,
      };
    case Actions.GET_QUESTION_SUCCESS:
      return {
        ...state,
        isGettingQuestion: false,
        isGettingQuestionSuccess: true,
        fetchedQuestion: action.payload,
        isGettingQuestionFailure: false,
      };
    case Actions.GET_QUESTION_FAILURE:
      return {
        ...state,
        isGettingQuestion: false,
        isGettingQuestionSuccess: false,
        isGettingQuestionFailure: true,
      };
    case Actions.EDIT_QUESTION_START:
      return {
        ...state,
        isEditing: true,
        isEditingSuccess: false,
        isEditingFailure: false,
      };
    case Actions.EDIT_QUESTION_SUCCESS:
      return {
        ...state,
        isEditing: false,
        isEditingSuccess: true,
        isEditingFailure: false,
      };
    case Actions.EDIT_QUESTION_FAILURE:
      return {
        ...state,
        isEditing: false,
        isEditingSuccess: false,
        isEditingFailure: true,
      };
    case Actions.UPDATE_ASSESSMENT_START:
      return {
        ...state,
        isUpdating: true,
        isUpdatingSuccess: false,
      };
    case Actions.UPDATE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isUpdatingSuccess: true,
      };
    case Actions.UPDATE_ASSESSMENT_FAILURE:
      return {
        ...state,
        isUpdating: false,
        isUpdatingSuccess: false,
      };
    case Actions.DELETE_ASSESSMENT_START:
      return {
        ...state,
        isDeleting: true,
        deleteSuccess: false,
        isFilteringByCategory: false,
      };
    case Actions.DELETE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: true,
        isFilteringByCategory: false,
      };
    case Actions.DELETE_ASSESSMENT_FAILURE:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: false,
      };
    case Actions.RESET_QUESTION:
      return {
        ...state,
        fetchedQuestion: {},
        isGettingQuestion: false,
        isGettingQuestionSuccess: false,
        isGettingQuestionFailure: false,
        isEditing: false,
        isEditingSuccess: false,
        isEditingFailure: false,
      };
    case Actions.REORDER_ASSESSMENT_START:
      return {
        ...state,
        reorderingQuestion: true,
        reorderingSuccess: false,
      };
    case Actions.REORDER_ASSESSMENT_SUCCESS:
      return {
        ...state,
        reorderingQuestion: false,
        reorderingSuccess: true,
      };
    case Actions.REORDER_ASSESSMENT_FAILURE:
      return {
        ...state,
        reorderingQuestion: false,
        reorderingSuccess: false,
      };
    case Actions.FILTER_ASSESSMENT_FAILURE:
      return {
        ...state,
        isGettingAssessments: false,
        getAssessmentsSuccess: false,
        filterSuccess: false,
      };
    case Actions.FILTER_ASSESSMENT_BY_SUBCATEGORY_START:
      return {
        ...state,
        isFilteringByCategory: true,
        filterByCategorySuccess: false,
        getAssessmentsSuccess: false,
        isUpdatingSuccess: false,
        filteredAssessments: [],
      };
    case Actions.FILTER_ASSESSMENT_BY_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        isFilteringByCategory: false,
        filterByCategorySuccess: true,
        getAssessmentsSuccess: false,
        isUpdatingSuccess: false,
        filteredAssessments: action.payload ? action.payload : [],
      };
    case Actions.FILTER_ASSESSMENT_BY_SUBCATEGORY_FAILURE:
      return {
        ...state,
        isFilteringByCategory: false,
        filterByCategorySuccess: false,
        getAssessmentsSuccess: false,
        isUpdatingSuccess: false,
        filteredAssessments: [],
      };
    default:
      return { ...state };
  }
};

export default reduxState;
