import moment from "moment";
import React, { useState } from "react";
import ChangeCategoryStatus from "../../BlogManagement/BlogCategory/ChangeCategoryStatus";
import DeleteCategory from "../../BlogManagement/BlogCategory/DeleteCategory";
import EditCategory from "../../BlogManagement/BlogCategory/EditCategory";
import { cancelModal, viewModal } from "../../Modal/Modal";
import {
  ErrorBtn,
  SuccessBtn,
  DropBtn,
  TableDropdown,
} from "../../Tables/TableButtons";

export const blogCategoryColumns = [
  {
    Header: "Category Name",
    accessor: "name",
    Cell: ({ row }) => (
      <div className="table-flex">
        <span>{row.original && row.original.name}</span>
      </div>
    ),
  },
  {
    Header: "Date Created",
    accessor: "category",
    Cell: ({ row }) => (
      <div className="table-flex">
        <span>{moment(row.original.created_at).format("DD/MM/YYYY")}</span>
      </div>
    ),
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ row }) =>
      row.original.status && row.original.status.toLowerCase() === "active" ? (
        <SuccessBtn text={"Active"} />
      ) : (
        <ErrorBtn text={"Deactivated"} />
      ),
  },
  {
    Header: "Action ",
    accessor: "action",
    Cell: ({ row }) => {
      const [show, setShow] = useState(false);
      const [state, setState] = useState({
        deleteModal: false,
        statusModal: false,
        editModal: false,
      });

      const status =
        row.original.status && row.original.status.toLowerCase() === "active";

      const openModal = (type) => {
        viewModal(type, state, setState);
        setShow((prevCheck) => !prevCheck);
      };

      const closeModal = (type) => {
        cancelModal(type, state, setState);
      };

      const options = [
        {
          name: "Edit",
          action: () => openModal("editModal"),
        },
        {
          name: status ? "Deactivate" : "Activate",
          action: () => openModal("statusModal"),
        },
        {
          name: "Delete",
          action: () => openModal("deleteModal"),
        },
      ];

      return (
        <div className="action-flex">
          <DropBtn onClick={() => setShow((prevCheck) => !prevCheck)} />
          {show && <TableDropdown options={options} />}

          {state.editModal && (
            <EditCategory
              displayModal={state.editModal}
              closeModal={() => closeModal("editModal")}
              data={row.original}
            />
          )}

          {state.statusModal && (
            <ChangeCategoryStatus
              displayModal={state.statusModal}
              closeModal={() => closeModal("statusModal")}
              data={row.original}
              status={row.original.status && row.original.status.toLowerCase()}
            />
          )}

          {state.deleteModal && (
            <DeleteCategory
              displayModal={state.deleteModal}
              closeModal={() => closeModal("deleteModal")}
              id={row.original && row.original.id}
            />
          )}
        </div>
      );
    },
  },
];
