import React, { useEffect, useState } from "react";
import {
  SuccessBtn,
  DropBtn,
  TableDropdown,
  InfoBtn,
} from "../../../Tables/TableButtons";
import { cancelModal, viewModal } from "../../../Modal/Modal";
import { useHistory } from "react-router-dom";
import DeleteClient from "../../../UserManagement/Clients/DeleteClient";
import ActivateClient from "../../../UserManagement/Clients/ActivateClient";
import SuspendClient from "../../../UserManagement/Clients/SuspendClient";
import Table from "../../../Tables/Table";
import truncateName from "../../../../utils/functions/ShortenStr";
import { filterOptionsLabel } from "../../../../utils/functions/FilterObject";
import { getUsers } from "../../../../redux/Actions/UsersActions";
import { connect } from "react-redux";

const ClientsColumns = (props) => {
  const {
    state,
    setState,
    data,
    getData,
    getMoreData,
    isLoading,
    success,
    sortSucces,
    total,
    deleteSuccess,
    revokeSuccess,
    createSuccess
  } = props;

  useEffect(() => {
    if (deleteSuccess || revokeSuccess || createSuccess) {
      getData(1, 10);
    }
  }, [deleteSuccess, revokeSuccess, createSuccess]);

  const clientsData = [
    {
      Header: "Full Name",
      accessor: "full_name",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <span style={{ textTransform: "capitalize" }}>
            {row?.original?.full_name === null ? "Nil" : row?.original?.full_name}
          </span>
        </div>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ row }) => (
        <div className="table-flex">
          <span>{truncateName(row?.original?.email, 17) || "-"}</span>
        </div>
      ),
    },
    {
      Header: "Subscription Plan",
      accessor: "plans",
      Cell: ({ row }) => (
        <div className="table-flex">
          <span>
            {row?.original?.plans && row?.original?.plans?.name === null
              ? "Nil"
              : row?.original?.plans && row?.original?.plans?.name}
          </span>
        </div>
      ),
    },

    {
      Header: "Sex",
      accessor: "gender",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <span style={{ textTransform: "capitalize" }}>
            {row?.original && row?.original?.gender === null
              ? "Nil"
              : row?.original && row?.original?.gender}
          </span>
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        const id = row?.original && row.original?.id;
        const status = row?.original && row.original?.status;
        const [show, setShow] = useState(false);

        const history = useHistory();

        const [state, setState] = useState({
          activateModal: false,
          suspendModal: false,
          deleteModal: false,
          deliveredModal: false,
          canceledModal: false,
        });

        const openModal = (type) => {
          viewModal(type, state, setState);
          setShow((prevCheck) => !prevCheck);
        };

        const closeModal = (type) => {
          cancelModal(type, state, setState);
        };

        const goToSinglePage = () => {
          history.push({
            pathname: `/users/clients/${id}`,
            state: {
              id: id,
              data: row.original,
            },
          });
        };

        // Newly Added 
        const goToEditPage = () => {
          history.push({
            pathname: `/users/clients/edit/${id}`,
            state: {
              id: id,
              data: row.original,
            },
          });
        };

        const options = [
          {
            name: "View",
            action: () => goToSinglePage(),
          },
          {
            name: "Activate",
            label: "Active",
            action: () => openModal("activateModal"),
          },
          {
            name: "Suspend",
            label: "Suspended",
            action: () => openModal("suspendModal"),
          },
          // {
          //   name: "Edit",
          //   label: "Edit",
          //   action: () => goToEditPage(),
          // },
          {
            name: "Delete",
            action: () => openModal("deleteModal"),
          },
        ];

        return (
          <div className="action-flex">
            {row.original.status === "active" ? (
              <SuccessBtn text={"Active"} />
            ) : row.original.status === "suspended" ? (
              <InfoBtn text={"Suspended"} />
            ) : (
              "Nil"
            )}
            <DropBtn onClick={() => setShow((prevCheck) => !prevCheck)} />
            {show && (
              <TableDropdown
                options={options && filterOptionsLabel(status, options)}
              />
            )}

            {state.activateModal && (
              <ActivateClient
                displayModal={state.activateModal}
                closeModal={() => closeModal("activateModal")}
                id={id}
              />
            )}

            {state.suspendModal && (
              <SuspendClient
                displayModal={state.suspendModal}
                closeModal={() => closeModal("suspendModal")}
                id={id}
              />
            )}

            {state.deleteModal && (
              <DeleteClient
                displayModal={state.deleteModal}
                closeModal={() => closeModal("deleteModal")}
                id={id}
              />
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="orders-table" id="ordersTable">
      <Table
        state={state}
        setState={setState}
        columns={clientsData}
        data={data}
        getData={getData}
        getMoreData={getMoreData}
        isLoading={isLoading}
        success={success}
        sortSucces={sortSucces}
        total={total}
        emptyText1={"You haven't added any Client"}
        emptyResultText={"Client not found!"}
      />
    </div>
  );
};
const mapStateToProps = (state) => ({
  isDeleting: state.users.isDeleting,
  deleteSuccess: state.users.deleteSuccess,
  revokeSuccess: state.users.revokeSuccess,
  createSuccess: state.users.createSuccess,
});



export default connect(mapStateToProps, null)(ClientsColumns);
