import React, { useEffect } from "react";
import Modal, { ModalButton } from "../../Modal/Modal";
import { connect } from "react-redux";
import { Field, Form, Formik } from "formik";
import Input from "../../Input/Input";
import { editReferral } from "../../../redux/Actions/ReferralActions";
import { ReferralSchema } from "../../../utils/validation/validation";

const EditReferral = (props) => {
  const {
    displayModal,
    closeModal,
    data,
    editReferral,
    isEditing,
    editSuccess,
  } = props;

  useEffect(
    () => {
      if (editSuccess) {
        document.getElementById("editReferralform").reset();
        closeModal();
      }
    },
    // eslint-disable-next-line
    [editSuccess]
  );

  const initialValues = {
    name: data && data.name,
    sender_reward: data && data.referrer_amount,
    receiver_reward: data && data.referred_amount,
    duration: data && data.duration_in_days,
  };

  const submitValues = (values) => {
    const payload = {
      name: values.name,
      referred_amount: values.receiver_reward,
      referrer_amount: values.sender_reward,
      duration_in_days: values.duration,
    };
    const id = data && data.id;
    editReferral(id, payload);
  };

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Edit Referral"}
      width={"568px"}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => submitValues(values)}
        validationSchema={ReferralSchema}
      >
        {({ isValid, dirty, setFieldValue }) => {
          return (
            <Form id="editReferralform">
              <div className="form-grid3">
                <Field
                  name="name"
                  label="Name"
                  placeholder="Enter name"
                  component={Input}
                  emptyValue={setFieldValue}
                />
                <Field
                  type="number"
                  name="sender_reward"
                  label="Sender's Reward"
                  placeholder="Enter sender's reward"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>
              <div className="form-grid2">
                <Field
                  type="number"
                  name="receiver_reward"
                  label="Receiver's Reward"
                  placeholder="Enter receiver's reward"
                  component={Input}
                  emptyValue={setFieldValue}
                />
                <Field
                  type="number"
                  name="duration"
                  label="Duration"
                  placeholder="Enter duration"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>

              <ModalButton
                isLoading={isEditing}
                text={"Save Changes"}
                type={"submit"}
                testId={"button"}
                loadingText={"Updating..."}
                disabled={isEditing}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isEditing: state.referrals.isEditing,
  editSuccess: state.referrals.editSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  editReferral: (id, payload) => dispatch(editReferral(id, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditReferral);
