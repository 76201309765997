import React from "react";
import Table from "../../Tables/Table";
import { productColumns } from "../../TableData/Products/ProductSummary";

export const paginateProductHistoryFilter = (
  page,
  perPage,
  values,
  filterProductHistory
) => {
  const payload = {
    status: values.status,
    date: values.date,
    id: values.id,
  };
  filterProductHistory(page, perPage, payload);
};

const ProductHistory = (props) => {
  const {
    id,
    isGettingHistory,
    productHistory,
    productHistoryData,
    getHistorySuccess,
    isSearching,
    searchedProducts,
    searchedProductsData,
    searchSuccess,
    getProductHistory,
    searchProductHistory,
    isFiltering,
    filteredProducts,
    filteredProductsData,
    filterSuccess,
    filterProductHistory,
    state,
  } = props;

  const payload = {
    searchvalue: state.searchValue,
    id: id,
  };

  const moreData = (page, perPage) => {
    searchSuccess
      ? searchProductHistory(page, perPage, payload)
      : filterSuccess
      ? paginateProductHistoryFilter(
          page,
          perPage,
          state.filterValues,
          filterProductHistory
        )
      : getProductHistory(page, perPage, id);
  };

  const allProducts = searchSuccess
    ? searchedProducts
    : filterSuccess
    ? filteredProducts
    : productHistory;

  const totalProducts = searchSuccess
    ? searchedProductsData && searchedProductsData.total
    : filterSuccess
    ? filteredProductsData && filteredProductsData.total
    : productHistoryData && productHistoryData.total;

  return (
    <div>
      <Table
        columns={productColumns}
        data={allProducts}
        getMoreData={(page, perPage) => moreData(page, perPage)}
        getData={(page, limit) => getProductHistory(page, limit, id)}
        emptyText1={"No history availble"}
        isLoading={isGettingHistory || isFiltering || isSearching}
        success={getHistorySuccess || filterSuccess || searchSuccess}
        emptyResultText={"Product History not found!"}
        sortSuccess={searchSuccess || filterSuccess}
        total={totalProducts}
        className={"manager-table"}
      />
    </div>
  );
};

export default ProductHistory;
