import React from "react";
import BackBtn from "../../assets/chevron_right.svg";
import EmptyChat from "./EmptyChat";
import imageUrl from "../../assets/account_icon.svg";

const SingleChat = (props) => {
  const { chat, component, className } = props;

  return (
    <div className={`chats-right ${className}`}>
      {chat === "" ? (
        <EmptyChat
          text={"Open a chat to get started"}
          className="chats-right-details3"
        />
      ) : (
        <>
          <div className="chats-right-header">
            <div className="chats-right-header-left">
              <input type="checkbox" id="chat-check" />
              <label htmlFor="chat-check">
                <div className="chats-back">
                  <img src={BackBtn} alt="back" />
                </div>
              </label>
              <div className="chats-right-header-left-image">
                <img
                  src={
                    chat?.specialist?.image_url === null
                      ? imageUrl
                      : chat?.specialist?.image_url ||
                        chat?.user?.image_url === null
                      ? imageUrl
                      : chat?.user?.image_url
                  }
                  alt="profile pic"
                />
              </div>
              <div className="chats-right-header-left-details">
                <div className="top">
                  {" "}
                  {chat?.specialist?.full_name || chat?.user?.full_name}
                </div>
                <div className="bottom">
                  {chat.status === "active" ? "Online" : "Away"}
                </div>
              </div>
            </div>

            <div>{component}</div>
          </div>

          <div className="chats-right-details">
            <div className="chats-right-details-title">{chat.question}</div>
            <div className="chats-right-details-history">
              Chat history not available
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SingleChat;
