import React, { useEffect, useState } from "react";
import Modal, { ModalButton } from "../../Modal/Modal";
import { Form, Formik } from "formik";
import { Statuses } from "../../Input/Statuses";
import UploadImage from "../UploadImage";
import ProductInputs from "./ProductInputs";
import { connect } from "react-redux";
import { getProductCategories } from "../../../redux/Actions/ProductCategoryAction";
import { assignCategoryOptions } from "../../../utils/functions/UpdateValues";
import { uploadImage } from "../../../redux/Actions/UploadActions";
import {
  checkIfUrlExists,
  filterObj,
} from "../../../utils/functions/FilterObject";
import { ProductSchema } from "../../../utils/validation/validation";
import ImportantText from "../../Wrappers/ImportantText";

const AddProduct = (props) => {
  const {
    displayModal,
    closeModal,
    isCreating,
    createProduct,
    getSuccess,
    getProductSuccess,
    productCategories,
    getProductCategory,
    isUploading,
    uploadFile,
    addSuccess,
  } = props;

  const [categoryOptions, setCategoryOptions] = useState([]);

  useEffect(
    () => {
      getProductCategory();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (getProductSuccess) {
        const updatedCategory = assignCategoryOptions(productCategories);
        setCategoryOptions(updatedCategory);
      }
    },
    // eslint-disable-next-line
    [getProductSuccess, getSuccess]
  );

  const [state, setState] = useState({
    category: "",
    active: false,
    status: "",
    index: "",
    product_images: [""],
    error: "",
    loaded: false,
    file_name: "",
  });

  const [iconState, setIconState] = useState({
    loaded: false,
    file_name: "",
  });

  const [quantityState, setQuantityState] = useState({
    date: "",
    active: false,
    status: "",
    index: "",
  });

  const [deliveryState, setDeliveryState] = useState({
    status: "",
    index: "",
    active: false,
  });

  const updateSelection = (type, data) => {
    setState({
      ...state,
      [type]: data.value,
    });
  };

  const status = [
    { name: "In Stock" },
    { name: "Low Stock" },
    { name: "Out of Stock" },
  ];

  const addNewImage = () => {
    setState({
      ...state,
      product_images: state.product_images.concat([""]),
    });
  };

  const initialValues = {
    product_name: "",
    price: "",
    moq: "",
    manufacturer: "",
    discount: "",
    product_description: "",
    quantity: "",
    size: "",
    effectiveness: "",
    color: "",
    duration: "",
  };

  const quantity = [{ name: "Single" }, { name: "Bulk" }];
  const delivery = [{ name: "Yes" }, { name: "No" }];

  const updateUrl = (index, uploadedFile, payload) => {
    const newImages = state.product_images.map((image, idx) => {
      if (index !== idx) return image;
      return uploadedFile;
    });
    setState({ ...state, product_images: newImages });
  };

  const submitValues = (values) => {
    const data = Object.assign({
      name: values.product_name,
      price: values.price && values.price.toString(),
      minimum_order_quantity: values.moq,
      manufacturer: values.manufacturer,
      discount: values.discount,
      duration: values.duration,
      category_id: state.category,
      quantity: values.quantity,
      expiry_date: quantityState.date,
      delivery_available: deliveryState.status === "Yes" ? true : false,
      effectiveness: values.effectiveness,
      product_status: state.status,
      summary: values.product_description,
      color: values.color,
      size: values.size,
      quantity_type: quantityState.status,
      images: state.product_images,
    });
    submitPayload(data);
  };

  const submitPayload = (data) => {
    const payload = data && filterObj(data);
    createProduct(payload);
  };

  useEffect(
    () => {
      if (addSuccess) {
        setState({
          ...state,
          category: "",
          product_images: [""],
          status: "",
          index: "",
        });
        setQuantityState({
          status: "",
          index: "",
        });
        setDeliveryState({
          status: "",
          index: "",
        });
      }
    },
    // eslint-disable-next-line
    [addSuccess]
  );

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Add New Product"}
      width={"568px"}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => submitValues(values)}
        validationSchema={ProductSchema}
      >
        {({ isValid, dirty, setFieldValue }) => {
          return (
            <Form id="addProduct">
              <div className="form-grid3">
                {state.product_images &&
                  state.product_images.map((image, index) => (
                    <div key={index}>
                      <UploadImage
                        state={iconState}
                        setState={setIconState}
                        uploadFile={uploadFile}
                        id={index}
                        text={<ImportantText label="Add Picture" />}
                        type={"multiple"}
                        isUploading={isUploading}
                        updateUrl={updateUrl}
                        imageUrl={image}
                        changeText={"Change Image"}
                        loaded={checkIfUrlExists(state.product_images, index)}
                      />
                    </div>
                  ))}
              </div>
              <div
                className="payment-modal-add-more"
                style={{ textDecoration: "underline", marginTop: "0px" }}
              >
                <span onClick={addNewImage}> + Add new image</span>
              </div>

              <ProductInputs
                options={categoryOptions}
                updateSelection={updateSelection}
                state={quantityState}
                setState={setQuantityState}
                setFieldValue={setFieldValue}
                addSuccess={addSuccess}
              />

              <div className="top-spacer">
                <Statuses
                  label={<ImportantText label={"Delivery available"} />}
                  state={deliveryState}
                  options={delivery}
                  setstate={setDeliveryState}
                  blue={false}
                />
              </div>

              <div className="top-spacer">
                <Statuses
                  label={<ImportantText label={"Status"} />}
                  state={state}
                  options={status}
                  setstate={setState}
                  blue={false}
                />
              </div>

              <div className="top-spacer">
                <Statuses
                  label={<ImportantText label={"Quantity type"} />}
                  state={quantityState}
                  options={quantity}
                  setstate={setQuantityState}
                  blue={false}
                />
              </div>

              <div className="add-product-btn">
                <ModalButton
                  isLoading={isCreating}
                  text={"Create Product"}
                  type={"submit"}
                  testId={"button"}
                  loadingText={"Creating..."}
                  disabled={
                    isCreating ||
                    (state.product_images &&
                      state.product_images.length === 0) ||
                    !(isValid, dirty) ||
                    !deliveryState.status ||
                    !quantityState.status ||
                    !state.category
                  }
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isGettingProductCategory: state.productcategory.isGetting,
  getProductSuccess: state.productcategory.getSuccess,
  productCategories: state.productcategory.productCategories,
  isUploading: state.upload.isUploading,
  uploadSuccess: state.upload.uploadSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getProductCategory: () => dispatch(getProductCategories()),
  uploadFile: (payload) => dispatch(uploadImage(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);
