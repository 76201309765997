import React, { useState, useEffect } from "react";
import SelectInput from "../../components/Input/Select";
import Input from "../../components/Input/Input";
import MultiDropdown from "../../components/Input/MultiDropdown";
import { v4 as uuidv4 } from "uuid";
import BackBtn from "../../components/Input/BackBtn";
import { useHistory, useParams } from "react-router";
import { getSingleAppointmentCategory } from "../../redux/Actions/AppointmentActions";
import {
  createQuestion,
  fetchSingleQuestion,
  editSingleQuestion,
  clearQuestion,
} from "../../redux/Actions/AssessmentActions";
import { connect } from "react-redux";
import { ModalButton } from "../../components/Modal/Modal";

const initialAnswers = [
  {
    option: "",
    condition: "",
    unique: "",
    isSkipNext1: "",
    isSkipNext2: "",
    id: uuidv4(),
  },
];

const AssessmentCategory = ({
  fetchAppointmentCategory,
  isGettingCategory,
  getCategorySuccess,
  category,
  isSubmitting,
  submitQuestion,
  submitSuccess,
  fetchQuestion,
  isGettingQuestion,
  isGettingQuestionSuccess,
  fetchedQuestion,
  editQuestion,
  isEditing,
  resetQuestion,
}) => {
  const [state, setState] = useState({
    question: "",
    type: "",
    appointment_sub_categories: "",
    status: "",
  });
  const [categoryOptions, setCategoryOptions] = useState([
    { category_id: "", name: "Loading..." },
  ]);
  const [answers, setAnswers] = useState(initialAnswers);

  const { push } = useHistory();
  const params = useParams();
  const { id: category_id, type: queryType } = params;
  const [question, setQuestion] = useState(null);

  useEffect(
    () => {
      if (queryType === "create") {
        fetchAppointmentCategory(category_id);
        return;
      }

      fetchQuestion(category_id);

      return () => resetQuestion();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (getCategorySuccess) {
        setCategoryOptions(
          category.appointment_subcategory.map((subCat) => ({
            category_id: subCat.id,
            name: subCat.name,
          }))
        );
      }
    },
    // eslint-disable-next-line
    [isGettingCategory]
  );

  // When editing a question...
  useEffect(
    () => {
      if (isGettingQuestionSuccess) {
        fetchAppointmentCategory(fetchedQuestion?.appointment_category?.id);
        setQuestion(fetchedQuestion);
      }
    },
    // eslint-disable-next-line
    [isGettingQuestion]
  );

  //When a question has been fetched
  useEffect(() => {
    if (question) {
      const formatCategory = (subCat) => ({
        category_id: subCat.id,
        name: subCat.name,
      });

      const {
        question: questionTitle,
        appointment_subcategory,
        status,
        type,
        option,
      } = question;

      setState({
        question: questionTitle,
        type,
        appointment_sub_categories:
          appointment_subcategory?.map(formatCategory),
        status,
      });
      setAnswers(
        option?.map((obj) => ({
          option: obj.option,
          condition: obj.answer,
          unique: obj.is_unique,
          isSkipNext1: obj.next_skip === 1 ? 1 : 0,
          isSkipNext2: obj.next_skip === 2 ? 2 : 0,
          id: obj.id,
        }))
      );
    }
  }, [question]);

  useEffect(() => {
    if (submitSuccess) {
      resetFields();
    }
  }, [submitSuccess]);

  const resetFields = () => {
    setAnswers([
      {
        option: "",
        condition: "",
        unique: "",
        isSkipNext1: "",
        isSkipNext2: "",
        id: uuidv4(),
      },
    ]);
    setState((state) => ({
      ...state,
      question: "",
      type: "",
    }));
  };

  const options = {
    types: [
      { label: "Choice", value: "choice" },
      { label: "Open", value: "open" },
    ],
    statuses: [
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ],
    conditions: [
      { label: "Pass", value: "pass" },
      { label: "Fail", value: "fail" },
      { label: "Confirm", value: "confirm" },
      { label: "Pending", value: "pending" },
    ],
    unique: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
    isSkipNext1: [
      { label: "Yes", value: 1 },
      { label: "No", value: 0 },
    ],
    isSkipNext2: [
      { label: "Yes", value: 2 },
      { label: "No", value: 0 },
    ],
  };

  const actionHandler = (action, index) => {
    const currentAnswers = [...answers];
    if (action === "add") {
      currentAnswers.splice(index + 1, 0, {
        option: "",
        condition: "",
        unique: "",
        isSkipNext1: "",
        isSkipNext2: "",
        id: uuidv4(),
      });
      setAnswers(currentAnswers);
      return;
    }

    if (action === "remove") currentAnswers.splice(index, 1);
    setAnswers(currentAnswers);
  };

  const updateAnswerOption = (value, index, key) => {
    setAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[index][key] = value;
      return updatedAnswers;
    });
  };

  const isNumber = (value) => typeof value === "number";

  const allOptionsFilled = (option) => {
    return (
      !!option.option &&
      !!option.condition &&
      typeof option.unique === "boolean" &&
      (isNumber(option.isSkipNext1) || isNumber(option.isSkipNext2))
    );
  };

  const submitButtonDisabled = () => {
    const questionsSectionValid = Object.keys(state).every((key) =>
      typeof state[key] !== "object" ? !!state[key] : !!state[key].length
    );

    let answersValid;
    const selectedAnswers =
      state.type === "choice" ? [{ ...answers[0] }] : [...answers];

    answersValid = selectedAnswers.every((option) => {
      const {
        isSkipNext1,
        isSkipNext2,
        option: answer,
        condition,
        unique,
      } = option;
      return state.type === "open"
        ? !!condition &&
            typeof unique === "boolean" &&
            (isNumber(isSkipNext1) || isNumber(isSkipNext2))
        : !!answer &&
            !!condition &&
            typeof unique === "boolean" &&
            (isNumber(isSkipNext1) || isNumber(isSkipNext2));
    });

    return !(questionsSectionValid && answersValid);
  };

  const optionFormatter = (option) => {
    let obj = {
      option: option.option,
      condition: option.condition,
      unique: option.unique,
    };

    let obj2 = {
      condition: option.condition,
      unique: option.unique,
    };

    let next_skip;
    if (isNumber(option.isSkipNext1) && isNumber(option.isSkipNext2)) {
      const { isSkipNext1, isSkipNext2 } = option;
      next_skip = isSkipNext2 > isSkipNext1 ? isSkipNext2 : isSkipNext1;
    }
    if (isNumber(option.isSkipNext1) && !isNumber(option.isSkipNext2)) {
      next_skip = option.isSkipNext1;
    }
    if (!isNumber(option.isSkipNext1) && isNumber(option.isSkipNext2)) {
      next_skip = option.isSkipNext2;
    }

    obj = { ...obj, next_skip };
    obj2 = { ...obj2, next_skip };

    return state.type === "open" ? obj2 : obj;
  };

  const submitQuestionHandler = () => {
    const questionsAndAnswers = {
      ...state,
      category_id: Number(category_id),
      appointment_sub_categories: state.appointment_sub_categories?.map(
        (category) => ({
          sub_category_id: category.category_id,
        })
      ),
      options: answers.map(optionFormatter),
    };

    if (state.type === "open" && answers.length > 1) {
      if (
        // eslint-disable-next-line no-restricted-globals
        confirm(
          "Saving multiple options with a choice of 'Open' will only submit the first option. Do you wish to continue?"
        )
      ) {
        const updatedQuestions = {
          ...questionsAndAnswers,
          options: [{ ...questionsAndAnswers.options[0] }],
        };
        if (queryType === "create") {
          submitQuestion(updatedQuestions);
        } else {
          editQuestion({ question: updatedQuestions, id: category_id });
        }
      }
      return;
    }
    if (queryType === "create") {
      submitQuestion(questionsAndAnswers);
    } else {
      editQuestion({ question: questionsAndAnswers, id: category_id });
    }
  };

  const multiChoiceDisabled = ["open", ""].includes(state.type);

  return (
    <>
      <BackBtn onClick={() => push({ pathname: "/appointment-manager" })} />
      <div className="page-headers">
        <div className="page-headers-top">
          <div className="auth-header2">
            <h1 className="text-capitalize">{queryType} Question</h1>
            <h4>Helps you {queryType} your questions</h4>
          </div>
        </div>
      </div>
      <div
        className={`assessment__card${
          (!getCategorySuccess && isGettingCategory) ||
          isSubmitting ||
          isEditing ||
          isGettingQuestion
            ? " disabled"
            : ""
        }`}
      >
        <div className="form-grid3 grid__gap-lg">
          <SelectInput
            label="Category"
            options={[]}
            defaultOption={
              (queryType === "create"
                ? category?.name
                : question?.appointment_category.name) ?? "Loading..."
            }
            readOnly
          />

          <Input
            value={state.question}
            onChange={(e) =>
              setState((state) => ({ ...state, question: e.target.value }))
            }
            label={"Question"}
            placeholder="What do you want to know?"
          />

          <div className="u-above">
            <MultiDropdown
              state={state}
              accessor="appointment_sub_categories"
              onSelect={(categories) =>
                setState((state) => ({
                  ...state,
                  appointment_sub_categories: categories,
                }))
              }
              onRemove={(categories) =>
                setState((state) => ({
                  ...state,
                  appointment_sub_categories: categories,
                }))
              }
              label="Subcategory"
              chipBorderRadius="4px"
              chipPadding="1px 6px"
              chipTextColor="#303B54"
              mb="8px"
              placeholder={
                state.categories && state.categories.length > 0
                  ? "Add more categories..."
                  : "Select categories"
              }
              testid={"category-tags"}
              type2={false}
              options={categoryOptions}
            />
          </div>

          <SelectInput
            label="Choice"
            options={options.types}
            defaultOption={state.type}
            onChange={(option) => setState({ ...state, type: option.value })}
            readOnly={queryType === "edit"}
          />

          <SelectInput
            label="Status"
            options={options.statuses}
            defaultOption={state.status}
            onChange={(option) => setState({ ...state, status: option.value })}
          />
        </div>

        <table className="assessment__table-wrapper">
          <thead>
            <tr className="assessment__table-head">
              <th className="assessment__table-titles">Answer</th>
              <th className="assessment__table-titles">Condition</th>
              <th className="assessment__table-titles">Is Unique</th>
              <th className="assessment__table-titles">Is Skip Next(1)</th>
              <th className="assessment__table-titles">Is Skip Next(2)</th>
              <th className="assessment__table-titles">Action</th>
            </tr>
          </thead>

          <tbody>
            {answers?.map((option, index) => (
              <tr key={option.id}>
                <td
                  className={`assessment__table-data${!index ? " pt-lg" : ""}`}
                >
                  <Input
                    value={option.option}
                    onChange={({ target: { value } }) =>
                      updateAnswerOption(value, index, "option")
                    }
                    hideLabel
                    placeholder="Your answer..."
                    readOnly={
                      (multiChoiceDisabled && !!index) || state.type === "open"
                    }
                  />
                </td>
                <td
                  className={`assessment__table-data${!index ? " pt-lg" : ""}`}
                >
                  <SelectInput
                    placeholder="Select..."
                    options={options.conditions}
                    defaultOption={option.condition}
                    onChange={(option) =>
                      updateAnswerOption(option.value, index, "condition")
                    }
                    readOnly={multiChoiceDisabled && !!index}
                  />
                </td>
                <td
                  className={`assessment__table-data${!index ? " pt-lg" : ""}`}
                >
                  <SelectInput
                    placeholder="Select..."
                    options={options.unique}
                    defaultOption={
                      option.unique === true
                        ? "Yes"
                        : option.unique === false
                        ? "No"
                        : ""
                    }
                    onChange={(option) =>
                      updateAnswerOption(option.value, index, "unique")
                    }
                    readOnly={multiChoiceDisabled && !!index}
                  />
                </td>
                <td
                  className={`assessment__table-data${!index ? " pt-lg" : ""}`}
                >
                  <SelectInput
                    placeholder="Select..."
                    options={options.isSkipNext1}
                    defaultOption={
                      option.isSkipNext1 === 1
                        ? "Yes"
                        : option.isSkipNext1 === ""
                        ? ""
                        : "No"
                    }
                    onChange={(option) =>
                      updateAnswerOption(option.value, index, "isSkipNext1")
                    }
                    readOnly={multiChoiceDisabled && !!index}
                  />
                </td>
                <td
                  className={`assessment__table-data${!index ? " pt-lg" : ""}`}
                >
                  <SelectInput
                    placeholder="Select..."
                    options={options.isSkipNext2}
                    defaultOption={
                      option.isSkipNext2 === 2
                        ? "Yes"
                        : option.isSkipNext2 === ""
                        ? ""
                        : "No"
                    }
                    onChange={(option) =>
                      updateAnswerOption(option.value, index, "isSkipNext2")
                    }
                    readOnly={multiChoiceDisabled && !!index}
                  />
                </td>
                <td
                  className={`assessment__table-data ${
                    !index ? "pt-lg" : ""
                  } text-blue`}
                >
                  <button
                    onClick={() => actionHandler("add", index)}
                    className="assessment__action-btn"
                    disabled={
                      !state.type ||
                      multiChoiceDisabled ||
                      !allOptionsFilled(option)
                    }
                    title={!state.type ? "Select a choice" : ""}
                  >
                    + <span className="text-underline">Add</span>
                  </button>
                  &nbsp;
                  {index ? (
                    <button
                      onClick={() => actionHandler("remove", index)}
                      className="assessment__action-btn text-underline text-red cursor-pointer"
                      disabled={!state.type || multiChoiceDisabled}
                      title={!state.type ? "Select a choice" : ""}
                    >
                      Remove
                    </button>
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <ModalButton
          disabled={submitButtonDisabled()}
          onClick={() => submitQuestionHandler()}
          width="167px"
          isLoading={queryType === "create" ? isSubmitting : isEditing}
          text={"Save"}
          type={"submit"}
          testId={"button"}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  isGettingCategory: state.appointment.isGettingSingle,
  getCategorySuccess: state.appointment.getSuccess,
  category: state.appointment.singleCategory,
  isSubmitting: state.assessments.isSubmittingQuestion,
  submitSuccess: state.assessments.submitQuestionSuccess,
  isGettingQuestion: state.assessments.isGettingQuestion,
  isGettingQuestionSuccess: state.assessments.isGettingQuestionSuccess,
  fetchedQuestion: state.assessments.fetchedQuestion,
  isEditing: state.assessments.isEditing,
  isEditingSuccess: state.assessments.isEditingSuccess,
  isEditingFailure: state.assessments.isEditingFailure,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAppointmentCategory: (id) => dispatch(getSingleAppointmentCategory(id)),
  submitQuestion: (payload) => dispatch(createQuestion(payload)),
  fetchQuestion: (payload) => dispatch(fetchSingleQuestion(payload)),
  editQuestion: (payload) => dispatch(editSingleQuestion(payload)),
  resetQuestion: () => dispatch(clearQuestion()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentCategory);
