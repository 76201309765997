import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  changeProfilePassword,
  updateProfile,
} from "../../redux/Actions/ProfileActions";
import {
  getAdminDetails,
  getUserData,
} from "../../utils/functions/GetStorageItems";
import UpdateProfile from "../../components/Profile/UpdateProfile";
import ChangePassword from "../../components/Profile/ChangePassword";
import { clearStorage } from "../../utils/functions/ClearStorage";

const Profile = (props) => {
  const {
    changeProfilePassword,
    updateProfile,
    isChangingPassword,
    changePasswordSuccess,
    updateProfileSuccess,
    isUpdatingProfile,
  } = props;

  const { id } = getUserData();

  const { first_name, last_name, phoneNo, address, email, image } =
    getAdminDetails();

  const [state, setState] = useState({
    image: image ? image : "",
    edit: false,
    loaded: image ? true : false,
  });

  const activateEdit = () => {
    setState({
      ...state,
      edit: (state.edit = !state.edit),
    });
  };

  const initialValues1 = {
    first_name: first_name || "Nil",
    last_name: last_name || "Nil",
    email: email || "Nil",
    phone_number: phoneNo || "Nil",
    address: address || "Nil",
  };

  const initialValues2 = {
    old_password: "",
    new_password: "",
    confirm_password: "",
  };

  const submitValues = (values) => {
    const payload = {
      image_url: state.image,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      phone_number: values.phone_number,
      address: values.address,
    };
    updateProfile(id, payload);
  };

  const changePassword = (values) => {
    const payload = {
      oldPassword: values.old_password,
      newPassword: values.new_password,
    };
    changeProfilePassword(id, payload);
  };

  useEffect(
    () => {
      if (updateProfileSuccess) {
        window.location.reload();
      }
    },
    // eslint-disable-next-line
    [updateProfileSuccess]
  );

  useEffect(
    () => {
      if (changePasswordSuccess) {
        document.getElementById("changePasswordForm").reset();
        clearStorage();
      }
    },
    // eslint-disable-next-line
    [changePasswordSuccess, updateProfileSuccess]
  );

  return (
    <div className="user-profile">
      <UpdateProfile
        initialValues1={initialValues1}
        submitValues={submitValues}
        onClick={activateEdit}
        disabled={!state.edit}
        state={state}
        setState={setState}
        isLoading={isUpdatingProfile}
      />

      <ChangePassword
        initialValues2={initialValues2}
        submitValues={changePassword}
        isLoading={isChangingPassword}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isUpdatingProfile: state.profile.isUpdatingProfile,
  isChangingPassword: state.profile.isChangingPassword,
  changePasswordSuccess: state.profile.changePasswordSuccess,
  updateProfileSuccess: state.profile.updateProfileSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  changeProfilePassword: (id, payload) =>
    dispatch(changeProfilePassword(id, payload)),
  updateProfile: (id, payload) => dispatch(updateProfile(id, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
