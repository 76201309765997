import React, { useEffect } from "react";
import Modal, { ModalButton } from "../../Modal/Modal";
import { Field, Form, Formik } from "formik";
import Input from "../../Input/Input";
import { createReferral } from "../../../redux/Actions/ReferralActions";
import { connect } from "react-redux";
import { ReferralSchema } from "../../../utils/validation/validation";

const CreateReferral = (props) => {
  const {
    displayModal,
    closeModal,
    createReferral,
    isCreating,
    createSuccess,
  } = props;

  useEffect(
    () => {
      if (createSuccess) {
        document.getElementById("createReferralform").reset();
      }
      closeModal();
    },
    // eslint-disable-next-line
    [createSuccess]
  );

  const initialValues = {
    name: "",
    referred_amount: "",
    referrer_amount: "",
    duration_in_days: "",
  };

  const submitValues = (values) => {
    const payload = {
      name: values.name,
      referred_amount: values.receiver_reward,
      referrer_amount: values.sender_reward,
      duration_in_days: values.duration,
    };
    createReferral(payload);
  };

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Add New Referral Code"}
      width={"568px"}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => submitValues(values)}
        validationSchema={ReferralSchema}
      >
        {({ isValid, dirty, setFieldValue }) => {
          return (
            <Form id="createReferralform">
              <div className="form-grid3">
                <Field
                  name="name"
                  label="Name"
                  placeholder="Enter name"
                  component={Input}
                  emptyValue={setFieldValue}
                />
                <Field
                  type="number"
                  name="sender_reward"
                  label="Sender's Reward"
                  placeholder="Enter sender's reward"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>
              <div className="form-grid2">
                <Field
                  type="number"
                  name="receiver_reward"
                  label="Receiver's Reward"
                  placeholder="Enter receiver's reward"
                  component={Input}
                  emptyValue={setFieldValue}
                />
                <Field
                  type="number"
                  name="duration"
                  label="Duration"
                  placeholder="Enter duration"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>

              <ModalButton
                isLoading={isCreating}
                text={"Create"}
                type={"submit"}
                testId={"button"}
                loadingText={"Creating..."}
                disabled={isCreating || !(isValid && dirty)}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isCreating: state.referrals.isCreating,
  createSuccess: state.referrals.createSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  createReferral: (payload) => dispatch(createReferral(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateReferral);
