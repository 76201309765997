import React, { useEffect, useState } from "react";
import { PageButton } from "../../components/Button/Button";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import Add from "../../assets/add.svg";
import { cancelModal, viewModal } from "../../components/Modal/Modal";
import { connect } from "react-redux";
import { getSubscriptions } from "../../redux/Actions/SubscriptionsAction";
import CreateSubscription from "../../components/Subscriptions/CreateSubscription";
import SubscriptionsColumns from "../../components/Subscriptions/SubscriptionsColums";

const Subscriptions = (props) => {
  const [state, setState] = useState({
    createSubscriptionsModal: false,
  });
  const [planIds, setPlanIds] = useState([]);

  const {
    getSubscriptions,
    subscriptiondata,
    getSubscriptionsSuccess,
    isGetting,
  } = props;

  useEffect(() => {
    getSubscriptions();
  }, []);
  useEffect(() => {
    if (subscriptiondata) {
      // const planIdData = subscriptiondata.map((el) => el.plan_id);
      // const uniqueIds = [...new Set(planIdData)];
      // const sort = uniqueIds.sort(function (a, b) {
      //   return a - b;
      // });
      // setPlanIds(sort);
      console.log(subscriptiondata)
    }
  }, [subscriptiondata]);

  const sort = (array) => {
    const a = array.sort(function (a, b) {
      return b.id - a.id;
    });
    return a;
  };

  const allSubscriptionData = getSubscriptionsSuccess
    ? sort(subscriptiondata)
    : [
        {
          transaction_id: "",
          plan_id: "",
          amount: "",
          status: "",
        },
      ];

  const openModal = (type) => viewModal(type, state, setState);
  const closeModal = (type) => cancelModal(type, state, setState);
  return (
    <div>
      <HeaderWrapper
        title={"Subscriptions"}
        subtitle={"Create and Manage Subscriptions"}
        children={
          <PageButton onClick={() => openModal("createSubscriptionsModal")}>
            <div className="button-data">
              <img src={Add} alt="add-icon" />
              <span>Create Subscription</span>
            </div>
          </PageButton>
        }
      ></HeaderWrapper>
      <CreateSubscription
        displayModal={state.createSubscriptionsModal}
        closeModal={() => closeModal("createSubscriptionsModal")}
        getSubscriptions={getSubscriptions}
        planIds={planIds}
      />
      <SubscriptionsColumns
        data={allSubscriptionData}
        getData={getSubscriptions}
        success={getSubscriptionsSuccess}
        isLoading={isGetting}
        planIds={planIds}
      />
    </div>
  );
};
const mapStateToProps = (state) => ({
  subscriptiondata: state.subscriptions.subscriptiondata,
  getSubscriptionsSuccess: state.subscriptions.getSubscriptionsSuccess,
  isGetting: state.subscriptions.isGetting,
});
const mapDispatchToProps = (dispatch) => ({
  getSubscriptions: () => dispatch(getSubscriptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
