import React, { useEffect } from "react";
import { Form, Field, Formik } from "formik";
import Input from "../../Input/Input";
import { ModalButton } from "../../Modal/Modal";
import UploadImage from "../../Products/UploadImage";

import SelectInput from "../../Input/Select";
import ImportantText from "../../Wrappers/ImportantText";
import { v4 as uuid } from "uuid";
import update from "immutability-helper";
import { useCallback } from "react";

import CategorySubInputs from "./CategorySubInputs";

const CategoryInputs = (props) => {
  const {
    state,
    setState,
    uploadFile,
    isLoading,
    isUploading,
    iconState,
    setIconState,
    formId,
    actionType,
    initialValues,
    submitValues,
    schema,
    disabledFunc,
    statusValue,
    subcategoryState,
    setSubcategoryState,
    swapAppointmentSubcategoryRankHandler
  } = props;

  const addNewSubCategory = () => {
    setSubcategoryState(
      subcategoryState.concat([
        {
          name: "",
          image_url: "",
          description: "",
          direction: "",
          percentage_effectiveness: "",
          duration: "",
          hormones: "",
          amount: "",
          price_range: "",
          error: "",
          status: "",
          id: uuid(),
        },
      ])
    );
  };

  const hormoneOptions = [
    { value: true, label: "True" },
    { value: false, label: "False" },
  ];

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "InActive" },
  ];
  const updateSubcategory = (index, type) => (event) => {
    const newSubcategory = subcategoryState.map((category, idx) => {
      if (index !== idx) return category;
      return {
        ...category,
        [type]: event.target.value,
      };
    });

    setSubcategoryState(newSubcategory);
  };

  const updateHormoneSubcategory = (index, data) => {
    const newSubcategory = subcategoryState.map((category, idx) => {
      if (index !== idx) return category;
      return { ...category, hormones: data.value };
    });
    setSubcategoryState(newSubcategory);
  };
  const updateStatusSubcategory = (index, data) => {
    const newSubcategory = subcategoryState.map((category, idx) => {
      if (index !== idx) return category;
      return { ...category, status: data.value };
    });
    setSubcategoryState(newSubcategory);
  };

  const removeCategory = (id) => {
    const newSubcategory =
      subcategoryState &&
      subcategoryState.filter((item, index) => index !== id);
    setSubcategoryState(newSubcategory);
  };

  const assignUrl = (index, uploadedFile) => {
    const newSubcategory = subcategoryState.map((category, idx) => {
      if (index !== idx) return category;
      return { ...category, image_url: uploadedFile };
    });
    setSubcategoryState(newSubcategory);
  };

  const handleRemoval = (id) => {
    const newSubcategory = subcategoryState.map((category, idx) => {
      if (id !== idx) return category;
      return { ...category, image_url: "" };
    });
    setSubcategoryState(newSubcategory);
  };

  const disableScroll = (id) => {
    const input = document.getElementById(id);
    if (input) input.blur();
  };

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setSubcategoryState((prevSubCategory) =>
      update(prevSubCategory, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevSubCategory[dragIndex]],
        ],
      })
    );
  }, []);
  // console.log(subcategoryState)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema ? schema : ""}
      onSubmit={(values) => submitValues(values)}
    >
      {({ isValid, dirty, values, setFieldValue }) => {
        const disabled = disabledFunc(isValid, dirty, values);

        return (
          <Form id={formId}>
            <UploadImage
              uploadFile={uploadFile}
              id={3120}
              text={<ImportantText label="Add Category icon" />}
              type={"single"}
              isUploading={isUploading}
              state={iconState}
              setState={setIconState}
              changeText={"Change Category Icon"}
            />
            <div className="grid-4-one">
              <Field
                name="category_name"
                label={<ImportantText label="Category Name" />}
                placeholder="Enter category name"
                component={Input}
                emptyValue={setFieldValue}
              />

              <Field
                name="description"
                label={<ImportantText label="Description" />}
                placeholder="Enter description"
                component={Input}
                emptyValue={setFieldValue}
              />
              <SelectInput
                value={statusValue}
                label="Status"
                options={statusOptions}
                defaultOption={actionType === "edit" && statusValue}
                onChange={(data) => setState({ ...state, status: data.value })}
              />
              <Field
                name="rank"
                label={<ImportantText label="Rank" />}
                placeholder="Enter Rank"
                component={Input}
                emptyValue={setFieldValue}
                // value={subcategoryState.}
              />
            </div>

            <div className="appointment-subcategories">
              <div className="appointment-subcategories-header">
                Appointment Subcategories
              </div>
              <div
                className="payment-modal-add-more"
                style={{
                  textDecoration: "underline",
                  marginTop: "0px",
                }}
              >
                <span onClick={addNewSubCategory} data-testid="addSubcategory">
                  {" "}
                  + Add Subcategory
                </span>
              </div>
              <div className="appointment-subcategories-container">
                {subcategoryState &&
                  subcategoryState.map((category, index) => (
                    <CategorySubInputs
                      moveCard={moveCard}
                      category={category}
                      updateSubcategory={updateSubcategory}
                      index={index}
                      subcategoryState={subcategoryState}
                      setSubcategoryState={setSubcategoryState}
                      removeCategory={removeCategory}
                      isUploading={isUploading}
                      uploadFile={uploadFile}
                      handleRemoval={handleRemoval}
                      assignUrl={assignUrl}
                      actionType={actionType}
                      disableScroll={disableScroll}
                      hormoneOptions={hormoneOptions}
                      statusOptions={statusOptions}
                      id={category.id}
                      swapAppointmentSubcategoryRankHandler={swapAppointmentSubcategoryRankHandler}
                      updateStatusSubcategory={updateStatusSubcategory}
                      updateHormoneSubcategory={updateHormoneSubcategory}
                    />
                  ))}
              </div>
            </div>

            <ModalButton
              isLoading={isLoading}
              text={actionType === "add" ? "Add Category" : "Save Changes"}
              type={"submit"}
              testId={"button"}
              loadingText={actionType === "add" ? "Adding..." : "Saving..."}
              disabled={disabled}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default CategoryInputs;
