import moment from "moment";
import React from "react";
import { ErrorBtn, SuccessBtn } from "../../Tables/TableButtons";

export const productColumns = [
  {
    Header: "Date Added",
    accessor: "date",
    Cell: ({ row }) => (
      <div className="table-flex">
        <span>{moment(row.original.createdAt).format("L")}</span>
      </div>
    ),
  },
  {
    Header: "Time",
    accessor: "createdAt",
    Cell: ({ row }) => (
      <div className="table-flex">
        <span>{moment(row.original.createdAt).format("LT")}</span>
      </div>
    ),
  },
  {
    Header: "Action",
    accessor: "action",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ row }) =>
      row.original.status && row.original.status.toLowerCase() === "success" ? (
        <SuccessBtn text={"Successful"} />
      ) : (
        <ErrorBtn text={"Failed"} />
      ),
  },
];
