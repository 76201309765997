import React from "react";

const SwitchWrapper = (props) => {
  const {
    title1,
    title2,
    title3,
    title4,
    title5,
    title6,
    right1,
    right2,
    component1,
    component2,
    component3,
    component4,
    component5,
    component6,
    state,
    setstate,
    margin,
    paddingLeft,
    minWidth,
  } = props;

  const setActiveClass1 = () => {
    setstate({
      ...state,
      active1: true,
      active2: false,
      active3: false,
      active4: false,
      active5: false,
      active6: false,
      chatId: "",
      chat: "",
    });
  };

  const setActiveClass2 = () => {
    setstate({
      ...state,
      active1: false,
      active2: true,
      active3: false,
      active4: false,
      active5: false,
      active6: false,
      chatId: "",
      chat: "",
    });
  };

  const setActiveClass3 = () => {
    setstate({
      ...state,
      active1: false,
      active2: false,
      active3: true,
      active4: false,
      active5: false,
      active6: false,
      chatId: "",
      chat: "",
    });
  };

  const setActiveClass4 = () => {
    setstate({
      ...state,
      active1: false,
      active2: false,
      active3: false,
      active4: true,
      active5: false,
      active6: false,
      chatId: "",
      chat: "",
    });
  };

  const setActiveClass5 = () => {
    setstate({
      ...state,
      active1: false,
      active2: false,
      active3: false,
      active4: false,
      active5: true,
      active6: false,
      chatId: "",
      chat: "",
    });
  };
  const setActiveClass6 = () => {
    setstate({
      ...state,
      active1: false,
      active2: false,
      active3: false,
      active4: false,
      active5: false,
      active6: true,
      chatId: "",
      chat: "",
    });
  };

  return (
    <div className="switch-wrapper">
      <div className={`switch-wrapper-top ${paddingLeft}`}>
        <div className={`switch-wrapper-top-link ${minWidth}`}>
          {title1 && (
            <div
              className={
                state.active1
                  ? `switch-wrapper-top-link-two ${margin}`
                  : `switch-wrapper-top-link-one ${margin}`
              }
              data-testid="setActiveClass1"
              onClick={setActiveClass1}
            >
              {title1}
            </div>
          )}
          {title2 && (
            <div
              className={
                state.active2
                  ? `switch-wrapper-top-link-two ${margin}`
                  : `switch-wrapper-top-link-one ${margin}`
              }
              data-testid="setActiveClass2"
              onClick={setActiveClass2}
            >
              {title2}
            </div>
          )}
          {title3 && (
            <div
              className={
                state.active3
                  ? `switch-wrapper-top-link-two ${margin}`
                  : `switch-wrapper-top-link-one ${margin}`
              }
              data-testid="setActiveClass3"
              onClick={setActiveClass3}
            >
              {title3}
            </div>
          )}
          {title4 && (
            <div
              className={
                state.active4
                  ? `switch-wrapper-top-link-two ${margin}`
                  : `switch-wrapper-top-link-one ${margin}`
              }
              data-testid="setActiveClass4"
              onClick={setActiveClass4}
            >
              {title4}
            </div>
          )}
          {title5 && (
            <div
              className={
                state.active5
                  ? `switch-wrapper-top-link-two ${margin}`
                  : `switch-wrapper-top-link-one ${margin}`
              }
              data-testid="setActiveClass5"
              onClick={setActiveClass5}
            >
              {title5}
            </div>
          )}
          {title6 && (
            <div
              className={
                state.active6
                  ? `switch-wrapper-top-link-two ${margin}`
                  : `switch-wrapper-top-link-one ${margin}`
              }
              data-testid="setActiveClass6"
              onClick={setActiveClass6}
            >
              {title6}
            </div>
          )}
        </div>

        {state.active1 ? (
          <>
            <div className="switch-wrapper-top-right">{right1}</div>
          </>
        ) : (
          <>
            <div className="switch-wrapper-top-right">
              {right2 === "false" ? right1 : right2}
            </div>
          </>
        )}
      </div>
      {state.active1 ? (
        <div>{component1}</div>
      ) : state.active2 ? (
        <div>{component2}</div>
      ) : state.active3 ? (
        <div>{component3}</div>
      ) : state.active4 ? (
        <div>{component4}</div>
      ) : state.active5 ? (
        <div>{component5}</div>
      ) : (
        <div>{component6}</div>
      )}
    </div>
  );
};

export default SwitchWrapper;
