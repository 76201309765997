import React from "react";
import Skeleton from "react-loading-skeleton";

const PaymentPlansLoader = () => {
  return (
    <div>
      <div className="overview-figures2">
        <Skeleton height={"96px"} width={"100%"} />
        <Skeleton height={"96px"} width={"100%"} />
        <Skeleton height={"96px"} width={"100%"} />
        <Skeleton height={"96px"} width={"100%"} />
        <Skeleton height={"96px"} width={"100%"} />
      </div>

      <div className="top-spacer">
        <div className="payment-plans">
          <div className="payment-grid">
            <Skeleton height={"500px"} width={"100%"} />
            <Skeleton height={"500px"} width={"100%"} />
            <Skeleton height={"500px"} width={"100%"} />
          </div>
        </div>
      </div>

      <div
        className="payment-table"
        style={{ padding: 0, border: "none", marginTop: "20px" }}
      >
        <Skeleton height={"40px"} width={"100%"} count={6} />
      </div>
    </div>
  );
};

export default PaymentPlansLoader;
