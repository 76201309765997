import React, { useState } from "react";
import { TableDropdown, DropBtn } from "../../Tables/TableButtons";
import { useHistory } from "react-router-dom";

export default function useOverviewColumns() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [id, setId] = useState();
  const { push } = useHistory();

  
  const options = [
    {
      name: "View",
      action: (id) => {
        setShowDropdown(false);
        push({
          pathname: `/order-management/${id}`,
          state: { id },
        });
      },
    },
  ];

  const toggleHandler = (id) => {
    setShowDropdown((showDropdown) => !showDropdown);
    setId(id);
  };

  const columns = [
    {
      Header: "Placed",
      accessor: "date",
      Cell: ({ row }) => (
        <span className="table-shrink">
          {new Date(row.original.created_at).toLocaleDateString()}
        </span>
      ),
    },
    {
      Header: "Order No.",
      accessor: "order_no",
      Cell: ({ row }) => (
        <span className="table-shrink">{row.original.order_number}</span>
      ),
    },
    {
      Header: "Customer Name",
      accessor: "full_name",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <span style={{ textTransform: "capitalize" }}>
            {row.original?.users?.first_name && row.original?.users?.last_name
              ? `${row.original?.users?.first_name} ${row.original?.users?.last_name}`
              : "Nil"}
          </span>
        </div>
      ),
    },
    {
      Header: "Price",
      accessor: "price",
      Cell: ({ row }) => (
        <span className="table-shrink">NGN {row.original.total_item_price}</span>
      ),
    },
    {
      Header: "",
      accessor: "action",
      Cell: ({ row }) => (
        <span className="action-flex">
          <DropBtn onClick={() => toggleHandler(row.original.id)} />
          {showDropdown && id === row.original.id && (
            <TableDropdown
              hideDropdown={() => setShowDropdown(false)}
              data={row.original.id}
              options={options}
            />
          )}
        </span>
      ),
    },
  ];

  return { columns };
}
