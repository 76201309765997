import React from "react";
import AsyncSelect from "react-select/async";
import axios from "axios";
import Storage from "../../utils/services/storage";
import responseHandler from "../../utils/notifications/responseHandler";
import { useEffect } from "react";
import SelectInput from "../Input/Select";
import CancelIcon from "../../assets/plus.svg";

const EnterpriseFacilityInput = (props) => {
  const adminToken = Storage.get("admin-access-token");
  const { state, setState, edit } = props;
  const loadFacilities = (inputValue, callback) => {
    const url = `${
      process.env.REACT_APP_WHISPA_ADMIN_KEY
    }/facility?page=${1}&limit=${10}&search=${inputValue}`;
    axios
      .get(url, {
        headers: {
          "Content-type": "application/json",
          Authorization: adminToken,
        },
      })
      .then((res) => {
        const tempArr = [];
        const data = res?.data?.data?.rows;
        data.forEach((element) => {
          tempArr.push({ label: element.name, value: element.id });
        });
        callback(tempArr);
      })
      .catch((err) => responseHandler(err, "Failed!"));
  };

  const updateFacilityField = (index, data, stateVal) => {
    const newFacility = state.enterprise_facilities.map((facility, idx) => {
      if (index !== idx) return facility;
      return { ...facility, [stateVal]: data };
    });
    setState({ ...state, enterprise_facilities: newFacility });
  };

  const options = [
    { label: "True", value: true },
    { label: "False", value: false },
  ];

  const removeCategory = (id) => {
    const newFacility =
      state.enterprise_facilities &&
      state.enterprise_facilities.filter((item, index) => index !== id);
    setState({ ...state, enterprise_facilities: newFacility });
  };

  return (
    <React.Fragment>
      {state.enterprise_facilities &&
        state.enterprise_facilities.map((facility, index) => (
          <div
            key={index}
            className="top-spacer"
            style={{ position: "relative", zIndex: 1000 }}
          >
            {(index > 0 || state.enterprise_facilities.length > 1) && (
              <div
                className="appointment-subcategories-grid-delete"
                data-testid={`deleteSlot${index}`}
                onClick={() => removeCategory(index)}
              >
                <img src={CancelIcon} alt="cancel icon" />
              </div>
            )}
            <div className="grid-2-one">
              {facility.facility?.name ? (
                <AsyncSelect
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      padding: ".5rem .5rem",
                      cursor: "pointer",
                    }),
                  }}
                  loadOptions={loadFacilities}
                  placeholder="Enter Facility Name"
                  onChange={(val) => {
                    updateFacilityField(index, val.value, "facility_id");
                  }}
                  defaultInputValue={facility.facility?.name}
                />
              ) : (
                <AsyncSelect
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      padding: ".5rem .5rem",
                      cursor: "pointer",
                    }),
                  }}
                  loadOptions={loadFacilities}
                  placeholder="Enter Facilit Name"
                  onChange={(val) => {
                    updateFacilityField(index, val.value, "facility_id");
                  }}
                />
              )}
              <SelectInput
                label={"default"}
                options={options}
                onChange={(data) =>
                  updateFacilityField(index, data.value, "default")
                }
                defaultOption={edit && facility?.default.toString()}
              />
            </div>
            {/* <div className=""></div> */}
          </div>
        ))}
    </React.Fragment>
  );
};

export default EnterpriseFacilityInput;
