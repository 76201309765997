import React, { useState } from "react";
import Modal, { ModalButton } from "../Modal/Modal";
import { Field, Form, Formik } from "formik";
import { AddRoleSchema } from "../../utils/validation/validation";
import Input from "../Input/Input";
import { adminPermissions } from "./Permissions"; 
import { permissionPayload } from "../../utils/functions/CreateNewArray";
import MultiSelect from "../Input/MultiSelect";

const CreateRole = (props) => {
  const { displayModal, closeModal, isCreating, createRole } = props;

  const [checkedItems, setCheckedItems] = useState(adminPermissions);

  const permissionsPayload = checkedItems && permissionPayload(checkedItems);

  const selectedCategories =
    checkedItems && checkedItems.filter((item) => item.checked === true);

  const submitValues = (values) => {
    const payload = {
      name: values.name,
      description: values.description,
      status: "Active",
      ...permissionsPayload,
    };
    createRole(payload);
  };

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Create Role"}
      width={"568px"}
    >
      <Formik
        initialValues={{
          name: "",
          description: "",
        }}
        validationSchema={AddRoleSchema}
        onSubmit={(values) => submitValues(values)}
      >
        {({ isValid, dirty, setFieldValue }) => {
          return (
            <Form id="createRole">
              <Field
                name="name"
                label="Role Name"
                placeholder="Enter role name"
                component={Input}
                emptyValue={setFieldValue}
              />

              <div className="form-grid">
                <Field
                  name="description"
                  label="Description"
                  placeholder="Enter description"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>

              <br />
              <br />
              <div className="top-spacer">
                <MultiSelect
                  className={"checkboxes-title"}
                  rowNo={2}
                  options={checkedItems}
                  checkedItems={checkedItems}
                  setCheckedItems={setCheckedItems}
                  title={"Admin Permissions"}
                  isGetting={false}
                  getSuccess={true}
                />
              </div>

              <ModalButton
                isLoading={isCreating}
                text={"Create Role"}
                type={"submit"}
                testId={"button"}
                loadingText={"Creating..."}
                disabled={
                  isCreating ||
                  !(isValid && dirty) ||
                  (selectedCategories && selectedCategories.length === 0)
                }
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CreateRole;
