import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/auth-logo.svg";
import CompleteProfile from "../../pages/Onboarding/CompleteProfile";
import AddAdmin from "../../pages/Onboarding/AddAdmin";
import BackBtn from "../Input/BackBtn";
import { getUserData } from "../../utils/functions/GetStorageItems";
import OtherAdmins from "../Onboarding/OtherAdmins";
import { getAdmins } from "../../redux/Actions/AdminActions";
import { connect } from "react-redux";

const OnboardingWrapper = (props) => {
  const { getAdmins } = props;
  const { role } = getUserData();

  const [state, setstate] = useState({
    active1: true,
    active2: false,
    openAddForm: false,
  });

  const setActiveClass1 = () => {
    setstate({
      ...state,
      active1: true,
      active2: false,
    });
  };

  const setActiveClass2 = () => {
    setstate({
      ...state,
      active1: false,
      active2: true,
    });
  };

  const goToPrevious = () => {
    setstate({
      ...state,
      active1: false,
      active2: true,
      openAddForm: false,
    });
  };

  useEffect(
    () => {
      role === "Super Admin" && getAdmins(1, 5);
    },
    // eslint-disable-next-line
    []
  );

  return (
    <div className="container3">
      {role === "Super Admin" && (
        <div className="container3-right">
          {state.openAddForm && state.active2 ? (
            <BackBtn onClick={goToPrevious} />
          ) : (
            ""
          )}
          <div
            className={
              state.active1 ? "container3-right-top" : "container3-right-bottom"
            }
            data-testid="setActiveClass1"
            onClick={setActiveClass1}
          >
            <h4>Super Admin</h4>
            <h6>Complete your personal profile</h6>
          </div>
          <div className="container3-right-hr"></div>
          <div
            className={
              state.active2 ? "container3-right-top" : "container3-right-bottom"
            }
            data-testid="setActiveClass2"
            onClick={setActiveClass2}
          >
            <h4>Other Admins</h4>
            <h6>Invite and add new admins</h6>
          </div>
        </div>
      )}
      <div className="container3-left">
        <div
          className={
            state.active2 && !state.openAddForm ? "auth-form3" : "auth-form2"
          }
        >
          <Link to="/">
            <div className="brand-logo">
              <img src={Logo} alt="logo" />
            </div>
          </Link>
          {state.active1 ? (
            <div>
              <CompleteProfile />
            </div>
          ) : state.openAddForm && state.active2 ? (
            <AddAdmin state={state} setstate={setstate} />
          ) : (
            <OtherAdmins state={state} setstate={setstate} />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isFetching: state.admin.isFetching,
  getAdminsSuccess: state.admin.getAdminsSuccess,
  admins: state.admin.admins,
  adminsData: state.admin.adminsData,
  editAdminSuccess: state.admin.editAdminSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getAdmins: (page, limit) => dispatch(getAdmins(page, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingWrapper);
