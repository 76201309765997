import React, { useEffect } from "react";
import { Field, Form, Formik } from "formik";
import Modal, { ModalButton } from "../../Modal/Modal";
import Input from "../../Input/Input";
import {
  editProductCategory,
  getProductCategories,
} from "../../../redux/Actions/ProductCategoryAction";
import { connect } from "react-redux";

const EditCategory = (props) => {
  const {
    displayModal,
    closeModal,
    isEditing,
    editCategory,
    editSuccess,
    data,
    getCategories,
  } = props;

  const id = data && data.id;

  const initialValues = {
    name: data && data.name,
    description: data && data.description,
  };

  const submitValues = (values) => {
    const payload = {
      category_id: id,
      name: values.name,
      description: values.description,
    };
    editCategory(payload);
  };

  useEffect(
    () => {
      if (editSuccess) {
        closeModal();
        getCategories(1, 10);
      }
    },
    // eslint-disable-next-line
    [editSuccess]
  );

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Edit Category"}
      width={"568px"}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => submitValues(values)}
      >
        {({ isValid, dirty, setFieldValue, values }) => {
          const disabled =
            values.description === "" || values.name === "" || isEditing;
          return (
            <Form id="editCategory">
              <div className="form-grid3">
                <Field
                  name="name"
                  label="Category name"
                  placeholder="Enter category name"
                  component={Input}
                  emptyValue={setFieldValue}
                />

                <Field
                  name="description"
                  label="Description"
                  placeholder="Enter description"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>

              <ModalButton
                isLoading={isEditing}
                text={"Save Changes"}
                type={"submit"}
                testId={"button"}
                loadingText={"Editing..."}
                disabled={disabled}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isEditing: state.productcategory.isEditing,
  editSuccess: state.productcategory.editSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  editCategory: (id, payload) => dispatch(editProductCategory(id, payload)),
  getCategories: (page, limit) => dispatch(getProductCategories(page, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCategory);
