import React, { useEffect, useState, useRef } from "react";
import AllChats from "../../components/ChatManagement/AllChats";
import SwitchWrapper from "../../components/Wrappers/SwitchWrapper";
import ChatList from "../../components/ChatManagement/ChatList";
import { PageButton } from "../../components/Button/Button";
import TransferUser from "./TransferUser";
import { cancelModal, viewModal } from "../../components/Modal/Modal";
import { connect } from "react-redux";
import {
  filterChatStats,
  filterChatsByStatus,
  getChatStats,
  getChatsByStatus,
  searchChats,
} from "../../redux/Actions/ChatActions";
import ClosedChatRoom from "./ClosedChatRoom";
import PendingChatRoom from "./PendingChatRoom";
import ActiveChatRoom from "./ActiveChatRoom";
import SingleClientChat from "../../components/ChatManagement/SingleClientChat";
import Figures2 from "../../components/Overview/Figures2";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import Export2 from "../../components/Overview/Exports2";
import html2pdf from "html2pdf.js";
import downloadCSV from "../../utils/functions/csvDownloader";
import { getOverview } from "../../redux/Actions/OverviewActions";
import Storage from "../../utils/services/storage";
import axios from "axios";
import RefreshSvg from "../../assets/refreshing.svg";
import FilterChat from "../../components/ChatManagement/FilterChat";

const Chat = (props) => {
  const {
    isGetting,
    chats,
    getSuccess,
    isSearching,
    searchedChats,
    searchSuccess,
    getChats,
    searchChats,
    fetchChatsOverview,
    overviewChatsData,
    filterChatsHandler,
    isFilteringChatStats,
    filterChatsSuccess,
    getChatStatsSuccess,
    getChatsStatsHandler,
    filterChatRooms,
    chatStats,
    filterChatRoomsStart,
    filterChatRoomSuccess,
  } = props;

  const [state, setState] = useState({
    searchValue: "",
    active1: true,
    active2: false,
    active3: false,
    chatId: "",
    chat: "",
    transferUser: false,
    specialist: "",
    status: "Whispa Doctor",
    active: true,
    filterModal: false,
    filterValues: {
      created_from: "",
      created_to: "",
    },
    index: 0,
  });

  // Newly added for the chat distribution
  const adminToken = Storage.get("admin-access-token");
  let date = new Date();
  let firstDayMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const [chatStatistics, setChatStatistics] = useState(null);
  const [minDate, setMinDate] = useState(firstDayMonth.toISOString());
  const [maxDate, setMaxDate] = useState(new Date().toISOString());

  const [allChats, setAllChats] = useState([]);
  const [figuresData, setFiguresData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [chatStatsData, setChatStatData] = useState({});

  const openModal = (type) => viewModal(type, state, setState);
  const closeModal = (type) => cancelModal(type, state, setState);

  const showSingleChat = (chat) => {
    setState({
      ...state,
      chatId: chat && chat.id,
      chat: chat,
    });
  };

  const searchAllChats = () => {
    let status = state.active1
      ? "active"
      : state.active2
      ? "pending"
      : "closed";
    if (state.searchValue === "") {
      getChats(status);
    } else {
      searchChats(status, state.searchValue);
    }
  };

  const getAllChats = () => {
    let status = state.active1
      ? "active"
      : state.active2
      ? "pending"
      : "closed";
    getChats(status);
  };

  const getChatStatistics = async (date1, date2, setChatState) => {
    let url;
    if (date1 && date2) {
      url = `${process.env.REACT_APP_WHISPA_ADMIN_KEY}/chat/stats?created-from=${date1}&created-to=${date2}`;
    } else {
      url = `${process.env.REACT_APP_WHISPA_ADMIN_KEY}/chat/stats`;
    }

    try {
      await axios
        .get(url, {
          headers: {
            "Content-type": "application/json",
            Authorization: adminToken,
          },
        })
        .then((res) => {
          if (res?.data?.data) {
            setChatStatistics(res?.data?.data);
          } else {
            setChatStatistics(null);
          }
        });
    } catch (error) {
      console.log("err", error);
    }
  };

  useEffect(() => {
    getChatsStatsHandler();
  }, []);

  useEffect(() => {
    if (getSuccess) {
      setState({
        ...state,
        searchValue: "",
      });
      setAllChats(chats);
    }

    // Make call to API go get the chat statistics
    let data = {
      total_user: {
        total: 0,
      },
      total_appointment: {
        total: 0,
      },
      total_product: {
        total: 0,
      },
      total_chat: {
        total: 0,
      },
    };
    setFiguresData(data);

    // Get Chat Statistics whenever the minDate, maxDate changes
    getChatStatistics("", "", setChatStatistics);
  }, [getSuccess]);

  useEffect(() => {
    getChatStatistics(minDate, maxDate, setChatStatistics);
    setRefresh(true);
  }, [minDate, maxDate]);

  useEffect(() => {
    setChatStatData(chatStats);
  }, [filterChatsSuccess, getChatStatsSuccess]);
  const chatsData = searchSuccess ? searchedChats : chats;

  const wrapperRef = useRef();
  const [showDate, setShowDate] = useState(false);
  const filterOverviewHandler = (queries, type) => {
    fetchChatsOverview({ ...queries, type });
  };

  const exportHandler = (format) => {
    switch (format) {
      case "PDF":
        var opt = {
          filename: `overview-${Date.now()}.pdf`,
          margin: 10,
          html2canvas: { scale: 2 },
          jsPDF: { format: "letter", orientation: "landscape" },
        };
        html2pdf().set(opt).from(wrapperRef.current).save();
        return;
      case "CSV":
        const order = overviewChatsData.order.map((order) => ({
          Placed: new Date(order.created_at).toLocaleDateString(),
          "Order Number": order.order_number,
          Price: `NGN${order.total_item_price}`,
          Status: order.status,
        }));
        downloadCSV(order, "recent-orders");
        return;
      default:
        return;
    }
  };

  return (
    <div>
      {/* Chat Statistics  */}
      <div style={{ padding: "40px 30px" }}>
        {/* Title of the page.  */}
        <HeaderWrapper
          title={"User Chats"}
          subtitle={"View all chats and chat statistics on whispa"}
          className={"overview-header-right"}
        >
          <div style={{ display: "flex", alignItems: "center" }} className="">
            {filterChatsSuccess ? (
              <img
                src={RefreshSvg}
                alt="refresh"
                style={{ marginRight: "2rem" }}
                onClick={() => {
                  getChatsStatsHandler();
                  setState({
                    ...state,
                    filterValues: {
                      created_from: "",
                      created_to: "",
                    },
                  });
                }}
              />
            ) : null}
            <div className="overview-header-right-button">
              <PageButton
                width="auto"
                background={"rgba(10, 116, 220, 0.08)"}
                marginRight={"10px"}
                onClick={() => openModal("filterModal")}
                type={"button"}
              >
                <div className="button-data">
                  <span>Filter</span>
                </div>
              </PageButton>
            </div>
          </div>
        </HeaderWrapper>

        {/* Statistics boxes  */}
        {chatStatsData && <Figures2 chatStatistics={chatStatsData} />}
      </div>

      {/* All Chats  */}
      <AllChats
        chats={allChats}
        main={true}
        leftComponent={
          <ChatList
            // subtitle={`View all ${state.active1 ? "active" : state.active2 ? "pending" : "closed"
            //   } chats on Whispa`}
            setState={setState}
            state={state}
            searchItems={() => searchAllChats()}
            sortSuccess={searchSuccess}
            refreshFunction={getAllChats}
            switchWrapper={
              <SwitchWrapper
                title1={"Pending"}
                title2={"Active"}
                title3={"Closed"}
                state={state}
                setstate={setState}
                component1={
                  <PendingChatRoom
                    isLoading={isGetting || isSearching || filterChatRoomsStart}
                    data={chatsData}
                    setState={setState}
                    state={state}
                    showSingleChat={showSingleChat}
                    getChats={getChats}
                    success={getSuccess || searchSuccess}
                    sortSuccess={searchSuccess}
                    status={"pending"}
                    openModal={openModal}
                    filterChatRooms={filterChatRooms}
                    filterSuccess={filterChatsSuccess}
                    type="chat"
                  />
                }
                component2={
                  <ActiveChatRoom
                    isLoading={isGetting || isSearching || filterChatRoomsStart}
                    data={chatsData}
                    setState={setState}
                    state={state}
                    showSingleChat={showSingleChat}
                    getChats={getChats}
                    success={getSuccess || searchSuccess}
                    sortSuccess={searchSuccess}
                    status={"active"}
                    type="chat"
                    filterChatRooms={filterChatRooms}
                    filterSuccess={filterChatsSuccess}
                  />
                }
                component3={
                  <ClosedChatRoom
                    isLoading={isGetting || isSearching || filterChatRoomsStart}
                    data={chatsData}
                    setState={setState}
                    state={state}
                    showSingleChat={showSingleChat}
                    getChats={getChats}
                    success={getSuccess || searchSuccess}
                    sortSuccess={searchSuccess}
                    status={"closed"}
                    type="chat"
                    filterChatRooms={filterChatRooms}
                    filterSuccess={filterChatsSuccess}
                  />
                }
                margin={"chats-margin"}
                paddingLeft={"chats-padding"}
                minWidth={"chats-minWidth"}
              />
            }
          />
        }
        rightComponent={
          <>
            <SingleClientChat
              chat={state.chat}
              className={"chats-right-web"}
              chats={chats}
              component={
                !state.active3 && (
                  <button
                    className="custom-transfer-user-button"
                    onClick={() => openModal("transferUser")}
                  >
                    Transfer
                  </button>
                )
              }
              state={state}
            />

            <TransferUser
              displayModal={state.transferUser}
              closeModal={() => closeModal("transferUser")}
              setState={setState}
              state={state}
              id={state.chatId}
              getChats={getChats}
            />
          </>
        }
      ></AllChats>
      <FilterChat
        displayModal={state.filterModal}
        closeModal={() => closeModal("filterModal")}
        filterChatStats={filterChatsHandler}
        isFiltering={isFilteringChatStats}
        filterSuccess={filterChatsSuccess}
        prevstate={state}
        setPrevState={setState}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGetting: state.chats.isGetting,
  chats: state.chats.chats,
  getSuccess: state.chats.getSuccess,
  isSearching: state.chats.isSearching,
  searchedChats: state.chats.searchedChats,
  searchSuccess: state.chats.searchSuccess,
  overviewChatsData: state.overview.overviewData,
  isFilteringChatStats: state.chats.isFilteringChatStats,
  filterChatsStart: state.chats.filterChatsStart,
  filterChatsSuccess: state.chats.filterChatsSuccess,
  getChatStatsSuccess: state.chats.getChatStatsSuccess,
  chatStats: state.chats.statsData,
  filterChatRoomSuccess: state.chats.filterChatRoomsSuccess,
  filterChatRoomsStart: state.chats.filterChatRoomsStart,
});

const mapDispatchToProps = (dispatch) => ({
  getChats: (status) => dispatch(getChatsByStatus(status)),
  getChatsStatsHandler: () => dispatch(getChatStats()),
  searchChats: (status, searchValue) =>
    dispatch(searchChats(status, searchValue)),
  fetchChatsOverview: (payload) => dispatch(getOverview(payload)),
  filterChatRooms: (status, created_from, created_to) =>
    dispatch(filterChatsByStatus(status, created_from, created_to)),
  filterChatsHandler: (date1, date2) => dispatch(filterChatStats(date1, date2)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
