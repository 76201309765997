import Actions from "../Types";

const initialState = {
  isGetting: false,
  getSuccess: false,
  coupons: [],
  couponsData: {},

  isCreating: false,
  createSuccess: false,
  coupon: {},

  isEditing: false,
  editSuccess: false,

  isChanging: false,
  changeSuccess: false,

  isDeleting: false,
  deleteSuccess: false,

  isSearching: false,
  searchSuccess: false,
  searchedCoupons: [],
  searchedCouponsData: {},

  isFiltering: false,
  filterSuccess: false,
  filteredCoupons: [],
  filteredCouponsData: {},

  isFilteringCoupons: false,
  filterSuccessCoupons: false,
  filteredCoupon: [],
  filteredCouponData: {},

  singleCouponData: {},
  isGettingSingleCoupon: false,
  getSingleCouponSuccess: false,

  error: null,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_COUPONS_START:
      return {
        ...state,
        isGetting: true,
        getSuccess: false,
        coupons: [],
        error: null,
      };
    case Actions.GET_COUPONS_SUCCESS:
      return {
        ...state,
        isGetting: false,
        getSuccess: true,
        changeSuccess: false,
        createSuccess: false,
        deleteSuccess: false,
        searchSuccess: false,
        filterSuccess: false,
        filterSuccessCoupons: false,
        editSuccess: false,
        coupons: action.payload && action.payload.coupons,
        couponsData: action.payload,
        error: null,
      };
    case Actions.GET_COUPONS_FAILURE:
      return {
        ...state,
        isGetting: false,
        getSuccess: false,
        coupons: [],
        couponsData: {},
        error: action.payload,
      };
    case Actions.GET_SINGLE_COUPON_START:
      return {
        ...state,
        isGettingSingleCoupon: true,
        getSingleCouponSuccess: false,
      };
    case Actions.GET_SINGLE_COUPON_SUCCESS:
      return {
        ...state,
        isGettingSingleCoupon: false,
        getSingleCouponSuccess: true,
        singleCouponData: action.payload,
      };
    case Actions.SEARCH_COUPONS_START:
      return {
        ...state,
        isSearching: true,
        searchSuccess: false,
        searchedCoupons: [],
        searchedCouponsData: {},
        error: null,
      };
    case Actions.SEARCH_COUPONS_SUCCESS:
      return {
        ...state,
        isSearching: false,
        searchSuccess: true,
        searchedCoupons: action.payload && action.payload.coupons,
        searchedCouponsData: action.payload,
        error: null,
      };
    case Actions.SEARCH_COUPONS_FAILURE:
      return {
        ...state,
        isSearching: false,
        searchSuccess: false,
        searchedCoupons: [],
        searchedCouponsData: {},
        error: action.payload,
      };
    case Actions.FILTER_REFERRAL_COUPON_START:
      return {
        ...state,
        isFiltering: true,
        filterSuccess: false,
        filteredCoupons: [],
        filteredCouponsData: {},
        error: null,
      };
    case Actions.FILTER_REFERRAL_COUPON_SUCCESS:
      return {
        ...state,
        isFiltering: false,
        filterSuccess: true,
        filteredCoupons: action.payload && action.payload.coupons,
        filteredCouponsData: action.payload,
        error: null,
      };
    case Actions.FILTER_REFERRAL_COUPON_FAILURE:
      return {
        ...state,
        isFiltering: false,
        filterSuccess: false,
        filteredCoupons: [],
        filteredCouponsData: {},
        error: action.payload,
      };
    case Actions.FILTER_COUPONS_START:
      return {
        ...state,
        isFilteringCoupons: true,
        filterSuccessCoupons: false,
        filteredCoupon: [],
        filteredCouponData: {},
        error: null,
      };
    case Actions.FILTER_COUPONS_SUCCESS:
      return {
        ...state,
        isFilteringCoupons: false,
        filterSuccessCoupons: true,
        filteredCoupon: action.payload && action.payload.subscribers,
        filteredCouponData: action.payload,
        error: null,
      };
    case Actions.FILTER_COUPONS_FAILURE:
      return {
        ...state,
        isFilteringCoupons: false,
        filterSuccessCoupons: false,
        filteredCoupon: [],
        filteredCouponData: {},
        error: action.payload,
      };
    case Actions.CREATE_COUPON_START:
      return {
        ...state,
        isCreating: true,
        createSuccess: false,
        error: null,
      };
    case Actions.CREATE_COUPON_SUCCESS:
      return {
        ...state,
        isCreating: false,
        createSuccess: true,
        coupon: action.payload,
        error: null,
      };
    case Actions.CREATE_COUPON_FAILURE:
      return {
        ...state,
        isCreating: false,
        createSuccess: false,
        error: action.payload,
      };
    case Actions.EDIT_COUPON_START:
      return {
        ...state,
        isEditing: true,
        editSuccess: false,
        coupon: {},
        error: null,
      };
    case Actions.EDIT_COUPON_SUCCESS:
      return {
        ...state,
        isEditing: false,
        editSuccess: true,
        coupon: action.payload,
        error: null,
      };
    case Actions.EDIT_COUPON_FAILURE:
      return {
        ...state,
        isEditing: false,
        editSuccess: false,
        coupon: null,
        error: action.payload,
      };
    case Actions.CHANGE_COUPON_STATUS_START:
      return {
        ...state,
        isChanging: true,
        changeSuccess: false,
        coupon: {},
        error: null,
      };
    case Actions.CHANGE_COUPON_STATUS_SUCCESS:
      return {
        ...state,
        isChanging: false,
        changeSuccess: true,
        coupon: action.payload,
        error: null,
      };
    case Actions.CHANGE_COUPON_STATUS_FAILURE:
      return {
        ...state,
        isChanging: false,
        changeSuccess: false,
        coupon: null,
        error: action.payload,
      };
    case Actions.DELETE_COUPON_START:
      return {
        ...state,
        isDeleting: true,
        deleteSuccess: false,
        coupon: {},
        error: null,
      };
    case Actions.DELETE_COUPON_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: true,
        coupon: action.payload,
        error: null,
      };
    case Actions.DELETE_COUPON_FAILURE:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: false,
        coupon: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reduxState;
