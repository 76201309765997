import responseHandler from "../../utils/notifications/responseHandler";
import Instance from "../../utils/services/api";
import Storage from "../../utils/services/storage";
import Actions from "../Types";

export const getSubscriptionsStart = () => ({
  type: Actions.GET_SUBSCRIPTIONS_START,
});

export const getSubscriptionsSuccess = (payload) => ({
  type: Actions.GET_SUBSCRIPTIONS_SUCCESS,
  payload,
});

export const createSubscriptionStart = () => ({
  type: Actions.CREATE_SUBSCRIPTIONS_START,
});

export const createSubscriptionSuccess = () => ({
  type: Actions.CREATE_SUBSCRIPTIONS_SUCCESS,
});

export const createSubscriptionFailure = () => ({
  type: Actions.CREATE_SUBSCRIPTIONS_FAILURE,
});

export const editSubscriptionStart = () => ({
  type: Actions.EDIT_SUBSCRIPTIONS_START,
});

export const editSubscriptionSuccess = (payload) => ({
  type: Actions.EDIT_SUBSCRIPTIONS_SUCCESS,
  payload,
});
export const editSubscriptionFailure = () => ({
  type: Actions.EDIT_SUBSCRIPTIONS_FAILURE,
});

export const getSubscriptions = () => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  dispatch(getSubscriptionsStart());
  API.get(`/subscription-payment`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getSubscriptionsSuccess(data?.data));
    }
  });
};

export const createSubscription = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  const createSubData = JSON.stringify(payload);

  dispatch(createSubscriptionStart());
  console.log(payload)
  API.post(`/subscription-payment`, payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      responseHandler(response, "Subscription Successfully Created");
      dispatch(createSubscriptionSuccess());
    } else {
      dispatch(createSubscriptionFailure());
      responseHandler(response, "Failed!");
    }
    console.log(data);
  });
};

export const editSubscription = (payload, id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  dispatch(editSubscriptionStart());
  console.log(payload, id);
  API.patch(`/subscription-payment/${id}`, payload).then(
    (response) => {
      const { data, ok } = response;
      if (ok) {
        console.log("edited");
        dispatch(editSubscriptionSuccess(data));
        responseHandler(response, "Subscription Successfully Created");
      } else {
        dispatch(editSubscriptionFailure());
        responseHandler(response, "Failed");
      }
    }
  );
};
