const Links = [
  {
    label: "",
    items: [
      {
        name: "/overview",
        label: "Overview",
        icon: "bar-chart-2",
        role: "can_manage_overview",
      },
    ],
  },
  {
    label: "Main Management",
    items: [
      {
        name: "/appointment-manager",
        label: "Appointment Management",
        icon: "calendar",
        role: "can_manage_appointment",
      },
      {
        name: "/products",
        label: "Product Management",
        icon: "plus-circle",
        role: "can_manage_product",
      },
      {
        name: "/blog-management",
        label: "Blog Management",
        icon: "book-open",
        role: "can_manage_blog",
      },
      {
        name: "/facility",
        label: "Facility Management",
        icon: "map",
        role: "can_manage_facility",
      },
      {
        name: "/administrators",
        label: "Administrators",
        icon: 'user',
        role: "can_manage_administrators",
      },
      {
        name: "/enterprise",
        label: "Enterprise",
        icon: 'briefcase',
        role: "can_manage_overview",
      },
      {
        name: "/app-versions",
        label: "App Versions",
        icon: 'smartphone',
        role: "can_manage_administrators",
      },

    ],
  },
  {
    label: "Users Management",
    items: [
      {
        name: "/users",
        label: "Users",
        icon: "users",
        role: "can_manage_users",
      },
      {
        name: "/order-management",
        label: "User Orders",
        icon: "shopping-cart",
        role: "can_manage_orders",
      },
      {
        name: "/appointment",
        label: "User Appointment",
        icon: "calendar",
        role: "can_manage_user_appointment",
      },
      {
        name: "/chat-management",
        label: "User Chats",
        icon: "message-square",
        role: "can_manage_chat",
      },
      {
        name: "/payment-plans/users",
        label: "User Subscriptions",
        icon: "user-plus",
        role: "can_manage_user_subscriptions",
      },
    ],
  },
  {
    label: "Others",
    items: [
      {
        name: "/reports",
        label: "Reports",
        icon: "book",
        role: "can_manage_payment_plans",
      },
      {
        name: "/payment-plans",
        label: "Payment Plans",
        icon: "credit-card",
        role: "can_manage_payment_plans",
      },
      {
        name: "/audit-trail",
        label: "Audit Trail",
        icon: "check-square",
        role: "can_manage_audit_trail",
      },
      {
        name: "/promotions",
        label: "Promotions",
        icon: "percent",
        role: "can_manage_coupons",
      },
      {
        name: "/referrals",
        label: "Referrals Management and Report",
        icon: "gift",
        role: "can_manage_coupons",
      },
 
      {
        name: "/delivery-fee-management",
        label: "Delivery Fees",
        icon: "truck",
        role: "can_manage_delivery_fees",
      },
      {
        name: "/prescriptions",
        label: "Prescriptions",
        icon: "play",
        role: "can_manage_prescriptions",
      },
    ],
  },
  // {
  //   label: "Reports",
  //   items: [
  //     {
  //       name: "/referral-manager",
  //       label: "Referrals",
  //       icon: "user",
  //       role: "can_manage_referrals",
  //     },
  //   ],
  // },
];

export default Links;
