import React from 'react';
import useOverviewColumns from '../TableData/Overview/Overview';
import Table from '../Tables/Table';

const RecentOrders = ({ recentOrders }) => {
  const { columns } = useOverviewColumns();
  return (
    <div className='overview-table'>
      <div className='overview-table-header'>
        <h2>Recent Orders</h2>
        <a href='/order-management'>View all</a>
      </div>

      <Table
        columns={columns}
        data={recentOrders ?? []}
        emptyText1={'No recent orders'}
        getData={() => {}}
        isLoading={false}
        success
        emptyResultText={'Order not found!'}
        className={'table7'}
        border={'none'}
      />
    </div>
  );
};

export default RecentOrders;
