import React from "react";
import { LoaderSpin } from "../Loader/Loader";

export const AddAdminButton = (props) => {
  const { setstate } = props;

  const handleOpen = () => {
    setstate({
      active1: false,
      active2: true,
      openAddForm: true,
    }); 
  };
  return (
    <>
      <button
        className="add-admin-btn"
        data-testId="add-admin"
        onClick={handleOpen}
      > 
        &#43; <span>Add Admin</span>
      </button>
    </>
  );
};

export const AuthButton = (props) => {
  const {
    children,
    width,
    color,
    type,
    onClick,
    testId,
    marginTop,
    disabled,
    isLoading,
    loadingText,
  } = props;
  return (
    <Button
      children={children}
      type={type}
      width={width}
      height={"48px"}
      background={"#0A74DC"}
      marginTop={marginTop ? marginTop : "30px"}
      color={color}
      border={"none"}
      onClick={onClick}
      testId={testId}
      disabled={disabled}
      isLoading={isLoading}
      loadingText={loadingText}
    />
  );
};

export const DeleteButton = (props) => {
  const { children, width, color, type, onClick, testId, marginTop, disabled } =
    props;
  return (
    <Button
      children={children}
      type={type}
      width={width}
      height={"32px"}
      background={"none"}
      marginTop={marginTop ? marginTop : "30px"}
      color={color}
      border={"1px solid rgba(220, 0, 0, 0.1)"}
      onClick={onClick}
      testId={testId}
      disabled={disabled}
    />
  );
};

export const PageButton = (props) => {
  const {
    children,
    background, 
    type,
    width,
    onClick,
    marginTop,
    testId,
    disabled,
    height,
    marginRight,
    marginBottom,
    mainWidth,
  } = props;
  return (
    <Button
      children={children}
      type={type}
      width={width}
      height={height ? height : "48px"}
      background={background}
      marginTop={marginTop}
      marginRight={marginRight}
      marginBottom={marginBottom}
      color={"#0A74DC"}
      border={"1px solid rgba(10, 116, 220, 0.6)"}
      onClick={onClick}
      testId={testId}
      disabled={disabled}
      mainWidth={mainWidth}
    />
  );
};

const Button = ({
  children,
  color,
  onClick,
  disabled,
  isLoading,
  loadingText,
  width,
  height,
  testId,
  type,
  background,
  marginTop,
  marginBottom,
  className,
  border,
  marginRight,
  mainWidth,
}) => {
  return (
    <button
      data-testid={testId}
      disabled={disabled}
      onClick={onClick}
      style={{
        color: `${color}`,
        height: `${height}`,
        minWidth: `${width}`,
        width: `${mainWidth}`,
        background: `${background}`,
        marginTop: `${marginTop}`,
        marginRight: `${marginRight}`,
        marginBottom: `${marginBottom}`,
        border: `${border}`,
      }}
      className={className ? className : "button"}
      type={type}
    >
      {isLoading ? (
        <div className="loader">
          <div className="loader-text">{loadingText}</div>
          <LoaderSpin width="25px" height="25px" />
        </div>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
