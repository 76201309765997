import { notification } from "antd";

const Alert = (type, title, value, message) => {
  notification[type]({
    message: title,
    duration: 5,
    description:
      (value && value.error_message) || (value && value.message) || message,
  });
};

export default Alert;
