import React, { useEffect, useState } from "react";
import Modal, { ModalButton } from "../Modal/Modal";
import { Form, Formik, Field } from "formik";
import SelectInput from "../Input/Select";
import DateFilter from "../Input/DateFilter";
import Input from "../Input/Input";
import TimePicker from "../Input/TimePicker";
import { ScheduleAppointmentSchema } from "../../utils/validation/validation";
import { getFacility } from "../../redux/Actions/FacilityActions";
import {
  assignCategoryOptions,
  assignFacilityOptions,
  assignSubcategoryOptions,
} from "../../utils/functions/UpdateValues";
import { connect } from "react-redux";
import { getAppointmentCategories } from "../../redux/Actions/AppointmentActions";
import moment from "moment";
import momentTz from "moment-timezone"
import responseHandler from "../../utils/notifications/responseHandler";
import { getCurrentDate, getDayName } from "../../utils/functions/date";

const ScheduleAppointment = (props) => {
  const {
    displayModal,
    closeModal,
    isScheduling,
    scheduleSuccess,
    scheduleAppointment,
    getCategory,
    getFacilities,
    getCategorySuccess,
    getFacilitiesSuccess,
    facilities,
    categories,
  } = props;

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [saturdayTimeValid, setSaturdayTimeValid] = useState(false);
  const [formattedTime, setFormattedTime] = useState("")

  const currentDate = new Date();
  const currentTime = moment().format("HH:mm:ss");

  useEffect(
    () => {
      getCategory();
      getFacilities();
    },
    // eslint-disable-next-line
    [getCategory, getFacilities]
  );

  useEffect(
    () => {
      if (getCategorySuccess) {
        const updatedCategory = assignCategoryOptions(categories);
        setCategoryOptions(updatedCategory);
      }
    },
    // eslint-disable-next-line
    [getCategorySuccess, getFacilitiesSuccess]
  );

  const [state, setState] = useState({
    date: "",
    time: "",
    appointment_category: "",
    appointment_subcategory: "",
    appointment_subcategory_name: "",
    facility: "",
  });

  const initialValues = {
    email: "",
  };

  const updateSelection = (data, type) => {
    setState({
      ...state,
      [type]: data.value,
    });
  };

  const updateSelection2 = (data, type) => {
    const updatedSubcategory = assignSubcategoryOptions(categories, data.value);
    setSubcategoryOptions(updatedSubcategory);
    setState({
      ...state,
      appointment_subcategory_name: "",
      appointment_subcategory: "",
      [type]: data.value,
      facility: "",
    });
  };

  const updateSelection3 = (data) => {
    const facilityOptions = assignFacilityOptions(facilities, data.value);
    setFacilityOptions(facilityOptions);
    if (facilityOptions?.length === 0) {
      responseHandler(
        { problem: "CLIENT_ERROR" },
        "No facility found for this service. Kindly select another or add a facility"
      );
    }
    setState({
      ...state,
      appointment_subcategory: data.value,
      appointment_subcategory_name: data.label,
    });
  };

  const validateValues = (payload) => {
    const scheduledHour = new Date(payload.scheduled_time).getHours();
    const scheduledDate = new Date(payload.scheduled_date);
    const scheduledTime = moment(payload.scheduled_time).format("HH:mm:ss");
    const appointmentIsOpen = scheduledHour >= 8 && scheduledHour < 16;
    const pastTime =
      moment(scheduledDate).isSame(currentDate, "day") &&
      scheduledTime <= currentTime &&
      scheduledHour < 16;
    // if (!appointmentIsOpen) {
    //   responseHandler(
    //     { problem: "CLIENT_ERROR" },
    //     "Select a time between 8AM and 4PM"
    //   );
    // } else 
    if (pastTime) {
      responseHandler(
        { problem: "CLIENT_ERROR" },
        "Select a future time for this date"
      );
    } else {
      scheduleAppointment(payload);
      return;
    }
  };

  const submitValues = (values) => {
    const a = getCurrentDate(state.date, state.time)
    const formattedTimeZone = moment(a).tz("Africa/Lagos").format()
    const payload = {
      email: values.email,
      appointment_sub_category_id: state.appointment_subcategory,
      scheduled_date: state.date,
      scheduled_time: formattedTimeZone,
      appointment_category_id: state.appointment_category,
      facility_id: state.facility,
    };

    validateValues(payload);
  };

  useEffect(
    () => {
      if (scheduleSuccess) {
        document.getElementById("scheduleModal").reset();
        closeModal();
        setState({
          ...state,
          date: "",
          time: "",
          appointment_category: "",
          appointment_subcategory: "",
          appointment_subcategory_name: "",
          facility: "",
        });
      }
    },
    // eslint-disable-next-line
    [scheduleSuccess]
  );
  useEffect(() => {
    if (state.date && state.time) {
      const date = new Date(state.time);
      const time = date.getTime();

      const day = getDayName(state.date);
      if (day === "Saturday" && time >= 25200000 && time <= 46800000) {
        setSaturdayTimeValid(true);
      } else if (day === "Saturday" && (time <= 25200000 || time >= 46800000)) {
        setSaturdayTimeValid(false);
      } else {
        setSaturdayTimeValid(true);
      }
    }
  }, [state]);

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Schedule Appointment"}
      width={"568px"}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={ScheduleAppointmentSchema}
      >
        {({ isValid, dirty, values, setFieldValue }) => {
          const disabled =
            state.facility === "" ||
            state.appointment_category === "" ||
            state.appointment_subcategory === "" ||
            state.date === "" ||
            state.time === "" ||
            !(isValid && dirty);

          const onClick = () => {
            submitValues(values);
          };

          return (
            <Form id="scheduleModal">
              <div className="form-grid3">
                <Field
                  name="email"
                  label="Email"
                  placeholder="Enter client's email"
                  component={Input}
                  emptyValue={setFieldValue}
                />

                <SelectInput
                  label="Appointment type"
                  options={categoryOptions}
                  onChange={(data) =>
                    updateSelection2(data, "appointment_category")
                  }
                  success={scheduleSuccess}
                />
              </div>

              <div className="form-grid2">
                <DateFilter
                  label={"Date"}
                  placeholder={"Select date"}
                  state={state}
                  name={"date"}
                  setState={setState}
                  minDate={moment().toDate()}
                  success={scheduleSuccess}
                />

                <div>
                  <TimePicker
                    label={"Time"}
                    placeholder={"Select time"}
                    state={state}
                    name={"time"}
                    setState={setState}
                    setTime="1970-01-01T02:00:00+01:00"
                    displayModal={displayModal}
                  />
                  {/* <span className="notify">
                    Morning (8 to 12pm) or Afternoon (12 to 4pm)
                  </span> */}
                  {/* <span style={{
                    marginTop: "2rem",
                    display: "block",
                    color: "red",
                    fontSize: ".8rem"
                  }}>Saturdays (8 to 2pm)</span> */}
                </div>
              </div>

              <div className="form-grid">
                <SelectInput
                  label="Service"
                  options={subcategoryOptions}
                  defaultOption={state.appointment_subcategory_name}
                  onChange={(data) => updateSelection3(data)}
                  success={scheduleSuccess}
                />
              </div>

              <div className="form-grid">
                <SelectInput
                  label="Facility"
                  options={facilityOptions}
                  onChange={(data) => updateSelection(data, "facility")}
                  success={scheduleSuccess || facilityOptions?.length === 0}
                />
              </div>

              <ModalButton
                isLoading={isScheduling}
                text={"Schedule"}
                type={"submit"}
                testId={"schedule"}
                loadingText={"Scheduling..."}
                disabled={isScheduling || disabled}
                onClick={() => onClick()}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isGettingCategory: state.appointment.isGettingCategories,
  getCategorySuccess: state.appointment.getCategorySuccess,
  categories: state.appointment.categories,
  getFacilitiesSuccess: state.facility.getSuccess,
  facilities: state.facility.facilities,
});

const mapDispatchToProps = (dispatch) => ({
  getCategory: () => dispatch(getAppointmentCategories()),
  getFacilities: () => dispatch(getFacility()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleAppointment);
