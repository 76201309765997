import React, { useEffect } from "react";
import { connect } from "react-redux";
import { changeReferralStatus } from "../../../redux/Actions/ReferralActions";
import DeleteModal from "../../Modal/DeleteModal";

const DeactivateReferral = (props) => {
  const { changeReferralStatus, id, isChanging, changeSuccess } = props;

  useEffect(
    () => {
      if (changeSuccess) {
        props.closeModal();
      }
    }, // eslint-disable-next-line
    [changeSuccess]
  );
  return (
    <DeleteModal
      displayModal={props.displayModal}
      closeModal={props.closeModal}
      title={"Deactivate this referral?"}
      width={"463px"}
      text={"Deactivate"}
      onClick={() => changeReferralStatus(id)}
      disabled={isChanging}
      isLoading={isChanging}
      loadingText={"Changing..."}
      children={
        "Once you deactivate this referral, this referral would be deactivated. Deactivate Referral?"
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isChanging: state.referrals.isChanging,
  changeSuccess: state.referrals.changeSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  changeReferralStatus: (id) => dispatch(changeReferralStatus(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeactivateReferral);
