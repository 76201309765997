import Storage from "../services/storage";

export const clearStorage = () => {
  Storage.remove("admin-access-token");
  Storage.remove("admin-data");
  Storage.remove("email-value");
  Storage.remove("admin-details");
  Storage.remove("id");
  Storage.remove("page");
  Storage.remove("perPage");
  if (typeof window !== "undefined") {
    window.location.href = "/";
  }
};
