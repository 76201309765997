import Actions from "../Types";

const initialState = {
  isFetching: false, 
  getAdminsSuccess: false,
  admins: [], 
  adminsData: {},

  isSearching: false,
  searchSuccess: false,
  searchedAdmins: [], 
  searchedAdminsData: {},

  isFiltering: false,
  filterSuccess: false,
  filteredAdmins: [],
  filteredAdminsData: {}, 

  isCreating: false,
  createAdminsSuccess: false,
  admin: {},

  isEditing: false,
  editAdminSuccess: false,

  isGetting: false,
  roles: [],
  getRolesSuccess: false,

  isDeleting: false,
  deleteSuccess: false,

  isRevoking: false,
  revokeSuccess: false,

  error: null,
};

const reduxState = (state = initialState, action) => {

  switch (action.type) {
    case Actions.GET_ADMINS_START:
      return {
        ...state,
        isFetching: true,
        error: null, 
        getAdminsSuccess: false,
      };
    case Actions.GET_ADMINS_SUCCESS:
      return {
        ...state, 
        isFetching: false,
        error: null,
        editAdminSuccess: false,
        deleteSuccess: false,
        revokeSuccess: false,
        createAdminsSuccess: false,
        filterSuccess: false,
        searchSuccess: false,
        admins: action.payload && action.payload.rows,
        adminsData: action.payload,
        getAdminsSuccess: true,
      };
    case Actions.GET_ADMINS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        getAdminsSuccess: false,
      };
    case Actions.SEARCH_ADMINS_START:
      return {
        ...state,
        isSearching: true,
        searchSuccess: false,
        searchedAdmins: [],
        searchedAdminsData: {},
        error: null,
      };
    case Actions.SEARCH_ADMINS_SUCCESS:
      return {
        ...state,
        isSearching: false,
        searchSuccess: true,
        searchedAdmins: action.payload && action.payload.rows,
        searchedAdminsData: action.payload,
        error: null,
      };
    case Actions.SEARCH_ADMINS_FAILURE:
      return {
        ...state,
        isSearching: false,
        searchSuccess: false,
        searchedAdmins: [],
        searchedAdminsData: {},
        error: action.payload,
      };
    case Actions.FILTER_ADMINS_START:
      return {
        ...state,
        isFiltering: true,
        filterSuccess: false,
        filteredAdmins: [],
        filteredAdminsData: {},
        error: null,
      };
    case Actions.FILTER_ADMINS_SUCCESS:
      return {
        ...state,
        isFiltering: false,
        filterSuccess: true,
        filteredAdmins: action.payload && action.payload.rows,
        filteredAdminsData: action.payload,
        error: null,
      };
    case Actions.FILTER_ADMINS_FAILURE:
      return {
        ...state,
        isFiltering: false,
        filterSuccess: false,
        filteredAdmins: [],
        filteredAdminsData: {},
        error: action.payload,
      };
    case Actions.GET_ROLES_START:
      return {
        ...state,
        isGetting: true,
        error: null,
        getRolesSuccess: false,
      };
    case Actions.GET_ROLES_SUCCESS:
      return {
        ...state,
        isGetting: false,
        error: null,
        roles: action.payload && action.payload.data,
        getRolesSuccess: true,
      };
    case Actions.GET_ROLES_FAILURE:
      return {
        ...state,
        isGetting: false,
        error: action.payload,
        getRolesSuccess: false,
      };
    case Actions.CREATE_ADMIN_START:
      return {
        ...state,
        isGetting: false,
        isCreating: true,
        error: null,
        createAdminSuccess: false,
      };
    case Actions.CREATE_ADMIN_SUCCESS:
      return {
        ...state,
        isCreating: false,
        error: null,
        admin: action.payload,
        createAdminSuccess: true,
      };
    case Actions.CREATE_ADMIN_FAILURE:
      return {
        ...state,
        isCreating: false,
        error: action.payload,
        createAdminSuccess: false,
      };
    case Actions.EDIT_ADMIN_START:
      return {
        ...state,
        isEditing: true,
        error: null,
        editAdminSuccess: false,
      };
    case Actions.EDIT_ADMIN_SUCCESS:
      return {
        ...state,
        isEditing: false,
        error: null,
        editAdminSuccess: true,
      };
    case Actions.EDIT_ADMIN_FAILURE:
      return {
        ...state,
        isEditing: false,
        error: action.payload,
        editAdminSuccess: false,
      };
    case Actions.DELETE_ADMIN_START:
      return {
        ...state,
        isDeleting: true,
        deleteSuccess: false,
        admin: {},
        error: null,
      };
    case Actions.DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: true,
        admin: action.payload,
        error: null,
      };
    case Actions.DELETE_ADMIN_FAILURE:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: false,
        admin: null,
        error: action.payload,
      };
    case Actions.REVOKE_ADMIN_START:
      return {
        ...state,
        isRevoking: true,
        revokeSuccess: false,
        admin: null,
        error: null,
      };
    case Actions.REVOKE_ADMIN_SUCCESS:
      return {
        ...state,
        isRevoking: false,
        revokeSuccess: true,
        admin: action.payload,
        error: null,
      };
    case Actions.REVOKE_ADMIN_FAILURE:
      return {
        ...state,
        isRevoking: false,
        revokeSuccess: false,
        admin: null,
        error: action.payload,
      };
    case Actions.CLEAR_ADMIN_STATE:
      return {
        deleteSuccess: false,
      };
    default:
      return state;
  }
};

export default reduxState;
