import Alert from "./Alert";

const responseHandler = (response, title) => {
  const { data, problem, ok, status } = response;
  let res;

  if (ok || status === 200 || status === 201) {
    res = data;
    Alert("success", title, res, res);
  } else {
    if (status === 500) {
      res = (data && data.error_message) || (data && data.message) || data;
      if (
        (res && res.toLowerCase() === "jwt expired") ||
        (res && res.toLowerCase() === "jwt malformed") ||
        (res && res.toLowerCase() === "token is not valid")
      ) {
        Alert(
          "error",
          "Session Expired!",
          "Log in again to begain your session",
          "Log in again to begain your session"
        );
      } else {
        Alert(
          "error",
          "Server Error!",
          "Kindly try again.",
          "Kindly try again."
        );
      }
    }
    if (problem === "NETWORK_ERROR") {
      res = "Network Error! Kindly try again.";
      Alert("warning", title, res, res);
    }
    if (problem === "CLIENT_ERROR") {
      res = data;
      Alert("error", title, res, res);
    }
  }
  return res;
};

export default responseHandler;
