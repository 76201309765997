export function visibleList(state, setState) {
  const handleToggle1 = (status) => {
    setState({
      ...state,
      checked1: !status,
      checked2: !status,
      checked3: !status,
      checked4: !status,
    });
  }; 

  const handleToggle2 = (status) => {
    setState({
      ...state,
      checked1: false,
      checked2: !status,
      checked3: false,
      checked4: false,
    });
  };

  const handleToggle3 = (status) => {
    setState({
      ...state,
      checked1: false,
      checked2: false,
      checked3: !status,
      checked4: false,
    });
  };

  const handleToggle4 = (status) => {
    setState({
      ...state,
      checked1: false,
      checked2: false,
      checked3: false,
      checked4: !status,
    });
  };

  return [
    {
      title: "Visible to all Users",
      id: "all-users",
      handleChange: () => handleToggle1(state.checked1),
      status: state.checked1,
    },
    {
      title: "Visible to Patients only",
      id: "patients-only",
      handleChange: () => handleToggle2(state.checked2),
      status: state.checked2,
    },
    {
      title: "Visible to Doctors only",
      id: "doctors-only",
      handleChange: () => handleToggle3(state.checked3),
      status: state.checked3,
    },
    {
      title: "Visible to Consultants only",
      id: "consultants-only",
      handleChange: () => handleToggle4(state.checked4),
      status: state.checked4,
    },
  ];
}
