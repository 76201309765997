import React, { useEffect, useState } from "react";
import ActivateCoupon from "../../../CouponsReferrals/Coupons/ActivateCoupon";
import DeleteCoupon from "../../../CouponsReferrals/Coupons/DeleteCoupon";
import EditCoupon from "../../../CouponsReferrals/Coupons/EditCoupon";
import ScheduleCoupon from "../../../CouponsReferrals/Coupons/ScheduleCoupon";
import { cancelModal, viewModal } from "../../../Modal/Modal";
import {
  SuccessBtn,
  DropBtn,
  TableDropdown,
  InfoBtn,
} from "../../../Tables/TableButtons";
import Table from "../../../Tables/Table";
import { getCoupons } from "../../../../redux/Actions/CouponsActions";
import { connect } from "react-redux";
import { filterOptionsLabel } from "../../../../utils/functions/FilterObject";
import { useHistory } from "react-router-dom";
import { getFormattedNowDate } from "../../../../utils/functions/date";
import { ErrorBtn } from "../../../Tables/TableButtons";

export const handleData = (data) => {
  const columndata = Array.prototype.map
    .call(data, function (item) {
      return item.name;
    })
    .join(", ");
  return columndata.replace(/^(.{40}[^\s]*).*/, "$1") + "...";
};

const CouponsColumns = (props) => {
  const {
    state,
    setState,
    data,
    getData,
    getMoreData,
    isLoading,
    success,
    sortSucces,
    total,
    deleteSuccess,
    getCoupons,
    changeSuccess,
    createSuccess,
    editSuccess,
  } = props;

  useEffect(
    () => {
      if (createSuccess || changeSuccess || deleteSuccess || editSuccess) {
        getCoupons(1, 10);
      }
    }, // eslint-disable-next-line
    [createSuccess, deleteSuccess, changeSuccess, editSuccess, getCoupons]
  );

  const getServices = (data) => {
    if (data.service_type === "products") {
      if (data.products?.length > 0) {
        return handleData(data.products);
      } else {
        return "All Products";
      }
    } else if (data.service_type === "subscriptions") {
      if (data.subscription_plans?.length > 0) {
        return handleData(data.subscription_plans);
      } else {
        return "All Subscription Plans";
      }
    } else if (data.service_type === "appointment_sub_categories") {
      if (data.appointment_sub_categories?.length > 0) {
        return handleData(data.appointment_sub_categories);
      } else {
        return "All Appointment SubCategories";
      }
    }
  };

  const couponsColumns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row }) => (
        <div style={{ textTransform: "capitalize" }}>
          <span>{row.original.name}</span>
        </div>
      ),
    },
    {
      Header: "Code/Link",
      accessor: "code_link",
      Cell: ({ row }) => (
        <div className={"code-link"}>
          <span>{row.original.code}</span>
        </div>
      ),
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ row }) => (
        <div>
          <span>
            {row.original.type === "fixed" ? "NGN" : ""} &nbsp;
            {row.original.amount}
            {row.original.type === "percentage" ? "%" : ""}
          </span>
        </div>
      ),
    },
    {
      Header: "Products",
      accessor: "products",
      Cell: ({ row }) => (
        <div className="table-flex">
          <span>{getServices(row.original)}</span>
          {/* <span>
            {row.original &&
            row.original.products &&
            row.original.products.length > 0
              ? handleData(row)
              : "All Products"}
          </span> */}
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        const data = row.original;
        const id = row.original && row.original.id;
        const status = row.original && row.original.status;
        const [show, setShow] = useState(false);
        const history = useHistory();
        const [state, setState] = useState({
          editModal: false,
          scheduleModal: false,
          activateModal: false,
          deleteModal: false,
        });

        const openModal = (type) => {
          viewModal(type, state, setState);
          setShow((prevCheck) => !prevCheck);
        };

        const closeModal = (type) => {
          cancelModal(type, state, setState);
        };

        const options = [
          {
            name: "More Info",
            action: () => history.push(`promotions/${id}`),
          },
          {
            name: "Edit",
            action: () => openModal("editModal"),
          },
          {
            name: "Schedule",
            label: "Schedule",
            action: () => openModal("scheduleModal"),
          },
          {
            name: "Activate",
            label: "Active",
            action: () => openModal("activateModal"),
          },
          {
            name: "Delete",
            action: () => openModal("deleteModal"),
          },
        ];

        return (
          <div className="action-flex">
            {new Date(getFormattedNowDate()) >
            new Date(row.original.expiration_date) ? (
              <ErrorBtn text={"Expired"} />
            ) : row.original.status === "active" ? (
              <SuccessBtn text={"Active"} />
            ) : (
              <InfoBtn text={"Scheduled"} />
            )}
            <DropBtn onClick={() => setShow((prevCheck) => !prevCheck)} />
            {show && (
              <TableDropdown
                options={options && filterOptionsLabel(status, options)}
              />
            )}

            {state.editModal && (
              <EditCoupon
                displayModal={state.editModal}
                closeModal={() => closeModal("editModal")}
                data={data}
              />
            )}

            {state.scheduleModal && (
              <ScheduleCoupon
                displayModal={state.scheduleModal}
                closeModal={() => closeModal("scheduleModal")}
                id={id}
              />
            )}

            {state.activateModal && (
              <ActivateCoupon
                displayModal={state.activateModal}
                closeModal={() => closeModal("activateModal")}
                id={id}
              />
            )}

            {state.deleteModal && (
              <DeleteCoupon
                displayModal={state.deleteModal}
                closeModal={() => closeModal("deleteModal")}
                id={id}
              />
            )}
          </div>
        );
      },
    },
  ];

  return (
    <Table
      state={state}
      setState={setState}
      columns={couponsColumns}
      data={data}
      getData={getData}
      getMoreData={getMoreData}
      isLoading={isLoading}
      success={success}
      sortSucces={sortSucces}
      total={total}
      emptyText1={"You haven't added any Coupon"}
      emptyResultText={"Coupon not found!"}
    />
  );
};
const mapStateToProps = (state) => ({
  isDeleting: state.coupons.isDeleting,
  deleteSuccess: state.coupons.deleteSuccess,
  changeSuccess: state.coupons.changeSuccess,
  createSuccess: state.coupons.createSuccess,
  editSuccess: state.coupons.editSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getCoupons: (page, limit) => dispatch(getCoupons(page, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CouponsColumns);
