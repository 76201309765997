import React from "react";
import moment from "moment";
// import { cancelModal, viewModal } from "../../Modal/Modal";
// import ActivatePlan from "../../PaymentPlans/ActivatePlan";

export const paymentColumns = [
  {
    Header: "Name",
    accessor: "full_name",
    Cell: ({ row }) => (
      <div className="table-flex">
        <span style={{ textTransform: "capitalize" }}>
          {row.original.first_name && row.original.last_name
            ? `${row.original.first_name} ${row.original.last_name}`
            : row.original.email}
        </span>
      </div>
    ),
  },
  {
    Header: "Email",
    accessor: "email",
    Cell: ({ row }) => (
      <div className="table-flex">{row.original.email || "Nil"}</div>
    ),
  },
  {
    Header: "Subscription plan",
    accessor: "plan_name",
    Cell: ({ row }) => (
      <div className="" style={{ display: "flex", flexFlow: "column" }}>
        <div style={{ display: "flex", flexFlow: "row" }}>
          {`${row.original.plan_name || "Nil"}${
            row.original.auto_renewal_status ? "🔁" : ""
          }`}
          {new Date() > new Date(row.original.expiration_date) ? (
            <>
              <div className="error-btn" style={{ marginLeft: "4px" }}>
                <span>expired</span>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <h6>
          {"purchased  "}
          {new Date(row.original.subscription_date).toDateString()}
        </h6>
      </div>
    ),
  },
  {
    Header: "Expiration Date",
    accessor: "expiration_date",
    Cell: ({ row }) => (
      <div className="table-flex">
        {moment(row.original.expiration_date).format("MMM Do YY") || "Nil"}
      </div>
    ),
  },
];
