import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  changeBlogCategoryStatus,
  getAllBlogCategories,
} from "../../../redux/Actions/BlogActions";
import { getCurrentPagination } from "../../../utils/functions/GetStorageItems";
import DeleteModal from "../../Modal/DeleteModal";

const ChangeCategoryStatus = (props) => {
  const {
    displayModal,
    closeModal,
    isChangingStatus,
    changeStatus,
    data,
    getCategories,
    changeSuccess,
    status,
  } = props;

  const { page, perPage } = getCurrentPagination();

  const id = data && data.id;
  const ActivateText =
    "Once you activate this category, this category would be active. Activate category?";
  const DeactivateText =
    "Once you deactivate this category, this category would be inactive. Deactivate category?";

  const submitValues = () => {
    changeStatus(id);
  };

  useEffect(
    () => {
      if (changeSuccess) {
        closeModal();
        getCategories(page, perPage);
      }
    },
    // eslint-disable-next-line
    [changeSuccess]
  );

  return (
    <DeleteModal
      displayModal={displayModal}
      closeModal={closeModal}
      title={
        status === "active"
          ? "Deactivate this category?"
          : "Activate this category?"
      }
      width={"463px"}
      text={status === "active" ? "Deactivate" : "Activate"}
      onClick={() => submitValues()}
      disabled={isChangingStatus}
      isLoading={isChangingStatus}
      activate={status === "active" ? false : true}
      loadingText={status === "active" ? "Deactivating..." : "Activating..."}
      children={status === "active" ? DeactivateText : ActivateText}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  changeStatus: (payload) => dispatch(changeBlogCategoryStatus(payload)),
  getCategories: (page, limit) => dispatch(getAllBlogCategories(page, limit)),
});

const mapStateToProps = (state) => ({
  isChangingStatus: state.blogcategory.isChangingStatus,
  changeSuccess: state.blogcategory.changeStatusSuccess,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeCategoryStatus);
