import PropTypes from "prop-types";
import React from "react";
import ActiveLogo1 from "../../assets/status-blue.svg";
import ActiveLogo2 from "../../assets/status-green.svg";
import InactiveLogo from "../../assets/status-grey.svg";

export const Statuses = (props) => {
  const {
    label,
    state,
    options,
    setstate,
    blue,
    numberPerRow,
    marginTop,
    onClick,
  } = props;

  const setActiveState = (index, status) => {
    setstate({
      ...state,
      active: true,
      status: status,
      index: index,
    });
  };

  return (
    <div
      className="messages-status"
      style={{ marginTop: marginTop ? marginTop : "32px" }}
    >
      <p className="messages-status-label">{label}</p>

      <div
        className="messages-status-group"
        style={{ gridTemplateColumns: `repeat(${numberPerRow}, 1fr)` }}
      >
        {options.map((option, index) => (
          <div
            className="messages-status-group-each"
            key={index}
            data-testid={option.name}
            onClick={
              onClick
                ? () => onClick(index, option.name)
                : () => setActiveState(index, option.name)
            }
          >
            <div className="messages-status-group-each-image">
              <img
                src={
                  state.active === true && state.index === index
                    ? blue
                      ? ActiveLogo1
                      : ActiveLogo2
                    : InactiveLogo
                }
                alt="icon"
              />
            </div>
            <span className="messages-status-group-each-label">
              {option.name}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

Statuses.propTypes = {
  options: PropTypes.any,
  setstate: PropTypes.func,
  state: PropTypes.shape({
    active: PropTypes.bool,
    index: PropTypes.any,
  }),
};
