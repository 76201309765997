import React from "react";
import { connect } from "react-redux";
import { revokeAdmin } from "../../redux/Actions/AdminActions";
import {
  assignPermissionData,
  permissionData,
  permissionPayload,
} from "../../utils/functions/CreateNewArray";
import DeleteModal from "../Modal/DeleteModal";

const ActivateAdmin = (props) => {
  const { revokeAdmin, isRevoking, data } = props;

  const newArray = data && assignPermissionData(data);
  const permissions = newArray && permissionData(newArray);
  const permissionsPayload = permissions && permissionPayload(permissions);

  const handleRevoke = () => {
    const payload = {
      id: data.id,
      status: "active",
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      role: data.role,
      ...permissionsPayload,
    };
    revokeAdmin(payload);
  };
  return (
    <DeleteModal
      displayModal={props.displayModal}
      closeModal={props.closeModal}
      title={"Activate this admin?"}
      width={"463px"}
      text={"Activate"}
      onClick={handleRevoke}
      disabled={isRevoking}
      isLoading={isRevoking}
      activate={true}
      loadingText={"Activating..."}
      children={
        "Once you activate this admin, this admin would be active. Activate admin?"
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isRevoking: state.admin.isRevoking,
});

const mapDispatchToProps = (dispatch) => ({
  revokeAdmin: (payload) => dispatch(revokeAdmin(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivateAdmin);
