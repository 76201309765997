import React from "react";

const UploadFile = (props) => {
  const {changed} = props
  return (
    <label for="fileSelect" className="drop-container">
      <input onChange={changed}  type="file" id="fileSelect" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" required />
    </label>
  );
};

export default UploadFile;
