import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import Icon from "../../../assets/account_icon.svg";
import BackBtn from "../../../components/Input/BackBtn";
import { LoaderSpin } from "../../../components/Loader/Loader";
import {
    deleteUser,
    getUsersProfile,
} from "../../../redux/Actions/UsersActions";
import Storage from "../../../utils/services/storage";
import Instance from "../../../utils/services/api";
import HeaderWrapper from "../../../components/Wrappers/HeaderWrapper";


const EditConsultant = (props) => {
    const {
        getUsersProfile,
        isGettingProfile,
        usersProfileData
    } = props;


    const location = useLocation();

    const id = location && location.state && location.state.id;
    const doctorImage = usersProfileData && usersProfileData.image_url;

    useEffect(() => {
        getUsersProfile("customer", id);
    }, [getUsersProfile, id]);

    const [isLoading, setIsLoading] = useState(false);
    const [disabled, setDisabled] = useState(false)
    const [clientData, setClientData] = useState(location?.state?.data ? location?.state?.data : {
        first_name: '',
        last_name: '',
        status: '',
        specialization: '',
        gender: '',
        age_group: '',
        email: '',
        full_name: '',
        full_address: '',
        years_of_experience: '',
        registration_no: ''
    })
    const [message, setMessage] = useState('');
    const [messType, setMessType] = useState('');
    const bgColor = messType == 'danger' ? '#F7E4E8' : '#ECF5EF';
    const textColor = messType == 'danger' ? 'brown' : '#228B22';

    // To handle the input change 
    const handleInputChange = (e) => {
        setMessage('');
        setClientData({
            ...clientData, [e.target.name]: e.target.value
        })
    }

    // Making API call to update user's profile 
    const handleUpdateProfile = (e) => {
        e.preventDefault();

        if (clientData.first_name == '' || clientData.first_name == ' ') {
            setMessType('danger')
            setMessage("First name can't be empty");
            return false;
        } else if (clientData.last_name == '' || clientData.last_name == ' ') {
            setMessType('danger')
            setMessage("Last name can't be empty")
            return false;
        } else if (clientData.specialization == '' || clientData.specialization == ' ') {
            setMessType('danger')
            setMessage("Specialization can't be empty")
            return false;
        } else if (clientData.status == '' || clientData.status == ' ') {
            setMessType('danger')
            setMessage("Status can't be empty")
            return false;
        }else if (clientData.full_name == '' || clientData.full_name == ' ') {
            setMessType('danger')
            setMessage("Full Name can't be empty")
            return false;
        }else if (clientData.full_address == '' || clientData.full_address == ' ') {
            setMessType('danger')
            setMessage("Full Address can't be empty")
            return false;
        }else if (clientData.registration_no == '' || clientData.registration_no == ' ') {
            setMessType('danger')
            setMessage("Registration number can't be empty")
            return false;
        } else {

            // Now the user has entered email and password, let's check if the user entered a valid email 
            var p = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z]+)/ig;
            var rg = clientData.email.match(p);

            if (!rg) {
                setMessType('danger')
                setMessage("Please enter a valid email.")
                return false;
            } else {
                let updatedUser = {
                    first_name: clientData.first_name,
                    last_name: clientData.last_name,
                    full_name: clientData.full_name,
                    full_address: clientData.full_address,
                    years_of_experience: clientData.years_of_experience,
                    specialization: clientData.specialization,
                    gender: clientData.gender,
                    registration_no: clientData.registration_no,
                }


                // All tests have passed so we can make our API call to update the profile
                setIsLoading(true)
                const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
                API.patch(`user/${id}`, updatedUser).then((response) => {
                    const { data, ok } = response;

                    console.log('response', response)

                    if (ok) {
                        console.log('response', data.data)
                        setMessage(data.message)
                        setMessType('success')
                        setIsLoading(false)
                    } else {
                        setMessage(data.message)
                        setMessType('danger')
                        setIsLoading(false)
                        console.log('response', data.data)
                    }
                });
            }
        }
    }

    return (
        <div className="single-users">
            {isGettingProfile ? (
                <div className="loader-table2">
                    <LoaderSpin width="40px" height="40px" />
                </div>
            ) : (
                <div className="editingMode">
                    <BackBtn page="/users" />
                    <HeaderWrapper
                        title={"Edit Consultant Profile"}
                        subtitle={
                            "You can edit the consultant's profile here except the 'email' and 'status'."
                        }
                        className={"overview-header-right"}
                    />
                    <div>
                        {
                            message != '' &&
                            <div
                                style={{
                                    marginBottom: '15px',
                                    paddingTop: '8px',
                                    paddingBottom: '8px',
                                    borderRadius: '8px',
                                    backgroundColor: bgColor,
                                    color: textColor,
                                    textAlign: 'center',
                                    width: '36%',
                                    fontSize: '18px'
                                }}
                            >
                                {message}
                            </div>
                        }
                    </div>
                    <form onSubmit={handleUpdateProfile}>
                        <div className="top-wrap">
                            <div className="top-wrap-top">
                                <div className="client-header-top">
                                    <div className="client-header-top-image">
                                        <img src={doctorImage === null ? Icon : doctorImage} alt="client" />
                                    </div>

                                    <div className="client-header-top-text">
                                        <div className="edit-flex">
                                            <span>
                                                FirstName:
                                            </span>
                                            <span style={{ marginLeft: '20px', marginBottom: '10px' }}>
                                                <input
                                                    type="text"
                                                    name={'first_name'}
                                                    onChange={handleInputChange}
                                                    value={clientData?.first_name}
                                                />
                                            </span>
                                        </div>
                                        <div className="edit-flex">
                                            <span>
                                                LastName:
                                            </span>
                                            <span style={{ marginLeft: '20px', marginBottom: '10px' }}>
                                                <input
                                                    type="text"
                                                    name={'last_name'}
                                                    onChange={handleInputChange}
                                                    value={clientData?.last_name}
                                                />
                                            </span>
                                        </div>
                                        <div className="edit-flex">
                                            <span>
                                                Status:
                                            </span>
                                            <span style={{ marginLeft: '20px', marginBottom: '10px' }}>
                                                <input
                                                    type="text"
                                                    name={'status'}
                                                    value={clientData?.status}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Lower Section  */}
                        <div className="info-wrap">
                            <div className="info-wrap-left">
                                <div className="info">
                                    <h4>General Infomation</h4>
                                    <div className="info-data">
                                        <div className="info-data-left">Full Name:</div>
                                        <div
                                            className="info-data-right"
                                        >
                                            <input
                                                type="text"
                                                name={'full_name'}
                                                onChange={handleInputChange}
                                                value={clientData?.full_name}
                                            />
                                        </div>
                                        <div className="info-data-left">Address:</div>
                                        <div
                                            className="info-data-right"
                                        >
                                            <input
                                                type="text"
                                                name={'full_address'}
                                                onChange={handleInputChange}
                                                value={clientData?.full_address}
                                            />
                                        </div>
                                        <div className="info-data-left">Sex</div>
                                        <div
                                            className="info-data-right"
                                            style={{ textTransform: "capitalize" }}
                                        >
                                            <select name="gender" value={clientData?.gender} onChange={handleInputChange}>
                                                <option value="male" selected={clientData?.status == "male"}>Male</option>
                                                <option value="female" selected={clientData?.status == "female"}>Female</option>
                                            </select>
                                        </div>
                                        <div className="info-data-left">Email</div>
                                        <div
                                            className="info-data-right"
                                            style={{ textTransform: "capitalize" }}
                                        >
                                            <input
                                                type="email"
                                                name={'email'}
                                                value={clientData?.email}
                                            />
                                        </div>
                                        <div className="info-data-left">Specialization:</div>
                                        <div
                                            className="info-data-right"
                                        >
                                            <input
                                                type="text"
                                                name={'specialization'}
                                                onChange={handleInputChange}
                                                value={clientData?.specialization}
                                            />
                                        </div>
                                        <div className="info-data-left">Registration No:</div>
                                        <div
                                            className="info-data-right"
                                        >
                                            <input
                                                type="text"
                                                name={'registration_no'}
                                                onChange={handleInputChange}
                                                value={clientData?.registration_no}
                                            />
                                        </div>
                                        <div className="info-data-left">Years Of Experience:</div>
                                        <div
                                            className="info-data-right"
                                        >
                                            <input
                                                type="text"
                                                name={'years_of_experience'}
                                                onChange={handleInputChange}
                                                value={clientData?.years_of_experience}
                                            />
                                        </div>
                                        <div className="info-data-left">Last Active Subscription Plan</div>
                                        <div
                                            className="info-data-right"
                                            style={{ textTransform: "capitalize" }}
                                        >
                                            {!clientData?.last_active_subscription?.subscription_plans?.id ? "Nil" : ` ${clientData?.last_active_subscription?.subscription_plans?.name}@${new Date(clientData?.last_active_subscription?.created_at).toDateString()}`}
                                        </div>
                                    </div>
                                </div>

                                {/* Submit Button  */}
                                <button
                                    disabled={disabled}
                                    style={{
                                        width: "auto",
                                        background: "rgba(10, 116, 220, 1)",
                                        color: "white",
                                        padding: '10px 20px',
                                        borderRadius: '10px',
                                        margin: '30px auto'
                                    }}
                                    className={"button"}
                                    type={'submit'}
                                // onClick={() => handleUpdateProfile()}
                                >
                                    {
                                        isLoading ? (
                                            <div className="loader">
                                                <div className="loader-text">Loading...</div>
                                                <LoaderSpin width="25px" height="25px" />
                                            </div>
                                        ) :
                                            (
                                                <div className="button-data">
                                                    <span>
                                                        Update Profile
                                                    </span>
                                                </div>
                                            )
                                    }
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    isGettingProfile: state.users.isGettingProfile,
    getSuccessProfile: state.users.getSuccessProfile,
    usersProfileData: state.users.usersProfileData,
    isDeleting: state.users.isDeleting,
    deleteSuccess: state.users.deleteSuccess,
});

const mapDispatchToProps = (dispatch) => ({
    deleteUser: (id) => dispatch(deleteUser(id)),
    getUsersProfile: (type, id) => dispatch(getUsersProfile(type, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditConsultant);
