import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import Input from "../Input/Input";
import Modal, { ModalButton } from "../Modal/Modal";
import { connect } from "react-redux";
import {
  assignSubscriptionPlan,
  getAllSubscribers,
} from "../../redux/Actions/PaymentPlanActions";
import { assignSubscriptionSchema } from "../../utils/validation/validation";
import { getSubscriptions } from "../../redux/Actions/SubscriptionsAction";
import { assignSubscriptionCategoryOptions } from "../../utils/functions/UpdateValues";
import SelectInput from "../Input/Select";

const AssignSubscriptionModal = (props) => {
  const {
    displayModal,
    closeModal,
    assignSubscriptionPlan,
    user_id,
    isAssigningSubscription,
    assignSubscriptionSuccess,
    getSubscribers,
    subscriptiondata,
    getSubscriptions,
    getSubscriptionsSuccess,
    isGetting,
  } = props;

  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  const [subscriptionId, setSubscriptionId] = useState("");
  const sort = (array) => {
    const a = array.sort(function (a, b) {
      return b.id - a.id;
    });
    return a;
  };

  useEffect(() => {
    if (getSubscriptionsSuccess) {
      setSubscriptionOptions(
        sort(assignSubscriptionCategoryOptions(subscriptiondata))
      );
    }
  }, [getSubscriptionsSuccess]);

  useEffect(() => {
    getSubscriptions();
  }, []);
  const submitValues = (values) => {
    const payload = {
      subscription_payment_id: subscriptionId,
      user_id,
    };
    assignSubscriptionPlan(payload);
  };

  useEffect(() => {
    if (assignSubscriptionSuccess) {
      getSubscribers(1, 10);
      document.getElementById("assignsubscriptionform");
      closeModal();
    }
  }, [assignSubscriptionSuccess]);

  const initialValues = {
    city: "",
  };
  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Assign Subscription Modal"}
      width={"568px"}
      no_overflow={true}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => submitValues(values)}
      >
        {({ isValid, dirty, setFieldValue }) => {
          const disabled = isAssigningSubscription || !subscriptionId;
          return (
            <Form id="assignsubscriptionform">
              <SelectInput
                label={"Subscription"}
                options={subscriptionOptions}
                isSearchable
                placeholder={
                  isGetting
                    ? "Fetching Subscriptions... "
                    : "Search Transaction Reference"
                }
                onChange={(data) => setSubscriptionId(data.value)}
              />
              <div className="pin-to-bottom">
                <ModalButton
                  isLoading={isAssigningSubscription}
                  text={"Assign Subscription"}
                  type={"submit"}
                  testId={"button"}
                  loadingText={"Assigning..."}
                  disabled={disabled}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isAssigningSubscription: state.plan.isAssigningSubscription,
  assignSubscriptionSuccess: state.plan.assignSubscriptionSuccess,
  subscriptiondata: state.subscriptions.subscriptiondata,
  isGetting: state.subscriptions.isGetting,
  getSubscriptionsSuccess: state.subscriptions.getSubscriptionsSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  assignSubscriptionPlan: (payload) =>
    dispatch(assignSubscriptionPlan(payload)),
  getSubscribers: (page, limit) => dispatch(getAllSubscribers(page, limit)),
  getSubscriptions: () => dispatch(getSubscriptions()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignSubscriptionModal);
