import React, { useEffect } from "react";
import DeleteModal from "../Modal/DeleteModal";
import { connect } from "react-redux";
import { getOverview } from "../../redux/Actions/OverviewActions";
import {
  deleteUserAppointment,
  getUserAppointments,
} from "../../redux/Actions/UserAppointmentActions";
import { useHistory } from "react-router";
import { useOverviewTypeContext } from "../../contexts/OverviewTypeContext";

const DeleteAppointment = (props) => {
  const {
    deleteAppointment,
    isDeleting,
    deleteSuccess,
    fetchOverview,
    data,
    getAppointments,
    limit,
    closeModal,
  } = props;
  const appointmentId = data && data.id;
  const history = useHistory();
  const type = useOverviewTypeContext();

  useEffect(
    () => {
      if (deleteSuccess) {
        closeModal();
        if (history?.location?.pathname === "/appointment") {
          getAppointments(1, limit);
          return;
        }
        fetchOverview(type?.overviewType);
      }
    }, // eslint-disable-next-line
    [deleteSuccess]
  );

  const handleDelete = (id) => {
    deleteAppointment(id);
  };

  return (
    <DeleteModal
      displayModal={props.displayModal}
      closeModal={props.closeModal}
      title={"Delete this appointment?"}
      width={"463px"}
      text={"Delete"}
      onClick={() => handleDelete(appointmentId)}
      disabled={isDeleting}
      isLoading={isDeleting}
      loadingText={"Deleting..."}
      children={
        "Once you delete this appointment, it won’t be possible to undo this action. Delete appointment?"
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isDeleting: state.userappointment.isDeleting,
  deleteSuccess: state.userappointment.deleteSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  deleteAppointment: (id) => dispatch(deleteUserAppointment(id)),
  getAppointments: (page, limit) => dispatch(getUserAppointments(page, limit)),
  fetchOverview: (queries) => dispatch(getOverview(queries)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAppointment);
