import React, { useEffect, useState } from "react";
import { cancelModal, viewModal } from "../../../Modal/Modal";
import {
  SuccessBtn,
  DropBtn,
  TableDropdown,
  ErrorBtn,
} from "../../../Tables/TableButtons";
import Table from "../../../Tables/Table";
import { connect } from "react-redux";
import { filterOptionsLabel } from "../../../../utils/functions/FilterObject";
import { getReferrals } from "../../../../redux/Actions/ReferralActions";
import DeleteReferral from "../../../CouponsReferrals/Referrals/DeleteReferral";
import EditReferral from "../../../CouponsReferrals/Referrals/EditReferral";
import ActivateReferral from "../../../CouponsReferrals/Referrals/ActivateReferral";
import DeactivateReferral from "../../../CouponsReferrals/Referrals/DeactivateReferral";

const ReferralColumns = (props) => { 
  const {
    state,
    setState,
    data,
    getData,
    getMoreData,
    isLoading,
    success,
    sortSucces,
    total,
    deleteSuccess,
    getReferrals,
    changeSuccess,
    createSuccess,
    editSuccess,
  } = props;

  useEffect(
    () => {
      if (createSuccess || changeSuccess || deleteSuccess || editSuccess) {
        getReferrals(1, 10);
      }
    }, // eslint-disable-next-line
    [createSuccess, deleteSuccess, changeSuccess, editSuccess, getReferrals]
  );

  const referralColumns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row }) => (
        <span style={{ textTransform: "capitalize" }}>
          {row.original?.name}
        </span>
      ),
    },
    {
      Header: "Sender Amount",
      accessor: "sender_amount",
      Cell: ({ row }) => (
        <div>
          <span>NGN{row.original?.referrer_amount}</span>
        </div>
      ),
    },
    {
      Header: "Receiver Amount",
      accessor: "receiver_amount",
      Cell: ({ row }) => (
        <div>
          <span>NGN{row.original?.referred_amount}</span>
        </div>
      ),
    },
    {
      Header: "Duration",
      accessor: "duration",
      Cell: ({ row }) => (
        <div>
          <span>{row.original?.duration_in_days} days </span>
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        const data = row.original;
        const id = row.original?.id;
        const status = row.original?.status;
        const [show, setShow] = useState(false);

        const [state, setState] = useState({
          editModal: false,
          deactivateModal: false,
          activateModal: false,
          deleteModal: false,
        });

        const openModal = (type) => {
          viewModal(type, state, setState);
          setShow((prevCheck) => !prevCheck);
        };

        const closeModal = (type) => {
          cancelModal(type, state, setState);
        };

        const options = [
          {
            name: "Edit",
            action: () => openModal("editModal"),
          },
          {
            name: "Deactivate",
            label: "Inactive",
            action: () => openModal("deactivateModal"),
          },
          {
            name: "Activate",
            label: "Active",
            action: () => openModal("activateModal"),
          },
          {
            name: "Delete",
            action: () => openModal("deleteModal"),
          },
        ];

        return (
          <div className="action-flex">
            {row.original.status === "active" ? (
              <SuccessBtn text={"Active"} />
            ) : (
              <ErrorBtn text={"Inactive"} />
            )}
            <DropBtn onClick={() => setShow((prevCheck) => !prevCheck)} />
            {show && (
              <TableDropdown
                options={options && filterOptionsLabel(status, options)}
              />
            )}

            {state.editModal && (
              <EditReferral
                displayModal={state.editModal}
                closeModal={() => closeModal("editModal")}
                data={data}
              />
            )}

            {state.deactivateModal && (
              <DeactivateReferral
                displayModal={state.deactivateModal}
                closeModal={() => closeModal("deactivateModal")}
                id={id}
              />
            )}

            {state.activateModal && (
              <ActivateReferral
                displayModal={state.activateModal}
                closeModal={() => closeModal("activateModal")}
                id={id}
              />
            )}

            {state.deleteModal && (
              <DeleteReferral
                displayModal={state.deleteModal}
                closeModal={() => closeModal("deleteModal")}
                id={id}
              />
            )}
          </div>
        );
      },
    },
  ];

  return (
    <Table
      state={state}
      setState={setState}
      columns={referralColumns}
      data={data}
      getData={getData}
      getMoreData={getMoreData}
      isLoading={isLoading}
      success={success}
      sortSucces={sortSucces}
      total={total}
      emptyText1={"You haven't added any Referral"}
      emptyResultText={"Referral not found!"}
    />
  );
};
const mapStateToProps = (state) => ({
  isDeleting: state.referrals.isDeleting,
  deleteSuccess: state.referrals.deleteSuccess,
  changeSuccess: state.referrals.changeSuccess,
  createSuccess: state.referrals.createSuccess,
  editSuccess: state.referrals.editSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getReferrals: (page, limit) => dispatch(getReferrals(page, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReferralColumns);
