import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";

// GET USERS
export const getUsersStart = () => ({
  type: Actions.GET_USERS_START,
});

export const getUsersSuccess = (payload) => ({
  type: Actions.GET_USERS_SUCCESS,
  payload,
});

export const getUsersFailure = (payload) => ({
  type: Actions.GET_USERS_FAILURE,
  payload,
});

// GET USERS PROFILE
export const getUsersProfileStart = () => ({
  type: Actions.GET_USERS_PROFILE_START,
});

export const getUsersProfileSuccess = (payload) => ({
  type: Actions.GET_USERS_PROFILE_SUCCESS,
  payload,
});

export const getUsersProfileFailure = (payload) => ({
  type: Actions.GET_USERS_PROFILE_FAILURE,
  payload,
});

// SEARCH USERS
export const searchUsersStart = () => ({
  type: Actions.SEARCH_USERS_START,
});

export const searchUsersSuccess = (payload) => ({
  type: Actions.SEARCH_USERS_SUCCESS,
  payload,
});

export const searchUsersFailure = (payload) => ({
  type: Actions.SEARCH_USERS_FAILURE,
  payload,
});

// FILTER USERS
export const filterUsersStart = () => ({
  type: Actions.FILTER_USERS_START,
});

export const filterUsersSuccess = (payload) => ({
  type: Actions.FILTER_USERS_SUCCESS,
  payload,
});

export const filterUsersFailure = (payload) => ({
  type: Actions.FILTER_USERS_FAILURE,
  payload,
});

// CREATE USER
export const createUserStart = () => ({
  type: Actions.CREATE_USER_START,
});

export const createUserSuccess = (payload) => ({
  type: Actions.CREATE_USER_SUCCESS,
  payload,
});

export const createUserFailure = (payload) => ({
  type: Actions.CREATE_USER_FAILURE,
  payload,
});

// DELETE USER
export const deleteUserStart = () => ({
  type: Actions.DELETE_USER_START,
});

export const deleteUserSuccess = (payload) => ({
  type: Actions.DELETE_USER_SUCCESS,
  payload,
});

export const deleteUserFailure = (payload) => ({
  type: Actions.DELETE_USER_FAILURE,
  payload,
});

// REVOKE USER
export const revokeUserStart = () => ({
  type: Actions.REVOKE_USER_START,
});

export const revokeUserSuccess = (payload) => ({
  type: Actions.REVOKE_USER_SUCCESS,
  payload,
});

export const revokeUserFailure = (payload) => ({
  type: Actions.REVOKE_USER_FAILURE,
  payload,
});

// GET USER CHAT HISTORY
export const getUserChatHistoryStart = () => ({
  type: Actions.GET_USER_CHAT_HISTORY_START,
});

export const getUserChatHistorySuccess = (payload) => ({
  type: Actions.GET_USER_CHAT_HISTORY_SUCCESS,
  payload,
});

export const getUserChatHistoryFailure = (payload) => ({
  type: Actions.GET_USER_CHAT_HISTORY_FAILURE,
  payload,
});

// SEARCH USER CHAT HISTORY
export const searchUserChatHistoryStart = () => ({
  type: Actions.SEARCH_USER_CHAT_HISTORY_START,
});

export const searchUserChatHistorySuccess = (payload) => ({
  type: Actions.SEARCH_USER_CHAT_HISTORY_SUCCESS,
  payload,
});

export const searchUserChatHistoryFailure = (payload) => ({
  type: Actions.SEARCH_USER_CHAT_HISTORY_FAILURE,
  payload,
});

// TRANSFER USER CHAT
export const transferUserChatStart = () => ({
  type: Actions.TRANSFER_USER_CHAT_START,
});

export const transferUserChatSuccess = (payload) => ({
  type: Actions.TRANSFER_USER_CHAT_SUCCESS,
  payload,
});

export const transferUserChatFailure = (payload) => ({
  type: Actions.TRANSFER_USER_CHAT_FAILURE,
  payload,
});

export const clearUsersState = () => ({
  type: Actions.CLEAR_USERS_STATE,
});

export const getExportCSVSuccess = () => ({
  type: Actions.GET_EXPORT_CSV_SUCCESS,
});

export const isGettingCSVResponse = () => ({
  type: Actions.GET_CSV_START,
});

export const getUsers =
  (page, limit, type, hasReferredUsers) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

    dispatch(getUsersStart());
    API.get(
      `user?page=${page}&limit=${limit}&type=${type}&hasReferredUsers=${hasReferredUsers}`
    ).then((response) => {
      const { data, ok } = response;

      if (ok) {
        dispatch(getUsersSuccess(data && data.data));
      } else {
        dispatch(getUsersFailure(data));
        responseHandler(response, "Failed!");
      }
    });
  };

export const getUsersProfile = (type, id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getUsersProfileStart());
  API.get(`user/${type}/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getUsersProfileSuccess(data && data.data));
    } else {
      dispatch(getUsersProfileFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const searchUsers =
  (page, limit, type, search, hasReferredUsers) => async (dispatch) => {
    console.log(hasReferredUsers);
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

    dispatch(searchUsersStart());
    API.get(
      `user?page=${page}&limit=${limit}&type=${type}&search=${search}&hasReferredUsers=${hasReferredUsers}`
    ).then((response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(searchUsersSuccess(data && data.data));
      } else {
        dispatch(searchUsersFailure(data));
        responseHandler(response, "Failed!");
      }
    });
  };

export const filterUsers =
  (startDate, endDate, page, limit, type, gender, age, status, location) =>
  async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

    dispatch(filterUsersStart());
    API.get(
      type === "hfcp"
        ? `user/filter?page=${page}&limit=${limit}&type=${type}&age_group=${age}&gender=${gender}&status=${status}&state=${location}&start_date=${startDate}&end_date=${endDate}`
        : `user/filter?page=${page}&limit=${limit}&type=${type}&gender=${gender}&age_group=${age}&status=${status}&start_date=${startDate}&end_date=${endDate}`
    ).then((response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(filterUsersSuccess(data && data.data));
      } else {
       dispatch(filterUsersFailure(data));
        responseHandler(response, "Failed!");
      }
    });
  };

export const createUser = (type, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(createUserStart());
  API.post(`user?type=${type}`, payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(createUserSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(createUserFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const deleteUser = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(deleteUserStart());
  API.delete(`user/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(deleteUserSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(deleteUserFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const revokeUser = (id, status) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(revokeUserStart());
  API.patch(`user/change-status/${id}?&status=${status}`).then((response) => {
    const { data, ok } = response;
    responseHandler(response);
    if (ok) {
      dispatch(revokeUserSuccess(data && data.data));
    } else {
      dispatch(revokeUserFailure(data));
    }
  });
};

export const getUserChatHistory = (id, type) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getUserChatHistoryStart());
  API.get(`user/customer/chat/${id}?&type=${type}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getUserChatHistorySuccess(data && data.data));
    } else {
      dispatch(getUserChatHistoryFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const searchUserChatHistory = (id, type, search) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(searchUserChatHistoryStart());
  API.get(`user/customer/chat/${id}?&type=${type}&search=${search}`).then(
    (response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(searchUserChatHistorySuccess(data && data.data));
      } else {
        dispatch(searchUserChatHistoryFailure(data));
        responseHandler(response, "Failed!");
      }
    }
  );
};

export const transferUserChat = (id, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(transferUserChatStart());
  API.post(`user/transfer-chat/${id}`, payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(transferUserChatSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(transferUserChatFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const getConsultantChatHistory = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getUserChatHistoryStart());
  API.get(`user/chat/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getUserChatHistorySuccess(data && data.data));
    } else {
      dispatch(getUserChatHistoryFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const searchConsultantChatHistory = (id, search) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(searchUserChatHistoryStart());
  API.get(`user/chat/${id}?&search=${search}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(searchUserChatHistorySuccess(data && data.data));
    } else {
      dispatch(searchUserChatHistoryFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};
