import Actions from "../Types";

const initialState = {
  isGetting: false,
  getSuccess: false,
  deliveryFees: [],
  deliveryFeesData: {},

  isCreating: false,
  createSuccess: false,
  deliveryFee: {},

  isEditing: false,
  editSuccess: false,

  isChanging: false,
  changeSuccess: false,

  isDeleting: false,
  deleteSuccess: false,

  error: null,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_DELIVERY_FEES_START:
      return {
        ...state,
        isGetting: true,
        getSuccess: false,
        deliveryFees: [],
        error: null,
      };
    case Actions.GET_DELIVERY_FEES_SUCCESS:
      return {
        ...state,
        isGetting: false,
        getSuccess: true,
        changeSuccess: false,
        createSuccess: false,
        deleteSuccess: false,
        editSuccess: false,
        deliveryFees: action.payload && action.payload.deliveryFees,
        deliveryFeesData: action.payload,
        error: null,
      };
    case Actions.GET_DELIVERY_FEES_FAILURE:
      return {
        ...state,
        isGetting: false,
        getSuccess: false,
        deliveryFees: [],
        deliveryFeesData: {},
        error: action.payload,
      };
    case Actions.CREATE_DELIVERY_FEE_START:
      return {
        ...state,
        isCreating: true,
        createSuccess: false,
        error: null,
      };
    case Actions.CREATE_DELIVERY_FEE_SUCCESS:
      return {
        ...state,
        isCreating: false,
        createSuccess: true,
        deliveryFee: action.payload,
        error: null,
      };
    case Actions.CREATE_DELIVERY_FEE_FAILURE:
      return {
        ...state,
        isCreating: false,
        createSuccess: false,
        error: action.payload,
      };
    case Actions.EDIT_DELIVERY_FEE_START:
      return {
        ...state,
        isEditing: true,
        editSuccess: false,
        deliveryFee: {},
        error: null,
      };
    case Actions.EDIT_DELIVERY_FEE_SUCCESS:
      return {
        ...state,
        isEditing: false,
        editSuccess: true,
        deliveryFee: action.payload,
        error: null,
      };
    case Actions.EDIT_DELIVERY_FEE_FAILURE:
      return {
        ...state,
        isEditing: false,
        editSuccess: false,
        deliveryFee: null,
        error: action.payload,
      };
    case Actions.DELETE_DELIVERY_FEE_START:
      return {
        ...state,
        isDeleting: true,
        deleteSuccess: false,
        deliveryFee: {},
        error: null,
      };
    case Actions.DELETE_DELIVERY_FEE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: true,
        deliveryFee: action.payload,
        error: null,
      };
    case Actions.DELETE_DELIVERY_FEE_FAILURE:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: false,
        deliveryFee: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reduxState;
