import React, { useEffect, useState } from "react";
import {
  SuccessBtn,
  DropBtn,
  TableDropdown,
  InfoBtn,
} from "../../../Tables/TableButtons";
import { useHistory } from "react-router-dom";
import DeleteConsultant from "../../../UserManagement/Consultants/DeleteConsultant";
import { cancelModal, viewModal } from "../../../Modal/Modal";
import ActivateConsultant from "../../../UserManagement/Consultants/ActivateConsultant";
import SuspendConsultant from "../../../UserManagement/Consultants/SuspendConsultant";
import Table from "../../../Tables/Table";
import { filterOptionsLabel } from "../../../../utils/functions/FilterObject";
import truncateName from "../../../../utils/functions/ShortenStr";
import { getUsers } from "../../../../redux/Actions/UsersActions";
import { connect } from "react-redux";

const ConsultantColumns = (props) => {
  const {
    state,
    setState,
    data,
    getData,
    getMoreData,
    isLoading,
    success,
    sortSucces,
    total,
    deleteSuccess,
    revokeSuccess,
    createSuccess,
  } = props;

  useEffect(
    () => {
      if (deleteSuccess || revokeSuccess || createSuccess) {
        getData(1, 10,);
      }
    }, // eslint-disable-next-line
    [deleteSuccess, getUsers, revokeSuccess, createSuccess]
  );

  const consultantData = [
    {
      Header: "Full Name",
      accessor: "full_name",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <span style={{ textTransform: "capitalize" }}>
            {row.original.full_name}
          </span>
        </div>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ row }) => (
        <div className="table-flex">
          <span>{truncateName(row.original.email, 17) || "-"}</span>
        </div>
      ),
    },
    {
      Header: "Registration No.",
      accessor: "registration_no",
      Cell: ({ row }) => (
        <span>
          {row.original?.registration_no === null
            ? "Nil"
            : row.original?.registration_no}
        </span>
      ),
    },
    {
      Header: "Specialization",
      accessor: "specialization",
      Cell: ({ row }) => (
        <span>
          {row.original?.specialization === null
            ? "Nil"
            : row.original?.specialization}
        </span>
      ),
    },
    {
      Header: "Sex",
      accessor: "gender",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <span style={{ textTransform: "capitalize" }}>
            {row.original && row.original.gender}
          </span>
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        const id = row.original && row.original.id;
        const status = row.original && row.original.status;
        const [show, setShow] = useState(false);

        const history = useHistory();

        const [state, setState] = useState({
          activateModal: false,
          suspendModal: false,
          deleteModal: false,
          deliveredModal: false,
          canceledModal: false,
        });

        const openModal = (type) => {
          viewModal(type, state, setState);
          setShow((prevCheck) => !prevCheck);
        };

        const closeModal = (type) => {
          cancelModal(type, state, setState);
        };

        const goToSinglePage = () => {
          history.push({
            pathname: `/users/consultants/${id}`,
            state: {
              id: id,
              data: row.original,
            },
          });
        };

        // Newly Added 
        const goToEditPage = () => {
          history.push({
            pathname: `/users/consultants/edit/${id}`,
            state: {
              id: id,
              data: row.original,
            },
          });
        };


        const options = [
          {
            name: "View",
            action: () => goToSinglePage(),
          },
          {
            name: "Activate",
            label: "Active",
            action: () => openModal("activateModal"),
          },
          {
            name: "Suspend",
            label: "Suspended",
            action: () => openModal("suspendModal"),
          },
          {
            name: "Edit",
            label: "Edit",
            action: () => goToEditPage(),
          },
          {
            name: "Delete",
            action: () => openModal("deleteModal"),
          },
        ];

        return (
          <div className="action-flex">
            {row.original.status === "active" ? (
              <SuccessBtn text={"Active"} />
            ) : row.original.status === "suspended" ? (
              <InfoBtn text={"Suspended"} />
            ) : (
              "Nil"
            )}
            <DropBtn onClick={() => setShow((prevCheck) => !prevCheck)} />
            {show && (
              <TableDropdown
                options={options && filterOptionsLabel(status, options)}
              />
            )}

            {state.activateModal && (
              <ActivateConsultant
                displayModal={state.activateModal}
                closeModal={() => closeModal("activateModal")}
                id={id}
              />
            )}

            {state.suspendModal && (
              <SuspendConsultant
                displayModal={state.suspendModal}
                closeModal={() => closeModal("suspendModal")}
                id={id}
              />
            )}

            {state.deleteModal && (
              <DeleteConsultant
                displayModal={state.deleteModal}
                closeModal={() => closeModal("deleteModal")}
                id={id}
              />
            )}
          </div>
        );
      },
    },
  ];
  return (
    <div className="orders-table">
      <Table
        state={state}
        setState={setState}
        columns={consultantData}
        data={data}
        getData={getData}
        getMoreData={getMoreData}
        isLoading={isLoading}
        success={success}
        sortSucces={sortSucces}
        total={total}
        emptyText1={"You haven't added any Consultant"}
        emptyResultText={"Consultant not found!"}
        className={"table7"}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isDeleting: state.users.isDeleting,
  deleteSuccess: state.users.deleteSuccess,
  revokeSuccess: state.users.revokeSuccess,
  createSuccess: state.users.createSuccess,
});


export default connect(mapStateToProps, null)(ConsultantColumns);
