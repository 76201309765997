import React, { useEffect, useState } from "react";
import { PageButton } from "../../components/Button/Button";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import { getReferrals } from "../../redux/Actions/ReferralActions";
import {
  getCoupons,
  filterCoupons,
  searchCoupons,
} from "../../redux/Actions/CouponsActions";
import Add from "../../assets/add.svg";
import { cancelModal, viewModal } from "../../components/Modal/Modal";
import SwitchWrapper from "../../components/Wrappers/SwitchWrapper";
import ReferralsCouponColumns from "../../components/TableData/CouponReferrals/ReferralsCoupon/ReferralsCoupon";
import ReferralColumns from "../../components/TableData/CouponReferrals/Referrals/Referrals";
import { connect } from "react-redux";
import FilterReferralCoupon from "../../components/CouponsReferrals/ReferralCoupon/FilterReferralCoupon";
import SortData from "../../components/Sorting/SortData";
import CreateReferral from "../../components/CouponsReferrals/Referrals/CreateReferral";
import CustomersColumn from "../../components/ReferralCodes/CustomerColumns";
import {
  filterCustomers,
  getCustomers,
  searchCustomers,
} from "../../redux/Actions/ReferralReportAction";
import FilterCustomer from "../../components/ReferralCodes/FilterCustomer";
import InfluencerColumns from "../../components/ReferralCodes/InfluencerColumns";

function ReferralManager(props) {
  const {
    isGetting,
    getSuccess,
    coupons,
    couponsData,
    filteredCouponsData,
    searchedCouponsData,
    isSearching,
    searchSuccess,
    searchedCoupons,
    getCoupons,
    searchCoupons,
    getReferrals,
    referrals,
    referralsData,
    isGettingReferrals,
    getSuccessReferrals,
    filterCoupons,
    isFiltering,
    filterSuccess,
    filteredCoupons,
    filterSuccessCoupons,
    filteredCoupon,
    isGettingCustomers,
    getCustomersSuccess,
    customers,
    isSearchingCustomer,
    searchCustomersSuccess,
    searchedCustomers,
    isFilteringCustomers,
    filterCustomersSuccess,
    filteredCustomers,
    customersData,
    filteredCustomersData,
    searchedCustomersData,
    getCustomersHandler,
    searchCustomersHandler,
    filteredCustomersHandler,
  } = props;
  const [state, setState] = useState({
    active1: true,
    active2: false,
    active3: false,
    active4: false,
    addModal: false,
    addReferralModal: false,
    filterModal: false,
    filterCouponModal: false,
    showSearchResults: false,
    searchValue: "",
    filterValues: {},
  });
  const allCoupons = searchSuccess
    ? searchedCoupons
    : filterSuccess
    ? filteredCoupons
    : filterSuccessCoupons
    ? filteredCoupon
    : coupons;
  useEffect(() => {
    console.log(allCoupons);
  }, [allCoupons]);
  const totalCoupons = searchSuccess
    ? searchedCouponsData && searchedCouponsData.count
    : filterSuccess
    ? filteredCouponsData && filteredCouponsData.count
    : filterSuccessCoupons
    ? filteredCoupon && filteredCouponsData.count
    : couponsData && couponsData.count;

  const moreData = (page, perPage) => {
    searchSuccess
      ? searchCoupons(
          page,
          perPage,
          state.searchValue,
          state.active3 ? "auto" : ""
        )
      : filterSuccess
      ? filterCoupons(
          page,
          perPage,
          state.filterValues?.amount,
          state.filterValues?.status,
          state.filterValues?.product
        )
      : getCoupons(page, perPage);
  };

  const searchAllCoupons = () => {
    if (state.searchValue === "") {
      return coupons;
    } else {
      searchCoupons(1, 10, state.searchValue, state.active1 ? "auto" : "");
    }
  };
  const searchAllUsers = () => {
    console.log("sddf");
    const checkType = state.active3 ? "customer" : "influencer";
    if (state.searchValue === "") {
      return customers;
    }
    searchCustomersHandler(1, 10, checkType, state.searchValue);
  };

  const openModal = (type) => viewModal(type, state, setState);
  const closeModal = (type) => cancelModal(type, state, setState);
  const allCustomers = searchCustomersSuccess
    ? searchedCustomers
    : filterCustomersSuccess
    ? filteredCustomers
    : getCustomersSuccess
    ? customers
    : [
        {
          full_name: "",
          email: "",
          referral_code: "",
          referral_trails_referrerUsers: [],
        },
      ];
  useEffect(() => {
    console.log(allCustomers);
  }, [allCustomers]);
  const moreCustomerData = (page, perPage, type) => {
    searchCustomersSuccess
      ? searchCustomersHandler(page, perPage, type, state.searchValue)
      : filterCustomersSuccess
      ? filteredCustomersHandler(page, perPage, type, state.filterValues)
      : getCustomersHandler(page, perPage, type);
  };
  const totalCustomers = searchCustomersSuccess
    ? searchedCustomersData && searchedCustomersData.count
    : filterCustomersSuccess
    ? filteredCustomersData && filteredCustomersData.count
    : customersData && customersData.count;
  const totalReferrals = referralsData && referralsData.count;
  return (
    <div>
      <HeaderWrapper
        title={"Referrals"}
        subtitle={"View and Manage all Referrals"}
        children={
          state.active2 ? (
            <PageButton
              width={"auto"}
              background={"rgba(10, 116, 220, 0.08)"}
              marginTop={"30px"}
              onClick={() => openModal("addReferralModal")}
              type={"submit"}
            >
              <div className="button-data">
                <img src={Add} alt="add-icon" /> <span>Create Referral</span>
              </div>
            </PageButton>
          ) : (
            ""
          )
        }
      ></HeaderWrapper>
      <SwitchWrapper
        title1={"Receivers Report"}
        title2={"Referrals Manager"}
        title3={"Senders Report"}
        title4={"Influencers Report"}
        state={state}
        setstate={setState}
        component1={
          <ReferralsCouponColumns
            state={state}
            setState={setState}
            data={allCoupons}
            getData={(page, limit) => getCoupons(page, limit, "auto")}
            getMoreData={(page, perPage) => moreData(page, perPage, "auto")}
            isLoading={isGetting || isSearching || isFiltering}
            success={
              getSuccess ||
              searchSuccess ||
              filterSuccess ||
              filterSuccessCoupons
            }
            sortSucces={searchSuccess || filterSuccess}
            total={totalCoupons}
          />
        }
        component2={
          <ReferralColumns
            state={state}
            setState={setState}
            data={referrals}
            getData={(page, limit) => getReferrals(page, limit)}
            getMoreData={(page, perPage) => getReferrals(page, perPage)}
            isLoading={isGettingReferrals}
            success={getSuccessReferrals}
            total={totalReferrals}
          />
        }
        component3={
          <CustomersColumn
            state={state}
            setState={setState}
            data={allCustomers}
            getData={() => getCustomersHandler(1, 10, "customer")}
            getMoreData={(page, perPage) =>
              moreCustomerData(page, perPage, "customer")
            }
            isLoading={
              isGettingCustomers || isSearchingCustomer || isFilteringCustomers
            }
            success={
              getCustomersSuccess ||
              searchCustomersSuccess ||
              filterCustomersSuccess
            }
            sortSucces={searchCustomersSuccess || filterCustomersSuccess}
            total={totalCustomers}
          />
        }
        component4={
          <InfluencerColumns
            state={state}
            setState={setState}
            data={allCustomers}
            getData={() => getCustomersHandler(1, 10, "influencer")}
            getMoreData={(page, perPage) =>
              moreCustomerData(page, perPage, "influencer")
            }
            isLoading={
              isGettingCustomers || isSearchingCustomer || isFilteringCustomers
            }
            success={
              getCustomersSuccess ||
              searchCustomersSuccess ||
              filterCustomersSuccess
            }
            sortSuccess={searchCustomersSuccess || filterCustomersSuccess}
            total={totalCustomers}
          />
        }
        right1={
          !state.active2 && (
            <SortData
              placeholder={"Search by name, email"}
              width={"347px"}
              searchItems={state.active1 ? searchAllCoupons : searchAllUsers}
              setState={setState}
              state={state}
              onClick={() => openModal("filterModal")}
              filter={true}
              sortSuccess={
                filterSuccessCoupons ||
                searchSuccess ||
                filterCustomersSuccess ||
                searchCustomersSuccess ||
                filterSuccess
              }
              refreshFunction={() => {
                setState({
                  ...state,
                  searchValue: "",
                });
                state.active1
                  ? getCoupons(1, 10, state.active1 ? "auto" : "")
                  : getCustomersHandler(
                      1,
                      10,
                      state.active3 ? "customer" : "influencer"
                    );
              }}
            />
          )
        }
        right2={"false"}
      />
      <CreateReferral
        displayModal={state.addReferralModal}
        closeModal={() => closeModal("addReferralModal")}
      />
      {state.active1 ? (
        <FilterReferralCoupon
          displayModal={state.filterModal}
          closeModal={() => closeModal("filterModal")}
        />
      ) : (
        <FilterCustomer
          displayModal={state.filterModal}
          closeModal={() => closeModal("filterModal")}
          state={state}
          setState={setState}
          filterSuccess={filterCustomersSuccess}
          isFiltering={isFilteringCustomers}
          filterCustomers={filteredCustomersHandler}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isGetting: state.coupons.isGetting,
  getSuccess: state.coupons.getSuccess,
  coupons: state.coupons.coupons,
  couponsData: state.coupons.couponsData,
  searchedCouponsData: state.coupons.searchedCouponsData,
  filteredCouponsData: state.coupons.filteredCouponsData,
  isSearching: state.coupons.isSearching,
  searchSuccess: state.coupons.searchSuccess,
  searchedCoupons: state.coupons.searchedCoupons,
  isFiltering: state.coupons.isFiltering,
  filterSuccess: state.coupons.filterSuccess,
  filteredCoupons: state.coupons.filteredCoupons,
  filterSuccessCoupons: state.coupons.filterSuccessCoupons,
  filteredCoupon: state.coupons.filteredCoupon,
  isGettingReferrals: state.referrals.isGetting,
  getSuccessReferrals: state.referrals.getSuccess,
  referrals: state.referrals.referrals,
  referralsData: state.referrals.referralsData,
  isGettingCustomers: state.referralreport.isGetting,
  getCustomersSuccess: state.referralreport.getCustomersSuccess,
  customers: state.referralreport.customers,
  isSearchingCustomers: state.referralreport.isSearching,
  searchCustomersSuccess: state.referralreport.searchSuccess,
  searchedCustomers: state.referralreport.searchedCustomers,
  isFilteringCustomers: state.referralreport.isFiltering,
  filterCustomersSuccess: state.referralreport.filterSuccess,
  filteredCustomers: state.referralreport.filteredCustomers,
  customersData: state.referralreport.customersData,
  filteredCustomersData: state.referralreport.filteredCustomersData,
  searchedCustomersData: state.referralreport.searchedCustomersData,
});
const mapDispatchToProps = (dispatch) => ({
  getReferrals: (page, limit) => dispatch(getReferrals(page, limit)),
  getCoupons: (page, limit, type) => dispatch(getCoupons(page, limit, type)),
  searchCoupons: (page, limit, searchValue, type) =>
    dispatch(searchCoupons(page, limit, searchValue, type)),
  filterCoupons: (page, limit, amount, status, product) =>
    dispatch(filterCoupons(page, limit, amount, status, product)),
  getCustomersHandler: (page, limit, type) =>
    dispatch(getCustomers(page, limit, type)),
  searchCustomersHandler: (page, limit, type, search) =>
    dispatch(searchCustomers(page, limit, type, search)),
  filteredCustomersHandler: (page, limit, type, payload) =>
    dispatch(filterCustomers(page, limit, type, payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ReferralManager);
