import React, { useEffect, useState } from "react";
import Modal, { ModalButton } from "../../Modal/Modal";
import { Field, Form, Formik } from "formik";
import { Statuses } from "../../Input/Statuses";
import Input from "../../Input/Input";
import DateFilter from "../../Input/DateFilter";
import responseHandler from "../../../utils/notifications/responseHandler";
import SelectInput from "../../Input/Select";
import MultiDropdown from "../../Input/MultiDropdown";
import { connect } from "react-redux";
import { editCoupon } from "../../../redux/Actions/CouponsActions";
import {
  assignBlogCategoryOptions,
  assignCouponProductPayload,
  assignSubcategoriesOptions,
} from "../../../utils/functions/UpdateValues";
import { parseISO } from "date-fns";
import { getAllProducts } from "../../../redux/Actions/ProductActions";
import { EditCouponSchema } from "../../../utils/validation/validation";
import { getAppointmentCategories } from "../../../redux/Actions/AppointmentActions";

const EditCoupon = (props) => {
  const {
    displayModal,
    closeModal,
    data,
    editCoupon,
    isEditing,
    editSuccess,
    getProducts,
    products,
    getSuccess,
    getAllCategories,
    getPlans,
    plans,
    categories,
  } = props;

  const [state, setState] = useState({
    active: true,
    index: data && data.status === "active" ? 0 : 1,
    status: data && data.status,
    start_date: data && data.start_date,
    expiration_date: data && data.expiration_date,
    type: data && data.type,
    service_type: data && data.service_type,
    single_use: data && data.single_use,
    products:
      data &&
      data.products.map((val) => ({ category_id: val.id, name: val.name })),
    plans:
      data &&
      data.subscription_plans.map((val) => ({
        category_id: val.id,
        name: val.name,
      })),
    appointment_subcategories:
      data &&
      data.appointment_sub_categories.map((val) => ({
        category_id: val.id,
        name: val.name,
      })),
  });


  const [checkAllState, setCheckAllState] = useState({
    status: data && data.products.length === 0 ? "Yes" : "No",
    index: data && data.products.length === 0 ? 0 : 1,
    active:
      data &&
      (data.subscription_plans?.length > 0 ||
        data.appointment_sub_categories?.length > 0)
        ? false
        : true,
  });

  const [checkAllPlansState, setCheckAllPlansState] = useState({
    status: data && data.subscription_plans?.length === 0 ? "Yes" : "No",
    index: data && data.subscription_plans?.length === 0 ? 0 : 1,
    active:
      data &&
      (data.products.length > 0 || data.appointment_sub_categories?.length > 0)
        ? false
        : true,
  });
  const [checkAllSubCategoriesState, setCheckAllSubCategoriesState] = useState({
    status:
      data && data.appointment_sub_categories?.length === 0 ? "Yes" : "No",
    index: data && data.appointment_sub_categories?.length === 0 ? 0 : 1,
    active:
      data && (data.products.length > 0 || data.subscription_plans?.length > 0)
        ? false
        : true,
  });

  const types = [
    { label: "Percentage", value: "percentage" },
    { label: "Fixed", value: "fixed" },
  ];

  const [options, setOptions] = useState({
    products: null,
    plans: null,
    appointment_subcategories: null,
  });

  useEffect(
    () => {
      getPlans();
      getAllCategories();
      getProducts(1, 10, true);
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (editSuccess) {
        closeModal();
      } else if (getSuccess) {
        const updatedOptions = state && assignBlogCategoryOptions(products);
        const updatedPlanOptions =
          state && assignBlogCategoryOptions(plans?.plans);
        const updatedSubcategoryOpitons =
          state && assignSubcategoriesOptions(categories);
        setOptions({
          ...options,
          products: updatedOptions,
          plans: updatedPlanOptions,
          appointment_subcategories: updatedSubcategoryOpitons,
        });
      }
    },
    // eslint-disable-next-line
    [editSuccess]
  );

  const status = [{ name: "Active" }, { name: "Schedule" }];

  const checkAll = [{ name: "Yes" }, { name: "No" }];

  const initialValues = {
    code: data.code,
    amount: data.amount,
    name: data.name,
  };

  const updateType = ({ value: type }) => {
    setState((state) => ({ ...state, type }));
  };

  const multiDropdownTypes = [
    { label: "Products", value: "products" },
    { label: "Subscription Plans", value: "subscriptions" },
    { label: "Appointment Subcategories", value: "appointment_sub_categories" },
  ];

  const submitValues = (values) => {
    const {
      status,
      start_date,
      expiration_date,
      type,
      products,
      plans,
      appointment_subcategories,
    } = state;
    const { code, amount, name } = values;

    const payload = {
      code,
      amount,
      name,
      type,
      status: status.toLowerCase(),
      start_date,
      expiration_date,
      service_type: state.service_type,
      single_use: state.single_use,
      products:
        checkAllState.status === "Yes"
          ? []
          : assignCouponProductPayload(products),
      subscription_plans:
        checkAllPlansState.status === "Yes"
          ? []
          : assignCouponProductPayload(plans),
      appointment_sub_categories:
        checkAllSubCategoriesState.status === "Yes"
          ? []
          : assignCouponProductPayload(appointment_subcategories),
    };

    const date = (value) => new Date(value).getTime();
    const dateValid = date(start_date) < date(expiration_date);
    const id = data && data.id;

    if (dateValid) {
      editCoupon(id, payload);
      return;
    }
    responseHandler(
      { problem: "CLIENT_ERROR" },
      "End date should be ahead of the start date"
    );
  };
  const resetPlansState = () => {
    setCheckAllPlansState({
      active: false,
      status: "",
      index: "",
    });
  };
  const resetProductsState = () => {
    setCheckAllState({
      active: false,
      status: "",
      index: "",
    });
  };
  const resetSubcategoriesState = () => {
    setCheckAllSubCategoriesState({
      active: false,
      status: "",
      index: "",
    });
  };
  const updateMultipDropdownType = ({ value: type }) => {
    if (type === "subscriptions") {
      resetSubcategoriesState();
      resetProductsState();
      setState({
        ...state,
        products: [],
        appointment_subcategories: [],
      });
    } else if (type === "products") {
      resetPlansState();
      resetSubcategoriesState();
      setState({
        ...state,
        plans: [],
        appointment_subcategories: [],
      });
    } else if (type === "appointment_sub_categories") {
      resetPlansState();
      resetProductsState();
      setState({
        ...state,
        plans: [],
        products: [],
      });
    }
    setState({ ...state, service_type: type });
  };

  const singleUseOption = [
    { label: "True", value: true },
    { label: "False", value: false },
  ];
  const updateSingleUse = ({ value: type }) => {
    setState((state) => ({ ...state, single_use: type }));
  };
  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Edit Discount Code"}
      width={"568px"}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={EditCouponSchema}
        onSubmit={(values) => submitValues(values)}
      >
        {({ isValid, dirty, setFieldValue }) => {
          const disabled =
            isEditing ||
            state.status === "" ||
            state.start_date === "" ||
            state.expiration_date === "" ||
            state.type === "" ||
            (checkAllState.status === "No" && state.products.length === 0) ||
            (checkAllPlansState.status === "No" && state.plans.length === 0) ||
            (checkAllSubCategoriesState.status === "No" &&
              state.appointment_subcategories.length === 0) ||
            (checkAllState.status === "" &&
              checkAllPlansState.status === "" &&
              checkAllSubCategoriesState.status === "");
          return (
            <Form id="addcouponform">
              <div className="">
                <Field
                  name="name"
                  label="Name"
                  placeholder="Enter Name"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>
              <div className="form-grid2">
                <Field
                  name="code"
                  label="Code"
                  placeholder="Enter Code"
                  component={Input}
                  emptyValue={setFieldValue}
                />
                <SelectInput
                  label={"Single Use"}
                  options={singleUseOption}
                  onChange={updateSingleUse}
                  defaultOption={state.single_use.toString()}
                />
              </div>
              <div className="form-grid2">
                <DateFilter
                  label={"Start Date"}
                  placeholder={"Select date"}
                  state={state}
                  name={"start_date"}
                  setState={setState}
                  previousDate={data.start_date && parseISO(data.start_date)}
                />
                <DateFilter
                  label={"End Date"}
                  placeholder={"Select date"}
                  state={state}
                  name={"expiration_date"}
                  setState={setState}
                  previousDate={
                    data.expiration_date && parseISO(data.expiration_date)
                  }
                />
              </div>
              <div className="form-grid2">
                <SelectInput
                  label="Type"
                  options={types}
                  defaultOption={state.type}
                  onChange={updateType}
                />
                <Field
                  type="number"
                  name="amount"
                  label="Amount(Fixed/Percentage(%))"
                  placeholder="Enter amount"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>
              <div className="top-spacer">
                <Statuses
                  label={"Status"}
                  state={state}
                  options={status}
                  setstate={setState}
                  blue={false}
                />
              </div>
              <div className="top-spacer">
                <SelectInput
                  label="Assign Coupon To"
                  options={multiDropdownTypes}
                  onChange={updateMultipDropdownType}
                  defaultOption={state.service_type}
                />
              </div>
              <div className="top-spacer">
                {state.service_type === "products" ? (
                  <Statuses
                    label={"Apply to all products?"}
                    state={checkAllState}
                    options={checkAll}
                    setstate={setCheckAllState}
                    blue={false}
                  />
                ) : null}
                {state.service_type === "subscriptions" ? (
                  <Statuses
                    label={"Apply to all Subscription Plans"}
                    state={checkAllPlansState}
                    options={checkAll}
                    setstate={setCheckAllPlansState}
                    blue={false}
                  />
                ) : null}
                {state.service_type === "appointment_sub_categories" ? (
                  <Statuses
                    label={"Apply to all Appointment Subcategories?"}
                    state={checkAllSubCategoriesState}
                    options={checkAll}
                    setstate={setCheckAllSubCategoriesState}
                    blue={false}
                  />
                ) : null}
              </div>
              {checkAllState.status === "No" && state.service_type === "products" && (
                <div>
                  {state && (
                    <MultiDropdown
                      state={state}
                      onSelect={(products) =>
                        setState((state) => ({ ...state, products }))
                      }
                      onRemove={(products) =>
                        setState((state) => ({ ...state, products }))
                      }
                      label={"Products"}
                      top={"30px"}
                      mb={"10px"}
                      accessor={"products"}
                      placeholder={
                        state.products.length === 0
                          ? "Add more products..."
                          : "e.g Select Products"
                      }
                      testid={"product-tags"}
                      type2={true}
                      options={options.products}
                    />
                  )}
                </div>
              )}
              {checkAllPlansState.status === "No" && state.service_type === "subscriptions" && (
                <div>
                  {state && (
                    <MultiDropdown
                      state={state}
                      onSelect={(plans) =>
                        setState((state) => ({ ...state, plans }))
                      }
                      onRemove={(plans) =>
                        setState((state) => ({ ...state, plans }))
                      }
                      label={"Plans"}
                      top={"30px"}
                      mb={"10px"}
                      accessor={"plans"}
                      placeholder={
                        state.plans.length === 0
                          ? "Add more plans..."
                          : "e.g Select Plans"
                      }
                      testid={"plans-tags"}
                      type2={true}
                      options={options.plans}
                    />
                  )}
                </div>
              )}
              {checkAllSubCategoriesState.status === "No" && state.service_type === "appointment_sub_categories" && (
                <div>
                  {state && (
                    <MultiDropdown
                      state={state}
                      onSelect={(appointment_subcategories) =>
                        setState((state) => ({
                          ...state,
                          appointment_subcategories,
                        }))
                      }
                      onRemove={(appointment_subcategories) =>
                        setState((state) => ({
                          ...state,
                          appointment_subcategories,
                        }))
                      }
                      label={"Appointment Subcategories"}
                      top={"30px"}
                      mb={"10px"}
                      accessor={"appointment_subcategories"}
                      placeholder={
                        state.appointment_subcategories.length === 0
                          ? "Add more subcategories..."
                          : "e.g Select Appointment Subcategory"
                      }
                      testid={"subcategories-tags"}
                      type2={true}
                      options={options.appointment_subcategories}
                      groupedBy={"category_name"}
                    />
                  )}
                </div>
              )}

              <ModalButton
                isLoading={isEditing}
                text={"Save Changes"}
                type={"submit"}
                testId={"button"}
                disabled={disabled}
                loadingText={"Saving..."}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isEditing: state.coupons.isEditing,
  editSuccess: state.coupons.editSuccess,
  getSuccess: state.products.getSuccess,
  products: state.products.allProducts,
  plans: state.plan.plans,
  categories: state.appointment.categories,
});

const mapDispatchToProps = (dispatch) => ({
  editCoupon: (id, payload) => dispatch(editCoupon(id, payload)),
  getProducts: (page, limit, all) => dispatch(getAllProducts(page, limit, all)),
  getAllCategories: () => dispatch(getAppointmentCategories()),
  getPlans: () => dispatch(getAppointmentCategories()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCoupon);
