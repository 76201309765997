import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import Modal, { ModalButton } from "../../Modal/Modal";
import Input from "../../Input/Input";
import SelectInput from "../../Input/Select";
import { createUser } from "../../../redux/Actions/UsersActions";
import { connect } from "react-redux";
import { AddConsultantSchema } from "../../../utils/validation/validation";
import TextArea from "../../../components/Input/TextArea";

const AddConsultant = (props) => {
  const { displayModal, closeModal, createUser, isCreating, createSuccess, getUsers } =
    props;

  useEffect(
    () => {
      if (createSuccess) {
        document.getElementById("addConsultant").reset();
        getUsers(1, 10, "consultant", false)
      }
    },
    // eslint-disable-next-line
    [createSuccess]
  );

  const [gender, setGender] = useState("");

  const options = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const updateSelection = (data) => {
    setGender(data.value);
  };
  const initialValues = {
    first_name: "",
    last_name: "",
    username: "",
    gender: "",
    email: "",
    phone_number: "",
    full_address: "",
    registration_no: "",
    specialization: "",
    about: "",
    years_of_experience: "",
  };

  const submitValues = (values) => {
    const payload = {
      first_name: values.first_name,
      last_name: values.last_name,
      username: values.username,
      gender: gender,
      email: values.email,
      phone_number: values.phone_number,
      full_address: values.full_address,
      registration_no: values.registration_no,
      specialization: values.specialization,
      about: values.about,
      years_of_experience: values.years_of_experience.toString(),
    };

    createUser("consultant", payload);
  };

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Add New Consultant"}
      width={"568px"}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => submitValues(values)}
        validationSchema={AddConsultantSchema}
      >
        {({ isValid, dirty, setFieldValue }) => {
          return (
            <Form id="addConsultant">
              <div className="form-grid3">
                <Field
                  name="first_name"
                  label="First Name"
                  placeholder="Enter first name"
                  component={Input}
                  emptyValue={setFieldValue}
                />
                <Field
                  name="last_name"
                  label="Last Name"
                  placeholder="Enter last name"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>
              <div className="form-grid2">
                <Field
                  name="username"
                  label="User Name"
                  placeholder="Enter user name"
                  component={Input}
                  emptyValue={setFieldValue}
                />
                <SelectInput
                  label={"Gender"}
                  options={options}
                  onChange={(data) => updateSelection(data)}
                  success={createSuccess}
                />
              </div>
              <div className="form-grid2">
                <Field
                  name="email"
                  label="Email"
                  placeholder="Enter email address"
                  component={Input}
                  emptyValue={setFieldValue}
                />
                <Field
                  name="phone_number"
                  label="Phone Number"
                  placeholder="Enter phone number"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>
              <div className="form-grid2">
                <Field
                  name="specialization"
                  label="Specialization"
                  placeholder="Enter specialization"
                  component={Input}
                  emptyValue={setFieldValue}
                />
                <Field
                  name="registration_no"
                  label="Registration No."
                  placeholder="Enter registration number"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>
              <div className="form-grid2">
                <Field
                  name="full_address"
                  label="Full Address"
                  placeholder="Enter full address"
                  component={Input}
                  emptyValue={setFieldValue}
                />
                <Field
                  type="number"
                  name="years_of_experience"
                  label="Years of Experience"
                  placeholder="Enter years of experience"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>
              <div className="form-grid">
                <Field
                  name="about"
                  label="Description"
                  placeholder="Enter description"
                  component={TextArea}
                  emptyValue={setFieldValue}
                />
              </div>

              <ModalButton
                text={"Create Consultant"}
                type={"submit"}
                testId={"button"}
                loadingText={"Creating..."}
                isLoading={isCreating}
                disabled={gender === "" || isCreating || !(isValid && dirty)}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
const mapStateToProps = (state) => ({
  isCreating: state.users.isCreating,
  createSuccess: state.users.createSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  createUser: (type, payload) => dispatch(createUser(type, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddConsultant);
