import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";
import downloadCSV from "../../utils/functions/csvDownloader";

export const getReportsStart = () => ({
  type: Actions.GET_REPORTS_START,
});

export const getReportsSuccess = () => ({
  type: Actions.GET_REPORTS_SUCCESS,
});

export const nodataReports = () => ({
  type: Actions.NO_DATA_REPORTS
})

export const getReports = (start_date, end_date, ) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  dispatch(getReportsStart())

  API.get(
    `reports?start_date=${start_date}&end_date=${end_date}`
    ).then((response) => {
    dispatch(getReportsStart())
    const {data, ok} = response
    if (ok) {
      if (!data) {
       return dispatch(nodataReports())
      }
      dispatch(getReportsSuccess())
      downloadCSV(data, "report")

    } else {
        responseHandler(response, "Failed!")
    }
  })
};
