import React from "react";
import { rolesColumns } from "../TableData/Profile/Roles";
import Table from "../Tables/Table";

const Roles = (props) => {
  const { getRoles, isGetting, getRolesSuccess, roles } = props;

  return (
    <div>
      <Table
        columns={rolesColumns}
        data={roles}
        getData={getRoles}
        emptyText1={"No added roles"}
        isLoading={isGetting}
        success={getRolesSuccess}
        total={roles && roles.length}
        emptyResultText={""}
        className="table6"
      />
    </div>
  );
};

export default Roles;
