import React from "react";
import Appointment2 from "../Appointment2";

export const HfcpRight = (props) => {
  const { data } = props;
  return (
    <div>
      <div className="info">
        <div className="info-top">
          <h4>Recents Appointments</h4>
        </div>
        <Appointment2 data={data} />
      </div>
    </div>
  );
};
