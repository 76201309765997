import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { getAppointmentCategories } from "../../redux/Actions/AppointmentActions";
import { getAllPlans } from "../../redux/Actions/PaymentPlanActions";
import { getAllProducts } from "../../redux/Actions/ProductActions";
import {
  assignCategoryOptions,
  assignSubcategoriesOptions,
} from "../../utils/functions/UpdateValues";
import Input from "../Input/Input";
import SelectInput from "../Input/Select";
import CancelIcon from "../../assets/plus.svg";
import { ModalButton } from "../Modal/Modal";
import { filterEnterpriseFacility, filterEnterpriseServices } from "../../utils/functions/FilterObject";
import ImportantText from "../Wrappers/ImportantText";
import UploadImage from "../Products/UploadImage";
import { uploadImage } from "../../redux/Actions/UploadActions";
import EnterpriseFacilityInput from "./EnterpriseFacilityInput";

const CreateEnterprise = (props) => {
  const {
    state,
    setState,
    products,
    plans,
    categories,
    getProducts,
    getPlans,
    getAllCategories,
    initialValues,
    submitValues,
    isCreating,
    isUploading,
    uploadFile,
  } = props;
  const updateStateService = (service, field) => {
    setState({
      ...state,
      [service]: state[service].concat([field]),
    });
  };

  const [options, setOptions] = useState({
    products: null,
    plans: null,
    appointment_subcategories: null,
  });

  useEffect(() => {
    getProducts(1, 10, true);
    getPlans();
    getAllCategories();
  }, []);

  useEffect(() => {
    if (
      plans.plans?.length > 0 &&
      products?.length > 0 &&
      categories?.length > 0
    ) {
      setOptions({
        ...options,
        products: assignCategoryOptions(products),
        plans: assignCategoryOptions(plans.plans),
        appointment_subcategories: assignSubcategoriesOptions(
          categories,
          "dropdown"
        ),
      });
    }
  }, [products, plans, categories]);

  const types = [
    { label: "Products", value: "products" },
    { label: "Subscriptions", value: "subscriptions" },
    {
      label: "Appointment Sub Categories",
      value: "appointment_sub_categories",
    },
  ];
  const avaialableTypes = [
    {
      label: "True",
      value: true,
    },
    {
      label: "False",
      value: false,
    },
  ];

  const removeCategory = (id) => {
    const newService =
      state.services && state.services.filter((item, index) => index !== id);
    setState({ ...state, services: newService });
  };
  const updateAvailableData = (index, data) => {
    const newService = state.services.map((service, idx) => {
      if (index !== idx) return service;
      return { ...service, available: data };
    });
    setState({ ...state, services: newService });
  };
  const updateRecommendData = (index, data) => {
    const newService = state.services.map((service, idx) => {
      if (index !== idx) return service;
      return { ...service, free_if_recommended: data };
    });
    setState({ ...state, services: newService });
  };
  const updateServiceType = (index, data) => {
    const newService = state.services.map((service, idx) => {
      if (index !== idx) return service;
      return { ...service, enterprise_service_type: data };
    });
    setState({ ...state, services: newService });
  };
  const updateServiceCategoryType = (index, data, stateVal) => {
    const newService = state.services.map((service, idx) => {
      if (index !== idx) return service;
      return { ...service, [stateVal]: data };
    });
    setState({ ...state, services: newService });
  };
  const updateAmount = (index, type) => (e) => {
    const newService = state.services.map((service, idx) => {
      if (index !== idx) return service;
      return {
        ...service,
        [type]: e.target.value,
      };
    });

    setState({ ...state, services: newService });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => submitValues(values)}
    >
      {({ isValid, dirty, setFieldValue }) => {
        const disabled =
          !(isValid && dirty) ||
          isCreating ||
          (state.services &&
            filterEnterpriseServices(state.services)) || (state.enterprise_facilities &&
            filterEnterpriseFacility(state.enterprise_facilities))
            || !state.file_name
            return (
          <Form id="addenterpriseform">
            <UploadImage
              uploadFile={uploadFile}
              id={3333}
              text={<ImportantText label={"Add Enterprise Logo"} />}
              type={"single"}
              isUploading={isUploading}
              state={state}
              setState={setState}
              changeText={"Change Enterprise Logo"}
            />
            <div
              style={{
                marginTop: "50px",
              }}
              className="grid-4-one"
            >
              <Field
                name="name"
                label="Enterprise Name"
                placeholder="Enter Enterprise Name"
                component={Input}
                emptyValue={setFieldValue}
              />

              <Field
                name="domain"
                label="Enterprise Domain"
                placeholder="Enter Enterprise Domain"
                component={Input}
                emptyValue={setFieldValue}
              />
            </div>
            <div
              style={{
                marginTop: "50px",
              }}
              className="appointment-subcategories-header "
            >
              Enterprise Facility
            </div>
            <div
              onClick={() =>
                updateStateService("enterprise_facilities", {
                  facility_id: "",
                  default: false,
                })
              }
              className=""
              style={{
                marginTop: "-30px",
                display: "flex",
                cursor: "pointer",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ marginRight: "1rem" }} className="">
                <FeatherIcon icon={"plus-circle"} />
              </div>
              <p>Add New Facility</p>
            </div>
            <EnterpriseFacilityInput state={state} setState={setState} />

            <div
              style={{
                marginTop: "50px",
              }}
              className="appointment-subcategories-header "
            >
              Enterprise Services
            </div>
            <div
              onClick={() =>
                updateStateService("services", {
                  enterprise_service_type: "",
                  amount: null,
                  model_id: null,
                  available: false,
                  free_if_recommended: false,
                })
              }
              className=""
              style={{
                marginTop: "-30px",
                display: "flex",
                cursor: "pointer",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ marginRight: "1rem" }} className="">
                <FeatherIcon icon={"plus-circle"} />
              </div>
              <p style={{}}>Add New Service</p>
            </div>
            {state.services &&
              state.services.map((service, index) => (
                <div
                  key={index}
                  className="top-spacer"
                  style={{ position: "relative" }}
                >
                  {(index > 0 || state.services.length > 1) && (
                    <div
                      className="appointment-subcategories-grid-delete"
                      data-testid={`deleteSlot${index}`}
                      onClick={() => removeCategory(index)}
                    >
                      <img src={CancelIcon} alt="cancel icon" />
                    </div>
                  )}

                  <div
                    style={{
                      marginTop: "62px",
                    }}
                    className="grid-2-one"
                  >
                    <SelectInput
                      label={"Service Type"}
                      options={types}
                      onChange={(data) => updateServiceType(index, data.value)}
                    />

                    {service.enterprise_service_type ? (
                      <SelectInput
                        label={
                          service.enterprise_service_type === "products"
                            ? "Select Product"
                            : service.enterprise_service_type ===
                              "subscriptions"
                            ? "Select Subscription Plan"
                            : "Select Appointment Subcategories"
                        }
                        options={
                          service.enterprise_service_type === "products"
                            ? options?.products
                            : service.enterprise_service_type ===
                              "subscriptions"
                            ? options?.plans
                            : options?.appointment_subcategories
                        }
                        onChange={(data) =>
                          updateServiceCategoryType(
                            index,
                            data.value,
                            "model_id"
                          )
                        }
                      />
                    ) : (
                      ""
                    )}
                    <Input
                      value={service.amount}
                      onChange={updateAmount(index, "amount")}
                      label={<ImportantText label={"Amount"} />}
                      placeholder="Enter Amount"
                      testId={`updateCategory${index}`}
                    />
                    <Input
                      value={service.free_budget_per_user}
                      onChange={updateAmount(index, "free_budget_per_user")}
                      label={<ImportantText label={"Free Budget Per User"} />}
                      placeholder="Enter Budget"
                      testId={`updateCategory${index}`}
                    />
                    <SelectInput
                      label={"Available"}
                      options={avaialableTypes}
                      onChange={(data) =>
                        updateAvailableData(index, data.value)
                      }
                      //   defaultOption={service.available}
                    />
                    <SelectInput
                      label={"Free If Recommended"}
                      options={avaialableTypes}
                      onChange={(data) =>
                        updateRecommendData(index, data.value)
                      }
                      //   defaultOption={service.free_if_recommencded}
                    />
                  </div>
                </div>
              ))}
            <ModalButton
              isLoading={isCreating}
              text={"Create Enterprises"}
              type="submit"
              testId={"button"}
              s
              loadingText={"Creating"}
              disabled={disabled}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
const mapStateToProps = (state) => ({
  getSuccess: state.products.getSuccess,
  products: state.products.allProducts,
  plans: state.plan.plans,
  categories: state.appointment.categories,
  isCreating: state.enterprise.isCreating,
  isCreatingSuccess: state.enterprise.isCreatingSuccess,
  isUploading: state.upload.isUploading,
  uploadSuccess: state.upload.uploadSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getProducts: (page, limit, all) => dispatch(getAllProducts(page, limit, all)),
  getPlans: () => dispatch(getAllPlans()),
  getAllCategories: () => dispatch(getAppointmentCategories()),
  uploadFile: (payload) => dispatch(uploadImage(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEnterprise);
