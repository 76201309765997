import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DeleteButton, PageButton } from "../Button/Button";
import { cancelModal, viewModal } from "../Modal/Modal";
import DeleteModal from "../Modal/DeleteModal";

const UserHeader = (props) => {
  const {
    clientPicture,
    clientName,
    status,
    chatPage,
    singleStateId,
    deleteUser,
    isDeleting,
    deleteSuccess,
    ClientId,
  } = props;
  const [state, setState] = useState({
    deleteModal: false,
  });
  const openModal = (type) => viewModal(type, state, setState);
  const closeModal = (type) => cancelModal(type, state, setState);

  useEffect(
    () => {
      if (deleteSuccess) {
        closeModal();
        history.push("/users");
      }
    }, // eslint-disable-next-line
    [deleteSuccess]
  );
  const history = useHistory();

  const goToChatPage = () => {
    history.push({
      pathname: chatPage,
      state: ClientId,
    });
  };
  const id = singleStateId && singleStateId.id;

  return (
    <div className="top-wrap">
      <div className="top-wrap-top">
        <div className="client-header-top">
          <div className="client-header-top-image">
            <img src={clientPicture} alt="client" />
          </div>

          <div className="client-header-top-text">
            <h2>{clientName}</h2>
            <p>{status}</p>
          </div>
        </div>
        <div className="left-buttons">
          {window.location.pathname !== `/users/hfcp/${id}` && (
            <PageButton
              width="103px"
              height={"32px"}
              children="View Chats"
              background="#fff"
              marginRight={"20px"}
              type={"button"}
              onClick={() => goToChatPage()}
              testId={"goToChatPage"}
            />
          )}
          <DeleteButton
            children={"Delete"}
            width={"67px"}
            color={"#DC0000"}
            marginTop={"0px"}
            type={"submit"}
            onClick={() => openModal("deleteModal")}
            testId={"deleteButton"}
          />
        </div>

        <DeleteModal
          displayModal={state.deleteModal}
          title={"Delete this client?"}
          width={"463px"}
          text={"Delete"}
          onClick={() => deleteUser(ClientId)}
          disabled={isDeleting}
          isLoading={isDeleting}
          loadingText={"Deleting..."}
          children={
            "Once you delete this client it won’t be possible to undo this action. Delete client?"
          }
          closeModal={() => closeModal("deleteModal")}
        />
      </div>
    </div>
  );
};

export default UserHeader;
