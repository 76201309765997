import Multiselect from "multiselect-react-dropdown";

const MultiDropdown = (props) => {
  const {
    state,
    onSelect,
    onRemove,
    accessor,
    label,
    type2,
    mb,
    options,
    top,
    placeholder,
    chipBorderRadius = "4px",
    chipPadding = "",
    chipTextColor = "#303B54",
    multiselectRef,
    testid,
    groupedBy
  } = props;

  return (
    <div className="input-wrapper" style={{ marginTop: top }}>
      <div className="input-container">
        {type2 && (
          <p className="label-input2" style={{ marginBottom: mb }}>
            {label}
          </p>
        )}
        <div className="multitags">
          <Multiselect
            groupBy={groupedBy}
            ref={multiselectRef}
            options={options ?? []} // Options to display in the dropdown
            selectedValues={state[accessor] ?? []} // Preselected value to persist in dropdown
            onSelect={onSelect} // Function will trigger on select event
            onRemove={onRemove} // Function will trigger on remove event
            displayValue="name" // Property name to display in the dropdown options
            customCloseIcon={
              <div className="multidrop-icon">
                <svg
                  width="9"
                  height="9"
                  viewBox="0 0 9 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 3.50016L8.00016 0L9 0.999843L5.49984 4.5L9 8.00016L8.00016 9L4.5 5.49984L0.999843 9L0 8.00016L3.50016 4.5L0 0.999843L0.999843 0L4.5 3.50016Z"
                    fill="#D8D8D8"
                  />
                </svg>
              </div>
            }
            style={{
              chips: {
                background: "#F2F2F2",
                borderRadius: chipBorderRadius,
                color: chipTextColor,
                fontFamily: "Inter",
                padding: chipPadding,
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "16px",
                lineHeight: "24px",
              },
              searchBox: {
                background: "#FFFFFF",
                border: "none",
                borderRadius: "4px",
                minHeight: "48px",
                padding: "10px",
                boxSizing: "border-box",
              },
            }}
            placeholder={placeholder}
            id={testid}
          />
          <span className="multiselect-arrw"></span>
        </div>
        {!type2 && <span className="text-label3">{label}</span>}
      </div>
    </div>
  );
};

MultiDropdown.defaultProps = {
  top: "0px",
  mb: "0px",
  accessor: "category",
};

export default MultiDropdown;
