import React from "react";
import CompleteForm from "../../components/Onboarding/CompleteForm";
import { Headers } from "../../components/Wrappers/AuthWrapper";

const CompleteProfile = () => {
  return (
    <div>
      <Headers
        title1={"Change password"}
        title2={"Kindly change your password to login anytime"}
      />
      <CompleteForm />
    </div>
  );
};

export default CompleteProfile;
