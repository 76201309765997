export const filterObj = (obj) => {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === "" ||
      obj[propName] === undefined
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

export const filterArrayObj = (items) => {
  for (var i in items) {
    for (var propName in items[i]) {
      if (
        items[i][propName] === null ||
        items[i][propName] === "" ||
        items[i][propName] === undefined
      ) {
        delete items[i][propName];
      }
    }
  }
  return items;
};

export const filterOptions = (a, b) => {
  return b.filter((x) =>
    x.name === a.charAt(0).toUpperCase() + a.slice(1) ? !b.includes(x) : b
  );
};

export const filterOptionsLabel = (a, b) => {
  return b.filter((x) =>
    x.label === a.charAt(0).toUpperCase() + a.slice(1) ? !b.includes(x) : b
  );
};

export const filterArray = (array) => {
  let filtered =
    array &&
    array.filter(function (el) {
      return el !== "";
    });
  return filtered;
};

export const checkIfUrlExists = (array, index) => {
  let check = array && array[index];
  let checkUrl = check && check.length !== 0;
  return checkUrl;
};

export const assignProductImages = (data) => {
  let newData = data && data.map((item) => item.image_url);
  return newData;
};

export const assignProductImagesPayload = (data) => {
  let newData = [];
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      const each = data[i].image_url;
      newData && newData.push(each);
    }
    return newData;
  }
};

export const filterProductImages = (data) => {
  let newData = [];
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      const each = Object.assign({
        id: data[i].id,
        image_url: data[i].image_url,
        loaded: true,
      });
      newData && newData.push(each);
    }
    return newData;
  }
};

export const filterUploadImage = (data, str) => {
  const each = Object.assign({
    id: Math.round(Math.random() * 100),
    image_url: str,
    loaded: true,
  });
  data && data.push(each);
  return data;
};

export const filterRoles = (selectedRole, roles) => {
  const roleIndex = roles && roles.findIndex((x) => x.id === selectedRole);
  const roleData = roles && roles.find((x) => x.id === selectedRole);
  const roleName = roleData && roleData.id;
  return { roleIndex, roleName, roleData };
};

export const filterObject = (obj) => {
  let filteredObj =
    obj &&
    obj.length > 0 &&
    obj.filter(function (values) {
      const name = values.name && values.name.length;
      const description = values.description && values.description.length;
      const image = values.image_url && values.image_url.length;
      if (name <= 0 || description <= 0 || image <= 0) return false;
      else return true;
    });
  return filteredObj;
};

export const filterObj2 = (array) => {
  let newArray = [];
  let data = array && filterObj(array);
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      const each = Object.assign({
        id: data[i].id,
        name: data[i].name,
        image_url: data[i].image_url,
        description: data[i].description,
        direction: data[i].direction,
        percentage_effectiveness: data[i].percentage_effectiveness,
        duration: data[i].duration,
        hormones: data[i].hormones,
        price_range: data[i].price_range,
        amount: data[i].amount,
      });
      newArray && newArray.push(each);
    }
    return newArray;
  }
};

export const filterObj3 = (array) => {
  let newArray = [];
  let data = array && filterObj(array);
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      const each = Object.assign({
        id: data[i].id,
        name: data[i].name,
        image_url: data[i].image_url,
        description: data[i].description,
        direction: data[i].direction,
        effectiveness: data[i].percentage_effectiveness,
        duration: data[i].duration,
        hormones: data[i].hormones,
        price_range: data[i].price_range,
        amount: data[i].amount,
        status: data[i].status,
        rank: data[i].rank,
      });
      newArray && newArray.push(each);
    }
    return newArray;
  }
};

export const filterEnterpriseServices = (objectsArray) => {
  var wasNull = false;
  for (var i in objectsArray) {
    if (
      objectsArray[i].enterprise_service_type === "" ||
      !objectsArray[i].amount ||
      !objectsArray[i].model_id
    )
      wasNull = true;
  }
  return wasNull;
};

export const filterEnterpriseFacility = (objectsArray) => {
  var wasNull = false;
  for (var i in objectsArray) {
    if (objectsArray[i].facility_id === "") {
      wasNull = true;
    }
  }
  return wasNull;
};

export const filterAppointmentSubcategories = (objectsArray) => {
  let wasNull = false;
  for (var i in objectsArray) {
    if (
      objectsArray[i].name === "" ||
      !objectsArray[i].name ||
      objectsArray[i].image_url === "" ||
      !objectsArray[i].image_url ||
      objectsArray[i].description === "" ||
      !objectsArray[i].description
    )
      wasNull = true;
  }
  return wasNull;
};

export const filterFacilityCategories = (objectsArray) => {
  var wasNull = false;
  for (var i in objectsArray) {
    if (
      objectsArray[i].category_id === "" ||
      (objectsArray[i].sub_category_ids &&
        objectsArray[i].sub_category_ids.length === 0)
    )
      wasNull = true;
  }
  return wasNull;
};

export const filterFacilityPayload = (data) => {
  let newArray = [];
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      const each = Object.assign({
        category_id: data[i].category_id,
        sub_category_ids: data[i].sub_category_ids.map(
          (item) => item.category_id
        ),
      });
      newArray && newArray.push(each);
    }
    return newArray;
  }
};
