import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  changeSubscriptionPlanStatus,
  getAllPlans,
  getAllSubscribers,
} from "../../redux/Actions/PaymentPlanActions";
import DeleteModal from "../Modal/DeleteModal";

const ActivatePlan = (props) => {
  const {
    isChangingStatus,
    changeSuccess,
    changePlanStatus,
    getAllPlans,
    data,
    type,
    getSubscribers,
  } = props;

  const changeStatus = () => {
    const payload = {
      plan_id: data && data.plan_id,
      user_id: (data && data.user_id) || (data && data.id),
      status: "activated",
    };
    changePlanStatus(payload);
  };

  useEffect(
    () => {
      if (changeSuccess) {
        props.closeModal();
        type === "subscribers" ? getSubscribers(1, 10) : getAllPlans();
      }
    }, // eslint-disable-next-line
    [changeSuccess]
  );

  return (
    <DeleteModal
      displayModal={props.displayModal}
      closeModal={props.closeModal}
      title={"Activate this plan?"}
      width={"463px"}
      text={"Activate"}
      onClick={changeStatus}
      disabled={isChangingStatus}
      isLoading={isChangingStatus}
      activate={true}
      loadingText={"Activating..."}
      children={
        "Once you activate this plan, this plan would be active. Activate plan?"
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isChangingStatus: state.plan.isChangingStatus,
  changeSuccess: state.plan.changeSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getAllPlans: (page, limit) => dispatch(getAllPlans(page, limit)),
  getSubscribers: (page, limit) => dispatch(getAllSubscribers(page, limit)),
  changePlanStatus: (payload) =>
    dispatch(changeSubscriptionPlanStatus(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivatePlan);
