import Actions from '../Types';

const initialState = {
  isGetting: false,
  overviewData: {},
  getSuccess: false,
  error: null,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_OVERVIEW_START:
      return {
        ...state,
        isGetting: true,
        overviewData: {},
        getSuccess: false,
        error: null,
      };
    case Actions.GET_OVERVIEW_SUCCESS:
      return {
        ...state,
        isGetting: false,
        overviewData: action.payload,
        getSuccess: true,
        error: null,
      };
    case Actions.GET_OVERVIEW_FAILURE:
      return {
        ...state,
        isGetting: false,
        overviewData: {},
        getSuccess: false,
        error: true,
      };
    default:
      return state;
  }
};

export default reduxState;
