import React, { useEffect } from "react";
import {
  changeUserAppointmentStatus,
  getUserAppointments,
} from "../../redux/Actions/UserAppointmentActions";
import DeleteModal from "../Modal/DeleteModal";
import { connect } from "react-redux";
import { getOverview } from "../../redux/Actions/OverviewActions";
import { useHistory } from "react-router";
import { useOverviewTypeContext } from "../../contexts/OverviewTypeContext";

const DeclineAppointment = (props) => {
  const {
    declineAppointment,
    isDeclining,
    declineSuccess,
    getAppointments,
    fetchOverview,
    data,
    limit,
  } = props;
  const id = data && data.id;
  const history = useHistory();
  const type = useOverviewTypeContext();

  useEffect(
    () => {
      if (declineSuccess) {
        props.closeModal();
        if (history?.location?.pathname === "/appointment") {
          getAppointments(1, limit);
          return;
        }
        fetchOverview(type?.overviewType);
      }
    }, // eslint-disable-next-line
    [declineSuccess]
  );

  const changeStatus = (id) => {
    declineAppointment(id, "declined");
  };

  return (
    <DeleteModal
      displayModal={props.displayModal}
      closeModal={props.closeModal}
      title={"Decline this appointment?"}
      width={"463px"}
      text={"Decline"}
      onClick={() => changeStatus(id)}
      disabled={isDeclining}
      isLoading={isDeclining}
      loadingText={"Declining..."}
      children={
        "Once you decline this appointment, it won’t be possible to undo this action. Decline appointment?"
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isDeclining: state.userappointment.isChangingStatus,
  declineSuccess: state.userappointment.changeSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  declineAppointment: (id, status) =>
    dispatch(changeUserAppointmentStatus(id, status)),
  getAppointments: (page, limit) => dispatch(getUserAppointments(page, limit)),
  fetchOverview: (queries) => dispatch(getOverview(queries)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeclineAppointment);
