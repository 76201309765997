import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";

//GET ROLES
export const getAllRolesStart = () => ({
  type: Actions.GET_ALL_ROLES_START,
});

export const getAllRolesSuccess = (payload) => ({
  type: Actions.GET_ALL_ROLES_SUCCESS,
  payload,
});

export const getAllRolesFailure = (payload) => ({
  type: Actions.GET_ALL_ROLES_FAILURE,
  payload,
});

//CREATE ROLE
export const createRoleStart = () => ({
  type: Actions.CREATE_ROLE_START,
});

export const createRoleSuccess = (payload) => ({
  type: Actions.CREATE_ROLE_SUCCESS,
  payload,
});

export const createRoleFailure = (payload) => ({
  type: Actions.CREATE_ROLE_FAILURE,
  payload,
});

//EDIT ROLE
export const editRoleStart = () => ({
  type: Actions.EDIT_ROLE_START,
});

export const editRoleSuccess = (payload) => ({
  type: Actions.EDIT_ROLE_SUCCESS,
  payload,
});

export const editRoleFailure = (payload) => ({
  type: Actions.EDIT_ROLE_FAILURE,
  payload,
});

//DEACTIVATE ROLE
export const deactivateRoleStart = () => ({
  type: Actions.DEACTIVATE_ROLE_START,
});

export const deactivateRoleSuccess = (payload) => ({
  type: Actions.DEACTIVATE_ROLE_SUCCESS,
  payload,
});

export const deactivateRoleFailure = (payload) => ({
  type: Actions.DEACTIVATE_ROLE_FAILURE,
  payload,
});

//DELETE ADMIN
export const deleteRolesStart = () => ({
  type: Actions.DELETE_ROLE_START,
});

export const deleteRolesSuccess = (payload) => ({
  type: Actions.DELETE_ROLE_SUCCESS,
  payload,
});

export const deleteRolesFailure = (payload) => ({
  type: Actions.DELETE_ROLE_FAILURE,
  payload,
});

export const getAllRoles = () => (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getAllRolesStart());
  API.get("/role").then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getAllRolesSuccess(data && data.data));
    } else {
      dispatch(getAllRolesFailure(data));
      responseHandler(response);
    }
  });
};

export const createRole = (payload) => (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(createRoleStart());
  API.post("/role", payload).then((response) => {
    const { data, ok } = response;
    responseHandler(response);
    if (ok) {
      dispatch(createRoleSuccess(data));
    } else {
      dispatch(createRoleFailure(data));
    }
  });
};

export const editRole = (payload) => (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(editRoleStart());
  API.put("/role", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(editRoleSuccess(data));
      responseHandler(response, "Success!");
    } else {
      dispatch(editRoleFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const deleteRoles = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(deleteRolesStart());
  API.delete(`/admin/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(deleteRolesSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(deleteRolesFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const deactivateRole = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(deactivateRoleStart());
  API.get("/role", payload).then(
    (response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(deactivateRoleSuccess(data && data.data));
        responseHandler(response, "Success!");
      } else {
        dispatch(deactivateRoleFailure(data));
        responseHandler(response, "Failed!");
      }
    }
  );
};
