import React, {useEffect} from "react";
import DateFilter from "../Input/DateFilter";
import Modal, { ModalButton } from "../Modal/Modal";

const FilterCustomer = (props) => {
  const {
    displayModal,
    closeModal,
    state,
    setState,
    isFiltering,
    filterSuccess,
    filterCustomers,
  } = props;
  const disabled = isFiltering;
  useEffect(() => {
    if (filterSuccess) {
        closeModal()
    }
  }, [filterSuccess])
  const handleFilter = () => {
    const payload = {
      createdFrom: state.createdFrom,
      createdTo: state.createdTo,
    };
    setState({ ...state, filterValues: payload });
    
    filterCustomers(1, 10, state.active1 ? "customer" : "influencer", payload);
  };
  return (
    <div>
      <Modal
        displayModal={displayModal}
        closeModal={closeModal}
        title={state.active1 ? "Filter Customers" : "Filter Influencers"}
        width={"496px"}
      >
        <DateFilter
          label={"From"}
          placeholder={"Select From Date"}
          name={"createdFrom"}
          setState={setState}
          state={state}
          success={filterSuccess}
        />
        <div className="top-spacer">
          <DateFilter
            label={"To"}
            placeholder={"Select To Date"}
            name={"createdTo"}
            setState={setState}
            state={state}
            success={filterSuccess}
          />
        </div>
        <ModalButton
          isLoading={isFiltering}
          disbled={disabled}
          testId={"button"}
          text={"Apply Filter"}
          onClick={() => handleFilter()}
          loadingText={"Filtering"}
        />
      </Modal>
    </div>
  );
};

export default FilterCustomer;
