import React, { useEffect } from "react";
import { connect } from "react-redux";
import { editRole, getAllRoles } from "../../../redux/Actions/SettingActions";
import { changeStatusPermissions } from "../../../utils/functions/CreateNewArray";
import DeleteModal from "../../Modal/DeleteModal";

const DeactivateRole = (props) => {
  const {
    displayModal,
    closeModal,
    isChangingStatus,
    changeStatus,
    data,
    getRoles,
    changeSuccess,
    status,
  } = props;

  const ActivateText =
    "Once you activate this role, this role would be active. Activate role?";
  const DeactivateText =
    "Once you deactivate this role, this role would be inactive. Deactivate role?";

  const permissionsPayload = data && changeStatusPermissions(data);

  const submitValues = () => {
    const payload = {
      name: data.id,
      description: data.description,
      status: status === "inactive" ? "Active" : "Inactive",
      ...permissionsPayload,
    };
    changeStatus(payload);
  };

  useEffect(
    () => {
      if (changeSuccess) {
        closeModal();
        getRoles();
      }
    },
    // eslint-disable-next-line
    [changeSuccess]
  );

  return (
    <DeleteModal
      displayModal={displayModal}
      closeModal={closeModal}
      title={
        status === "inactive" ? "Activate this role?" : "Deactivate this role?"
      }
      width={"463px"}
      text={status === "inactive" ? "Activate" : "Deactivate"}
      onClick={() => submitValues()}
      disabled={isChangingStatus}
      isLoading={isChangingStatus}
      activate={status === "inactive" ? true : false}
      loadingText={status === "inactive" ? "Activating..." : "Deactivating..."}
      children={status === "inactive" ? ActivateText : DeactivateText}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  changeStatus: (payload) => dispatch(editRole(payload)),
  getRoles: () => dispatch(getAllRoles()),
});

const mapStateToProps = (state) => ({
  isChangingStatus: state.roles.isEditing,
  changeSuccess: state.roles.editRoleSuccess,
});

export default connect(mapStateToProps, mapDispatchToProps)(DeactivateRole);
