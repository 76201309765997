import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";
import downloadCSV from "../../utils/functions/csvDownloader";

// GET USER APPOINTMENTS
export const getUserAppointmentsStart = () => ({
  type: Actions.GET_USER_APPOINTMENTS_START,
});

export const getUserAppointmentsSuccess = (payload) => ({
  type: Actions.GET_USER_APPOINTMENTS_SUCCESS,
  payload,
});

export const getUserAppointmentsFailure = (payload) => ({
  type: Actions.GET_USER_APPOINTMENTS_FAILURE,
  payload,
});

// GET SPECIALISTS
export const getSpecialistsStart = () => ({
  type: Actions.GET_SPECIALISTS_START,
});

export const getSpecialistsSuccess = (payload) => ({
  type: Actions.GET_SPECIALISTS_SUCCESS,
  payload,
});

export const getSpecialistsFailure = (payload) => ({
  type: Actions.GET_SPECIALISTS_FAILURE,
  payload,
});

// SEARCH USER APPOINTMENTS
export const searchUserAppointmentsStart = () => ({
  type: Actions.SEARCH_USER_APPOINTMENTS_START,
});

export const searchUserAppointmentsSuccess = (payload) => ({
  type: Actions.SEARCH_USER_APPOINTMENTS_SUCCESS,
  payload,
});

export const searchUserAppointmentsFailure = (payload) => ({
  type: Actions.SEARCH_USER_APPOINTMENTS_FAILURE,
  payload,
});

// FILTER USER APPOINTMENTS
export const filterUserAppointmentsStart = () => ({
  type: Actions.FILTER_USER_APPOINTMENTS_START,
});

export const filterUserAppointmentsSuccess = (payload) => ({
  type: Actions.FILTER_USER_APPOINTMENTS_SUCCESS,
  payload,
});

export const filterUserAppointmentsFailure = (payload) => ({
  type: Actions.FILTER_USER_APPOINTMENTS_FAILURE,
  payload,
});

// SCHEDULE USER APPOINTMENT
export const scheduleUserAppointmentStart = () => ({
  type: Actions.SCHEDULE_USER_APPOINTMENT_START,
});

export const scheduleUserAppointmentSuccess = (payload) => ({
  type: Actions.SCHEDULE_USER_APPOINTMENT_SUCCESS,
  payload,
});

export const scheduleUserAppointmentFailure = (payload) => ({
  type: Actions.SCHEDULE_USER_APPOINTMENT_FAILURE,
  payload,
});

// RESCHEDULE USER APPOINTMENT
export const resheduleUserAppointmentStart = () => ({
  type: Actions.RESCHEDULE_USER_APPOINTMENT_START,
});

export const resheduleUserAppointmentSuccess = (payload) => ({
  type: Actions.RESCHEDULE_USER_APPOINTMENT_SUCCESS,
  payload,
});

export const resheduleUserAppointmentFailure = (payload) => ({
  type: Actions.RESCHEDULE_USER_APPOINTMENT_FAILURE,
  payload,
});
export const rescheduleAppointmentReset = () => ({
  type: Actions.RESCHEDULE_USER_APPOINTMENT_RESET,
});

// DELETE USER APPOINTMENT
export const deleteUserAppointmentStart = () => ({
  type: Actions.DELETE_USER_APPOINTMENT_START,
});

export const deleteUserAppointmentSuccess = (payload) => ({
  type: Actions.DELETE_USER_APPOINTMENT_SUCCESS,
  payload,
});

export const deleteUserAppointmentFailure = (payload) => ({
  type: Actions.DELETE_USER_APPOINTMENT_FAILURE,
  payload,
});

// CHANGE USER APPOINTMENT STATUS
export const changeUserAppointmentStatusStart = () => ({
  type: Actions.CHANGE_USER_APPOINTMENT_STATUS_START,
});

export const changeUserAppointmentStatusSuccess = (payload) => ({
  type: Actions.CHANGE_USER_APPOINTMENT_STATUS_SUCCESS,
  payload,
});

export const changeUserAppointmentStatusFailure = (payload) => ({
  type: Actions.CHANGE_USER_APPOINTMENT_STATUS_FAILURE,
  payload,
});

// CHANGE USER CONFIRM STATUS
export const confirmUserAppointmentStart = () => ({
  type: Actions.CONFIRM_USER_APPOINTMENT_START,
});

export const confirmUserAppointmentSuccess = (payload) => ({
  type: Actions.CONFIRM_USER_APPOINTMENT_SUCCESS,
  payload,
});

export const confirmUserAppointmentFailure = (payload) => ({
  type: Actions.CONFIRM_USER_APPOINTMENT_FAILURE,
  payload,
});

export const exportAppointmentDataStart = () => ({
  type: Actions.EXPORT_APPOINTMENT_START,
});
export const exportAppointmentDataSuccess = () => ({
  type: Actions.EXPORT_APPOINTMENT_SUCCESS,
});
export const exportAppointmentDataFailure = () => ({
  type: Actions.EXPORT_APPOINTMENT_FAILURE,
});

export const getUserAppointments = (page, limit) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getUserAppointmentsStart());
  API.get(`appointment?limit=${limit}&page=${page}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getUserAppointmentsSuccess(data && data.data));
    } else {
      dispatch(getUserAppointmentsFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const getSpecialists = (type) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getSpecialistsStart());
  API.get(`/user/specialist?type=${type}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getSpecialistsSuccess(data && data.data));
    } else {
      dispatch(getSpecialistsFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const searchUserAppointments =
  (page, limit, searchValue) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

    dispatch(searchUserAppointmentsStart());
    API.get(
      `appointment?limit=${limit}&page=${page}&search=${searchValue}`
    ).then((response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(searchUserAppointmentsSuccess(data && data.data));
      } else {
        dispatch(searchUserAppointmentsFailure(data));
        responseHandler(response, "Failed!");
      }
    });
  };

export const filterUserAppointments =
  (page, limit, payload) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

    dispatch(filterUserAppointmentsStart());
    const { start_date, end_date, status } = payload;

    const url =
      start_date && end_date && !status
        ? `appointment/filter?start_date=${start_date}&end_date=${end_date}&limit=${limit}&page=${page}`
        : status && !(start_date && end_date)
        ? `appointment/filter?status=${status}&limit=${limit}&page=${page}`
        : `appointment/filter?start_date=${start_date}&end_date=${end_date}&status=${status}&limit=${limit}&page=${page}`;
    API.get(url).then((response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(filterUserAppointmentsSuccess(data && data.data));
      } else {
        dispatch(filterUserAppointmentsFailure(data));
        responseHandler(response, "Failed!");
      }
    });
  };

export const exportAppointmentData =
  (start_date, end_date, status) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
    dispatch(exportAppointmentDataStart());
     const url =
      start_date && end_date && !status
        ? `appointment/export?start_date=${start_date}&end_date=${end_date}`
        : status && !(start_date && end_date)
        ? `appointment/export?status=${status}`
        : `appointment/export?start_date=${start_date}&end_date=${end_date}&status=${status}`;
    API.get(url).then((response) => {
      const { data, ok } = response;
      if (ok) {
        downloadCSV(data, "user-appointment");
        dispatch(exportAppointmentDataSuccess());
      } else {
        dispatch(exportAppointmentDataFailure());
        responseHandler(response, "Failed");
      }
    });
  };

export const scheduleUserAppointment = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(scheduleUserAppointmentStart());
  API.post("appointment/schedule", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(scheduleUserAppointmentSuccess(data && data.data));
      responseHandler(response, "Sucess!");
    } else {
      dispatch(scheduleUserAppointmentFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const resheduleUserAppointment = (id, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(resheduleUserAppointmentStart());
  API.patch(`/appointment/reschedule/${id}`, payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(resheduleUserAppointmentSuccess(data && data.data));
      responseHandler(response, "Sucess!");
    } else {
      dispatch(resheduleUserAppointmentFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const resetRescheduleSuccess = () => (dispatch) => {
  dispatch(rescheduleAppointmentReset());
};

export const deleteUserAppointment = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(deleteUserAppointmentStart());
  API.delete(`/appointment/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(deleteUserAppointmentSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(deleteUserAppointmentFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const changeUserAppointmentStatus = (id, status) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(changeUserAppointmentStatusStart());
  API.patch(`/appointment/change-status/${id}?status=${status}`).then(
    (response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(changeUserAppointmentStatusSuccess(data && data.data));
        responseHandler(response, "Success!");
      } else {
        dispatch(changeUserAppointmentStatusFailure(data));
        responseHandler(response, "Failed!");
      }
    }
  );
};
export const confirmUserAppointment = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(confirmUserAppointmentStart());
  API.patch(`/appointment/confirm/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(confirmUserAppointmentSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(confirmUserAppointmentFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};
