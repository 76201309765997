import React from "react";
import { AuthButton, PageButton } from "../Button/Button";
import { Field, Form, Formik } from "formik";
import Input from "../Input/Input";
import { UpdateProfileSchema } from "../../utils/validation/validation";
import ProfileImage from "./ProfileImage";

const UpdateProfile = (props) => {
  const {
    initialValues1,
    submitValues,
    onClick,
    disabled,
    state,
    setState,
    isLoading,
  } = props;
  return (
    <Formik
      initialValues={initialValues1}
      onSubmit={(values) => submitValues(values)}
      validationSchema={UpdateProfileSchema}
    >
      {({ isValid, dirty, values, setFieldValue }) => {
        const disableBtn =
          disabled ||
          values.first_name === "" ||
          values.last_name === "" ||
          values.phone_number === "" ||
          values.address === "" ||
          state.image === "" ||
          isLoading;

        return (
          <Form id="form">
            <div className="user-profile-top">
              <div className="profile-page-left">
                <div className="profile-page-left-header">
                  <h2>Profile</h2>
                  <h5>Helps you set up your admin profile</h5>
                </div>
              </div>
              <div className="user-profile-top-right">
                <PageButton
                  width="167px"
                  children="Edit"
                  background="#fff"
                  type={"button"}
                  onClick={onClick}
                />
              </div>
            </div>

            <div className="user-profile-bottom profile-spacer">
              <div className="user-profile-bottom-left">
                <ProfileImage
                  id={1520}
                  state={state}
                  setState={setState}
                  disabled={disabled}
                />
                <div className="user-profile-bottom-left-form">
                  <div className="user-profile-bottom-left-form-flex">
                    <Field
                      name="first_name"
                      label="First Name"
                      placeholder="Enter first name"
                      component={Input}
                      readOnly={disabled}
                      emptyValue={setFieldValue}
                    />

                    <Field
                      name="last_name"
                      label="Last Name"
                      placeholder="Enter last name"
                      component={Input}
                      readOnly={disabled}
                      emptyValue={setFieldValue}
                    />

                    <Field
                      name="email"
                      label="Email"
                      placeholder="Enter email"
                      component={Input}
                      readOnly={true}
                    />
                  </div>

                  <div className="user-profile-bottom-left-form-flex3">
                    <Field
                      name="phone_number"
                      label="Phone Number"
                      placeholder="Enter phone number"
                      component={Input}
                      readOnly={disabled}
                      emptyValue={setFieldValue}
                    />

                    <Field
                      name="address"
                      label="Address"
                      placeholder="Enter address"
                      component={Input}
                      readOnly={disabled}
                      emptyValue={setFieldValue}
                    />
                  </div>
                </div>
              </div>
              <div className="user-profile-bottom-right">
                <AuthButton
                  children={"Save"}
                  background={
                    disableBtn ? "rgba(164, 167, 183, 0.4)" : "#0A74DC"
                  }
                  width={"167px"}
                  color={"#FFFFFF"}
                  marginTop={"0px"}
                  type={"submit"}
                  disabled={disableBtn}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UpdateProfile;
