import responseHandler from "../../utils/notifications/responseHandler";
import Instance from "../../utils/services/api";
import Storage from "../../utils/services/storage";
import Actions from "../Types";

const getPrescriptionsStart = () => ({
  type: Actions.GET_ALL_PRESCRIPTIONS_START,
});

const getPrescriptionsSuccess = (payload) => ({
  type: Actions.GET_ALL_PRESCRIPTIONS_SUCCESS,
  payload,
});

const getSinglePrescriptionStart = () => ({
  type: Actions.GET_PRESCRIPTION_START,
});

const getSinglePrescriptionSuccess = (payload) => ({
  type: Actions.GET_PRESCRIPTION_SUCCESS,
  payload,
});

const filterPrescriptionsStart = () => ({
  type: Actions.FILTER_PRESCRIPTIONS_START,
});

const filterPrescriptionsSuccess = (payload) => ({
  type: Actions.FILTER_PRESCRIPTIONS_SUCCESS,
  payload,
});

const searchPrescriptionsStart = () => ({
  type: Actions.SEARCH_PRESCRIPTIONS_START,
});

const searchPrescriptionsSuccess = (payload) => ({
  type: Actions.SEARCH_PRESCRIPTIONS_SUCCESS,
  payload,
});

export const getPrescriptions = (page, limit) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  dispatch(getPrescriptionsStart());
  API.get(`prescription?page=${page}&limit=${limit}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getPrescriptionsSuccess(data && data.data));
    } else {
      responseHandler(response, "Failed!");
    }
  });
};

export const getSinglePrescription = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  dispatch(getSinglePrescriptionStart());
  API.get(`prescription/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getSinglePrescriptionSuccess(data && data.data));
    } else {
      responseHandler(response, "Failed!");
    }
  });
};

export const filterPrescriptions =
  (page, limit, payload) => async (dispatch) => {
    const { prescribedFrom, prescribedTo } = payload;
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
    dispatch(filterPrescriptionsStart());
    API.get(
      `prescription?page=${page}&limit=${limit}&prescribed-from=${prescribedFrom}&prescribed-to=${prescribedTo}
  `
    ).then((response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(filterPrescriptionsSuccess(data && data.data));
      } else {
        responseHandler(response, "Failed!");
      }
    });
  };

export const searchPrescriptions =
  (page, limit, search) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
    dispatch(searchPrescriptionsStart());
    API.get(
      `prescription?page=${page}&limit=${limit}&q=${search}
  `
    ).then((response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(searchPrescriptionsSuccess(data && data.data));
      } else {
        responseHandler(response, "Failed!");
      }
    });
  };
