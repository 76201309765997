import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";

// GET CHATS BY STATUS
export const getChatsByStatusStart = () => ({
  type: Actions.GET_CHATS_ROOMS_START,
});

export const getChatsByStatusSuccess = (payload) => ({
  type: Actions.GET_CHATS_ROOMS_SUCCESS,
  payload,
});

export const getChatsByStatusFailure = (payload) => ({
  type: Actions.GET_CHATS_ROOMS_FAILURE,
  payload,
});

// SEARCH CHATS
export const searchChatsStart = () => ({
  type: Actions.SEARCH_CHATS_ROOMS_START,
});

export const searchChatsSuccess = (payload) => ({
  type: Actions.SEARCH_CHATS_ROOMS_SUCCESS,
  payload,
});

export const searchChatsFailure = (payload) => ({
  type: Actions.SEARCH_CHATS_ROOMS_FAILURE,
  payload,
});

export const filterChatsStatsStart = () => ({
  type: Actions.FILTER_CHAT_STATS_START,
});

export const filterChatsStatsSuccess = (payload) => ({
  type: Actions.FILTER_CHAT_STATS_SUCCESS,
  payload,
});
export const filterChatsStart = () => ({
  type: Actions.FILTER_CHATS_START,
});

export const filterChatsSuccess = (payload) => ({
  type: Actions.FILTER_CHATS_SUCCESS,
  payload,
});

export const getChatsStatsStart = () => ({
  type: Actions.GET_CHAT_STATS_START,
});
export const getChatsStatsSuccess = (payload) => ({
  type: Actions.GET_CHAT_STATS_SUCCESS,
  payload,
});

// ASSIGN CHAT
export const assignChatStart = () => ({
  type: Actions.ASSIGN_PENDING_CHAT_START,
});

export const assignChatSuccess = (payload) => ({
  type: Actions.ASSIGN_PENDING_CHAT_SUCCESS,
  payload,
});

export const assignChatFailure = (payload) => ({
  type: Actions.ASSIGN_PENDING_CHAT_FAILURE,
  payload,
});

export const getChatsByStatus =
  (status, created_from, created_to) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
    
    dispatch(getChatsByStatusStart());
    API.get(`chat?status=${status}`).then((response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(getChatsByStatusSuccess(data && data.data));
      } else {
        responseHandler(response, "Failed!");
        dispatch(getChatsByStatusFailure(data));
      }
    });
  };

  export const filterChatsByStatus  =  (status, created_from, created_to) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
    
    dispatch(filterChatsStart());
    API.get(`chat?status=${status}&created-from=${created_from}&created-to=${created_to}`).then((response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(filterChatsSuccess(data && data.data));
      } else {
        responseHandler(response, "Failed!");
      }
    });
  };

export const searchChats = (status, searchValue) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(searchChatsStart());
  API.get(`chat?status=${status}&search=${searchValue}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(searchChatsSuccess(data && data.data));
    } else {
      responseHandler(response, "Failed!");
      dispatch(searchChatsFailure(data));
    }
  });
};

export const assignChat = (id, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(assignChatStart());
  API.put(`/chat/transfer/${id}`, payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(assignChatSuccess(data && data.data));
    } else {
      responseHandler(response, "Failed!");
      dispatch(assignChatFailure(data));
    }
  });
};

export const filterChatStats = (date1, date2) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  dispatch(filterChatsStatsStart());
  API.get(`/chat/stats?created-from=${date1}&created-to=${date2}`).then(
    (response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(filterChatsStatsSuccess(data.data));
      } else {
        responseHandler(response, "Failed!");
      }
    }
  );
};

export const getChatStats = () => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  dispatch(getChatsStatsStart());
  API.get(`/chat/stats`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getChatsStatsSuccess(data.data));
    } else {
      responseHandler(response, "Failed!");
    }
  });
};
