export const filterSpecialist = (data) => {
  const specialist =
    data &&
    data.members.find(
      (chat) =>
        chat.user_type.toLowerCase() === "whispa doctor" ||
        chat.user_type.toLowerCase() === "consultant" ||
        chat.user_type.toLowerCase() === "doctor"
    );
  return specialist;
};

export const filterCustomer = (data) => {
  const customer =
    data &&
    data.members.find((chat) => chat.user_type.toLowerCase() === "customer");
  return customer;
};
