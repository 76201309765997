export const capitalizeFirstLetter = (string) => {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
};

export const addNewValue = (data) => {
  let newData = [];
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      const each = Object.assign({
        ...data,
        checked: false,
      });
      newData && newData.push(each);
    }
    return newData;
  }
};

export const updateCheckedValue = (categories, checkedCategories) => {
  let newData = [];
  if (
    categories &&
    categories.length > 0 &&
    checkedCategories &&
    checkedCategories.length > 0
  ) {
    categories.forEach((category) => {
      const each = Object.assign({
        ...category,
        checked: checkedCategories.find((a) => a.id === category.id)
          ? true
          : false,
      });
      newData && newData.push(each);
    });
    return newData;
  }
};

export const assignNewData = (data) => {
  let newData = [];
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      const each = Object.assign({
        category_id: data[i].id,
      });
      newData && newData.push(each);
    }
    return newData;
  }
};

export const assignStates = (data) => {
  let newStates = [];
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      const each = Object.assign({
        value: data[i],
        label: data[i],
      });
      newStates && newStates.push(each);
    }
    return newStates;
  }
};

export const assignCategoryOptions = (data) => {
  let newOptions = [];
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      const each = Object.assign({
        value: data[i].id || data[i].category_id,
        label: data[i].name,
      });
      newOptions && newOptions.push(each);
    }
    return newOptions;
  }
};
export const assignPlanFilterOptions = (data) => {
  let newOptions = [];
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      const each = Object.assign({
        category_id: data[i].id || data[i].category_id,
        name: data[i].name,
      });
      newOptions && newOptions.push(each);
    }
    return newOptions;
  }
};



export const assignUpdatedOptions = (data) => {
  if (data?.length > 0) {
    const each = Object.assign({
      value: "all products",
      label: "All products",
    });
    data.unshift(each);
  }
  return data;
};

export const assignSpecialistsOptions = (data) => {
  let newOptions = [];
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      const each = Object.assign({
        value: data[i].id,
        label: data[i].full_name,
      });
      newOptions && newOptions.push(each);
    }
    return newOptions;
  }
};

export const assignSubcategoryOptions = (data, id) => {
  let category = data && data.find((x) => x.id === id);
  let subcategory = category && category.appointment_subcategory;
  let updatedSubcategory = assignCategoryOptions(subcategory);
  return updatedSubcategory;
};

export const refineFacilityArray = (data) => {
  let checkboxData = [];
  for (var key in data) {
    const each = {
      id: data[key][0].appointment_category?.id,
      name: data[key][0].appointment_category?.name,
      subcategories: data[key].map((item) =>
        Object.assign({ id: item.id, name: item.name })
      ),
    };
    checkboxData.push(each);
  }
  return checkboxData;
};

export const changeFacilityArray = (items) => {
  const facilities =
    items &&
    items.map((item) =>
      Object.assign({
        ...item,
        appointment_categories: refineFacilityArray(
          item?.appointment_categories
        ),
      })
    );
  return facilities;
};

export const assignFacilityOptions = (data, id) => {
  const newData = data && changeFacilityArray(data);
  let facilities =
    newData &&
    newData.filter((x) =>
      x?.appointment_categories?.find((o) =>
        o?.subcategories?.find((c) => c.id === id)
      )
    );
  let updatedFacilities = assignCategoryOptions(facilities);
  if (updatedFacilities?.length > 0) {
    return updatedFacilities;
  } else {
    return [];
  }
};

export const assignFacilitySubcategoryOptions = (data, id) => {
  let category = data && data.find((x) => x.id === id);
  let subcategory = category && category.appointment_subcategory;
  let updatedSubcategory = assignBlogCategoryOptions(subcategory);
  return updatedSubcategory;
};

export const assignRolesOptions = (data) => {
  let newOptions = [];
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      const each = Object.assign({
        value: data[i].id,
        label: data[i].id,
      });
      newOptions && newOptions.push(each);
    }
    return newOptions;
  }
};

export const paginateFacilityFilter = (
  page,
  perPage,
  values,
  filterFacilities
) => {
  const payload = {
    category_name: values.category,
    status: values.status,
    location: values.location,
  };
  filterFacilities(page, perPage, payload);
};

export const createCategoryArray = (data) => {
  let newCategories = data && data.map((a) => a.name);
  return newCategories && newCategories.join(", ");
};

export const getImageType = (name, str) => {
  let dotIndex = str && str.lastIndexOf(".");
  let ext = str && str.substring(dotIndex);
  let nameStr = name && name.replace(/\s+/g, "-").toLowerCase();
  let fileName = nameStr && nameStr.concat(ext);
  return fileName;
};

export const updateSubcategories = (categories) => {
  let newData = [];
  if (categories && categories.length > 0) {
    categories.forEach((category) => {
      const each = Object.assign({
        ...category,
        loaded: true,
        error: "",
        hormones_name: category.hormones ? "True" : "False",
        description: category.information,
        image_name: {
          name: getImageType(category.name, category.image_url),
        },
      });
      newData && newData.push(each);
    });
    return newData;
  }
};

export const assignPlanOptions = (data) => {
  let newData = data && data.map((item) => item.benefit);
  return newData;
};

export const assignBlogCategoryOptions = (data) => {
  let newOptions = [];
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      const each = Object.assign({
        category_id: data[i].id,
        name: data[i].name,
      });
      newOptions && newOptions.push(each);
    }
    return newOptions;
  }
};

export const assignSubscriptionCategoryOptions = (data) => {
  let newOptions = [];
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      const each = Object.assign({
        value: data[i].id,
        label: data[i].transaction_reference,
      });
      newOptions && newOptions.push(each);
    }
    return newOptions;
  }
};

const getSubCategoryData = (data, data_name, type) => {
  const res_data = [];
  for (let i = 0; i < data.length; i++) {
    if (type === "multi-dropdown") {
      const each = Object.assign({
        category_name: data_name,
        name: data[i].name,
        category_id: data[i].id,
      });
      res_data.push(each);
    } else if (type === "dropdown") {
      const each = Object.assign({
        category_name: data_name,
        label: data[i].name,
        value: data[i].id,
      });
      res_data.push(each);
    }
  }
  return res_data;
};

export const assignSubcategoriesOptions = (data, type) => {
  let res_data = [];
  let newOptions = [];
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      res_data &&
        res_data.push(
          getSubCategoryData(
            data[i].appointment_subcategory,
            data[i].name,
            type
          )
        );
    }
  }
  for (let i = 0; i < res_data.length; i++) {
    for (let j = 0; j < res_data[i].length; j++) {
      newOptions.push(res_data[i][j]);
    }
  }
  return newOptions;
};

export const assignBlogCategoryPayload = (data) => {
  let payload = [];
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      const each = Object.assign({
        category_id: data[i].category_id,
      });
      payload && payload.push(each);
    }
    return payload;
  }
};

export const assignCouponProductPayload = (data) => {
  let payload = [];
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      const each = data[i].category_id;
      payload && payload.push(each);
    }
    return payload;
  }
};

export const assignOrderItemsPayload = (data1, data2) => {
  let payload = [];
  if (data1 && data1.length > 0) {
    for (let i = 0; i < data1.length; i++) {
      const each = { product_id: data1[i].category_id, qty: data2[i] };
      payload && payload.push(each);
    }
    return payload;
  }
};
