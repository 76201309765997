import PropTypes from "prop-types";
import React from "react";
import Switch from "react-switch";

export const ToggleButton = (props) => {
  const { text, handleChange, checked, id, subtext } = props;

  return (
    <div className="toggle-button">
      {text && <h2>{text}</h2>}
      <label htmlFor={id}>
        <Switch
          onChange={handleChange}
          checked={checked}
          uncheckedIcon={false}
          checkedIcon={false}
          width={38}
          height={20}
          handleDiameter={16}
          offColor={"#f2f2f2"}
          onColor={"#00DCB4"}
          offHandleColor={"#fff"}
          boxShadow={"2px 2px 6px rgba(67, 72, 84, 0.15)"}
          onHandleColor={"#fff"}
          activeBoxShadow={"#fff"}
          id={id}
        />
        {subtext && <span>{subtext}</span>}
      </label>
    </div>
  );
};

ToggleButton.propTypes = {
  checked: PropTypes.any,
  handleChange: PropTypes.any,
  id: PropTypes.any,
  style1: PropTypes.any,
  style2: PropTypes.any,
  text: PropTypes.any
}
