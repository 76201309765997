import React, { useState } from "react";
import Storage from "../../../utils/services/storage";
import { cancelModal, viewModal } from "../../Modal/Modal";
import ActivatePlan from "../../PaymentPlans/ActivatePlan";
import AssignSubscriptionModal from "../../PaymentPlans/AssignSubscriptionModal";

export const subscribersColumns = [
  {
    Header: "Name",
    accessor: "full_name",
    Cell: ({ row }) => (
      <div className="table-flex">
        <span style={{ textTransform: "capitalize" }}>
          {row?.original?.first_name && row?.original?.last_name
            ? `${row?.original?.first_name} ${row?.original?.last_name}`
            : `${row?.original?.full_name}`}
        </span>
      </div>
    ),
  },
  {
    Header: "Email",
    accessor: "email",
    Cell: ({ row }) => (
      <div
        className="table-flex"
        style={{ marginRight: "20px", width: "250px" }}
      >
        {row?.original?.email || "Nil"}
      </div>
    ),
  },
  {
    Header: "Plan name",
    accessor: "expiration_date",
    Cell: ({ row }) => (
      <div className="table-flex">
        {!row.original.plan_name ? "Nil" : row.original.plan_name}
      </div>
    ),
  },
  {
    Header: "Subscription status",
    accessor: "plan_name",
    Cell: ({ row }) => (
      <div className="" style={{ display: "flex", flexFlow: "column" }}>
        <div style={{ display: "flex", flexFlow: "row" }}>
          {!row.original.plan_name ? "Nil" : row.original.auto_renewal_status}

          {row.original.expiration_date &&
          new Date() > new Date(row.original.expiration_date) ? (
            <>
              <div style={{ marginLeft: "4px", marginTop: "5px" }}>
                <span className="error-btn">expired</span>
                <div>
                  {!row?.original?.subscription_date ? (
                    ""
                  ) : (
                    <div style={{ fontSize: "10px" }}>
                      {"purchase:  "}
                      {new Date(row.original.subscription_date).toDateString()}
                    </div>
                  )}
                  {!row?.original?.expiration_date ? (
                    ""
                  ) : (
                    <div style={{ fontSize: "10px" }}>
                      {"expires:  "}
                      {new Date(row.original.expiration_date).toDateString()}
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (row.original.expiration_date && new Date()) <
            new Date(row.original.expiration_date) ? (
            // ""
            <>
              <div style={{ marginLeft: "0px", marginTop: "5px" }}>
                <span className="success-btn">active</span>
                <div>
                  {!row?.original?.subscription_date ? (
                    ""
                  ) : (
                    <div style={{ fontSize: "10px" }}>
                      {"purchase:  "}
                      {new Date(row.original.subscription_date).toDateString()}
                    </div>
                  )}
                  {!row?.original?.expiration_date ? (
                    ""
                  ) : (
                    <div style={{ fontSize: "10px" }}>
                      {"expires:  "}
                      {new Date(row.original.expiration_date).toDateString()}
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    ),
  },
  {
    Header: "Renewal Status",
    accessor: "auto_renewal_status",
    Cell: ({ row }) => (
      <div className="table-flex">
        {!row.original.auto_renewal_status ? "False" : "True"}
      </div>
    ),
  },
  // {
  //   Header: "Action",
  //   accessor: "action",
  //   Cell: ({ row }) => {
  //     const [state, setState] = useState({
  //       activateModal: false,
  //       assignSubscriptionModal: false,
  //     });
  //     const adminDetails = Storage.get("admin-data");
  //     const json = JSON.parse(adminDetails);
  //     const role = json.role;

  //     return (
  //       <div className="">
  //         <div className="table-flex">
  //           {row.original.status &&
  //           row.original.status.toLowerCase() === "activated" ? (
  //             <h5 className="payment-btns-activated">Activated</h5>
  //           ) : (
  //             <div
  //               className="payment-btns-notactivated"
  //               onClick={() => viewModal("activateModal", state, setState)}
  //             >
  //               Activate
  //             </div>
  //           )}

  //           {state.activateModal && (
  //             <ActivatePlan
  //               displayModal={state.activateModal}
  //               closeModal={() => cancelModal("activateModal", state, setState)}
  //               data={row.original}
  //               type={"subscribers"}
  //             />
  //           )}

  //           {state.assignSubscriptionModal && role === "Super Admin" && (
  //             <AssignSubscriptionModal
  //               user_id={row?.original?.id}
  //               displayModal={state.assignSubscriptionModal}
  //               closeModal={() =>
  //                 cancelModal("assignSubscriptionModal", state, setState)
  //               }
  //             />
  //           )}
  //         </div>
  //         {/* {row.original.status === "active"
  //           ? ""
  //           : role === "Super Admin" && (
  //               <button
  //                 onClick={() =>
  //                   viewModal("assignSubscriptionModal", state, setState)
  //                 }
  //                 className="assign-plan-btn"
  //               >
  //                 Assign
  //               </button>
  //             )} */}
  //       </div>
  //     );
  //   },
  // },
];
