import React, { useEffect } from "react";
import Modal from "../Modal/Modal";
import { Form, Formik } from "formik";
import PlanInputs from "./PlanInputs";
import { PaymentPlanSchema } from "../../utils/validation/validation";
import { filterArray, filterObj } from "../../utils/functions/FilterObject";

const CreatePlan = (props) => {
  const {
    displayModal,
    closeModal,
    state,
    setState,
    isCreatingPlan,
    createPlan,
    enterpriseData,
    getEnterprises,
  } = props;

  useEffect(() => {
    getEnterprises(1, 10);
  }, []);

  const initialValues = {
    name: "",
    amount: "",
    caption: "",
    tag: "",
  };

  const filteredBenefits = state.benefits && filterArray(state.benefits);

  const submitValues = (values) => {
    const data = {
      name: values.name,
      amount: values.amount,
      validity: state.plan_validity,
      caption: values.caption,
      benefits: filteredBenefits,
      tag: values.tag,
      auto_renew: state.plan_validity === "Weekly" ? false : state.auto_renew,
      unlimited_chat: state.unlimited_chat,
      enterprise_id: state.enterpriseId
    };
    const payload = filterObj(data);
    createPlan(payload);
  };

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Create New Plan"}
      width={"568px"}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={PaymentPlanSchema}
        onSubmit={(values) => submitValues(values)}
      >
        {({ isValid, dirty, setFieldValue }) => {
          const disabled =
            isCreatingPlan ||
            !(isValid && dirty) ||
            (filteredBenefits && filteredBenefits.length === 0) ||
            state.plan_validity === "";

          return (
            <Form id="createPlan">
              <PlanInputs
                state={state}
                setState={setState}
                text={"Create Plan"}
                loadingText={"Creating..."}
                defaultOption={""}
                disabled={disabled}
                isLoading={isCreatingPlan}
                setFieldValue={setFieldValue}
                actionType={"create"}
                enterpriseData={enterpriseData}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CreatePlan;
