import React, { useEffect } from "react";
import Modal, { ModalButton } from "../../Modal/Modal";
import { Statuses } from "../../Input/Statuses";
import DateFilter from "../../Input/DateFilter";

const FilterCategory = (props) => {
  const {
    displayModal,
    closeModal,
    isFiltering,
    filterCategories,
    filterSuccess,
    setFilterState,
    filterState,
  } = props;

  const status = [{ name: "Active" }, { name: "Deactivated" }];

  const [state, setState] = React.useState({
    date: "",
    active: false,
    status: "",
    index: "",
  });

  const handleFilter = () => {
    const payload = {
      date: state.date,
      status: state.status === "Active" ? "active" : "deactivated",
    };
    setFilterState({
      ...filterState,
      filterValues: payload,
    });
    filterCategories(1, 10, payload);
  };

  useEffect(
    () => {
      if (filterSuccess) {
        closeModal();
        setState({
          ...state,
          date: "",
          active: false,
          status: "",
          index: "",
        });
      }
    },
    // eslint-disable-next-line
    [filterSuccess]
  );

  const disabled = isFiltering || state.status === "";

  return (
    <div>
      <Modal
        displayModal={displayModal}
        closeModal={closeModal}
        title={"Filter Category"}
        width={"496px"}
      >
        <DateFilter
          label={"Date"}
          placeholder={"Select date"}
          state={state}
          name={"date"}
          setState={setState}
          success={filterSuccess}
        />

        <div className="top-spacer">
          <Statuses
            label={"Status"}
            state={state}
            options={status}
            setstate={setState}
            blue={false}
            numberPerRow={3}
          />
        </div>

        <ModalButton
          isLoading={isFiltering}
          text={"Apply Search"}
          onClick={() => handleFilter()}
          testId={"button"}
          loadingText={"Filtering..."}
          disabled={disabled}
        />
      </Modal>
    </div>
  );
};

export default FilterCategory;
