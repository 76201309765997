import React from "react";
import GridIcon from "../../assets/grid-view.svg";
import ListIcon from "../../assets/list-view.svg";
import SortData from "../Sorting/SortData";

const AppointmentHeader = (props) => {
  const {
    state,
    setState,
    searchItems,
    openModal,
    sortSuccess,
    refreshFunction,
  } = props;

  const types = [{ image: GridIcon }, { image: ListIcon }];

  const setView = (index) => {
    setState({
      ...state,
      index: index,
      active: true,
    });
  };

  return (
    <>
      <div className="appointment-header">
        <div className="appointment-header-left">
          {types.map((type, index) => (
            <div
              className={
                index === state.index && state.active === true
                  ? "appointment-header-left-single"
                  : "appointment-header-left-single2"
              }
              key={index}
              onClick={() => setView(index)}
            >
              <div className="appointment-header-image">
                <img src={type.image} alt="icon" />
              </div>
            </div>
          ))}
        </div>
        <div className="appointment-header-right">
          <SortData
            placeholder={"Search appointments by name, email"}
            width={"347px"}
            searchItems={searchItems}
            setState={setState}
            state={state}
            onClick={() => openModal("filterModal")}
            filter={true}
            testid={"appointment-header"}
            sortSuccess={sortSuccess}
            refreshFunction={refreshFunction}
          />
        </div>
      </div>
    </>
  );
};

export default AppointmentHeader;
