import moment from "moment";
import React from "react";
import Table from "../Tables/Table";

const ReferralCodeUsersColumn = (props) => {
  const { getData, isLoading, success, data, total, getMoreData } = props;
  const column = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <span>{row.original?.referredUser?.full_name}</span>
        </div>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <span>{row.original?.referredUser?.email}</span>
        </div>
      ),
    },
    {
      Header: "Gender",
      accessor: "gender",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <span>{row.original?.referredUser?.gender || "Nil"}</span>
        </div>
      ),
    },
    {
      Header: "Referral Code",
      accessor: "referral_code",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <span>{row.original?.referredUser?.referral_code}</span>
        </div>
      ),
    },
    {
      Header: "Date Registered",
      accessor: "date_registered",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <span>{moment(row.original?.created_at).format("MMMM Do YYYY")}</span>
        </div>
      ),
    },
  ];
  return (
    <Table
      data={data}
      getData={getData}
      success={success}
      isLoading={isLoading}
      columns={column}
      total={total}
      getMoreData={getMoreData}
    />
  );
};

export default ReferralCodeUsersColumn;
