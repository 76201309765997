import OverviewIcon from "../../assets/overview.svg";
import AppointmentIcon from "../../assets/manager-icon.svg";
import ChatIcon from "../../assets/chat-icon.svg";
import ProductIcon from "../../assets/product-icon.svg";
import BlogIcon from "../../assets/blog.svg";
import FacilityIcon from "../../assets/facility.svg";
import OrderIcon from "../../assets/order.svg";
import UsersIcon from "../../assets/users.svg";
import AdminIcon from "../../assets/admin.svg";
import PlanIcon from "../../assets/sub-plan.svg";
import AuditIcon from "../../assets/Audit-trail.svg";
import CouponIcon from "../../assets/coupon.svg";

const LinkRoutes = [
  {
    name: "/overview",
    label: "Overview",
    icon: OverviewIcon, 
    role: "can_manage_overview",
  },
  {
    name: "/users",
    label: "Users",
    icon: UsersIcon,
    role: "can_manage_users",
  },
  {
    name: "/administrators",
    label: "Administrators",
    icon: AdminIcon,
    role: "can_manage_administrators",
  },
  {
    name: "/payment-plans",
    label: "Payment Plans",
    icon: PlanIcon,
    role: "can_manage_payment_plans",
  },
  {
    name: "/audit-trail",
    label: "Audit Trail",
    icon: AuditIcon,
    role: "can_manage_audit_trail",
  },
  {
    name: "/coupon",
    label: "Coupon",
    icon: CouponIcon,
    role: "can_manage_coupons",
  },
  {
    name: "/appointment",
    label: "User Appointment",
    icon: AppointmentIcon,
    role: "can_manage_user_appointment",
  },
  {
    name: "/chat-management",
    label: "Chat Management",
    icon: ChatIcon,
    role: "can_manage_chat",
  },
  {
    name: "/appointment-manager",
    label: "Appointment Manager",
    icon: AppointmentIcon,
    role: "can_manage_appointment",
  },
  {
    name: "/products",
    label: "Products",
    icon: ProductIcon,
    role: "can_manage_product",
  },
  {
    name: "/order-management",
    label: "Order Management",
    icon: OrderIcon,
    role: "can_manage_orders",
  },
  {
    name: "/blog-management",
    label: "Blog Management",
    icon: BlogIcon,
    role: "can_manage_blog",
  },
  {
    name: "/facility",
    label: "Facility",
    icon: FacilityIcon,
    role: "can_manage_facility", 
  },
  {
    name: "/referrals",
    label: "Referrals Report",
    icon: BlogIcon,
    role: "can_manage_referrals",
  },
  {
    name: "/delivery-fee-management",
    label: "Delivery Fees",
    icon: OrderIcon,
    role: "can_manage_overview",
  },
];

export default LinkRoutes;  
