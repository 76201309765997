import React from "react";
import Table from "../Tables/Table";
import { appointmentColumns } from "../TableData/Appointment/Appointment";
import { useEffect } from "react";

const List = (props) => {
  const {
    isGetting,
    isSearching,
    isFiltering,
    getSuccess,
    appointments,
    appointmentsData,
    searchSuccess,
    searchedAppointments,
    searchedAppointmentsData,
    filterSuccess,
    filteredAppointments,
    filteredAppointmentsData,
    getAppointments,
    moreData,
    setGeneralData,
  } = props;

  const allAppointments = searchSuccess
    ? searchedAppointments
    : filterSuccess
    ? filteredAppointments
    : appointments;

  const totalAppointments = searchSuccess
    ? searchedAppointmentsData && searchedAppointmentsData.count
    : filterSuccess
    ? filteredAppointmentsData && filteredAppointmentsData.count
    : appointmentsData && appointmentsData.count;

  useEffect(() => {
    setGeneralData(allAppointments);
  }, [allAppointments]);

  return (
    <div style={{ marginTop: "24px" }}>
      <Table
        columns={appointmentColumns}
        data={allAppointments}
        getData={getAppointments}
        getMoreData={(page, perPage) => moreData(page, perPage)}
        emptyText1={"No Appointments found"}
        emptyText2={"Click on the “Schedule” button to get started"}
        isLoading={isGetting || isSearching || isFiltering}
        success={getSuccess || filterSuccess || searchSuccess}
        sortSuccess={filterSuccess || searchSuccess}
        emptyResultText={"Appointment not found!"}
        className={"table8"}
        total={totalAppointments}
      />
    </div>
  );
};

export default List;
