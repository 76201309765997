import React, { useEffect, useState } from "react";
import Modal, { ModalButton } from "../Modal/Modal";
import SelectInput from "../Input/Select";
import { Statuses } from "../Input/Statuses";
import DateFilter from "../Input/DateFilter";
import { assignRolesOptions } from "../../utils/functions/UpdateValues";
import { filterAdmins, getRoles } from "../../redux/Actions/AdminActions";
import { connect } from "react-redux";

const FilterAdmin = (props) => {
  const {
    displayModal,
    closeModal,
    roles,
    getAdminRoles,
    getRolesSuccess,
    filterAdmins,
    isFiltering,
    filterSuccess,
    prevstate,
    setPrevState,
  } = props;

  const [options, setOptions] = useState([]);
  const [state, setState] = useState({
    date: "",
    role: "",
    active: false,
    status: "",
    index: "",
  });

  useEffect(
    () => {
      if (filterSuccess) {
        closeModal();
        setState({
          ...state,
          date: "",
          role: "",
          active: false,
          status: "",
          index: "",
        });
      }
    },
    // eslint-disable-next-line
    [filterSuccess]
  );

  useEffect(
    () => {
      getAdminRoles();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (getRolesSuccess) {
        const updatedRoles = assignRolesOptions(roles && roles.roles);

        setOptions(updatedRoles);
      }
    },
    // eslint-disable-next-line
    [getRolesSuccess]
  );

  const status = [{ name: "Active" }, { name: "Suspended" }];

  const updateSelection = (data) => {
    setState({
      ...state,
      role: data.label,
    });
  };

  const handleFilter = () => {
    const role = state.role;
    const status = state.status.toLowerCase();
    const date = state.date;
    let allStatus

    const payload = {
      role: state.role,
      status: state.status.toLowerCase(),
      date: state.date,
    };
    setPrevState({ ...prevstate, filterValues: payload });

    filterAdmins(1, 10, date, role, payload.status);
  };

  return (
    <div>
      <Modal
        displayModal={displayModal}
        closeModal={closeModal}
        title={"Filter Administrators"}
        width={"496px"}
      >
        <SelectInput
          label={"Role"}
          options={options}
          onChange={updateSelection}
          success={filterSuccess}
        />
        <div className="top-spacer">
          <DateFilter
            label={"Date Added"}
            placeholder={"Select date"}
            state={state}
            name={"date"}
            setState={setState}
            success={filterSuccess}
          />
        </div>

        <div className="top-spacer">
          <Statuses
            label={"Status"}
            state={state}
            options={status}
            setstate={setState}
            blue={false}
            numberPerRow={3}
          />
        </div>

        <ModalButton
          isLoading={isFiltering}
          disabled={isFiltering}
          text={"Apply Filter"}
          onClick={handleFilter}
          testId={"button"}
          loadingText={"Filtering..."}
        />
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => ({
  isFiltering: state.admin.isFiltering,
  filterSuccess: state.admin.filterSuccess,
  roles: state.admin.roles,
  getRolesSuccess: state.admin.getRolesSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getAdminRoles: (page, limit) => dispatch(getRoles(page, limit)),
  filterAdmins: (page, limit, date, role, status) =>
    dispatch(filterAdmins(page, limit, date, role, status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterAdmin);
