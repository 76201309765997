import React from "react";
import { Form, Formik, Field } from "formik";
import Input from "../../components/Input/Input";
import { AuthButton } from "../../components/Button/Button";
import { connect } from "react-redux";
import { ChangePasswordSchema } from "../../utils/validation/validation";
import { changeAdminPassword } from "../../redux/Actions/AuthActions";
import { useHistory } from "react-router-dom";

const CompleteForm = (props) => {
  const { changePassword, isChanging } = props;
  const history = useHistory();
  return (
    <div>
      <Formik
        initialValues={{
          // token: "",
          password: "",
          confirm_password: "",
        }}
        onSubmit={(values) => {
          const newValues = {
            // token: values.token,
            password: values.password,
            confirm_password: values.confirm_password,
          };
          changePassword(newValues, history);
        }}
        validationSchema={ChangePasswordSchema}
      >
        {({ isValid, dirty }) => {
          return (
            <Form id="form">
              <div>
                <div className="form-grid">
                  {/* <Field
                    name="token"
                    label="Token"
                    placeholder="Enter Token"
                    component={Input}
                    type="password"
                    password
                  /> */}
                </div>
                <div className="form-grid">
                  <Field
                    name="password"
                    label="Password"
                    placeholder="Enter password"
                    component={Input}
                    type="password"
                    password
                  />
                </div>
                <div className="form-grid">
                  <Field
                    name="confirm_password"
                    label="Confirm Password"
                    placeholder="Enter confirm password"
                    component={Input}
                    type="password"
                    password
                  />
                </div>

                <AuthButton
                  children={"Set Password"}
                  background={"#0A74DC"}
                  width={"167px"}
                  color={"#FFFFFF"}
                  isLoading={isChanging}
                  loadingText={"Changing"}
                  disabled={isChanging || !(isValid && dirty)}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isChanging: state.auth.isReseting,
});
const mapDispatchToProps = (dispatch) => ({
  changePassword: (payload, history) =>
    dispatch(changeAdminPassword(payload, history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompleteForm);
