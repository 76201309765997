import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  deleteFacility,
  getFacilities,
} from "../../redux/Actions/FacilityActions";
import DeleteModal from "../Modal/DeleteModal";

const DeleteFacility = (props) => {
  const { deleteFacility, isDeleting, deleteSuccess, id, getFacilities } =
    props;

  useEffect(
    () => {
      if (deleteSuccess) {
        props.closeModal();
        getFacilities(1, 10);
      }
    }, // eslint-disable-next-line
    [deleteSuccess]
  );

  const handleDelete = (id) => {
    deleteFacility(id);
  };

  return (
    <DeleteModal
      displayModal={props.displayModal}
      closeModal={props.closeModal}
      title={"Delete this facility?"}
      width={"463px"}
      text={"Delete"}
      onClick={() => handleDelete(id)}
      disabled={isDeleting}
      isLoading={isDeleting}
      loadingText={"Deleting..."}
      children={
        "Once you delete this facility, it won’t be possible to undo this action. Delete facility?"
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isDeleting: state.facility.isDeletingFacility,
  deleteSuccess: state.facility.deleteSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  deleteFacility: (id) => dispatch(deleteFacility(id)),
  getFacilities: (page, limit) => dispatch(getFacilities(page, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteFacility);
