import React, { useEffect, useState } from "react";
import AddAdmin from "../../components/AdminManagement/AddAdmin";
import FilterAdmin from "../../components/AdminManagement/FilterAdmin";
import { PageButton } from "../../components/Button/Button";
import SortData from "../../components/Sorting/SortData";
import Table from "../../components/Tables/Table";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import Add from "../../assets/add.svg";
import { cancelModal, viewModal } from "../../components/Modal/Modal";
import { AdminColumns } from "../../components/TableData/Admin/Admin";
import { connect } from "react-redux";
import {
  filterAdmins,
  getAdmins,
  getRoles,
  searchAdmins,
} from "../../redux/Actions/AdminActions";
import ExportIcon from "../../assets/Union.svg";
import DropIcon from "../../assets/drop-export.svg";
import TableDropdown from "../../components/Tables/Dropdown";
import downloadCSV from "../../utils/functions/csvDownloader";
import moment from "moment";

const Administrators = (props) => {
  const {
    getAdmins,
    isFetching,
    getAdminsSuccess,
    admins,
    createSuccess,
    editAdminSuccess,
    isSearching,
    searchSuccess,
    searchedAdmins,
    searchAdmins,
    searchedAdminsData,
    isFiltering,
    filterSuccess,
    filteredAdmins,
    filteredAdminsData,
    adminsData,
    revokeSuccess,
    deleteSuccess,
    roles,
    getAdminRoles,
  } = props;
  const [show, setShow] = useState(false);
  const [state, setState] = useState({
    addModal: false,
    filterModal: false,
    checkedItems: new Map(),
    checked: true,
    searchValue: "",
    filterValues: {},
  });

  useEffect(
    () => {
      getAdminRoles();
    },
    // eslint-disable-next-line
    [getAdminRoles]
  );

  const openModal = (type) => viewModal(type, state, setState);
  const closeModal = (type) => cancelModal(type, state, setState);

  useEffect(
    () => {
      if (deleteSuccess || revokeSuccess || createSuccess || editAdminSuccess) {
        getAdmins(1, 10);
      }
    },
    // eslint-disable-next-line
    [deleteSuccess, revokeSuccess, createSuccess, editAdminSuccess]
  );

  const searchAllAdmins = () => {
    if (state.searchValue === "") {
      return admins;
    } else {
      searchAdmins(1, 10, state.searchValue);
    }
  };

  const moreData = (page, perPage) => {
    const role = state.filterValues?.role;
    const status = state.filterValues?.status?.toLowerCase();
    const date = state.filterValues?.date;

    searchSuccess
      ? searchAdmins(page, perPage, state.searchValue)
      : filterSuccess
      ? filterAdmins(page, perPage, date, role, status)
      : getAdmins(page, perPage);
  };

  const allAdmins = searchSuccess
    ? searchedAdmins
    : filterSuccess
    ? filteredAdmins
    : admins;

  // console.log(allAdmins)

  const totalAdmins = searchSuccess
    ? searchedAdminsData && searchedAdminsData.count
    : filterSuccess
    ? filteredAdminsData && filteredAdminsData.count
    : adminsData && adminsData.count;

  useEffect(
    () => {
      if (getAdminsSuccess) {
        setState({
          ...state,
          searchValue: "",
        });
      }
    },
    // eslint-disable-next-line
    [getAdminsSuccess]
  );

  const exportHandler = (format) => {
    switch (format) {
      case "CSV":
        const data = allAdmins;
        const exportData =
          data &&
          data.map((admin) => ({
            Name: admin.full_name,
            Email: admin.email,
            Role: admin.role,
            Date: moment(admin.created_at).format("DD/MM/YYYY"),
            Status: admin.status,
          }));
        downloadCSV(exportData, "admins-data");
        return;
      default:
        return;
    }
  };

  const export_options = [
    {
      name: "CSV",
      action: () => exportHandler("CSV"),
    },
  ];

  return (
    <div>
      <HeaderWrapper
        title={"Administrators"}
        subtitle={"View and Manage all Aministrators"}
      >
        <div
          style={{ display: "flex", alignItems: "center" }}
          className="overview-header-right-button"
        >
          <PageButton
            width={"auto"}
            background={"rgba(10, 116, 220, 0.08)"}
            marginTop={"30px"}
            marginRight={"10px"}
            onClick={() => openModal("addModal")}
            type={"submit"}
          >
            <div className="button-data">
              <img src={Add} alt="add-icon" />
              <span>Add Admin</span>
            </div>
          </PageButton>
          <PageButton
            width={"121px"}
            mainWidth={"144px"}
            background={"rgba(10, 116, 220, 0.08)"}
            marginTop={"30px"}
            type={"button"}
            onClick={() => setShow((prevCheck) => !prevCheck)}
          >
            <div className="button-data">
              <img src={ExportIcon} alt="export icon" />
              <span className="span2">Export</span>
              <img src={DropIcon} alt="dropdown" />
            </div>
          </PageButton>
          {show && (
            <TableDropdown
              options={export_options}
              hideDropdown={() => setShow((prevCheck) => !prevCheck)}
              active
            />
          )}
        </div>
      </HeaderWrapper>

      <SortData
        placeholder={"Search Admins"}
        width={"347px"}
        searchItems={searchAllAdmins}
        setState={setState}
        state={state}
        onClick={() => openModal("filterModal")}
        filter={true}
        sortSuccess={filterSuccess || searchSuccess}
        refreshFunction={() => getAdmins(1, 10)}
      />

      <Table
        columns={AdminColumns}
        data={allAdmins}
        getData={getAdmins}
        getMoreData={(page, perPage) => moreData(page, perPage)}
        emptyText1={"You haven't added any admin"}
        emptyText2={"Click on the “Add Admin” button to get started"}
        isLoading={isFetching || isSearching || isFiltering}
        success={getAdminsSuccess || filterSuccess || searchSuccess}
        sortSucces={searchSuccess || filterSuccess}
        total={totalAdmins}
        emptyResultText={"Admin not found!"}
        className={"table3"}
      />

      {/* {state.addModal && ( */}
      <AddAdmin
        displayModal={state.addModal}
        closeModal={() => closeModal("addModal")}
      />
      {/* )} */}
      <FilterAdmin
        displayModal={state.filterModal}
        closeModal={() => closeModal("filterModal")}
        isFiltering={isFiltering}
        filterAdmins={filterAdmins}
        filterSuccess={filterSuccess}
        roles={roles}
        prevstate={state}
        setPrevState={setState}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isFetching: state.admin.isFetching,
  getAdminsSuccess: state.admin.getAdminsSuccess,
  admins: state.admin.admins,
  adminsData: state.admin.adminsData,
  editAdminSuccess: state.admin.editAdminSuccess,
  createSuccess: state.admin.createAdminSuccess,
  isSearching: state.admin.isSearching,
  searchSuccess: state.admin.searchSuccess,
  searchedAdmins: state.admin.searchedAdmins,
  searchedAdminsData: state.admin.searchedAdminsData,
  revokeSuccess: state.admin.revokeSuccess,
  roles: state.admin.roles,
  filterSuccess: state.admin.filterSuccess,
  filteredAdmins: state.admin.filteredAdmins,
  filteredAdminsData: state.admin.filteredAdminsData,
  deleteSuccess: state.admin.deleteSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getAdmins: (page, limit) => dispatch(getAdmins(page, limit)),
  getAdminRoles: (page, limit) => dispatch(getRoles(page, limit)),
  searchAdmins: (page, limit, search) =>
    dispatch(searchAdmins(page, limit, search)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Administrators);
