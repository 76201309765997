import React, { useEffect } from "react";
import { connect } from "react-redux";
import { deletePlan } from "../../redux/Actions/PaymentPlanActions";
import DeleteModal from "../Modal/DeleteModal";

const DeletePlan = (props) => {
  const { deletePlan, isDeleting, deleteSuccess, id, getPlans } = props;

  useEffect(
    () => {
      if (deleteSuccess) {
        props.closeModal();
        getPlans();
      }
    }, // eslint-disable-next-line
    [deleteSuccess]
  );

  const handleDelete = () => {
    deletePlan(id);
  };

  return (
    <DeleteModal
      displayModal={props.displayModal}
      closeModal={props.closeModal}
      title={"Delete this plan?"}
      width={"463px"}
      text={"Delete"}
      onClick={handleDelete}
      disabled={isDeleting}
      isLoading={isDeleting}
      loadingText={"Deleting..."}
      children={
        "Once you delete this plan it won’t be possible to undo this action. Delete plan?"
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isDeleting: state.plan.isDeleting,
  deleteSuccess: state.plan.deleteSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  deletePlan: (id) => dispatch(deletePlan(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeletePlan);
