import React, { useState } from "react";
import Input from "../../Input/Input";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import SubCategoryIcon from "./SubCategoryIcon";
import CancelIcon from "../../../assets/plus.svg";
import ImportantText from "../../Wrappers/ImportantText";
import SelectInput from "../../Input/Select";

const CategorySubInputs = ({
  subcategoryState,
  setSubcategoryState,
  removeCategory,
  index,
  category,
  updateSubcategory,
  moveCard,
  isUploading,
  uploadFile,
  assignUrl,
  handleRemoval,
  actionType,
  disableScroll,
  hormoneOptions,
  updateHormoneSubcategory,
  updateStatusSubcategory,
  statusOptions,
  id,
  swapAppointmentSubcategoryRankHandler,
}) => {
  const ref = useRef(null);

  let dropIndex = 0
  const [{ handlerId }, drop] = useDrop({
    accept: "category",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);
      swapAppointmentSubcategoryRankHandler({
        array: [subcategoryState[dragIndex].id, id],
      });
      // Time to actually perform the action

      // swapAppointmentSubcategoryRankHandler({
      //   array: [subcategoryState[dragIndex].id, id],
      // });
      //   updateSubcategory()
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
   
  });
  const [{ isDragging }, drag] = useDrag({
    type: "category",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));
  return (
    <div
      ref={swapAppointmentSubcategoryRankHandler ? ref : null}
      className="appointment-subcategories-grid"
      key={index}
    >
      {(index > 0 || subcategoryState.length > 1) && (
        <div
          className="appointment-subcategories-grid-delete"
          data-testid={`deleteSlot${index}`}
          onClick={() => removeCategory(index)}
        >
          <img src={CancelIcon} alt="cancel icon" />
        </div>
      )}
      <div className="appointment-subcategories-grid-layout">
        <Input
          value={category.name}
          onChange={updateSubcategory(index, "name")}
          label={<ImportantText label={"Subcategory"} />}
          placeholder="Enter category"
          testId={`updateCategory${index}`}
        />

        <Input
          value={category.description}
          onChange={updateSubcategory(index, "description")}
          label={<ImportantText label={"Description"} />}
          placeholder="Enter description"
          testId={`updateDescription${index}`}
        />

        <Input
          value={category.direction}
          onChange={updateSubcategory(index, "direction")}
          label={"Direction"}
          placeholder="Enter direction"
          testId={`updateDirection${index}`}
        />

        <SubCategoryIcon
          isUploading={isUploading}
          uploadFile={uploadFile}
          assignUrl={assignUrl}
          index={index}
          handleRemoval={handleRemoval}
          type={actionType}
          subcategory={category}
        />

        <Input
          id={`percentage_effectiveness-${category.id}`}
          onWheel={() =>
            disableScroll(`percentage_effectiveness-${category.id}`)
          }
          value={category.percentage_effectiveness}
          onChange={updateSubcategory(index, "percentage_effectiveness")}
          label={"Effectiveness(%)"}
          placeholder="Enter effectiveness"
          type={"number"}
          testId={`updateSubcategory${index}`}
        />

        <Input
          id={`duration-${category.id}`}
          onWheel={() => disableScroll(`duration-${category.id}`)}
          type={"number"}
          value={category.duration}
          onChange={updateSubcategory(index, "duration")}
          label={"Duration (days)"}
          placeholder="Enter duration"
          testId={`updateDuration${index}`}
        />

        <SelectInput
          value={category.hormones}
          label="No Hormones"
          options={hormoneOptions}
          onChange={(data) => updateHormoneSubcategory(index, data)}
          defaultOption={actionType === "edit" && category.hormones_name}
        />

        <div>
          <Input
            id={`amount-${category.id}`}
            onWheel={() => disableScroll(`amount-${category.id}`)}
            value={category.amount}
            onChange={updateSubcategory(index, "amount")}
            label={"Amount"}
            placeholder="Enter amount"
            type="number"
          />
        </div>
        <SelectInput
          value={category.status}
          label="Status"
          options={statusOptions}
          defaultOption={actionType === "edit" && category.status}
          onChange={(data) => {
            updateStatusSubcategory(index, data);
          }}
        />

        {/* <Input
          id={`rank-${category.rank}`}
          onWheel={() => disableScroll(`rank-${category.rank}`)}
          value={category.rank}
          onChange={updateSubcategory(index, "rank")}
          label={"Rank"}
          placeholder="Update Rank"
          type="number"
        /> */}
      </div>
    </div>
  );
};

export default CategorySubInputs;
