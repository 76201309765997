import React, { useEffect } from "react";
import { connect } from "react-redux";
import { changeOrderStatus } from "../../redux/Actions/OrderActions";
import DeleteModal from "../Modal/DeleteModal";

const CanceledOrder = (props) => {
  const { changeOrderStatus, id, isChangingStatus, changeSuccess } = props;

  useEffect(
    () => {
      if (changeSuccess) {
        props.closeModal();
      }
    }, // eslint-disable-next-line
    [changeSuccess]
  );

  const payload = {
    status: "canceled",
  };

  const handleChange = () => {
    changeOrderStatus(id, payload);
  };
  return (
    <DeleteModal
      displayModal={props.displayModal}
      closeModal={props.closeModal}
      title={"Change order status to canceled?"}
      width={"463px"}
      text={"Cancel"}
      onClick={handleChange}
      disabled={isChangingStatus}
      isLoading={isChangingStatus}
      loadingText={"Canceling..."}
      children={
        "Once you change this order status, this order would show as canceled. Change to canceled?"
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isChangingStatus: state.order.isChangingStatus,
  changeSuccess: state.order.changeSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  changeOrderStatus: (id, payload) => dispatch(changeOrderStatus(id, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CanceledOrder);
