import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";

// GET REFERRAL
export const getReferralsStart = () => ({
  type: Actions.GET_REFERRALS_START,
});

export const getReferralsSuccess = (payload) => ({ 
  type: Actions.GET_REFERRALS_SUCCESS,
  payload,
});

export const getReferralsFailure = (payload) => ({
  type: Actions.GET_REFERRALS_FAILURE,
  payload, 
});

// CREATE REFERRAL
export const createReferralStart = () => ({
  type: Actions.CREATE_REFERRAL_START,
});

export const createReferralSuccess = (payload) => ({
  type: Actions.CREATE_REFERRAL_SUCCESS,
  payload,
});

export const createReferralFailure = (payload) => ({
  type: Actions.CREATE_REFERRAL_FAILURE,
  payload,
});

// DELETE REFERRAL
export const deleteReferralStart = () => ({
  type: Actions.DELETE_REFERRAL_START,
});

export const deleteReferralSuccess = (payload) => ({
  type: Actions.DELETE_REFERRAL_SUCCESS,
  payload,
});

export const deleteReferralFailure = (payload) => ({
  type: Actions.DELETE_REFERRAL_FAILURE,
  payload,
});

// CHANGE REFERRAL STATUS
export const changeReferralStatusStart = () => ({
  type: Actions.CHANGE_REFERRAL_STATUS_START,
});

export const changeReferralStatusSuccess = (payload) => ({
  type: Actions.CHANGE_REFERRAL_STATUS_SUCCESS,
  payload,
});

export const changeReferralStatusFailure = (payload) => ({
  type: Actions.CHANGE_REFERRAL_STATUS_FAILURE,
  payload,
});

// EDIT REFERRAL
export const editReferralStart = () => ({
  type: Actions.EDIT_REFERRAL_START,
});

export const editReferralSuccess = (payload) => ({
  type: Actions.EDIT_REFERRAL_SUCCESS,
  payload,
});

export const editReferralFailure = (payload) => ({
  type: Actions.EDIT_REFERRAL_FAILURE,
  payload,
});

export const getReferrals = (page, limit) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getReferralsStart());
  API.get(`referral?page=${page}&limit=${limit}`).then((response) => {
    const { data, ok } = response;
    console.log('referrals-response-data', data)

    if (ok) {
      dispatch(getReferralsSuccess(data && data.data));
    } else {
      dispatch(getReferralsFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const createReferral = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(createReferralStart());
  API.post("referral", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(createReferralSuccess(data && data.data));
    } else {
      dispatch(createReferralFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const deleteReferral = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(deleteReferralStart());
  API.delete(`referral/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(deleteReferralSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(deleteReferralFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const changeReferralStatus = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(changeReferralStatusStart());
  API.patch(`referral/change-status/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(changeReferralStatusSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(changeReferralStatusFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const editReferral = (id, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(editReferralStart());
  API.put(`referral/edit-ref/${id}`, payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(editReferralSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(editReferralFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};
