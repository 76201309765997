import React from "react";
import { connect } from "react-redux";
import EmptyIcon from "../../../assets/empty-image.svg";
import { uploadImage } from "../../../redux/Actions/UploadActions";
import UploadProductImage from "./UploadProductImage";

const ProductImage = (props) => {
  const { images, isUploading, uploadFile, updateUrl, fileUrl, uploadSuccess } =
    props;

  return (
    <div className="product-image-container">
      {images &&
        images.map((item, index) => {
          return (
            <div
              className={
                item.loaded ? "overlay-holder" : "product-summary-left-image2"
              }
              key={index}
            >
              <div
                className={
                  item.loaded
                    ? "product-summary-left-image"
                    : "product-summary-left-image2-icon"
                }
              >
                <img src={item.image_url} alt="product" />
              </div>
              <div className="overlay">
                <UploadProductImage
                  uploadFile={uploadFile}
                  id={item.id}
                  index={index}
                  type={"multiple"}
                  isUploading={isUploading}
                  updateUrl={updateUrl}
                  loaded={item.loaded}
                />
              </div>
            </div>
          );
        })}

      <>
        {
          <div className="product-summary-left-image2">
            <div className="product-summary-left-image2-icon">
              <img src={EmptyIcon} alt="icon" />
            </div>
            <UploadProductImage
              uploadFile={uploadFile}
              id={998887}
              type={"multiple"}
              isUploading={isUploading}
              updateUrl={updateUrl}
              uploadSuccess={uploadSuccess}
              imageUrl={fileUrl}
            />
          </div>
        }
      </>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isUploading: state.upload.isUploading,
  uploadSuccess: state.upload.uploadSuccess,
  fileUrl: state.upload.fileUrl,
});

const mapDispatchToProps = (dispatch) => ({
  uploadFile: (payload) => dispatch(uploadImage(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductImage);
