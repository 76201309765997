import React, { useEffect, useState } from "react";
import Modal, { ModalButton } from "../../Modal/Modal";
import { Field, Form, Formik } from "formik";
import { Statuses } from "../../Input/Statuses";
import Input from "../../Input/Input";
import DateFilter from "../../Input/DateFilter";
import responseHandler from "../../../utils/notifications/responseHandler";
import SelectInput from "../../Input/Select";
import MultiDropdown from "../../Input/MultiDropdown";
import { CouponSchema } from "../../../utils/validation/validation";
import { connect } from "react-redux";
import { createCoupon } from "../../../redux/Actions/CouponsActions";
import {
  assignBlogCategoryOptions,
  assignCouponProductPayload,
  assignSubcategoriesOptions,
} from "../../../utils/functions/UpdateValues";
import { getAllProducts } from "../../../redux/Actions/ProductActions";
import Storage from "../../../utils/services/storage";
import axios from "axios";
import { getAllPlans } from "../../../redux/Actions/PaymentPlanActions";
import { getAppointmentCategories } from "../../../redux/Actions/AppointmentActions";

const CreateCoupon = (props) => {
  const {
    displayModal,
    closeModal,
    createCoupon,
    products,
    isCreating,
    createSuccess,
    getSuccess,
    plans,
    getProducts,
    getPlans,
    getAllCategories,
    categories,
  } = props;

  const [state, setState] = useState({
    status: "",
    start_date: "",
    expiration_date: "",
    type: "",
    products: [],
    plans: [],
    appointment_subcategories: [],
    single_use:"",
  });

  const [checkAllState, setCheckAllState] = useState({
    status: "",
    index: "",
    active: false,
  });

  const [checkAllPlansState, setCheckAllPlansState] = useState({
    status: "",
    index: "",
    active: false,
  });
  const [checkAllSubCategoriesState, setCheckAllSubCategoriesState] = useState({
    status: "",
    index: "",
    active: false,
  });
  const [multiDropdownTypeState, setMultipDropdownTypeState] = useState("");
  // Get Admin token
  const adminToken = Storage.get("admin-access-token");

  const types = [
    { label: "Percentage", value: "percentage" },
    { label: "Fixed", value: "fixed" },
  ];

  const multiDropdownTypes = [
    { label: "Products", value: "products" },
    { label: "Subscription Plans", value: "subscriptions" },
    { label: "Appointment Subcategories", value: "appointment_sub_categories" },
  ];

  const [options, setOptions] = useState({
    products: null,
    plans: null,
    appointment_subcategories: null,
  });

  // Function to get all products
  // const getAllProductsNew = async (page, limit, all) => {
  //   const url = `${process.env.REACT_APP_WHISPA_ADMIN_KEY}/product?limit=${limit}&page=${page}&all=${all}`;

  //   await axios
  //     .get(url, {
  //       headers: {
  //         "Content-type": "application/json",
  //         Authorization: adminToken,
  //       },
  //     })
  //     .then((res) => {
  //       setOptions({ ...options, products: res?.data?.data?.products });
  //     });
  // };

  useEffect(
    () => {
      // getProducts(1, 10, true);
      getProducts(1, 10, true);
      getPlans();
      getAllCategories();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (createSuccess) {
        document.getElementById("addcouponform").reset();
        setState({
          ...state,
          active: false,
          status: "",
          index: "",
        });
        setCheckAllState({
          ...state,
          active: false,
          status: "",
          index: "",
        });
        setCheckAllPlansState({
          ...state,
          active: false,
          status: "",
          index: "",
        });
        setCheckAllSubCategoriesState({
          ...state,
          active: false,
          status: "",
          index: "",
        });
      }
      closeModal();
    },
    // eslint-disable-next-line
    [createSuccess]
  );

  useEffect(() => {
    if (
      plans.plans?.length > 0 &&
      products?.length > 0 &&
      categories?.length > 0
    ) {
      setOptions({
        ...options,
        products: assignBlogCategoryOptions(products),
        plans: assignBlogCategoryOptions(plans.plans),
        appointment_subcategories: assignSubcategoriesOptions(
          categories,
          "multi-dropdown"
        ),
      });
    }
  }, [plans, products, categories]);

  const status = [{ name: "Active" }, { name: "Scheduled" }];

  const checkAll = [{ name: "Yes" }, { name: "No" }];

  const initialValues = {
    name: "",
    amount: "",
  };

  const updateType = ({ value: type }) => {
    setState((state) => ({ ...state, type }));
  };
  const resetPlansState = () => {
    setCheckAllPlansState({
      active: false,
      status: "",
      index: "",
    });
  };
  const resetProductsState = () => {
    setCheckAllState({
      active: false,
      status: "",
      index: "",
    });
  };
  const resetSubcategoriesState = () => {
    setCheckAllSubCategoriesState({
      active: false,
      status: "",
      index: "",
    });
  };
  const updateMultipDropdownType = ({ value: type }) => {
    setMultipDropdownTypeState(type);
    if (type === "subscriptions") {
      resetSubcategoriesState();
      resetProductsState();
      setState({
        ...state,
        products: [],
        appointment_subcategories: [],
      });
    } else if (type === "products") {
      resetPlansState();
      resetSubcategoriesState();
      setState({
        ...state,
        plans: [],
        appointment_subcategories: [],
      });
    } else if (type === "appointment_sub_categories") {
      resetPlansState();
      resetProductsState();
      setState({
        ...state,
        plans: [],
        products: [],
      });
    }
  };

  const singleUseOption = [
    { label: "True", value: true },
    { label: "False", value: false },
  ];

  const submitValues = (values) => {
    const {
      status,
      start_date,
      expiration_date,
      type,
      products,
      plans,
      appointment_subcategories,
    } = state;
    const { name, amount, code } = values;

    const payload = {
      name,
      amount,
      code,
      type,
      status: status.toLowerCase(),
      start_date,
      expiration_date,
      service_type: multiDropdownTypeState,
      single_use: state.single_use,
      products:
        checkAllState.status === "Yes"
          ? []
          : assignCouponProductPayload(products),
      subscription_plans:
        checkAllPlansState.status === "Yes"
          ? []
          : assignCouponProductPayload(plans),
      appointment_sub_categories:
        checkAllSubCategoriesState.status === "Yes"
          ? []
          : assignCouponProductPayload(appointment_subcategories),
    };

    const date = (value) => new Date(value).getTime();
    const dateValid = date(start_date) < date(expiration_date);

    if (dateValid) {
      createCoupon(payload);
      return;
    }
    responseHandler(
      { problem: "CLIENT_ERROR" },
      "End date should be ahead of the start date"
    );
  };
  const updateSingleUse = ({ value: type }) => {
    setState((state) => ({ ...state, single_use: type }));
  };

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Add New Discount Code"}
      width={"568px"}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={CouponSchema}
        onSubmit={(values) => submitValues(values)}
      >
        {({ isValid, dirty, setFieldValue }) => {
          const disabled =
            isCreating ||
            !(isValid && dirty) ||
            state.status === "" ||
            state.start_date === "" ||
            state.expiration_date === "" ||
            state.type === "" ||
            (checkAllState.status === "No" && state.products.length === 0) ||
            (checkAllPlansState.status === "No" && state.plans.length === 0) ||
            (checkAllSubCategoriesState.status === "No" &&
              state.appointment_subcategories.length === 0) ||
            (checkAllState.status === "" &&
              checkAllPlansState.status === "" &&
              checkAllSubCategoriesState.status === "");

          return (
            <Form id="addcouponform">
              <Field
                name="name"
                label="Name"
                placeholder="Enter name"
                component={Input}
                emptyValue={setFieldValue}
              />
              <div className="form-grid2">
                <Field
                  name="code"
                  label="Code"
                  placeholder="Enter Coupon Code"
                  component={Input}
                  emptyValue={setFieldValue}
                />
                <SelectInput
                  label={"Single Use"}
                  options={singleUseOption}
                  onChange={updateSingleUse}
                  success={createSuccess}
                />
              </div>
              <div className="form-grid2">
                <DateFilter
                  label={"Start Date"}
                  placeholder={"Select date"}
                  state={state}
                  name={"start_date"}
                  setState={setState}
                  success={createSuccess}
                />
                <DateFilter
                  label={"End Date"}
                  placeholder={"Select date"}
                  state={state}
                  name={"expiration_date"}
                  setState={setState}
                  success={createSuccess}
                />
              </div>
              <div className="form-grid2">
                <SelectInput
                  label="Type"
                  options={types}
                  onChange={updateType}
                  success={createSuccess}
                />
                <Field
                  type="number"
                  name="amount"
                  label="Amount(Fixed/Percentage(%))"
                  placeholder="Enter amount"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>
              <div className="top-spacer">
                <Statuses
                  label={"Status"}
                  state={state}
                  options={status}
                  setstate={setState}
                  blue={false}
                />
              </div>
              <div className="top-spacer">
                <SelectInput
                  label="Assign Coupon To"
                  options={multiDropdownTypes}
                  onChange={updateMultipDropdownType}
                  success={createSuccess}
                />
              </div>
              <div className="top-spacer">
                {multiDropdownTypeState === "products" ? (
                  <Statuses
                    label={"Apply to all products?"}
                    state={checkAllState}
                    options={checkAll}
                    setstate={setCheckAllState}
                    blue={false}
                  />
                ) : null}
                {multiDropdownTypeState === "subscriptions" ? (
                  <Statuses
                    label={"Apply to all Subscription Plans"}
                    state={checkAllPlansState}
                    options={checkAll}
                    setstate={setCheckAllPlansState}
                    blue={false}
                  />
                ) : null}
                {multiDropdownTypeState === "appointment_sub_categories" ? (
                  <Statuses
                    label={"Apply to all Appointment Subcategories?"}
                    state={checkAllSubCategoriesState}
                    options={checkAll}
                    setstate={setCheckAllSubCategoriesState}
                    blue={false}
                  />
                ) : null}
              </div>
              {checkAllState.status === "No" && (
                <div>
                  {options && options.products != null && (
                    <MultiDropdown
                      state={state}
                      accessor="products"
                      onSelect={(products) =>
                        setState((state) => ({ ...state, products }))
                      }
                      onRemove={(products) =>
                        setState((state) => ({ ...state, products }))
                      }
                      label={"Products"}
                      top={"30px"}
                      mb={"10px"}
                      placeholder={
                        state.products.length > 0
                          ? "Add more products..."
                          : "e.g Select Products"
                      }
                      testid={"product-tags"}
                      type2
                      options={options.products}
                    />
                  )}
                </div>
              )}
              {checkAllPlansState.status === "No" && (
                <div>
                  {options && options.plans != null && (
                    <MultiDropdown
                      state={state}
                      accessor="plans"
                      onSelect={(plans) =>
                        setState((state) => ({ ...state, plans }))
                      }
                      onRemove={(plans) =>
                        setState((state) => ({ ...state, plans }))
                      }
                      label={"Plans"}
                      top={"30px"}
                      mb={"10px"}
                      placeholder={
                        state.plans.length > 0
                          ? "Add more plans..."
                          : "e.g Select Plans"
                      }
                      testid={"plans-tags"}
                      type2
                      options={options.plans}
                    />
                  )}
                </div>
              )}
              {checkAllSubCategoriesState.status === "No" && (
                <div>
                  {options && options.appointment_subcategories != null && (
                    <MultiDropdown
                      state={state}
                      accessor="appointment_subcategories"
                      onSelect={(appointment_subcategories) =>
                        setState((state) => ({
                          ...state,
                          appointment_subcategories,
                        }))
                      }
                      onRemove={(appointment_subcategories) =>
                        setState((state) => ({
                          ...state,
                          appointment_subcategories,
                        }))
                      }
                      label={"Appointment Subcategories"}
                      top={"30px"}
                      mb={"10px"}
                      placeholder={
                        state.appointment_subcategories.length > 0
                          ? "Add more subcategories..."
                          : "e.g Select Appointment Subcategory"
                      }
                      testid={"subcategories-tags"}
                      type2
                      options={options.appointment_subcategories}
                      groupedBy={"category_name"}
                    />
                  )}
                </div>
              )}

              <ModalButton
                isLoading={isCreating}
                text={"Create"}
                type={"submit"}
                testId={"button"}
                disabled={disabled}
                loadingText={"Creating..."}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isCreating: state.coupons.isCreating,
  createSuccess: state.coupons.createSuccess,
  getSuccess: state.products.getSuccess,
  products: state.products.allProducts,
  plans: state.plan.plans,
  categories: state.appointment.categories,
});

const mapDispatchToProps = (dispatch) => ({
  createCoupon: (payload) => dispatch(createCoupon(payload)),
  getProducts: (page, limit, all) => dispatch(getAllProducts(page, limit, all)),
  getPlans: () => dispatch(getAllPlans()),
  getAllCategories: () => dispatch(getAppointmentCategories()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCoupon);
