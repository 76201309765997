import React, { useEffect, useState } from "react";
import { Form, Field } from "formik";
import Input from "../Input/Input";
import { LoaderSpin } from "../Loader/Loader";
import { RoleSelect } from "./RoleSelect";
import Checkboxes from "../Input/Checkboxes";
import { ModalButton } from "../Modal/Modal";
import { AuthButton } from "../Button/Button";
import { assignCategoryOptions } from "../../utils/functions/UpdateValues";
import SelectInput from "../Input/Select";

const AdminInputs = (props) => {
  const {
    loadingText,
    text,
    setFieldValue,
    disabled,
    checkedItems,
    setCheckedItems,
    formId,
    isGetting,
    getRolesSuccess,
    rolesData,
    roles,
    handleSelectRole,
    isCreating,
    readonly,
    datarole,
    onboarding,
    enterpriseData,
    setEnterpriseId,
    enterpriseId,
  } = props;

  const [entepriseOptions, setEnterpriseOptions] = useState([]);
  useEffect(() => {
    if (enterpriseData.length > 0) {
      const options = assignCategoryOptions(enterpriseData)
      options.unshift({
        value: "",
        label: "No Option"
      })
      setEnterpriseOptions(options);
    }
  }, [enterpriseData]);

  const getOption = (id) => {
    if (id) {
      const option = entepriseOptions.filter((el) => el.value === id);
      return option && option[0].label;
    }
  };

  return (
    <Form id={formId}>
      <div className="form-grid3">
        <Field
          name="first_name"
          label="First Name"
          placeholder="Enter first name"
          component={Input}
          emptyValue={setFieldValue}
        />
        <Field
          name="last_name"
          label="Last Name"
          placeholder="Enter last name"
          component={Input}
          emptyValue={setFieldValue}
        />
      </div>
      <div className="form-grid2">
        <Field
          name="username"
          label="User Name"
          placeholder="Enter username"
          component={Input}
          type="text"
          emptyValue={setFieldValue}
        />
        <Field
          name="phone_number"
          label="Phone Number"
          placeholder="Enter phone number"
          component={Input}
          type="text"
          emptyValue={setFieldValue}
        />
      </div>
      <div className="form-grid">
        <Field
          name="email"
          label="Email"
          placeholder="Enter email address"
          component={Input}
          emptyValue={setFieldValue}
          readOnly={readonly ? true : false}
        />
      </div>
      <div className="form-grid">
        <Field
          name="description"
          label="Description"
          placeholder="Enter description"
          component={Input}
          emptyValue={setFieldValue}
        />
      </div>
      <div className="top-spacer">
        <SelectInput
          label={"Select Enterprise"}
          options={entepriseOptions}
          onChange={(data) => setEnterpriseId(data.value)}
          defaultOption={enterpriseId && entepriseOptions.length > 0 ? getOption(enterpriseId) : ""}
        />
      </div>

      <div className="role-checkbox">
        <div className="role-checkbox-title2">Select Role</div>
        {isGetting ? (
          <LoaderSpin width="40px" height="40px" />
        ) : (
          <>
            {rolesData?.length === 0 ? (
              "No Availaible Role"
            ) : (
              <RoleSelect
                getRolesSuccess={getRolesSuccess}
                rolesData={rolesData}
                roles={roles}
                handleSelectRole={handleSelectRole}
                datarole={datarole}
              />
            )}
          </>
        )}
      </div>

      {roles !== "" && getRolesSuccess && (
        <Checkboxes
          options={checkedItems}
          title={"Admin Permissions"}
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
        />
      )}

      <div style={{ paddingBottom: "50px" }}>
        {onboarding ? (
          <AuthButton
            children={text}
            background={"#0A74DC"}
            width={"167px"}
            color={"#FFFFFF"}
            isLoading={isCreating}
            loadingText={loadingText}
            disabled={disabled}
            testId={"button3"}
          />
        ) : (
          <ModalButton
            text={text}
            type={"submit"}
            testId={"button"}
            isLoading={isCreating}
            loadingText={loadingText}
            disabled={disabled}
            width={"167px"}
          />
        )}
      </div>
    </Form>
  );
};

export default AdminInputs;
