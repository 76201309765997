import React, { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import { Formik } from "formik";
import AdminInputs from "../Onboarding/AdminInputs";
import {
  assignPermissionData,
  filterActiveRoles,
  permissionData,
  permissionPayload,
} from "../../utils/functions/CreateNewArray";
import { connect } from "react-redux";
import { createAdmin, getRoles } from "../../redux/Actions/AdminActions";
import { CreateAdminSchema } from "../../utils/validation/validation";
import { getAllEnterprises } from "../../redux/Actions/EnterpriseActions";

const AddAdmin = (props) => {
  const {
    displayModal,
    closeModal,
    createAdmin,
    isCreating,
    adminRoles,
    getRolesSuccess,
    isGetting,
    createSuccess,
    enterpriseData,
    getEnterprises,
  } = props;

  const [roles, setRoles] = useState("");
  const [rolesName, setRolesName] = useState("");
  const [enterpriseId, setEnterpriseId] = useState("");

  const rolesData =
    adminRoles && adminRoles.roles && filterActiveRoles(adminRoles.roles);
  const mapRoles = rolesData && rolesData[roles];

  const newArray = assignPermissionData(mapRoles);

  const permissions = newArray && permissionData(newArray);

  const [checkedItems, setCheckedItems] = useState(permissions);

  const permissionsPayload = checkedItems && permissionPayload(checkedItems);

  useEffect(
    () => {
      if (createSuccess) {
        document.getElementById("addAdmin").reset();
        setRoles("");
        closeModal();
      }
    },
    // eslint-disable-next-line
    [createSuccess]
  );

  useEffect(
    () => {
      if (getRolesSuccess) {
        setCheckedItems(permissions);
      }
    },
    // eslint-disable-next-line
    [getRolesSuccess, roles]
  );

  useEffect(() => {
    getEnterprises(1, 10);
  }, []);

  const handleSelectRole = (index, data) => {
    setRoles(index);
    setRolesName(data);
  };

  const initialValues = {
    first_name: "",
    last_name: "",
    username: "",
    phone_number: "",
    email: "",
    description: "",
    role: "",
  };

  const submitValues = (values) => {
    let payload;
    if (enterpriseId) {
      payload = {
        first_name: values.first_name,
        last_name: values.last_name,
        username: values.username,
        phone_number: values.phone_number,
        email: values.email,
        description: values.description,
        role: rolesName,
        enterprises_id: enterpriseId,
        ...permissionsPayload,
      };
    } else {
      payload = {
        first_name: values.first_name,
        last_name: values.last_name,
        username: values.username,
        phone_number: values.phone_number,
        email: values.email,
        description: values.description,
        role: rolesName,
        // enterprises_id:enterpriseId,
        ...permissionsPayload,
      };
    }

    console.log(payload)

    createAdmin(payload);
  };

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Add New Admin"}
      width={"568px"}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => submitValues(values)}
        validationSchema={CreateAdminSchema}
      >
        {({ isValid, dirty, setFieldValue }) => {
          return (
            <AdminInputs
              enterpriseData={enterpriseData}
              text={"Create Admin"}
              formId={"addAdmin"}
              loadingText={"Creating..."}
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
              isGetting={isGetting}
              getRolesSuccess={getRolesSuccess}
              rolesData={rolesData}
              roles={roles}
              handleSelectRole={handleSelectRole}
              isCreating={isCreating}
              disabled={roles === "" || isCreating || !(isValid && dirty)}
              setFieldValue={setFieldValue}
              readonly={false}
              setEnterpriseId={setEnterpriseId}
            />
          );
        }}
      </Formik>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createAdmin: (payload) => dispatch(createAdmin(payload)),
  getAdminRoles: () => dispatch(getRoles()),
  getEnterprises: (page, limit) => dispatch(getAllEnterprises(page, limit)),
});

const mapStateToProps = (state) => ({
  isCreating: state.admin.isCreating,
  createSuccess: state.admin.createAdminSuccess,
  adminRoles: state.admin.roles,
  getRolesSuccess: state.admin.getRolesSuccess,
  isGetting: state.admin.isGetting,
  allState: state,
  enterpriseData: state.enterprise.enterpriseData,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAdmin);
