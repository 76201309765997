import React from "react";

const PrivacyPolicy = () => {
  const embeddedFile = process.env.REACT_APP_WHISPA_PRIVACY_POLICY_FILE;

  return (
    <div className="privacy-policy">
      <iframe
        src={embeddedFile}
        width="100%"
        height="100%"
        padding="30"
        allow="autoplay"
        title="Privacy policy"
      ></iframe>
    </div>
  );
};

export default PrivacyPolicy;
