import React from "react";
import Table from "../Tables/Table";
import truncateName from "../../utils/functions/ShortenStr";
import { useHistory } from "react-router-dom";

const InfluencerColumns = (props) => {
  const {
    state,
    setState,
    getData,
    isLoading,
    success,
    data,
    sortSuccess,
    total,
    getMoreData,
  } = props;
  const {push} = useHistory()
  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <span>{row.original?.full_name}</span>
        </div>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <span>{truncateName(row?.original?.email, 17) || "-"}</span>
        </div>
      ),
    },
    {
      Header: "Gender",
      accessor: "gender",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <span>{row.original?.gender || "Nil"}</span>
        </div>
      ),
    },
    {
      Header: "Referral Code",
      accessor: "referral_code",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <span>{row.original?.referral_code}</span>
        </div>
      ),
    },
    {
      Header: "No. Of Referrals",
      accessor: "no_of_referrals",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <span>{row?.original?.referred_users?.length}</span>
        </div>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <button
            className="table-action-button"
            onClick={() => push(`referrals/${row.original?.referral_code}`)}
          >
            View Users
          </button>
        </div>
      ),
    },
  ];
  return (
    <Table
      state={state}
      setState={setState}
      columns={columns}
      data={data}
      getData={getData}
      getMoreData={getMoreData}
      isLoading={isLoading}
      success={success}
      sortSuccess={sortSuccess}
      total={total}
    />
  );
};

export default InfluencerColumns;
