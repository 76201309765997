import React from "react";
import Check from "../../assets/check.svg";
import Popular from "../../assets/popular.svg";
import { cancelModal } from "../../components/Modal/Modal";
import EditPlan from "./EditPlan";
import Empty from "../../assets/empty.svg";
import DeletePlan from "./DeletePlan";

const Plans = (props) => {
  const { state, setState, plans, getPlans, enterpriseData } = props;

  const openModal = (index) => {
    setState({
      ...state,
      index: index,
      editModal: true,
    });
  };

  const openDeleteModal = (index) => {
    setState({
      ...state,
      index: index,
      deleteModal: true,
    });
  };

  const closeModal = (type) => cancelModal(type, state, setState);

  return (
    <div className="payment-plans">
      {plans && plans.length > 0 ? (
        <div className="payment-grid">
          {plans &&
            plans.map((plan) => (
              <div key={plan.id} className="payment-plans-section">
                <div className="payment-plans-section-type">
                  <div
                    className="popular-tag-section"
                    style={{
                      justifyContent:
                        plan.tag && plan.most_subscribed
                          ? "space-between"
                          : "flex-end",
                    }}
                  >
                    {plan.tag && (
                      <div className="show-tag">
                        <div className="popular-tag">
                          <img src={Popular} alt="most popular" />
                        </div>
                        <span className="popular-tag-text">{plan.tag}</span>
                      </div>
                    )}
                    {plan.most_subscribed === true && (
                      <div className="show-tag">
                        <div className="popular-tag">
                          <img src={Popular} alt="most popular" />
                        </div>
                        <span className="popular-tag-text">Most Popular</span>
                      </div>
                    )}
                  </div>

                  <h2>{plan.name}</h2>
                  <h3>{plan.caption}</h3>
                </div>
                <div className="payment-plans-section-price">
                  <h2>
                    <span>₦</span>
                    {plan.amount}
                  </h2>
                  <h4>
                    {plan.payment_frequency &&
                    plan.payment_frequency.toLowerCase() === "monthly"
                      ? "Per Month"
                      : plan.payment_frequency &&
                        plan.payment_frequency.toLowerCase() === "weekly"
                      ? "Per Week"
                      : plan.payment_frequency &&
                        plan.payment_frequency.toLowerCase() === "yearly"
                      ? "Per Year"
                      : "Biannually"}
                  </h4>
                </div>
                <div className="payment-plans-section-benefits">
                  {plan.benefits &&
                    plan.benefits.map((item) => (
                      <div
                        className="payment-plans-section-benefits-div"
                        key={item.id}
                      >
                        <div>
                          <img src={Check} alt="check-icon" />
                        </div>
                        <span>{item.benefit}</span>
                      </div>
                    ))}
                </div>

                <div className="payment-plans-section-button">
                  <button
                    onClick={() => openDeleteModal(plan.id)}
                    data-testid={`deleteModal${plan.id}`}
                  >
                    Delete
                  </button>
                  <button
                    onClick={() => openModal(plan.id)}
                    data-testid={`openModal${plan.id}`}
                  >
                    Edit Plan
                  </button>
                </div>

                {state.editModal && plan.id === state.index && (
                  <EditPlan
                    displayModal={state.editModal && plan.id === state.index}
                    closeModal={() => closeModal("editModal")}
                    type={plan.type}
                    data={plan}
                    actionType={"edit"}
                    enterpriseData={enterpriseData}
                  />
                )}

                {state.deleteModal && plan.id === state.index && (
                  <DeletePlan
                    displayModal={state.deleteModal && plan.id === state.index}
                    closeModal={() => closeModal("deleteModal")}
                    id={plan?.id}
                    getPlans={getPlans}
                  />
                )}
              </div>
            ))}
        </div>
      ) : (
        <div className="payment-plan-empty">
          <div className="table-empty">
            <div className="table-empty-container">
              <div className="table-empty-container-image">
                <img src={Empty} alt="empty-table" />
              </div>
              <div>
                <h2>You haven't added any plan</h2>
                <h3>Click on the “Create Plan” button to get started</h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Plans;
