import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const DateFilter = ({
  currentDate,
  placeholder,
  state,
  setState,
  label,
  name,
  type2,
  readOnly,
  previousDate,
  minDate,
  success,
}) => {
  const [date, setDate] = useState(
    currentDate ? new Date() : previousDate ? previousDate : ""
  );

  const setSelectedDate = (date) => {
    setDate(date);
    setState({
      ...state,
      [name]: moment(date).format("YYYY-MM-DD"),
    });
  };

  useEffect(
    () => {
      if (success) {
        setDate("");
      }
    },
    // eslint-disable-next-line
    [success]
  );

  return (
    <div className="date-wrapper">
      <p className={type2 ? "filter-label" : "label2"}>{label}</p>
      <DatePicker
        selected={date}
        onChange={setSelectedDate}
        placeholderText={placeholder}
        dateFormat={"dd, MMM yyyy"}
        disabled={readOnly}
        minDate={minDate}
      />
    </div>
  );
};

DateFilter.propTypes = {
  fixedDate: PropTypes.any,
  label: PropTypes.any,
  onChange: PropTypes.any,
  placeholder: PropTypes.any,
  setState: PropTypes.func,
  state: PropTypes.any,
  subLabel: PropTypes.any,
};

export default DateFilter;
