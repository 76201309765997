import PropTypes from "prop-types";
import React from "react";

const Checkboxes = (props) => {
  const {
    options,
    title,
    className,
    rowNo, 
    marginBottom, 
    checkedItems, 
    setCheckedItems,
  } = props;

  const handleOnChange = (index) => {
    if (index >= 0) {
      checkedItems[index].checked = !checkedItems[index].checked;
      setCheckedItems(JSON.parse(JSON.stringify(checkedItems)));
    }
  };

  return (
    <div>
      <div className={className ? className : "checkboxes-title"}>{title}</div>
      <div
        className="checkboxes"
        style={{
          gridTemplateColumns: `repeat(${rowNo}, 1fr)`,
          marginBottom: marginBottom,
        }}
      >
        {options &&
          options.map((item, index) => (
            <label key={item.key}>
              <input
                className="checkbox-input"
                type={"checkbox"}
                id={item.id}
                key={index}
                name={item.id}
                checked={item.checked}
                data-testid="handleOnChange"
                onClick={() => handleOnChange(index)}
              />
              {
                item.label == 'appointment' ? `${item.label} Management` :
                item.label == 'blog' ? `${item.label} Management` :
                item.label == 'product' ? `${item.label} Management` :
                item.label == 'facility' ? `${item.label} Management` :
                item.label == 'chat' ? `User ${item.label}` :
                item.label == 'coupons' ? `Discounts` :
                item.label == 'orders' ? `User ${item.label}`
                : item.label
              }
            </label>
          ))}
      </div>
    </div>
  );
};

Checkboxes.propTypes = {
  checked: PropTypes.shape({
    get: PropTypes.func,
  }),
  onChange: PropTypes.any,
  options: PropTypes.shape({
    map: PropTypes.func,
  }),
  title: PropTypes.any,
};

export default Checkboxes;
