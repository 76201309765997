import React, { useEffect} from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { getReferredUsers } from "../../redux/Actions/ReferralReportAction";
import ReferralCodeUsersColumn from "../../components/ReferralCodes/ReferralCodeUsersColumn";

const ReferralCodeUsers = (props) => {
  const {
    isGettingReferredUsers,
    getReferredUsersSuccess,
    referredUsers,
    referredUsersData,
    getReferredUsersHandler,
  } = props;
  let referralid
  const location = useLocation();
  const paths = location.pathname.split("/");
  const history = useHistory()
  
  useEffect(() => {
    const id = paths[paths.length - 1];
    if (id) {
      referralid = id
      getReferredUsersHandler(id, 1, 10);
    }
  }, []);

  const moreData = (page, perpage, id) => {
    getReferredUsersHandler(id, page, perpage)
  }

  // Function to take you the previous page
  const handleGoBack = () => {
    history.goBack()
  };

  return (
    <div className="orders">
      {/* <Table /> */}
      <div className="displayFlex backSection" onClick={handleGoBack}>
        <div className="backIcon">
          <AiOutlineArrowLeft />
        </div>
        <div className="backText">Back</div>
      </div>
      <div className="table-header">
        <h2 className="table-header-title">Users</h2>
        <p>
          Here, you'll see a list of users that signed up using that referral
          code.
        </p>
      </div>
      <ReferralCodeUsersColumn
        getData={getReferredUsersHandler}
        isLoading={isGettingReferredUsers}
        success={getReferredUsersSuccess}
        data={
          getReferredUsersSuccess && referredUsers.length > 0
            ? referredUsers
            : [
                {
                  referredUser: {
                    email: "",
                    full_name: "",
                    gender: "",
                    referral_code: "",
                  },
                },
              ]
        }
        getMoreData={(page, perpage) => moreData(page, perpage, referralid)}
        total={referredUsersData && referredUsersData.total}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGettingReferredUsers: state.referralreport.isGettingReferredUsers,
  getReferredUsersSuccess: state.referralreport.getReferredUsersSuccess,
  referredUsers: state.referralreport.referredUsers,
  referredUsersData: state.referralreport.referredUsersData,
});

const mapDispatchToProps = (dispatch) => ({
  getReferredUsersHandler: (id, page, limit) => dispatch(getReferredUsers(id, page, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReferralCodeUsers);
