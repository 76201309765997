import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { createAdmin, getRoles } from "../../redux/Actions/AdminActions";
import { connect } from "react-redux";
import { CreateAdminSchema } from "../../utils/validation/validation";
import {
  assignPermissionData,
  filterActiveRoles,
  permissionData,
  permissionPayload,
} from "../../utils/functions/CreateNewArray.js";
import AdminInputs from "./AdminInputs";

const AddAdminForm = (props) => {
  const {
    createAdmin,
    isCreating,
    getAdminRoles,
    adminRoles,
    getRolesSuccess,
    isGetting,
    createSuccess,
    state,
    setstate,
  } = props;
  const [roles, setRoles] = useState("");
  const [rolesName, setRolesName] = useState("");

  const rolesData =
    adminRoles && adminRoles.roles && filterActiveRoles(adminRoles.roles);
  const mapRoles = rolesData && rolesData[roles];

  const newArray = assignPermissionData(mapRoles);

  const permissions = newArray && permissionData(newArray);

  const [checkedItems, setCheckedItems] = useState(permissions);

  const permissionsPayload = permissions && permissionPayload(checkedItems);

  useEffect(
    () => {
      if (getRolesSuccess) {
        setCheckedItems(permissions);
      }
    },
    // eslint-disable-next-line
    [getRolesSuccess, roles]
  );

  useEffect(
    () => {
      if (createSuccess) {
        setstate({
          ...state,
          active1: false,
          active2: true,
          openAddForm: false,
        });
      }
    },
    // eslint-disable-next-line
    [createSuccess]
  );

  useEffect(
    () => {
      getAdminRoles();
    },
    // eslint-disable-next-line
    [getAdminRoles]
  );

  const handleSelectRole = (index, data) => {
    setRoles(index);
    setRolesName(data);
  };

  const initialValues = {
    first_name: "",
    last_name: "",
    username: "",
    phone_number: "",
    email: "",
    description: "",
    role: "",
  };

  const submitValues = (values) => {
    const payload = {
      first_name: values.first_name,
      last_name: values.last_name,
      username: values.username,
      phone_number: values.phone_number,
      email: values.email,
      description: values.description,
      role: rolesName,
      ...permissionsPayload,
    };
    createAdmin(payload);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => submitValues(values)}
        validationSchema={CreateAdminSchema}
      >
        {({ isValid, dirty, setFieldValue }) => {
          return (
            <AdminInputs
              formId={"form"}
              text={"Add"}
              loadingText={"Creating..."}
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
              isGetting={isGetting}
              getRolesSuccess={getRolesSuccess}
              rolesData={rolesData}
              roles={roles}
              handleSelectRole={handleSelectRole}
              isCreating={isCreating}
              disabled={roles === "" || isCreating || !(isValid && dirty)}
              setFieldValue={setFieldValue}
              readonly={false}
              onboarding={true}
            />
          );
        }}
      </Formik>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createAdmin: (payload) => dispatch(createAdmin(payload)),
  getAdminRoles: () => dispatch(getRoles()),
});

const mapStateToProps = (state) => ({
  isCreating: state.admin.isCreating,
  createSuccess: state.admin.createAdminSuccess,
  adminRoles: state.admin.roles,
  getRolesSuccess: state.admin.getRolesSuccess,
  isGetting: state.admin.isGetting,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAdminForm);
