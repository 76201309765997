import React, { useEffect, useState } from "react";
import { PageButton } from "../../components/Button/Button";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Add from "../../assets/add.svg";
import Blog from "./Blog";
import BlogCategory from "./BlogCategory";
import SortData from "../../components/Sorting/SortData";
import SwitchWrapper from "../../components/Wrappers/SwitchWrapper";
import { cancelModal, viewModal } from "../../components/Modal/Modal";
import {
  createBlogCategory,
  getAllBlogCategories,
  getBlogPosts,
  searchBlogCategories,
  searchBlogPosts,
} from "../../redux/Actions/BlogActions";
import AddCategory from "../../components/BlogManagement/BlogCategory/AddCategory";
import TableDropdown from "../../components/Tables/Dropdown";
import ExportIcon from "../../assets/Union.svg";
import DropIcon from "../../assets/drop-export.svg";
import downloadCSV from "../../utils/functions/csvDownloader";
import moment from "moment";

const BlogContainer = (props) => {
  const {
    searchBlogPosts,
    searchBlogCategories,
    blogPosts,
    blogCategories,
    isCreating,
    createCategory,
    addSuccess,
    searchSuccess,
    searchCategorySuccess,
    filterSuccess,
    filterCategorySuccess,
    getBlogCategories,
    getBlogPosts,
    getSuccess,
    getCategorySuccess,
  } = props;

  const history = useHistory();

  const [state, setState] = useState({
    active1: true,
    active2: false,
    filterModal: false,
    addCategory: false,
    filterCategory: false,
    searchValue: "",
    filterValues: {},
    active: false,
    status: "",
    index: "",
  });

  const openModal = (type) => viewModal(type, state, setState);
  const closeModal = (type) => cancelModal(type, state, setState);

  const [show, setShow] = useState(false);
  const [blogData, setBlogData] = useState({
    blog: [],
    category: [],
  });

  const searchAllPosts = () => {
    if (state.searchValue === "") {
      return blogPosts;
    } else {
      searchBlogPosts(1, 10, state.searchValue);
    }
  };

  const searchAllCategories = () => {
    if (state.searchValue === "") {
      return blogCategories;
    } else {
      searchBlogCategories(1, 10, state.searchValue);
    }
  };

  useEffect(
    () => {
      if (getSuccess || getCategorySuccess) {
        setState({
          ...state,
          searchValue: "",
        });
      }
    },
    // eslint-disable-next-line
    [getSuccess, getCategorySuccess]
  );
  const exportHandler = (format) => {
    switch (format) {
      case "CSV":
        const data = blogData;
        const blogExportData =
          data.blog &&
          data.blog.map((blog) => ({
            "Article By": blog.author_name,
            "Article Title": blog.title,
            category: blog.categories?.map((el) => el.name).join(","),
            Sold: blog.quantity_sold,

            Status: blog.status,
          }));
        const categoryData =
          data.category &&
          data.category.map((category) => ({
            "Category Name": category.name,
            Date: moment(category.created_at).format("DD/MM/YYYY"),
            Status: category.status,
          }));
        downloadCSV(state.active1 ? blogExportData : categoryData, "bog-data");
        return;
      default:
        return;
    }
  };
  console.log(blogData);

  const export_options = [
    {
      name: "CSV",
      action: () => exportHandler("CSV"),
    },
  ];

  return (
    <div>
      <HeaderWrapper
        title={"Blog Management"}
        subtitle={"View and Manage all Blog posts"}
      >
        <div
          style={{ display: "flex", alignItems: "center" }}
          className="overview-header-right-button"
        >
          <PageButton
            width={"auto"}
            background={"rgba(10, 116, 220, 0.08)"}
            marginTop={"30px"}
            marginRight={"10px"}
            onClick={
              state.active1
                ? () => history.push("/blog-management/create")
                : () => openModal("addCategory")
            }
            type={"submit"}
          >
            <div className="button-data">
              <img src={Add} alt="add-icon" />
              <span>{state.active1 ? "Create Post" : "Create Category"}</span>
            </div>
          </PageButton>
          <PageButton
            width={"121px"}
            mainWidth={"144px"}
            background={"rgba(10, 116, 220, 0.08)"}
            marginTop={"30px"}
            type={"button"}
            onClick={() => setShow((prevCheck) => !prevCheck)}
          >
            <div className="button-data">
              <img src={ExportIcon} alt="export icon" />
              <span className="span2">Export</span>
              <img src={DropIcon} alt="dropdown" />
            </div>
          </PageButton>
          {show && (
            <TableDropdown
              options={export_options}
              hideDropdown={() => setShow((prevCheck) => !prevCheck)}
              active
            />
          )}
        </div>
      </HeaderWrapper>

      <SwitchWrapper
        title1={"Blog"}
        title2={"Blog Category"}
        state={state}
        setstate={setState}
        right1={
          <SortData
            placeholder={"Search by author name, title"}
            width={"347px"}
            searchItems={searchAllPosts}
            setState={setState}
            state={state}
            onClick={() => openModal("filterModal")}
            filter={true}
            sortSuccess={filterSuccess || searchSuccess}
            refreshFunction={() => getBlogPosts(1, 10)}
          />
        }
        right2={
          <SortData
            placeholder={"Search by category name"}
            width={"347px"}
            searchItems={searchAllCategories}
            setState={setState}
            state={state}
            onClick={() => openModal("filterCategory")}
            filter={true}
            sortSuccess={filterCategorySuccess || searchCategorySuccess}
            refreshFunction={() => getBlogCategories(1, 10)}
          />
        }
        component1={
          <Blog
            setBlogData={setBlogData}
            blogData={blogData}
            closeModal={closeModal}
            state={state}
            setState={setState}
          />
        }
        component2={
          <BlogCategory
            setBlogData={setBlogData}
            closeModal={closeModal}
            state={state}
            blogData={blogData}
            setState={setState}
            addSuccess={addSuccess}
          />
        }
      />

      <AddCategory
        displayModal={state.addCategory}
        closeModal={() => closeModal("addCategory")}
        isCreating={isCreating}
        createCategory={createCategory}
        state={state}
        setState={setState}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  blogPosts: state.blog.blogPosts,
  blogCategories: state.blogcategory.blogCategories,
  getSuccess: state.blog.getSuccess,
  getCategorySuccess: state.blogcategory.getCategorySuccess,
  searchSuccess: state.blog.searchSuccess,
  filterSuccess: state.blog.filterSuccess,
  searchCategorySuccess: state.blogcategory.searchSuccess,
  filterCategorySuccess: state.blogcategory.filterSuccess,
  isCreating: state.blogcategory.isAddingCategory,
  addSuccess: state.blogcategory.addCategorySuccess,
});

const mapDispatchToProps = (dispatch) => ({
  searchBlogPosts: (page, limit, searchValue) =>
    dispatch(searchBlogPosts(page, limit, searchValue)),
  searchBlogCategories: (page, limit, searchValue) =>
    dispatch(searchBlogCategories(page, limit, searchValue)),
  createCategory: (payload) => dispatch(createBlogCategory(payload)),
  getBlogCategories: (page, limit) =>
    dispatch(getAllBlogCategories(page, limit)),
  getBlogPosts: (page, limit) => dispatch(getBlogPosts(page, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BlogContainer);
