import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { getReports } from "../../redux/Actions/ReportsAction";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import { PageButton } from "../../components/Button/Button";
import DateFilter from "../../components/Input/DateFilter";
import { getDistanceBetweenMonths } from "../../utils/functions/date";
import SelectInput from "../../components/Input/Select";
const Reports = (props) => {
  const { isGetting, getReportsSuccess, reports, nodata, getReportsHandler } =
    props;
  const [state, setState] = useState({
    filterReportsModal: false,
    startDate: "",
    endDate: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [reportType, setReportType] = useState("");

  useEffect(() => {
    if (nodata) {
      setErrorMessage("No data for this Date");
    }
  }, [nodata]);

  const downloadHandler = () => {
    setErrorMessage("");
    const gap = getDistanceBetweenMonths(
      new Date(state.startDate),
      new Date(state.endDate)
    );
    // if (gap > 4) {
    //   return setErrorMessage("Date Gap Cannot Exceed 4 months");
    // }
   
    let start = new Date(state.startDate).toISOString(),
      end = new Date(state.endDate).toISOString();
    getReportsHandler(start, end);
  };
  const reportTypeOptions = [
    { value: "appointment_revenue", label: "Appointment Revenue" },
    { value: "product_order_revenue", label: "Product Order Revenue" },
    { value: "subscription_report", label: "Subscription Report" },
    { value: "chat_doctor_report", label: "Chat Doctor Report" },
  ];

  return (
    <div
      style={{
        height: "100vh",
      }}
    >
      <HeaderWrapper
        title={"Reports"}
        subtitle={"Generate Reports"}
      ></HeaderWrapper>
      <div
        style={{
          width: "70%",
          margin: "auto",
        }}
        className="form-grid2"
      >
        {/* <SelectInput
          options={reportTypeOptions}
          value={reportType}
          label="Report Type"
          onChange={(data) => setReportType(data.value)}
        /> */}
        <DateFilter
          label={"Start Date"}
          placeholder={"Select date"}
          state={state}
          name={"startDate"}
          setState={setState}
          success={""}
        />
        <DateFilter
          label={"End Date"}
          placeholder={"Select date"}
          state={state}
          name={"endDate"}
          setState={setState}
          success={""}
        />
      </div>
      {errorMessage ? (
        <p
          style={{
            textAlign: "center",
            marginTop: "2rem",
            color: "red",
          }}
        >
          {errorMessage}
        </p>
      ) : (
        ""
      )}
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          marginTop: "4rem",
        }}
      >
        <PageButton
          disabled={!state.startDate || !state.endDate}
          onClick={downloadHandler}
        >
          <span>{isGetting ? "Getting Data..." : "Download"}</span>
        </PageButton>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getReportsHandler: (start_date, end_date) =>
    dispatch(getReports(start_date, end_date)),
});

const mapStateToProps = (state) => ({
  isGetting: state.reports.isGetting,
  getReportsSuccess: state.reports.getReportsSucess,
  reports: state.reports.reports,
  nodata: state.reports.nodata,
});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
