import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getSpecialists } from "../../../redux/Actions/UserAppointmentActions";
import { transferUserChat } from "../../../redux/Actions/UsersActions";
import { assignSpecialistsOptions } from "../../../utils/functions/UpdateValues";
import SelectInput from "../../Input/Select";
import Modal, { ModalButton } from "../../Modal/Modal";

const RerouteClient = (props) => {
  const {
    displayModal,
    closeModal,
    state,
    setState,
    id,
    specialists,
    getSpecialistsSuccess,
    getSpecialists,
    transferUserChat,
    isTransfering,
    transferSuccess,
    getUserChatHistory,
  } = props;

  const [specialistOptions, setSpecialistOptions] = useState([]);

  const type = state.active1 ? "consultant" : "doctor";

  useEffect(
    () => {
      getSpecialists(type);
    },
    // eslint-disable-next-line
    [getSpecialists]
  );

  useEffect(
    () => {
      if (getSpecialistsSuccess) {
        const updatedSpecialists = assignSpecialistsOptions(specialists);
        setSpecialistOptions(updatedSpecialists);
      }
    },
    // eslint-disable-next-line
    [getSpecialistsSuccess]
  );

  useEffect(
    () => {
      if (transferSuccess) {
        getUserChatHistory(id, type);
        setState({
          ...state,
          chat: "",
          reRouteModal: false,
        });
      }
    },
    // eslint-disable-next-line
    [transferSuccess]
  );

  const updateSelection = (data) => {
    setState({
      ...state,
      newDoctor: data.value,
    });
  };

  const submitValues = () => {
    const payload = {
      user_id: state.newDoctor,
    };
    transferUserChat(id, payload);
  };

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Reroute Client"}
      width={"496px"}
    >
      <div className="chats-modal-text">
        {`Chose a ${
          state.active1 ? "Consultant" : "Doctor"
        } you want to redirect the client to`}
      </div>
      <SelectInput
        label={`Choose Whispa ${state.active1 ? "Consultant" : "Doctor"}`}
        options={specialistOptions}
        onChange={updateSelection}
        success={transferSuccess}
      />

      <ModalButton
        isLoading={isTransfering}
        text={"Transfer Chats"}
        testId={"button"}
        loadingText={"Transfering..."}
        onClick={submitValues}
        disabled={isTransfering || state.newDoctor === ""}
      />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  getSpecialistsSuccess: state.userappointment.getSpecialistsSuccess,
  specialists: state.userappointment.specialists,
  isTransfering: state.users.isTransfering,
  transferSuccess: state.users.transferSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getSpecialists: (type) => dispatch(getSpecialists(type)),
  transferUserChat: (id, payload) => dispatch(transferUserChat(id, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RerouteClient);
