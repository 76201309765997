import React from "react";
import truncateName from "../../utils/functions/ShortenStr";
import Table from "../Tables/Table";
import {useHistory} from "react-router-dom"

const CustomersColumn = (props) => {
  const {
    state,
    setState,
    getData,
    isLoading,
    success,
    data,
    sortSuccess,
    total,
    getMoreData,
  } = props;
  const {push} = useHistory()
  const columns = [
    {
      Header: "Full Name",
      accessor: "full_name",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <span>{row?.original?.full_name || "Nil"}</span>
        </div>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <span>{truncateName(row?.original?.email, 17) || "-"}</span>
        </div>
      ),
    },
    {
      Header: "Referral Code",
      accessor: "referral_code",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <span>{row?.original?.referral_code}</span>
        </div>
      ),
    },
    {
      Header: "No. Of Referrals",
      accessor: "no_of_referrals",
      Cell: ({ row }) => (
        <div className="table-shrink">
          <span>{row?.original?.referred_users?.length}</span>
        </div>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <div className="table-shrink">
        <button className="table-action-button" onClick={() => push(`referrals/${row.original?.referral_code}`)}>View Users</button>
        </div>
      ),
    },
  ];
  return (
    <div className="">
      <Table
        state={state}
        setState={setState}
        columns={columns}
        data={data}
        getData={getData}
        getMoreData={getMoreData}
        isLoading={isLoading}
        success={success}
        sortSuccess={sortSuccess}
        total={total}
      />
    </div>
  );
};

export default CustomersColumn;
