import React from "react";
import { createCategoryArray } from "../../utils/functions/UpdateValues";

const FacilityInfo = (props) => {
  const { children, data } = props;

  return (
    <div className="info-wrap">
      <div className="info-wrap-left">
        <div className="info">
          <h4>Description</h4>
          <div> {!data?.about ? "Description not available" : data?.about}</div>
        </div>
        <div className="info">
          <h4>Facility Infomation</h4>
          <div className="info-data">
            <div className="info-data-left">Appointment type</div>
            <div className="info-data-right">
              {data && createCategoryArray(data?.appointment_type)}
            </div>
            <div className="info-data-left">Location</div>
            <div className="info-data-right">
              {data?.facility && data?.facility?.city}, &nbsp;
              {data?.facility && data?.facility?.state}
            </div>

            <div className="info-data-left">Years of Experience</div>
            <div
              className="info-data-right"
              style={{ textTransform: "capitalize" }}
            >
              {!data?.years_of_experience
                ? "Nil"
                : ` ${data?.years_of_experience} years`}
            </div>
          </div>
        </div>
        <div className="info">
          <h4>Contact Infomation</h4>
          <div className="info-data">
            <div className="info-data-left">Facility Address</div>
            <div className="info-data-right">
              {data?.facility && data?.facility?.full_address}
            </div>
            <div className="info-data-left">HFCP Contact No.</div>
            <div className="info-data-right">{data?.phone_number}</div>
            <div className="info-data-left">HFCP's contact email</div>
            <div className="info-data-right">{data?.email}</div>
          </div>
        </div>
      </div>
      <div className="info-wrap-right">{children}</div>
    </div>
  );
};

export default FacilityInfo;
