import React from "react";
import AuthWrapper, { Headers } from "../../components/Wrappers/AuthWrapper";
import { Form, Formik, Field } from "formik";
import Input from "../../components/Input/Input";
import { AuthButton } from "../../components/Button/Button";
import { SendEmailAddress } from "../../redux/Actions/AuthActions";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { SendEmailSchema } from "../../utils/validation/validation";

const ForgotPassword = (props) => {
  const { sendEmailAddress, isSending } = props;
  const history = useHistory();
  return (
    <AuthWrapper className="container2">
      <Headers
        title1={"Forgot Password"}
        title2={"Enter your email to reset password"}
      />
      <div>
        <Formik
          initialValues={{ email: "" }}
          onSubmit={(values) => {
            const emailAddress = {
              email: values.email.trim(),
            };
            sendEmailAddress(emailAddress, history);
          }}
          validationSchema={SendEmailSchema}
        >
          {({ isValid, dirty }) => {
            return (
              <Form id="form">
                <div>
                  <div className="form-grid">
                    <Field
                      name="email"
                      label="Email"
                      placeholder="Enter email address"
                      component={Input}
                    />
                  </div>

                  <AuthButton
                    children={"Send"}
                    background={"#0A74DC"}
                    width={"100%"}
                    color={"#FFFFFF"}
                    isLoading={isSending}
                    loadingText={"Sending"}
                    disabled={!(isValid && dirty)}
                  />
                </div>
                <div className="bottom-wrap">
                  Remember Password? &nbsp;
                  <a href="/" className="policy">
                    Login here
                  </a>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </AuthWrapper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  sendEmailAddress: (user, history) =>
    dispatch(SendEmailAddress(user, history)),
});

const mapStateToProps = (state) => ({
  isSending: state.auth.isSendingEmail,
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
