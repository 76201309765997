import React, { useEffect, useState } from "react";
import { Statuses } from "../../Input/Statuses";
import Modal, { ModalButton } from "../../Modal/Modal";
import Input from "../../Input/Input";
import { Field, Form, Formik } from "formik";
import { filterReferralCoupon } from "../../../redux/Actions/CouponsActions";
import { connect } from "react-redux";

const FilterReferralCoupon = (props) => {
  const {
    displayModal,
    closeModal,
    filterReferralCoupon,
    isFiltering,
    filterSuccess,
  } = props;

  const status = [{ name: "Used" }, { name: "Unused" }];

  const [state, setState] = useState({
    active: false,
    status: "",
    index: "",
  });

  const submitValues = (values) => {
    const payload = {
      amount: values && Number(values.amount),
      status: state.status.toLowerCase(),
    };

    filterReferralCoupon(1, 10, payload.amount, payload.status);
  };

  useEffect(
    () => {
      if (filterSuccess) {
        closeModal();
        document.getElementById("filterReferralCoupon").reset();
        setState({
          ...state,
          active: false,
          status: "",
          index: "",
        });
      }
    },
    // eslint-disable-next-line
    [filterSuccess]
  );

  return (
    <div>
      <Modal
        displayModal={displayModal}
        closeModal={closeModal}
        title={"Filter Referral Manager"}
        width={"496px"}
      >
        <Formik
          initialValues={{ amount: "" }}
          onSubmit={(values) => submitValues(values)}
        >
          {({ isValid, dirty, setFieldValue }) => {
            return (
              <Form id="filterReferralCoupon">
                <Field
                  type="number"
                  name="amount"
                  label="Amount"
                  placeholder="Enter amount"
                  component={Input}
                  emptyValue={setFieldValue}
                />

                <div className="top-spacer">
                  <Statuses
                    label={"Status"}
                    state={state}
                    options={status}
                    setstate={setState}
                    blue={false}
                    numberPerRow={3}
                  />
                </div>

                <ModalButton
                  isLoading={isFiltering}
                  disabled={
                    isFiltering || state.status === "" || !(isValid && dirty)
                  }
                  text={"Apply Filter"}
                  type={"submit"}
                  testId={"button"}
                  loadingText={"Filtering..."}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isFiltering: state.coupons.isFiltering,
  filterSuccess: state.coupons.filterSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  filterReferralCoupon: (page, limit, amount, status) =>
    dispatch(filterReferralCoupon(page, limit, amount, status)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterReferralCoupon);
