import React from "react";
import { assignUrlFunc } from "../../Products/UploadImage";
import Subcategory from "../../../assets/emptypic.svg";
import Storage from "../../../utils/services/storage";
import Cancel from "./../../../assets/delete.svg";
import truncateName from "../../../utils/functions/ShortenStr";

const SubCategoryIcon = (props) => {
  const {
    uploadFile,
    isUploading,
    assignUrl,
    index,
    handleRemoval,
    type,
    subcategory,
  } = props;

  const [state, setState] = React.useState({
    loaded: type === "edit" ? subcategory.loaded : false,
    file_name: type === "edit" ? subcategory.image_url : "",
    image_name: type === "edit" ? subcategory.image_name : "",
  });

  const updateUrl = (uploadedFile, payload) => {
    setState({
      ...state,
      loaded: true,
      file_name: uploadedFile,
      image_name: payload && payload.uploadedFile,
    });
    assignUrl(payload.id, uploadedFile);
  };

  const upload = (id) => {
    Storage.set("id", id);
    assignUrlFunc(id, updateUrl, uploadFile, "multiple");
  };

  const removeFile = (id) => {
    handleRemoval(id);
    setState({
      ...state,
      file_name: "",
      loaded: false,
    });
  };

  const setId = Storage.get("id") && Number(Storage.get("id"));
  const loading = setId === index && isUploading;

  return (
    <div>
      {loading ? (
        <div className="appointment-subcategories-image">
          <span className="appointment-subcategories-image-text2">
            Uploading Icon...
          </span>
        </div>
      ) : state.loaded ? (
        <div className="appointment-subcategories-image2">
          <div className="appointment-subcategories-image2-text2">
            {state.image_name &&
              state.image_name.name &&
              truncateName(state.image_name.name, 15)}
          </div>
          <div
            className="icon-upload"
            onClick={() => removeFile(index)}
            data-testid="removeFile"
          >
            <img src={Cancel} alt="remove" />
          </div>
        </div>
      ) : (
        <div className="appointment-subcategories-image">
          <div className="appointment-subcategories-image-div">
            <img src={Subcategory} alt="subcategory pic" />
          </div>
          <span className="appointment-subcategories-image-text">
            Add icon{" "}
            <small style={{ color: "#dc0000", fontSize: "12px" }}>*</small>
          </span>
          <input
            type="file"
            className="custom-file-input"
            data-testid={`uploadFile${index}`}
            id={`custom-file-input${index}`}
            onChange={() => upload(index)}
          />
        </div>
      )}
    </div>
  );
};

export default SubCategoryIcon;
