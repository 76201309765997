import React, { useEffect, useState } from "react";
import Modal, { ModalButton } from "../Modal/Modal";
import { Form, Formik, Field } from "formik";
import DateFilter from "../Input/DateFilter";
import Input from "../Input/Input";
import TimePicker from "../Input/TimePicker";
import { connect } from "react-redux";
import {
  getUserAppointments,
  resheduleUserAppointment,
  resetRescheduleSuccess,
} from "../../redux/Actions/UserAppointmentActions";
import { parseISO } from "date-fns";
import moment from "moment";
import { useHistory } from "react-router";
import { getOverview } from "../../redux/Actions/OverviewActions";
import responseHandler from "../../utils/notifications/responseHandler";
import { useOverviewTypeContext } from "../../contexts/OverviewTypeContext";

const ReScheduleAppointment = (props) => {
  const {
    displayModal,
    closeModal,
    isRescheduling,
    rescheduleAppointment,
    rescheduleSuccess,
    data,
    fetchOverview,
    getAppointments,
    resetReschedule,
    limit,
  } = props;

  const [state, setState] = useState({
    date: data && data.scheduled_date,
    time: data && data.scheduled_time,
  });
  const currentDate = new Date();
  const currentTime = moment().format("HH:mm:ss");

  const type = useOverviewTypeContext();
  const history = useHistory();
  const id = data && data.id;

  const initialValues = {
    email: data && data.user && data.user.email,
    appointment_category:
      data && data.appointment_category && data.appointment_category.name,
    appointment_subcategory:
      data &&
      data.appointment_sub_category &&
      data.appointment_sub_category.name,
    facility: data && data.facility && data.facility.name,
  };

  const submitValues = () => {
    const payload = {
      scheduled_date: state.date,
      scheduled_time: state.time,
    };
    const scheduledTime = moment(payload.scheduled_time).format("HH:mm:ss");
    const scheduledHour = new Date(payload.scheduled_time).getHours();
    const scheduledDate = new Date(payload.scheduled_date);
    const appointmentIsOpen = scheduledHour >= 8 && scheduledHour < 16;
    const pastTime =
      moment(scheduledDate).isSame(currentDate, "day") &&
      scheduledTime <= currentTime &&
      scheduledHour < 16;
    const pastDate = moment(scheduledDate).isBefore(currentDate, "day");
    if (!appointmentIsOpen) {
      responseHandler(
        { problem: "CLIENT_ERROR" },
        "Select a time between 8AM and 4PM"
      );
    } else if (pastTime) {
      responseHandler(
        { problem: "CLIENT_ERROR" },
        "Select a future time for this date"
      );
    } else if (pastDate) {
      responseHandler({ problem: "CLIENT_ERROR" }, "Select a date in future");
    } else {
      rescheduleAppointment(id, payload);
    }
  };

  const disabled = state.date === "" || state.time === "" || isRescheduling;

  useEffect(
    () => {
      if (rescheduleSuccess) {
        closeModal();
        if (history?.location?.pathname === "/appointment") {
          getAppointments(1, limit);
          return;
        }
        fetchOverview(type?.overviewType);
        resetReschedule();
      }
    },
    // eslint-disable-next-line
    [rescheduleSuccess]
  );

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Reschedule Appointment"}
      width={"568px"}
    >
      <Formik initialValues={initialValues} onSubmit={() => submitValues()}>
        {({ isValid, dirty }) => {
          return (
            <Form id="form">
              <div className="form-grid3">
                <Field
                  name="email"
                  label="Email"
                  placeholder="Enter client's email"
                  component={Input}
                  readOnly
                />

                <Field
                  name="facility"
                  label="Facility"
                  placeholder="Enter facility"
                  component={Input}
                  readOnly
                />
              </div>

              <div className="form-grid2">
                <DateFilter
                  label={"Date"}
                  placeholder={"Select date"}
                  state={state}
                  name={"date"}
                  setState={setState}
                  previousDate={parseISO(state.date)}
                  minDate={moment().toDate()}
                />

                <div>
                  <TimePicker
                    label={"Time"}
                    placeholder={"Select time"}
                    state={state}
                    name={"time"}
                    setState={setState}
                    setTime={state.time}
                    setOnMount
                  />
                  <span className="notify">
                    Morning (8 to 12pm) or Afternoon (12 to 4pm)
                  </span>
                </div>
              </div>

              <div className="form-grid2">
                <Field
                  name="appointment_category"
                  label="Appointment type"
                  placeholder="Enter category"
                  component={Input}
                  readOnly
                />

                <Field
                  name="appointment_subcategory"
                  label="Service"
                  placeholder="Enter subcategory"
                  component={Input}
                  readOnly
                />
              </div>

              <ModalButton
                isLoading={isRescheduling}
                text={"Reschedule"}
                type={"submit"}
                testId={"button"}
                loadingText={"Rescheduling..."}
                disabled={disabled}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isRescheduling: state.userappointment.isRescheduling,
  rescheduleSuccess: state.userappointment.rescheduleSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  rescheduleAppointment: (id, payload) =>
    dispatch(resheduleUserAppointment(id, payload)),
  getAppointments: (page, limit) => dispatch(getUserAppointments(page, limit)),
  fetchOverview: (payload) => dispatch(getOverview(payload)),
  resetReschedule: () => dispatch(resetRescheduleSuccess()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReScheduleAppointment);
