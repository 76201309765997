import React, { useState } from "react";
import Table from "../Tables/Table";
import { useHistory } from "react-router-dom";
import { DropBtn, TableDropdown } from "../Tables/TableButtons";
import UploadCSVModal from "./UploadCSVModal";
import { useDispatch } from "react-redux";
import { uploadEnterpriseUsersFailure } from "../../redux/Actions/EnterpriseActions";

const EnterpriseColumns = (props) => {
  const { data, getData, success, isLoading } = props;
  const columns = [
    {
      Header: "Enterprise Name",
      accessor: "name",
      Cell: ({ row }) => (
        <div className="table-flex">
          
          <div className="table-flex-image">
            <img src={row.original && row.original.logo_url} alt="product" />
          </div>
          <span style={{ marginLeft: "16px" }}>
            {row.original && row.original.name}{" "}
          </span>
        </div>
      ),
    },
    {
      Header: "Enterprise Domain",
      accessor: "domain",
      Cell: ({ row }) => (
        <div className="table-flex">
          <span>{row.original && row.original.domain}</span>
        </div>
      ),
    },
    {
      Header: "",
      accessor: "upload",
      Cell: ({ row }) => {
        const [show, setShow] = useState(false);
        const dispatch = useDispatch();

        return (
          <>
            {show ? (
              <UploadCSVModal rowId={row.original.id} setShow={setShow} />
            ) : null}
            <div
              onClick={() => {
                dispatch(uploadEnterpriseUsersFailure());
                setShow(true);
              }}
              className="table-action-button"
            >
              Upload Users
            </div>
          </>
        );
      },
    },
    {
      header: "Action",
      accessor: "id",
      Cell: ({ row }) => {
        const [show, setShow] = useState(false);
        const history = useHistory();
        const options = [
          {
            name: "View & Edit",
            action: () =>
              history.push(`/enterprise/view/edit/${row.original.id}`),
          },
        ];
        return (
          <div
            style={{
              width: "55%",
              position: "relative",
            }}
          >
            <DropBtn onClick={() => setShow((prevCheck) => !prevCheck)} />
            {show && <TableDropdown options={options} />}
          </div>
        );
      },
    },
  ];
  return (
    <Table
      columns={columns}
      data={data}
      getData={getData}
      success={success}
      isLoading={isLoading}
    />
  );
};

export default EnterpriseColumns;
