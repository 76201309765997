import Table from "../../components/Tables/Table";
import { Link } from "react-router-dom";

const PrescriptionsColumns = (props) => {
  const { data, getData, success, isLoading, getMoreData, total } = props;
  const prescriptionsColumns = [
    {
      Header: "Doctor",
      accessor: "doctor",
      Cell: ({ row }) => (
        <div className="table-flex">
          <span>{row.original.doctor?.username}</span>
        </div>
      ),
    },
    {
      Header: "Customer",
      accessor: "customers",
      Cell: ({ row }) => (
        <div className="table-flex">
          <span>{row.original.customer?.username}</span>
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <div
          style={{
            width: row.original.status === "draft" ? "7rem" : "9.5rem",
            height: "3rem",
          }}
          className={`${
            row.original.status === "draft"
              ? "prescription-draft"
              : "prescription-published"
          } table-flex prescription-status`}
        >
          <span
            style={{
              color: row.original.status === "draft" ? "#0A74DC" : "#0f8d11",
            }}
          >
            {row.original.status}
          </span>
        </div>
      ),
    },
    {
      Header: "Prescription Number",
      accessor: "prescription_number",
      Cell: ({ row }) => (
        <div className="table-flex">
          <span  style={{ "marginRight": "10px" }}>{row.original.prescription_number}</span>
        </div>
      ),
    },
    {
      Header: "Prescriptions Items",
      accessor: "prescription_items",
      Cell: ({ row }) => {
        return (
          <div className="">
            {row.original.prescription_items?.map((item) => (
              <div key={item.drug} className="table-flex">
                <span>{item.drug}</span>
              </div>
            ))}
          </div>
        );
      },
    },
    {
      // Header: "Prescriptions Items",
      accessor: "more",
      Cell: ({ row }) => {
        return (
          <div style={{ float: "right" }} className="prescription-table-button">
            <Link to={`/prescriptions/${row.original.id}`}>
              <p>View Details</p>
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <Table
      columns={prescriptionsColumns}
      data={data}
      getData={getData}
      success={success}
      isLoading={isLoading}
      getMoreData={getMoreData}
      total={total}
    />
  );
};
export default PrescriptionsColumns;
