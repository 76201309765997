import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createDeliveryFee } from "../../redux/Actions/DeliveryFeesActions";
import { DeliveryFeeSchema } from "../../utils/validation/validation";
import Input from "../Input/Input";
import Modal, { ModalButton } from "../Modal/Modal";

const CreateDeliveryFee = (props) => {
	const {
		displayModal,
		closeModal,
		createDeliveryFee,
		isCreating,
		createSuccess,
	} = props;

	const [state, setState] = useState({
		state: "",
		fees: "",
	});

	useEffect(
		() => {
			if (createSuccess) {
				document.getElementById("addDeliveryFeeForm").reset();
				setState({
					...state,
					active: false,
					status: "",
					index: "",
				});
			}
			closeModal();
		},
		// eslint-disable-next-line
		[createSuccess]
	);

	const initialValues = {
		state: "",
		fees: "",
	};

	const submitValues = (values) => {
		const { state, fees } = values;

		const payload = {
			state,
			fees,
		};

		createDeliveryFee(payload);
	};

	return (
		<Modal
			displayModal={displayModal}
			closeModal={closeModal}
			title={"Add New Region"}
			width={"568px"}
		>
			<Formik
				initialValues={initialValues}
				validationSchema={DeliveryFeeSchema}
				onSubmit={(values) => submitValues(values)}
			>
				{({ isValid, dirty, setFieldValue }) => {
					const disabled =
						isCreating ||
						!(isValid && dirty) ||
						state.name === "" ||
						state.amount === "";

					return (
						<Form id="addDeliveryFeeForm">
							<Field
								name="state"
								label="State"
								placeholder="Enter state"
								component={Input}
								emptyValue={setFieldValue}
							/>
							<div className="top-spacer">
							<Field
								type="number"
								name="fees"
								label="Fees(NGN)"
								placeholder="Enter amount"
								component={Input}
								emptyValue={setFieldValue}
							/></div>

							<ModalButton
								isLoading={isCreating}
								text={"Create"}
								type={"submit"}
								testId={"button"}
								disabled={disabled}
								loadingText={"Creating..."}
							/>
						</Form>
					);
				}}
			</Formik>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	isCreating: state.deliveryFees.isCreating,
	createSuccess: state.deliveryFees.createSuccess,
});

const mapDispatchToProps = (dispatch) => ({
	createDeliveryFee: (payload) => dispatch(createDeliveryFee(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateDeliveryFee);
