import React, { useState } from "react";
import { PageButton } from "../Button/Button";
import ExportIcon from "../../assets/Union.svg";
import DropIcon from "../../assets/drop-export.svg";
import TableDropdown from "../Tables/Dropdown";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file


const Export3 = (props) => { 
  const { exportHandler, isGettingCSVResponse } = props; 

  const [show, setShow] = useState(false);

  const options = [
    // {
    //   name: "PDF",
    //   action: () => exportHandler("PDF"),
    // },
    {
      name: "CSV",
      action: () => exportHandler("CSV"),
    },
  ];


  return (
    <div className="overview-header-right">
      <div className="overview-header-right-button">
        <PageButton
          width={"121px"}
          mainWidth={"144px"}
          background={"rgba(10, 116, 220, 0.08)"}
          marginTop={"0px"}
          type={"button"}
          onClick={() => setShow((prevCheck) => !prevCheck)}
        >
          <div className="button-data">
            <img src={ExportIcon} alt="export icon" />
            <span className="span2">Export</span>
            <img src={DropIcon} alt="dropdown" />
          </div>
        </PageButton>
        {show && (
          <TableDropdown
            active={show}
            hideDropdown={() => setShow(false)}
            options={options}
            isGettingCSVResponse={isGettingCSVResponse}
          />
        )}
      </div>
    </div>
  );
};

export default Export3;
