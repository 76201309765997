import React, { useEffect } from "react";
import { connect } from "react-redux";
import { filterAuditTrail } from "../../../redux/Actions/AuditTrailActions";
import DateFilter from "../../Input/DateFilter";
import { Statuses } from "../../Input/Statuses";
import Modal, { ModalButton } from "../../Modal/Modal";

const FilterAuditTrail = (props) => {
  const {
    state,
    setState,
    displayModal,
    closeModal,
    filterAuditTrail,
    isFiltering,
    filterSuccess,
  } = props;

  const status = [{ name: "Success" }, { name: "Failed" }];

  const disabled = isFiltering || state.date === "" || state.status === "";

  const handleFilter = () => {
    const date = state.date;
    const status = state.status && state.status.toLowerCase();

    filterAuditTrail(1, 10, date, status);
  };

  useEffect(
    () => {
      if (filterSuccess) {
        closeModal();
      }
    },
    // eslint-disable-next-line
    [filterSuccess]
  );
  return (
    <div>
      <Modal
        displayModal={displayModal}
        closeModal={closeModal}
        title={"Filter Audit Trail"}
        width={"496px"}
      >
        <DateFilter
          label={"Date"}
          placeholder={"Select date"}
          state={state}
          name={"date"}
          setState={setState}
          success={filterSuccess}
        />

        <div className="top-spacer">
          <Statuses
            label={"Status"}
            state={state}
            options={status}
            setstate={setState}
            blue={false}
            numberPerRow={3}
          />
        </div>

        <ModalButton
          isLoading={isFiltering}
          disabled={disabled}
          text={"Apply Filter"}
          onClick={() => handleFilter()}
          testId={"button"}
          loadingText={"Filtering..."}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isFiltering: state.auditTrail.isFiltering,
  filterSuccess: state.auditTrail.filterSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  filterAuditTrail: (page, limit, created_at, status) =>
    dispatch(filterAuditTrail(page, limit, created_at, status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterAuditTrail);
