import React, { useState } from "react";
import BackBtn from "../../components/Input/BackBtn";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import {
  getSingleEnterpriseData,
  updateEnterpriseData,
} from "../../redux/Actions/EnterpriseActions";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import EditEnterpriseInput from "../../components/Enterprise/EditEnterpriseInput";
import { LoaderSpin } from "../../components/Loader/Loader";
const EditEnterprise = (props) => {
  const history = useHistory();
  const {
    isGetting,
    enterpriseData,
    getEnterpriseDataSuccess,
    editEnterpriseData,
    getEnterpriseData,
    updateEnterpriseDataSuccess,
  } = props;
  const location = useLocation();
  const paths = location.pathname.split("/");
  const id = paths[paths.length - 1];
  const [state, setState] = useState({
    name: enterpriseData && enterpriseData.name,
    domain: enterpriseData && enterpriseData.domain,
    file_name: enterpriseData && enterpriseData.logo_url,
    enterprise_services: [],
    enterprise_facilities: [],
  });
  useEffect(() => {
    if (getEnterpriseDataSuccess) {
      setState({
        ...state,
        loaded: true,
        name: enterpriseData && enterpriseData.name,
        domain: enterpriseData && enterpriseData.domain,
        file_name: enterpriseData && enterpriseData.logo_url,
        enterprise_services: enterpriseData?.enterprise_services,
        enterprise_facilities: enterpriseData?.enterprise_facilities,
      });
    }
  }, [enterpriseData]);

  useEffect(() => {
    getEnterpriseData(id);
  }, [location]);
  useEffect(() => {
    if (updateEnterpriseDataSuccess) {
      history.goBack();
    }
  }, [updateEnterpriseDataSuccess]);

  const initialValues = {
    name: (enterpriseData && enterpriseData.name) || "",
    domain: (enterpriseData && enterpriseData.domain) || "",
  };
  const submitValues = (values) => {
    const payload = {
      name: values.name,
      domain: values.domain,
      logo_url: state.file_name,
      enterprise_services: state.enterprise_services,
      enterprise_facilities: state.enterprise_facilities,
    };
    console.log(payload);
    editEnterpriseData(id, payload);
  };
  return (
    <div className="">
      <BackBtn page={"/enterprise"} />;
      <HeaderWrapper title={"Edit Enterprise"} />
      {isGetting ? (
        <LoaderSpin />
      ) : (
        <div className="assessment-manager-single-container">
          <EditEnterpriseInput
            submitValues={submitValues}
            initialValues={initialValues}
            state={state}
            setState={setState}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGetting: state.enterprise.isGettingSingeEnterpriseData,
  enterpriseData: state.enterprise.singleEnterpriseData,
  getEnterpriseDataSuccess: state.enterprise.getSingleEnterpriseDataSuccess,
  updateEnterpriseDataSuccess: state.enterprise.isUpdatingSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  editEnterpriseData: (id, payload) =>
    dispatch(updateEnterpriseData(id, payload)),
  getEnterpriseData: (id) => dispatch(getSingleEnterpriseData(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditEnterprise);
