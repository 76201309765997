import React, { useEffect } from "react";
import BackBtn from "../../assets/chevron_right.svg";
import EmptyChat from "./EmptyChat";
import Icon from "../../assets/account_icon.svg";
import { filterCustomer, filterSpecialist } from "../../utils/functions/Chats";
import {
  dateToString,
  timeDifferenceForDate,
} from "../../utils/functions/date";

const SingleClientChat = (props) => {
  const { chat, component, className, chats, state } = props;
  const specialist = chat && filterSpecialist(chat);
  useEffect(() => {
    console.log(specialist);
  }, [specialist]);
  const customer = chat && filterCustomer(chat);

  return (
    <div className={`chats-right ${className}`} id="overall-chats-inner">
      {chat === "" ? (
        <EmptyChat
          text={
            chats?.length === 0
              ? "No chat is available"
              : "Open a chat to get started"
          }
          className="chats-right-details3"
        />
      ) : (
        <>
          <div className="chats-right-header" style={{ flexWrap: "wrap" }}>
            <div className="chats-right-header-left">
              <input type="checkbox" id="chat-check" />
              <label htmlFor="chat-check">
                <div className="chats-back">
                  <img src={BackBtn} alt="back" />
                </div>
              </label>
              <div className="chats-right-header-left-image">
                <img
                  src={customer?.user?.image_url || Icon}
                  alt="profile pic"
                />
              </div>
              <div className="chats-right-header-left-details">
                <div className="top">
                  {customer?.user?.first_name && customer?.user?.last_name
                    ? `${customer?.user?.first_name} ${customer?.user?.last_name}`
                    : "Nil"}
                </div>
                <div className="bottom">{chat?.status}</div>
              </div>
            </div>
            <div className="chats-right-header-right">{component}</div>
            {specialist ? (
              <div
              className="specialist-info"
                
              >
                <div style={{ marginRight: "3rem" }}>
                  <span style={{ fontWeight: "bold", color: "#a4a7b7" }}>Doctor:</span>{" "}
                  <span>
                    {specialist?.user?.username || specialist?.user?.email}
                  </span>
                </div>
                <div style={{ marginRight: "3rem" }}>
                  <span style={{ fontWeight: "bold", color: "#a4a7b7" }}>Initialized:</span>{" "}
                  {dateToString(specialist?.created_at)}
                </div>
                <div>
                  <span style={{ fontWeight: "bold", color: "#a4a7b7" }}>Duration:</span>{" "}
                  {timeDifferenceForDate(specialist?.created_at)}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="chats-right-details">
            {specialist && !state.active2 && (
              <div
                className="chats-right-details-title"
                style={{ color: "#434854" }}
              >
                With {specialist?.user?.full_name}
              </div>
            )}
            <br />
            <div className="chats-right-details-title">{chat?.question}</div>
            <div className="chats-right-details-history">
              Chat history not available
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SingleClientChat;
