import React, { useState } from "react";
import BellIcon from "../../assets/notifications_iconsvg.svg";
import ProfileIcon from "../../assets/account_icon.svg";
import OptionIcon from "../../assets/option_icon.svg";
import CancelIcon from "../../assets/cancel-icon.svg";
import Sidebar2 from "../Sidebar/Sidebar2";
import Dropdown from "./Dropdown";
import {
  getAdminDetails,
  getUserData,
} from "../../utils/functions/GetStorageItems";

const Navbar = () => {
  const [menuChecked, setMenuChecked] = useState(false);
  const [show, setShow] = useState(false);

  const { role } = getUserData(); 

  const { first_name, last_name, image } = getAdminDetails(); 

  return (
    <header className="navbar">
      <input type="checkbox" id="nav-check" defaultChecked={menuChecked} />
      <Sidebar2 menuChecked={menuChecked} setMenuChecked={setMenuChecked} />

      <div
        className="navbar-left"
        data-testid="setmenu"
        onClick={() => setMenuChecked(!menuChecked)}
      >
        <label htmlFor="nav-check">
          <div className="navbar-left-item1"></div>
          <div className="navbar-left-item2"></div>
          <div className="navbar-left-item3"></div>
        </label>
      </div>
      <div className="navbar-right">
        <div className="navbar-right-bell">
          <img src={BellIcon} alt="bell-icon" />
        </div>
        <div className="navbar-right-hr"></div>
        <div className="navbar-right-profile">
          <img src={image ? image : ProfileIcon} alt="bell-icon" />
        </div>
        <div className="navbar-right-user">
          {first_name} {last_name}
        </div>
        <div
          className="drop-btn-img"
          onClick={() => setShow((prevCheck) => !prevCheck)}
        >
          <img src={show ? CancelIcon : OptionIcon} alt="options-icon" />
        </div>
        {show && <Dropdown role={role} />}
      </div>
    </header>
  );
};

export default Navbar;
