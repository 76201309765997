import React, { useState, useEffect, useRef } from "react";
import Table from "../../../components/Tables/Table";
import { useAssessmentManagerColumns } from "../../TableData/Appointment/AppointmentManager";
import { connect } from "react-redux";
import {
  getAssessments,
  updateAssessmentStatus,
  reorderAssessment,
} from "../../../redux/Actions/AssessmentActions";
import { getAppointmentCategories } from "../../../redux/Actions/AppointmentActions";
import FilterAssessment from "./FilterAssessment";

export const setNewData = (data) => {
  const assessments = data.map((assessment, index) => ({
    status: assessment.status,
    type: assessment.type,
    title: assessment.question,
    order_sequence: index + 1,
    id: assessment.id || assessment.question_id,
    category: assessment?.appointment_category?.name || assessment.category,
  }));
  return assessments;
};

const Assessment = ({
  fetchAssessments,
  getAssessmentsSuccess,
  assessments,
  isGettingAssessments,
  updateStatus,
  reorderQuestion,
  reorderingQuestion,
  closeModal,
  modalActive,
  filterSuccess,
  getAllCategories,
  isFilteringByCategory,
  filterByCategorySuccess,
  filteredAssessments,
  id,
  assessmentsData,
  setAssessmentsData,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isDraggable, setIsDraggable] = useState(false);
  const isFirstRender = useRef(true);
  const { columns } = useAssessmentManagerColumns(updateStatus);

  useEffect(
    () => {
      getAllCategories();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (getAssessmentsSuccess || filterSuccess) {
        setAssessmentsData(setNewData(assessments.rows));
        setIsDraggable(false);
      }
    },
    // eslint-disable-next-line
    [getAssessmentsSuccess, filterSuccess]
  );

  useEffect(
    () => {
      if (filterByCategorySuccess) {
        setAssessmentsData(setNewData(filteredAssessments));
        setIsDraggable(true);
      }
    },
    // eslint-disable-next-line
    [filterByCategorySuccess]
  );

  useEffect(
    () => {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }
      if (!isDragging && filterByCategorySuccess) {
        const reorderedQuestions = {
          questions_id: assessmentsData.map((assessment) => assessment.id),
        };
        reorderQuestion(reorderedQuestions, id);
      }
    },
    // eslint-disable-next-line
    [isDragging]
  );

  const reArrangeAssessmentData = (data) => {
    setAssessmentsData(
      data.map((obj, index) => ({ ...obj, order_sequence: index + 1 }))
    );
  };

  return (
    <div className={`table-disabled${reorderingQuestion ? " active" : ""}`}>
      <Table
        columns={columns}
        data={assessmentsData}
        emptyText1={"You haven't created any questions"}
        getData={() => fetchAssessments({ type: "all" }, setAssessmentsData)}
        isLoading={isGettingAssessments || isFilteringByCategory}
        success={
          getAssessmentsSuccess || filterSuccess || filterByCategorySuccess
        }
        isDraggable={isDraggable}
        reArrangedData={(newData) => reArrangeAssessmentData(newData)}
        rowDragState={setIsDragging}
        className="assess-table"
      />
      <FilterAssessment
        isGettingAssessments={isGettingAssessments}
        isGettingSuccess={getAssessmentsSuccess}
        modalActive={modalActive}
        fetchAssessments={fetchAssessments}
        closeModal={closeModal}
        stopTableDrag={setIsDraggable}
        setAssessmentsData={setAssessmentsData}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  getAssessmentsSuccess: state.assessments.getAssessmentsSuccess,
  assessments: state.assessments.assessments,
  isGettingAssessments: state.assessments.isGettingAssessments,
  filterSuccess: state.assessments.filterSuccess,
  reorderingQuestion: state.assessments.reorderingQuestion,
  reorderingSuccess: state.assessments.reorderingSuccess,
  isGettingCategories: state.appointment.isGettingCategories,
  getCategorySuccess: state.appointment.getCategorySuccess,
  categories: state.appointment.categories,
  isFilteringByCategory: state.assessments.isFilteringByCategory,
  filterByCategorySuccess: state.assessments.filterByCategorySuccess,
  filteredAssessments: state.assessments.filteredAssessments,
  deleteSuccess: state.assessments.isUpdatingSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAssessments: (queries, setData) =>
    dispatch(getAssessments(queries, setData)),
  updateStatus: (status, id) => dispatch(updateAssessmentStatus(status, id)),
  reorderQuestion: (payload, id) => dispatch(reorderAssessment(payload, id)),
  getCategories: () => dispatch(getAppointmentCategories),
});

export default connect(mapStateToProps, mapDispatchToProps)(Assessment);
