import React, { useState } from "react";
import truncateName from "../../../utils/functions/ShortenStr";
import ActivateAdmin from "../../AdminManagement/ActivateAdmin";
import DeleteAdmin from "../../AdminManagement/DeleteAdmin";
import EditAdmin from "../../AdminManagement/EditAdmin";
import SuspendAdmin from "../../AdminManagement/SuspendAdmin";
import moment from "moment";
import { cancelModal, viewModal } from "../../Modal/Modal";
import {
  SuccessBtn,
  DropBtn,
  TableDropdown,
  InfoBtn,
} from "../../Tables/TableButtons";
import { filterOptionsLabel } from "../../../utils/functions/FilterObject";

export const AdminColumns = [
  {
    Header: "Name",
    accessor: "full_name",
    Cell: ({ row }) => (
      <div className="table-flex">
        <span style={{ textTransform: "capitalize" }}>
          {row.original.first_name} {row.original.last_name}
        </span>
      </div>
    ),
  },
  {
    Header: "Email",
    accessor: "email",
    Cell: ({ row }) => (
      <div className="table-flex">
        <span>{truncateName(row.original.email, 15) || "-"}</span>
      </div>
    ),
  },
  {
    Header: "Role",
    accessor: "role",
    Cell: ({ row }) => (
      <div style={{ textTransform: "capitalize" }}>{row.original.role}</div>
    ),
  },
  {
    Header: "Date Added",
    accessor: "date",
    Cell: ({ row }) => (
      <div className="table-flex">
        {moment(row.original.createdAt).format("DD-MM-YYYY")}
      </div>
    ),
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ row }) =>
      row.original.status === "active" ? (
        <SuccessBtn text={"Active"} />
      ) : (
        <InfoBtn text={"Suspended"} />
      ),
  },
  {
    Header: "Action ",
    accessor: "action",
    Cell: ({ row }) => {
      const id = row.original && row.original.id;
      const status = row.original && row.original.status;
      const [show, setShow] = useState(false);
      const [state, setState] = useState({
        editModal: false,
        suspendModal: false,
        activateModal: false,
        deleteModal: false,
        checkedItems: new Map(),
        checked: true,
      });

      const showModal = (type) => {
        viewModal(type, state, setState);
        setShow((prevCheck) => !prevCheck);
      };

      const options = [
        {
          name: "Edit",
          action: () => showModal("editModal"),
        },
        {
          name: "Activate",
          label: "Active",
          action: () => showModal("activateModal"),
        },
        {
          name: "Suspend",
          label: "Suspended",
          action: () => showModal("suspendModal"),
        },
        {
          name: "Delete",
          action: () => showModal("deleteModal"),
        },
      ];

      const closeModal = (type) => cancelModal(type, state, setState);

      return (
        <div className="action-flex">
          <DropBtn onClick={() => setShow((prevCheck) => !prevCheck)} />
          {show && (
            <TableDropdown
              options={options && filterOptionsLabel(status, options)}
            />
          )}

          {state.editModal && (
            <EditAdmin
              displayModal={state.editModal}
              closeModal={() => closeModal("editModal")}
              data={row.original}
              state={state}
              setState={setState}
            />
          )}

          {state.activateModal && (
            <ActivateAdmin
              displayModal={state.activateModal}
              closeModal={() => closeModal("activateModal")}
              data={row.original}
            />
          )}

          {state.suspendModal && (
            <SuspendAdmin
              displayModal={state.suspendModal}
              closeModal={() => closeModal("suspendModal")}
              data={row.original}
            />
          )}

          {state.deleteModal && (
            <DeleteAdmin
              displayModal={state.deleteModal}
              closeModal={() => closeModal("deleteModal")}
              data={row.original}
              id={id}
            />
          )}
        </div>
      );
    },
  },
];
