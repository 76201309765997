import React from "react";
import Icon from "../../assets/account_icon.svg";
import moment from "moment";
import Empty from "../../assets/empty.svg";

const Patients = (props) => {
  const { data, type } = props;

  return (
    <>
      {data?.length > 0 ? (
        <div className="appointment-grid2">
          {data &&
            data?.map((item, index) => (
              <div className="appointment-grid-patient-section" key={index}>
                <div className="appointment-grid-patient-section-top">
                  <div className="header">
                    <div>
                      <img
                        src={item?.image_url === null ? Icon : item?.image_url}
                        alt="individual"
                      />
                    </div>
                    <h2>
                      {item?.first_name === null ? "Nil" : item?.first_name}{" "}
                      {item?.last_name === null ? "Nil" : item?.last_name}
                    </h2>
                  </div>
                  <div className="body">
                    <div className="body-item">
                      <p>Date</p>
                      <h3>{moment(item.updated_at).format("ll")}</h3>
                    </div>
                    <div className="body-item">
                      <p>Time</p>
                      <h3>{moment(item.updated_at).format("LT")}</h3>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <div className="table-empty2">
          <div className="table-empty-container">
            <div className="table-empty-container-image">
              <img src={Empty} alt="empty-table" />
            </div>
            <div>
              <h2>No {type} available</h2>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Patients;
