import React, { useEffect, useState } from "react";
import Modal, { ModalButton } from "../Modal/Modal";
import { Statuses } from "../Input/Statuses";
import { Form, Formik } from "formik";
import DateFilter from "../Input/DateFilter";
import MultiDropdown from "../Input/MultiDropdown";

const FilterAppointment = (props) => {
  const {
    displayModal,
    closeModal,
    isFiltering,
    filterAppointment,
    setFilterState,
    filterState,
    filterSuccess,
  } = props;

  const status = [
    { category_id: "pending", name: "Pending" },
    { category_id: "declined", name: "Declined" },
    { category_id: "completed", name: "Completed" },
    { category_id: "upcoming", name: "Upcoming" },
    { category_id: "confirmed", name: "Confirmed" },
  ];

  const [state, setState] = useState({
    startDate: "",
    endDate: "",
    active: false,
    status: "",
    statuses: []
  });

  const disabled =
    isFiltering 

  const handleFilter = () => {
    const statusMap = state.statuses.map(el => el.category_id)
    const status = statusMap.join(",")
    const payload = {
      start_date: state.startDate,
      status: status,
      end_date: state.endDate,
    };
    setFilterState({
      ...filterState,
      filterValues: payload,
    });
    filterAppointment(1, 10, payload);
  };

  useEffect(
    () => {
      if (filterSuccess) {
        closeModal();
        setState({
          ...state,
          startDate: "",
          endDate: "",
          active: false,
          status: "",
        });
      }
    },
    // eslint-disable-next-line
    [filterSuccess]
  );

  return (
    <div>
      <Modal
        displayModal={displayModal}
        closeModal={closeModal}
        title={"Filter Appointment"}
        width={"496px"}
      >
        <Formik>
          {({ isValid, dirty }) => {
            return (
              <Form id="form">
                <DateFilter
                  label={"Start Date"}
                  placeholder={"Select start date"}
                  state={state}
                  name={"startDate"}
                  setState={setState}
                  success={filterSuccess}
                />

                <div className="top-spacer">
                  <DateFilter
                    label={"End Date"}
                    placeholder={"Select end date"}
                    state={state}
                    name={"endDate"}
                    setState={setState}
                    success={filterSuccess}
                  />
                </div>

                {/* <div className="top-spacer">
                  <Statuses
                    label={"Status"}
                    state={state}
                    options={status}
                    setstate={setState}
                    blue={false}
                    numberPerRow={3}
                  />
                </div> */}
                <MultiDropdown
                  state={state}
                  accessor="statuses"
                  label="Status"
                  placeholder={
                    state.statuses.length > 0
                      ? "Filter by more statuses..."
                      : "e.g Select Statuses"
                  }
                  top={"30px"}
                  testid={"statuses-tags"}
                  options={status}
                  onSelect={(statuses) =>
                    setState((state) => ({ ...state, statuses }))
                  }
                  onRemove={(statuses) =>
                    setState((state) => ({ ...state, statuses }))
                  }
                />

                <ModalButton
                  isLoading={isFiltering}
                  text={"Apply Search"}
                  testId={"button"}
                  loadingText={"Filtering..."}
                  disabled={disabled}
                  onClick={handleFilter}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
};

export default FilterAppointment;
