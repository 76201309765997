import React, { useEffect } from "react";
import { Headers } from "../Wrappers/AuthWrapper";
import Table from "../Tables/Table";
import { otherAdminsColumns } from "../TableData/OtherAdmins/OtherAdmins";
import { AddAdminButton, AuthButton } from "../Button/Button";
import { connect } from "react-redux";
import { getAdmins, getRoles } from "../../redux/Actions/AdminActions";
import { useHistory } from "react-router-dom";
import NoAdmin from "./NoAdmin";

const OtherAdmins = (props) => {
  const {
    state,
    setstate,
    getAdmins,
    isFetching,
    getAdminsSuccess,
    admins,
    getAdminRoles,
    adminsData,
    editAdminSuccess,
  } = props;

  const history = useHistory();

  const routeToOverview = () => {
    history.push("/overview");
  };

  useEffect(
    () => {
      getAdminRoles();
    },
    // eslint-disable-next-line
    [getAdminRoles]
  );

  useEffect(
    () => {
      if (editAdminSuccess) {
        getAdmins(1, 5);
      }
    },
    // eslint-disable-next-line
    [editAdminSuccess]
  );

  const totalAdmins = adminsData && adminsData.count;

  return (
    <div>
      {admins && admins.length > 0 ? (
        <>
          <Headers
            title1={"Other Admins"}
            title2={"View all other and add Admins here"}
          />
          <div className="add-admin-button">
            <AddAdminButton setstate={setstate} state={state} />
          </div>
          <Table
            columns={otherAdminsColumns}
            data={admins}
            getData={getAdmins}
            getMoreData={(page, perPage) => getAdmins(page, perPage)}
            emptyText1={"You haven't added any admin"}
            isLoading={isFetching}
            success={getAdminsSuccess}
            emptyResultText={"Admin not found!"}
            total={totalAdmins}
            auth={true}
            className={"auth-admin"}
          />
          {getAdminsSuccess && (
            <div className="bottom-btn">
              <AuthButton
                children={"Skip"}
                background={"#0A74DC"}
                width={"167px"}
                color={"#FFFFFF"}
                onClick={routeToOverview}
              />
            </div>
          )}
        </>
      ) : (
        <NoAdmin setstate={setstate} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isFetching: state.admin.isFetching,
  getAdminsSuccess: state.admin.getAdminsSuccess,
  admins: state.admin.admins,
  adminsData: state.admin.adminsData,
  editAdminSuccess: state.admin.editAdminSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getAdmins: (page, limit) => dispatch(getAdmins(page, limit)),
  getAdminRoles: (page, limit) => dispatch(getRoles(page, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OtherAdmins);
