import PropTypes from "prop-types";
import React from "react";
import { LoaderSpin } from "../Loader/Loader";

const MultiSelect = (props) => {
  const {
    options,
    title,
    checkedItems,
    setCheckedItems,
    className, 
    rowNo,
    marginBottom,
    isGetting,
    getSuccess,
    errorText,
  } = props;

  const onToggle = (index) => {
    if (index >= 0) {
      checkedItems[index].checked = !checkedItems[index].checked;
      setCheckedItems(JSON.parse(JSON.stringify(checkedItems)));
    }
  };

  return (
    <div>
      <div className={className ? className : "checkboxes-title"}>{title}</div>
      {isGetting ? (
        <LoaderSpin width="40px" height="40px" />
      ) : getSuccess ? (
        <div
          className="checkboxes"
          style={{
            gridTemplateColumns: `repeat(${rowNo}, 1fr)`,
            marginBottom: marginBottom,
          }}
        >
          {options &&
            options.map((item, index) => (
              <label key={index}>
                <input
                  className="checkbox-input"
                  type="checkbox"
                  id={item.id}
                  key={index}
                  name={item.name || item.label}
                  checked={item.checked}
                  onChange={() => onToggle(index)}
                  data-testid="onToggle"
                />
                {item.name || item.label}
              </label>
            ))}
        </div>
      ) : (
        <div>{errorText}</div>
      )}
    </div>
  );
};

MultiSelect.propTypes = {
  checked: PropTypes.shape({
    get: PropTypes.func,
  }),
  onChange: PropTypes.any,
  options: PropTypes.array,
  title: PropTypes.any,
};

export default MultiSelect;
