import PropTypes from "prop-types";
import React, { useRef, useEffect } from "react";
import select from "../../assets/action.svg";

export const SuccessBtn = (props) => {
  return (
    <div className={props.className ? props.className : "success-btn"}>
      <span style={{ textTransform: "capitalize" }}>{props.text}</span>
    </div>
  );
};

SuccessBtn.propTypes = {
  className: PropTypes.any,
  text: PropTypes.any,
};

export const ErrorBtn = (props) => {
  return (
    <div className={props.className ? props.className : "error-btn"}>
      <span style={{ textTransform: "capitalize" }}>{props.text}</span>
    </div>
  );
};

ErrorBtn.propTypes = {
  className: PropTypes.any,
  text: PropTypes.any,
};

export const PendingBtn = (props) => {
  return (
    <div className={props.className ? props.className : "pending-btn"}>
      <span style={{ textTransform: "capitalize" }}>{props.text}</span>
    </div>
  );
};

PendingBtn.propTypes = {
  className: PropTypes.any,
  text: PropTypes.any,
};

export const InfoBtn = (props) => {
  return (
    <div className={props.className ? props.className : "info-btn"}>
      <span style={{ textTransform: "capitalize" }}>{props.text}</span>
    </div>
  );
};

PendingBtn.propTypes = {
  className: PropTypes.any,
  text: PropTypes.any,
};

export const DropBtn = (props) => {
  return (
    <div
      className="action-flex-image"
      onClick={props.onClick}
      data-testid="dropBtn"
    >
      <img src={select} alt="select" />
    </div>
  );
};

DropBtn.propTypes = {
  onClick: PropTypes.any,
};

export const TableDropdown = (props) => {
  const { options, data, hideDropdown, fromLeft } = props;
  const dropdownRef = useRef();

  const toggleHandler = (option) => {
    if (data) {
      option.action(data);
    } else {
      option.action();
    }
  };

  useEffect(
    () => {
      dropdownRef.current.focus();
      dropdownRef.current.addEventListener("blur", () => {
        hideDropdown();
      });
    }, // eslint-disable-next-line
    []
  );

  return (
    <div
      tabIndex="-1"
      ref={dropdownRef}
      className={`table-dropdown${fromLeft ? " left" : ""}`}
    >
      {options &&
        options.map((option, index) => (
          <div
            className="table-dropdown-item"
            onClick={() => toggleHandler(option)}
            key={index}
            data-testid={`toggleHandler${index}`}
          >
            <div className="table-dropdown-item-text">{option.name}</div>
          </div>
        ))}
    </div>
  );
};

TableDropdown.defaultProps = {
  hideDropdown: () => {},
};

TableDropdown.propTypes = {
  options: PropTypes.array,
  hideDropdown: PropTypes.func,
};
