import React, { useEffect} from "react";
import { PageButton } from "../../components/Button/Button";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import { getAllEnterprises } from "../../redux/Actions/EnterpriseActions";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import EnterpriseColumns from "../../components/Enterprise/EnterpriseColumns";

const Enterprise = (props) => {
  const history = useHistory();
  const {
    enterpriseData,
    isGettingEnterpriseData,
    getEnterpriseSuccess,
    getAllEnterprises,
  } = props;

  useEffect(() => {
    getAllEnterprises(1, 10);
  }, []);


  return (
    <div>
      <HeaderWrapper
        title={"Enterprise Management"}
        subtitle={"Manage Enterprises and Users"}
        children={
          <PageButton
            width={"auto"}
            background={"rgba(10, 116, 220, 0.08)"}
            marginTop={"30px"}
            onClick={() => history.push("/enterprise/create-enterprise")}
            type={"submit"}
          >
            <div className="button-data">
              <span>Create Enterprise</span>
            </div>
          </PageButton>
        }
      ></HeaderWrapper>
      <EnterpriseColumns
        data={enterpriseData}
        getData={getAllEnterprises}
        success={getEnterpriseSuccess}
        isLoading={isGettingEnterpriseData}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  enterpriseData: state.enterprise.enterpriseData,
  getEnterpriseSuccess: state.enterprise.isGettingSuccess,
  isGettingEnterpriseData: state.enterprise.isGetting,
});

const mapDispatchToProps = (dispatch) => ({
  getAllEnterprises: (page, limit) => dispatch(getAllEnterprises(page, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Enterprise);
