import React, { useEffect } from "react";
import {
  changeUserAppointmentStatus,
  getUserAppointments,
  confirmUserAppointment,
} from "../../redux/Actions/UserAppointmentActions";
import DeleteModal from "../Modal/DeleteModal";
import { connect } from "react-redux";
import { getOverview } from "../../redux/Actions/OverviewActions";
import { useHistory } from "react-router";
import { useOverviewTypeContext } from "../../contexts/OverviewTypeContext";

const DeclineAppointment = (props) => {
  const {
    confirmUserAppointment,
    isConfirming,
    confirmSuccess,
    getAppointments,
    fetchOverview,
    data,
    limit,
  } = props;
  const id = data && data.id;
  const history = useHistory();
  const type = useOverviewTypeContext();

  useEffect(
    () => {
      if (confirmSuccess) {
        props.closeModal();
        if (history?.location?.pathname === "/appointment") {
          getAppointments(1, limit);
          return;
        }
        fetchOverview(type?.overviewType);
      }
    }, // eslint-disable-next-line
    [confirmSuccess]
  );

  const changeStatus = (id) => {
    confirmUserAppointment(id);
  };

  return (
    <DeleteModal
      displayModal={props.displayModal}
      closeModal={props.closeModal}
      title={"Confirm Appointment?"}
      width={"463px"}
      text={"Confirm"}
      onClick={() => changeStatus(id)}
      disabled={isConfirming}
      isLoading={isConfirming}
      loadingText={"Confirming..."}
      children={
        "Confirming this appointment would notifiy the user of his appointment details"
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isConfirming: state.userappointment.isChangingStatus,
  confirmSuccess: state.userappointment.changeSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  confirmUserAppointment: (id) =>
    dispatch(confirmUserAppointment(id)),
  getAppointments: (page, limit) => dispatch(getUserAppointments(page, limit)),
  fetchOverview: (queries) => dispatch(getOverview(queries)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeclineAppointment);
