import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";
import downloadCSV from "../../utils/functions/csvDownloader";

// GET ALL ORDERS
export const getAllOrdersStart = () => ({
  type: Actions.GET_ALL_ORDERS_START,
});

export const getAllOrdersSuccess = (payload) => ({
  type: Actions.GET_ALL_ORDERS_SUCCESS,
  payload,
});

export const getAllOrdersFailure = (payload) => ({
  type: Actions.GET_ALL_ORDERS_FAILURE,
  payload,
});

// GET ORDER
export const getOrderStart = () => ({
  type: Actions.GET_ORDER_START,
});

export const getOrderSuccess = (payload) => ({
  type: Actions.GET_ORDER_SUCCESS,
  payload,
});

export const getOrderFailure = (payload) => ({
  type: Actions.GET_ORDER_FAILURE,
  payload,
});

// GET ORDERS BY STATUS
export const getOrdersByStatusStart = () => ({
  type: Actions.GET_ORDERS_BY_STATUS_START,
});

export const getOrdersByStatusSuccess = (payload) => ({
  type: Actions.GET_ORDERS_BY_STATUS_SUCCESS,
  payload,
});

export const getOrdersByStatusFailure = (payload) => ({
  type: Actions.GET_ORDERS_BY_STATUS_FAILURE,
  payload,
});

// SEARCH ORDERS
export const searchOrdersStart = () => ({
  type: Actions.SEARCH_ORDERS_START,
});

export const searchOrdersSuccess = (payload) => ({
  type: Actions.SEARCH_ORDERS_SUCCESS,
  payload,
});

export const searchOrdersFailure = (payload) => ({
  type: Actions.SEARCH_ORDERS_FAILURE,
  payload,
});

// FILTER ORDERS
export const filterOrdersStart = () => ({
  type: Actions.FILTER_ORDERS_START,
});

export const filterOrdersSuccess = (payload) => ({
  type: Actions.FILTER_ORDERS_SUCCESS,
  payload,
});

export const filterOrdersFailure = (payload) => ({
  type: Actions.FILTER_ORDERS_FAILURE,
  payload,
});

// CHANGE ORDER STATUS
export const changeOrderStatusStart = () => ({
  type: Actions.CHANGE_ORDER_STATUS_START,
});

export const changeOrderStatusSuccess = (payload) => ({
  type: Actions.CHANGE_ORDER_STATUS_SUCCESS,
  payload,
});

export const changeOrderStatusFailure = (payload) => ({
  type: Actions.CHANGE_ORDER_STATUS_FAILURE,
  payload,
});

export const clearOrdersState = () => ({
  type: Actions.CLEAR_ORDERS_STATE,
});

export const createOrderStart = () => ({
  type: Actions.CREATE_ORDER_START,
});

export const createOrderSuccess = () => ({
  type: Actions.CREATE_ORDER_SUCCESS,
});

export const exportOrderDataStart = () => ({
  type: Actions.EXPORT_ORDERS_START,
});
export const exportOrderDataSuccess = () => ({
  type: Actions.EXPORT_ORDERS_SUCCESS,
});
export const exportOrderDataFailure = () => ({
  type: Actions.EXPORT_ORDERS_FAILURE,
});

export const createOrder = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  dispatch(createOrderStart());
  API.post("order/create", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(createOrderSuccess());
      responseHandler(response, "Success!");
    } else {
      responseHandler(response, "Failed!");
    }
  });
};

export const getAllOrders = (page, limit) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getAllOrdersStart());
  API.get(`order?page=${page}&limit=${limit}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getAllOrdersSuccess(data && data.data));
    } else {
      dispatch(getAllOrdersFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const getOrder = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getOrderStart());
  API.get(`/order/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getOrderSuccess(data && data.data));
    } else {
      dispatch(getOrderFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const getOrdersByStatus =
  (page, limit, status, payment_status) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

    dispatch(getOrdersByStatusStart());
    const url = payment_status
      ? `order?page=${page}&limit=${limit}&status=${status}&payment_status=${payment_status}`
      : `order?page=${page}&limit=${limit}&status=${status}`;
    API.get(url).then((response) => {
      const { data, ok } = response;

      if (ok) {
        dispatch(getOrdersByStatusSuccess(data && data.data));
      } else {
        dispatch(getOrdersByStatusFailure(data));
        responseHandler(response, "Failed!");
      }
    });
  };

export const searchOrders = (page, limit, type, search) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(searchOrdersStart());
  API.get(
    type === ""
      ? `order?page=${page}&limit=${limit}&search=${search}`
      : `order?page=${page}&limit=${limit}&status=${type}&search=${search}`
  ).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(searchOrdersSuccess(data && data.data));
    } else {
      dispatch(searchOrdersFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const exportOrdersData =
  (start_date, end_date, status, payment_status) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
    dispatch(exportOrderDataStart());
    const url =
      !status && start_date && end_date
        ? `order/export?from_date=${start_date}&to_date=${end_date}`
        : !(start_date && end_date) && status
        ? `order/export?status=${status}`
        : `order/export?from_date=${start_date}&to_date=${end_date}&status=${status}`;
    API.get(url).then((response) => {
      const { data, ok } = response;
      if (ok) {
        downloadCSV(data, "user-orders");
        dispatch(exportOrderDataSuccess());
      } else {
        dispatch(exportOrderDataFailure());
        responseHandler(response, "Failed");
      }
    });
  };

export const filterOrders =
  (page, limit, start_date, end_date, status) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
    const url =
      !status && start_date && end_date
        ? `order/filter?page=${page}&limit=${limit}&from_date=${start_date}&to_date=${end_date}`
        : !(start_date && end_date) && status
        ? `order/filter?page=${page}&limit=${limit}&status=${status}`
        : `order/filter?page=${page}&limit=${limit}&from_date=${start_date}&to_date=${end_date}&status=${status}`;
    dispatch(filterOrdersStart());
    API.get(url).then((response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(filterOrdersSuccess(data && data.data));
      } else {
        dispatch(filterOrdersFailure(data));
        responseHandler(response, "Failed!");
      }
    });
  };

export const changeOrderStatus = (id, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(changeOrderStatusStart());
  API.patch(`order/change-status/${id}`, payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(changeOrderStatusSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(changeOrderStatusFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};
