import PropTypes from "prop-types";
import React from "react";
import { AuthButton } from "../Button/Button";
import { LoaderSpin } from "../Loader/Loader";
import Backdrop from "../Backdrop/Backdrop";
import SimpleBar from "simplebar-react";

export const cancelModal = (type, state, setState) => {
  setState({ ...state, [type]: false });
};

export const viewModal = (type, state, setState) => {
  setState({ ...state, [type]: true });
};

export const ModalButton = (props) => {
  const {
    isLoading,
    text,
    onClick,
    testId,
    loadingText,
    disabled,
    type,
    width,
  } = props;
  return (
    <div className="flex top-spacer">
      <AuthButton
        onClick={onClick}
        testId={testId}
        background={"#0A74DC"}
        width={width ? width : "auto"}
        color={"#FFFFFF"}
        disabled={disabled}
        type={type}
      >
        {isLoading ? (
          <div className="loader">
            <div className="loader-text">{loadingText}</div>
            <LoaderSpin width="25px" height="25px" color="#fff" />
          </div>
        ) : (
          text
        )}
      </AuthButton>
    </div>
  );
};

const Modal = (props) => {
  const { title, width, children, no_overflow } = props;

  const closeModal = () => {
    props.closeModal();
  };

  return (
    <>
      {props.displayModal ? <Backdrop /> : null}
      <div className="modal">
        <div
          style={{
            width: `${width}`,
            right: props.displayModal ? "0" : "-100%",
          }}
          className="modal-content"
        >
          <div className="modal-content-header">
            <h1>{title}</h1>
            <span
              className="close-modal"
              onClick={closeModal}
              data-testid="close-modal"
            >
              &times;
            </span>
          </div>
          {no_overflow ? (
            <div className="modal-content-container">
              <div className="modal-content-container-form">{children}</div>
            </div>
          ) : (
            <SimpleBar
              timeout={500}
              style={{ zIndex: 5, maxHeight: "100%" }}
              scrollbarMaxSize={320}
            >
              <div className="modal-content-container">
                <div className="modal-content-container-form">{children}</div>
              </div>
            </SimpleBar>
          )}
        </div>
      </div>
    </>
  );
};

Modal.propTypes = {
  children: PropTypes.any,
  closeModal: PropTypes.func,
  displayModal: PropTypes.any,
  show: PropTypes.any,
  title: PropTypes.any,
  width: PropTypes.any,
};

export default Modal;
