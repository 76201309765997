import Actions from "../Types";
import Storage from "../../utils/services/storage";

const initialState = {
  isLoading: false,
  currentAdmin: null,
  data: {},

  isSendingEmail: false,
  sendEmailSuccess: false,
  email: {},

  isReseting: false,
  resetPassword: false,

  resetAmindPassword: false,

  error: null,
  isLoggingOut: false,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_CURRENT_ADMIN:
      return {
        ...state,
        isLoading: false,
        currentAdmin: Storage.get("admin-access-token"),
        data: action.payload,
        error: null,
        isAuthenticated: false,
      };
    case Actions.ADMIN_LOGIN_START:
      return {
        ...state,
        isLoading: true,
        currentAdmin: null,
        isAuthenticated: false,
        error: null,
      };
    case Actions.ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentAdmin: action.payload,
        isAuthenticated: true,
        error: null,
      };
    case Actions.ADMIN_LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        currentAdmin: null,
        isAuthenticated: false,
        error: action.payload,
      };
    case Actions.SEND_EMAIL_ADDRESS_START:
      return {
        ...state,
        isSendingEmail: true,
        sendEmailSuccess: false,
        error: null,
      };
    case Actions.SEND_EMAIL_ADDRESS_SUCCESS:
      return {
        ...state,
        isSendingEmail: false,
        sendEmailSuccess: true,
        email: action.payload,
        error: null,
      };
    case Actions.SEND_EMAIL_ADDRESS_FAILURE:
      return {
        ...state,
        isSendingEmail: false,
        sendEmailSuccess: false,
        error: action.payload,
      };
    case Actions.RESET_PASSWORD_START:
      return {
        ...state,
        isReseting: true,
        error: null,
        resetPassword: false,
      };
    case Actions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isReseting: false,
        error: null,
        resetPassword: true,
      };
    case Actions.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isReseting: false,
        error: action.payload,
        resetPassword: false,
      };
    case Actions.RESET_ADMIN_PASSWORD_START:
      return {
        ...state,
        isReseting: true,
        error: null,
        resetAdminPassword: false,
      };
    case Actions.RESET_ADMIN_PASSWORD_SUCCESS:
      return {
        ...state,
        isReseting: false,
        error: null,
        resetAdminPassword: true,
      };
    case Actions.RESET_ADMIN_PASSWORD_FAILURE:
      return {
        ...state,
        isReseting: false,
        error: action.payload,
        resetAdminPassword: false,
      };
    case Actions.USER_LOG_OUT_START:
      return {
        ...state,
        isLoggingOut: true,
      };
    case Actions.USER_LOG_OUT_SUCCESS:
      return {
        ...state,
        isLoggingOut: false,
        data: {},
        currentUser: null,
        error: null,
      };
    case Actions.USER_LOG_OUT_FAILURE:
      return {
        ...state,
        isLoggingOut: false,
      };
    default:
      return state;
  }
};

export default reduxState;
