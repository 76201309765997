import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  changeFacilityStatus,
  getFacilities,
} from "../../redux/Actions/FacilityActions";
import DeleteModal from "../Modal/DeleteModal";

const ActivateFacility = (props) => {
  const { activateFacility, isActivating, changeSuccess, id, getFacilities } =
    props;

  useEffect(
    () => {
      if (changeSuccess) {
        props.closeModal();
        getFacilities(1, 10);
      }
    }, // eslint-disable-next-line
    [changeSuccess]
  );

  const changeStatus = (id) => {
    activateFacility(id, "active");
  };

  return (
    <DeleteModal
      displayModal={props.displayModal}
      closeModal={props.closeModal}
      title={"Activate this facility?"}
      width={"463px"}
      text={"Activate"}
      onClick={() => changeStatus(id)}
      disabled={isActivating}
      isLoading={isActivating}
      activate={true}
      loadingText={"Activating..."}
      children={
        "Once you activate this facility, this facility would be active again. Activate facility?"
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isActivating: state.facility.isChangingStatus,
  changeSuccess: state.facility.changeSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  activateFacility: (id, status) => dispatch(changeFacilityStatus(id, status)),
  getFacilities: (page, limit) => dispatch(getFacilities(page, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivateFacility);
