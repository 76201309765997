import React from "react";
import Carousel from "../Input/Carousel";
import { useMediaQuery } from "react-responsive";
import Icon from "../../assets/account_icon.svg";
import moment from "moment";
import Empty from "../../assets/empty.svg";

const Appointment = (props) => {
  const { data } = props;

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <div className="info-flex">
      {data && data.length > 0 ? (
        <Carousel show={isTabletOrMobile ? 1 : 2}>
          {data &&
            data.map((item, index) => {
              return (
                <div className="appointment-grid-section" key={index}>
                  <div className="appointment-grid-section-top">
                    <div className="header">
                      <div>
                        <img
                          src={
                            item.user && item.user.image_url
                              ? item.user.image_url
                              : Icon
                          }
                          alt="individual"
                        />
                      </div>
                      <h2>{item?.user_full_name || "Nil"}</h2>
                    </div>
                    <div className="body">
                      <div className="body-item">
                        <p>With</p>
                        <h3>{item?.specialist || "Nil"}</h3>
                      </div>
                      <div className="body-item">
                        <p>Service</p>
                        <h3>{item.service}</h3>
                      </div>
                      <div className="body-item">
                        <p>Date</p>
                        <h3>{moment(item.scheduled_date).format("ll")}</h3>
                      </div>
                      <div className="body-item">
                        <p>Time</p>
                        <h3>{moment(item.scheduled_time).format("LT")}</h3>
                      </div>
                    </div>
                  </div>
                  <div className="appointment-grid-section-bottom">
                    <div className="appointment-grid-section-bottom-item">
                      {item.status === "available" ? (
                        <span style={{ color: "#00DCB4" }}>Done</span>
                      ) : item.status === "finished" ? (
                        <span style={{ color: "#F93D55" }}>Declined</span>
                      ) : (
                        <span style={{ color: "#0A74DC" }}>Pending</span>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </Carousel>
      ) : (
        <div className="table-empty2">
          <div className="table-empty-container">
            <div className="table-empty-container-image">
              <img src={Empty} alt="empty-table" />
            </div>
            <div>
              <h2>No recent appointments</h2>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Appointment;
