import React from "react";
import { Route, Switch } from "react-router-dom";
import Overview from "../pages/Overview/Overview";
import Login from "../pages/Login/Login";
import Products from "../pages/Products/Products";
import PrivateRoute from "./PrivateRoute";
import Appointment from "../pages/Appointment/Appointment";
import Chat from "../pages/ChatManagement/Chat";
import Orders from "../pages/OrderManagement/Orders";
import Facility from "../pages/FacilityManagement/Facility";
import Users from "../pages/UserManagement/Users";
import Administrators from "../pages/Administrators/Administrators";
import PaymentPlans from "../pages/PaymentPlans/PaymentPlans";
import AuditTrail from "../pages/AuditTrail/AuditTrail";
import Coupon from "../pages/Coupon/Coupon";
import ReferralManager from "../pages/ReferralManager/ReferralManager";
import OnboardingWrapper from "../components/Wrappers/OnboardingWrapper";
import SingleProduct from "../pages/Products/SingleProduct";
import ForgotPassword from "../pages/AuthScreens/ForgotPassword";
import ResetPassword from "../pages/AuthScreens/ResetPassword";
import CreatePost from "../components/BlogManagement/CreatePost";
import EditPost from "../components/BlogManagement/EditPost";
import SubscribedUsers from "../pages/PaymentPlans/SubscribedUsers";
import NotFound from "../pages/NotFound/NotFound";
import SingleClient from "../pages/UserManagement/Clients/SingleClient";
import SingleWhispaDoctor from "../pages/UserManagement/WhispaDoctors/SingleWhispaDoctor";
import SingleConsultant from "../pages/UserManagement/Consultants/SingleConsultant";
import SingleHfcp from "../pages/UserManagement/HFCP/SingleHfcp";
import OrderDetails from "../pages/OrderManagement/OrderDetails";
import AppointmentManager from "../pages/AppointmentManager/AppointmentManager";
import ClientChats from "../components/UserManagement/Clients/ClientChats";
import ConsultantChats from "../components/UserManagement/Consultants/ConsultantChats";
import WhispaDoctorChats from "../components/UserManagement/WhispaDoctors/WhispaDoctorChats";
import CheckInbox from "../pages/AuthScreens/CheckInbox";
import Settings from "../pages/Settings/Settings";
import Profile from "../pages/Profile/Profile";
import BlogContainer from "../pages/BlogManagement/BlogContainer";
import AssessmentQuestions from "../pages/AppointmentManager/AssessmentQuestion";
import AddCategory from "../components/AppointmentManager/AppointmentCategory/AddCategory";
import EditCategory from "../components/AppointmentManager/AppointmentCategory/EditCategory";
import Terms from "../pages/Login/Terms";
import PrivacyPolicy from "../pages/Login/PrivacyPolicy";
import ReferralCodeUsers from "../pages/ReferralCodes/ReferralCodeUsers";
import DeliveryFee from "../pages/DeliveryFee/Delivery";
import Subscriptions from "../pages/Subscriptions/Subscriptions";

import EditClient from "../pages/UserManagement/Clients/EditClient";
import EditConsultant from "../pages/UserManagement/Consultants/EditConsultant";
import EditHfcp from "../pages/UserManagement/HFCP/EditHfcp";
import EditDoctor from "../pages/UserManagement/WhispaDoctors/EditDoctor";
import Prescriptions from "../pages/Prescriptions/Prescriptions";
import PrescriptionDetails from "../pages/Prescriptions/PrescriptionDetails";
import CouponDetails from "../pages/Coupon/CouponDetails";
import Enterprise from "../pages/Enterprise/Enterprise";
import CreateEnterprise from "../pages/Enterprise/CreateEnterprise";
import EditEnterprise from "../pages/Enterprise/EditEnterprise";
import AppVersions from "../pages/AppVersions/AppVersions";
import ReferralCodeManager from "../pages/ReferralCodes/ReferralCodeManager";
import test from "../components/test";
import Reports from "../pages/Reports/Reports";
import TestUpload from "../components/TestUpload/TestUpload";

const Routes = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/check-inbox" component={CheckInbox} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route
          exact
          path="/complete-profile"
          component={OnboardingWrapper}
          onBoarding={true}
        />
        <Route
          exact
          path="/privacy-policy"
          component={PrivacyPolicy}
          onBoarding={true}
        />
        <Route
          exact
          path="/terms-of-service"
          component={Terms}
          onBoarding={true}
        />
        <PrivateRoute exact path="/overview" component={Overview} />
        <PrivateRoute exact path="/appointment" component={Appointment} />
        <PrivateRoute
          exact
          path="/chat-management"
          component={Chat}
          className="container-body2"
        />
        <PrivateRoute exact path="/test" component={test} />
        <PrivateRoute exact path="/reports" component={Reports}  />
        <PrivateRoute exact path="/upload" component={TestUpload}  />
        <PrivateRoute
          exact
          path="/appointment-manager"
          component={AppointmentManager}
        />
        <PrivateRoute
          exact
          path="/appointment-manager/create-category"
          component={AddCategory}
        />
        <PrivateRoute
          exact
          path="/appointment-manager/edit-category/:id"
          component={EditCategory}
        />
        <PrivateRoute
          exact
          path="/appointment-manager/question/:id/:type"
          component={AssessmentQuestions}
        />
        <PrivateRoute exact path="/products" component={Products} />
        <PrivateRoute exact path="/product/:id" component={SingleProduct} />
        <PrivateRoute exact path="/order-management" component={Orders} />
        <PrivateRoute
          exact
          path="/order-management/:id"
          component={OrderDetails}
        />
        <PrivateRoute exact path="/blog-management" component={BlogContainer} />
        <PrivateRoute
          exact
          path="/blog-management/create"
          component={CreatePost}
        />
        <PrivateRoute
          exact
          path="/blog-management/edit/:id"
          component={EditPost}
        />
        <PrivateRoute exact path="/facility" component={Facility} />
        <PrivateRoute exact path="/users" component={Users} />
        <PrivateRoute
          exact
          path="/users/clients/chats/:id"
          component={ClientChats}
          className="container-body2"
        />
        <PrivateRoute
          exact
          path="/users/clients/:id"
          component={SingleClient}
        />
        <PrivateRoute
          exact
          path="/users/whispa-doctor/chats/:id"
          component={WhispaDoctorChats}
          className="container-body2"
        />
        <PrivateRoute
          exact
          path="/users/whispa-doctors/:id"
          component={SingleWhispaDoctor}
        />
        <PrivateRoute
          exact
          path="/users/consultant/chats/:id"
          component={ConsultantChats}
          className="container-body2"
        />
        <PrivateRoute
          exact
          path="/users/consultants/:id"
          component={SingleConsultant}
        />
        <PrivateRoute exact path="/users/hfcp/:id" component={SingleHfcp} />
        <PrivateRoute exact path="/administrators" component={Administrators} />
        <PrivateRoute exact path="/payment-plans" component={PaymentPlans} />
        <PrivateRoute
          exact
          path="/payment-plans/users"
          component={SubscribedUsers}
        />
        <PrivateRoute exact path="/audit-trail" component={AuditTrail} />
        <PrivateRoute exact path="/promotions" component={Coupon} />
        <PrivateRoute exact path="/promotions/:id" component={CouponDetails} />
        <PrivateRoute exact path="/referrals" component={ReferralManager} />
        <PrivateRoute exact path="/subscriptions" component={Subscriptions} />
        <PrivateRoute exact path="/prescriptions" component={Prescriptions} />
        <PrivateRoute
          exact
          path="/prescriptions/:id"
          component={PrescriptionDetails}
        />
        <PrivateRoute exact path="/app-versions" component={AppVersions} />
        <PrivateRoute exact path="/profile" component={Profile} />
        <PrivateRoute exact path="/settings" component={Settings} />
        <PrivateRoute
          exact
          path="/referral-manager"
          component={ReferralCodeManager}
        />
        <PrivateRoute
          exact
          path="/referrals/:id"
          component={ReferralCodeUsers}
        />
        <PrivateRoute exact path="/enterprise" component={Enterprise} />
        <PrivateRoute
          exact
          path="/enterprise/create-enterprise"
          component={CreateEnterprise}
        />
        <PrivateRoute
          exact
          path="/enterprise/view/edit/:id"
          component={EditEnterprise}
        />
        {/* Newly added  */}
        <PrivateRoute
          exact
          path="/users/clients/edit/:id"
          component={EditClient}
        />
        <PrivateRoute
          exact
          path="/users/whispa-doctors/edit/:id"
          component={EditDoctor}
        />
        <PrivateRoute
          exact
          path="/users/consultants/edit/:id"
          component={EditConsultant}
        />
        <PrivateRoute exact path="/users/hfcps/edit/:id" component={EditHfcp} />

        <PrivateRoute
          exact
          path="/delivery-fee-management"
          component={DeliveryFee}
        />
        <Route exact path="*" component={NotFound} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
