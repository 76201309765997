import responseHandler from "../../utils/notifications/responseHandler";
import Instance from "../../utils/services/api";
import Storage from "../../utils/services/storage";
import Actions from "../Types";

export const getAppVersionsStart = () => ({
  type: Actions.GET_APP_VERSIONS_START,
});

export const getAppVersionsSuccess = (payload) => ({
  type: Actions.GET_APP_VERSIONS_SUCCESS,
  payload,
});

export const updateAppVersionsStart = () => ({
  type: Actions.UPDATE_APP_VERSIONS_START,
});

export const updateAppVersionsSuccess = () => ({
  type: Actions.UPDATE_APP_VERSIONS_SUCCESS,
});

export const updateAppVersionsFailure = () => ({
  type: Actions.UPDATE_APP_VERSIONS_FAILURE,
});

export const getAppVersions = () => async (dispatch) => {
  const res = [];
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  dispatch(getAppVersionsStart());
  API.get("app-versions").then((response) => {
    const { data, ok } = response;
    if (ok) {
      res.push(data && data.data);
      dispatch(getAppVersionsSuccess(res))
    } else {
      responseHandler(response, "Failed");
    }
  });
};

export const updateAppVersions = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  dispatch(updateAppVersionsStart());
  API.post("app-versions", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(updateAppVersionsSuccess());
      responseHandler(response, "Success!");
    } else {
      dispatch(updateAppVersionsFailure());
      responseHandler(response, "Failed");
    }
  });
};
