import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Line } from "react-chartjs-2";

const DistributionLines = (props) => {
    const {graph_name, data, label } = props
    const [date, setDate] = useState(moment().format("LLL"));
    const dateRef = useRef(null);
    const [labelProps, setLabelProps] = useState([]);
    useEffect(() => {
        const labels = data?.map((key) => key[label]);
        setLabelProps(labels);
      }, []);
    useEffect(
        () => {
          dateRef.current = setInterval(
            () => setDate(moment().format("LLL")),
            60_000
          );
          return () => clearInterval(dateRef.current);
        }, // eslint-disable-next-line
        []
      );
      const dataState = {
        labels: labelProps,
        datasets: [
          {
            label: "Residential Demographic",
            data: data?.map((key) => key.y),
            fill: true,
            borderColor: "#00DCB4",
            backgroundColor: "transparent",
            borderColor: "#F93D55",
            lineTension: 0.5,
          },
        ],
      };
  return (
    <div className="overview-graph">
    <div className="overview-graph-header">
      <div className="overview-graph-header-left">
        <h4>{graph_name}</h4>
        <p>{date}</p>
      </div>
    </div>

    <div className="overview-graph-section">
      <Line
        data={dataState}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              grid: {
                borderDash: [10, 10],
              },
              beginAtZero: true,
            },
          },
        }}
      />
    </div>
  </div>
  )
}

export default DistributionLines