import React, { useState } from "react";
import { PropTypes } from "prop-types";
import Show from "../../assets/show.svg";
import Hide from "../../assets/hide.svg";

const Input = (props) => {
    const {
        type,
        placeholder,
        field,
        form,
        onChange,
        onBlur,
        name,
        onFocus,
        label,
        onKeyDown,
        onPaste,
        value,
        readOnly,
        className,
        input2,
        emptyValue,
        id,
        pattern,
        hideLabel = false,
        testId,
        onWheel,
        disabled
    } = props;

    const [showPassword, setShowPassword] = useState(false);

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleType = () => {
        if (type === "password" && !showPassword) {
            return "password";
        } else if (type !== "password" && !showPassword) {
            return type;
        }
    };

    const errorCheck =
        form && form.touched[field.name] && form && form.errors[field.name];

    const errorCheck2 = errorCheck && field.value !== "";

    const resetValue = () => {
        emptyValue(field.name, "");
    };

    return (
        <div className="input-wrapper">
            <div className="input-container">
                {input2 && <label className="label-input2">{label}</label>}
                <input
                    type={handleType()}
                    onFocus={(field && field.onFocus) || onFocus}
                    onWheel={(field && field.onWheel) || onWheel}
                    onChange={(field && field.onChange) || onChange}
                    onBlur={(field && field.onBlur) || onBlur}
                    value={(field && field.value) || value || ""}
                    name={(field && field.name) || name}
                    id={(field && field.name) || id}
                    placeholder={placeholder}
                    onKeyDown={onKeyDown}
                    onPaste={onPaste}
                    pattern={pattern}
                    className={
                        className
                            ? className
                            : errorCheck
                            ? "error-border"
                            : readOnly
                            ? "input readonly-input"
                            : "input"
                    }
                    readOnly={readOnly}
                    data-testid={testId}
                    disabled={disabled}
                />
                {errorCheck2 && type !== "password" && (
                    <div
                        className={input2 ? "cancel-error2" : "cancel-error"}
                        onClick={() => resetValue()}
                    >
                        X
                    </div>
                )}
                {type === "password" && (
                    <div className="showhide-btn" onClick={handleShowPassword}>
                        <img
                            src={showPassword ? Hide : Show}
                            alt="view password"
                        />
                    </div>
                )}
                {!input2 && !hideLabel && (
                    <span className="label">{label}</span>
                )}
            </div>

            {errorCheck && (
                <small className="errors">{form.errors[field.name]}</small>
            )}
        </div>
    );
};

Input.propTypes = {
    className: PropTypes.any,
    field: PropTypes.object,
    form: PropTypes.object,
    label: PropTypes.any,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.any,
    onPaste: PropTypes.any,
    password: PropTypes.any,
    placeholder: PropTypes.string,
    readOnly: PropTypes.any,
    type: PropTypes.string,
    value: PropTypes.string,
};

export default Input;
