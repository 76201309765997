import React, { useState, useEffect } from "react";
import Modal, { ModalButton } from "../../Modal/Modal";
import SelectInput from "../../Input/Select";
import { useHistory } from "react-router";
import { getAppointmentCategories } from "../../../redux/Actions/AppointmentActions";
import { connect } from "react-redux";

const AddAssessment = (props) => {
  const {
    displayModal,
    closeModal,
    categories,
    isGetting,
    getSuccess,
    fetchAppointmentCategories,
  } = props;
  const [state, setState] = useState({});
  const [options, setOptions] = useState([{ value: "1", label: "Loading..." }]);

  const { push } = useHistory();

  useEffect(
    () => {
      fetchAppointmentCategories();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (!isGetting && getSuccess) {
        setOptions(() =>
          categories.map((category) => ({
            label: category.name,
            value: category.id,
          }))
        );
      }
    },
    // eslint-disable-next-line
    [isGetting]
  );

  const updateSelection = (data, key) => {
    setState((state) => ({ ...state, [key]: data }));
  };

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Add Assessment"}
      width={"568px"}
    >
      <>
        <div>
          <SelectInput
            defaultOption={state.parentCategory}
            label="Category"
            options={options}
            onChange={(data) => updateSelection(data, "parentCategory")}
          />
        </div>
        <ModalButton
          disabled={!state.parentCategory}
          onClick={() =>
            push({
              pathname: `/appointment-manager/question/${state.parentCategory.value}/create`,
            })
          }
          width="167px"
          isLoading={isGetting}
          text={"Next"}
          type={"submit"}
          testId={"button"}
        />
      </>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  categories: state.appointment.categories,
  isGetting: state.appointment.isGettingCategories,
  getSuccess: state.appointment.getCategorySuccess,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAppointmentCategories: () => dispatch(getAppointmentCategories()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAssessment);
