import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import Icon from "../../../assets/account_icon.svg";
import BackBtn from "../../../components/Input/BackBtn";
import { LoaderSpin } from "../../../components/Loader/Loader";
import { ClientRight } from "../../../components/UserManagement/Clients/ClientRight";
import GeneralInfo from "../../../components/UserManagement/GeneralInfo";
import UserHeader from "../../../components/UserManagement/UserHeader";
import {
  deleteUser,
  getUsersProfile,
} from "../../../redux/Actions/UsersActions";


const SingleClient = (props) => {
  const {
    getUsersProfile,
    isGettingProfile,
    usersProfileData,
    deleteUser,
    isDeleting,
    deleteSuccess,
  } = props;

  const location = useLocation();

  const id = location && location.state && location.state.id;
  const clientName = usersProfileData && usersProfileData.full_name;
  const clientStatus = usersProfileData && usersProfileData.status;
  const clientAppointments = usersProfileData && usersProfileData.appointments;
  const clientSession = usersProfileData?.session;
  const clientImage = usersProfileData && usersProfileData.image_url;

  useEffect(() => {
    getUsersProfile("customer", id);
  }, [getUsersProfile, id]);

  return (
    <div className="single-users">
      {isGettingProfile ? (
        <div className="loader-table2">
          <LoaderSpin width="40px" height="40px" />
        </div>
      ) : (
        <div>
          <BackBtn page="/users" /> 
          <UserHeader
            clientPicture={clientImage === null ? Icon : clientImage}
            clientName={clientName}
            status={clientStatus}
            chatPage={`/users/clients/chats/${id}`}
            deleteUser={deleteUser}
            isDeleting={isDeleting}
            deleteSuccess={deleteSuccess}
            ClientId={id}
          />
          <GeneralInfo
            data={usersProfileData}
            children={
              <ClientRight
                appointments={clientAppointments}
                clientSession={clientSession}
              />
            }
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGettingProfile: state.users.isGettingProfile,
  getSuccessProfile: state.users.getSuccessProfile,
  usersProfileData: state.users.usersProfileData,
  isDeleting: state.users.isDeleting,
  deleteSuccess: state.users.deleteSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  deleteUser: (id) => dispatch(deleteUser(id)),
  getUsersProfile: (type, id) => dispatch(getUsersProfile(type, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleClient);
