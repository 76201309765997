import React from "react";
import AddAdminForm from "../../components/Onboarding/AddAdminForm";
import { Headers } from "../../components/Wrappers/AuthWrapper";

const AddAdmin = (props) => {
  const { setstate, state } = props;
  return (
    <div>
      <Headers
        title1={"Enter the Admin details"}
        title2={"Kindly enter the ned Administartor’s details"}
      />
      <AddAdminForm setstate={setstate} state={state} />
    </div>
  );
};

export default AddAdmin;
