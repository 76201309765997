import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";

//GET AUDIT TRAIL
export const getAuditTrailStart = () => ({
  type: Actions.GET_AUDIT_TRAIL_START,
});

export const getAuditTrailSuccess = (payload) => ({
  type: Actions.GET_AUDIT_TRAIL_SUCCESS,
  payload,
});

export const getAuditTrailFailure = (payload) => ({
  type: Actions.GET_AUDIT_TRAIL_FAILURE,
  payload,
});

//SEARCH AUDIT TRAIL
export const searchAuditTrailStart = () => ({
  type: Actions.SEARCH_AUDIT_TRAIL_START,
});

export const searchAuditTrailSuccess = (payload) => ({
  type: Actions.SEARCH_AUDIT_TRAIL_SUCCESS,
  payload,
});

export const searchAuditTrailFailure = (payload) => ({
  type: Actions.SEARCH_AUDIT_TRAIL_FAILURE,
  payload,
});

//FILTER AUDIT TRAIL
export const filterAuditTrailStart = () => ({
  type: Actions.FILTER_AUDIT_TRAIL_START,
});

export const filterAuditTrailSuccess = (payload) => ({
  type: Actions.FILTER_AUDIT_TRAIL_SUCCESS,
  payload,
});

export const filterAuditTrailFailure = (payload) => ({
  type: Actions.FILTER_AUDIT_TRAIL_FAILURE,
  payload,
});

export const getAuditTrail = (page, limit) => (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getAuditTrailStart());
  API.get(`audit-trail?page=${page}&limit=${limit}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getAuditTrailSuccess(data && data.data));
    } else {
      dispatch(getAuditTrailFailure(data));
      responseHandler(response);
    }
  });
};

export const searchAuditTrail = (page, limit, search) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(searchAuditTrailStart());
  API.get(`audit-trail?page=${page}&limit=${limit}&search=${search}`).then(
    (response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(searchAuditTrailSuccess(data && data.data));
      } else {
        dispatch(searchAuditTrailFailure(data));
        responseHandler(response, "Failed!");
      }
    }
  );
};

export const filterAuditTrail =
  (page, limit, created_at, status) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

    dispatch(filterAuditTrailStart());
    API.get(
      `audit-trail?page=${page}&limit=${limit}&created_at=${created_at}&status=${status}`
    ).then((response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(filterAuditTrailSuccess(data && data.data));
      } else {
        dispatch(filterAuditTrailFailure(data));
        responseHandler(response, "Failed!");
      }
    });
  };
