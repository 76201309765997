import React from "react";
import { Redirect } from "react-router";
import AuthWrapper, { Headers } from "../../components/Wrappers/AuthWrapper";
import {
  getUserData,
  handleRouting,
} from "../../utils/functions/GetStorageItems";
import LoginForm from "./LoginForm";

const Login = () => {
  console.log("updated")
  const { isFirstTimeLogin, token } = getUserData();
  const user = !isFirstTimeLogin && token; 

  if (user) {
    const url = handleRouting();  
    console.log(url)

    return <Redirect to={url} />; 
  }
  return (
    <AuthWrapper className="container">
      <Headers
        title1={"Login"}
        title2={"Kindly enter your details to log in"}
      />
      <LoginForm />
    </AuthWrapper>
  );
};

export default Login;
