import React from "react";
import { PageButton } from "../Button/Button";
import SortData from "../Sorting/SortData";

const ProductHeader = (props) => {
  const {
    icon,
    productName,
    searchItems,
    onClick,
    component1,
    component2,
    state,
    setState,
    sortSuccess,
    refreshFunction,
  } = props;

  const options = [{ name: "Product Summary" }, { name: "Product History" }];

  const setPage = (index) => {
    setState({
      ...state,
      index: index,
      active: true,
    });
  };

  const enableEdit = () => {
    setState({
      ...state,
      disableEdit: (state.disableEdit = !state.disableEdit),
    });
  };

  return (
    <div className="product">
      <div className="product-header">
        <div className="product-header-top">
          <div className="product-header-top-image">
            <img src={icon} alt="product" />
          </div>

          <div className="product-header-top-text">
            <h2>{productName}</h2>
            <p>View product descriptions and history</p>
          </div>
        </div>

        <div className="product-header-bottom">
          <div className="product-header-bottom-wrapper">
            {options.map((option, index) => (
              <div
                className={
                  index === state.index && state.active === true
                    ? "wrapper-button2"
                    : "wrapper-button"
                }
                data-testid={`setPage${index}`}
                onClick={() => setPage(index)}
                key={index}
              >
                {option.name}
              </div>
            ))}
          </div>
          {state.index === 0 ? (
            <PageButton
              width="148px"
              children="Edit"
              background="rgba(10, 116, 220, 0.08)"
              onClick={enableEdit}
              testId="button"
            />
          ) : (
            <SortData
              placeholder={"Search by actions, status "}
              width={"347px"}
              searchItems={searchItems}
              setState={setState}
              state={state}
              onClick={onClick}
              filter={true}
              sortSuccess={sortSuccess}
              refreshFunction={refreshFunction}
            />
          )}
        </div>
      </div>

      {state.index === 0 ? component1 : component2}
    </div>
  );
};

export default ProductHeader;
