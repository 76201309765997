import React from "react";
import { connect } from "react-redux";
import ProfilePic from "../../assets/account_icon.svg";
import { uploadImage } from "../../redux/Actions/UploadActions";
import Storage from "../../utils/services/storage";
import { LoaderSpin } from "../Loader/Loader";
import { assignUrlFunc } from "../Products/UploadImage";

const ProfileImage = (props) => {
  const { state, id, isUploading, setState, uploadFile, disabled } = props;

  const assignUrl = (uploadedFile, payload) => {
    setState({
      ...state,
      loaded: true,
      image: uploadedFile,
    });
  };

  const upload = (id) => {
    Storage.set("id", id);
    assignUrlFunc(id, assignUrl, uploadFile, "single");
  };

  const removeFile = () => {
    setState({
      ...state,
      image: ProfilePic,
      loaded: false,
    });
  };

  const setId = Storage.get("id") && Number(Storage.get("id"));
  const loading = setId === id && isUploading;
  const uploaded = state.loaded;

  return (
    <div className="user-profile-bottom-left-image">
      {loading ? (
        <LoaderSpin width="30px" height="30px" />
      ) : uploaded ? (
        <div className="user-profile-bottom-left-image-div">
          <img src={state.image} alt="profile" />
        </div>
      ) : (
        <div className="user-profile-bottom-left-image-div">
          <img src={ProfilePic} alt="profile" />
        </div>
      )}
      {!disabled && (
        <>
          {" "}
          <span>{uploaded ? "Change Image" : "Upload new image"}</span>
          <input
            type="file"
            className="custom-file-input"
            data-testid="showWidget"
            accept=".jpg, .png"
            id={`custom-file-input${id}`}
            onChange={() => upload(id)}
          />
          <button
            disabled={state.image === "" || state.image === ProfilePic}
            onClick={removeFile}
          >
            x Remove
          </button>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isUploading: state.upload.isUploading,
  uploadSuccess: state.upload.uploadSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  uploadFile: (payload) => dispatch(uploadImage(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileImage);
