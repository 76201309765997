import React, { useEffect, useState } from "react";
import HeaderWrapper from "../Wrappers/HeaderWrapper";
import BackBtn from "../Input/BackBtn";
import { useHistory } from "react-router";
import {
  editBlogPost,
  getBlogCategories,
  getSingleBlogPost,
  singleBlogPostReset,
} from "../../redux/Actions/BlogActions";
import { uploadImage } from "../../redux/Actions/UploadActions";
import { connect } from "react-redux";
import { visibleList } from "../../utils/functions/BlogPost";
import { LoaderSpin } from "../Loader/Loader";
import {
  assignBlogCategoryOptions,
  assignBlogCategoryPayload,
} from "../../utils/functions/UpdateValues";
import truncateName from "../../utils/functions/ShortenStr";
import BlogInputs from "./BlogInputs";
import draftToHtml from "draftjs-to-html";

const EditPost = (props) => {
  const {
    isGetting,
    getSuccess,
    blogPost,
    getBlogPost,
    isEditing,
    editSuccess,
    editBlogPost,
    isUploading,
    uploadFile,
    uploadSuccess,
    getCategory,
    categories,
    resetPosts,
    getCategorySuccess,
  } = props;
  const history = useHistory();

  const data = props && props.location.state;

  const id = data && data.id;

  const [state, setState] = React.useState({
    image: "",
    checked1: false,
    checked2: false,
    checked3: false,
    checked4: false,
    category: [],
    contentState: "",
  });

  useEffect(
    () => {
      getBlogPost(id);
      getCategory();
      return () => resetPosts();
    },
    // eslint-disable-next-line
    [getBlogPost]
  );

  const singlePost = blogPost && blogPost[0];

  useEffect(
    () => {
      if (getSuccess) {
        setState({
          ...state,
          image: singlePost && singlePost.image_url,
          loaded: true,
          contentState: singlePost && JSON.parse(singlePost.admin_content),
          category:
            singlePost &&
            singlePost.category_name &&
            assignBlogCategoryOptions(singlePost.category_name),
          checked1: singlePost && singlePost.vissible_to_all,
          checked2: singlePost && singlePost.vissible_to_patient_only,
          checked3: singlePost && singlePost.vissible_to_doctors_only,
          checked4: singlePost && singlePost.vissible_to_consultants_only,
        });
      }
    },
    // eslint-disable-next-line
    [getSuccess]
  );

  useEffect(
    () => {
      if (getSuccess && getCategorySuccess) {
        const updatedCategory =
          categories && assignBlogCategoryOptions(categories);
        setOptions(updatedCategory);
      }
    },
    // eslint-disable-next-line
    [getSuccess, getCategorySuccess]
  );

  const initialValues = {
    title: singlePost && singlePost.title,
    caption: singlePost && singlePost.caption,
    author: singlePost && singlePost.author_name,
  };

  const [options, setOptions] = useState([]);

  const toggleList = visibleList(state, setState);

  const onContentStateChange = (contentState) => {
    setState((state) => ({ ...state, contentState }));
  };

  const removeAll = (type) => {
    setState({
      ...state,
      [type]: [],
    });
  };

  const noCheckedState =
    !state.checked1 && !state.checked2 && !state.checked3 && !state.checked4;

  const submitValues = (values) => {
    const payload = {
      image_url: state.image,
      vissible_to_all: state.checked1,
      vissible_to_patient_only: state.checked2,
      vissible_to_doctors_only: state.checked3,
      vissible_to_consultants_only: state.checked4,
      title: values.title,
      admin_content: JSON.stringify(state.contentState),
      content: state.contentState && draftToHtml(state.contentState),
      caption: values.caption,
      author_name: values.author,
      blog_categories:
        state.category && assignBlogCategoryPayload(state.category),
    };
    editBlogPost(id, payload);
  };

  useEffect(
    () => {
      if (editSuccess) {
        history.push("/blog-management");
      }
    },
    // eslint-disable-next-line
    [editSuccess]
  );

  return (
    <>
      {isGetting ? (
        <div className="loader-table2">
          <LoaderSpin width="40px" height="40px" />
        </div>
      ) : getSuccess ? (
        <div>
          <BackBtn page="/blog-management" />

          <HeaderWrapper
            title={"Edit Blog Post"}
            subtitle={"Edit already created blog post for users to read"}
          />

          {state.contentState && (
            <BlogInputs
              state={state}
              setState={setState}
              toggleList={toggleList}
              removeAll={removeAll}
              btnText="Save Changes"
              currentDate={true}
              isLoading={isEditing}
              disabledFunc={(isValid, dirty, values) =>
                isEditing ||
                values.caption === "" ||
                values.author === "" ||
                values.title === "" ||
                state.contentState === "" ||
                state.image === "" ||
                noCheckedState ||
                (state.category && state.category.length === 0)
              }
              contentState={state.contentState}
              onContentStateChange={onContentStateChange}
              isUploading={isUploading}
              uploadSuccess={uploadSuccess}
              uploadFile={uploadFile}
              name={state.image && truncateName(state.image, 50)}
              type={"edit"}
              options={options}
              initialValues={initialValues}
              submitValues={submitValues}
            />
          )}
        </div>
      ) : (
        <div className="auth-header">
          <BackBtn page="/blog-management" />
          <h1>Error fetching single blog post</h1>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isGetting: state.blog.isGettingSinglePost,
  getSuccess: state.blog.getSingleSuccess,
  blogPost: state.blog.singlePost,
  isEditing: state.blog.isEditingBlogPost,
  editSuccess: state.blog.editSuccess,
  isUploading: state.upload.isUploading,
  uploadSuccess: state.upload.uploadSuccess,
  isGettingCategory: state.blogcategory.isGettingCategories,
  getCategorySuccess: state.blogcategory.getCategorySuccess,
  categories: state.blogcategory.allCategories,
});

const mapDispatchToProps = (dispatch) => ({
  getBlogPost: (id) => dispatch(getSingleBlogPost(id)),
  editBlogPost: (id, payload) => dispatch(editBlogPost(id, payload)),
  uploadFile: (payload) => dispatch(uploadImage(payload)),
  getCategory: () => dispatch(getBlogCategories()),
  resetPosts: () => dispatch(singleBlogPostReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPost);
