import React, { useEffect } from "react";
import { connect } from "react-redux";
import { deleteCoupon } from "../../../redux/Actions/CouponsActions";
import DeleteModal from "../../Modal/DeleteModal";

const DeleteCoupon = (props) => {
  const { deleteCoupon, isDeleting, deleteSuccess, id } = props;

  useEffect(
    () => {
      if (deleteSuccess) {
        props.closeModal();
      }
    }, // eslint-disable-next-line
    [deleteSuccess]
  );

  const handleDelete = () => {
    deleteCoupon(id);
  };
  return (
    <DeleteModal
      displayModal={props.displayModal}
      closeModal={props.closeModal}
      title={"Delete this discount code?"}
      width={"463px"}
      text={"Delete"}
      onClick={handleDelete}
      disabled={isDeleting}
      isLoading={isDeleting}
      loadingText={"Deleting..."}
      children={
        "Once you delete this discount code it won’t be possible to undo this action. Delete discount code?"
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isDeleting: state.coupons.isDeleting,
  deleteSuccess: state.coupons.deleteSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  deleteCoupon: (id) => dispatch(deleteCoupon(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteCoupon);
