import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";

// GET ALL PRODUCTS
export const getAllProductsStart = () => ({
  type: Actions.GET_ALL_PRODUCTS_START,
});

export const getAllProductsSuccess = (payload) => ({
  type: Actions.GET_ALL_PRODUCTS_SUCCESS,
  payload,
});

export const getAllProductsFailure = (payload) => ({
  type: Actions.GET_ALL_PRODUCTS_FAILURE,
  payload,
});

// GET SINGLE PRODUCT
export const getSingleProductStart = () => ({
  type: Actions.GET_SINGLE_PRODUCT_START,
});

export const getSingleProductSuccess = (payload) => ({
  type: Actions.GET_SINGLE_PRODUCT_SUCCESS,
  payload,
});

export const getSingleProductFailure = (payload) => ({
  type: Actions.GET_SINGLE_PRODUCT_FAILURE,
  payload,
});

// CREATE NEW PRODUCT
export const createProductStart = () => ({
  type: Actions.CREATE_PRODUCT_START,
});

export const createProductSuccess = (payload) => ({
  type: Actions.CREATE_PRODUCT_SUCCESS,
  payload,
});

export const createProductFailure = (payload) => ({
  type: Actions.CREATE_PRODUCT_FAILURE,
  payload,
});

// EDIT PRODUCT
export const editProductStart = () => ({
  type: Actions.EDIT_PRODUCT_START,
});

export const editProductSuccess = (payload) => ({
  type: Actions.EDIT_PRODUCT_SUCCESS,
  payload,
});

export const editProductFailure = (payload) => ({
  type: Actions.EDIT_PRODUCT_FAILURE,
  payload,
});

// ACTIVATE OR DEACTIVATE PRODUCT
export const changeProductStatusStart = () => ({
  type: Actions.CHANGE_PRODUCT_STATUS_START,
});

export const changeProductStatusSuccess = (payload) => ({
  type: Actions.CHANGE_PRODUCT_STATUS_SUCCESS,
  payload,
});

export const changeProductStatusFailure = (payload) => ({
  type: Actions.CHANGE_PRODUCT_STATUS_FAILURE,
  payload,
});

// STOCK UP PRODUCT
export const stockUpProductStart = () => ({
  type: Actions.STOCK_UP_PRODUCT_START,
});

export const stockUpProductSuccess = (payload) => ({
  type: Actions.STOCK_UP_PRODUCT_SUCCESS,
  payload,
});

export const stockUpProductFailure = (payload) => ({
  type: Actions.STOCK_UP_PRODUCT_FAILURE,
  payload,
});

// DELETE OR DEACTIVATE PRODUCT
export const deleteProductStart = () => ({
  type: Actions.DELETE_PRODUCT_START,
});

export const deleteProductSuccess = (payload) => ({
  type: Actions.DELETE_PRODUCT_SUCCESS,
  payload,
});

export const deleteProductFailure = (payload) => ({
  type: Actions.DELETE_PRODUCT_FAILURE,
  payload,
});

// FILTER PRODUCTS
export const filterProductsStart = () => ({
  type: Actions.FILTER_PRODUCTS_START,
});

export const filterProductsSuccess = (payload) => ({
  type: Actions.FILTER_PRODUCTS_SUCCESS,
  payload,
});

export const filterProductsFailure = (payload) => ({
  type: Actions.FILTER_PRODUCTS_FAILURE,
  payload,
});

// SEARCH PRODUCTS
export const searchProductsStart = () => ({
  type: Actions.SEARCH_PRODUCTS_START,
});

export const searchProductsSuccess = (payload) => ({
  type: Actions.SEARCH_PRODUCTS_SUCCESS,
  payload,
});

export const searchProductsFailure = (payload) => ({
  type: Actions.SEARCH_PRODUCTS_FAILURE,
  payload,
});

// GET PRODUCT HISTORY
export const getProductHistoryStart = () => ({
  type: Actions.GET_PRODUCT_HISTORY_START,
});

export const getProductHistorySuccess = (payload) => ({
  type: Actions.GET_PRODUCT_HISTORY_SUCCESS,
  payload,
});

export const getProductHistoryFailure = (payload) => ({
  type: Actions.GET_PRODUCT_HISTORY_FAILURE,
  payload,
});

// FILTER PRODUCT HISTORY
export const filterProductHistoryStart = () => ({
  type: Actions.FILTER_PRODUCT_HISTORY_START,
});

export const filterProductHistorySuccess = (payload) => ({
  type: Actions.FILTER_PRODUCT_HISTORY_SUCCESS,
  payload,
});

export const filterProductHistoryFailure = (payload) => ({
  type: Actions.FILTER_PRODUCT_HISTORY_FAILURE,
  payload,
});

// SEARCH PRODUCT HISTORY
export const searchProductHistoryStart = () => ({
  type: Actions.SEARCH_PRODUCT_HISTORY_START,
});

export const searchProductHistorySuccess = (payload) => ({
  type: Actions.SEARCH_PRODUCT_HISTORY_SUCCESS,
  payload,
});

export const searchProductHistoryFailure = (payload) => ({
  type: Actions.SEARCH_PRODUCT_HISTORY_FAILURE,
  payload,
});

export const pushProductToTopStart = () => ({
  type: Actions.PUSH_PRODUCT_TO_TOP_START,
});

export const pushProductToTopSuccess = () => ({
  type: Actions.PUSH_PRODUCT_TO_TOP_SUCCESS,
});

export const getAllProducts = (page, limit, all) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getAllProductsStart());
  API.get(`/product?limit=${limit}&page=${page}&all=${all}`).then(
    (response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(getAllProductsSuccess(data && data.data));
      } else {
        dispatch(getAllProductsFailure(data));
        responseHandler(response, "Failed!");
      }
    }
  );
};

export const getSingleProduct = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getSingleProductStart());
  API.get(`/product/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getSingleProductSuccess(data && data.data));
    } else {
      dispatch(getSingleProductFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const createProduct = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(createProductStart());
  API.post("/product", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(createProductSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(createProductFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const editProduct = (id, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(editProductStart());
  API.put(`/product/${id}`, payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(editProductSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(editProductFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const changeProductStatus = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(changeProductStatusStart());
  API.put(`/product/activate-deactivate/${payload.id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(changeProductStatusSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(changeProductStatusFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const stockUpProduct = (id, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(stockUpProductStart());
  API.put(`/product/stock-up/${id}`, payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(stockUpProductSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(stockUpProductFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const deleteProduct = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(deleteProductStart());
  API.delete(`/product/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(deleteProductSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(deleteProductFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const filterProducts = (page, limit, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(filterProductsStart());
  API.get(
    `product/filter?status=${payload.status}&product_category_id=${
      payload.category_id
    }${payload.date ? `&date=${payload.date}` : ``}`
  ).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(filterProductsSuccess(data && data.data));
    } else {
      dispatch(filterProductsFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const searchProducts =
  (page, limit, searchValue) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

    dispatch(searchProductsStart());
    API.get(`/product?limit=${limit}&page=${page}&search=${searchValue}`).then(
      (response) => {
        const { data, ok } = response;
        if (ok) {
          dispatch(searchProductsSuccess(data && data.data));
        } else {
          dispatch(searchProductsFailure(data));
          responseHandler(response, "Failed!");
        }
      }
    );
  };

export const getProductHistory = (page, limit, id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getProductHistoryStart());
  API.get(`/product-history/${id}?page=${page}&limit=${limit}`).then(
    (response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(getProductHistorySuccess(data && data.data));
      } else {
        dispatch(getProductHistoryFailure(data));
        responseHandler(response, "Failed!");
      }
    }
  );
};

export const filterProductHistory =
  (page, limit, payload) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

    dispatch(filterProductHistoryStart());
    API.get(
      `/product-history/filter/${payload.id}?status=${payload.status}&date=${payload.date}&limit=${limit}&page=${page}`
    ).then((response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(filterProductHistorySuccess(data && data.data));
      } else {
        dispatch(filterProductHistoryFailure(data));
        responseHandler(response, "Failed!");
      }
    });
  };

export const searchProductHistory =
  (page, limit, payload) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

    dispatch(searchProductHistoryStart());
    API.get(
      `/product-history/${payload.id}?limit=${limit}&page=${page}&search=${payload.searchValue}`
    ).then((response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(searchProductHistorySuccess(data && data.data));
      } else {
        dispatch(searchProductHistoryFailure(data));
        responseHandler(response, "Failed!");
      }
    });
  };

export const swapProductRank = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));
  API.post(`product/swap-ranks`, payload).then((response) => {
    const { ok } = response;
    if (!ok) {
      responseHandler(response, "Failed!");
    }
  });
};
