import React, { useEffect, useState } from "react";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import BlogInputs from "./BlogInputs";
import BackBtn from "../Input/BackBtn";
import { connect } from "react-redux";
import {
  createBlogPost,
  getBlogCategories,
} from "../../redux/Actions/BlogActions";
import { useHistory } from "react-router";
import { uploadImage } from "../../redux/Actions/UploadActions";
import { CreatePostSchema } from "../../utils/validation/validation";
import { visibleList } from "../../utils/functions/BlogPost";
import {
  assignBlogCategoryOptions,
  assignBlogCategoryPayload,
} from "../../utils/functions/UpdateValues";
import draftToHtml from "draftjs-to-html";

const CreatePost = (props) => {
  const {
    isCreating,
    createSuccess,
    createBlogPost,
    isUploading,
    uploadFile,
    uploadSuccess,
    getSuccess,
    categories,
    getCategory,
    name,
  } = props;

  const history = useHistory();

  const [options, setOptions] = useState([]);

  useEffect(
    () => {
      getCategory();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (getSuccess) {
        const updatedCategory = assignBlogCategoryOptions(categories);
        setOptions(updatedCategory);
      }
    },
    // eslint-disable-next-line
    [getSuccess]
  );

  const initialValues = {
    title: "",
    caption: "",
    author: "",
  };

  const [state, setState] = React.useState({
    image: "",
    checked1: false,
    checked2: false,
    checked3: false,
    checked4: false,
    category: [],
    content: "",
    loaded: false,
  });

  const toggleList = visibleList(state, setState);

  const onContentStateChange = (contentState) => {
    setState({
      ...state,
      contentState: contentState,
    });
  };

  const removeAll = (type) => {
    setState({
      ...state,
      [type]: [],
    });
  };

  const noCheckedState =
    !state.checked1 && !state.checked2 && !state.checked3 && !state.checked4;

  const submitValues = (values) => {
    const payload = {
      image_url: state.image,
      vissible_to_all: state.checked1,
      vissible_to_patient_only: state.checked2,
      vissible_to_doctors_only: state.checked3,
      vissible_to_consultants_only: state.checked4,
      title: values.title,
      admin_content: JSON.stringify(state.contentState),
      content: state.contentState && draftToHtml(state.contentState),
      caption: values.caption,
      author_name: values.author,
      blog_categories:
        state.category && assignBlogCategoryPayload(state.category),
    };
    createBlogPost(payload);
  };

  useEffect(
    () => {
      if (createSuccess) {
        history.push("/blog-management");
      }
    },
    // eslint-disable-next-line
    [createSuccess]
  );

  return (
    <div className="blog">
      <BackBtn page="/blog-management" />
      <HeaderWrapper
        title={"New Blog Post"}
        subtitle={"Create a new blog post for users to read"}
      />

      <BlogInputs
        state={state}
        setState={setState}
        toggleList={toggleList}
        removeAll={removeAll}
        options={options}
        btnText="Post Article"
        currentDate={true}
        isLoading={isCreating}
        disabledFunc={(isValid, dirty, values) =>
          isCreating ||
          isUploading ||
          !(isValid && dirty) ||
          state.contentState === "" ||
          state.image === "" ||
          noCheckedState ||
          (state.category && state.category.length === 0)
        }
        contentState={state.contentState}
        onContentStateChange={onContentStateChange}
        isUploading={isUploading}
        uploadSuccess={uploadSuccess}
        uploadFile={uploadFile}
        name={name}
        type={"add"}
        initialValues={initialValues}
        schema={CreatePostSchema}
        submitValues={submitValues}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isCreating: state.blog.isCreatingBlogPost,
  createSuccess: state.blog.createSuccess,
  isUploading: state.upload.isUploading,
  uploadSuccess: state.upload.uploadSuccess,
  isGetting: state.blogcategory.isGettingCategories,
  getSuccess: state.blogcategory.getCategorySuccess,
  categories: state.blogcategory.allCategories,
});

const mapDispatchToProps = (dispatch) => ({
  createBlogPost: (payload) => dispatch(createBlogPost(payload)),
  uploadFile: (payload) => dispatch(uploadImage(payload)),
  getCategory: () => dispatch(getBlogCategories()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePost);
