import React from "react";
import PropTypes from "prop-types";
import Search from "./Search";
import Filter from "./Filter";
import refreshLogo from "../../assets/refreshing.svg";

const SortData = (props) => {
  const {
    placeholder,
    type,
    width,
    searchItems,
    setState,
    state,
    onClick,
    filter,
    testid,
    newIcon,
    sortSuccess,
    refreshFunction,
  } = props;

  return (
    <div className="flex-options">
      <Search
        type={type}
        placeholder={placeholder}
        width={width}
        searchItems={searchItems}
        setState={setState}
        state={state}
      />
      {filter && (
        <Filter
          onClick={onClick}
          marginLeft={"24px"}
          marginRight={"0px"}
          testid={testid}
          newIcon={newIcon}
        />
      )}
      {sortSuccess && (
        <img
          title="Refresh table"
          style={{ marginLeft: "20px", width: "25px", height: "25px" }}
          className="cursor-pointer"
          src={refreshLogo}
          alt="refresh-logo"
          onClick={() => refreshFunction()}
        />
      )}
    </div>
  );
};

SortData.propTypes = {
  filter: PropTypes.any,
  newIcon: PropTypes.any,
  onClick: PropTypes.any,
  placeholder: PropTypes.any,
  searchItems: PropTypes.any,
  setState: PropTypes.any,
  state: PropTypes.any,
  testid: PropTypes.any,
  width: PropTypes.any,
};

export default SortData;
