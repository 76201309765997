import React, { useMemo } from "react";
import PopularProducts from "./Chart";
import UserGrid from "./UserGrid";
import NothingFound from "../../assets/empty.svg";

const RecentAppointments = (props) => {
  const { state, setState, appointments, products } = props;

  const recentAppointments = appointments.length ? (
    <div className="appointment-grid3">
      {appointments.map((item, index) => (
        <UserGrid
          overview
          item={item}
          index={index}
          key={index}
          icon
          state={state}
          setState={setState}
          limit={4}
        />
      ))}
    </div>
  ) : (
    <div className="table-empty">
      <div className="table-empty-container">
        <div className="table-empty-container-image2">
          <img src={NothingFound} alt="empty-table" />
        </div>

        <div>
          <h2>No recent appointments at the moment</h2>
        </div>
      </div>
    </div>
  );

  return (
    <div className="overview-recents">
      {useMemo(
        () => (
          <PopularProducts productsData={products} />
        ), // eslint-disable-next-line
        []
      )}
      <div className="overview-recents-appointments">
        <h5>Recent Appointments</h5>
        {recentAppointments}
      </div>
    </div>
  );
};

export default RecentAppointments;
