import React from "react";
import Skeleton from "react-loading-skeleton";

const OverviewLoader = () => {
  return (
    <div>
      <div className="overview-figures">
        <Skeleton height={"96px"} width={"100%"} />
        <Skeleton height={"96px"} width={"100%"} />
        <Skeleton height={"96px"} width={"100%"} />
        <Skeleton height={"96px"} width={"100%"} />
      </div>

      <div className="overview-graph" style={{ padding: 0, border: "none" }}>
        <Skeleton height={"556px"} width={"100%"} />
      </div>

      <div className="overview-recents">
        <Skeleton height={"500px"} width={"100%"} />
        <Skeleton height={"500px"} width={"100%"} />
      </div>

      <div className="overview-table" style={{ padding: 0, border: "none" }}>
        <Skeleton height={"40px"} width={"100%"} count={6} />
      </div>
    </div>
  );
};

export default OverviewLoader;
