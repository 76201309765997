import React, { useEffect } from "react";
import { connect } from "react-redux";
import Table from "../../components/Tables/Table";
import {
  filterBlogCategories,
  getAllBlogCategories,
  searchBlogCategories,
} from "../../redux/Actions/BlogActions";
import FilterCategory from "../../components/BlogManagement/BlogCategory/FilterCategory";
import { blogCategoryColumns } from "../../components/TableData/Blog/BlogCategory";
import Storage from "../../utils/services/storage";

const BlogCategory = (props) => {
  const {
    isGettingBlogCategories,
    getSuccess,
    blogCategoriesData,
    isFiltering,
    filterSuccess,
    filteredBlogCategories,
    filteredBlogCategoriesData,
    isSearching,
    searchSuccess,
    searchedBlogCategories,
    searchedBlogCategoriesData,
    getBlogCategories,
    searchBlogCategories,
    filterBlogCategories,
    blogCategories,
    closeModal,
    state,
    setState,
    addSuccess,
    setBlogData,
    blogData,
  } = props;

  const paginateCategoryFilter = (page, perPage, values) => {
    const payload = {
      date: values.date,
      status: values.status,
    };
    filterBlogCategories(page, perPage, payload);
  };

  const getBlogCategory = (page, perPage) => {
    Storage.set("page", page);
    Storage.set("perPage", perPage);
    getBlogCategories(page, perPage);
  };

  const moreData = (page, perPage) => {
    searchSuccess
      ? searchBlogCategories(page, perPage, state.searchValue)
      : filterSuccess
      ? paginateCategoryFilter(page, perPage, state.filterValues)
      : getBlogCategory(page, perPage);
  };

  const allBlogCategories = searchSuccess
    ? searchedBlogCategories
    : filterSuccess
    ? filteredBlogCategories
    : blogCategories;
  useEffect(() => {
    setBlogData({ ...blogData, category: allBlogCategories });
  }, [allBlogCategories]);

  const totalBlogCategories = searchSuccess
    ? searchedBlogCategoriesData && searchedBlogCategoriesData.total
    : filterSuccess
    ? filteredBlogCategoriesData && filteredBlogCategoriesData.total
    : blogCategoriesData && blogCategoriesData.total;

  const clearStatus = () => {
    setState({
      ...state,
      status: "",
      active: false,
      index: "",
    });
  };

  useEffect(
    () => {
      if (addSuccess) {
        clearStatus();
        document.getElementById("addCategory").reset();
        closeModal("addCategory");
        getBlogCategories(1, 10);
      }
    },
    // eslint-disable-next-line
    [addSuccess]
  );

  return (
    <div>
      <Table
        columns={blogCategoryColumns}
        data={allBlogCategories}
        getData={(page, perPage) => getBlogCategory(page, perPage)}
        emptyText1={"You haven't added any blog category"}
        emptyText2={"Click on the “Create Category” button to get started"}
        isLoading={isGettingBlogCategories || isSearching || isFiltering}
        getMoreData={(page, perPage) => moreData(page, perPage)}
        success={getSuccess || filterSuccess || searchSuccess}
        sortSuccess={searchSuccess || filterSuccess}
        total={totalBlogCategories}
        emptyResultText={"Category not found!"}
        className={"table3"}
      />

      <FilterCategory
        displayModal={state.filterCategory}
        closeModal={() => closeModal("filterCategory")}
        isFiltering={isFiltering}
        filterCategories={filterBlogCategories}
        filterSuccess={filterSuccess}
        setFilterState={setState}
        filterState={state}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGettingBlogCategories: state.blogcategory.isGettingCategories,
  getSuccess: state.blogcategory.getCategorySuccess,
  blogCategories: state.blogcategory.categories,
  blogCategoriesData: state.blogcategory.categoriesData,
  isFiltering: state.blogcategory.isFiltering,
  filterSuccess: state.blogcategory.filterSuccess,
  filteredBlogCategories: state.blogcategory.filteredBlogCategories,
  filteredBlogCategoriesData: state.blogcategory.filteredBlogCategoriesData,
  isSearching: state.blogcategory.isSearching,
  searchSuccess: state.blogcategory.searchSuccess,
  searchedBlogCategories: state.blogcategory.searchedBlogCategories,
  searchedBlogCategoriesData: state.blogcategory.searchedBlogCategoriesData,
});

const mapDispatchToProps = (dispatch) => ({
  getBlogCategories: (page, limit) =>
    dispatch(getAllBlogCategories(page, limit)),
  searchBlogCategories: (page, limit, searchValue) =>
    dispatch(searchBlogCategories(page, limit, searchValue)),
  filterBlogCategories: (page, limit, payload) =>
    dispatch(filterBlogCategories(page, limit, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BlogCategory);
