import React, { useState } from "react";
import { cancelModal, viewModal } from "../../Modal/Modal";
import DeleteCategory from "../../Products/ProductCategory/DeleteCategory";
import EditCategory from "../../Products/ProductCategory/EditCategory";
import { DropBtn, TableDropdown } from "../../Tables/TableButtons";

export const productCategoryColumns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Description",
    accessor: "description",
    Cell: ({ row }) => {
      return <div>{row.original.description || "Not available"}</div>;
    },
  },
  {
    Header: "",
    accessor: "action",
    Cell: ({ row }) => {
      const [state, setState] = useState({
        editModal: false,
        deleteModal: false,
        show: false,
        index: "",
      });

      const [show, setShow] = useState(false);

      const id = row.original.id;

      const openModal = (type) => {
        viewModal(type, state, setState);
        setShow((prevCheck) => !prevCheck);
      };

      const closeModal = (type) => {
        cancelModal(type, state, setState);
      };

      const options = [
        {
          name: "Edit",
          action: () => openModal("editModal"),
        },
        {
          name: "Delete",
          action: () => openModal("deleteModal"),
        },
      ];

      return (
        <div className="action-flex">
          <DropBtn onClick={() => setShow((prevCheck) => !prevCheck)} />
          {show && <TableDropdown options={options} />}

          {state.editModal && (
            <EditCategory
              displayModal={state.editModal}
              closeModal={() => closeModal("editModal")}
              data={row.original}
            />
          )}
          {state.deleteModal && (
            <DeleteCategory
              displayModal={state.deleteModal}
              closeModal={() => closeModal("deleteModal")}
              id={id}
            />
          )}
        </div>
      );
    },
  },
];
