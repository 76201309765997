import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import Icon from "../../../assets/Clients.svg";
import BackBtn from "../../../components/Input/BackBtn";
import { LoaderSpin } from "../../../components/Loader/Loader";
import { ConsultantRight } from "../../../components/UserManagement/Consultants/ConsultantRight";
import GeneralInfo from "../../../components/UserManagement/GeneralInfo";
import UserHeader from "../../../components/UserManagement/UserHeader";
import {
  deleteUser,
  getUsersProfile,
} from "../../../redux/Actions/UsersActions";

const SingleConsultant = (props) => {
  const {
    getUsersProfile,
    isGettingProfile,
    usersProfileData,
    deleteUser,
    isDeleting,
    deleteSuccess,
  } = props;

  const location = useLocation();

  const id = location && location.state.id;
  const clientName = usersProfileData && usersProfileData.full_name;
  const clientStatus = usersProfileData && usersProfileData.status;
  const clientAppointments = usersProfileData && usersProfileData.appointments;
  const patients = usersProfileData && usersProfileData.patients;
  const reroutedPatients =
    usersProfileData && usersProfileData.reroutedPatients;

  useEffect(() => {
    getUsersProfile("specialist", id);
  }, [getUsersProfile, id]);

  return (
    <div>
      {isGettingProfile ? (
        <div className="loader-table2">
          <LoaderSpin width="40px" height="40px" />
        </div>
      ) : (
        <>
          <BackBtn page="/users" />
          <UserHeader
            clientPicture={Icon}
            clientName={clientName}
            status={clientStatus}
            chatPage={`/users/consultant/chats/${id}`}
            deleteUser={deleteUser}
            isDeleting={isDeleting}
            deleteSuccess={deleteSuccess}
            ClientId={id}
          />
          <GeneralInfo
            data={usersProfileData}
            children={
              <ConsultantRight
                data={clientAppointments}
                patients={patients}
                reroutedPatients={reroutedPatients}
              />
            }
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGettingProfile: state.users.isGettingProfile,
  getSuccessProfile: state.users.getSuccessProfile,
  usersProfileData: state.users.usersProfileData,
  isDeleting: state.users.isDeleting,
  deleteSuccess: state.users.deleteSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  deleteUser: (id) => dispatch(deleteUser(id)),
  getUsersProfile: (type, id) => dispatch(getUsersProfile(type, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleConsultant);
