import React from "react";
import { useHistory } from "react-router-dom";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import BackBtn from "../Input/BackBtn";
import SortData from "../Sorting/SortData";

const ChatList = (props) => {
  const {
    back,
    subtitle,
    setState,
    state,
    searchItems,
    switchWrapper,
    chatComponent,
    sortSuccess,
    refreshFunction,
  } = props;

  const history = useHistory();
  const goBack = () => {
    history.goBack(-1);
  };

  return (
    <div className="chats-left">
      <div className="chats-top">
        {back && <BackBtn onClick={goBack} />}
        {
        /* <HeaderWrapper title={"User Chats"} subtitle={subtitle}></HeaderWrapper> */}

        <div className="chats-left-sorts">
          <SortData
          type={"chat"}
            placeholder={"Search by name"}
            width={"100%"}
            searchItems={searchItems}
            setState={setState}
            state={state}
            newIcon={true}
            sortSuccess={sortSuccess}
            refreshFunction={refreshFunction}
          />
        </div>
      </div>

      <div className="chats-left-switch chat-left-tabs">{switchWrapper}</div>
      <div className="chats-left-switch">{chatComponent}</div>
    </div>
  );
};

export default ChatList;
