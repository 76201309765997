import React, { useEffect, useState } from "react";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import OrderDetailsColumns from "../../components/Orders/OrderDetails";
import Input from "../../components/Input/Input";
import BackBtn from "../../components/Input/BackBtn";
import { getOrder } from "../../redux/Actions/OrderActions";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { LoaderSpin } from "../../components/Loader/Loader";

const OrderDetails = (props) => {
  const { getOrder, isGetting, singleOrder, getSuccess, changeSuccess } = props;
  const location = useLocation();
  const [ordersInfo, setOrdersInfo] = useState([]);
 
  const id = location && location.state && location.state.id;

  const userInfo = singleOrder && singleOrder.user;
  const deliveryInfo = singleOrder && singleOrder.delivery_address;
  const orderNo = singleOrder && singleOrder.order_number;

  useEffect(() => {
    getOrder(id);
  }, [getOrder, id]); 

  useEffect(() => {
    if (getSuccess) {
      setOrdersInfo(singleOrder && singleOrder.order_items);
    }
  }, [getSuccess, singleOrder]);

  console.log('userInfo', userInfo)

  return (
    <div>
      {isGetting ? (
        <div className="loader-table">
          <LoaderSpin width="40px" height="40px" />
        </div>
      ) : (
        <>
          <BackBtn page="/order-management" />
          <HeaderWrapper
            title={`${
              (userInfo && userInfo?.first_name && userInfo?.last_name) ? 
              (userInfo?.first_name + ' ' + userInfo?.last_name) :
              userInfo?.full_name
            } Orders`}

            subtitle={`View all orders by ${
              (userInfo && userInfo?.first_name && userInfo?.last_name) ? 
              (userInfo?.first_name + ' ' + userInfo?.last_name) :
              userInfo?.full_name
            }`}
          ></HeaderWrapper>

          <OrderDetailsColumns
            data={ordersInfo}
            orderNo={orderNo}
            changeSuccess={changeSuccess}
            singleOrder={singleOrder}
          />
          <div>
            <div className="order-details-title">Delivery Info</div>
            <div className="form-grid4">
              <Input
                name="name"
                label="Name"
                placeholder="Not available"
                value={`${
                  (userInfo && userInfo?.first_name && userInfo?.last_name) ? 
                  (userInfo?.first_name + ' ' + userInfo?.last_name) :
                  userInfo?.full_name
                }`}
                readOnly
              />
              <Input
                name="phoneNumber"
                label="Phone Number"
                placeholder="Not available"
                value={userInfo && userInfo.phone_number}
                readOnly
              />
              <Input
                name="email"
                label="Email"
                placeholder="Enter email address"
                value={userInfo && userInfo.email}
                readOnly
              />
              <Input
                name="city"
                label="City"
                placeholder="Not available"
                value={deliveryInfo && deliveryInfo.city}
                readOnly
              />
              <Input
                name="address"
                label="Address"
                placeholder="Not available"
                value={deliveryInfo && deliveryInfo.full_address}
                readOnly
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGetting: state.order.isGetting,
  singleOrder: state.order.singleOrder,
  getSuccess: state.order.getOrderSuccess,
  changeSuccess: state.order.changeSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getOrder: (id) => dispatch(getOrder(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
