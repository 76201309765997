import React, { useEffect, useState, useRef } from "react";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import SortData from "../../components/Sorting/SortData";
import Table from "../../components/Tables/Table";
import { cancelModal, viewModal } from "../../components/Modal/Modal";
import FilterSubscribers from "../../components/PaymentPlans/FilterSubscribers";
import BackBtn from "../../components/Input/BackBtn";
import { connect } from "react-redux";
import Add from "../../assets/add.svg";
import {
  filterAllSubscribers,
  getAllSubscribers,
  getExportSubscribersCSVResponse,
  searchAllSubscribers,
} from "../../redux/Actions/PaymentPlanActions";
import { subscribersColumns } from "../../components/TableData/Payment/Subscribers";
import Export3 from "../../components/Overview/Export3";
import html2pdf from "html2pdf.js";
import downloadCSV from "../../utils/functions/csvDownloader";
import { PageButton } from "../../components/Button/Button";
import CreateSubscription from "../../components/Subscriptions/CreateSubscription";
import { getSubscriptions } from "../../redux/Actions/SubscriptionsAction";
import Alert from "../../utils/notifications/Alert";

export const paginateSubscribersFilter = (
  page,
  perPage,
  values,
  filterSubscribers
) => {
  const payload = {
    plan: values.plan,
    startDate: values.startDate,
    endDate: values.endDate,
  };
  console.log(page, perPage, payload);

  filterSubscribers(page, perPage, payload);
};

const SubscribedUsers = (props) => {
  const {
    isGettingSubscribers,
    getSuccess,
    subscribers,
    subscribersData,
    isFilteringSubscribers,
    filterSuccess,
    filteredSubscribers,
    filteredSubscribersData,
    isSearchingSubscribers,
    searchSuccess,
    searchedSubscribers,
    searchedSubscribersData,
    getSubscribers,
    searchSubscribers,
    filterSubscribers,
    getCSVResponseDataSuccess,
    csvResponseData,
    getExportSubscribersCSVResponse,
    isGettingCSVResponse,
    getSubscriptions,
    subscriptiondata,
  } = props;

  let date = new Date();
  let firstDayMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const [minDate, setMinDate] = useState(firstDayMonth.toISOString());
  const [maxDate, setMaxDate] = useState(new Date().toISOString());
  const [state, setState] = useState({
    filterModal: false,
    assignSubscriptionModal: false,
    filterValues: {},
    searchValue: "",
  });
  const wrapperRef = useRef();

  useEffect(() => {
    getSubscriptions();
  }, []);

  useEffect(() => {
    if (subscriptiondata) {
      const planIdData = subscriptiondata.rows.map((el) => el.plan_id);
      const uniqueIds = [...new Set(planIdData)];
      const sort = uniqueIds.sort(function (a, b) {
        return a - b;
      });
      setPlanIds(sort);
      // console.log(subscriptiondata)
    }
  }, [subscriptiondata]);

  const openModal = (type) => viewModal(type, state, setState);
  const closeModal = (type) => cancelModal(type, state, setState);

  const searchAllSubscribers = () => {
    if (state.searchValue === "") {
      return subscribers;
    } else {
      searchSubscribers(1, 10, state.searchValue);
    }
  };

  const moreData = (page, perPage) => {
    searchSuccess
      ? searchSubscribers(page, perPage, state.searchValue)
      : filterSuccess
      ? paginateSubscribersFilter(
          page,
          perPage,
          state.filterValues,
          filterSubscribers
        )
      : getSubscribers(page, perPage);
    getExportSubscribersCSVResponse("", page, perPage);
  };

  const [planIds, setPlanIds] = useState([]);

  const allSubscribers = searchSuccess
    ? searchedSubscribers
    : filterSuccess
    ? filteredSubscribers
    : subscribers;

  const totalSubscribers = searchSuccess
    ? searchedSubscribersData && searchedSubscribersData.total
    : filterSuccess
    ? filteredSubscribersData && filteredSubscribersData.total
    : subscribersData && subscribersData.total;

  useEffect(
    () => {
      if (getSuccess) {
        setState({
          ...state,
          searchValue: "",
        });
      }
    },
    // eslint-disable-next-line
    [getSuccess, minDate, maxDate]
  );
  // useEffect(() => {
  //   getExportSubscribersCSVResponse("", 1, 10);
  // }, []);

  // To export
  const exportHandler = (format) => {
    switch (format) {
      case "CSV":
        if (
          !state.filterValues.subStartDate &&
          !state.filterValues.subEndDate &&
          !state.filterValues.plan
        ) {
          return Alert(
            "error",
            "Failed",
            "",
            "Please Select Filters before Generating CSV"
          );
        }
        
        if (
          !state.filterValues.subStartDate &&
          !state.filterValues.subEndDate &&
          state.filterValues.plan
          ) {
          return Alert(
            "error",
            "Failed",
            "",
            "Can't Generate CSV For Plan Filters Only"
          );
        }
        getExportSubscribersCSVResponse(
          {
            subStartDate: state.filterValues.subStartDate,
            subEndDate: state.filterValues.subEndDate,
            plan: state.filterValues.plan,
          },
          1,
          10
        );

        return;
      default:
        return;
    }
  };

  return (
    <div>
      {/* <BackBtn page={"/payment-plans"} /> */}
      <HeaderWrapper
        title={"Users Subscriptions"}
        subtitle={"View all subscribed users on the platform"}
      >
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: "2rem" }}>
            <Export3
              exportHandler={exportHandler}
              isGettingCSVResponse={isGettingCSVResponse}
            />
          </div>

          <PageButton onClick={() => openModal("createSubscriptionsModal")}>
            <div className="button-data">
              <img src={Add} alt="add-icon" />
              <span>Create</span>
            </div>
          </PageButton>
        </div>
        <div className=""></div>
      </HeaderWrapper>

      <SortData
        placeholder={"Search subscribed users by name"}
        width={"347px"}
        searchItems={searchAllSubscribers}
        setState={setState}
        state={state}
        onClick={() => openModal("filterModal")}
        filter={true}
        sortSuccess={filterSuccess || searchSuccess}
        refreshFunction={() => {
          setState({...state, filterValues: {}})
          getSubscribers(1, 10)}}
      />

      <div ref={wrapperRef}>
        <Table
          columns={subscribersColumns}
          data={allSubscribers}
          getData={getSubscribers}
          emptyText1={"No subscribed users"}
          isLoading={
            isGettingSubscribers ||
            isFilteringSubscribers ||
            isSearchingSubscribers
          }
          getMoreData={(page, perPage) => moreData(page, perPage)}
          success={getSuccess || filterSuccess || searchSuccess}
          sortSuccess={searchSuccess || filterSuccess}
          total={totalSubscribers}
          emptyResultText={"Subscriber not found!"}
        />

        <CreateSubscription
          displayModal={state.createSubscriptionsModal}
          closeModal={() => closeModal("createSubscriptionsModal")}
          getSubscriptions={getSubscriptions}
          planIds={planIds}
        />

        <FilterSubscribers
          displayModal={state.filterModal}
          closeModal={() => closeModal("filterModal")}
          isFiltering={isFilteringSubscribers}
          filterSubscribers={filterSubscribers}
          filterSuccess={filterSuccess}
          setFilterState={setState}
          filterState={state}
          getExportSubscribersCSVResponse={(payload, page, limit) =>
            getExportSubscribersCSVResponse(payload, page, limit)
          }
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGettingSubscribers: state.plan.isGettingSubscribers,
  getSuccess: state.plan.getSubscribersSuccess,
  subscribers: state.plan.subscribers,
  subscribersData: state.plan.subscribersData,
  isFilteringSubscribers: state.plan.isFilteringSubscribers,
  filterSuccess: state.plan.filterSuccess,
  filteredSubscribers: state.plan.filteredSubscribers,
  filteredSubscribersData: state.plan.filteredSubscribersData,
  isSearchingSubscribers: state.plan.isSearchingSubscribers,
  searchSuccess: state.plan.searchSuccess,
  searchedSubscribers: state.plan.searchedSubscribers,
  searchedSubscribersData: state.plan.searchedSubscribersData,
  getCSVResponseDataSuccess: state.plan.getCSVResponseDataSuccess,
  csvResponseData: state.plan.csvResponseData,
  isGettingCSVResponse: state.plan.isGettingCSVResponse,
  subscriptiondata: state.subscriptions.subscriptiondata,
});

const mapDispatchToProps = (dispatch) => ({
  getSubscribers: (page, limit) => dispatch(getAllSubscribers(page, limit)),
  searchSubscribers: (page, limit, searchValue) =>
    dispatch(searchAllSubscribers(page, limit, searchValue)),
  filterSubscribers: (page, limit, payload) =>
    dispatch(filterAllSubscribers(page, limit, payload)),
  getExportSubscribersCSVResponse: (payload, page, limit) =>
    dispatch(getExportSubscribersCSVResponse(payload, page, limit)),
  getSubscriptions: () => dispatch(getSubscriptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscribedUsers);
