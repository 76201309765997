import React, { useEffect } from "react";
import { connect } from "react-redux";
import { LoaderSpin } from "../../components/Loader/Loader";
import CancelIcon from "../../assets/cancel.svg";
import WhispaLogo from "../../assets/whispa-logo.svg";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import { getSinglePrescription } from "../../redux/Actions/prescriptionActions";
import moment from "moment";
import { useHistory } from "react-router-dom";

const PrescriptionDetails = (props) => {
  const {
    match,
    getSinglePresciptionData,
    prescriptionData,
    isGettingPrescription,
    getPrescriptionSuccess,
  } = props;
  const history = useHistory();

  useEffect(() => {
    const id = match.params.id;
    getSinglePresciptionData(id);
  }, []);
  return (
    <>
      {!isGettingPrescription ? (
        <div className="prescription-details">
          <div onClick={() => history.goBack()} className="cancel-icon">
            <img src={CancelIcon} alt="cancel-icon" />
          </div>
          <section className="page-content">
            <div className="date">
              <p className="date-issued">Date/Time Issued:</p>
              <p>
                {moment(prescriptionData?.created_at).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
              </p>
            </div>
            <div className="client-details">
              <div className="left">
                <div className="text-content">
                  <p className="name presc-ref">Prescription Ref:</p>
                  <p className="content">
                    {prescriptionData.prescription_number}
                  </p>
                </div>
                <div className="text-content">
                  <p className="name">Client Name:</p>
                  <p className="content">
                    {prescriptionData.customer?.full_name}
                  </p>
                </div>
                <div className="text-content">
                  <p className="name">Age Range:</p>
                  <p className="content">
                    {prescriptionData.customer?.age_group}
                  </p>
                </div>
                <div className="text-content">
                  <p className="name">Sex:</p>
                  <p className="content">{prescriptionData.customer?.gender}</p>
                </div>
              </div>
              <div className="right">
                <div className="img">
                  <img src={WhispaLogo} alt="whispa-logo" />
                </div>
                <div className="text-content">
                  <p className="name">website:</p>
                  <p className="content">www.whispahealth.com</p>
                </div>
                <div className="text-content">
                  <p className="name">tel:</p>
                  <p className="content">+2348094477226</p>
                </div>
                <div className="text-content">
                  <p className="content">info@whispahealth.com</p>
                </div>
              </div>
            </div>
            <table>
              <tr>
                <th>Drug</th>
                <th>Dosage</th>
                <th>Note</th>
              </tr>
              <>
                {prescriptionData.prescription_items?.map((item) => (
                  <tr>
                    <td>{item.drug}</td>
                    <td>{item.dosage}</td>
                    <td>{item.note}</td>
                  </tr>
                ))}
              </>
            </table>
            <div className="prescribed-by">
              <h2>Prescribed by:</h2>
              <h3>{prescriptionData.doctor?.full_name}</h3>
              <p>Do not share your medicines with others.</p>
              <p>
                Do not use this medicine for any other purpose than what it was
                prescribed to you for.
              </p>
              <p>Do not use this medicine longer than prescribed.</p>
              <p>
                Do not stop taking prescribed medicines without first consulting
                with a medical doctor.
              </p>
              <p>You must safely throw away any remaining unused medicines.</p>
              <p>Keep ALL medicines out of reach of children!.</p>
            </div>
          </section>
        </div>
      ) : (
        <LoaderSpin />
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  prescriptionData: state.prescriptions.prescriptionData,
  isGettingPrescription: state.prescriptions.isGettingPrescription,
  getPrescriptionSuccess: state.prescriptions.getPrescriptionSuccess,
});
const mapDispatchToProps = (dispatch) => ({
  getSinglePresciptionData: (id) => dispatch(getSinglePrescription(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrescriptionDetails);
