import React from "react";
import SelectInput from "../../Input/Select";
import Input from "../../Input/Input";
import { Field } from "formik";
import DateFilter from "../../Input/DateFilter";
import moment from "moment";
import ImportantText from "../../Wrappers/ImportantText";

const ProductInputs = (props) => {
  const {
    options,
    updateSelection,
    className,
    state,
    setState,
    setFieldValue,
    addSuccess
  } = props;

  return (
    <>
      <div className={className ? className : "form-grid2"}>
        <Field
          name="product_name"
          label={<ImportantText label={"Product Name"} />}
          placeholder="Enter product name"
          component={Input}
          emptyValue={setFieldValue}
        />

        <SelectInput
          label={<ImportantText label={"Category"} />}
          options={options}
          onChange={(data) => updateSelection("category", data)}
          success={addSuccess}
        />
      </div>

      <div className="form-grid2">
        <Field
          name="price"
          label={<ImportantText label={"Price"} />}
          type="number"
          placeholder="NGN 0.00"
          component={Input}
          emptyValue={setFieldValue}
        />

        <Field
          name="product_description"
          label={<ImportantText label={"Product Description"} />}
          placeholder="Enter description"
          component={Input}
          emptyValue={setFieldValue}
        />
      </div>

      <div className="form-grid2">
        <Field
          name="quantity"
          label={<ImportantText label={"Quantity"} />}
          type="number"
          placeholder="Enter quantity"
          component={Input}
          emptyValue={setFieldValue}
        />

        <Field
          name="moq"
          label={<ImportantText label={"Minimum Order Quantity"} />}
          type="number"
          placeholder="Enter MOQ"
          component={Input}
          emptyValue={setFieldValue}
        />
      </div>

      <div className="form-grid2">
        <Field
          name="manufacturer"
          label="Manufacturer"
          placeholder="Manufacturer name"
          component={Input}
          emptyValue={setFieldValue}
        />

        <Field
          name="effectiveness"
          label="Effectiveness"
          placeholder="Enter effectiveness"
          component={Input}
          emptyValue={setFieldValue}
        />
      </div>

      <div className="form-grid2">
        <DateFilter
          label={"Expiry Date"}
          placeholder={"Select date"}
          state={state}
          name={"date"}
          setState={setState}
          minDate={moment().toDate()}
        />

        <Field
          name="duration"
          label="Duration"
          placeholder="Enter duration eg 20 days"
          component={Input}
          emptyValue={setFieldValue}
        />
      </div>

      <div className="form-grid2">
        <Field
          name="color"
          label="Color"
          placeholder="Enter color"
          component={Input}
          emptyValue={setFieldValue}
        />

        <Field
          name="size"
          label="Size"
          placeholder="Enter size"
          component={Input}
          emptyValue={setFieldValue}
        />
      </div>

      <div className="form-grid2">
        <Field
          name="discount"
          label="Discount"
          placeholder="Enter discount"
          component={Input}
          emptyValue={setFieldValue}
        />
      </div>
    </>
  );
};

export default ProductInputs;
