import React, { useEffect, useState } from "react";
import Modal, { ModalButton } from "../Modal/Modal";
import { Field, Form, Formik } from "formik";
import Input from "../Input/Input";
import {
  assignPermissionData,
  permissionData,
  permissionPayload,
} from "../../utils/functions/CreateNewArray";
import MultiSelect from "../Input/MultiSelect";
import { ToggleButton } from "../Input/ToggleButton";
import { editRole, getAllRoles } from "../../redux/Actions/SettingActions";
import { connect } from "react-redux";

const EditRole = (props) => {
  const {
    displayModal,
    closeModal,
    isEditing,
    editRole,
    data,
    getRoles,
    editSuccess,
  } = props;

  const [state, setState] = useState({
    toggle: false,
  });
  const permissionsArray = data && assignPermissionData(data);

  const adminPermissions = permissionsArray && permissionData(permissionsArray);

  const [checkedItems, setCheckedItems] = useState(adminPermissions);

  useEffect(
    () => {
      if (displayModal) {
        setState({
          ...state,
          toggle:
            data.status && data.status.toLowerCase() === "active"
              ? true
              : false,
        });
      }
    },
    // eslint-disable-next-line
    [displayModal]
  );

  const permissionsPayload = checkedItems && permissionPayload(checkedItems);

  const handleToggle = () => {
    setState({
      ...state,
      toggle: (state.toggle = !state.toggle),
    });
  };

  const selectedCategories =
    checkedItems && checkedItems.filter((item) => item.checked === true);

  const submitValues = (values) => {
    const payload = {
      name: values.name,
      description: values.description,
      status: state.toggle === true ? "Active" : "Inactive",
      ...permissionsPayload,
    };
    editRole(payload);
  };

  useEffect(
    () => {
      if (editSuccess) {
        closeModal();
        getRoles();
      }
    },
    // eslint-disable-next-line
    [editSuccess]
  );

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Edit Role"}
      width={"568px"}
    >
      <Formik
        initialValues={{
          name: data && data.id,
          description: data && data.description,
        }}
        onSubmit={(values) => submitValues(values)}
      >
        {({ isValid, dirty, setFieldValue, values }) => {
          const disabled =
            values.description === "" ||
            values.description === null ||
            isEditing ||
            (selectedCategories && selectedCategories.length === 0);
          return (
            <Form id="editRole">
              <Field
                name="name"
                label="Role Name"
                placeholder="Enter role name"
                component={Input}
                emptyValue={setFieldValue}
                readOnly
              />

              <div className="form-grid">
                <Field
                  name="description"
                  label="Description"
                  placeholder="Enter description"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>

              <br />
              <div className="top-spacer">
                <MultiSelect
                  className={"checkboxes-title"}
                  rowNo={2}
                  options={checkedItems}
                  checkedItems={checkedItems}
                  setCheckedItems={setCheckedItems}
                  title={"Admin Permissions"}
                  isGetting={false}
                  getSuccess={true}
                />
              </div>

              <div className="settings-toggle">
                <h3>
                  Toggle to {state.toggle ? "deactivate" : "activate"} Role
                </h3>
                <ToggleButton
                  text={""}
                  handleChange={() => handleToggle()}
                  checked={state.toggle}
                  id={"edit-toggle"}
                  subtext={state.toggle ? "Active" : "Inactive"}
                />
              </div>

              <ModalButton
                isLoading={isEditing}
                text={"Save Changes"}
                type={"submit"}
                testId={"button"}
                loadingText={"Saving..."}
                disabled={disabled}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  editRole: (payload) => dispatch(editRole(payload)),
  getRoles: () => dispatch(getAllRoles()),
});

const mapStateToProps = (state) => ({
  isEditing: state.roles.isEditing,
  editSuccess: state.roles.editRoleSuccess,
  getRolesSuccess: state.roles.getRolesSuccess,
  isGetting: state.roles.isGetting,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditRole);
