import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getSpecialists } from "../../../redux/Actions/UserAppointmentActions";
import { transferUserChat } from "../../../redux/Actions/UsersActions";
import { assignSpecialistsOptions } from "../../../utils/functions/UpdateValues";
import SelectInput from "../../Input/Select";
import Modal, { ModalButton } from "../../Modal/Modal";

const RerouteWhispaDoctor = (props) => {
  const {
    displayModal,
    closeModal,
    state,
    setState,
    id,
    specialists,
    getSpecialistsSuccess,
    getSpecialists,
    transferUserChat,
    isTransfering,
    transferSuccess,
    getUserChatHistory,
  } = props;

  const [specialistOptions, setSpecialistOptions] = useState([]);

  useEffect(
    () => {
      getSpecialists("doctor");
    },
    // eslint-disable-next-line
    [getSpecialists]
  );

  useEffect(
    () => {
      if (getSpecialistsSuccess) {
        const updatedSpecialists = assignSpecialistsOptions(specialists);
        setSpecialistOptions(updatedSpecialists);
      }
    },
    // eslint-disable-next-line
    [getSpecialistsSuccess]
  );

  useEffect(
    () => {
      if (transferSuccess) {
        getUserChatHistory(id, "doctor");
        setState({
          ...state,
          chat: "",
          reRouteModal: false,
        });
      }
    },
    // eslint-disable-next-line
    [transferSuccess]
  );

  const updateSelection = (data) => {
    setState({
      ...state,
      newDoctor: data.value,
    });
  };

  const submitValues = () => {
    const payload = {
      user_id: state.newDoctor,
    };
    transferUserChat(id, payload);
  };

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Reroute Whispa Doctor"}
      width={"496px"}
    >
      <div className="chats-modal-text">
        Chose a doctor you want to redirect to client
      </div>
      <SelectInput
        label={"Choose Whispa Doctor"}
        options={specialistOptions}
        onChange={updateSelection}
        success={transferSuccess}
      />

      <ModalButton
        isLoading={isTransfering}
        text={"Transfer Chats"}
        testId={"button"}
        loadingText={"Transfering..."}
        onClick={submitValues}
        disabled={isTransfering || state.newDoctor === ""}
      />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  getSpecialistsSuccess: state.userappointment.getSpecialistsSuccess,
  specialists: state.userappointment.specialists,
  isTransfering: state.users.isTransfering,
  transferSuccess: state.users.transferSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getSpecialists: (type) => dispatch(getSpecialists(type)),
  transferUserChat: (id, payload) => dispatch(transferUserChat(id, payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RerouteWhispaDoctor);
