import React from "react";
import AuthWrapper, { Headers } from "../../components/Wrappers/AuthWrapper";
import { Form, Formik, Field } from "formik";
import Input from "../../components/Input/Input";
import { AuthButton } from "../../components/Button/Button";
import { connect } from "react-redux";
import { ChangePasswordSchema } from "../../utils/validation/validation";
import { resetPassword } from "../../redux/Actions/AuthActions";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import responseHandler from "../../utils/notifications/responseHandler";

const ResetPassword = (props) => {
  const { resetPassword, isReseting } = props;
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get("token")

 useEffect(() => {
  if (!token) {
    responseHandler({
      status: 500,
    }, "Failed!")
  }
 }  , [token])
  return (
    <AuthWrapper className="container2">
      <Headers
        title1={"Reset Password"}
        title2={"Enter a new password to proceed"}
      />
      <div>
        <Formik
          initialValues={{
            password: "",
            confirm_password: "",
          }}
          onSubmit={(values) => {
            const newValues = {
              token: token,
              password: values.password,
              confirm_password: values.confirm_password,
            };
            resetPassword(newValues);
          }}
          validationSchema={ChangePasswordSchema}
        >
          {({ isValid, dirty }) => {
            return (
              <Form id="form">
                <div>
                  <div className="form-grid">
                    <Field
                      name="password"
                      label="Password"
                      placeholder="Enter password"
                      component={Input}
                      type="password"
                      password
                    />
                  </div>
                  <div className="form-grid">
                    <Field
                      name="confirm_password"
                      label="Confirm Password"
                      placeholder="Enter confirm password"
                      component={Input}
                      type="password"
                      password
                    />
                  </div>

                  <AuthButton
                    children={"Reset Password"}
                    background={"#0A74DC"}
                    width={"100%"}
                    color={"#FFFFFF"}
                    isLoading={isReseting}
                    loadingText={"Reseting"}
                    disabled={!(isValid && dirty)}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </AuthWrapper>
  );
};

const mapStateToProps = (state) => ({
  isReseting: state.auth.isReseting,
  resetPassword: state.auth.resetPassword,
});
const mapDispatchToProps = (dispatch) => ({
  resetPassword: (payload) => dispatch(resetPassword(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
