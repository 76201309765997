import React from "react";
import HeaderWrapper from "../Wrappers/HeaderWrapper";
import EmptyChat from "./EmptyChat";

function AllChats(props) {
  const { chats, leftComponent, rightComponent, main } = props;

  return (
    <>
      {!main && chats && chats.length !== 0 ? (
        <div className="chats2">
          {/* <HeaderWrapper
            title={"User Chats"}
            subtitle={"View all new chats"}
          ></HeaderWrapper> */}
          <EmptyChat text={"No active chat history available"} />
        </div>
      ) : (
        <div className="chats">
          {leftComponent}
          {rightComponent}
        </div>
      )}
    </>
  );
}

export default AllChats;
