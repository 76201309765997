import Actions from "../Types";

const initialState = {
  isGetting: false,
  getReportsSucess: false,
  nodata: false,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_REPORTS_START:
      return {
        ...state,
        isGetting: true,
        getReportsSucess: false,
        nodata: false,
      };
    case Actions.GET_REPORTS_SUCCESS:
      return {
        ...state,
        isGetting: false,
        getReportsSucess: true,
        nodata: false,
      };
    case Actions.NO_DATA_REPORTS:
      return {
        ...state,
        isGetting: false,
        getReportsSucess: false,
        nodata: true,
      };
    default:
      return state;
  }
};

export default reduxState;
