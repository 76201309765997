import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getUsers, revokeUser } from "../../../redux/Actions/UsersActions";
import DeleteModal from "../../Modal/DeleteModal";

const SuspendHfcp = (props) => {
  const { revokeUser, isRevoking, id, revokeSuccess, getUsers } = props;

  useEffect(
    () => {
      if (revokeSuccess) {
        props.closeModal();
        getUsers(1, 10, "hfcp");
      }
    }, // eslint-disable-next-line
    [revokeSuccess]
  );

  return (
    <DeleteModal
      displayModal={props.displayModal}
      closeModal={props.closeModal}
      title={"Suspend this HFCP?"}
      width={"463px"}
      text={"Suspend"}
      onClick={() => revokeUser(id, "suspend")}
      disabled={isRevoking}
      isLoading={isRevoking}
      loadingText={"Suspending..."}
      children={
        "Once you suspend this HFCP, this HFCP won't be active. Suspend whispa doctor?"
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isRevoking: state.users.isRevoking,
  revokeSuccess: state.users.revokeSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  revokeUser: (payload, status) => dispatch(revokeUser(payload, status)),
  getUsers: (page, limit, type) => dispatch(getUsers(page, limit, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuspendHfcp);
