import { combineReducers } from "redux";
import AdminReducer from "./Reducers/AdminReducer";
import AuthReducer from "./Reducers/AuthReducer";
import ProductReducer from "./Reducers/ProductReducer";
import ProfileReducer from "./Reducers/ProfileReducer";
import FacilityReducer from "./Reducers/FacilityReducer";
import AppointmentReducer from "./Reducers/AppointmentReducer";
import UserAppointmentReducer from "./Reducers/UserAppointmentReducer";
import UploadReducer from "./Reducers/UploadReducer";
import OrderReducer from "./Reducers/OrderReducer";
import UsersReducer from "./Reducers/UsersReducer";
import ProductCategoryReducer from "./Reducers/ProductCategoryReducer";
import PaymentPlanReducer from "./Reducers/PaymentPlanReducer";
import BlogReducer from "./Reducers/BlogReducer";
import AuditTrailReducer from "./Reducers/AuditTrailReducer";
import SettingReducer from "./Reducers/SettingReducer";
import OverviewReducer from "./Reducers/OverviewReducer";
import CouponsReducer from "./Reducers/CouponsReducer";
import DeliveryFeesReducer from "./Reducers/DeliveryFeeReducer";
import ReferralsReducer from "./Reducers/ReferralsReducer";
import AssessmentReducer from "./Reducers/AssessmentReducer";
import BlogCategoryReducer from "./Reducers/BlogCategoryReducer";
import ChatReducer from "./Reducers/ChatReducer";
import SubscriptionReducer from "./Reducers/SubscriptionReducers";
import PrescriptionReducer from "./Reducers/PrescriptionsReducer";
import EnterpriseReducer from "./Reducers/EnterpriseReducer";
import AppVersionsReducer from "./Reducers/AppVersions";
import ReferralReportReducer from "./Reducers/ReferralReportReducer"
import ReportReducer from "./Reducers/ReportReducer"

export default combineReducers({
  auth: AuthReducer,
  admin: AdminReducer,
  products: ProductReducer,
  profile: ProfileReducer,
  facility: FacilityReducer,
  appointment: AppointmentReducer,
  userappointment: UserAppointmentReducer,
  upload: UploadReducer,
  order: OrderReducer,
  users: UsersReducer,
  productcategory: ProductCategoryReducer,
  plan: PaymentPlanReducer,
  blog: BlogReducer,
  blogcategory: BlogCategoryReducer,
  auditTrail: AuditTrailReducer,
  roles: SettingReducer,
  overview: OverviewReducer,
  coupons: CouponsReducer,
  deliveryFees: DeliveryFeesReducer,
  referrals: ReferralsReducer,
  assessments: AssessmentReducer,
  chats: ChatReducer,
  subscriptions: SubscriptionReducer,
  prescriptions: PrescriptionReducer,
  enterprise: EnterpriseReducer,
  appversions: AppVersionsReducer,
  referralreport: ReferralReportReducer,
  reports: ReportReducer 
});
