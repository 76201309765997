import React from "react";
import Links from "./Links";
import logo from "../../assets/whispa-logo.svg";
import { NavLink } from "react-router-dom";
import { getAdminPermissions } from "../../utils/functions/CreateNewArray";
import Storage from "../../utils/services/storage";
import SimpleBar from "simplebar-react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
// import "simplebar-react/dist/simplebar.min.css";

const Sidebar = () => {
  const adminDetails = Storage.get("admin-data");

  const currentAdmin = adminDetails && JSON.parse(adminDetails);

  const permissions = getAdminPermissions(currentAdmin);

  const handleVisibility = (data) => {
    const menu = permissions?.find(({ id }) => id === data?.role);
    if (menu?.checked) {
      return false;
    }
    return true;
  };

  return (
    <div className="sidebar">
      <div className="sidebar-logo">
        <img src={logo} alt="logo" />
      </div>
      <SimpleBar
        timeout={500}
        style={{ zIndex: 5, maxHeight: "100%" }}
        scrollbarMaxSize={320}
      >
        <div className="sidebar-content">
          {Links.map((data, index) => {
            return (
              <div className="sidebar-content-list" key={index}>
                <h3 className="sidebar-content-items">{data.label}</h3>
                {data?.items?.map((subdata, index) => {
                  return (
                    <NavLink
                      key={index}
                      to={subdata.name}
                      activeClassName="sidebar-content-isactive"
                      exact={subdata.label === "Overview" ? true : false}
                      hidden={handleVisibility(subdata)}
                    >
                      <div className="sidebar-item">
                        <FeatherIcon icon={subdata.icon} style={{width: "2rem"}} />
                        {/* <div className="sidebar-content-icon">
                          <img src={subdata.icon} alt="pic" />
                        </div> */}
                        <span className="link-name">{subdata.label}</span>
                      </div>
                    </NavLink>
                  );
                })}
              </div>
            );
          })}
        </div>
      </SimpleBar>
    </div>
  );
};

export default Sidebar;
