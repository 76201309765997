import React, { useEffect, useState } from "react";
import UserGrid from "../Overview/UserGrid";
import Empty from "../../assets/empty.svg";
import { Pagination } from "../Tables/Pagination";
import GridLoader from "./GridLoader";

const Grid = (props) => {
  const {
    state,
    setState,
    isGetting,
    isSearching,
    isFiltering,
    getSuccess,
    appointments,
    appointmentsData,
    searchSuccess,
    searchedAppointments,
    searchedAppointmentsData,
    filterSuccess,
    filteredAppointments,
    filteredAppointmentsData,
    getAppointments,
    getMoreData,
    setGeneralData,
  } = props;

  const isLoading = isGetting || isFiltering || isSearching;
  const success = getSuccess || filterSuccess || searchSuccess;
  const sortSuccess = filterSuccess || searchSuccess;

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(6);

  useEffect(
    () => {
      getAppointments(1, 6);
    },
    // eslint-disable-next-line
    []
  );

  const getNewData = (page) => {
    getMoreData(page, perPage);
  };

  const total = searchSuccess
    ? searchedAppointmentsData && searchedAppointmentsData.count
    : filterSuccess
    ? filteredAppointmentsData && filteredAppointmentsData.count
    : appointmentsData && appointmentsData.count;

  const allAppointments = searchSuccess
    ? searchedAppointments
    : filterSuccess
    ? filteredAppointments
    : appointments;

  useEffect(() => {
    setGeneralData(allAppointments);
  }, [allAppointments]);

  const pageOptions = total && Math.ceil(Number(total) / 6);

  return (
    <div className="appointments">
      {isLoading ? (
        <GridLoader />
      ) : success && allAppointments && allAppointments.length > 0 ? (
        <div className="appointment-grid">
          {allAppointments &&
            allAppointments.map((item, index) => (
              <UserGrid
                key={index}
                item={item}
                index={index}
                icon={true}
                state={state}
                setState={setState}
                limit={6}
              />
            ))}
        </div>
      ) : (
        <div className="table-empty">
          <div className="table-empty-container">
            <div className="table-empty-container-image">
              <img src={Empty} alt="empty-table" />
            </div>
            {sortSuccess ? (
              <div>
                <h2>{"Appointment not found!"}</h2>
              </div>
            ) : (
              <div>
                <h2>{"No Appointments found"}</h2>
                <h3>{"Click on the “Schedule” button to get started"}</h3>
              </div>
            )}
          </div>
        </div>
      )}
      <div>
        {pageOptions > 1 && !isLoading && (
          <Pagination
            getData={(index) => getNewData(index)}
            pageOptions={pageOptions}
            setPage={setPage}
            pageIndex={page}
            setPerPage={setPerPage}
          />
        )}
      </div>
    </div>
  );
};

export default Grid;
