import Actions from "../Types";

const initialState = {
  isGetting: false,
  roles: [],
  getRolesSuccess: false,
  isCreating: false,
  createRoleSuccess: false,
  isEditing: false,
  editRoleSuccess: false,
  isDeactivating: false,
  deactivateSuccess: false,
  isDeleting: false,
  deleteSuccess: false,
  role: {},
  error: null,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_ALL_ROLES_START:
      return {
        ...state,
        isGetting: true,
        error: null,
        editRoleSuccess: false,
        deactivateSuccess: false,
        getRolesSuccess: false,
      };
    case Actions.GET_ALL_ROLES_SUCCESS:
      return {
        ...state,
        isGetting: false,
        editRoleSuccess: false,
        deactivateSuccess: false,
        roles: action.payload && action.payload.roles,
        getRolesSuccess: true,
        error: null,
      };
    case Actions.GET_ALL_ROLES_FAILURE:
      return {
        ...state,
        isGetting: false,
        error: action.payload,
        getRolesSuccess: false,
        editRoleSuccess: false,
        deactivateSuccess: false,
      };
    case Actions.CREATE_ROLE_START:
      return {
        ...state,
        isGetting: false,
        isCreating: true,
        error: null,
        createRoleSuccess: false,
      };
    case Actions.CREATE_ROLE_SUCCESS:
      return {
        ...state,
        isCreating: false,
        error: null,
        role: action.payload,
        createRoleSuccess: true,
      };
    case Actions.CREATE_ROLE_FAILURE:
      return {
        ...state,
        isCreating: false,
        error: action.payload,
        createRoleSuccess: false,
      };
    case Actions.EDIT_ROLE_START:
      return {
        ...state,
        isEditing: true,
        error: null,
        editRoleSuccess: false,
        role: {},
      };
    case Actions.EDIT_ROLE_SUCCESS:
      return {
        ...state,
        isEditing: false,
        editRoleSuccess: true,
        error: null,
        role: action.payload,
      };
    case Actions.EDIT_ROLE_FAILURE:
      return {
        ...state,
        isEditing: false,
        error: action.payload,
        editRoleSuccess: false,
        role: {},
      };
    case Actions.DEACTIVATE_ROLE_START:
      return {
        ...state,
        isDeactivating: true,
        deactivateSuccess: false,
        role: {},
        error: null,
      };
    case Actions.DEACTIVATE_ROLE_SUCCESS:
      return {
        ...state,
        isDeactivating: false,
        deactivateSuccess: true,
        role: action.payload,
        error: null,
      };
    case Actions.DEACTIVATE_ROLE_FAILURE:
      return {
        ...state,
        isDeactivating: false,
        deactivateSuccess: false,
        role: {},
        error: action.payload,
      };
    case Actions.DELETE_ROLE_START:
      return {
        ...state,
        isDeleting: true,
        deleteSuccess: false,
        role: {},
        error: null,
      };
    case Actions.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: true,
        role: action.payload,
        error: null,
      };
    case Actions.DELETE_ROLE_FAILURE:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: false,
        role: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reduxState;
