import React, { useEffect } from "react";
import { connect } from "react-redux";
import { productCategoryColumns } from "../../components/TableData/Products/ProductCategory";
import Table from "../../components/Tables/Table";
import { getProductCategories } from "../../redux/Actions/ProductCategoryAction";

const ProductCategory = (props) => {
  const {
    createSuccess,
    closeModal,
    getCategories,
    categories,
    isGetting,
    getSuccess,
  } = props;

  useEffect(
    () => {
      if (createSuccess) {
        document.getElementById("addCategory").reset();
        closeModal("addCategory");
        getCategories(1, 10);
      }
    },
    // eslint-disable-next-line
    [createSuccess]
  );

  const moreData = (page, perPage) => {
    getCategories(page, perPage);
  };

  const totalCategories = categories && categories.length;

  return (
    <div>
      <Table
        columns={productCategoryColumns}
        data={categories}
        getData={getCategories}
        getMoreData={(page, perPage) => moreData(page, perPage)}
        emptyText1={"You haven't added any product category"}
        emptyText2={"Click on the “Add Category” button to get started"}
        isLoading={isGetting}
        success={getSuccess}
        emptyResultText={"category not found!"}
        total={totalCategories}
        className={"table4"}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGetting: state.productcategory.isGetting,
  getSuccess: state.productcategory.getSuccess,
  categories: state.productcategory.productCategories,
  categoriesData: state.productcategory.productCategoriesData,
});

const mapDispatchToProps = (dispatch) => ({
  getCategories: (page, limit) => dispatch(getProductCategories(page, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductCategory);
