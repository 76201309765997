import React, { useEffect } from "react";
import Modal, { ModalButton } from "../../Modal/Modal";
import { Form, Formik, Field } from "formik";
import Input from "../../Input/Input";
import { getProductCategories } from "../../../redux/Actions/ProductCategoryAction";
import {
  getAllProducts,
  stockUpProduct,
} from "../../../redux/Actions/ProductActions";
import { connect } from "react-redux";
import { filterObj } from "../../../utils/functions/FilterObject";

const StockUp = (props) => {
  const {
    displayModal,
    closeModal,
    isStocking,
    stockUp,
    stockSuccess,
    getProductCategory,
    getProducts,
    data,
  } = props;

  useEffect(
    () => {
      getProductCategory();
    },
    // eslint-disable-next-line
    []
  );

  const id = data && data.id;

  const categoryName = data && data.category && data.category.name;
  const categoryId = data && data.category_id;

  const initialValues = {
    product_name: data && data.name,
    price: data && data.price && Number(data.price),
    stock_quantity: data && data.quantity,
    manufacturer: data && data.manufacturer,
    discount: data && data.discount,
    moq: data && data.minimum_order_quantity,
    category_name: categoryName,
  };

  const submitValues = (values) => {
    const payload = {
      category_id: categoryId,
      minimum_order_quantity: values.moq,
      quantity: values.stock_quantity,
      discount: values.discount,
      price: values.price && values.price.toString(),
      manufacturer: values.manufacturer,
    };
    const data = filterObj(payload);
    stockUp(id, data);
  };

  useEffect(
    () => {
      if (stockSuccess) {
        closeModal();
        getProducts(1, 10);
      }
    },
    // eslint-disable-next-line
    [stockSuccess]
  );

  return (
    <Modal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Stock up"}
      width={"568px"}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => submitValues(values)}
      >
        {({ isValid, dirty, setFieldValue, values }) => {
          const disabled =
            isStocking ||
            values.moq === "" ||
            values.stock_quantity === "" ||
            values.price === "";

          return (
            <Form id="form">
              <div className="form-grid3">
                <Field
                  name="product_name"
                  label="Product Name"
                  placeholder="Enter product name"
                  component={Input}
                  emptyValue={setFieldValue}
                  readOnly
                />

                <Field
                  name="category_name"
                  label="Category"
                  placeholder="Enter category name"
                  component={Input}
                  emptyValue={setFieldValue}
                  readOnly
                />
              </div>

              <div className="form-grid2">
                <Field
                  name="price"
                  label="Price"
                  type="number"
                  placeholder="NGN 0.00"
                  component={Input}
                  emptyValue={setFieldValue}
                  readOnly
                />

                <Field
                  name="stock_quantity"
                  label="Stock Quantity"
                  type="number"
                  placeholder="Enter stock quantity"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>

              <div className="form-grid2">
                <Field
                  name="manufacturer"
                  label="Manufacturer"
                  placeholder="Manufacturer name"
                  component={Input}
                  emptyValue={setFieldValue}
                  readOnly
                />

                <Field
                  name="discount"
                  label="Discount"
                  placeholder="Enter discount"
                  component={Input}
                  emptyValue={setFieldValue}
                />
              </div>

              <ModalButton
                isLoading={isStocking}
                text={"Add Product"}
                type={"submit"}
                testId={"button"}
                loadingText={"Adding Product..."}
                disabled={disabled}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isGettingProductCategory: state.productcategory.isGetting,
  getProductSuccess: state.productcategory.getSuccess,
  productCategories: state.productcategory.productCategories,
  isStocking: state.products.isStocking,
  stockSuccess: state.products.stockSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getProductCategory: () => dispatch(getProductCategories()),
  stockUp: (id, payload) => dispatch(stockUpProduct(id, payload)),
  getProducts: (page, limit) => dispatch(getAllProducts(page, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StockUp);
