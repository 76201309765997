import React, { useEffect } from "react";
import { connect } from "react-redux";
import { deleteAssessment } from "../../../redux/Actions/AssessmentActions";
import DeleteModal from "../../Modal/DeleteModal";

const DeleteAssessment = (props) => {
  const { deleteQuestion, isDeleting, deleteSuccess, data, closeModal } = props;
  const id = data && data.id;

  const handleDelete = () => {
    deleteQuestion(id);
  };

  useEffect(
    () => {
      if (deleteSuccess) {
        closeModal();
      }
    }, // eslint-disable-next-line
    [deleteSuccess]
  );

  return (
    <DeleteModal
      displayModal={props.displayModal}
      closeModal={props.closeModal}
      title={"Delete this question?"}
      width={"463px"}
      text={"Delete"}
      onClick={() => handleDelete()}
      disabled={isDeleting}
      isLoading={isDeleting}
      loadingText={"Deleting..."}
      children={
        "Once you delete this question, it won’t be possible to undo this action. Delete question?"
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isDeleting: state.assessments.isDeleting,
  deleteSuccess: state.assessments.deleteSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  deleteQuestion: (id) => dispatch(deleteAssessment(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAssessment);
