import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";

// GET BLOG POSTS
export const getBlogPostsStart = () => ({
  type: Actions.GET_BLOG_POSTS_START,
});

export const getBlogPostsSuccess = (payload) => ({
  type: Actions.GET_BLOG_POSTS_SUCCESS,
  payload,
});

export const getBlogPostsFailure = (payload) => ({
  type: Actions.GET_BLOG_POSTS_FAILURE,
  payload,
});

// CREATE BLOG CATEGORY
export const createBlogCategoryStart = () => ({
  type: Actions.CREATE_BLOG_CATEGORY_START,
});

export const createBlogCategorySuccess = (payload) => ({
  type: Actions.CREATE_BLOG_CATEGORY_SUCCESS,
  payload,
});

export const createBlogCategoryFailure = (payload) => ({
  type: Actions.CREATE_BLOG_CATEGORY_FAILURE,
  payload,
});

// GET BLOG CATEGORIES
export const getBlogCategoriesStart = () => ({
  type: Actions.GET_BLOG_CATEGORIES_START,
});

export const getBlogCategoriesSuccess = (payload) => ({
  type: Actions.GET_BLOG_CATEGORIES_SUCCESS,
  payload,
});

export const getBlogCategoriesFailure = (payload) => ({
  type: Actions.GET_BLOG_CATEGORIES_FAILURE,
  payload,
});

// GET BLOG CATEGORIES WITH PAGINATION
export const getAllBlogCategoriesStart = () => ({
  type: Actions.GET_ALL_BLOG_CATEGORIES_START,
});

export const getAllBlogCategoriesSuccess = (payload) => ({
  type: Actions.GET_ALL_BLOG_CATEGORIES_SUCCESS,
  payload,
});

export const getAllBlogCategoriesFailure = (payload) => ({
  type: Actions.GET_ALL_BLOG_CATEGORIES_FAILURE,
  payload,
});

// CREATE BLOG POST
export const createBlogPostStart = () => ({
  type: Actions.CREATE_BLOG_POST_START,
});

export const createBlogPostSuccess = (payload) => ({
  type: Actions.CREATE_BLOG_POST_SUCCESS,
  payload,
});

export const createBlogPostFailure = (payload) => ({
  type: Actions.CREATE_BLOG_POST_FAILURE,
  payload,
});

// EDIT BLOG POST
export const editBlogPostStart = () => ({
  type: Actions.EDIT_BLOG_POST_START,
});

export const editBlogPostSuccess = (payload) => ({
  type: Actions.EDIT_BLOG_POST_SUCCESS,
  payload,
});

export const editBlogPostFailure = (payload) => ({
  type: Actions.EDIT_BLOG_POST_FAILURE,
  payload,
});

// GET SINGLE BLOG POST
export const getSingleBlogPostStart = () => ({
  type: Actions.GET_SINGLE_BLOG_POST_START,
});

export const getSingleBlogPostSuccess = (payload) => ({
  type: Actions.GET_SINGLE_BLOG_POST_SUCCESS,
  payload,
});

export const getSingleBlogPostFailure = (payload) => ({
  type: Actions.GET_SINGLE_BLOG_POST_FAILURE,
  payload,
});

export const singleBlogPostReset = () => (dispatch) =>
  dispatch({ type: Actions.SINGLE_BLOG_POST_CLEAR, payload: false });

// FILTER BLOG POSTS
export const filterBlogPostsStart = () => ({
  type: Actions.FILTER_BLOG_POSTS_START,
});

export const filterBlogPostsSuccess = (payload) => ({
  type: Actions.FILTER_BLOG_POSTS_SUCCESS,
  payload,
});

export const filterBlogPostsFailure = (payload) => ({
  type: Actions.FILTER_BLOG_POSTS_FAILURE,
  payload,
});

// SEARCH BLOG POSTS
export const searchBlogPostsStart = () => ({
  type: Actions.SEARCH_BLOG_POSTS_START,
});

export const searchBlogPostsSuccess = (payload) => ({
  type: Actions.SEARCH_BLOG_POSTS_SUCCESS,
  payload,
});

export const searchBlogPostsFailure = (payload) => ({
  type: Actions.SEARCH_BLOG_POSTS_FAILURE,
  payload,
});

// DELETE BLOG POST
export const deleteBlogPostStart = () => ({
  type: Actions.DELETE_BLOG_POST_START,
});

export const deleteBlogPostSuccess = (payload) => ({
  type: Actions.DELETE_BLOG_POST_SUCCESS,
  payload,
});

export const deleteBlogPostFailure = (payload) => ({
  type: Actions.DELETE_BLOG_POST_FAILURE,
  payload,
});

// EDIT BLOG CATEGORY
export const editBlogCategoryStart = () => ({
  type: Actions.EDIT_BLOG_CATEGORY_START,
});

export const editBlogCategorySuccess = (payload) => ({
  type: Actions.EDIT_BLOG_CATEGORY_SUCCESS,
  payload,
});

export const editBlogCategoryFailure = (payload) => ({
  type: Actions.EDIT_BLOG_CATEGORY_FAILURE,
  payload,
});

// FILTER BLOG CATEGORIES
export const filterBlogCategoriesStart = () => ({
  type: Actions.FILTER_BLOG_CATEGORIES_START,
});

export const filterBlogCategoriesSuccess = (payload) => ({
  type: Actions.FILTER_BLOG_CATEGORIES_SUCCESS,
  payload,
});

export const filterBlogCategoriesFailure = (payload) => ({
  type: Actions.FILTER_BLOG_CATEGORIES_FAILURE,
  payload,
});

// SEARCH BLOG CATEGORIES
export const searchBlogCategoriesStart = () => ({
  type: Actions.SEARCH_BLOG_CATEGORIES_START,
});

export const searchBlogCategoriesSuccess = (payload) => ({
  type: Actions.SEARCH_BLOG_CATEGORIES_SUCCESS,
  payload,
});

export const searchBlogCategoriesFailure = (payload) => ({
  type: Actions.SEARCH_BLOG_CATEGORIES_FAILURE,
  payload,
});

// CHANGE BLOG CATEGORY STATUS
export const changeBlogCategoryStatusStart = () => ({
  type: Actions.CHANGE_BLOG_CATEGORY_STATUS_START,
});

export const changeBlogCategoryStatusSuccess = (payload) => ({
  type: Actions.CHANGE_BLOG_CATEGORY_STATUS_SUCCESS,
  payload,
});

export const changeBlogCategoryStatusFailure = (payload) => ({
  type: Actions.CHANGE_BLOG_CATEGORY_STATUS_FAILURE,
  payload,
});

// DELETE BLOG CATEGORY
export const deleteBlogCategoryStart = () => ({
  type: Actions.DELETE_BLOG_CATEGORY_START,
});

export const deleteBlogCategorySuccess = (payload) => ({
  type: Actions.DELETE_BLOG_CATEGORY_SUCCESS,
  payload,
});

export const deleteBlogCategoryFailure = (payload) => ({
  type: Actions.DELETE_BLOG_CATEGORY_FAILURE,
  payload,
});

export const getBlogPosts = (page, limit) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getBlogPostsStart());
  API.get(`/blog/fetch-all?limit=${limit}&page=${page}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getBlogPostsSuccess(data && data.data));
    } else {
      dispatch(getBlogPostsFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const getBlogCategories = () => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getBlogCategoriesStart());
  API.get("blog-category/get-category").then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getBlogCategoriesSuccess(data && data.data));
    } else {
      dispatch(getBlogCategoriesFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const getAllBlogCategories = (page, limit) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getAllBlogCategoriesStart());
  API.get(`blog-category/fetch-all?limit=${limit}&page=${page}`).then(
    (response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(getAllBlogCategoriesSuccess(data && data.data));
      } else {
        dispatch(getAllBlogCategoriesFailure(data));
        responseHandler(response, "Failed!");
      }
    }
  );
};

export const createBlogCategory = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(createBlogCategoryStart());
  API.post("blog-category/create-category", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(createBlogCategorySuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(createBlogCategoryFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const createBlogPost = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(createBlogPostStart());
  API.post("/blog/create", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(createBlogPostSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(createBlogPostFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const editBlogPost = (id, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(editBlogPostStart());
  API.put(`/blog/edit/${id}`, payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(editBlogPostSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(editBlogPostFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const getSingleBlogPost = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(getSingleBlogPostStart());
  API.get(`/blog/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getSingleBlogPostSuccess(data && data.data));
    } else {
      dispatch(getSingleBlogPostFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const filterBlogPosts = (page, limit, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(filterBlogPostsStart());
  API.get(
    `/blog/fetch-all?status=${payload.status}&category=${payload.category}&limit=${limit}&page=${page}`
  ).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(filterBlogPostsSuccess(data && data.data));
    } else {
      dispatch(filterBlogPostsFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const searchBlogPosts =
  (page, limit, searchValue) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

    dispatch(searchBlogPostsStart());
    API.get(
      `/blog/fetch-all?page=${page}&limit=${limit}&search=${searchValue}`
    ).then((response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(searchBlogPostsSuccess(data && data.data));
      } else {
        dispatch(searchBlogPostsFailure(data));
        responseHandler(response, "Failed!");
      }
    });
  };

export const filterBlogCategories =
  (page, limit, payload) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

    dispatch(filterBlogCategoriesStart());
    API.get(
      `blog-category/fetch-all?status=${payload.status}&created_at=${payload.date}&limit=${limit}&page=${page}`
    ).then((response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(filterBlogCategoriesSuccess(data && data.data));
      } else {
        dispatch(filterBlogCategoriesFailure(data));
        responseHandler(response, "Failed!");
      }
    });
  };

export const searchBlogCategories =
  (page, limit, searchValue) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

    dispatch(searchBlogCategoriesStart());
    API.get(
      `/blog-category/fetch-all?page=${page}&limit=${limit}&search=${searchValue}`
    ).then((response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(searchBlogCategoriesSuccess(data && data.data));
      } else {
        dispatch(searchBlogCategoriesFailure(data));
        responseHandler(response, "Failed!");
      }
    });
  };

export const editBlogCategory = (id, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(editBlogCategoryStart());
  API.put(`/blog-category/edit-blog-cat/${id}`, payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(editBlogCategorySuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(editBlogCategoryFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const changeBlogCategoryStatus = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(changeBlogCategoryStatusStart());
  API.patch(`/blog-category/toggle/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(changeBlogCategoryStatusSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(changeBlogCategoryStatusFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const deleteBlogPost = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(deleteBlogPostStart());
  API.delete(`/blog/delete/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(deleteBlogPostSuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(deleteBlogPostFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};

export const deleteBlogCategory = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("admin-access-token"));

  dispatch(deleteBlogCategoryStart());
  API.delete(`/blog-category/delete-category/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(deleteBlogCategorySuccess(data && data.data));
      responseHandler(response, "Success!");
    } else {
      dispatch(deleteBlogCategoryFailure(data));
      responseHandler(response, "Failed!");
    }
  });
};
