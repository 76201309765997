import React from "react";
import AuthWrapper, { Headers } from "../../components/Wrappers/AuthWrapper";
import Inbox from "../../assets/inbox.svg";

const CheckInbox = () => {
  return (
    <AuthWrapper className="container2" flex={true}>
      <Headers
        title1={"Check Your Inbox"}
        title2={"We have sent a reset password link to your registered email"}
        flex={true}
      />
      <div className="inbox">
        <img src={Inbox} alt="inbox" />
      </div>
    </AuthWrapper>
  );
};

export default CheckInbox;
