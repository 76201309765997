import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import Sidebar from "../components/Sidebar/Sidebar";
import Navbar from "../components/Navbar/Navbar";
import Storage from "../utils/services/storage";
import jwt_decode from "jwt-decode";
import Alert from "../utils/notifications/Alert";
import { clearStorage } from "../utils/functions/ClearStorage";
import ContextProviders from "../contexts/ContextProviders";

const PrivateRoute = ({
  component: Component,
  currentUser,
  isAuthenticated,
  className,
  onBoarding,
  ...rest 
}) => {
  const initialstate = {
    user: null,
  };

  if (Storage.get("admin-access-token")) {
    const jwt_Token_decoded = jwt_decode(Storage.get("admin-access-token"));

    if (jwt_Token_decoded.exp * 1000 < Date.now()) {
      Alert(
        "error",
        "Session Expired!",
        "Kindly login again",
        "Kindly login again"
      );
      clearStorage();
    } else {
      initialstate.user = jwt_Token_decoded;
    }
  }

  const userInstorage =
    Storage.get("admin-access-token") &&
    jwt_decode(Storage.get("admin-access-token"));

  if (!currentUser && !userInstorage) {
    return <Redirect to="/" />;
  }

  return onBoarding ? (
    <Route {...rest} component={Component} />
  ) : (
    <div className="container">
      <Sidebar />
      <Navbar />
      <div className={className ? className : "container-body"}>
        <ContextProviders>
          <Route {...rest} component={Component} />
        </ContextProviders>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(PrivateRoute);
