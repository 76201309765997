import React from "react";

const GeneralInfo = (props) => {
  const { children, data } = props;

  return (
    <div className="info-wrap">
      <div className="info-wrap-left">
        <div className="info">
          <h4>Description</h4>
          <div> {!data?.about ? "Description not available" : data?.about}</div>
        </div>
        <div className="info">
          <h4>General Infomation</h4>
          <div className="info-data">
            <div className="info-data-left">Sex</div>
            <div
              className="info-data-right"
              style={{ textTransform: "capitalize" }}
            >
              {!data?.gender ? "Nil" : data?.gender}
            </div>
            <div className="info-data-left">Age Group</div>
            <div
              className="info-data-right"
              style={{ textTransform: "capitalize" }}
            >
              {!data?.age_group ? "Nil" : ` ${data?.age_group} years`}
            </div>
            <div className="info-data-left">Enterprise</div>
            <div
              className="info-data-right"
              style={{ textTransform: "capitalize" }}
            >
              {data?.enterprise || "Nil"}
            </div>

            <div className="info-data-left">Last Active Subscription Plan</div>
            <div
              className="info-data-right"
              style={{ textTransform: "capitalize" }}
            >
              {!data?.last_active_subscription?.subscription_plans?.id ? "Nil" : ` ${data?.last_active_subscription?.subscription_plans?.name}@${new Date(data?.last_active_subscription?.created_at).toDateString()}`}
            </div>
          </div>
        </div>
        <div className="info">
          <h4>Contact Infomation</h4>
          <div className="info-data">
            <div className="info-data-left">Phone Number</div>
            <div className="info-data-right">
              {!data?.phone_number ? "Nil" : data?.phone_number}
            </div>
            <div className="info-data-left">Email</div>
            <div className="info-data-right">{data?.email}</div>
          </div>
        </div>
      </div>
      <div className="info-wrap-right">{children}</div>
    </div>
  );
};

export default GeneralInfo;
